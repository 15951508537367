import React from "react";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { Modal } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";
import { Form } from "../../components/Form/Form";
import { Field } from "../../components/Field/Field";
import { useOrganizationProjectById, useUpdateProject } from "../../schema";
import { LoadingView } from "../LoadingView/LoadingView";

import styles from "./CompanyDetailsView.module.scss";

export interface UpdateProjectProps {
  projectId: string;
  organizationId: string;

  onModalClose(): void;
}

export const UpdateProject: React.FC<UpdateProjectProps> = ({
  projectId,
  organizationId,
  onModalClose,
}) => {
  // setup update project form
  const {
    useInput,
    loading: isSaving,
    error: saveError,
    submit: updateProject,
  } = useForm({
    mutation: useUpdateProject,
    onSuccess: () => onModalClose(),
  });

  // configure inputs
  const nameInput = useInput({ name: "name" });

  // load project by id
  const {
    data,
    loading: isFetching,
    error: fetchError,
  } = useOrganizationProjectById({
    fetchPolicy: "network-only",
    variables: {
      projectId,
      organizationId,
    },
  });

  const project = data?.viewer?.organization?.projectById;

  React.useEffect(() => {
    if (!project) {
      return;
    }

    nameInput.setValue(project.name);
  }, [project]);

  // submit form
  const submit = () => {
    updateProject({
      projectId,
      organizationId,
    });
  };

  if (fetchError) {
    return null;
  }

  // handle loading
  if (isSaving || isFetching || !project) {
    return <LoadingView overlay />;
  }

  return (
    <Modal
      title="Edit Project"
      onCloseRequested={onModalClose}
      footer={
        <>
          <Button data-testid="1f09ua90fcc6f" secondary onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            data-testid="faf90a0fb6"
            loading={isSaving}
            onClick={() => submit()}
          >
            Save
          </Button>
        </>
      }
    >
      <Form onSubmit={submit} className={styles["form"]}>
        <Field {...nameInput} label="Project name" short />

        {saveError && (
          <div className={styles["error"]}>An error has occurred</div>
        )}
      </Form>
    </Modal>
  );
};
