import classNames from "classnames";
import React, {
  ChangeEvent,
  FocusEvent,
  useEffect,
  useRef,
  useContext,
} from "react";
import { FieldError } from "../FieldError/FieldError";
import { FieldLabel } from "../FieldLabel/FieldLabel";
import { EditableModeContext } from "../Form/Form";
import { NotAvailable } from "../NotAvailable/NotAvailable";
import { isDefined } from "../../services/isDefined";
import styles from "./Field.module.scss";

export interface FieldProps {
  // TODO: extend field defaultProps
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string | number | boolean;
  defaultValue?: string | number | boolean;
  errors?: string[];
  type?: string;
  className?: string;
  addon?: React.ReactNode;
  checkbox?: boolean;
  textarea?: boolean;
  textareaNumRows?: number;
  required?: boolean;
  short?: boolean;
  center?: boolean;
  autofocus?: boolean;
  push?: boolean;
  zeroMargin?: boolean;
  currency?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  onCheckboxChange?(e: ChangeEvent<HTMLInputElement>): void;
  onBlur?(e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  onValueChange?(value: string): void;
  actionOnChange?: (newValue: unknown) => void;
  testId?: string;
  isFieldEditable?: boolean;
  step?: number | "any";
  inputRef?:
    | React.RefObject<HTMLInputElement>
    | React.Ref<HTMLInputElement>
    | null;
  textareaRef?:
    | React.RefObject<HTMLTextAreaElement>
    | React.Ref<HTMLTextAreaElement>
    | null;
}

export const Field: React.FC<FieldProps> = ({
  name,
  label,
  placeholder,
  value,
  defaultValue,
  errors,
  type,
  className,
  addon,
  checkbox,
  textarea,
  textareaNumRows = 3,
  required,
  short,
  autofocus,
  push,
  zeroMargin,
  currency,
  onChange,
  onCheckboxChange,
  onBlur,
  children,
  actionOnChange,
  disabled,
  readOnly,
  testId,
  isFieldEditable,
  inputRef,
  textareaRef,
  step,
}) => {
  // warn the programmer about using placeholders with checkboxes
  if (checkbox && placeholder !== undefined) {
    throw new Error(
      "Please use children to set checkbox text, placeholder does not work for checkboxes",
    );
  }

  // field identifier
  const fieldId = `${name}-field`;

  // onChange and actionOnChange can be defined on different places
  const composedOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let newValue = null;

    // run if onChange first exists
    if (typeof onChange === "function") {
      newValue = onChange(e);
    }

    // run actionOnChange second if exists
    if (typeof actionOnChange === "function") {
      actionOnChange(newValue);
    }
  };

  // onChange with e.target.checked option
  const onCheckboxSpecificChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (typeof onCheckboxChange === "function") onCheckboxChange(e);
  };

  // common field attributes
  const attributes = {
    id: fieldId,
    name,
    placeholder,
    value: value !== undefined ? value.toString() : undefined,
    defaultValue:
      defaultValue !== undefined ? defaultValue.toString() : undefined,
    onChange: composedOnChange,
    onBlur,
    disabled,
    readOnly,
  };

  // reference to input
  const internalRef = useRef<HTMLInputElement>(null);

  const editableModeContextValue = useContext(EditableModeContext);

  // prop value of `isFieldEditable` takes precedence over editable context value
  const isEditable = isDefined(isFieldEditable)
    ? isFieldEditable
    : editableModeContextValue;

  // executed on first render
  useEffect(() => {
    // focus the input if requested
    if (autofocus && internalRef.current) {
      internalRef.current.focus();
    }
  }, [autofocus]);

  // supported input element types
  const inputElement = (
    <input
      {...attributes}
      ref={inputRef ?? internalRef}
      className={classNames(styles.input)}
      type={type || "text"}
      step={step}
      data-testid={testId ?? name}
    />
  );
  const textareaElement = (
    <textarea
      ref={textareaRef}
      rows={textareaNumRows}
      {...attributes}
      className={classNames(styles.textarea)}
      data-testid={testId ?? name}
    />
  );
  const checkboxElement = (
    <label
      className={classNames(
        styles.checkbox,
        !isEditable && styles["checkbox--disabled"],
      )}
    >
      <input
        {...attributes}
        ref={inputRef}
        type="checkbox"
        checked={typeof value === "boolean" ? value : undefined}
        className={classNames(
          styles.input,
          !isEditable && styles["checkbox--disabled"],
        )}
        disabled={!isEditable}
        onChange={
          onCheckboxChange ? onCheckboxSpecificChange : attributes.onChange
        }
        data-testid={testId ?? name}
      />
      <div className={styles.checkmark} />
      {children !== undefined && (
        <div className={styles.placeholder}>{children}</div>
      )}
    </label>
  );

  // resolve element to use
  const fieldElement = textarea
    ? textareaElement
    : checkbox
    ? checkboxElement
    : inputElement;

  // format fieldElement value
  const fieldValue = () => {
    if (!fieldElement.props.value) return <NotAvailable alignLeft />;

    if (addon && currency) {
      return fieldElement.props.value + " " + addon;
    }

    return fieldElement.props.value || fieldElement.props.checked;
  };

  return (
    <div
      className={classNames(
        styles.field,
        {
          [styles["field--uneditable"]]: !isEditable,
          [styles["field--short"]]: short && isEditable,
          [styles["field--checkbox"]]: checkbox,
          [styles.push]: push,
          [styles["zero-margin"]]: zeroMargin,
        },
        className,
      )}
    >
      {/* Field is editable */}
      {isEditable && (
        <>
          {label !== undefined && label !== "" && (
            <FieldLabel label={label} htmlFor={fieldId} required={required} />
          )}

          {addon !== undefined ? (
            <div className={styles["addon-wrap"]}>
              <div className={styles.addon}>{addon}</div>
              {fieldElement}
            </div>
          ) : (
            fieldElement
          )}
          {errors !== undefined &&
            errors.map((error, index) => (
              <FieldError key={index} error={error} />
            ))}
        </>
      )}

      {/* Field is uneditable */}
      {!isEditable && (
        <div className={styles["information"]}>
          <div className={styles["left-column"]}>{label}</div>
          <div className={styles["right-column"]}>{fieldValue()}</div>
        </div>
      )}
    </div>
  );
};
