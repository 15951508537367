import React, { useState } from "react";
import classNames from "classnames";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { CARD_ITEMS_VISIBLE_COUNT } from "../../constants";
import { formatDate } from "../../services/formatDate";
import { AttachmentIcon } from "../../theme/svg/AttachmentIcon";
import { CommentHollowIcon } from "../../theme/svg/CommentHollowIcon";
import { AvatarUser } from "../Avatar/Avatar";
import { CardItem, CardItemInfo } from "../CardItem/CardItem";
import { Description } from "../Description/Description";
import { Label, LabelInfo } from "../Label/Label";
import { CardStatus, Status } from "../Status/Status";
import {
  BaseDraggable,
  BaseDroppable,
  DraggableType,
} from "../Swimlane/Swimlane";
import { UserSelectDropdown } from "../UserSelectDropdown/UserSelectDropdown";
import styles from "./Card.module.scss";

export interface SupplierInfo {
  id: string;
  name: string | null;
  defaultContactPerson: {
    email: string | null;
  };
}

export interface ProjectInfo {
  id: string;
  name: string;
}

export interface CardInfo extends BaseDroppable, BaseDraggable {
  prefix: string;
  departmentCode?: string | null;
  code: string;
  name: string;
  status: CardStatus;
  description: string;
  projects: ProjectInfo[];
  suppliers: SupplierInfo[];
  items: CardItemInfo[];
  users: AvatarUser[];
  labels: LabelInfo[];
  requestors: AvatarUser[];
  assignee: AvatarUser | null;
  createdDate: Date | string | null;
  attachmentCount: number | null;
  commentCount: number | null;
  assigning?: boolean;
  isDescriptionWarning?: boolean;
  // TODO: is it needed / give it a better name?
  isPurchaseRequestCard?: boolean;
  onClick?(): void;
}

export interface CardProps {
  info: CardInfo;
  index: number;
  organizationId: string;
  swimlaneId: string;
  onClick?: () => void;
  onAssign?(user: AvatarUser): void;
  onUnassign?(): void;
}

export const Card: React.FC<CardProps> = ({
  info,
  index,
  organizationId,
  swimlaneId,
  onClick,
  onAssign,
  onUnassign,
}) => {
  const {
    id,
    code,
    prefix,
    departmentCode,
    name,
    status,
    description,
    projects,
    suppliers,
    items,
    users,
    labels,
    requestors,
    assignee,
    createdDate,
    attachmentCount,
    commentCount,
    assigning,
    isDescriptionWarning,
    isPurchaseRequestCard,
  } = info;

  // controls whether all or limited number of items are shown
  const [showAllItems, setShowAllItems] = useState(false);

  // resolve hidden and visible items
  const hiddenItemCount = Math.max(items.length - CARD_ITEMS_VISIBLE_COUNT, 0);
  const visibleItems = showAllItems
    ? items
    : items.slice(0, CARD_ITEMS_VISIBLE_COUNT);

  // how many items to consider to be "a lot" so show less items link would be shown
  const manyItemsCount = 10;

  const inProgress = status.text === "IN PROGRESS";

  return (
    <Draggable draggableId={info.id} index={index}>
      {(
        {
          innerRef,
          draggableProps,
          dragHandleProps,
        } /*, { isDragging, isDropAnimating, mode, draggingOver }*/,
      ) => (
        <div
          data-testid="6d4f26eac6"
          className={classNames(styles.card, styles["click-target"], {
            [styles["card--purchase-request"]]: isPurchaseRequestCard,
          })}
          onClick={onClick}
          ref={innerRef}
          {...draggableProps}
          {...dragHandleProps}
        >
          <div
            className={classNames(styles.section, styles.header, {
              [styles["unfocused"]]: inProgress,
            })}
          >
            <div className={styles["code-wrapper"]}>
              <div className={styles["card-code"]}>
                {departmentCode
                  ? `${prefix}-${departmentCode}-${code}`
                  : `${prefix}-${code}`}
              </div>
              {items.length > 0 && (
                <span className={styles["secondary-text"]}>
                  ({items.length > 1 ? `${items.length} items` : "one item"})
                </span>
              )}
            </div>
            {status.text && <Status level={status.level} text={status.text} />}
          </div>

          <div className={styles.section}>
            <div className={styles["title-wrapper"]}>
              <h3
                className={classNames(styles.title, {
                  [styles["unfocused"]]: inProgress,
                })}
                title={name}
              >
                {name}
              </h3>
              <span
                className={classNames(styles.date, styles["secondary-text"], {
                  [styles["unfocused"]]: inProgress,
                })}
              >
                {createdDate && formatDate(createdDate)}
              </span>
            </div>
            <div
              className={classNames(styles["description-wrapper"], {
                [styles["unfocused"]]: inProgress,
              })}
            >
              <span
                className={classNames(
                  styles.requestor,
                  styles["secondary-text"],
                )}
              >
                {requestors
                  .map(
                    (requestor) =>
                      `${requestor.firstName} ${requestor.lastName}`,
                  )
                  .join(", ")}
              </span>
              <span
                className={classNames(styles["secondary-text"], {
                  [styles["description--has-warning"]]: isDescriptionWarning,
                })}
              >
                {description}
              </span>
            </div>
            {labels?.length > 0 && (
              <div className={styles["label-wrapper"]}>
                {labels.map((label, index) => (
                  <Label key={index} text={label.text} color={label.color} />
                ))}
              </div>
            )}

            {showAllItems && items.length >= manyItemsCount && (
              <div className={styles["toggle-more-wrap--top"]}>
                <button
                  data-testid="ac70936962"
                  className={styles["toggle-more-button"]}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAllItems(false);
                  }}
                >
                  Show less items
                </button>
              </div>
            )}

            <Droppable droppableId={id} type={DraggableType.ITEM}>
              {(
                { innerRef, droppableProps, placeholder },
                { isDraggingOver /*, draggingOverWith, draggingFromThisWith*/ },
              ) => (
                <div
                  ref={innerRef}
                  {...droppableProps}
                  className={classNames(styles["card-items"], {
                    [styles["card-items--over"]]: isDraggingOver,
                  })}
                >
                  {visibleItems.map((item, index) => (
                    <CardItem
                      key={`${id}.${item.id}`}
                      index={index}
                      info={item}
                      organizationId={organizationId}
                      swimlaneId={swimlaneId}
                    />
                  ))}
                  {visibleItems.length === 0 && (
                    <Description center>No items have been added</Description>
                  )}
                  {placeholder}
                </div>
              )}
            </Droppable>

            {hiddenItemCount > 0 && !showAllItems && (
              <div className={styles["toggle-more-wrap--bottom"]}>
                <button
                  data-testid="7926ab48a0"
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAllItems(true);
                  }}
                  className={styles["toggle-more-button"]}
                >
                  {hiddenItemCount > 1
                    ? `Show ${
                        items.length - CARD_ITEMS_VISIBLE_COUNT
                      } more items`
                    : "Show one more item"}
                </button>
              </div>
            )}

            {showAllItems && items.length >= manyItemsCount && (
              <div className={styles["toggle-more-wrap--bottom"]}>
                <button
                  data-testid="385f25936c"
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAllItems(false);
                  }}
                  className={styles["toggle-more-button"]}
                >
                  Show less items
                </button>
              </div>
            )}
          </div>
          <div
            className={classNames(styles.footer, {
              [styles["unfocused"]]: inProgress,
            })}
          >
            <div className={styles.footer__section}>
              {projects.length > 0 ? (
                <div className={styles["data-block"]}>
                  <div className={styles["footer__title"]}>
                    {projects.length > 1
                      ? `PROJECTS (${projects.length})`
                      : "PROJECT"}
                  </div>
                  <div
                    className={styles["footer__data"]}
                    title={projects.map((project) => project.name).join(", ")}
                  >
                    {projects.map((project) => project.name).join(", ")}
                  </div>
                </div>
              ) : null}

              {suppliers.length > 0 ? (
                <div className={styles["data-block"]}>
                  <div className={styles["footer__title"]}>
                    {suppliers.length > 1
                      ? `SUPPLIERS (${suppliers.length})`
                      : "SUPPLIER"}
                  </div>
                  <div
                    className={styles["footer__data"]}
                    title={suppliers
                      .map(
                        (supplier) =>
                          supplier.name || supplier.defaultContactPerson.email,
                      )
                      .join(", ")}
                  >
                    {suppliers
                      .map(
                        (supplier) =>
                          supplier.name || supplier.defaultContactPerson.email,
                      )
                      .join(", ")}
                  </div>
                </div>
              ) : null}
            </div>
            <div className={styles.footer__section}>
              {attachmentCount !== null && attachmentCount > 0 && (
                <div className={styles["icon-wrapper"]}>
                  <AttachmentIcon className={styles["attachment-icon"]} />
                  {attachmentCount}
                </div>
              )}

              {commentCount !== null && commentCount > 0 && (
                <div className={styles["icon-wrapper"]}>
                  <CommentHollowIcon className={styles["comments-icon"]} />
                  {commentCount}
                </div>
              )}

              <UserSelectDropdown
                loading={assigning}
                title={
                  assignee === null && inProgress
                    ? "Unassigned"
                    : assignee === null
                    ? "Click to assign card"
                    : inProgress
                    ? `${assignee.firstName} ${assignee.lastName}`
                    : `[${assignee.firstName} ${assignee.lastName}] Click to change card assignee`
                }
                activeUser={assignee}
                users={users}
                disabled={inProgress}
                onChoose={onAssign}
                onUnassign={onUnassign}
              />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};
