import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useForm as useFormHook, FormProvider } from "react-hook-form";
import { Accordion } from "../../components/Accordion/Accordion";
import { Approver } from "../../components/Approver/Approver";
import { Attachments } from "../../components/Attachments/Attachments";
import { Badge } from "../../components/Badge/Badge";
import { Button } from "../../components/Button/Button";
import { CollaborationSidebar } from "../../components/CollaborationSidebar/CollaborationSidebar";
import { CommentForm } from "../../components/CommentForm/CommentForm";
import { Datepicker } from "../../components/Datepicker/Datepicker";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { Field } from "../../components/Field/Field";
import fieldStyles from "../../components/Field/Field.module.scss";
import { FieldLabel } from "../../components/FieldLabel/FieldLabel";
import { Form } from "../../components/Form/Form";
import {
  AutosuggestItem,
  ItemAutosuggest,
} from "../../components/ItemAutosuggest/ItemAutosuggest";
import { LabelDropdown } from "../../components/LabelDropdown/LabelDropdown";
import { Modal } from "../../components/Modal/Modal";
import { NonUploadedFiles } from "../../components/NonUploadedFiles/NonUploadedFiles";
import { NotAvailable } from "../../components/NotAvailable/NotAvailable";
import {
  PurchaseRequestForm,
  UpdatePurchaseRequestFormData,
} from "../../components/PurchaseRequestForm/PurchaseRequestForm";
import {
  PurchaseRequestItems,
  PurchaseRequestItem,
} from "../../components/PurchaseRequestItems/PurchaseRequestItems";
import { RequesterCorrespondenceBar } from "../../components/RequesterCorrespondenceBar/RequesterCorrespondenceBar";
import { Separator } from "../../components/Separator/Separator";
import { Cell, BasicTable } from "../../components/BasicTable/BasicTable";
import { UploadedAttachment } from "../../components/UploadedAttachment/UploadedAttachment";
import { UserSelectDropdown } from "../../components/UserSelectDropdown/UserSelectDropdown";
import { LatestActivityProvider } from "../../contexts/latest-activity-context";
import { ViewerContextProvider } from "../../contexts/viewer-context";
import { useRouter } from "../../hooks/useRouter";
import { useForm } from "../../lib/react-apollo-hooks-form";
import {
  ActivityRelativeTypeEnum,
  ApprovalRequestDecisionEnum,
  ApprovalWorkflowStatusEnum,
  CardTypeEnum,
  CreateApprovalRequestMutation,
  CreateApprovalRequestVariables,
  CreateApprovalWorkflowMutation,
  CreateApprovalWorkflowVariables,
  CreatePurchaseRequestAttachmentsMutation,
  CreatePurchaseRequestAttachmentsVariables,
  PurchaseRequestStatusEnum,
  UpdateApprovalWorkflowDataMutation,
  UpdateApprovalWorkflowDataVariables,
  useActivitySubscription,
  useAddRolesToUser,
  useAssignCard,
  useCreateApprovalRequest,
  useCreateApprovalWorkflow,
  useCreateApprover,
  useCreateCostCentre,
  useCreateDepartment,
  useCreateProject,
  useCreatePurchaseRequestAttachments,
  useCreateRequestor,
  useDeletePurchaseRequestItem,
  usePurchaseRequestByCode,
  useRemovePurchaseRequestAttachment,
  UserRoleEnum,
  useUpdateApprovalWorkflowData,
  useUpdateApprovalWorkflowStatus,
  useUpdatePurchaseRequest,
  useUpdatePurchaseRequestStatus,
  useArchivePurchaseRequest,
  ViewerOrganizations,
  ViewerViewer,
  useCreateRfxFromPurchaseRequestItems,
  useCreateOrderFromPurchaseRequestItems,
  StatusFilterEnum,
  useAddPurchaseRequestItemsToRfx,
  useRfxModalInfo,
  useOrderModalInfo,
  useAddPurchaseRequestItemsToOrder,
  useUpdatePurchaseRequestFromPortal,
  UpdatePurchaseRequestFromPortalMutationVariables,
  Attachment,
  useCompletePurchaseRequest,
  useDeleteApprovalRequest,
} from "../../schema";
import { convertPRFormDataToMutation } from "../../services/convertPRFormDataToMutation";
import { costCentreToAutosuggestItem } from "../../services/costCentreToAutosuggestItem";
import { departmentToAutosuggestItem } from "../../services/departmentToAutosuggestItem";
import { formatCapitalizeString } from "../../services/formatCapitalizeString";
import { formatDatetime } from "../../services/formatDatetime";
import { getCardStatus } from "../../services/getPurchaseRequestCardInfo";
import { isDefined } from "../../services/isDefined";
import { matches } from "../../services/matches";
import { projectToAutosuggestItem } from "../../services/projectsToAutosuggestItem";
import { splitFullName } from "../../services/splitFullName";
import { userToAutosuggestItem } from "../../services/userToAutosuggestItem";
import { CheckboxIcon } from "../../theme/svg/CheckboxIcon";
import { CheckmarkIcon } from "../../theme/svg/CheckmarkIcon";
import { CloseIcon } from "../../theme/svg/CloseIcon";
import { EditIcon } from "../../theme/svg/EditIcon";
import { ReadyIcon } from "../../theme/svg/ReadyIcon";
import { formatTextNumber } from "../../transformers/formatTextNumber";
import { isEmail } from "../../validators/isEmail";
import { isNumber } from "../../validators/isNumber";
import { notEmpty } from "../../validators/notEmpty";
import { ErrorView } from "../ErrorView/ErrorView";
import { filterUsersWhoCanBeAssignedToCards } from "../KanbanView/KanbanView";
import { LoadingView } from "../LoadingView/LoadingView";
import { OnDropCallbackFn } from "../../components/Dropzone/Dropzone";
import { NumberInput } from "../../components/NumberInput/NumberInput";
import { ArchiveIcon } from "../../theme/svg/ArchiveIcon";
import { RemoveIcon } from "../../theme/svg/RemoveIcon";
import { formatDate } from "../../services/formatDate";
import styles from "./PurchaseRequestView.module.scss";

export interface PurchaseRequestViewProps {
  viewer: ViewerViewer;
  organization: ViewerOrganizations;
  code: string;
  onModalClose(): void;
}

export const PurchaseRequestView: React.FC<PurchaseRequestViewProps> = ({
  organization,
  code,
  viewer,
  onModalClose,
}) => {
  // use router
  const { history } = useRouter();

  // make it possible to change the update purchase request success redirect route path
  const successRedirectPath = useRef(`/${organization.urlName}`);

  // SETUP STATE

  // check if approval workflow was started
  const [startedApprovalWorkflow, setStartedApprovalWorkflow] = useState(false);

  // handle Request Info open state
  const [isRequestInfoOpen, setIsRequestInfoOpen] = useState(true);

  // cancelling reason form open
  const [isCancellingCommentFormOpen, setIsCancellingCommentFormOpen] =
    useState(false);

  // keep track of which purchase request we are deleting
  const [deletingPurchaseRequestItemId, setDeletingPurchaseRequestItemId] =
    useState<string>();

  // track for initiation state
  const [isInitiated, setIsInitiated] = useState(false);

  const [files, setFiles] = useState<File[]>([]);

  const [chosenRequestor, setChosenRequestor] = useState<AutosuggestItem>();
  const [requestorDisplayValue, setRequestorDisplayValue] = useState("");

  const [chosenApprover, setChosenApprover] = useState<AutosuggestItem>();
  const [approverDisplayValue, setApproverDisplayValue] = useState("");

  const [chosenDepartment, setChosenDepartment] = useState<AutosuggestItem>();
  const [departmentDisplayValue, setDepartmentDisplayValue] = useState("");

  const [chosenProject, setChosenProject] = useState<AutosuggestItem>();
  const [projectDisplayValue, setProjectDisplayValue] = useState("");

  const [chosenCostCentre, setChosenCostCentre] = useState<AutosuggestItem>();
  const [costCentreDisplayValue, setCostCentreDisplayValue] = useState("");

  const [areRequestorFieldsActive, setAreRequestorFieldsActive] =
    useState(false);
  const [areApproverFieldsActive, setAreApproverFieldsActive] = useState(false);

  const [isPurchaseRequestEditable, setIsPurchaseRequestEditable] =
    useState(true);

  const [isCommentFormLoading, setIsCommentFormLoading] = useState(false);

  const [showCorrespondenceBar, setShowCorrespondenceBar] = useState(false);

  const [selectedItems, setSelectedItems] = useState<PurchaseRequestItem[]>([]);

  // configure request info form
  const {
    useInput: useRequestInfoInput,
    useCheckbox: useRequestStatusCheckbox,
    submit: updateRequestInfo,
    loading: isUpdateRequestInfoLoading,
    error: updateRequestInfoError,
  } = useForm({
    mutation: useUpdatePurchaseRequest,
    options: {
      refetchQueries: ["PaginatedPurchaseRequests", "PurchaseRequestByCode"],
      awaitRefetchQueries: true,
    },
  });

  // configure request info form inputs
  const purchaseRequestNameInput = useRequestInfoInput({
    name: "name",
    validators: [notEmpty],
  });
  const purchaseRequestNotesInput = useRequestInfoInput({
    name: "notes",
    optional: true,
  });

  // configure purchase request status update input
  const purchaseRequestStatusInput = useRequestStatusCheckbox({
    name: "status",
  });

  // configure create approval workflow form
  const {
    submit: createApprovalWorkflow,
    loading: isCreateApprovalWorkflowLoading,
    // error: createApprovalWorkflowError,
  } = useForm<CreateApprovalWorkflowMutation, CreateApprovalWorkflowVariables>({
    mutation: useCreateApprovalWorkflow,
    options: {
      refetchQueries: ["PaginatedPurchaseRequests", "PurchaseRequestByCode"],
      awaitRefetchQueries: true,
    },
  });

  // configure update approval workflow form
  const {
    useInput: useUpdateApprovalWorkflowDataInput,
    useCheckbox: useUpdateApprovalWorkflowDataCheckbox,
    submit: updateApprovalWorkflowData,
    loading: isUpdateApprovalWorkflowDataLoading,
    // error: updateApprovalWorkflowDataError,
  } = useForm<
    UpdateApprovalWorkflowDataMutation,
    UpdateApprovalWorkflowDataVariables
  >({
    mutation: useUpdateApprovalWorkflowData,
    options: {
      refetchQueries: ["PaginatedPurchaseRequests", "PurchaseRequestByCode"],
      awaitRefetchQueries: true,
    },
  });

  // configure approval workflow form inputs
  const requestNameInput = useUpdateApprovalWorkflowDataInput({
    name: "requestName",
    validators: [],
    optional: true,
  });
  const approvalAmountInput = useUpdateApprovalWorkflowDataInput({
    name: "approvalAmount",
    optional: true,
    validators: [isNumber],
  });
  const keepRequesterUpdatedInput = useUpdateApprovalWorkflowDataCheckbox({
    name: "keepRequesterUpdated",
    defaultValue: false,
  });
  const singleApprovalRequiredInput = useUpdateApprovalWorkflowDataCheckbox({
    name: "singleApprovalRequired",
    defaultValue: false,
  });

  // configure create approval request form
  const {
    useInput: useCreateApprovalRequestInput,
    useDatepicker,
    submit: createApprovalRequest,
    loading: isCreateApprovalRequestLoading,
    error: createApprovalRequestError,
  } = useForm<CreateApprovalRequestMutation, CreateApprovalRequestVariables>({
    mutation: useCreateApprovalRequest,
    options: {
      refetchQueries: ["PaginatedPurchaseRequests", "PurchaseRequestByCode"],
      awaitRefetchQueries: true,
    },
  });

  // configure create approval request inputs
  const creatorCommentInput = useCreateApprovalRequestInput({
    name: "comment",
    optional: true,
  });
  const approvalDeadlineInput = useDatepicker({
    name: "dueDate",
    optional: true,
  });
  const sequenceNumberInput = useCreateApprovalRequestInput({
    name: "sequenceNumber",
    transform: formatTextNumber(0),
    validators: [notEmpty, isNumber],
  });

  // close Request Info block if Approval Workflow was started
  useEffect(() => {
    // close Request Info if Approval Workflow was started
    if (startedApprovalWorkflow) {
      setIsRequestInfoOpen(false);
    }
  }, [startedApprovalWorkflow]);

  // attempt to load purchase request info
  const {
    data: prData,
    error: getPrError,
    loading: isGetPrLoading,
    refetch: refetchPurchaseRequestByCode,
  } = usePurchaseRequestByCode({
    fetchPolicy: "network-only",
    variables: {
      organizationId: organization.id,
      code,
    },
  });

  // attempt to load rfx drafts and ongoings
  const { data: rfxModalInfoData } = useRfxModalInfo({
    fetchPolicy: "network-only",
    variables: {
      organizationId: organization.id,
      filter: {
        status: [StatusFilterEnum.DRAFT],
        userId: null,
        projectId: null,
        departmentId: null,
        supplierId: null,
        dateRange: null,
      },
    },
  });

  // attempt to load order drafts
  const { data: orderModalInfoData } = useOrderModalInfo({
    fetchPolicy: "network-only",
    variables: {
      organizationId: organization.id,
      filter: {
        status: [StatusFilterEnum.PO_DRAFT],
        userId: null,
        projectId: null,
        departmentId: null,
        supplierId: null,
        dateRange: null,
      },
    },
  });

  const rfxDrafts = rfxModalInfoData?.viewer?.organization.rfx;
  const orderDrafts = orderModalInfoData?.viewer?.organization.orders;

  // setup update approval workflow status
  const [
    updateApprovalWorkflowStatus,
    { loading: isUpdateApprovalWorkflowStatusLoading },
  ] = useUpdateApprovalWorkflowStatus({
    refetchQueries: ["PurchaseRequestByCode", "PaginatedPurchaseRequests"],
    awaitRefetchQueries: true,
  });

  // setup update purchase request status
  const [updatePurchaseRequestStatus] = useUpdatePurchaseRequestStatus({
    refetchQueries: ["PurchaseRequestByCode", "PaginatedPurchaseRequests"],
    awaitRefetchQueries: true,
  });

  const [
    completePurchaseRequest,
    { loading: isCompletePurchaseRequestLoading },
  ] = useCompletePurchaseRequest({
    refetchQueries: ["PaginatedPurchaseRequests"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      onModalClose();
    },
  });

  const [archivePurchaseRequest, { loading: isArchivePurchaseRequestLoading }] =
    useArchivePurchaseRequest({
      refetchQueries: ["PaginatedPurchaseRequests"],
      awaitRefetchQueries: true,
      onCompleted: () => {
        onModalClose();
      },
    });

  // setup deleting purchase request item
  const [deletePurchaseRequestItem] = useDeletePurchaseRequestItem({
    refetchQueries: ["PurchaseRequestByCode", "PaginatedPurchaseRequests"],
    awaitRefetchQueries: true,
  });

  // setup assigning card
  const [assignCard, { loading: isAssigningCard }] = useAssignCard({
    refetchQueries: ["PurchaseRequestByCode", "PaginatedPurchaseRequests"],
    awaitRefetchQueries: true,
  });

  // setup creating department
  const [createDepartment, { loading: isCreatingDepartment }] =
    useCreateDepartment({
      refetchQueries: ["PurchaseRequestByCode", "departments"],
      awaitRefetchQueries: true,
    });

  // setup creating costcentre
  const [createCostCentre, { loading: isCreatingCostCentre }] =
    useCreateCostCentre({
      refetchQueries: ["PurchaseRequestByCode"],
      awaitRefetchQueries: true,
    });

  // setup creating project
  const [createProject, { loading: isCreatingProject }] = useCreateProject({
    refetchQueries: ["PurchaseRequestByCode"],
    awaitRefetchQueries: true,
  });

  // setup updating user roles
  const [addRolesToUser, { loading: isUpdatingUserRoles }] = useAddRolesToUser({
    refetchQueries: ["PurchaseRequestByCode", "PaginatedPurchaseRequests"],
    awaitRefetchQueries: true,
  });

  // setup removing attachment
  const [removeAttachment] = useRemovePurchaseRequestAttachment({
    refetchQueries: ["PurchaseRequestByCode"],
    awaitRefetchQueries: true,
  });

  // setup creating rfx from items
  const [createRfxFromItems, { loading: isCreatingRfxFromItems }] =
    useCreateRfxFromPurchaseRequestItems({
      refetchQueries: [
        "PurchaseRequestByCode",
        "PaginatedPurchaseRequests",
        "RfxModalInfo",
      ],
      awaitRefetchQueries: true,
    });

  // setup creating order from items
  const [createOrderFromItems, { loading: isCreatingOrderFromItems }] =
    useCreateOrderFromPurchaseRequestItems({
      refetchQueries: [
        "PurchaseRequestByCode",
        "PaginatedPurchaseRequests",
        "OrderModalInfo",
      ],
      awaitRefetchQueries: true,
    });

  // setup adding items to rfx
  const [addItemsToRfx, { loading: isAddingItemsToRfx }] =
    useAddPurchaseRequestItemsToRfx({
      refetchQueries: [
        "PurchaseRequestByCode",
        "PaginatedPurchaseRequests",
        "PaginatedRfx",
      ],
      awaitRefetchQueries: true,
    });

  // setup adding items to order
  const [addItemsToOrder, { loading: isAddingItemsToOrder }] =
    useAddPurchaseRequestItemsToOrder({
      refetchQueries: ["PurchaseRequestByCode", "PaginatedPurchaseRequests"],
      awaitRefetchQueries: true,
    });

  // resolve info
  const purchaseRequest = prData?.viewer
    ? prData.viewer.purchaseRequestByCode
    : undefined;
  const users = prData?.viewer ? prData.viewer.organization.users : [];
  const suppliers = prData?.viewer ? prData.viewer.organization.suppliers : [];
  const departments = prData?.viewer
    ? prData.viewer.organization.departments
    : [];
  const costCentres = prData?.viewer
    ? prData.viewer.organization.costCentres
    : [];
  const projects = prData?.viewer ? prData.viewer.organization.projects : [];
  const currentApprovalWorkflow = purchaseRequest
    ? purchaseRequest.latestApprovalWorkflow
    : null;
  const currentApprovalRequests =
    currentApprovalWorkflow?.approvalRequests.sort((a, b) =>
      a.sequenceNumber < b.sequenceNumber ? -1 : 1,
    );
  const organizationBaseCurrency = organization.baseCurrency;
  const addressBook = prData?.viewer?.organization
    ? prData.viewer.organization.addressBook
    : [];

  const usersWhoCanBeAssignedToCards =
    filterUsersWhoCanBeAssignedToCards(users);

  const userMentionData = users.map((user) => ({
    id: user.id,
    display: `${user.firstName} ${user.lastName}`,
  }));

  const supplierMentionData = suppliers.map((supplier) => ({
    id: supplier.id,
    display: supplier.name
      ? supplier.name
      : supplier.defaultContactPerson.email
      ? supplier.defaultContactPerson.email
      : "[missing name]",
  }));

  // subscribe to activities
  const { data: activitySubscriptionData } = useActivitySubscription({
    variables: {
      organizationId: organization.id,
      relativeId: purchaseRequest ? purchaseRequest.id : null,
    },
    skip: !prData,
  });

  const attachments = purchaseRequest?.attachments as Pick<
    Attachment,
    "id" | "filename" | "url"
  >[];

  // [PR2.0] Configure form
  const {
    submit: updatePR,
    loading: isUpdatingPR,
    error: updatingPRError,
  } = useForm({
    mutation: useUpdatePurchaseRequestFromPortal,
    options: {
      refetchQueries: [
        "CreatePurchaseRequestView",
        "PaginatedPurchaseRequests",
        "PurchaseRequestByCode",
      ],
      awaitRefetchQueries: true,
    },
    onSuccess: () => {
      setFiles([]);
    },
  });

  const methods = useFormHook<UpdatePurchaseRequestFormData>();

  // Control form submission
  const savePurchaseRequest = async (
    values: UpdatePurchaseRequestFormData,
    newStatus?: PurchaseRequestStatusEnum,
  ) => {
    // Then update purchase request details
    const purchaseRequestDetails: UpdatePurchaseRequestFromPortalMutationVariables =
      {
        ...convertPRFormDataToMutation(values),
        purchaseRequestId: values.purchaseRequestId,
        status: isDefined(newStatus)
          ? PurchaseRequestStatusEnum[newStatus]
          : PurchaseRequestStatusEnum.DRAFT,
        attachments: files,
      };

    updatePR(purchaseRequestDetails);
  };

  const draftPurchaseRequest = methods.handleSubmit((values: any) => {
    // If purchase request is created from portal/email assign to user has edited the request
    if (
      purchaseRequest &&
      purchaseRequest.status === PurchaseRequestStatusEnum.CREATED_FROM_EMAIL
    ) {
      !purchaseRequest.assignee &&
        assignCard({
          variables: {
            itemId: purchaseRequest.id,
            assigneeId: viewer.id,
            type: CardTypeEnum.PURCHASE_REQUEST,
          },
        });
    }

    savePurchaseRequest(values);
  });

  const readyPurchaseRequest = methods.handleSubmit((values: any) => {
    // On set request ready assignee user if has not informed
    if (purchaseRequest) {
      !purchaseRequest.assignee &&
        assignCard({
          variables: {
            itemId: purchaseRequest.id,
            assigneeId: viewer.id,
            type: CardTypeEnum.PURCHASE_REQUEST,
          },
        });
    }

    savePurchaseRequest(values, PurchaseRequestStatusEnum.READY);
  });

  // set files state on drop
  const onDrop = useCallback<OnDropCallbackFn>(
    (acceptedFiles) => {
      // set selected file
      setFiles([...(files ?? []), ...acceptedFiles]);
    },
    [files],
  );

  // refetch PurchaseRequestByCode data if new subscription events occur
  useEffect(() => {
    refetchPurchaseRequestByCode();
  }, [activitySubscriptionData, refetchPurchaseRequestByCode]);

  // suggest next sequence value for approver
  useEffect(() => {
    const nextSequenceValue = currentApprovalRequests
      ? currentApprovalRequests.length + 1
      : 1;

    if (sequenceNumberInput.value !== nextSequenceValue) {
      sequenceNumberInput.setValue(nextSequenceValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApprovalRequests]);

  const { submit: createAttachments, loading: createAttachmentsLoading } =
    useForm<
      CreatePurchaseRequestAttachmentsMutation,
      CreatePurchaseRequestAttachmentsVariables
    >({
      mutation: useCreatePurchaseRequestAttachments,
      options: {
        refetchQueries: ["PurchaseRequestByCode", "PaginatedPurchaseRequests"],
        awaitRefetchQueries: true,
      },
      onSuccess: () => {
        setFiles([]);
      },
    });

  // configure requestor form
  const {
    useInput: useRequestorInput,
    submit: createRequestor,
    loading: isCreateRequestorLoading,
    error: requestorError,
  } = useForm({
    mutation: useCreateRequestor,
    options: {
      refetchQueries: ["PaginatedPurchaseRequests"],
      awaitRefetchQueries: true,
    },
    onSuccess: (response) => {
      setAreRequestorFieldsActive(false);

      // set the chosen requestor as the created user
      setChosenRequestor(userToAutosuggestItem(response.createRequestor));
      setRequestorDisplayValue(
        `${response.createRequestor.firstName} ${response.createRequestor.lastName}`,
      );
    },
  });

  // configure requestor inputs
  const requestorFirstNameInput = useRequestorInput({
    name: "firstName",
    validators: [notEmpty],
  });
  const requestorLastNameInput = useRequestorInput({
    name: "lastName",
    validators: [notEmpty],
  });
  const requestorEmailInput = useRequestorInput({
    name: "email",
    validators: [notEmpty, isEmail],
  });

  // configure approver form
  const {
    useInput: useApproverInput,
    submit: createApprover,
    loading: isCreateApproverLoading,
    error: approverError,
  } = useForm({
    mutation: useCreateApprover,
    options: {
      refetchQueries: ["PaginatedPurchaseRequests"],
      awaitRefetchQueries: true,
    },
    onSuccess: (response) => {
      setAreApproverFieldsActive(false);

      // set the chosen requestor as the created user
      setChosenApprover(userToAutosuggestItem(response.createApprover));
      setApproverDisplayValue(
        `${response.createApprover.firstName} ${response.createApprover.lastName}`,
      );
    },
  });

  // configure approver inputs
  const approverFirstNameInput = useApproverInput({
    name: "firstName",
    validators: [notEmpty],
  });
  const approverLastNameInput = useApproverInput({
    name: "lastName",
    validators: [notEmpty],
  });
  const approverEmailInput = useApproverInput({
    name: "email",
    validators: [notEmpty, isEmail],
  });

  // configure approval workflow cancelling reason form
  const {
    useInput: useCancellingInput,
    submit: submitCancellingWorkflow,
    reset: resetCancellingWorkflowForm,
    loading: loadingCancellingWorkflow,
  } = useForm({
    mutation: useUpdateApprovalWorkflowStatus,
    options: {
      refetchQueries: ["PaginatedPurchaseRequests"],
      awaitRefetchQueries: true,
    },
  });

  // configure cancelling comment input
  const cancellingCommentInput = useCancellingInput({
    name: "cancellingComment",
    optional: true,
  });

  // configure deleting approval request
  const [deleteApprovalRequest, { loading: isDeleteApprovalRequestLoading }] =
    useDeleteApprovalRequest({
      refetchQueries: ["PurchaseRequestByCode"],
      awaitRefetchQueries: true,
    });

  // set default input values once purchase request is loaded
  useEffect(() => {
    if (!purchaseRequest) {
      return;
    }

    // set input default values for request info form
    purchaseRequestNameInput.setValue(purchaseRequest.name);
    purchaseRequestNotesInput.setValue(purchaseRequest.notes);
    purchaseRequestStatusInput.setValue(
      purchaseRequest.status === PurchaseRequestStatusEnum.READY,
    );

    // set approval workflow form default values if exists
    if (purchaseRequest.latestApprovalWorkflow) {
      const requestName =
        purchaseRequest.latestApprovalWorkflow.requestName ??
        purchaseRequest.name;
      const approvalAmount =
        purchaseRequest.latestApprovalWorkflow.approvalAmount;
      const keepRequesterUpdated =
        purchaseRequest.latestApprovalWorkflow.keepRequesterUpdated;
      const singleApprovalRequired =
        purchaseRequest.latestApprovalWorkflow.singleApprovalRequired;
      const status = purchaseRequest.latestApprovalWorkflow.status;

      requestName && requestNameInput.setValue(requestName);
      approvalAmount && approvalAmountInput.setValue(approvalAmount);
      keepRequesterUpdated &&
        keepRequesterUpdatedInput.setValue(keepRequesterUpdated);
      singleApprovalRequiredInput &&
        singleApprovalRequiredInput.setValue(singleApprovalRequired);

      if (
        [
          ApprovalWorkflowStatusEnum.DRAFT,
          ApprovalWorkflowStatusEnum.IN_PROGRESS,
        ].includes(status)
      ) {
        setStartedApprovalWorkflow(true);
      }
    }

    // find chosen requestor
    const prRequestor = users.find(
      (user) => user.id === purchaseRequest.requestorId,
    );
    const prDepartment = departments.find(
      (department) => department.id === purchaseRequest.departmentId,
    );
    const prProject = projects.find(
      (project) => project.id === purchaseRequest.projectId,
    );
    const prCostCentre = costCentres.find(
      (costCentre) => costCentre.id === purchaseRequest.costCentreId,
    );

    // update chosen requestor and value
    if (prRequestor) {
      // convert user to autosuggest item
      const requestorItem = userToAutosuggestItem(prRequestor);

      setChosenRequestor(requestorItem);
      setRequestorDisplayValue(requestorItem.name || "");
    }
    if (prDepartment) {
      const departmentItem = departmentToAutosuggestItem(prDepartment);

      setChosenDepartment(departmentItem);
      setDepartmentDisplayValue(departmentItem.name || "");
    }
    if (prProject) {
      const projectItem = projectToAutosuggestItem(prProject);

      setChosenProject(projectItem);
      setProjectDisplayValue(projectItem.name || "");
    }
    if (prCostCentre) {
      const costCentreItem = costCentreToAutosuggestItem(prCostCentre);

      setChosenCostCentre(costCentreItem);
      setCostCentreDisplayValue(costCentreItem.name || "");
    }

    // control purchase request editablility
    setIsPurchaseRequestEditable(
      ![
        PurchaseRequestStatusEnum.READY,
        PurchaseRequestStatusEnum.IN_PROGRESS,
      ].includes(purchaseRequest.status) && !startedApprovalWorkflow,
    );

    setIsInitiated(true);

    // show correspondence bar if there were any e-mails sent or received in preceding rfx stadium
    if (
      purchaseRequest &&
      purchaseRequest.emails &&
      purchaseRequest.emails.length > 0
    ) {
      setShowCorrespondenceBar(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseRequest]);

  // handle changes in currentApprovalWorkfkow
  useEffect(() => {
    if (!currentApprovalWorkflow) {
      return;
    }

    if (
      currentApprovalWorkflow.status === ApprovalWorkflowStatusEnum.CANCELLED
    ) {
      setStartedApprovalWorkflow(false);
    }
  }, [currentApprovalWorkflow]);

  // handle loading purchase request && departments error
  if (getPrError) {
    return (
      <Modal
        wide
        title="Loading purchase request failed"
        onCloseRequested={onModalClose}
      >
        <ErrorView error="Requested purchase request could not be found" />
      </Modal>
    );
  }

  // handle loading (also avoid form content flicker)
  if (!purchaseRequest) {
    return <LoadingView overlay />;
  }

  // set up creation roles for autosuggest
  const allowedCreationRoles = [
    UserRoleEnum.ADMIN,
    UserRoleEnum.KEY_USER,
    UserRoleEnum.BUYER,
  ];

  const isAllowedToCreate = viewer.roles.some((role) =>
    allowedCreationRoles.includes(role),
  );

  // submits approver
  const addApproverAction = async () => {
    if (
      !currentApprovalWorkflow ||
      !chosenApprover ||
      (sequenceNumberInput.value && Number(sequenceNumberInput.value) <= 0)
    ) {
      return;
    }

    await createApprovalRequest({
      approverId: chosenApprover ? chosenApprover.id : "",
      approvalWorkflowId: currentApprovalWorkflow.id,
      organizationId: purchaseRequest.organizationId,
      sequenceNumber:
        typeof sequenceNumberInput.value === "number"
          ? sequenceNumberInput.value
          : typeof sequenceNumberInput.value === "string"
          ? parseFloat(sequenceNumberInput.value)
          : 1,
    });

    // reset fields
    setChosenApprover(undefined);
    setApproverDisplayValue("");
    creatorCommentInput.reset();
    approvalDeadlineInput.reset();
  };

  const handleDeleteApprovalRequest = (approvalRequestId: string) => {
    deleteApprovalRequest({
      variables: {
        approvalRequestId,
        organizationId: purchaseRequest.organizationId,
      },
    });
  };

  // submits conditionally forms
  const saveAction = async (newStatusValue?: boolean) => {
    if (!startedApprovalWorkflow) {
      let status: PurchaseRequestStatusEnum | undefined;

      if (isDefined(newStatusValue)) {
        status = newStatusValue
          ? PurchaseRequestStatusEnum.READY
          : PurchaseRequestStatusEnum.DRAFT;
        // assign card if complete & unassigned
        status === PurchaseRequestStatusEnum.READY &&
          !purchaseRequest.assignee &&
          assignCard({
            variables: {
              itemId: purchaseRequest.id,
              assigneeId: viewer.id,
              type: CardTypeEnum.PURCHASE_REQUEST,
            },
          });
      }

      if (!isDefined(newStatusValue)) {
        status = purchaseRequest.status;
      }

      if (files) {
        createAttachments({
          organizationId: organization.id,
          purchaseRequestId: purchaseRequest.id,
          attachments: files,
        });
      }

      await updateRequestInfo({
        purchaseRequestId: purchaseRequest.id,
        requestorId: chosenRequestor ? chosenRequestor.id : undefined,
        departmentId: chosenDepartment ? chosenDepartment.id : null,
        projectId: chosenProject ? chosenProject.id : null,
        costCentreId: chosenCostCentre ? chosenCostCentre.id : null,
        assigneeId: undefined, // TODO: implement assignee choice (so long should be 'undefined' instead of 'null'. Otherwise will overwrite assignee selection.)
        status,
      });
    }

    if (startedApprovalWorkflow) {
      if (purchaseRequest.latestApprovalWorkflow) {
        await updateApprovalWorkflowData({
          approvalWorkflowId: purchaseRequest.latestApprovalWorkflow.id,
          comment: null,
          approvalAmount: parseFloat(approvalAmountInput.value.toString()),
        });
      }

      // Add role APPROVER to chosenApprover in case they do not have the role
      const chosenApproverUser = chosenApprover
        ? users.find(
            (organizationUser) => organizationUser.id === chosenApprover.id,
          )
        : undefined;
      if (chosenApproverUser) {
        addRolesToUser({
          variables: {
            userId: chosenApproverUser.id,
            roles: [UserRoleEnum.APPROVER],
          },
        });
      }
    }
  };

  // submits creating requestor
  const submitCreatingRequestor = () => {
    createRequestor({ organizationId: organization.id });
  };

  // attempts to delete requested purchase request item
  const deleteItem = async (purchaseRequestItemId: string) => {
    setDeletingPurchaseRequestItemId(purchaseRequestItemId);

    try {
      await deletePurchaseRequestItem({ variables: { purchaseRequestItemId } });
    } finally {
      setDeletingPurchaseRequestItemId(undefined);
    }
  };

  const getRequestorUsers = async (value: string) =>
    users
      .filter(
        (user) =>
          matches(value, `${user.firstName} ${user.lastName}`) ||
          matches(value, user.email),
      )
      .map(userToAutosuggestItem);

  const getApproverUsers = async (value: string) =>
    users
      .filter(
        (user) =>
          user.roles.includes(UserRoleEnum.APPROVER) &&
          !currentApprovalRequests?.some(
            (approvalRequest) => approvalRequest.approver.id === user.id,
          ) &&
          (matches(value, `${user.firstName} ${user.lastName}`) ||
            matches(value, user.email)),
      )
      .map(userToAutosuggestItem);

  const suggestDepartments = async (value: string) =>
    (value.length === 0
      ? departments
      : departments.filter((department) => matches(value, department.name))
    ).map(departmentToAutosuggestItem);

  const suggestCostCentres = async (value: string) =>
    (value.length === 0
      ? costCentres
      : costCentres.filter((costCentre) => matches(value, costCentre.name))
    ).map(costCentreToAutosuggestItem);

  const suggestProjects = async (value: string) =>
    (value.length === 0
      ? projects
      : projects.filter((project) => matches(value, project.name))
    ).map(projectToAutosuggestItem);

  function renderPurchaseRequestStatus() {
    if (!purchaseRequest) {
      return null;
    }

    const cardStatus = getCardStatus(purchaseRequest);

    return (
      <LabelDropdown
        className={styles["label-dropdown"]}
        label={cardStatus.text}
        level={cardStatus.level}
        dropdownItems={[
          ...(purchaseRequest.latestApprovalWorkflow?.status ===
            ApprovalWorkflowStatusEnum.CANCELLED ||
          ((purchaseRequest.status === PurchaseRequestStatusEnum.READY ||
            purchaseRequest.status === PurchaseRequestStatusEnum.COMPLETE) &&
            !currentApprovalWorkflow)
            ? [
                {
                  id: "1",
                  icon: <EditIcon />,
                  text: "Edit",
                  onClick: async () => {
                    await updatePurchaseRequestStatus({
                      variables: {
                        purchaseRequestId: purchaseRequest.id,
                        status: PurchaseRequestStatusEnum.DRAFT,
                      },
                    });
                  },
                },
              ]
            : []),
          ...(purchaseRequest.status ===
            PurchaseRequestStatusEnum.CREATED_FROM_EMAIL ||
          purchaseRequest.status === PurchaseRequestStatusEnum.DRAFT ||
          purchaseRequest.status === PurchaseRequestStatusEnum.READY
            ? [
                {
                  id: "2",
                  icon: <ReadyIcon style={{ fill: "green" }} />,
                  text: "Mark complete",
                  onClick: async () => {
                    await completePurchaseRequest({
                      variables: {
                        purchaseRequestId: purchaseRequest.id,
                      },
                    });
                  },
                },
              ]
            : []),
          ...(purchaseRequest.status !== PurchaseRequestStatusEnum.ARCHIVED
            ? [
                {
                  id: "3",
                  icon: <ArchiveIcon className={styles["archive-icon"]} />,
                  text: "Archive",
                  onClick: async () => {
                    await archivePurchaseRequest({
                      variables: {
                        purchaseRequestId: purchaseRequest.id,
                      },
                    });
                  },
                },
              ]
            : []),
        ]}
      />
    );
  }

  return (
    <Modal
      title={`PR-${
        purchaseRequest.code
      } ${purchaseRequestNameInput.value.toString()}`}
      wide
      minimumHeight
      onCloseRequested={onModalClose}
      overlayClassName={styles["printable-overlay"]}
      className={styles.printable}
      addon={
        <div className={styles["addon-wrap"]}>
          {renderPurchaseRequestStatus()}
          <UserSelectDropdown
            large
            title={
              purchaseRequest.assignee === null
                ? "Click to assign purchase request"
                : `[${purchaseRequest.assignee.firstName} ${purchaseRequest.assignee.lastName}] Click to change card assignee`
            }
            activeUser={purchaseRequest.assignee}
            users={usersWhoCanBeAssignedToCards}
            loading={isAssigningCard}
            onChoose={(user) =>
              assignCard({
                variables: {
                  itemId: purchaseRequest.id,
                  assigneeId: user.id,
                  type: CardTypeEnum.PURCHASE_REQUEST,
                },
              })
            }
            onUnassign={() =>
              assignCard({
                variables: {
                  itemId: purchaseRequest.id,
                  assigneeId: null,
                  type: CardTypeEnum.PURCHASE_REQUEST,
                },
              })
            }
          />
        </div>
      }
      sidebarTitle="Collaboration feed"
      sidebar={
        <>
          {/* <SidebarFilter title="Show only updates" onClick={() => alertNotImplemented()} /> */}
          <CommentForm
            orgUsers={userMentionData ? userMentionData : []}
            orgSuppliers={supplierMentionData ? supplierMentionData : []}
            organizationId={organization.id}
            parentId={null}
            relativeId={purchaseRequest.id}
            relativeType={ActivityRelativeTypeEnum.PURCHASE_REQUEST}
            setIsCommentFormLoading={setIsCommentFormLoading} // TODO add isCommentFormLoading aswell?
          />
          <Separator />
          <LatestActivityProvider
            organizationId={organization.id}
            relativeId={purchaseRequest.id}
          >
            <CollaborationSidebar
              activities={
                purchaseRequest.activities ? purchaseRequest.activities : []
              }
              showActivityLoader={isCommentFormLoading}
            />
          </LatestActivityProvider>
        </>
      }
      footer={
        selectedItems.length === 0 && (
          <>
            <Form
              isEditable={
                (!startedApprovalWorkflow &&
                  !purchaseRequest.latestApprovalWorkflow &&
                  [
                    PurchaseRequestStatusEnum.CREATED_FROM_EMAIL,
                    PurchaseRequestStatusEnum.DRAFT,
                    PurchaseRequestStatusEnum.READY,
                  ].includes(purchaseRequest.status)) ||
                purchaseRequest.latestApprovalWorkflow?.status ===
                  ApprovalWorkflowStatusEnum.CANCELLED
              }
              className={styles["align-left"]}
            ></Form>

            {/* Buttons */}
            {currentApprovalWorkflow &&
              [
                ApprovalWorkflowStatusEnum.DRAFT,
                ApprovalWorkflowStatusEnum.IN_PROGRESS,
              ].includes(currentApprovalWorkflow.status) && (
                <Button
                  data-testid="85225cb02f"
                  className={styles["stop-approval-workflow-link"]}
                  secondary
                  text
                  onClick={() => {
                    resetCancellingWorkflowForm();
                    setIsCancellingCommentFormOpen(
                      !isCancellingCommentFormOpen,
                    );
                  }}
                >
                  {isCancellingCommentFormOpen
                    ? "Cancel stopping"
                    : "Stop the Workflow"}
                </Button>
              )}

            {purchaseRequest.status === PurchaseRequestStatusEnum.READY &&
              !startedApprovalWorkflow &&
              purchaseRequest.latestApprovalWorkflow?.status !==
                ApprovalWorkflowStatusEnum.APPROVED && (
                <Button
                  quaternary
                  data-testid="bba439ae42"
                  onClick={async () => {
                    await createApprovalWorkflow({
                      purchaseRequestId: purchaseRequest.id,
                    });
                    setStartedApprovalWorkflow(true);
                  }}
                >
                  Start Approval Workflow
                </Button>
              )}

            {currentApprovalWorkflow?.status ===
              ApprovalWorkflowStatusEnum.DRAFT && (
              <Button
                data-testid="1851bb5c61"
                quaternary
                loading={isUpdateApprovalWorkflowStatusLoading}
                disabled={
                  (!chosenApprover &&
                    currentApprovalWorkflow.approvalRequests.length === 0) ||
                  (chosenApprover &&
                    (!sequenceNumberInput.value ||
                      Number(sequenceNumberInput.value) <= 0)) ||
                  isCreateApprovalRequestLoading ||
                  isUpdateApprovalWorkflowDataLoading
                }
                onClick={async () => {
                  if (
                    currentApprovalWorkflow &&
                    ((chosenApprover &&
                      Number(sequenceNumberInput.value) > 0) ||
                      currentApprovalWorkflow.approvalRequests.length !== 0)
                  ) {
                    // save the form
                    await saveAction();

                    // create new approval request if there are necessary values
                    chosenApprover &&
                      Number(sequenceNumberInput.value) > 0 &&
                      (await addApproverAction());

                    // then change the status
                    await updateApprovalWorkflowStatus({
                      variables: {
                        approvalWorkflowId: currentApprovalWorkflow.id,
                        status: ApprovalWorkflowStatusEnum.IN_PROGRESS,
                        cancellingComment: null,
                      },
                    });
                  }
                }}
              >
                Send to Approval
              </Button>
            )}

            {![
              PurchaseRequestStatusEnum.READY,
              PurchaseRequestStatusEnum.IN_PROGRESS,
            ].includes(purchaseRequest.status) &&
              currentApprovalWorkflow?.status !==
                ApprovalWorkflowStatusEnum.IN_PROGRESS && (
                <>
                  <Button
                    secondary
                    data-testid="f3d76d706c"
                    onClick={async () => {
                      await draftPurchaseRequest();
                      // (removed for now) history.push(successRedirectPath.current);
                    }}
                    loading={
                      isUpdateRequestInfoLoading ||
                      isCreateApprovalWorkflowLoading ||
                      isUpdateApprovalWorkflowDataLoading ||
                      isUpdatingUserRoles ||
                      isUpdatingPR
                    }
                    disabled={
                      isUpdateRequestInfoLoading ||
                      isCreateApprovalWorkflowLoading ||
                      isUpdateApprovalWorkflowDataLoading ||
                      isUpdatingUserRoles ||
                      isUpdatingPR
                    }
                  >
                    Save
                  </Button>
                  <Button
                    onClick={async () => {
                      await readyPurchaseRequest();
                    }}
                  >
                    <ReadyIcon className={styles["ready-icon"]} />
                    Request ready
                  </Button>
                </>
              )}
          </>
        )
      }
    >
      <div className={styles["wrap"]}>
        <div className={styles["purchase-request-container"]}>
          <div className={styles["left-container"]}>
            {![
              PurchaseRequestStatusEnum.READY,
              PurchaseRequestStatusEnum.IN_PROGRESS,
            ].includes(purchaseRequest.status) ? (
              <FormProvider {...methods}>
                {prData?.viewer && (
                  <>
                    <PurchaseRequestForm
                      organization={prData.viewer.organization}
                      files={files}
                      setFiles={setFiles}
                      purchaseRequest={purchaseRequest}
                      error={updatingPRError}
                      viewer={viewer}
                      savePurchaseRequest={savePurchaseRequest}
                      isEditing
                      department={null}
                      project={null}
                      costCentre={null}
                    />
                  </>
                )}
              </FormProvider>
            ) : (
              <Accordion
                isInitiallyOpen={isRequestInfoOpen}
                title={<h3>Request info</h3>}
              >
                <Form
                  error={updateRequestInfoError}
                  isEditable={isPurchaseRequestEditable}
                >
                  <Field
                    {...purchaseRequestNameInput}
                    required
                    short
                    addon={`PR-${purchaseRequest.code}`}
                    label="Request name"
                  />
                  <Field
                    short
                    value={formatDate(purchaseRequest.createdDate)}
                    label="Request date"
                  />

                  <Field
                    {...purchaseRequestNotesInput}
                    textarea
                    short
                    label="Additional info"
                  />

                  {isPurchaseRequestEditable && (
                    <FieldLabel label="Attachments" />
                  )}
                  <div
                    className={classNames(styles["attachment-list"], {
                      [styles["attachment-list--is-not-editable"]]:
                        !isPurchaseRequestEditable,
                    })}
                  >
                    {!isPurchaseRequestEditable && (
                      <div className={styles["attachment-list--left-column"]}>
                        Attachments
                      </div>
                    )}
                    <div
                      className={classNames({
                        [styles["attachment-list--right-column"]]:
                          !isPurchaseRequestEditable,
                      })}
                    >
                      {attachments && attachments.length > 0
                        ? attachments.map((attachment) => (
                            <UploadedAttachment
                              key={attachment.id}
                              attachment={attachment}
                              isEditable={isPurchaseRequestEditable}
                              onRemove={() =>
                                removeAttachment({
                                  variables: {
                                    attachmentId: attachment.id,
                                    purchaseRequestId: purchaseRequest.id,
                                  },
                                })
                              }
                            />
                          ))
                        : !files && <NotAvailable alignLeft />}
                    </div>
                    {files && files.length > 0 && (
                      <NonUploadedFiles files={files} setFiles={setFiles} />
                    )}
                  </div>
                  {isPurchaseRequestEditable && (
                    <Attachments
                      multiple
                      loading={false}
                      onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
                    />
                  )}

                  {isPurchaseRequestEditable && <h4>Information</h4>}

                  <ItemAutosuggest
                    short
                    required
                    label="Requested by"
                    fetchSuggestions={getRequestorUsers}
                    value={requestorDisplayValue}
                    chosenSuggestion={chosenRequestor}
                    onChange={(newValue) => setRequestorDisplayValue(newValue)}
                    onChoose={(user) => setChosenRequestor(user)}
                    onCreateNew={() => {
                      setAreRequestorFieldsActive(true);
                      // get first name and last name & fill form below
                      const [requestorFirstName, requestorLastName] =
                        splitFullName(requestorDisplayValue);
                      requestorFirstNameInput.setValue(requestorFirstName);
                      requestorLastNameInput.setValue(requestorLastName);
                      setChosenRequestor(undefined);
                      setRequestorDisplayValue("");
                    }}
                  />

                  {areRequestorFieldsActive && (
                    <div>
                      <ErrorMessage error={requestorError} />
                      <div className={styles["names"]}>
                        <Field
                          {...requestorFirstNameInput}
                          short
                          label="First name"
                        />
                        <Field
                          {...requestorLastNameInput}
                          short
                          label="Last name"
                        />
                      </div>
                      <div className={styles["email"]}>
                        <Field
                          {...requestorEmailInput}
                          autofocus
                          short
                          label="Email"
                        />
                      </div>

                      <div className={styles["buttons"]}>
                        <Button
                          data-testid="82cc2ca25d"
                          secondary
                          onClick={() => {
                            setAreRequestorFieldsActive(false);
                            // TODO: set requestor field active
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          data-testid="0a40eb8653"
                          onClick={submitCreatingRequestor}
                          loading={isCreateRequestorLoading}
                          disabled={isCreateRequestorLoading}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  )}

                  <ItemAutosuggest
                    short
                    label="Which unit is this for?"
                    lockedLabel="Unit"
                    fetchSuggestions={suggestDepartments}
                    value={departmentDisplayValue}
                    chosenSuggestion={chosenDepartment}
                    onChange={(newValue) => setDepartmentDisplayValue(newValue)}
                    onChoose={(department) => setChosenDepartment(department)}
                    hasPermissionToCreateNew={isAllowedToCreate}
                    onCreateNew={async (name) => {
                      const createDepartmentResponse = await createDepartment({
                        variables: {
                          organizationId: organization.id,
                          shippingAddressId: null,
                          invoiceAddressId: null,
                          name: name,
                          code: null,
                          domain: null,
                        },
                      });
                      const createdDepartment =
                        createDepartmentResponse.data?.createDepartment;

                      if (createdDepartment) {
                        setChosenDepartment(
                          departmentToAutosuggestItem(createdDepartment),
                        );
                        setDepartmentDisplayValue(name);
                      }
                    }}
                    loading={isCreatingDepartment}
                  />

                  <ItemAutosuggest
                    short
                    label="Which project/product is this for?"
                    lockedLabel="Project / product"
                    fetchSuggestions={suggestProjects}
                    value={projectDisplayValue}
                    chosenSuggestion={chosenProject}
                    onChange={(newValue) => setProjectDisplayValue(newValue)}
                    onChoose={(project) => setChosenProject(project)}
                    onCreateNew={async (name) => {
                      const createdProjectResponse = await createProject({
                        variables: {
                          organizationId: organization.id,
                          name: name,
                        },
                      });
                      const createdProject =
                        createdProjectResponse.data?.createProject;

                      if (createdProject) {
                        setChosenProject(
                          projectToAutosuggestItem(createdProject),
                        );
                        setProjectDisplayValue(name);
                      }
                    }}
                    loading={isCreatingProject}
                  />

                  <ItemAutosuggest
                    short
                    label="Cost centre"
                    fetchSuggestions={suggestCostCentres}
                    value={costCentreDisplayValue}
                    chosenSuggestion={chosenCostCentre}
                    onChange={(newValue) => setCostCentreDisplayValue(newValue)}
                    onChoose={(costCentre) => setChosenCostCentre(costCentre)}
                    hasPermissionToCreateNew={isAllowedToCreate}
                    onCreateNew={async (name) => {
                      const createCostCentreResponse = await createCostCentre({
                        variables: {
                          organizationId: organization.id,
                          name: name,
                        },
                      });
                      const createdCostCentre =
                        createCostCentreResponse.data?.createCostCentre;

                      if (createdCostCentre) {
                        const item =
                          costCentreToAutosuggestItem(createdCostCentre);

                        setChosenCostCentre(item);
                        setCostCentreDisplayValue(name);
                      }
                    }}
                    loading={isCreatingCostCentre}
                  />

                  <Separator />

                  <PurchaseRequestItems
                    isEditable={
                      purchaseRequest.status ===
                        PurchaseRequestStatusEnum.DRAFT ||
                      purchaseRequest.status ===
                        PurchaseRequestStatusEnum.CREATED_FROM_EMAIL
                    }
                    items={purchaseRequest.items}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    loadingItemId={deletingPurchaseRequestItemId}
                    showItemProgress
                    approvalWorkFlowStatus={currentApprovalWorkflow?.status}
                    showCtaButtons={
                      [
                        PurchaseRequestStatusEnum.READY,
                        PurchaseRequestStatusEnum.IN_PROGRESS,
                      ].includes(purchaseRequest.status) &&
                      (!currentApprovalWorkflow ||
                        currentApprovalWorkflow.status ===
                          ApprovalWorkflowStatusEnum.APPROVED) &&
                      selectedItems.length > 0
                    }
                    showSelectAndSortButtons={
                      ![
                        PurchaseRequestStatusEnum.IN_PROGRESS,
                        PurchaseRequestStatusEnum.DISCARDED,
                        PurchaseRequestStatusEnum.ARCHIVED,
                      ].includes(purchaseRequest.status)
                    }
                    rfxDrafts={rfxDrafts}
                    orderDrafts={orderDrafts}
                    toUploadExcelItems={() =>
                      history.push(
                        `/${organization.urlName}/upload-items-excel/PR-${purchaseRequest.code}`,
                      )
                    }
                    onAdd={async () => {
                      // set alternate success redirect path
                      successRedirectPath.current = `/${organization.urlName}/create-product/PR-${purchaseRequest.code}`;

                      // submit current form data before navigating to a new page (triggers redirect to path set above)
                      await saveAction();

                      history.push(successRedirectPath.current);
                    }}
                    onEdit={(itemId, itemType) => {
                      itemType === "PRODUCT"
                        ? history.push(
                            `/${organization.urlName}/edit-product/PR-${code}/item-${itemId}`,
                          )
                        : history.push(
                            `/${organization.urlName}/edit-service/PR-${code}/item-${itemId}`,
                          );
                    }}
                    onDelete={(purchaseRequestItemId) =>
                      deleteItem(purchaseRequestItemId)
                    }
                    createRfxFromItems={() =>
                      createRfxFromItems({
                        variables: {
                          purchaseRequestItems: selectedItems.map(
                            (item) => item.id,
                          ),
                        },
                      })
                    }
                    createOrderFromItems={() =>
                      createOrderFromItems({
                        variables: {
                          purchaseRequestItems: selectedItems.map(
                            (item) => item.id,
                          ),
                        },
                      })
                    }
                    isMovingItemsToRfx={
                      isCreatingRfxFromItems || isAddingItemsToRfx
                    }
                    isMovingItemsToOrder={
                      isCreatingOrderFromItems || isAddingItemsToOrder
                    }
                    addItemsToRfx={(rfxId) =>
                      addItemsToRfx({
                        variables: {
                          rfxId: rfxId,
                          purchaseRequestItems: selectedItems.map(
                            (item) => item.id,
                          ),
                        },
                      })
                    }
                    addItemsToOrder={(orderId) =>
                      addItemsToOrder({
                        variables: {
                          orderId: orderId,
                          purchaseRequestItems: selectedItems.map(
                            (item) => item.id,
                          ),
                        },
                      })
                    }
                  />
                </Form>
              </Accordion>
            )}

            {!isPurchaseRequestEditable &&
              currentApprovalWorkflow &&
              currentApprovalWorkflow.status !==
                ApprovalWorkflowStatusEnum.CANCELLED && (
                <Accordion isInitiallyOpen title={<h3>Approvals</h3>}>
                  <Form
                    error={updateRequestInfoError}
                    isEditable={
                      purchaseRequest?.latestApprovalWorkflow?.status ===
                      ApprovalWorkflowStatusEnum.DRAFT
                    }
                  >
                    <div
                      className={classNames(styles["field-wrapper"], {
                        [styles["field-wrapper--non-editable"]]:
                          purchaseRequest?.latestApprovalWorkflow?.status !==
                          ApprovalWorkflowStatusEnum.DRAFT,
                      })}
                    >
                      <Field
                        short
                        label="Approval request name"
                        addon={`AWF-${currentApprovalWorkflow.code}`}
                        {...requestNameInput}
                      />
                      <NumberInput
                        short
                        label="Approval amount"
                        addon={organizationBaseCurrency}
                        currency
                        {...approvalAmountInput}
                      />
                    </div>
                    <div>
                      {purchaseRequest?.latestApprovalWorkflow?.status ===
                      ApprovalWorkflowStatusEnum.DRAFT ? (
                        <Field
                          label="Approval type (optional)"
                          {...singleApprovalRequiredInput}
                        >
                          Single approval required
                        </Field>
                      ) : (
                        <Field
                          label="Single approval"
                          value={
                            purchaseRequest.latestApprovalWorkflow
                              ?.singleApprovalRequired
                              ? "Yes"
                              : "No"
                          }
                        >
                          Single approval required
                        </Field>
                      )}
                    </div>
                  </Form>

                  {/* Render approval requests statuses table */}
                  {currentApprovalWorkflow &&
                    [
                      ApprovalWorkflowStatusEnum.DRAFT,
                      ApprovalWorkflowStatusEnum.IN_PROGRESS,
                      ApprovalWorkflowStatusEnum.APPROVED,
                      ApprovalWorkflowStatusEnum.DECLINED,
                    ].includes(currentApprovalWorkflow.status) && (
                      <BasicTable className={styles.table}>
                        <thead>
                          <tr>
                            <Cell secondary className={styles.cell}>
                              <FieldLabel label="Approvers" />
                            </Cell>
                          </tr>
                        </thead>
                        <tbody className={styles["approver-section"]}>
                          {currentApprovalRequests &&
                            currentApprovalRequests.map((ar) => {
                              return (
                                <tr
                                  key={`${ar.approver.id}-${ar.sequenceNumber}`}
                                >
                                  <Cell
                                    secondary
                                    className={styles.cell}
                                    key={`${ar.approver.id}-${ar.sequenceNumber}`}
                                  >
                                    <Approver
                                      approver={ar.approver}
                                      key={`${ar.approver.id}-${ar.sequenceNumber}`}
                                      dueDate={
                                        ar.dueDate && formatDatetime(ar.dueDate)
                                      }
                                      name={`${ar.approver.firstName} ${ar.approver.lastName}`}
                                      status={
                                        ar.decision
                                          ? formatCapitalizeString(ar.decision)
                                          : "Waiting for approval turn"
                                      }
                                      sequenceNumber={ar.sequenceNumber}
                                      creatorComment={ar.creatorComment}
                                      approverComment={ar.approverComment}
                                      className={styles.approver}
                                      approved={
                                        ar.decision
                                          ? ApprovalRequestDecisionEnum[
                                              ar.decision
                                            ] ===
                                            ApprovalRequestDecisionEnum.APPROVED
                                          : false
                                      }
                                      declined={
                                        ar.decision
                                          ? ApprovalRequestDecisionEnum[
                                              ar.decision
                                            ] ===
                                            ApprovalRequestDecisionEnum.DECLINED
                                          : false
                                      }
                                    />
                                  </Cell>
                                  {currentApprovalWorkflow.status ===
                                    ApprovalWorkflowStatusEnum.DRAFT &&
                                    ar.decision === null && (
                                      <Cell secondary>
                                        <Button
                                          icon={
                                            <RemoveIcon
                                              onClick={() =>
                                                handleDeleteApprovalRequest(
                                                  ar.id,
                                                )
                                              }
                                            />
                                          }
                                          loading={
                                            isDeleteApprovalRequestLoading
                                          }
                                        />
                                      </Cell>
                                    )}
                                </tr>
                              );
                            })}
                        </tbody>
                      </BasicTable>
                    )}

                  {/* Render approval requests configuration form */}
                  <Form
                    error={createApprovalRequestError}
                    isEditable={
                      currentApprovalWorkflow.status ===
                      ApprovalWorkflowStatusEnum.DRAFT
                    }
                    key={`${currentApprovalRequests?.length}`}
                  >
                    {[ApprovalWorkflowStatusEnum.DRAFT].includes(
                      currentApprovalWorkflow.status,
                    ) && (
                      <BasicTable className={styles.table}>
                        <tbody className={styles["approver-section"]}>
                          <tr className={styles["row-flex"]}>
                            <Cell
                              secondary
                              className={classNames(
                                styles.cell,
                                styles["cell-flex"],
                              )}
                            >
                              <Field
                                {...sequenceNumberInput}
                                type="number"
                                label="Sequence #"
                              />
                              <Datepicker
                                label="Due date"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={60}
                                timeCaption="time"
                                dateFormat="dd/MM/yyyy HH:mm"
                                minDate={new Date()}
                                autoComplete="false"
                                {...approvalDeadlineInput}
                              />
                            </Cell>
                            <Cell
                              secondary
                              className={classNames(
                                styles.cell,
                                styles["cell-flex"],
                                styles["flex-grow-1"],
                              )}
                            >
                              {/* TODO: filter already chosen approvers */}
                              <ItemAutosuggest
                                required
                                className={styles.autosuggest}
                                label="Approver"
                                fetchSuggestions={getApproverUsers}
                                value={approverDisplayValue}
                                chosenSuggestion={chosenApprover}
                                onChange={(newValue) =>
                                  setApproverDisplayValue(newValue)
                                }
                                onChoose={(user) => {
                                  if (!user) {
                                    setChosenApprover(undefined);
                                    return;
                                  }
                                  setChosenApprover(user);
                                }}
                                onCreateNew={() => {
                                  setAreApproverFieldsActive(true);
                                  const [approverFirstName, approverLastName] =
                                    splitFullName(approverDisplayValue);
                                  approverFirstNameInput.setValue(
                                    approverFirstName,
                                  );
                                  approverLastNameInput.setValue(
                                    approverLastName,
                                  );
                                  setChosenApprover(undefined);
                                  setApproverDisplayValue("");
                                }}
                                loading={isCreateApproverLoading}
                              />

                              {areApproverFieldsActive && (
                                <div className={styles["add-new-approver"]}>
                                  <ErrorMessage error={approverError} />
                                  <div className={styles["names"]}>
                                    <Field
                                      {...approverFirstNameInput}
                                      className={classNames(
                                        fieldStyles["table-create-new-field"],
                                        styles["first-name"],
                                      )}
                                      short
                                      label="First name"
                                    />
                                    <Field
                                      {...approverLastNameInput}
                                      className={
                                        fieldStyles["table-create-new-field"]
                                      }
                                      short
                                      label="Last name"
                                    />
                                  </div>
                                  <div className={styles["email"]}>
                                    <Field
                                      {...approverEmailInput}
                                      autofocus
                                      className={classNames(
                                        fieldStyles["table-create-new-field"],
                                      )}
                                      label="Email"
                                    />
                                  </div>

                                  <div className={styles["buttons"]}>
                                    <Button
                                      className={styles["cancel-button"]}
                                      secondary
                                      onClick={() => {
                                        setAreApproverFieldsActive(false);
                                        // TODO: set approver field active
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        createApprover({
                                          organizationId: organization.id,
                                        });
                                      }}
                                      loading={isCreateApproverLoading}
                                      disabled={isCreateApproverLoading}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </div>
                              )}

                              <Field
                                label="Comment"
                                textarea
                                {...creatorCommentInput}
                              />
                            </Cell>
                            {/* <Cell secondary className={styles.cell}></Cell> */}
                          </tr>
                        </tbody>
                      </BasicTable>
                    )}

                    <Button
                      data-testid="10fd12317a"
                      add
                      disabled={
                        !chosenApprover || isCreateApprovalRequestLoading
                      }
                      loading={isCreateApprovalRequestLoading}
                      className={styles["add-approver-button"]}
                      onClick={addApproverAction}
                    />

                    <div className={styles.justifiedCols}>
                      <span className={styles.notificationsField}>
                        <Field
                          label="Notifications"
                          {...keepRequesterUpdatedInput}
                        >
                          Keep Requester updated about progress automatically
                        </Field>
                      </span>

                      {currentApprovalWorkflow.status ===
                        ApprovalWorkflowStatusEnum.APPROVED && (
                        <span>
                          <Badge
                            title="Approved!"
                            icon={
                              <CheckmarkIcon
                                className={styles["approved-icon"]}
                              />
                            }
                            className={styles.statusBadge}
                          />
                        </span>
                      )}

                      {currentApprovalWorkflow.status ===
                        ApprovalWorkflowStatusEnum.DECLINED && (
                        <span>
                          <Badge
                            title="Declined"
                            icon={
                              <CloseIcon className={styles["close-icon"]} />
                            }
                            className={styles.statusBadge}
                          />
                        </span>
                      )}

                      {currentApprovalWorkflow.status ===
                        ApprovalWorkflowStatusEnum.IN_PROGRESS && (
                        <span>
                          <Badge
                            title="Waiting approvals!"
                            icon={<CheckboxIcon />}
                            className={styles.statusBadge}
                          />
                        </span>
                      )}
                    </div>
                  </Form>

                  {/* Cancelling reason form */}
                  {isCancellingCommentFormOpen && (
                    <Form
                      className={
                        styles["approval-workflow-cancelling-reason-form"]
                      }
                    >
                      <h4>STOP APPROVAL PROCESS</h4>
                      <Field
                        label="Comment"
                        textarea
                        {...cancellingCommentInput}
                      />
                      <Button
                        secondary
                        warning
                        className={styles["cancelling-reason-button"]}
                        onClick={async () => {
                          if (currentApprovalWorkflow) {
                            // change the status
                            await submitCancellingWorkflow({
                              approvalWorkflowId: currentApprovalWorkflow.id,
                              status: ApprovalWorkflowStatusEnum.CANCELLED,
                              cancellingComment: cancellingCommentInput.value
                                ? cancellingCommentInput.value.toString()
                                : null,
                            });

                            setIsRequestInfoOpen(true);
                            setIsCancellingCommentFormOpen(false);
                          }
                        }}
                      >
                        STOP
                      </Button>
                    </Form>
                  )}
                </Accordion>
              )}

            {(isGetPrLoading ||
              !isInitiated ||
              isUpdateRequestInfoLoading ||
              createAttachmentsLoading ||
              isCreateApprovalWorkflowLoading ||
              isUpdateApprovalWorkflowStatusLoading ||
              loadingCancellingWorkflow ||
              isUpdatingPR ||
              isArchivePurchaseRequestLoading ||
              isCompletePurchaseRequestLoading) && <LoadingView overlay />}
          </div>
          {showCorrespondenceBar && (
            <div className={styles["right-container"]}>
              <ViewerContextProvider viewer={viewer}>
                <RequesterCorrespondenceBar
                  addressBook={addressBook}
                  purchaseRequest={purchaseRequest}
                />
              </ViewerContextProvider>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
