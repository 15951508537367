import React, { useCallback } from "react";
import { Route } from "react-router";
import classNames from "classnames";
import { Link, Redirect } from "react-router-dom";
import { View } from "../../components/View/View";
import { Container } from "../../components/Container/Container";
import { OrganizationIcon } from "../../theme/svg/OrganizationIcon";
import { ValuePair } from "../../components/ValuePair/ValuePair";
import { Dropzone, OnDropCallbackFn } from "../../components/Dropzone/Dropzone";
import { Unit } from "../../components/Unit/Unit";
import { BasicTable, Cell } from "../../components/BasicTable/BasicTable";
import { Button } from "../../components/Button/Button";
import { EditIcon } from "../../theme/svg/EditIcon";
import { RemoveIcon } from "../../theme/svg/RemoveIcon";
import { Avatar } from "../../components/Avatar/Avatar";
import { CheckmarkIcon } from "../../theme/svg/CheckmarkIcon";
import {
  useUploadOrganizationLogo,
  ViewerOrganizations,
  Viewer,
  ViewerViewer,
  useOrganizationApiKeys,
  useDeleteApiKey,
  UserRoleEnum,
} from "../../schema";
import { CompanyLogo } from "../../components/CompanyLogo/CompanyLogo";
import { useRouter } from "../../hooks/useRouter";
import { currencies } from "../../constants";
import { ExcelIcon } from "../../theme/svg/ExcelIcon";
import { LoadingView } from "../LoadingView/LoadingView";
import { ErrorView } from "../ErrorView/ErrorView";
import { formatDatetime } from "../../services/formatDatetime";
import { sliceCharacters } from "../../services/sliceCharacters";
import { UpdateOrganization } from "./UpdateOrganization";
import { CreateUser } from "./CreateUser";
import { UpdateUser } from "./UpdateUser";
import styles from "./CompanyDetailsView.module.scss";
import { UpdateSecurity } from "./UpdateSecurity";
import { CreateDepartment } from "./CreateDepartment";
import { UpdateDepartment } from "./UpdateDepartment";
import { CreateApiKey } from "./CreateApiKey";
import { UpdatePoTemplate } from "./UpdatePoTemplate";
import { CreateCostCentre } from "./CreateCostCentre";
import { UpdateCostCentre } from "./UpdateCostCentre";
import { CreateProject } from "./CreateProject";
import { UpdateProject } from "./UpdateProject";
import { CompanyDetailsDeleteConfirmation } from "./CompanyDetailsDeleteConfirmation";

export interface CompanyDetailsViewProps {
  activeOrganization: ViewerOrganizations;
  countries: Viewer["countries"];
  viewer: ViewerViewer;
}

export const CompanyDetailsView: React.FC<CompanyDetailsViewProps> = ({
  activeOrganization,
  countries,
  viewer,
}) => {
  const { history } = useRouter();

  const [uploadCompanyLogo, { loading, error }] = useUploadOrganizationLogo({
    refetchQueries: ["Viewer"],
    awaitRefetchQueries: true,
  });

  // load api key data
  const {
    data,
    loading: isFetching,
    error: fetchError,
  } = useOrganizationApiKeys({
    fetchPolicy: "network-only",
    variables: {
      organizationId: activeOrganization.id,
    },
  });

  const apiKeys = data?.viewer?.organization?.apiKeys ?? [];

  // setup deleting api key
  const [deleteApiKey, { loading: isDeleteApiKeyLoading }] = useDeleteApiKey({
    refetchQueries: ["OrganizationApiKeys"],
    awaitRefetchQueries: true,
  });

  const delApiKey = (apiKeyId: string) => {
    deleteApiKey({
      variables: { apiKeyId, organizationId: activeOrganization.id },
    });
  };

  const onDrop = useCallback<OnDropCallbackFn>(
    (acceptedFiles) => {
      if (acceptedFiles.length !== 1) {
        return;
      }

      uploadCompanyLogo({
        variables: {
          companyLogo: acceptedFiles[0],
          organizationId: activeOrganization.id,
        },
      });
    },
    [uploadCompanyLogo, activeOrganization.id],
  );

  // to view protected routes, user must have an appropriate role
  const hasCompanyDetailsAllowedRole = viewer
    ? viewer.roles.some((role) =>
        [UserRoleEnum.ADMIN, UserRoleEnum.KEY_USER].includes(role),
      )
    : false;

  // redirect to kanban
  if (!hasCompanyDetailsAllowedRole) {
    return <Redirect to={`/${activeOrganization.urlName}`} />;
  }

  // extract base currency name
  const currency = currencies.find(
    (currency) => currency.symbol === activeOrganization.baseCurrency,
  );

  //TODO: handle loading and error of activeOrganization
  const whitelistedDomains = activeOrganization.domainWhitelist
    ?.join()
    .split(",");
  // add '@' before all domains
  whitelistedDomains?.forEach(
    (value, index, array) => (array[index] = "@" + value),
  );

  if (fetchError) {
    return <ErrorView title="Could not get api key data" error={fetchError} />;
  }

  // handle loading
  if (isFetching) {
    return <LoadingView overlay />;
  }

  return (
    <>
      <View secondary>
        <h1>{activeOrganization.companyName}</h1>

        <div className={styles["section-wrap"]}>
          <div className={styles["organization-details"]}>
            <Container
              small
              title="Organization details"
              icon={<OrganizationIcon />}
              addon={
                <Link
                  data-testid="9edf28fb59"
                  to={`/company-details/${activeOrganization.urlName}/update-organization-details`}
                  className={styles.edit}
                >
                  Edit
                </Link>
              }
            >
              <ValuePair name="Address" value={activeOrganization.address} />
              <ValuePair name="City" value={activeOrganization.city} />
              <ValuePair
                name="ZIP / postal"
                value={activeOrganization.areaCode}
              />
              <ValuePair
                name="Country"
                value={activeOrganization.country.name}
              />
              <ValuePair
                name="Base currency"
                value={currency && currency.name}
              />
              <ValuePair
                name="VAT identification number"
                value={activeOrganization.vatIdentification}
              />
              <ValuePair
                name="Local business registry number"
                value={activeOrganization.businessRegistry}
              />
              <ValuePair name="LEI code" value={activeOrganization.leiCode} />
              <ValuePair
                name="D-U-N-S code"
                value={activeOrganization.dunsCode}
              />
              <ValuePair
                name="Embedded Report"
                value={
                  activeOrganization.embeddedReport
                    ? sliceCharacters(activeOrganization.embeddedReport)
                    : null
                }
              />
              <ValuePair
                name="Purchase Order Status Webhook Url"
                value={
                  activeOrganization.poStatusWebhookUrl
                    ? sliceCharacters(activeOrganization.poStatusWebhookUrl)
                    : null
                }
              />
            </Container>
            <Container
              small
              title="Security"
              addon={
                <Link
                  to={`/company-details/${activeOrganization.urlName}/update-security`}
                  className={styles.edit}
                >
                  Edit
                </Link>
              }
            >
              <Unit>
                <p className={styles["security-section-title"]}>
                  Start getting Purchase Request from email!
                </p>
                <p>
                  Please ask your IT to create procurement@yourcompany.com and
                  forward it{" "}
                  <span className={styles.email}>
                    {activeOrganization.prEmailAddress}
                  </span>
                </p>
              </Unit>
              {whitelistedDomains && (
                <>
                  <p className={styles["security-section-title"]}>
                    Purchase Request over email is enabled only for following
                    email domains:
                  </p>
                  {whitelistedDomains.map((domain) => (
                    <p key={domain}>{domain}</p>
                  ))}
                </>
              )}
            </Container>
            <Container small title="Data export">
              <Unit>
                <p className={styles["security-section-title"]}>
                  Export PR and PO data
                </p>
              </Unit>
              <a
                target="_self"
                rel="noopener noreferrer"
                href={`/api/export/all/${activeOrganization.id}`}
              >
                <Button
                  data-testid="993df2c8ab"
                  icon={<ExcelIcon />}
                  className={styles.edit}
                >
                  Excel
                </Button>
              </a>
              <Unit className={styles["margin-top--small"]}>
                <p className={styles["security-section-title"]}>
                  Export eSourcing data
                </p>
              </Unit>
              <a
                target="_self"
                rel="noopener noreferrer"
                href={`/api/export/all/esourcing/${activeOrganization.id}`}
              >
                <Button
                  data-testid="993df9fd98fd"
                  icon={<ExcelIcon />}
                  className={styles.edit}
                >
                  Excel
                </Button>
              </a>
            </Container>

            <Container
              small
              title="PO template"
              addon={
                <Link
                  data-testid="9edf28fb59"
                  to={`/company-details/${activeOrganization.urlName}/update-po-template`}
                  className={styles.edit}
                >
                  Edit
                </Link>
              }
            >
              <ValuePair
                name="Primary color"
                value={activeOrganization.modules?.PO_TEMPLATE.primaryColor}
              />
              <ValuePair
                name="Secondary color"
                value={activeOrganization.modules?.PO_TEMPLATE.secondaryColor}
              />
              <ValuePair
                name="Note"
                value={
                  activeOrganization.modules?.PO_TEMPLATE.note
                    ? sliceCharacters(
                        activeOrganization.modules.PO_TEMPLATE.note,
                      )
                    : null
                }
              />
            </Container>
          </div>

          <div className={styles["side-section"]}>
            <Container title="Organization logo">
              <Dropzone
                multiple={false}
                loading={loading}
                error={error}
                label="Profile picture"
                accept={["image/jpeg", "image/png"]}
                rejectTypeText="Please provide valid image (.jpg or .png)"
                onDrop={onDrop}
              >
                {activeOrganization.companyLogoUrl === null ? (
                  "Drag file here"
                ) : (
                  <CompanyLogo
                    url={activeOrganization.companyLogoUrl}
                    alt={activeOrganization.companyName}
                    title={activeOrganization.companyName}
                  />
                )}
              </Dropzone>
            </Container>

            <Container
              title="Our team"
              scrollBody={true}
              addon={
                <Link
                  data-testid="831cce831a"
                  to={`/company-details/${activeOrganization.urlName}/add-new-user`}
                >
                  <Button>Add new user</Button>
                </Link>
              }
            >
              <BasicTable className={styles["our-team-table"]}>
                <thead>
                  <tr>
                    <Cell quaternary compact />
                    <Cell quaternary />
                    <Cell quaternary />
                    <Cell quaternary compact className={styles["table-header"]}>
                      Requestor
                    </Cell>
                    <Cell quaternary compact className={styles["table-header"]}>
                      Approver
                    </Cell>
                    <Cell quaternary compact className={styles["table-header"]}>
                      Buyer
                    </Cell>
                    <Cell quaternary compact className={styles["table-header"]}>
                      Key-User
                    </Cell>
                    <Cell quaternary compact />
                  </tr>
                </thead>
                <tbody>
                  {activeOrganization.users.map((user) => (
                    <tr key={user.id}>
                      <Cell quaternary>
                        <Avatar large user={user} />
                      </Cell>
                      <Cell quaternary className={styles["team-member"]}>
                        {user.firstName} {user.lastName}
                      </Cell>
                      <Cell quaternary>{user.email}</Cell>
                      <Cell quaternary center>
                        <CheckmarkIcon
                          className={classNames(styles.checkmark, {
                            [styles["checkmark--checked"]]: user.roles.includes(
                              UserRoleEnum.REQUESTER,
                            ),
                          })}
                        />
                      </Cell>
                      <Cell quaternary center>
                        <CheckmarkIcon
                          className={classNames(styles.checkmark, {
                            [styles["checkmark--checked"]]: user.roles.includes(
                              UserRoleEnum.APPROVER,
                            ),
                          })}
                        />
                      </Cell>
                      <Cell quaternary center>
                        <CheckmarkIcon
                          className={classNames(styles.checkmark, {
                            [styles["checkmark--checked"]]: user.roles.includes(
                              UserRoleEnum.BUYER,
                            ),
                          })}
                        />
                      </Cell>
                      <Cell quaternary center>
                        <CheckmarkIcon
                          className={classNames(styles.checkmark, {
                            [styles["checkmark--checked"]]: user.roles.includes(
                              UserRoleEnum.KEY_USER,
                            ),
                          })}
                        />
                      </Cell>
                      <Cell quaternary>
                        <Link
                          data-testid="c5cf0c1cba"
                          to={`/company-details/${activeOrganization.urlName}/edit-user/${user.id}`}
                          className={styles.edit}
                        >
                          <Button
                            data-testid="993df2c8ab"
                            icon={<EditIcon />}
                            className={styles.edit}
                          />
                        </Link>
                      </Cell>
                    </tr>
                  ))}
                </tbody>
              </BasicTable>
            </Container>

            <Container
              title="Business Units"
              scrollBody={activeOrganization.departments.length > 0}
              addon={
                <Link
                  data-testid="887jdfaf898a"
                  to={`/company-details/${activeOrganization.urlName}/add-new-business-unit`}
                >
                  <Button>Add new Business Unit</Button>
                </Link>
              }
            >
              {activeOrganization.departments.length > 0 ? (
                <BasicTable className={styles["business-units-table"]}>
                  <thead>
                    <tr>
                      <Cell quaternary className={styles["table-header"]}>
                        Name
                      </Cell>
                      <Cell
                        quaternary
                        compact
                        className={styles["table-header"]}
                      >
                        Code
                      </Cell>
                      <Cell quaternary compact />
                      <Cell quaternary compact />
                    </tr>
                  </thead>
                  <tbody>
                    {activeOrganization.departments.map((department) => (
                      <tr key={department.id}>
                        <Cell quaternary className={styles["team-member"]}>
                          {department.name}
                        </Cell>
                        <Cell quaternary>{department.code}</Cell>
                        <Cell quaternary>
                          <Link
                            data-testid="c5cf0c1cba"
                            to={`/company-details/${activeOrganization.urlName}/edit-business-unit/${department.id}`}
                            className={styles.edit}
                          >
                            <Button
                              data-testid="993df2c8ab"
                              icon={<EditIcon />}
                              className={styles.edit}
                            />
                          </Link>
                        </Cell>
                        <Cell quaternary>
                          <Link
                            data-testid="c5cf0c1cba"
                            to={`/company-details/${activeOrganization.urlName}/delete/business-unit/${department.id}`}
                            className={styles.edit}
                          >
                            <Button
                              data-testid="1963583e0f"
                              icon={<RemoveIcon className={styles["edit"]} />}
                            />
                          </Link>
                        </Cell>
                      </tr>
                    ))}
                  </tbody>
                </BasicTable>
              ) : (
                <small>No Business Units Found</small>
              )}
            </Container>

            <Container
              title="Cost Centers"
              scrollBody={activeOrganization.costCentres.length > 0}
              addon={
                <Link
                  data-testid="887jdfaf898a"
                  to={`/company-details/${activeOrganization.urlName}/add-new-cost-center`}
                >
                  <Button>Add new Cost Center</Button>
                </Link>
              }
            >
              {activeOrganization.costCentres.length > 0 ? (
                <BasicTable className={styles["business-units-table"]}>
                  <thead>
                    <tr>
                      <Cell quaternary className={styles["table-header"]}>
                        Name
                      </Cell>
                      <Cell quaternary compact />
                      <Cell quaternary compact />
                    </tr>
                  </thead>
                  <tbody>
                    {activeOrganization.costCentres.map((costCentre) => (
                      <tr key={costCentre.id}>
                        <Cell quaternary className={styles["team-member"]}>
                          {costCentre.name}
                        </Cell>
                        <Cell quaternary>
                          <Link
                            data-testid="c5cf0c1cba"
                            to={`/company-details/${activeOrganization.urlName}/edit-cost-center/${costCentre.id}`}
                            className={styles.edit}
                          >
                            <Button
                              data-testid="993df2c8ab"
                              icon={<EditIcon />}
                              className={styles.edit}
                            />
                          </Link>
                        </Cell>
                        <Cell quaternary>
                          <Link
                            data-testid="c5cf0c1cba"
                            to={`/company-details/${activeOrganization.urlName}/delete/cost-center/${costCentre.id}`}
                            className={styles.edit}
                          >
                            <Button
                              data-testid="1963583e0f"
                              icon={<RemoveIcon className={styles["edit"]} />}
                              /*onClick={() => delCostCentre(costCentre.id)}*/
                            />
                          </Link>
                        </Cell>
                      </tr>
                    ))}
                  </tbody>
                </BasicTable>
              ) : (
                <small>No Cost Centers Found</small>
              )}
            </Container>

            <Container
              title="Projects"
              scrollBody={activeOrganization.projects.length > 0}
              addon={
                <Link
                  data-testid="887jdfaf898a"
                  to={`/company-details/${activeOrganization.urlName}/add-new-project`}
                >
                  <Button>Add new Project</Button>
                </Link>
              }
            >
              {activeOrganization.projects.length > 0 ? (
                <BasicTable className={styles["business-units-table"]}>
                  <thead>
                    <tr>
                      <Cell quaternary className={styles["table-header"]}>
                        Name
                      </Cell>
                      <Cell quaternary compact />
                      <Cell quaternary compact />
                    </tr>
                  </thead>
                  <tbody>
                    {activeOrganization.projects.map((project) => (
                      <tr key={project.id}>
                        <Cell quaternary className={styles["team-member"]}>
                          {project.name}
                        </Cell>
                        <Cell quaternary>
                          <Link
                            data-testid="c5cf0c1cba"
                            to={`/company-details/${activeOrganization.urlName}/edit-project/${project.id}`}
                            className={styles.edit}
                          >
                            <Button
                              data-testid="993df2c8ab"
                              icon={<EditIcon />}
                              className={styles.edit}
                            />
                          </Link>
                        </Cell>
                        <Cell quaternary>
                          <Link
                            data-testid="c5cf0c1cba"
                            to={`/company-details/${activeOrganization.urlName}/delete/project/${project.id}`}
                            className={styles.edit}
                          >
                            <Button
                              data-testid="1963583e0f"
                              icon={<RemoveIcon className={styles["edit"]} />}
                            />
                          </Link>
                        </Cell>
                      </tr>
                    ))}
                  </tbody>
                </BasicTable>
              ) : (
                <small>No Projects Found</small>
              )}
            </Container>

            <Container
              title="Api keys"
              addon={
                <Link
                  data-testid="831cce831a"
                  to={`/company-details/${activeOrganization.urlName}/add-new-api-key`}
                >
                  <Button>Add new Api Key</Button>
                </Link>
              }
            >
              <BasicTable className={styles["business-units-table"]}>
                <thead>
                  <tr>
                    <Cell quaternary compact className={styles["table-header"]}>
                      #
                    </Cell>
                    <Cell quaternary center className={styles["table-header"]}>
                      Key
                    </Cell>
                    <Cell quaternary center className={styles["table-header"]}>
                      Description
                    </Cell>
                    <Cell quaternary center className={styles["table-header"]}>
                      Created
                    </Cell>
                    <Cell quaternary compact />
                  </tr>
                </thead>
                <tbody>
                  {apiKeys.map((apiKey, index) => (
                    <tr key={apiKey.id}>
                      <Cell quaternary compact>
                        {index + 1}
                      </Cell>
                      <Cell quaternary center strong>
                        {apiKey.token}
                      </Cell>
                      <Cell quaternary center>
                        {apiKey.description ? apiKey.description : "-"}
                      </Cell>
                      <Cell quaternary center>
                        {formatDatetime(apiKey.createdDate)}
                      </Cell>
                      <Cell quaternary compact>
                        <Button
                          data-testid="1963583e0f"
                          icon={<RemoveIcon className={styles["edit"]} />}
                          loading={isDeleteApiKeyLoading}
                          onClick={() => delApiKey(apiKey.id)}
                        />
                      </Cell>
                    </tr>
                  ))}
                </tbody>
              </BasicTable>
            </Container>

            <Route
              path={`/company-details/${activeOrganization.urlName}/update-organization-details`}
            >
              <UpdateOrganization
                activeOrganization={activeOrganization}
                onModalClose={() =>
                  history.push(`/company-details/${activeOrganization.urlName}`)
                }
                countries={countries}
              />
            </Route>
            <Route
              path={`/company-details/${activeOrganization.urlName}/update-security`}
            >
              <UpdateSecurity
                activeOrganization={activeOrganization}
                whitelistedDomains={whitelistedDomains}
                onModalClose={() =>
                  history.push(`/company-details/${activeOrganization.urlName}`)
                }
              />
            </Route>
            <Route
              path={`/company-details/${activeOrganization.urlName}/update-po-template`}
            >
              <UpdatePoTemplate
                activeOrganization={activeOrganization}
                onModalClose={() =>
                  history.push(`/company-details/${activeOrganization.urlName}`)
                }
              />
            </Route>
            <Route
              path={`/company-details/${activeOrganization.urlName}/add-new-user`}
            >
              <CreateUser
                activeOrganization={activeOrganization}
                organizationId={activeOrganization.id}
                countries={countries}
                onModalClose={() =>
                  history.push(`/company-details/${activeOrganization.urlName}`)
                }
              />
            </Route>
            <Route
              path={`/company-details/${activeOrganization.urlName}/add-new-business-unit`}
            >
              <CreateDepartment
                organizationId={activeOrganization.id}
                countries={countries}
                onModalClose={() =>
                  history.push(`/company-details/${activeOrganization.urlName}`)
                }
              />
            </Route>
            <Route
              path={`/company-details/${activeOrganization.urlName}/add-new-cost-center`}
            >
              <CreateCostCentre
                organizationId={activeOrganization.id}
                onModalClose={() =>
                  history.push(`/company-details/${activeOrganization.urlName}`)
                }
              />
            </Route>
            <Route
              path={`/company-details/${activeOrganization.urlName}/add-new-project`}
            >
              <CreateProject
                organizationId={activeOrganization.id}
                onModalClose={() =>
                  history.push(`/company-details/${activeOrganization.urlName}`)
                }
              />
            </Route>
            <Route
              path={`/company-details/${activeOrganization.urlName}/add-new-api-key`}
            >
              <CreateApiKey
                activeOrganization={activeOrganization}
                organizationId={activeOrganization.id}
                onModalClose={() =>
                  history.push(`/company-details/${activeOrganization.urlName}`)
                }
              />
            </Route>
            {activeOrganization.users.map((user) => (
              <Route
                path={`/company-details/${activeOrganization.urlName}/edit-user/${user.id}`}
                key={user.id}
              >
                <UpdateUser
                  organizationId={activeOrganization.id}
                  userId={user.id}
                  countries={countries}
                  onModalClose={() =>
                    history.push(
                      `/company-details/${activeOrganization.urlName}`,
                    )
                  }
                />
              </Route>
            ))}
            {activeOrganization.departments.map((department) => (
              <Route
                path={`/company-details/${activeOrganization.urlName}/edit-business-unit/${department.id}`}
                key={department.id}
              >
                <UpdateDepartment
                  departmentId={department.id}
                  organizationId={activeOrganization.id}
                  countries={countries}
                  onModalClose={() =>
                    history.push(
                      `/company-details/${activeOrganization.urlName}`,
                    )
                  }
                />
              </Route>
            ))}
            {activeOrganization.costCentres.map((costCentre) => (
              <Route
                path={`/company-details/${activeOrganization.urlName}/edit-cost-center/${costCentre.id}`}
                key={costCentre.id}
              >
                <UpdateCostCentre
                  costCentreId={costCentre.id}
                  organizationId={activeOrganization.id}
                  onModalClose={() =>
                    history.push(
                      `/company-details/${activeOrganization.urlName}`,
                    )
                  }
                />
              </Route>
            ))}
            {activeOrganization.projects.map((project) => (
              <Route
                path={`/company-details/${activeOrganization.urlName}/edit-project/${project.id}`}
                key={project.id}
              >
                <UpdateProject
                  projectId={project.id}
                  organizationId={activeOrganization.id}
                  onModalClose={() =>
                    history.push(
                      `/company-details/${activeOrganization.urlName}`,
                    )
                  }
                />
              </Route>
            ))}
            <Route
              path={`/company-details/${activeOrganization.urlName}/delete/:itemName/:itemId`}
            >
              <CompanyDetailsDeleteConfirmation
                activeOrganization={activeOrganization}
                onModalClose={() =>
                  history.push(`/company-details/${activeOrganization.urlName}`)
                }
              />
            </Route>
          </div>
        </div>
      </View>
    </>
  );
};
