import React from "react";
import { useParams } from "react-router-dom";
import { Modal } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";
import {
  useDeleteCostCentre,
  useDeleteDepartment,
  useDeleteProject,
  ViewerOrganizations,
} from "../../schema";
import { LoadingView } from "../LoadingView/LoadingView";

import styles from "./CompanyDetailsView.module.scss";

interface DeleteConfirmationParams {
  itemName?: string;
  itemId?: string;
}

export interface CompanyDetailsDeleteConfirmationProps {
  activeOrganization?: ViewerOrganizations;

  onModalClose(): void;
}

export const CompanyDetailsDeleteConfirmation: React.FC<CompanyDetailsDeleteConfirmationProps> =
  ({ activeOrganization, onModalClose }) => {
    const { itemName, itemId }: DeleteConfirmationParams = useParams();

    // don't do anything if params do not exist
    if (!itemName || !itemId || !activeOrganization)
      return <LoadingView overlay />;

    const itemToDelete = findItemToDelete(activeOrganization, itemName, itemId);

    // don't do anything if deleted item is not found
    if (!itemToDelete || !itemToDelete.deleteFunction) {
      return null;
    }

    // setup deleting department
    const [deleteDepartment, { loading: isDeleteDepartmentLoading }] =
      useDeleteDepartment({
        refetchQueries: ["Viewer"],
        awaitRefetchQueries: true,
      });

    // setup deleting cost centre
    const [deleteCostCentre, { loading: isDeleteCostCentreLoading }] =
      useDeleteCostCentre({
        refetchQueries: ["Viewer"],
        awaitRefetchQueries: true,
      });

    // setup deleting project
    const [deleteProject, { loading: isDeleteProjectLoading }] =
      useDeleteProject({
        refetchQueries: ["Viewer"],
        awaitRefetchQueries: true,
      });

    return (
      <Modal
        title={`Deleting ${formatItemText(itemName)}`}
        onCloseRequested={onModalClose}
        footer={
          <>
            <Button
              data-testid="1f09ua90fcc6f"
              secondary
              onClick={onModalClose}
            >
              Cancel
            </Button>
            <Button
              reject
              data-testid="faf90a0fb6"
              loading={
                isDeleteDepartmentLoading ||
                isDeleteCostCentreLoading ||
                isDeleteProjectLoading
              }
              onClick={() => itemToDelete.deleteFunction()}
            >
              Delete
            </Button>
          </>
        }
      >
        Are you sure you want to delete <b>{itemToDelete.item.name}</b>?
        <div className={styles["delete-warning"]}>
          {formatItemText(itemName)} will also be removed from all existing
          Purchase Requests, Rfx, Orders.
        </div>
      </Modal>
    );

    function findItemToDelete(
      activeOrganization: ViewerOrganizations,
      itemName: string,
      itemId: string,
    ) {
      switch (itemName) {
        case "business-unit": {
          const item = activeOrganization.departments.find(
            (department) => department.id === itemId,
          );

          if (!item) {
            return undefined;
          }

          return {
            item,
            deleteFunction: () =>
              deleteDepartment({
                variables: {
                  departmentId: item.id,
                  organizationId: activeOrganization.id,
                },
              }),
          };
        }

        case "cost-center": {
          const item = activeOrganization.costCentres.find(
            (costCentre) => costCentre.id === itemId,
          );

          if (!item) {
            return undefined;
          }

          return {
            item,
            deleteFunction: () =>
              deleteCostCentre({
                variables: {
                  costCentreId: item.id,
                  organizationId: activeOrganization.id,
                },
              }),
          };
        }

        case "project": {
          const item = activeOrganization.projects.find(
            (project) => project.id === itemId,
          );

          if (!item) {
            return undefined;
          }

          return {
            item,
            deleteFunction: () =>
              deleteProject({
                variables: {
                  projectId: item.id,
                  organizationId: activeOrganization.id,
                },
              }),
          };
        }

        default:
          return undefined;
      }
    }
  };

function formatItemText(text: string): string {
  return text
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
