import React from "react";
import classNames from "classnames";
import styles from "./Container.module.scss";

export interface ContainerProps {
  small?: boolean;
  title?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  addon?: React.ReactNode;
  scrollBody?: boolean;
}

export const Container: React.FC<ContainerProps> = ({
  children,
  small,
  title,
  className,
  icon,
  addon,
  scrollBody,
}) => {
  return (
    <div
      className={classNames(
        styles["container-wrap"],
        { [styles["container-wrap--small"]]: small },
        className,
      )}
    >
      {(title || icon || addon) && (
        <div className={styles.header}>
          {icon && <div className={styles.icon}>{icon}</div>}
          {title && <span className={styles.title}>{title}</span>}
          {addon && <div className={styles.items}>{addon}</div>}
        </div>
      )}
      <div
        className={classNames(styles.container, {
          [styles["container-wrap--fixed"]]: scrollBody,
        })}
      >
        {children}
      </div>
    </div>
  );
};
