import React from "react";
import { Modal } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";
import { Form } from "../../components/Form/Form";
import { Field } from "../../components/Field/Field";
import { ViewerOrganizations, useCreateApiKey } from "../../schema";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { Code } from "../../components/Code/Code";
import styles from "./CreateApiKey.module.scss";

export interface CreateApiKeyProps {
  activeOrganization: ViewerOrganizations;
  organizationId: string;
  onModalClose(): void;
}

export const CreateApiKey: React.FC<CreateApiKeyProps> = ({
  activeOrganization,
  onModalClose,
}) => {
  const absoluteUrl = window.location.origin;
  // setup form
  const {
    useInput,
    loading,
    error,
    submit: createApiKey,
  } = useForm({
    mutation: useCreateApiKey,
    options: {
      refetchQueries: ["OrganizationApiKeys"],
      awaitRefetchQueries: true,
    },
    onSuccess: () => onModalClose(),
  });

  // configure inputs
  const descriptionInput = useInput({ name: "description", optional: true });

  const submit = () => {
    createApiKey({
      organizationId: activeOrganization.id,
    });
  };

  return (
    <Modal
      title="Add new Api Key"
      onCloseRequested={onModalClose}
      wide={true}
      footer={
        <>
          <Button data-testid="ce96879dab" secondary onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            data-testid="74ec0e6b8a"
            loading={loading}
            onClick={() => submit()}
          >
            Save
          </Button>
        </>
      }
    >
      <Form error={error} onSubmit={submit}>
        <Field {...descriptionInput} label="Description" />
      </Form>

      <div className={styles.instructions}>
        <p>
          Use <Code>x-api-key</Code> header for key when making the request.
        </p>

        <p className={styles.block}>
          <Code title="PR and PO api endpoint">
            GET {`${absoluteUrl}/api/export/all/${activeOrganization.id}`}
          </Code>
        </p>

        <p className={styles.block}>
          <Code title="E-sourcing api endpoint">
            GET{" "}
            {`${absoluteUrl}/api/export/all/esourcing/${activeOrganization.id}`}
          </Code>
        </p>
      </div>
    </Modal>
  );
};
