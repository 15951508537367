import {
  CardStatusLevelEnum,
  PaginatedPurchaseRequestsItems,
  PurchaseRequestItemInfoFragment,
} from "../schema";
import { CardItemInfo } from "../components/CardItem/CardItem";
import { DraggableType } from "../components/Swimlane/Swimlane";

// provide more understandable names
type PurchaseRequestItem = PaginatedPurchaseRequestsItems & {
  status: { level: CardStatusLevelEnum; text: string };
};

export function getItemInfo(
  item: PurchaseRequestItemInfoFragment | PurchaseRequestItem,
  cardId: string,
  cardCode: string,
): CardItemInfo {
  const isSplit = item.parentCode !== cardCode;

  return {
    id: `${cardId}.${item.id}`, // same item can exist in multiple swimlanes, make the id unique
    parentCode: item.parentCode,
    type: DraggableType.ITEM,
    name: item.name,
    code: isSplit ? item.parentCode : undefined,
    unit: item.unit !== null ? item.unit : undefined,
    quantity: item.quantity !== null ? item.quantity : undefined,
    receivedQuantity:
      item.receivedQuantity !== null ? item.receivedQuantity : undefined,
    totalReceivedQuantity:
      item.totalReceivedQuantity !== null
        ? item.totalReceivedQuantity
        : undefined,
    assignee: isSplit ? item.assignee : undefined,
    status: isPurchaseRequestItem(item) ? item.status : undefined,
    isDisabled: isPurchaseRequestItem(item) && isSplit,
    // attachmentCount: Math.ceil(Math.random() * 20 + 5), // TODO: use real attachment count once implemented
    // commentCount: Math.ceil(Math.random() * 20 + 5), // TODO: use real comment count once implemented
  };
}

// some extra info is only available for purchase request itemss
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPurchaseRequestItem(item: any): item is PurchaseRequestItem {
  return item.status !== undefined;
}
