import React from "react";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { Modal } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";
import { Form } from "../../components/Form/Form";
import { Field } from "../../components/Field/Field";
import {
  useOrganizationCostCentreById,
  useUpdateCostCentre,
} from "../../schema";
import { LoadingView } from "../LoadingView/LoadingView";

import styles from "./CompanyDetailsView.module.scss";

export interface UpdateCostCentreProps {
  costCentreId: string;
  organizationId: string;

  onModalClose(): void;
}

export const UpdateCostCentre: React.FC<UpdateCostCentreProps> = ({
  costCentreId,
  organizationId,
  onModalClose,
}) => {
  // setup update cost centre form
  const {
    useInput,
    loading: isSaving,
    error: saveError,
    submit: updateCostCentre,
  } = useForm({
    mutation: useUpdateCostCentre,
    onSuccess: () => onModalClose(),
  });

  // configure inputs
  const nameInput = useInput({ name: "name" });

  // load cost centre by id
  const {
    data,
    loading: isFetching,
    error: fetchError,
  } = useOrganizationCostCentreById({
    fetchPolicy: "network-only",
    variables: {
      costCentreId,
      organizationId,
    },
  });

  const costCentre = data?.viewer?.organization?.costCentreById;

  React.useEffect(() => {
    if (!costCentre) {
      return;
    }

    nameInput.setValue(costCentre.name);
  }, [costCentre]);

  // submit form
  const submit = () => {
    updateCostCentre({
      costCentreId,
      organizationId,
    });
  };

  if (fetchError) {
    return null;
  }

  // handle loading
  if (isSaving || isFetching || !costCentre) {
    return <LoadingView overlay />;
  }

  return (
    <Modal
      title="Edit Cost Center"
      onCloseRequested={onModalClose}
      footer={
        <>
          <Button data-testid="1f09ua90fcc6f" secondary onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            data-testid="faf90a0fb6"
            loading={isSaving}
            onClick={() => submit()}
          >
            Save
          </Button>
        </>
      }
    >
      <Form onSubmit={submit} className={styles["form"]}>
        <Field {...nameInput} label="Cost Center name" short />

        {saveError && (
          <div className={styles["error"]}>An error has occurred</div>
        )}
      </Form>
    </Modal>
  );
};
