import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router";
import { useLocation } from "react-router-dom";
import {
  ViewerViewer,
  UserRoleEnum,
  useNotificationsQuery,
  useNotificationSubscription,
} from "../../schema";
import { KanbanView } from "../KanbanView/KanbanView";
import { NotFoundView } from "../NotFoundView/NotFoundView";
import { Header } from "../../components/Header/Header";
import { Menu } from "../../components/Menu/Menu";
import { ProfileDropdown } from "../../components/ProfileDropdown/ProfileDropdown";
import { OrganizationChoice } from "../../components/OrganizationChoice/OrganizationChoice";
import {
  FilterBlockButton,
  MenuFilter,
} from "../../components/MenuFilter/MenuFilter";
import { DropdownItem } from "../../components/Dropdown/Dropdown";
import { MainMenuFilters } from "../../components/MainMenuFilters/MainMenuFilters";
import { FilterIcon } from "../../theme/svg/FilterIcon";
import { SuppliersAndContractsListView } from "../SuppliersAndContractsView/SuppliersAndContractsListView";
import { AnalyticsAndKpiView } from "../AnalyticsAndKpiView/AnalyticsAndKpiView";
import { Button } from "../../components/Button/Button";
import { AdminView } from "../AdminView/AdminView";
import { SuppliersAndContractsDetailView } from "../SuppliersAndContractsView/SuppliersAndContractsDetailView";
import { WithoutOrganizationView } from "../WithoutOrganizationView/WithoutOrganizationView";
import { UserProfileView } from "../UserProfileView/UserProfileView";
import { ApprovalsView } from "../ApprovalsView/ApprovalsView";
import { useActiveOrganization } from "../../hooks/useActiveOrganization";
import { LatestActivityProvider } from "../../contexts/latest-activity-context";
import { useRouter } from "../../hooks/useRouter";
import { CreateSuppliersExcel } from "../SuppliersAndContractsView/CreateSuppliersExcel";
import { ForbiddenView } from "../ForbiddenView/ForbiddenView";
import { SourcingEventsView } from "../SourcingEventsView/SourcingEventsView";
import { getNotificationInfo } from "../../services/getNotificationInfo";
import { NotificationDropdown } from "../../components/NotificationDropdown/NotificationDropdown";
import { ReportingView } from "../ReportingView/ReportingView";
import { CompanyDetailsView } from "../CompanyDetailsView/CompanyDetailsView";
import styles from "./MemberView.module.scss";

export interface MemberViewProps {
  viewer: ViewerViewer;
}

export interface FilterDropdownsOpen {
  assignee: boolean;
  project: boolean;
  supplier: boolean;
  department: boolean;
}

export interface SelectedFilters {
  assignee?: AssigneeForFilter;
  project?: ProjectForFilter;
  supplier?: SupplierForFilter;
  department?: DepartmentForFilter;
  dateRange?: DateRangeForFilter;
}

export interface AssigneeForFilter {
  id: string;
  firstName: string;
  lastName: string;
}

export interface ProjectForFilter {
  id: string;
  name: string;
}

export interface SupplierForFilter {
  id: string;
  name?: string;
  email?: string;
}

export interface DepartmentForFilter {
  id: string;
  name: string;
}

export interface DateRangeForFilter {
  startDate: Date | string | null;
  endDate: Date | string | null;
}

export const MemberView: React.FC<MemberViewProps> = ({ viewer }) => {
  const { history } = useRouter();
  const activeOrganization = useActiveOrganization(viewer.organizations);
  const {
    data: notificationsData,
    loading: isLoadingNotifications,
    error: notificationsError,
    refetch: notificationsRefetch,
  } = useNotificationsQuery({
    variables: {
      organizatonId: activeOrganization ? activeOrganization.id : "",
      skipSeen: false,
    },
    skip: !activeOrganization,
  });

  // subscribe to notifications
  const { data: notificationSubscriptionData } = useNotificationSubscription({
    variables: {
      organizationId: activeOrganization ? activeOrganization.id : "",
    },
    skip: !notificationsData || !activeOrganization,
  });

  // refetch notifications when user gets new notification
  React.useEffect(() => {
    const newUserNotification =
      notificationSubscriptionData?.notification.userId === viewer.id;

    async function refetchNotifications() {
      if (typeof notificationsRefetch === "function") {
        await notificationsRefetch();
      }
    }

    if (newUserNotification) {
      refetchNotifications();
    }
  }, [notificationSubscriptionData]);

  // setup state
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>();
  const [isSubFilterBlockOpen, setIsSubFilterBlockOpen] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);

  const [filterDropdownsOpen, setFilterDropdownsOpen] =
    useState<FilterDropdownsOpen>({
      assignee: false,
      project: false,
      supplier: false,
      department: false,
    });

  // get current pathname
  const location = useLocation();

  // set filter state active/inactive
  useEffect(() => {
    if (!selectedFilters) {
      setIsFilterActive(false);
      return;
    }

    if (
      !selectedFilters.assignee &&
      !selectedFilters.project &&
      !selectedFilters.supplier &&
      !selectedFilters.department &&
      !selectedFilters.dateRange
    ) {
      setIsFilterActive(false);
      return;
    }

    setIsFilterActive(true);
  }, [selectedFilters]);

  const toggleSubFilterBlock = () => {
    setIsSubFilterBlockOpen(!isSubFilterBlockOpen);
  };

  // Redirect suppliers to supplier portal
  const userIsSupplier = viewer.roles.some((role) =>
    [UserRoleEnum.SUPPLIER].includes(role),
  );

  if (userIsSupplier) {
    return <Redirect to={"/esourcing"} />;
  }

  if (!activeOrganization) {
    return <WithoutOrganizationView viewer={viewer} />;
  }

  // transform notification items
  const notifications = (
    notificationsData?.viewer
      ? notificationsData.viewer.organization.notifications
      : []
  ).map((notification) =>
    getNotificationInfo(activeOrganization.urlName, notification),
  );

  // to view protected routes, user must have an appropriate role
  const hasKanbanAllowedRole = viewer
    ? viewer.roles.some((role) =>
        [
          UserRoleEnum.ADMIN,
          UserRoleEnum.BUYER,
          UserRoleEnum.KEY_USER,
        ].includes(role),
      )
    : false;

  const hasKeyUserRole = viewer.roles.some((role) =>
    [UserRoleEnum.ADMIN, UserRoleEnum.KEY_USER].includes(role),
  );
  const hasApproverRole = viewer.roles.some((role) =>
    [UserRoleEnum.ADMIN, UserRoleEnum.KEY_USER, UserRoleEnum.APPROVER].includes(
      role,
    ),
  );
  const hasRequesterRole = viewer.roles.some((role) =>
    [
      UserRoleEnum.ADMIN,
      UserRoleEnum.KEY_USER,
      UserRoleEnum.REQUESTER,
    ].includes(role),
  );

  const userIsInKanban =
    history.location.pathname.substr(1) === activeOrganization?.urlName;

  if (!hasKanbanAllowedRole) {
    if (hasRequesterRole) {
      return (
        <Redirect to={`/${activeOrganization.urlName}/purchase-requests`} />
      );
    } else if (hasApproverRole) {
      return (
        <Redirect to={`/${activeOrganization.urlName}/approval-requests`} />
      );
    } else {
      return <ForbiddenView viewer={viewer} />;
    }
  }

  return (
    <div className={styles.main}>
      {viewer.roles.some((role) =>
        [
          UserRoleEnum.ADMIN,
          UserRoleEnum.KEY_USER,
          UserRoleEnum.BUYER,
        ].includes(role),
      ) && (
        <>
          <Header organization={activeOrganization}>
            <NotificationDropdown
              loading={isLoadingNotifications}
              notifications={notifications}
              error={notificationsError}
              activeOrganization={activeOrganization}
            />
            <OrganizationChoice
              organizations={viewer.organizations}
              activeOrganization={activeOrganization}
            />
            <ProfileDropdown
              viewer={viewer}
              user={viewer}
              linkToMyInfo={"/my-info"}
              linkToCompanyDetails={
                activeOrganization && hasKeyUserRole
                  ? `/company-details/${activeOrganization.urlName}`
                  : undefined
              }
              linkToTeamBoard={
                hasKanbanAllowedRole && !userIsInKanban
                  ? `/${activeOrganization.urlName}`
                  : undefined
              }
              linkToApproverPortal={
                hasApproverRole
                  ? `/${activeOrganization.urlName}/approval-requests`
                  : undefined
              }
              linkToRequesterPortal={
                hasRequesterRole
                  ? `/${activeOrganization.urlName}/purchase-requests`
                  : undefined
              }
              linkToHelpCenter={
                hasKanbanAllowedRole
                  ? "https://www.procurementflow.com/helpcenter"
                  : undefined
              }
            />
          </Header>
          <Menu
            viewer={viewer}
            activeOrganization={activeOrganization}
            selectedFilters={selectedFilters}
            subMenu={
              <MainMenuFilters
                isOpen={isSubFilterBlockOpen}
                activeOrganization={activeOrganization}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                filterDropdownsOpen={filterDropdownsOpen}
                setFilterDropdownsOpen={setFilterDropdownsOpen}
              />
            }
          >
            {location.pathname === `/${activeOrganization?.urlName}` && (
              <>
                <MenuFilter
                  right
                  title={
                    selectedFilters?.assignee
                      ? selectedFilters.assignee.firstName +
                        " " +
                        selectedFilters.assignee.lastName
                      : "All users"
                  }
                  className={styles["users-filter"]}
                  filterDropdownsOpen={filterDropdownsOpen}
                  setFilterDropdownsOpen={setFilterDropdownsOpen}
                >
                  <DropdownItem
                    onClick={() => {
                      selectedFilters
                        ? setSelectedFilters(
                            Object.assign({}, selectedFilters, {
                              assignee: undefined,
                            }),
                          )
                        : setSelectedFilters({ assignee: undefined });
                      setFilterDropdownsOpen(
                        Object.assign({}, filterDropdownsOpen, {
                          assignee: false,
                        }),
                      );
                    }}
                  >
                    <Button data-testid="4a0ca222f9" text>
                      All users
                    </Button>
                  </DropdownItem>
                  {activeOrganization.users &&
                    activeOrganization.users.map((user) => {
                      return (
                        <DropdownItem
                          key={user.id}
                          onClick={() => {
                            selectedFilters
                              ? setSelectedFilters(
                                  Object.assign({}, selectedFilters, {
                                    assignee: {
                                      id: user.id,
                                      firstName: user.firstName,
                                      lastName: user.lastName,
                                    },
                                  }),
                                )
                              : setSelectedFilters({
                                  assignee: {
                                    id: user.id,
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                  },
                                });
                            setFilterDropdownsOpen(
                              Object.assign({}, filterDropdownsOpen, {
                                assignee: false,
                              }),
                            );
                          }}
                        >
                          <Button data-testid="f8a65d6bdc" text>
                            {user.firstName} {user.lastName}
                          </Button>
                        </DropdownItem>
                      );
                    })}
                </MenuFilter>
                <FilterBlockButton
                  addon={<FilterIcon />}
                  onClick={() => toggleSubFilterBlock()}
                  isOpen={isSubFilterBlockOpen}
                  isFilterActive={isFilterActive}
                >
                  Filters
                </FilterBlockButton>
              </>
            )}
          </Menu>
        </>
      )}

      <Switch>
        <Route exact path={`/approval-workflows/${activeOrganization.urlName}`}>
          <ApprovalsView organization={activeOrganization} />
        </Route>
        <Route exact path={`/analytics-and-kpi/${activeOrganization.urlName}`}>
          <AnalyticsAndKpiView organization={activeOrganization} />
        </Route>
        <Route exact path={`/reporting/${activeOrganization.urlName}`}>
          <ReportingView organization={activeOrganization} />
        </Route>
        <Route path="/admin">
          <AdminView viewer={viewer} />
        </Route>
        <Route exact path="/">
          <Redirect to={`/${activeOrganization.urlName}`} />
        </Route>
        {viewer.organizations.map((organization) => [
          <Route
            key={`${organization.id}.create`}
            exact
            path={[
              `/suppliers-and-contracts/${organization.urlName}`,
              `/suppliers-and-contracts/${organization.urlName}/create-new-supplier`,
            ]}
          >
            <SuppliersAndContractsListView
              organization={organization}
              viewer={viewer}
            />
          </Route>,
          <Route
            key={`${organization.id}.create`}
            exact
            path={[
              `/sourcing-events/${organization.urlName}`,
              `/sourcing-events/${organization.urlName}/start-new-sourcing-event`,
            ]}
          >
            <SourcingEventsView organization={organization} />
          </Route>,
          <Route
            key={`${organization.id}.import`}
            exact
            path={`/suppliers-and-contracts/${organization.urlName}/import-suppliers`}
          >
            <CreateSuppliersExcel
              organization={organization}
              viewer={viewer}
              onModalClose={() =>
                history.replace(
                  `/suppliers-and-contracts/${organization.urlName}`,
                )
              }
            />
          </Route>,
          <Route
            key={`${organization.id}.detail`}
            path={`/suppliers-and-contracts/${organization.urlName}/:supplierId`}
          >
            <SuppliersAndContractsDetailView organization={organization} />
          </Route>,
          <Route
            key={`${organization.id}.kanban`}
            path={`/${organization.urlName}`}
          >
            <LatestActivityProvider
              organizationId={organization.id}
              relativeId={null}
            >
              <KanbanView
                viewer={viewer}
                organization={organization}
                selectedFilters={selectedFilters}
              />
            </LatestActivityProvider>
          </Route>,
        ])}
        <Route
          exact
          path={[
            `/company-details/${activeOrganization.urlName}`,
            `/company-details/${activeOrganization.urlName}/update-organization-details`,
            `/company-details/${activeOrganization.urlName}/update-security`,
            `/company-details/${activeOrganization.urlName}/update-po-template`,
            `/company-details/${activeOrganization.urlName}/update-embedded-report`,
            `/company-details/${activeOrganization.urlName}/add-new-user`,
            `/company-details/${activeOrganization.urlName}/add-new-api-key`,
            `/company-details/${activeOrganization.urlName}/add-new-business-unit`,
            `/company-details/${activeOrganization.urlName}/add-new-cost-center`,
            `/company-details/${activeOrganization.urlName}/add-new-project`,
            `/company-details/${activeOrganization.urlName}/edit-user/:userId`,
            `/company-details/${activeOrganization.urlName}/edit-business-unit/:departmentId`,
            `/company-details/${activeOrganization.urlName}/edit-cost-center/:costCentreId`,
            `/company-details/${activeOrganization.urlName}/edit-project/:projectId`,
            `/company-details/${activeOrganization.urlName}/delete/:itemName/:itemId`,
          ]}
        >
          <CompanyDetailsView
            activeOrganization={activeOrganization}
            countries={viewer.countries}
            viewer={viewer}
          />
        </Route>
        <Route path={`/my-info`}>
          <UserProfileView viewer={viewer} />
        </Route>
        <Route component={NotFoundView} />
      </Switch>
    </div>
  );
};
