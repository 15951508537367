import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../components/Button/Button";
import { CollaborationSidebar } from "../../components/CollaborationSidebar/CollaborationSidebar";
import { CommentForm } from "../../components/CommentForm/CommentForm";
import { CorrespondenceBar } from "../../components/CorrespondenceBar/CorrespondenceBar";
import { Field } from "../../components/Field/Field";
import { FieldLabel } from "../../components/FieldLabel/FieldLabel";
import { Form } from "../../components/Form/Form";
import { LabelDropdown } from "../../components/LabelDropdown/LabelDropdown";
import { Modal } from "../../components/Modal/Modal";
import { PurchaseRequestItems } from "../../components/PurchaseRequestItems/PurchaseRequestItems";
import { Separator } from "../../components/Separator/Separator";
import { UserSelectDropdown } from "../../components/UserSelectDropdown/UserSelectDropdown";
import { LatestActivityProvider } from "../../contexts/latest-activity-context";
import { ViewerContextProvider } from "../../contexts/viewer-context";
import { useRouter } from "../../hooks/useRouter";
import { useForm } from "../../lib/react-apollo-hooks-form";
import {
  CardTypeEnum,
  OrderByCodeCountries,
  OrderByCodeOrderByCode,
  UserInfoFragment,
  ViewerOrganizations,
  useAssignCard,
  useUpdateOrder,
  useDeletePurchaseRequestItem,
  SupplierInfoFragment,
  ActivityRelativeTypeEnum,
  ViewerViewer,
  useArchiveOrder,
  useUpdatePurchaseRequestItemReceivedQuantites,
  PurchaseRequestItemReceivedInput,
  useCreateOrderInvoiceFromOrder,
  useUpdateOrderType,
  OrderTypeEnum,
  CardPositionMethodEnum,
} from "../../schema";
import { checkDownloadUrlValidity } from "../../services/checkDownloadUrlValidity";
import { formatDate } from "../../services/formatDate";
import { ArchiveIcon } from "../../theme/svg/ArchiveIcon";
import { ExcelIcon } from "../../theme/svg/ExcelIcon";
import { LoadingView } from "../LoadingView/LoadingView";
import styles from "./ReceivingModal.module.scss";

export interface ReceivingProps {
  viewer: ViewerViewer;
  organization: ViewerOrganizations;
  order: OrderByCodeOrderByCode;
  users: UserInfoFragment[];
  usersWhoCanBeAssignedToCards: UserInfoFragment[];
  countries: OrderByCodeCountries[];
  suppliers: SupplierInfoFragment[];
  onModalClose(): void;
}

export type PurchaseRquestItemRefType = {
  updateReceivingQuantites: () =>
    | {
        shouldUpdate: boolean;
        itemsToUpdate: PurchaseRequestItemReceivedInput[];
      }
    | undefined;
};

export const ReceivingModal: React.FC<ReceivingProps> = ({
  viewer,
  organization,
  order,
  users,
  usersWhoCanBeAssignedToCards,
  suppliers,
  onModalClose,
}) => {
  const { history } = useRouter();
  const purchaseRequestItemsRef = useRef<PurchaseRquestItemRefType>(null);

  // keep track of which purchase request we are deleting
  const [deletingPurchaseRequestItemId, setDeletingPurchaseRequestItemId] =
    useState<string | undefined>(undefined);

  const [isCommentFormLoading, setIsCommentFormLoading] = useState(false);

  const [showCorrespondenceBar, setShowCorrespondenceBar] = useState(false);

  const [isContinueToInvoiceEnabled, setIsContinueToInvoiceEnabled] =
    useState(false);

  // setup assigning card
  const [assignCard, { loading: isAssigningCard }] = useAssignCard({
    refetchQueries: ["OrderByCode", "PaginatedReceiving"],
    awaitRefetchQueries: true,
  });

  // setup archive order
  const [archiveOrder, { loading: isArchiveOrderLoading }] = useArchiveOrder({
    refetchQueries: ["PaginatedPurchaseRequests", "PaginatedReceiving"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      onModalClose();
    },
  });

  // setup updating purchase request item quantities
  const [
    updateReceivingQuantites,
    {
      error: updateReceivingQuantitesErrors,
      loading: isUpdateReceivedQuantitiesLoading,
    },
  ] = useUpdatePurchaseRequestItemReceivedQuantites({
    refetchQueries: ["OrderByCode", "PaginatedReceiving"],
    awaitRefetchQueries: true,
  });

  // setup continue to invoice
  const [
    createOrderInvoiceFromOrder,
    {
      error: createOrderInvoiceFromOrderErrors,
      loading: isCreateOrderInvoiceFromOrderLoading,
    },
  ] = useCreateOrderInvoiceFromOrder({
    refetchQueries: ["OrderByCode", "PaginatedReceiving", "PaginatedInvoices"],
    awaitRefetchQueries: true,
    onCompleted: (createOrderInvoiceData) => {
      if (!createOrderInvoiceData.createOrderInvoiceFromOrder.id) {
        history.push(`/${organization.urlName}`);
      }

      history.push(
        `/${organization.urlName}/PO-${createOrderInvoiceData.createOrderInvoiceFromOrder.code}`,
      );
    },
  });

  // setup updating order type
  const [
    updateOrderType,
    { error: updateOrderTypeErrors, loading: isUpdateOrderTypeLoading },
  ] = useUpdateOrderType({
    refetchQueries: ["OrderByCode", "PaginatedReceiving", "PaginatedInvoices"],
    awaitRefetchQueries: true,
    onCompleted: (updateOrderTypeData) => {
      if (!updateOrderTypeData.updateOrderType.id) {
        history.push(`/${organization.urlName}`);
      }

      history.push(
        `/${organization.urlName}/PO-${updateOrderTypeData.updateOrderType.code}`,
      );
    },
  });

  // setup deleting purchase request item
  const [deletePurchaseRequestItem] = useDeletePurchaseRequestItem({
    refetchQueries: ["PurchaseRequestByCode", "PaginatedReceiving"],
    awaitRefetchQueries: true,
  });

  const {
    useInput,
    useCheckbox,
    submit: updateOrder,
    loading: isUpdateOrderLoading,
    error: updateError,
  } = useForm({
    mutation: useUpdateOrder,
    options: {
      refetchQueries: ["PaginatedReceiving"],
      awaitRefetchQueries: true,
    },
    onSuccess: () => {
      history.push(`/${organization.urlName}`);
    },
  });

  const receivingIsConfirmationReceivedCheckbox = useCheckbox({
    name: "receivingIsConfirmationReceived",
  });

  const receivingGoodsReceivedCheckbox = useCheckbox({
    name: "receivingGoodsReceived",
  });

  const receivingGoodsReceivedPartiallyCheckbox = useCheckbox({
    name: "receivingGoodsReceivedPartially",
  });

  // cheeck for items that are not yet received
  const isGoodsReceivedPartiallyCheckboxDisabled =
    order.items.find(
      (orderItem) =>
        orderItem.receivedQuantity != null &&
        orderItem.quantity !== null &&
        orderItem.receivedQuantity < orderItem.quantity,
    ) === undefined;

  const receivingIsPoCancelledCheckbox = useCheckbox({
    name: "receivingIsPoCancelled",
  });
  const receivingWarehouseCodeInput = useInput({
    name: "receivingWarehouseCode",
    optional: true,
  });
  const receivingNotesInput = useInput({
    name: "receivingNotes",
    optional: true,
  });

  const userMentionData = users.map((user) => ({
    id: user.id,
    display: `${user.firstName} ${user.lastName}`,
  }));

  const supplierMentionData = suppliers.map((supplier) => ({
    id: supplier.id,
    display: supplier.name
      ? supplier.name
      : supplier.defaultContactPerson.email
      ? supplier.defaultContactPerson.email
      : "[missing name]",
  }));

  // is continiue to invoice allowed logic
  const isContinueToInvoiceAllowed = (
    items: PurchaseRequestItemReceivedInput[],
  ) => {
    const isGoodsReceivedChecked = receivingGoodsReceivedCheckbox.value;
    const isGoodsReceivedPartiallyChecked =
      receivingGoodsReceivedPartiallyCheckbox.value;
    const isReceivingIsPoCancelledChecked =
      receivingIsPoCancelledCheckbox.value;
    const areReceivingCheckboxesUnchecked =
      !isGoodsReceivedChecked && !isGoodsReceivedPartiallyChecked;

    // check wheter there are received items
    const hasReceivedItems = items
      ? items.some((receivedItem) => receivedItem.receivedQuantity > 0)
      : false;

    // to enable continiue to invoice
    // - Goods received and Goods received partially should not both be checked at same time
    // - If goods received partially is checked it should have some received items
    // - Checkboxes should not be left unchecked
    // - PO cancelled should not be checked
    const areRequirementsMet =
      !isReceivingIsPoCancelledChecked &&
      !(isGoodsReceivedChecked && isGoodsReceivedPartiallyChecked) &&
      !(isGoodsReceivedPartiallyChecked && !hasReceivedItems) &&
      !areReceivingCheckboxesUnchecked;

    return areRequirementsMet;
  };

  // logic when quantites get changed in child component
  const handleReceivedQuantitesChanged = (
    items: PurchaseRequestItemReceivedInput[],
  ) => {
    setIsContinueToInvoiceEnabled(isContinueToInvoiceAllowed(items));
  };

  // handle continiue to invoice logic for selected checkboxes
  useEffect(() => {
    // cast to purchase request item received input type
    const orderItems = order.items as PurchaseRequestItemReceivedInput[];

    setIsContinueToInvoiceEnabled(isContinueToInvoiceAllowed(orderItems));
  }, [
    receivingGoodsReceivedCheckbox.value,
    receivingGoodsReceivedPartiallyCheckbox.value,
    receivingIsPoCancelledCheckbox.value,
    order.items,
  ]);

  useEffect(() => {
    if (!order) {
      return;
    }

    receivingIsConfirmationReceivedCheckbox.setValue(
      order.receivingIsConfirmationReceived,
    );

    receivingGoodsReceivedCheckbox.setValue(order.receivingGoodsReceived);

    receivingGoodsReceivedPartiallyCheckbox.setValue(
      order.receivingGoodsReceivedPartially,
    );

    receivingIsPoCancelledCheckbox.setValue(order.receivingIsPoCancelled);

    if (order.receivingWarehouseCode) {
      receivingWarehouseCodeInput.setValue(order.receivingWarehouseCode);
    }

    if (order.receivingNotes) {
      receivingNotesInput.setValue(order.receivingNotes);
    }

    // show correspondence bar if there were any e-mails sent or received in preceding rfx stadium
    if (order.rfx && order.rfx.emails && order.rfx.emails.length > 0) {
      setShowCorrespondenceBar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const handleUpdateQuantities = async (areReceivingGoodsReceived: boolean) => {
    // get receiving items quantities data
    const receivingItemsData =
      purchaseRequestItemsRef.current?.updateReceivingQuantites();

    // only update quantities if changes are made
    await updateReceivingQuantites({
      variables: {
        orderId: order.id,
        organizationId: order.organizationId,
        areReceivingGoodsReceived,
        purchaseRequestReceivedItems: receivingItemsData?.itemsToUpdate
          ? receivingItemsData.itemsToUpdate
          : null,
      },
    });
  };

  //submits updating order and updating receiving quantities
  const submit = async () => {
    // attempt to update received item quantites
    await handleUpdateQuantities(false);

    // update order
    updateOrder({
      orderId: order.id,
      supplierId: order.supplierId,
      name: order.name,
      isConfirmationRequired: order.isConfirmationRequired,
      expectedConfirmationDate: order.expectedConfirmationDate,
      externalIdentifier: order.externalIdentifier,
      expectedDeliveryDate: order.expectedDeliveryDate,
      paymentTerm: order.paymentTerm,
      purchaseOrderDeliveryNotes: order.purchaseOrderDeliveryNotes,
      hasBeenSent: order.hasBeenSent,
      invoiceNumber: order.invoiceNumber,
      invoiceReceived: order.invoiceReceived,
      invoiceThreeWayMatch: order.invoiceThreeWayMatch,
      invoiceApproved: order.invoiceApproved,
      invoicePaid: order.invoicePaid,
    });
  };

  // submit continiue to invoice
  const submitContiniueToInvoice = async () => {
    const isGoodsReceivedChecked = receivingGoodsReceivedCheckbox.value;

    // attempt update order quantities
    await handleUpdateQuantities(isGoodsReceivedChecked);

    // if goods received is checked and continue to invoice is submited call drag mutation instead
    if (isGoodsReceivedChecked) {
      await updateOrderType({
        variables: {
          orderId: order.id,
          type: OrderTypeEnum.INVOICE,
          relativeOrderId: null,
          method: CardPositionMethodEnum.BEFORE,
          positionWithoutRelativeOrder: true,
        },
      });

      return;
    }

    // create invoice order
    await createOrderInvoiceFromOrder({
      variables: {
        orderId: order.id,
      },
    });
  };

  // attempts to delete requested purchase request item
  const deleteItem = async (purchaseRequestItemId: string) => {
    setDeletingPurchaseRequestItemId(purchaseRequestItemId);

    try {
      await deletePurchaseRequestItem({ variables: { purchaseRequestItemId } });
    } finally {
      setDeletingPurchaseRequestItemId(undefined);
    }
  };

  // handle loading (also avoid form content flicker)
  if (!order) {
    return <LoadingView overlay />;
  }

  return (
    <Modal
      wide
      title={`PO-${order.code} ${order.name} ${
        order.externalIdentifier ? order.externalIdentifier : ""
      }`}
      className={styles["modal"]}
      onCloseRequested={onModalClose}
      addon={
        <div className={styles["addon-wrap"]}>
          <LabelDropdown
            className={styles["label-dropdown"]}
            label={order.cardStatus.text}
            level={order.cardStatus.level}
            dropdownItems={[
              ...(order.deletedDate === null
                ? [
                    {
                      id: "1",
                      icon: <ArchiveIcon className={styles["archive-icon"]} />,
                      text: "Archive",
                      onClick: async () => {
                        await archiveOrder({
                          variables: {
                            orderId: order.id,
                          },
                        });
                      },
                    },
                  ]
                : []),
              {
                id: "2",
                icon: <ExcelIcon />,
                text: "Download",
                onClick: async () => {
                  return checkDownloadUrlValidity(
                    `/api/export/order/${order.id}`,
                  );
                },
              },
            ]}
          />
          <UserSelectDropdown
            large
            title={
              order.assignee === null
                ? "Click to assign card"
                : `[${order.assignee.firstName} ${order.assignee.lastName}] Click to change card assignee`
            }
            activeUser={order.assignee}
            users={usersWhoCanBeAssignedToCards}
            loading={isAssigningCard}
            onChoose={(user) => {
              assignCard({
                variables: {
                  itemId: order.id,
                  assigneeId: user.id,
                  type: CardTypeEnum.ORDER,
                },
              });
            }}
            onUnassign={() => {
              assignCard({
                variables: {
                  itemId: order.id,
                  assigneeId: null,
                  type: CardTypeEnum.ORDER,
                },
              });
            }}
          />
        </div>
      }
      sidebarTitle="Collaboration feed"
      sidebar={
        <>
          {/* <SidebarFilter title="Show only Updates" onClick={() => alertNotImplemented()} /> */}
          <CommentForm
            orgUsers={userMentionData ? userMentionData : []}
            orgSuppliers={supplierMentionData ? supplierMentionData : []}
            organizationId={organization.id}
            parentId={null}
            relativeId={order.id}
            relativeType={ActivityRelativeTypeEnum.ORDER}
            setIsCommentFormLoading={setIsCommentFormLoading} // TODO add isCommentFormLoading aswell?
          />
          <Separator />
          <LatestActivityProvider
            organizationId={organization.id}
            relativeId={order.id}
          >
            <CollaborationSidebar
              activities={order.activities}
              showActivityLoader={isCommentFormLoading}
            />
          </LatestActivityProvider>
        </>
      }
      footer={
        <>
          <Button
            data-testid="e725accc68"
            secondary
            onClick={() => history.push(`/${organization.urlName}`)}
          >
            Cancel
          </Button>
          <Button
            quaternary
            onClick={submitContiniueToInvoice}
            loading={isCreateOrderInvoiceFromOrderLoading}
            disabled={
              isUpdateOrderLoading ||
              isUpdateReceivedQuantitiesLoading ||
              isCreateOrderInvoiceFromOrderLoading ||
              isUpdateOrderTypeLoading ||
              !isContinueToInvoiceEnabled
            }
          >
            Continue to Invoice
          </Button>
          <Button
            data-testid="9eefb52149"
            onClick={submit}
            loading={isUpdateOrderLoading || isUpdateReceivedQuantitiesLoading}
            disabled={
              isUpdateOrderLoading ||
              isUpdateReceivedQuantitiesLoading ||
              isUpdateOrderTypeLoading ||
              isCreateOrderInvoiceFromOrderLoading
            }
          >
            Save
          </Button>
        </>
      }
    >
      <div className={styles["wrap"]}>
        <div className={styles["receiving-container"]}>
          <div className={styles["left-container"]}>
            <Form
              onSubmit={submit}
              error={
                updateError ||
                updateReceivingQuantitesErrors ||
                updateOrderTypeErrors ||
                createOrderInvoiceFromOrderErrors
              }
            >
              {order.purchaseOrderRenewalDate && (
                <FieldLabel label="Purchase order renewal">
                  PO Renewal Date:
                  <span className={styles["confirmation-date"]}>
                    {formatDate(order.purchaseOrderRenewalDate)}
                  </span>
                </FieldLabel>
              )}
              <FieldLabel label="Order confirmation">
                {order.isConfirmationRequired && (
                  <div className={styles.label}>
                    Confirmation expected by:
                    <span className={styles["confirmation-date"]}>
                      {formatDate(order.expectedConfirmationDate)}
                    </span>
                  </div>
                )}

                <Field {...receivingIsConfirmationReceivedCheckbox}>
                  Confirmation received
                </Field>
              </FieldLabel>
              <Separator />
              <h4>Goods status</h4>
              <Field {...receivingGoodsReceivedCheckbox}>Goods received</Field>
              <Field
                {...receivingGoodsReceivedPartiallyCheckbox}
                disabled={isGoodsReceivedPartiallyCheckboxDisabled}
              >
                Goods received partially
              </Field>
              <Field {...receivingIsPoCancelledCheckbox}>PO cancelled</Field>
              <Separator />
              <Field
                {...receivingWarehouseCodeInput}
                label="Receiving warehouse code"
                short
              ></Field>
              <Field
                {...receivingNotesInput}
                label="Receiving notes"
                textarea
              ></Field>
              <Separator />
              <PurchaseRequestItems
                ref={purchaseRequestItemsRef}
                items={order.items}
                loadingItemId={deletingPurchaseRequestItemId}
                showReceivingColumns={true}
                isReceivingQuantityEditable={
                  receivingGoodsReceivedPartiallyCheckbox.value
                }
                onRequestLinkClick={(requestCode) =>
                  history.push(`/${organization.urlName}/${requestCode}`)
                }
                onReceivingQuantityValuesChanged={
                  handleReceivedQuantitesChanged
                }
              />
            </Form>
          </div>
        </div>
        {isArchiveOrderLoading && <LoadingView overlay />}
      </div>
    </Modal>
  );
};
