import React from "react";
import { Modal } from "../../components/Modal/Modal";
import { Button } from "../../components/Button/Button";
import { Form } from "../../components/Form/Form";
import { Field } from "../../components/Field/Field";
import { useCreateCostCentre } from "../../schema";
import { useForm } from "../../lib/react-apollo-hooks-form";
import { notEmpty } from "../../validators/notEmpty";
import { ErrorView } from "../ErrorView/ErrorView";
import styles from "./CompanyDetailsView.module.scss";

export interface CreateCostCentreProps {
  organizationId: string;

  onModalClose(): void;
}

export const CreateCostCentre: React.FC<CreateCostCentreProps> = ({
  organizationId,
  onModalClose,
}) => {
  // setup create cost centre form
  const {
    useInput,
    loading,
    error,
    submit: createCostCentre,
  } = useForm({
    mutation: useCreateCostCentre,
    options: {
      refetchQueries: ["Viewer"],
      awaitRefetchQueries: true,
    },
    onSuccess: () => onModalClose(),
  });

  // configure inputs
  const nameInput = useInput({
    name: "name",
    validators: [notEmpty],
  });

  const submit = () => {
    createCostCentre({
      organizationId,
    });
  };

  // handle save errors
  if (error) {
    return (
      <ErrorView
        title="Saving Cost Center failed"
        error={error}
        modal={{ title: "Create Cost Center", onModalClose }}
      />
    );
  }

  return (
    <Modal
      title="Add new Cost Center"
      onCloseRequested={onModalClose}
      footer={
        <>
          <Button data-testid="cefjaofa888b" secondary onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            data-testid="74ecfjaof9a"
            loading={loading}
            onClick={() => submit()}
          >
            Save
          </Button>
        </>
      }
    >
      <Form error={error} onSubmit={submit} className={styles["form"]}>
        <div>
          <Field {...nameInput} label="Name" short />
        </div>
      </Form>
    </Modal>
  );
};
