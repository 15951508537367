import React from "react";
import classNames from "classnames";
import { Droppable } from "react-beautiful-dnd";
import InfiniteScroll from "react-infinite-scroll-component";
import { Card, CardInfo } from "../Card/Card";
import { AvatarUser } from "../Avatar/Avatar";
import { Loading } from "../Loading/Loading";
import { SwimlaneId } from "../../constants";
import styles from "./Swimlane.module.scss";

// we can drag either cards or items within them
export enum DraggableType {
  CARD = "CARD",
  ITEM = "ITEM",
}

// base interface for items that we can drag
export interface BaseDraggable {
  id: string;
  type: DraggableType;
}

// base interface for drop targets
export interface BaseDroppable {
  id: string;
  type: DraggableType;
  items: BaseDraggable[];
}

// export callback typings
export type OnSelectCard = (swimlaneId: string, cardInfo: CardInfo) => void;
export type OnAssignCard = (
  swimlaneId: string,
  cardInfo: CardInfo,
  user: AvatarUser,
) => void;
export type OnUnassignCard = (swimlaneId: string, cardInfo: CardInfo) => void;
export type OnAdd = () => void;

export interface SwimlaneInfo extends BaseDroppable {
  type: DraggableType;
  acceptItemsFrom: SwimlaneId[];
  title: string;
  emptyMessage: string;
  items: CardInfo[];
  headerColor: string;
  bodyColor: string;
  createFromItemsMessage?: string;
  large?: boolean;
  loading?: boolean;
  onSelectCard: OnSelectCard;
  onAssignCard: OnAssignCard;
  onLoadMore: () => void;
  onHasNextPage: boolean;
  onUnassignCard?: OnUnassignCard;
  onAdd?: OnAdd;
}

export interface SwimlaneProps {
  info: SwimlaneInfo;
  loading?: boolean;
  isAcceptingCreateFromItem: boolean;
  organizationId: string;
  swimlaneId: string;
  refCallback?: (element: HTMLElement) => void;
}

export const Swimlane: React.FC<SwimlaneProps> = ({
  info,
  loading,
  isAcceptingCreateFromItem,
  organizationId,
  swimlaneId,
  refCallback,
  ...rest
}) => {
  // extract swimlane info
  const {
    id,
    title,
    emptyMessage,
    createFromItemsMessage,
    items,
    headerColor,
    bodyColor,
    large,
    onAdd,
    onSelectCard,
    onAssignCard,
    onUnassignCard,
    onLoadMore,
    onHasNextPage,
  } = info;

  const onHandleLoadMore = () => {
    if (typeof onLoadMore === "function") {
      onLoadMore();
    }
  };

  return (
    <div
      className={classNames(styles.swimlane, {
        [styles["swimlane--large"]]: large,
      })}
      {...rest}
    >
      <div className={styles.header} style={{ background: `${headerColor}` }}>
        <h2 className={styles.title}>{title}</h2>
        {onAdd && (
          <button
            data-testid="e688180ced"
            type="button"
            className={styles["add-button"]}
            onClick={onAdd}
          />
        )}
        <Loading
          small
          show={loading}
          className={classNames(styles["header-loading-icon"], {
            [styles["header-loading-icon--with-add"]]: onAdd !== undefined,
          })}
        />
      </div>

      <div
        className={styles["content-wrapper"]}
        style={{ background: `${bodyColor}` }}
      >
        <Droppable droppableId={id} type={DraggableType.CARD}>
          {(
            {
              innerRef,
              droppableProps,
              placeholder,
            } /*, { isDraggingOver, draggingOverWith, draggingFromThisWith }*/,
          ) => {
            const composedRefCallbacks = (element: HTMLElement | null) => {
              innerRef(element);

              if (typeof refCallback === "function" && element) {
                refCallback(element);
              }
            };

            return (
              <>
                {createFromItemsMessage && (
                  <Droppable
                    droppableId={`${id}-new`}
                    type={DraggableType.ITEM}
                    isDropDisabled={!isAcceptingCreateFromItem}
                  >
                    {(
                      {
                        innerRef: subInnerRef,
                        droppableProps: subDroppableProps,
                        placeholder: subPlaceholder,
                      },
                      { isDraggingOver: subIsDraggingOver },
                    ) => (
                      <div
                        ref={subInnerRef}
                        {...subDroppableProps}
                        className={classNames(styles["create-new-card"], {
                          [styles["create-new-card--visible"]]:
                            isAcceptingCreateFromItem,
                          [styles["create-new-card--over"]]: subIsDraggingOver,
                        })}
                      >
                        {createFromItemsMessage}
                        {subPlaceholder}
                      </div>
                    )}
                  </Droppable>
                )}

                <div
                  id={`${id.toLowerCase()}-wrapper`}
                  className={styles.content}
                  ref={composedRefCallbacks}
                  {...droppableProps}
                >
                  <InfiniteScroll
                    dataLength={items.length}
                    next={onHandleLoadMore}
                    hasMore={onHasNextPage || false}
                    scrollableTarget={`${id.toLowerCase()}-wrapper`}
                    loader={<Loading padded />}
                  >
                    {items.map((item, index) => (
                      <Card
                        key={item.id}
                        index={index}
                        info={item}
                        organizationId={organizationId}
                        swimlaneId={swimlaneId}
                        onClick={() => onSelectCard(id, item)}
                        onAssign={(user) => onAssignCard(id, item, user)}
                        onUnassign={
                          onUnassignCard
                            ? () => onUnassignCard(id, item)
                            : undefined
                        }
                      />
                    ))}
                  </InfiniteScroll>
                  {placeholder}
                  {items.length === 0 ? (
                    <div className={styles["empty-swimlane"]}>
                      {emptyMessage}
                    </div>
                  ) : null}
                  {onAdd && (
                    <button
                      data-testid="db275d7978"
                      type="button"
                      className={classNames(
                        styles["add-button"],
                        styles["add-button--footer"],
                      )}
                      onClick={onAdd}
                    />
                  )}
                </div>
              </>
            );
          }}
        </Droppable>
      </div>
    </div>
  );
};

export const Swimlanes: React.FC = ({ children }) => (
  <div className={styles.swimlanes}>
    <div className={styles["swimlanes__inner-wrapper"]}>{children}</div>
  </div>
);

// returns droppable by id (works for both swimlanes and cards)
export function getDroppable(
  id: string,
  swimlanes: SwimlaneInfo[],
): BaseDroppable | undefined {
  // resolve droppable type
  const isLane = Object.keys(SwimlaneId).indexOf(id) !== -1;

  // attempt to find droppable by type
  if (isLane) {
    return swimlanes.find((lane) => lane.id === id);
  } else {
    // must be a card, search for it in all swimlanes
    for (const swimlane of swimlanes) {
      const card = swimlane.items.find((card) => card.id === id);

      if (card !== undefined) {
        return card;
      }
    }

    return undefined;
  }
}

// attempts to find swimlane that the card is from
export function getCardSwimlane(
  cardId: string,
  swimlanes: SwimlaneInfo[],
): SwimlaneInfo | undefined {
  return swimlanes.find(
    (swimlane) =>
      swimlane.items.find((item) => item.id === cardId) !== undefined,
  );
}
