export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: "INTERFACE",
        name: "UserInterface",
        possibleTypes: [
          {
            name: "User",
          },
          {
            name: "Viewer",
          },
        ],
      },
      {
        kind: "UNION",
        name: "ActivityFeedData",
        possibleTypes: [
          {
            name: "ADD_ATTACHMENT",
          },
          {
            name: "ADD_ITEM",
          },
          {
            name: "ADD_ITEMS",
          },
          {
            name: "ASSIGN",
          },
          {
            name: "COMMENT",
          },
          {
            name: "CREATE_APPROVAL_REQUEST",
          },
          {
            name: "CREATE_APPROVAL_WORKFLOW",
          },
          {
            name: "CREATE_INVOICE_FROM_ORDER",
          },
          {
            name: "CREATE_PURCHASE_REQUEST",
          },
          {
            name: "CREATE_PURCHASE_REQUEST_PRODUCT",
          },
          {
            name: "CREATE_PURCHASE_REQUEST_SERVICE",
          },
          {
            name: "CREATE_SUPPLIER",
          },
          {
            name: "DRAG_CARD",
          },
          {
            name: "DRAG_ITEM",
          },
          {
            name: "EMAIL_INBOUND_MENTION",
          },
          {
            name: "EMAIL_INBOUND_REQUESTER",
          },
          {
            name: "EMAIL_INBOUND_SUPPLIER",
          },
          {
            name: "EMAIL_OUTBOUND_MENTION",
          },
          {
            name: "EMAIL_OUTBOUND_SUPPLIER",
          },
          {
            name: "REPLY",
          },
          {
            name: "UPDATE_APPROVAL_REQUEST",
          },
          {
            name: "UPDATE_APPROVAL_WORKFLOW",
          },
          {
            name: "UPDATE_ORDER_RECEIVED_QUANTITIES",
          },
          {
            name: "UPDATE_SUPPLIER",
          },
        ],
      },
      {
        kind: "UNION",
        name: "ActivityRelativeInfo",
        possibleTypes: [
          {
            name: "ActivityRelativeCardInfo",
          },
          {
            name: "ActivityRelativeItemInfo",
          },
          {
            name: "ActivityRelativeSupplierInfo",
          },
        ],
      },
      {
        kind: "UNION",
        name: "Email",
        possibleTypes: [
          {
            name: "InboundEmail",
          },
          {
            name: "OutboundEmail",
          },
        ],
      },
    ],
  },
};
export default result;
