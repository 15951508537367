import { CreatePurchaseRequestFormData } from "../components/PurchaseRequestForm/PurchaseRequestForm";

export function convertPRFormDataToMutation(
  values: CreatePurchaseRequestFormData,
) {
  return {
    departmentId: values.departmentId ? values.departmentId : null,
    projectId: values.projectId ? values.projectId : null,
    costCentreId: values.costCentreId ? values.costCentreId : null,
    requestorId: values.requestorId ? values.requestorId : null,
    name: values.name,
    notes: values.notes ? values.notes : null,
    attachments: values.files ? values.files : null,
    items: values.items
      ? values.items.map((item) => ({
          id: item.id ? item.id : null,
          name: item.name,
          type: item.type,
          code: item.code ? item.code : null,
          suggestedSupplier: item.suggestedSupplier
            ? item.suggestedSupplier
            : null,
          supplierCode: item.supplierCode ? item.supplierCode : null,
          quantity: item.quantity ? parseFloat(item.quantity.toString()) : null,
          unit: item.unit ? item.unit : null,
          unitPrice: item.unitPrice
            ? parseFloat(item.unitPrice.toString())
            : null,
          currency: item.currency ? item.currency : null,
          expectedDeliveryDate: item.expectedDeliveryDate
            ? item.expectedDeliveryDate
            : null,
          productUrl: item.productUrl ? item.productUrl : null,
          additionalInfo: item.additionalInfo ? item.additionalInfo : null,
          customFields: null,
          esourcingFields: item.esourcingFields ? item.esourcingFields : null,
          attachments: item.files ? item.files : null,
        }))
      : null,
  };
}
