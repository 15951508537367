import React from "react";
import classNames from "classnames";
import useOnClickOutside from "use-onclickoutside";
import { useGlobalKeyPress } from "../../hooks/useGlobalKeyPress";
import { Overlay } from "../Overlay/Overlay";
import { Separator } from "../Separator/Separator";
import { Sidebar } from "../Sidebar/Sidebar";
import styles from "./Modal.module.scss";

export interface ModalProps {
  title: React.ReactNode;
  footer?: React.ReactNode;
  addon?: React.ReactNode;
  sidebar?: React.ReactNode;
  sidebarTitle?: string;
  wide?: boolean;
  minimumHeight?: boolean;
  className?: string;
  overlayClassName?: string;
  headerClassName?: string;
  onCloseRequested(): void;
}

export const Modal: React.FC<ModalProps> = ({
  title,
  footer,
  addon,
  sidebar,
  sidebarTitle,
  wide,
  minimumHeight,
  children,
  className,
  overlayClassName,
  headerClassName,
  onCloseRequested,
}) => {
  // handle clicking outside the dropdown, call the callback if supplied
  const wrapRef = React.useRef<HTMLDivElement>(null);

  // request closing modal on outside click
  useOnClickOutside(wrapRef, () => {
    if (typeof onCloseRequested !== "function") {
      return;
    }

    onCloseRequested();
  });

  const ESCAPE_KEY_CODE = 27;

  // request closing modal on escape keypress
  useGlobalKeyPress(ESCAPE_KEY_CODE, onCloseRequested);

  return (
    <Overlay className={overlayClassName}>
      <div
        ref={wrapRef}
        className={classNames(
          styles.modal,
          { [styles["modal--wide"]]: wide },
          className,
        )}
      >
        <button
          data-testid="42b4baf3b0"
          onClick={onCloseRequested}
          type="button"
          className={styles["close-button"]}
        />
        <div className={classNames(styles.header, headerClassName)}>
          <h3 className={styles.title}>{title}</h3>
          {addon && <div className={styles["header-addon"]}>{addon}</div>}
        </div>
        <div className={styles["modal-inner-wrap"]}>
          <div className={classNames(styles["modal-content-wrap"], {})}>
            <div className={styles["modal-content-inner-wrap"]}>
              <div
                className={classNames(styles.content, {
                  [styles["content--no-footer"]]: footer === undefined,
                  [styles["content--minimum-height"]]: minimumHeight,
                })}
              >
                {children}
              </div>
              {footer !== undefined && (
                <>
                  <Separator zeroMarginBottom />
                  <div className={styles.footer}>{footer}</div>
                </>
              )}
            </div>
          </div>
          {sidebar && (
            <Sidebar title={sidebarTitle} openOnTop>
              {sidebar}
            </Sidebar>
          )}
        </div>
      </div>
    </Overlay>
  );
};

export const ModalFooter: React.FC = ({ children }) => {
  return <div className={styles.footer}>{children}</div>;
};
