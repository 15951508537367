/* eslint-disable */
import { DocumentNode } from "graphql";
import * as ApolloReactCommon from "@apollo/react-common";
import * as ApolloReactHooks from "@apollo/react-hooks";
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { [key: string]: any };
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type ActivityEmailInfo = {
  __typename?: "ActivityEmailInfo";
  /**
   * Email
   *  @mockType {internet.email}
   **/
  email: Scalars["String"];
  /**
   * Message
   *  @mockType {lorem.sentence}
   **/
  message: Scalars["String"];
  /**
   * Subject
   *  @mockType {commerce.productName}
   **/
  subject: Scalars["String"];
};

export type ActivityFeed = {
  __typename?: "ActivityFeed";
  /** Activity feed attachments */
  attachments: Maybe<Array<Attachment>>;
  /** Activity author */
  author: Maybe<User>;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Activity Data */
  data: ActivityFeedData;
  /** Activity Feed unique id */
  id: Scalars["ID"];
  /** Activity organization id */
  organizationId: Scalars["ID"];
  /** Activity parent id */
  parentId: Maybe<Scalars["ID"]>;
  /** Identifier of the entity that the activity record is about */
  relativeId: Scalars["ID"];
  /** Activity relative info */
  relativeInfo: Maybe<ActivityRelativeInfo>;
  /** Activity relative type */
  relativeType: ActivityRelativeTypeEnum;
  /** Activity replies */
  replies: Array<ActivityFeed>;
  /** Activity type */
  type: ActivityTypeEnum;
  /** Date of update */
  updatedDate: Scalars["Date"];
  /** Activity user id */
  userId: Maybe<Scalars["ID"]>;
};

/** Resolves for different ActivityTypes as Union Types */
export type ActivityFeedData =
  | Add_Attachment
  | Add_Item
  | Add_Items
  | Assign
  | Comment
  | Create_Approval_Request
  | Create_Approval_Workflow
  | Create_Invoice_From_Order
  | Create_Purchase_Request
  | Create_Purchase_Request_Product
  | Create_Purchase_Request_Service
  | Create_Supplier
  | Drag_Card
  | Drag_Item
  | Email_Inbound_Mention
  | Email_Inbound_Requester
  | Email_Inbound_Supplier
  | Email_Outbound_Mention
  | Email_Outbound_Supplier
  | Reply
  | Update_Approval_Request
  | Update_Approval_Workflow
  | Update_Order_Received_Quantities
  | Update_Supplier;

export type ActivityRelativeCardInfo = {
  __typename?: "ActivityRelativeCardInfo";
  /** Relative assignee id */
  assigneeId: Maybe<Scalars["ID"]>;
  /** Code */
  code: Scalars["String"];
  /** Name */
  name: Scalars["String"];
  /** Relative requestor id */
  requestorId: Maybe<Scalars["ID"]>;
  /** Url */
  url: Scalars["String"];
};

/** Resolves to different relative info types as Union Types */
export type ActivityRelativeInfo =
  | ActivityRelativeCardInfo
  | ActivityRelativeItemInfo
  | ActivityRelativeSupplierInfo;

export type ActivityRelativeItemInfo = {
  __typename?: "ActivityRelativeItemInfo";
  /** Name */
  name: Scalars["String"];
  /** Quantity */
  quantity: Maybe<Scalars["Float"]>;
  /** Unit */
  unit: Maybe<Scalars["String"]>;
};

export type ActivityRelativeOrderInfo = {
  __typename?: "ActivityRelativeOrderInfo";
  /** Order code */
  code: Scalars["String"];
  /** Order unique id */
  id: Scalars["ID"];
  /** Order type */
  type: Maybe<OrderTypeEnum>;
};

export type ActivityRelativeRfxInfo = {
  __typename?: "ActivityRelativeRfxInfo";
  /** Rfx code */
  code: Scalars["String"];
  /** Rfx unique id */
  id: Scalars["ID"];
  /** Rfx type */
  type: Maybe<RfxTypeEnum>;
};

export type ActivityRelativeSupplierInfo = {
  __typename?: "ActivityRelativeSupplierInfo";
  /** Email */
  email: Maybe<Scalars["String"]>;
  /** Supplier ID */
  id: Maybe<Scalars["ID"]>;
  /** Name */
  name: Maybe<Scalars["String"]>;
};

export enum ActivityRelativeTypeEnum {
  ORDER = "ORDER",
  PURCHASE_REQUEST = "PURCHASE_REQUEST",
  PURCHASE_REQUEST_ITEM = "PURCHASE_REQUEST_ITEM",
  PURCHASE_REQUEST_PRODUCT = "PURCHASE_REQUEST_PRODUCT",
  PURCHASE_REQUEST_SERVICE = "PURCHASE_REQUEST_SERVICE",
  RFX = "RFX",
  SUPPLIER = "SUPPLIER",
}

export enum ActivityTypeEnum {
  ADD_ATTACHMENT = "ADD_ATTACHMENT",
  ADD_ITEM = "ADD_ITEM",
  ADD_ITEMS = "ADD_ITEMS",
  ASSIGN = "ASSIGN",
  COMMENT = "COMMENT",
  CREATE_APPROVAL_REQUEST = "CREATE_APPROVAL_REQUEST",
  CREATE_APPROVAL_WORKFLOW = "CREATE_APPROVAL_WORKFLOW",
  CREATE_INVOICE_FROM_ORDER = "CREATE_INVOICE_FROM_ORDER",
  CREATE_PURCHASE_REQUEST = "CREATE_PURCHASE_REQUEST",
  CREATE_PURCHASE_REQUEST_PRODUCT = "CREATE_PURCHASE_REQUEST_PRODUCT",
  CREATE_PURCHASE_REQUEST_SERVICE = "CREATE_PURCHASE_REQUEST_SERVICE",
  CREATE_SUPPLIER = "CREATE_SUPPLIER",
  DRAG_CARD = "DRAG_CARD",
  DRAG_ITEM = "DRAG_ITEM",
  EMAIL_INBOUND_MENTION = "EMAIL_INBOUND_MENTION",
  EMAIL_INBOUND_REQUESTER = "EMAIL_INBOUND_REQUESTER",
  EMAIL_INBOUND_SUPPLIER = "EMAIL_INBOUND_SUPPLIER",
  EMAIL_OUTBOUND_MENTION = "EMAIL_OUTBOUND_MENTION",
  EMAIL_OUTBOUND_SUPPLIER = "EMAIL_OUTBOUND_SUPPLIER",
  REPLY = "REPLY",
  UPDATE_APPROVAL_REQUEST = "UPDATE_APPROVAL_REQUEST",
  UPDATE_APPROVAL_WORKFLOW = "UPDATE_APPROVAL_WORKFLOW",
  UPDATE_ORDER_RECEIVED_QUANTITIES = "UPDATE_ORDER_RECEIVED_QUANTITIES",
  UPDATE_SUPPLIER = "UPDATE_SUPPLIER",
}

export type ActivityUserInfo = {
  __typename?: "ActivityUserInfo";
  /** Activity related user avatar url */
  avatarUrl: Maybe<Scalars["String"]>;
  /** Activity related user first name */
  firstName: Scalars["String"];
  /** User ID */
  id: Scalars["ID"];
  /** Activity related user last name */
  lastName: Scalars["String"];
};

export type Add_Attachment = {
  __typename?: "ADD_ATTACHMENT";
  /** encoding */
  encoding: Scalars["String"];
  /** Filename */
  filename: Scalars["String"];
  /** mimetype */
  mimetype: Scalars["String"];
  /** size */
  size: Scalars["Int"];
  /** url */
  url: Maybe<Scalars["String"]>;
};

export type Add_Item = {
  __typename?: "ADD_ITEM";
  /** Creator */
  creator: ActivityUserInfo;
  /** Name */
  name: Scalars["String"];
  /** Quantity */
  quantity: Maybe<Scalars["Float"]>;
  /** Unit */
  unit: Maybe<Scalars["String"]>;
};

export type Add_Items = {
  __typename?: "ADD_ITEMS";
  /** Creator */
  creator: ActivityUserInfo;
  /** Item count */
  itemCount: Scalars["Int"];
};

export type Address = {
  __typename?: "Address";
  /** Address line */
  address: Scalars["String"];
  /** Address type */
  addressType: AddressTypeEnum;
  /** Area code */
  areaCode: Scalars["String"];
  /** City */
  city: Scalars["String"];
  /** Address country */
  country: Country;
  /** Country code */
  countryCode: CountryCodeEnum;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Address unique id */
  id: Scalars["ID"];
  /** Location name */
  locationName: Maybe<Scalars["String"]>;
  /** Related organization id */
  organizationId: Scalars["ID"];
  /** Recipient name */
  recipientName: Maybe<Scalars["String"]>;
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export type AddressBook = {
  __typename?: "AddressBook";
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Address book email address */
  email: Scalars["String"];
  /** Address unique id */
  id: Scalars["ID"];
  /** Related organization id */
  organizationId: Scalars["ID"];
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export enum AddressTypeEnum {
  INVOICE_ADDRESS = "INVOICE_ADDRESS",
  REGISTRATION_ADDRESS = "REGISTRATION_ADDRESS",
  SHIPPING_ADDRESS = "SHIPPING_ADDRESS",
}

export type Admin = {
  __typename?: "Admin";
  /** List of supported countries */
  countries: Array<Country>;
  /** Currency symbol and name */
  currencies: Array<Currency>;
  /** Returns organization by id */
  organizationById: Organization;
  /** List of all organizations (ADMIN) */
  organizations: Array<Organization>;
  /** Returns user by id */
  userById: User;
  /** List of all users (ADMIN) */
  users: Array<User>;
};

export type AdminOrganizationByIdArgs = {
  organizationId: Scalars["String"];
};

export type AdminUserByIdArgs = {
  userId: Scalars["String"];
};

export type ApiKey = {
  __typename?: "ApiKey";
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Organization api key creator id */
  creatorId: Scalars["ID"];
  /** Organization api key description */
  description: Maybe<Scalars["String"]>;
  /** Organization api key ID */
  id: Scalars["ID"];
  /** Organization api key */
  token: Scalars["String"];
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export type ApprovalRequest = {
  __typename?: "ApprovalRequest";
  /** Related Approval Workflow ID */
  approvalWorkflowId: Scalars["ID"];
  /** Approval Request approver */
  approver: User;
  /** Comment by the approver about decision */
  approverComment: Maybe<Scalars["String"]>;
  /** User who is the approver of this request */
  approverId: Scalars["ID"];
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Comment by creator to approver */
  creatorComment: Maybe<Scalars["String"]>;
  /** User who started the Approval Request */
  creatorId: Scalars["ID"];
  /** Approval Request decision status */
  decision: Maybe<ApprovalRequestDecisionEnum>;
  /** Date that the decision was made */
  decisionDate: Maybe<Scalars["Date"]>;
  /** Due date of the decision to be made */
  dueDate: Maybe<Scalars["Date"]>;
  /** Approval Request ID */
  id: Scalars["ID"];
  /** Related Organization ID */
  organizationId: Scalars["ID"];
  /** Sequence number of approval groups. Parallel approval groups should have an equal value. */
  sequenceGroup: Scalars["Int"];
  /** Linear sequence within sequence group. Parallel requests should have an equal value. */
  sequenceNumber: Scalars["Int"];
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export enum ApprovalRequestDecisionEnum {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
}

export type ApprovalRequestInfo = {
  __typename?: "ApprovalRequestInfo";
  /** Comment by approver about the Request */
  approverComment: Maybe<Scalars["String"]>;
  /** Comment by buyer to approver about the Request */
  creatorComment: Maybe<Scalars["String"]>;
  /** Approver decision */
  decision: Maybe<ApprovalRequestDecisionEnum>;
  /** Approval request decision making date */
  decisionDate: Maybe<Scalars["Date"]>;
  /** Approval request decision due date */
  dueDate: Maybe<Scalars["Date"]>;
  /** ID of the Approval Request */
  id: Scalars["ID"];
  /** Request sequence group number */
  sequenceGroup: Scalars["Int"];
  /** Request sequence number */
  sequenceNumber: Scalars["Int"];
};

export type ApprovalWorkflow = {
  __typename?: "ApprovalWorkflow";
  /** Maximum budget request amount */
  approvalAmount: Maybe<Scalars["Float"]>;
  /** Approval workflow related approval requests */
  approvalRequests: Array<ApprovalRequest>;
  /** Approval workflow related approval requests stats */
  approvalWorkflowStats: ApprovalWorkflowStats;
  /** Approval workflow cancelling reason */
  cancellingComment: Maybe<Scalars["String"]>;
  /** Approval Workflow code */
  code: Scalars["String"];
  /** Comment for all approvers */
  comment: Maybe<Scalars["String"]>;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Approval workflow creator */
  creator: User;
  /** User who started the Approval Workflow */
  creatorId: Scalars["ID"];
  /** Approval workflow difference with deadline in days */
  diffWithDeadline: Maybe<Scalars["Int"]>;
  /** Approval Workflow ID */
  id: Scalars["ID"];
  /** Should keep Requester updated using e-mail/notifications */
  keepRequesterUpdated: Scalars["Boolean"];
  /** Related organization ID */
  organizationId: Scalars["ID"];
  /** Approval Workflow's latest Purchase Order */
  purchaseOrder: Maybe<Order>;
  /** Related Purchase Order ID */
  purchaseOrderId: Maybe<Scalars["ID"]>;
  /** Approval Workflow's latest Purchase Request */
  purchaseRequest: Maybe<PurchaseRequest>;
  /** Related Purchase Request ID */
  purchaseRequestId: Maybe<Scalars["ID"]>;
  /** Name of the request given by buyer */
  requestName: Maybe<Scalars["String"]>;
  /** Approval workflow requester */
  requestor: Maybe<User>;
  /** Is single approval required */
  singleApprovalRequired: Scalars["Boolean"];
  /** Approval Workflow current status */
  status: ApprovalWorkflowStatusEnum;
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export type ApprovalWorkflowInfo = {
  __typename?: "ApprovalWorkflowInfo";
  /** Total budget to be approved */
  approvalAmount: Maybe<Scalars["Float"]>;
  /** Code of the Approval Workflow */
  code: Scalars["ID"];
  /** Comment by buyer to approver about the workflow */
  comment: Maybe<Scalars["String"]>;
  /** Workflow title/name */
  requestName: Maybe<Scalars["String"]>;
  /** Approval Workflow status */
  status: ApprovalWorkflowStatusEnum;
};

export type ApprovalWorkflowStats = {
  __typename?: "ApprovalWorkflowStats";
  /** Number of positive approval decisions */
  approvedRequestCount: Scalars["Int"];
  /** Number of total Approval Requests */
  totalRequestCount: Scalars["Int"];
};

export enum ApprovalWorkflowStatusEnum {
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
  DECLINED = "DECLINED",
  DRAFT = "DRAFT",
  IN_PROGRESS = "IN_PROGRESS",
}

export type ApproverGroup = {
  __typename?: "ApproverGroup";
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Related department's ID */
  departmentId: Maybe<Scalars["ID"]>;
  /** Approver Group ID */
  id: Scalars["ID"];
  /** Group's name */
  name: Scalars["String"];
  /** Related Organization's ID */
  organizationId: Scalars["ID"];
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export type Assign = {
  __typename?: "ASSIGN";
  /** Assignee info */
  assigneeInfo: Maybe<ActivityUserInfo>;
  /** Assigner info */
  assignerInfo: ActivityUserInfo;
  /** Assign Info */
  itemInfo: ItemInfo;
};

export type Attachment = {
  __typename?: "Attachment";
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Attachment creator */
  creator: User;
  /** Creator id */
  creatorId: Scalars["ID"];
  /** Document date */
  documentDate: Maybe<Scalars["Date"]>;
  /** Attachment encoding */
  encoding: Scalars["String"];
  /** Attachment file name */
  filename: Scalars["String"];
  /** Attachment unique id */
  id: Scalars["ID"];
  /** Attachment mimetype */
  mimetype: Scalars["String"];
  /** Attachment notes */
  notes: Scalars["String"];
  /** Attachment size */
  size: Scalars["Int"];
  /** Date of update */
  updatedDate: Scalars["Date"];
  /** Attachment url */
  url: Maybe<Scalars["String"]>;
  /** Validity date */
  validityDate: Maybe<Scalars["Date"]>;
};

export type BaseSupplier = {
  __typename?: "BaseSupplier";
  /** BaseSupplier business registry code */
  businessRegistry: Maybe<Scalars["String"]>;
  /** BaseSupplier country code */
  countryCode: Maybe<CountryCodeEnum>;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Date of deletion */
  deletedDate: Maybe<Scalars["Date"]>;
  /** BaseSupplier unique ID */
  id: Scalars["ID"];
  /** BaseSupplier name */
  name: Maybe<Scalars["String"]>;
  /** Date of update */
  updatedDate: Scalars["Date"];
  /** Supplier web address */
  webAddress: Maybe<Scalars["String"]>;
};

export enum CardPositionMethodEnum {
  AFTER = "AFTER",
  BEFORE = "BEFORE",
}

export type CardStatus = {
  __typename?: "CardStatus";
  level: CardStatusLevelEnum;
  text: Scalars["String"];
};

export enum CardStatusLevelEnum {
  ALERT = "ALERT",
  DEFAULT = "DEFAULT",
  INFO = "INFO",
  PINK = "PINK",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
}

export enum CardTypeEnum {
  ORDER = "ORDER",
  PURCHASE_REQUEST = "PURCHASE_REQUEST",
  RFX = "RFX",
}

export type Category = {
  __typename?: "Category";
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Category unique ID */
  id: Scalars["ID"];
  /** Category name */
  name: Scalars["String"];
  /** Owner organization ID */
  organizationId: Maybe<Scalars["ID"]>;
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export type ChangedFieldInfo = {
  __typename?: "ChangedFieldInfo";
  /** Changed field's name */
  fieldName: Scalars["String"];
  /** Changed field's new value */
  newValue: Scalars["String"];
  /** Changed field's old value */
  oldValue: Scalars["String"];
};

export type Comment = {
  __typename?: "COMMENT";
  /** Comment text */
  comment: Scalars["String"];
  /** Relative info */
  relativeInfo: ActivityRelativeInfo;
  /** Comment author */
  user: ActivityUserInfo;
};

export type ContactPerson = {
  __typename?: "ContactPerson";
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Contact person email */
  email: Scalars["String"];
  /** Contact person first name */
  firstName: Maybe<Scalars["String"]>;
  /** Contact person unique id */
  id: Scalars["ID"];
  /** Contact person last name */
  lastName: Maybe<Scalars["String"]>;
  /** Contact person notes */
  notes: Maybe<Scalars["String"]>;
  /** Organization id */
  organizationId: Scalars["ID"];
  /** Contact person phone number */
  phoneNumber: Maybe<Scalars["String"]>;
  /** Contact person position */
  position: Maybe<Scalars["String"]>;
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export type CostCentre = {
  __typename?: "CostCentre";
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** CostCentre creator */
  creator: User;
  /** Creator id */
  creatorId: Scalars["ID"];
  /** Cost centre unique id */
  id: Scalars["ID"];
  /** Cost centre name */
  name: Scalars["String"];
  /** Organization id */
  organizationId: Scalars["ID"];
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export type Country = {
  __typename?: "Country";
  /** Country code */
  code: Scalars["String"];
  /** Country name */
  name: Scalars["String"];
};

export enum CountryCodeEnum {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AN = "AN",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  ST = "ST",
  SV = "SV",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  XK = "XK",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}

export type Create_Approval_Request = {
  __typename?: "CREATE_APPROVAL_REQUEST";
  /** Approval Request info at creation time */
  approvalRequestInfo: ApprovalRequestInfo;
  /** Approval Request related workflow info at creation time */
  approvalWorkflowInfo: ApprovalWorkflowInfo;
  /** Approval Workflow related approval requests stats */
  approvalWorkflowStats: ApprovalWorkflowStats;
  /** Workflow approver info */
  approverInfo: ActivityUserInfo;
};

export type Create_Approval_Workflow = {
  __typename?: "CREATE_APPROVAL_WORKFLOW";
  /** Workflow info at creation time */
  approvalWorkflowInfo: ApprovalWorkflowInfo;
  /** Approval Workflow related approval requests stats */
  approvalWorkflowStats: ApprovalWorkflowStats;
  /** Workflow creator info */
  creatorInfo: ActivityUserInfo;
};

export type Create_Invoice_From_Order = {
  __typename?: "CREATE_INVOICE_FROM_ORDER";
  /** Order info */
  orderInfo: Maybe<ActivityRelativeOrderInfo>;
  /** User info */
  user: ActivityUserInfo;
};

export type Create_Purchase_Request = {
  __typename?: "CREATE_PURCHASE_REQUEST";
  /** Code */
  code: Scalars["String"];
  /** Creator info */
  creator: ActivityUserInfo;
};

export type Create_Purchase_Request_Product = {
  __typename?: "CREATE_PURCHASE_REQUEST_PRODUCT";
  /** Creator info */
  creator: ActivityUserInfo;
  /** Name */
  name: Scalars["String"];
  /** Purchase request code */
  purchaseRequestCode: Scalars["String"];
  /** quantity */
  quantity: Scalars["Float"];
  /** unit */
  unit: Scalars["String"];
};

export type Create_Purchase_Request_Service = {
  __typename?: "CREATE_PURCHASE_REQUEST_SERVICE";
  /** Creator info */
  creator: ActivityUserInfo;
  /** Name */
  name: Scalars["String"];
  /** Purchase request code */
  purchaseRequestCode: Scalars["String"];
};

export type Create_Supplier = {
  __typename?: "CREATE_SUPPLIER";
  /** Creator info */
  creator: ActivityUserInfo;
  /** Supplier email */
  email: Scalars["String"];
  /** Supplier name */
  name: Maybe<Scalars["String"]>;
};

export type Currency = {
  __typename?: "Currency";
  /** Currency name */
  name: Scalars["String"];
  /** Currency symbol */
  symbol: Scalars["String"];
};

export enum CurrencyEnum {
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ANG = "ANG",
  AOA = "AOA",
  ARS = "ARS",
  AUD = "AUD",
  AWG = "AWG",
  AZN = "AZN",
  BAM = "BAM",
  BBD = "BBD",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BMD = "BMD",
  BND = "BND",
  BOB = "BOB",
  BRL = "BRL",
  BSD = "BSD",
  BTN = "BTN",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHF = "CHF",
  CLF = "CLF",
  CLP = "CLP",
  CNH = "CNH",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CUC = "CUC",
  CUP = "CUP",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  EUR = "EUR",
  FJD = "FJD",
  FKP = "FKP",
  GBP = "GBP",
  GEL = "GEL",
  GGP = "GGP",
  GHS = "GHS",
  GIP = "GIP",
  GMD = "GMD",
  GNF = "GNF",
  GTQ = "GTQ",
  GYD = "GYD",
  HKD = "HKD",
  HNL = "HNL",
  HRK = "HRK",
  HTG = "HTG",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  IMP = "IMP",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JEP = "JEP",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KGS = "KGS",
  KHR = "KHR",
  KMF = "KMF",
  KPW = "KPW",
  KRW = "KRW",
  KWD = "KWD",
  KYD = "KYD",
  KZT = "KZT",
  LAK = "LAK",
  LBP = "LBP",
  LKR = "LKR",
  LRD = "LRD",
  LSL = "LSL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MNT = "MNT",
  MOP = "MOP",
  MRO = "MRO",
  MRU = "MRU",
  MUR = "MUR",
  MVR = "MVR",
  MWK = "MWK",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PGK = "PGK",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SBD = "SBD",
  SCR = "SCR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SHP = "SHP",
  SLL = "SLL",
  SOS = "SOS",
  SRD = "SRD",
  SSP = "SSP",
  STD = "STD",
  STN = "STN",
  SVC = "SVC",
  SYP = "SYP",
  SZL = "SZL",
  THB = "THB",
  TJS = "TJS",
  TMT = "TMT",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  USD = "USD",
  UYU = "UYU",
  UZS = "UZS",
  VEF = "VEF",
  VND = "VND",
  VUV = "VUV",
  WST = "WST",
  XAF = "XAF",
  XAG = "XAG",
  XAU = "XAU",
  XCD = "XCD",
  XDR = "XDR",
  XOF = "XOF",
  XPD = "XPD",
  XPF = "XPF",
  XPT = "XPT",
  YER = "YER",
  ZAR = "ZAR",
  ZMW = "ZMW",
  ZWL = "ZWL",
}

export type DateRangeFilterType = {
  /** Date range filter end date */
  endDate: Maybe<Scalars["Date"]>;
  /** Date range filter start date */
  startDate: Maybe<Scalars["Date"]>;
};

export type Department = {
  __typename?: "Department";
  /** Department code */
  code: Maybe<Scalars["String"]>;
  /** Department cost centre */
  costCentre: Maybe<CostCentre>;
  /** Department cost centre id */
  costCentreId: Maybe<Scalars["ID"]>;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Department creator */
  creator: User;
  /** Department creator id */
  creatorId: Scalars["ID"];
  /** Department domain */
  domain: Maybe<Scalars["String"]>;
  /** Department unique id */
  id: Scalars["ID"];
  /** Department invoice address */
  invoiceAddress: Maybe<Address>;
  /** Department invoice address id */
  invoiceAddressId: Maybe<Scalars["ID"]>;
  /** Department name */
  name: Scalars["String"];
  /** Organization id */
  organizationId: Scalars["ID"];
  /** Department shipping address */
  shippingAddress: Maybe<Address>;
  /** Department shipping address id */
  shippingAddressId: Maybe<Scalars["ID"]>;
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export type Drag_Card = {
  __typename?: "DRAG_CARD";
  /** Assignee info */
  assigneeInfo: Maybe<ActivityUserInfo>;
  /** Card name */
  cardName: Scalars["String"];
  /** From code */
  fromCode: Scalars["String"];
  /** Swimlane from type */
  fromType: SwimlaneIdEnum;
  /** Requestor info */
  requestorInfo: ActivityUserInfo;
  /** To code */
  toCode: Scalars["String"];
  /** Swimlane to type */
  toType: SwimlaneIdEnum;
};

export type Drag_Item = {
  __typename?: "DRAG_ITEM";
  /** Drag Item info */
  cardName: Scalars["String"];
};

/** Resolves InboundEmail and OutboundEmail as a union Type */
export type Email = InboundEmail | OutboundEmail;

export type Email_Inbound_Mention = {
  __typename?: "EMAIL_INBOUND_MENTION";
  /** Comment text */
  comment: Scalars["String"];
  /** Email info */
  emailInfo: ActivityEmailInfo;
  /** Relative info */
  relativeInfo: ActivityRelativeInfo;
  /** Comment author */
  user: ActivityUserInfo;
};

export type Email_Inbound_Requester = {
  __typename?: "EMAIL_INBOUND_REQUESTER";
  /** Email inbound requester info */
  emailInboundRequesterInfo: Scalars["String"];
};

export type Email_Inbound_Supplier = {
  __typename?: "EMAIL_INBOUND_SUPPLIER";
  /** Email info */
  emailInfo: ActivityEmailInfo;
  /** Relative info */
  relativeInfo: ActivityRelativeRfxInfo;
  /** Email inbound supplier info */
  supplierInfo: ActivityRelativeSupplierInfo;
};

export type Email_Outbound_Mention = {
  __typename?: "EMAIL_OUTBOUND_MENTION";
  /** Email outbound mention info */
  emailOutboundMentionInfo: Scalars["String"];
  /** Comment author */
  user: ActivityUserInfo;
};

export type Email_Outbound_Supplier = {
  __typename?: "EMAIL_OUTBOUND_SUPPLIER";
  /** E-mail sender */
  creator: ActivityUserInfo;
  /** Message */
  message: Scalars["String"];
  /** Order info */
  orderInfo: Maybe<ActivityRelativeOrderInfo>;
  /** Rfx info */
  rfxInfo: Maybe<ActivityRelativeRfxInfo>;
  /** Subject */
  subject: Scalars["String"];
  /** Email outbound supplier info */
  supplierInfo: ActivityRelativeSupplierInfo;
};

export enum EmailCardTypeEnum {
  ORDER = "ORDER",
  PURCHASE_REQUEST = "PURCHASE_REQUEST",
  RFX = "RFX",
}

export enum EmailStatusEnum {
  ERROR = "error",
  INVALID = "invalid",
  QUEUED = "queued",
  REJECTED = "rejected",
  SCHEDULED = "scheduled",
  SENT = "sent",
}

export type ExcelImportItemResult = {
  __typename?: "ExcelImportItemResult";
  /** Created purchase request (if successful) */
  purchaseRequest: Maybe<PurchaseRequest>;
  /** Excel product import rows */
  rows: Array<ExcelImportItemRow>;
  success: Scalars["Boolean"];
};

export type ExcelImportItemRow = {
  __typename?: "ExcelImportItemRow";
  errors: ExcelImportItemValidationErrors;
  /** Row index number */
  row: Scalars["Int"];
  /** Row validation success */
  success: Scalars["Boolean"];
};

export type ExcelImportItemValidationErrors = {
  __typename?: "ExcelImportItemValidationErrors";
  /** Excel import product additional info validation error */
  additionalInfo: Maybe<Array<Scalars["String"]>>;
  /** Excel import product itemCode validation error */
  code: Maybe<Array<Scalars["String"]>>;
  /** Excel import product currency validation error */
  currency: Maybe<Array<Scalars["String"]>>;
  /** Excel import product custom fields validation error */
  customFields: Maybe<Array<Scalars["String"]>>;
  /** Excel import expected delivery date validation error */
  expectedDeliveryDate: Maybe<Array<Scalars["String"]>>;
  /** Excel import product name validation error */
  name: Maybe<Array<Scalars["String"]>>;
  /** Excel import product productUrl validation error */
  productUrl: Maybe<Array<Scalars["String"]>>;
  /** Excel import product quantity validation error */
  quantity: Maybe<Array<Scalars["String"]>>;
  /** Excel import product suggestedSupplier validation error */
  suggestedSupplier: Maybe<Array<Scalars["String"]>>;
  /** Excel import product supplierCode validation error */
  supplierCode: Maybe<Array<Scalars["String"]>>;
  /** Excel import product unit validation error */
  unit: Maybe<Array<Scalars["String"]>>;
  /** Excel import product unitPrice validation error */
  unitPrice: Maybe<Array<Scalars["String"]>>;
};

export type ExcelImportQuotationPricesResult = {
  __typename?: "ExcelImportQuotationPricesResult";
  /** Excel product import rows */
  rows: Array<ExcelImportItemRow>;
  /** Updated sourcing request (if successful) */
  sourcingRequest: Maybe<SourcingRequest>;
  success: Scalars["Boolean"];
};

export type ExcelImportSupplierResult = {
  __typename?: "ExcelImportSupplierResult";
  /** Excel supplier import rows */
  rows: Array<ExcelImportSupplierRow>;
  success: Scalars["Boolean"];
};

export type ExcelImportSupplierRow = {
  __typename?: "ExcelImportSupplierRow";
  errors: ExcelImportSupplierValidationErrors;
  /** Row index number */
  row: Scalars["Int"];
  /** Row validation success */
  success: Scalars["Boolean"];
};

export type ExcelImportSupplierValidationErrors = {
  __typename?: "ExcelImportSupplierValidationErrors";
  /** Excel import supplier address validation error */
  address: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier areaCode validation error */
  areaCode: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier business registry validation error */
  businessRegistry: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier categories validation error */
  categories: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier city validation error */
  city: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 1 email validation error */
  contactPersonEmail1: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 2 email validation error */
  contactPersonEmail2: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 3 email validation error */
  contactPersonEmail3: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 4 email validation error */
  contactPersonEmail4: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 5 email validation error */
  contactPersonEmail5: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 6 email validation error */
  contactPersonEmail6: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 1 first name validation error */
  contactPersonFirstName1: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 2 first name validation error */
  contactPersonFirstName2: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 3 first name validation error */
  contactPersonFirstName3: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 4 first name validation error */
  contactPersonFirstName4: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 5 first name validation error */
  contactPersonFirstName5: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 6 first name validation error */
  contactPersonFirstName6: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 1 last name validation error */
  contactPersonLastName1: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 2 last name validation error */
  contactPersonLastName2: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 3 last name validation error */
  contactPersonLastName3: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 4 last name validation error */
  contactPersonLastName4: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 5 last name validation error */
  contactPersonLastName5: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 6 last name validation error */
  contactPersonLastName6: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 1 notes validation error */
  contactPersonNotes1: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 2 notes validation error */
  contactPersonNotes2: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 3 notes validation error */
  contactPersonNotes3: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 4 notes validation error */
  contactPersonNotes4: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 5 notes validation error */
  contactPersonNotes5: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 6 notes validation error */
  contactPersonNotes6: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 1 phone validation error */
  contactPersonPhone1: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 2 phone validation error */
  contactPersonPhone2: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 3 phone validation error */
  contactPersonPhone3: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 4 phone validation error */
  contactPersonPhone4: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 5 phone validation error */
  contactPersonPhone5: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 6 phone validation error */
  contactPersonPhone6: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 1 position validation error */
  contactPersonPosition1: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 2 position validation error */
  contactPersonPosition2: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 3 position validation error */
  contactPersonPosition3: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 4 position validation error */
  contactPersonPosition4: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 5 position validation error */
  contactPersonPosition5: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier contact person 6 position validation error */
  contactPersonPosition6: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier areaCode validation error */
  countryCode: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier currency validation error */
  currency: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier custom field 1 validation error */
  customField1: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier custom field 2 validation error */
  customField2: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier custom field 3 validation error */
  customField3: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier duns code validation error */
  dunsCode: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier email validation error */
  email: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier erp supplier code validation error */
  erpSupplierCode: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier lei code validation error */
  leiCode: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier name validation error */
  name: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier notes validation error */
  notes: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier payment terms validation error */
  paymentTerms: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier status validation error */
  status: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier vat identification validation error */
  vatIdentification: Maybe<Array<Scalars["String"]>>;
  /** Excel import supplier webAddress validation error */
  webAddress: Maybe<Array<Scalars["String"]>>;
};

export type Experiment = {
  __typename?: "Experiment";
  session: Scalars["String"];
};

export type File = {
  __typename?: "File";
  encoding: Scalars["String"];
  filename: Scalars["String"];
  mimetype: Scalars["String"];
  path: Scalars["String"];
};

export type FullTextSearchItemParentInfo = {
  __typename?: "fullTextSearchItemParentInfo";
  /** Parent code */
  code: Scalars["String"];
  /** Parent prefix */
  prefix: Scalars["String"];
  /** Parent status */
  status: CardStatus;
  /** Parent type */
  type: FullTextSearchResultTypeEnum;
};

export type FullTextSearchResult = {
  __typename?: "fullTextSearchResult";
  /** Code value */
  code: Maybe<Scalars["String"]>;
  /** Result record unique ID */
  id: Scalars["ID"];
  /** Is archived result */
  isArchived: Scalars["Boolean"];
  /** Name value */
  name: Scalars["String"];
  /** Result item parents info */
  parent: Maybe<FullTextSearchItemParentInfo>;
  /** Result prefix */
  prefix: Scalars["String"];
  /** Result status */
  status: Maybe<CardStatus>;
  /** Result type */
  type: FullTextSearchResultTypeEnum;
};

export enum FullTextSearchResultTypeEnum {
  ORDER = "ORDER",
  ORDER_INVOICE = "ORDER_INVOICE",
  ORDER_RECEIVING = "ORDER_RECEIVING",
  PURCHASE_REQUEST = "PURCHASE_REQUEST",
  PURCHASE_REQUEST_ITEM = "PURCHASE_REQUEST_ITEM",
  RFX = "RFX",
}

export type InboundEmail = {
  __typename?: "InboundEmail";
  /** Related activity feed id */
  activityId: Maybe<Scalars["ID"]>;
  /** Inbound email cc */
  ccEmail: Maybe<Scalars["String"]>;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Inbound email raw message data */
  data: Maybe<Scalars["String"]>;
  /** Inbound dkim valid */
  dkimValid: Maybe<Scalars["Boolean"]>;
  /** Inbound email from address */
  fromEmail: Maybe<Scalars["String"]>;
  /** Inbound email has attachments */
  hasAttachments: Maybe<Scalars["Boolean"]>;
  /** Inbound email has been read by anybody in organization */
  hasBeenRead: Scalars["Boolean"];
  /** Inbound email html body */
  htmlBody: Maybe<Scalars["String"]>;
  /** Inbound email unique id */
  id: Scalars["ID"];
  /** Inbound emails parent (outbound) email id */
  parentId: Maybe<Scalars["ID"]>;
  /** Related card id */
  relativeId: Scalars["ID"];
  /** Card's relative type */
  relativeType: ActivityRelativeTypeEnum;
  /** Related requester id */
  requesterId: Maybe<Scalars["ID"]>;
  /** Corresponding requester name */
  requesterName: Maybe<Scalars["String"]>;
  /** Inbound email raw security data */
  securityData: Maybe<Scalars["JSON"]>;
  /** Inbound email spf result */
  spfResult: Maybe<SpfResultEnum>;
  /** Inbound email subject */
  subject: Maybe<Scalars["String"]>;
  /** Related supplier id */
  supplierId: Maybe<Scalars["ID"]>;
  /** Corresponding supplier name */
  supplierName: Maybe<Scalars["String"]>;
  /** Inbound email text body */
  textBody: Maybe<Scalars["String"]>;
  /** Inbound email to address */
  toEmail: Maybe<Scalars["String"]>;
  /** Inbound email type */
  type: InboundEmailTypeEnum;
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export enum InboundEmailTypeEnum {
  MENTION = "MENTION",
  PURCHASE_REQUEST = "PURCHASE_REQUEST",
  SUPPLIER = "SUPPLIER",
}

export type InputType = {
  /** ID */
  id: Maybe<Scalars["String"]>;
  /** ID */
  name: Maybe<Scalars["String"]>;
};

export type ItemInfo = {
  __typename?: "ItemInfo";
  /** Item code */
  code: Scalars["String"];
  /** Item ID */
  id: Scalars["ID"];
  /** Item name */
  name: Scalars["String"];
  /** Card type */
  type: CardTypeEnum;
};

export type KanbanFilterInput = {
  /** Approval Workflow related approval requests stats */
  dateRange: Maybe<DateRangeFilterType>;
  /** Department id filter */
  departmentId: Maybe<Scalars["String"]>;
  /** Project id filter */
  projectId: Maybe<Scalars["String"]>;
  /** Status filter enum */
  status: Maybe<Array<StatusFilterEnum>>;
  /** Supplier id filter */
  supplierId: Maybe<Scalars["String"]>;
  /** User id filter */
  userId: Maybe<Scalars["String"]>;
};

export type Label = {
  __typename?: "Label";
  /** Label unique id */
  id: Scalars["ID"];
  /** Label name */
  name: Scalars["String"];
  /** Organization label belongs to */
  organization: Organization;
  /** Organization id */
  organizationId: Scalars["ID"];
};

export type Mention = {
  __typename?: "Mention";
  /** Returns related activity */
  activity: ActivityFeed;
  /** Activity id where mentioned */
  activityId: Scalars["ID"];
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Mention unique id */
  id: Scalars["ID"];
  /** Mentioned user id */
  mentionedUserId: Maybe<Scalars["ID"]>;
  /** Mentioned supplier id */
  taggedSupplierId: Maybe<Scalars["ID"]>;
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export type MentionInput = {
  /** Mentioned user id */
  mentionedUserId: Maybe<Scalars["ID"]>;
  /** Mentioned supplier id */
  taggedSupplierId: Maybe<Scalars["ID"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  /** Adds organization to user */
  addOrganizationToUser: User;
  /** Adds purchase request items to order */
  addPurchaseRequestItemsToOrder: Order;
  /** Adds purchase request items to rfx */
  addPurchaseRequestItemsToRfx: Rfx;
  /** Adds roles to existing user (ADMIN, KEY_USER, create-requestor, create-approver) */
  addRolesToUser: User;
  /** Adds a category to organization's supplier */
  addSupplierCategory: Category;
  /** Adds resolution about supplier in RFX */
  addSupplierResolution: Rfx;
  /** Adds supplier to rfx */
  addSupplierToRfx: Rfx;
  /** Updates existing organization as Admin (ADMIN) */
  adminUpdateOrganization: Organization;
  /** Updates specified organizations logo */
  adminUpdateOrganizationLogo: Organization;
  /** Updates specified users profile picture */
  adminUpdateProfilePicture: Viewer;
  /** Archive order */
  archiveOrder: Order;
  /** Archive purchase request */
  archivePurchaseRequest: PurchaseRequest;
  /** Archive RFX */
  archiveRfx: Rfx;
  /** Assigns card to specified user */
  assignCard: Scalars["Boolean"];
  /** Cancel sourcing event */
  cancelSourcingEvent: SourcingEvent;
  /** Complete purchase request */
  completePurchaseRequest: PurchaseRequest;
  /** Complete sourcing event */
  completeSourcingEvent: SourcingEvent;
  /** Completes sourcing request */
  completeSourcingRequest: SourcingRequest;
  /** Creates new attachment */
  createActivityAttachment: Attachment;
  /** Create new api key (ADMIN, KEY_USER, api, create-api-key) */
  createApiKey: ApiKey;
  /** Creates new approval request (ADMIN, contact-person, create-approval-request) */
  createApprovalRequest: ApprovalRequest;
  /** Create Approval Workflow (approval-workflow, create-approval-workflow) */
  createApprovalWorkflow: ApprovalWorkflow;
  /** Creates new approver (ADMIN, KEY_USER, create-approver) */
  createApprover: User;
  /** Creates a new category */
  createCategory: Category;
  /** Creates new comment */
  createComment: ActivityFeed;
  /** Creates new contact person (ADMIN, contact-person, create-contact-person) */
  createContactPerson: ContactPerson;
  /** Creates new cost centre */
  createCostCentre: CostCentre;
  /** Creates new department */
  createDepartment: Department;
  /** Creates order from purchase request */
  createOrderFromPurchaseRequest: Order;
  /** Creates order from purchase request items */
  createOrderFromPurchaseRequestItems: Order;
  /** Creates order from rfx */
  createOrderFromRfx: Order;
  /** Creates invoice order from order */
  createOrderInvoiceFromOrder: Order;
  /** Creates new organization (ADMIN) */
  createOrganization: Organization;
  /** Creates a new address for organization (create-organization-address) */
  createOrganizationAddress: Address;
  /** Creates new organization user (ADMIN, KEY_USER) */
  createOrganizationUser: User;
  /** Creates or updates new purchase request */
  createOrUpdatePurchaseRequestFromPortal: PurchaseRequest;
  /** Creates new sourcing event or updates existing */
  createOrUpdateSourcingEvent: SourcingEvent;
  /** Creates new project */
  createProject: Project;
  /** Creates new purchase request */
  createPurchaseRequest: PurchaseRequest;
  /** Creates new Purchase request attachments */
  createPurchaseRequestAttachments: Array<Attachment>;
  /** Creates or updates new purchase request */
  createPurchaseRequestFromPortal: PurchaseRequest;
  /** Creates new Purchase request attachments */
  createPurchaseRequestItemAttachments: Array<Attachment>;
  /** Creates new purchase request product */
  createPurchaseRequestProduct: PurchaseRequestItem;
  /** Creates new purchase request service */
  createPurchaseRequestService: PurchaseRequestItem;
  /** Creates new quantity unit (ADMIN, KEY_USER, create-organization-quantity-unit) */
  createQuantityUnit: QuantityUnit;
  /** Creates new requestor (ADMIN, create-requestor) */
  createRequestor: User;
  /** Creates rfx from purchase request */
  createRfxFromPurchaseRequest: Rfx;
  /** Creates rfx from purchase request items */
  createRfxFromPurchaseRequestItems: Rfx;
  /** Creates new supplier */
  createSupplier: Supplier;
  /** Creates new supplier attachment */
  createSupplierAttachment: Array<Attachment>;
  /** Creates new user (ADMIN) */
  createUser: User;
  /** Deactivates organization (ADMIN) */
  deactivateOrganization: Organization;
  /** Deactivates user (ADMIN) */
  deactivateUser: User;
  /** Removes api key (ADMIN, KEY_USER, api, delete-api-key) */
  deleteApiKey: ApiKey;
  /** Deletes approval request (ADMIN, approval-workflow, delete-approval-request) */
  deleteApprovalRequest: ApprovalRequest;
  /** Removes a cost centre from organization */
  deleteCostCentre: CostCentre;
  /** Removes a department from organization */
  deleteDepartment: Department;
  /** Removes a project from organization */
  deleteProject: Project;
  /** Deletes purchase request item */
  deletePurchaseRequestItem: PurchaseRequestItem;
  /** Discard purchase request */
  discardPurchaseRequest: PurchaseRequest;
  /** List of found records */
  fullTextSearch: Array<FullTextSearchResult>;
  /** Invites new user to organization (ADMIN) */
  inviteToOrganization: User;
  /** Logs in with specified user (ADMIN) */
  loginAs: User;
  /** Handles logging in supplier user by magic link */
  loginSupplierUserWithToken: Viewer;
  /** Logs the user in */
  loginWithPassword: Viewer;
  /** Handles logging in by magic link */
  loginWithToken: Viewer;
  /** Logs the user out (LOGOUT) */
  logout: Scalars["Boolean"];
  /** Marks all notifications as seen */
  markAllNotificationsSeen: Scalars["Boolean"];
  /** Marks if email has been read or not */
  markInboundEmailReadStatus: Scalars["Boolean"];
  /** Marks notifications as seen */
  markNotificationsSeenByIds: Scalars["Boolean"];
  /** Removes organization from user */
  removeOrganizationFromUser: User;
  /** Removes relation between Purchase request and attachment */
  removePurchaseRequestAttachment: PurchaseRequest;
  /** Removes purchase request item from rfx and order */
  removePurchaseRequestItem: PurchaseRequestItem;
  /** Removes relation between Purchase request item and attachment */
  removePurchaseRequestItemAttachment: PurchaseRequestItem;
  /** Removes a category from organization's supplier */
  removeSupplierCategory: Category;
  /** Removes supplier to rfx */
  removeSupplierFromRfx: Rfx;
  /** Sends email to requester */
  sendEmailToRequester: Scalars["Boolean"];
  /** Sends email to each supplier */
  sendEmailToSuppliers: Scalars["Boolean"];
  /** Sends login token email */
  sendLoginToken: Scalars["Boolean"];
  /** Sends sourcing request email to each supplier */
  sendSourcingRequest: Scalars["Boolean"];
  /** Set contact person as default */
  setDefaultContactPerson: ContactPerson;
  /** List of found records */
  supplierFullTextSearch: Array<SupplierFullTextSearchResult>;
  /** Handles supplier lookup and resulting business logic */
  supplierLookup: SupplierLookupResponse;
  /** Handles supplier self registration */
  supplierSelfRegistration: Scalars["Boolean"];
  /** Test API */
  testApi: TestApi;
  /** Updates approval request (ADMIN, approval-workflow, update-approval-request) */
  updateApprovalRequest: ApprovalRequest;
  /** Update Approval Workflow data (without status) (approval-workflow, update-approval-workflow) */
  updateApprovalWorkflowData: ApprovalWorkflow;
  /** Update Approval Workflow status (approval-workflow, update-approval-workflow) */
  updateApprovalWorkflowStatus: ApprovalWorkflow;
  /** Updates card position */
  updateCardPosition: Scalars["Boolean"];
  /** Updates existing contact person (ADMIN, contact-person, update-contact-person) */
  updateContactPerson: ContactPerson;
  /** Updates existing cost centre */
  updateCostCentre: CostCentre;
  /** Updates existing department */
  updateDepartment: Department;
  /** Updates order */
  updateOrder: Order;
  /** Updates order confirmation received */
  updateOrderConfirmationReceived: Order;
  /** Updates order hasBeenSent */
  updateOrderHasBeenSent: Order;
  /** Updates order added to stock */
  updateOrderIsAddedToStock: Order;
  /** Updates purchase order release method */
  updateOrderReleaseMethod: Order;
  /** Updates order status */
  updateOrderStatus: Order;
  /** Updates order type */
  updateOrderType: Order;
  /** Updates existing organization (ADMIN, KEY_USER) */
  updateOrganization: Organization;
  /** Updates existing organization's po template domains (ADMIN, KEY_USER) */
  updateOrganizationPoTemplate: Organization;
  /** Updates existing user (ADMIN, KEY_USER) */
  updateOrganizationUser: User;
  /** Updates user */
  updateProfile: Viewer;
  /** Updates users profile picture */
  updateProfilePicture: Viewer;
  /** Updates existing project */
  updateProject: Project;
  /** Updates purchase request */
  updatePurchaseRequest: PurchaseRequest;
  /** Creates or updates new purchase request */
  updatePurchaseRequestFromPortal: PurchaseRequest;
  /** Updates purchase request item received quantities */
  updatePurchaseRequestItemReceivedQuantities: Scalars["Boolean"];
  /** Updates purchase request product */
  updatePurchaseRequestProduct: PurchaseRequestItem;
  /** Updates purchase request service */
  updatePurchaseRequestService: PurchaseRequestItem;
  /** Updates purchase request status */
  updatePurchaseRequestStatus: PurchaseRequest;
  /** Updates rfx */
  updateRfx: Rfx;
  /** Updates rfx name */
  updateRfxName: Rfx;
  /** Updates rfx */
  updateRfxType: Rfx;
  /** Creates new sourcing event or updates existing */
  updateSourcingEventItemWinners: SourcingEvent;
  /** Updates sourcing request */
  updateSourcingRequest: SourcingRequest;
  /** Updates supplier */
  updateSupplier: Supplier;
  /** Updates existing user (ADMIN) */
  updateUser: User;
  /** Updates existing organization's white-listed domains (ADMIN, KEY_USER) */
  updateWhitelistedDomains: Organization;
  /** Uploads specified organizations logo */
  uploadOrganizationLogo: Organization;
  /** Creates list of products based on excel */
  uploadProductExcel: ExcelImportItemResult;
  /** Updates sourcing request prices from excel */
  uploadQuotationPricesExcel: ExcelImportQuotationPricesResult;
  /** Creates list of suppliers based on excel */
  uploadSupplierExcel: ExcelImportSupplierResult;
};

export type MutationAddOrganizationToUserArgs = {
  organizationId: Scalars["String"];
  userId: Scalars["String"];
};

export type MutationAddPurchaseRequestItemsToOrderArgs = {
  orderId: Scalars["String"];
  purchaseRequestItems: Array<Scalars["String"]>;
};

export type MutationAddPurchaseRequestItemsToRfxArgs = {
  purchaseRequestItems: Array<Scalars["String"]>;
  rfxId: Scalars["String"];
};

export type MutationAddRolesToUserArgs = {
  roles: Array<UserRoleEnum>;
  userId: Scalars["String"];
};

export type MutationAddSupplierCategoryArgs = {
  categoryId: Scalars["String"];
  organizationId: Scalars["String"];
  supplierId: Scalars["String"];
};

export type MutationAddSupplierResolutionArgs = {
  resolution: Maybe<RfxSupplierResolutionEnum>;
  rfxId: Scalars["String"];
  supplierId: Scalars["String"];
};

export type MutationAddSupplierToRfxArgs = {
  rfxId: Scalars["String"];
  supplierId: Scalars["String"];
};

export type MutationAdminUpdateOrganizationArgs = {
  address: Scalars["String"];
  areaCode: Scalars["String"];
  baseCurrency: CurrencyEnum;
  city: Scalars["String"];
  companyName: Scalars["String"];
  countryCode: CountryCodeEnum;
  domainWhitelist: Maybe<Array<Scalars["String"]>>;
  isActive: Scalars["Boolean"];
  organizationId: Scalars["String"];
  urlName: Scalars["String"];
};

export type MutationAdminUpdateOrganizationLogoArgs = {
  companyLogo: Scalars["Upload"];
  organizationId: Scalars["String"];
};

export type MutationAdminUpdateProfilePictureArgs = {
  profilePicture: Scalars["Upload"];
  userId: Scalars["String"];
};

export type MutationArchiveOrderArgs = {
  orderId: Scalars["String"];
};

export type MutationArchivePurchaseRequestArgs = {
  purchaseRequestId: Scalars["String"];
};

export type MutationArchiveRfxArgs = {
  rfxId: Scalars["String"];
};

export type MutationAssignCardArgs = {
  assigneeId: Maybe<Scalars["String"]>;
  itemId: Scalars["String"];
  type: CardTypeEnum;
};

export type MutationCancelSourcingEventArgs = {
  sourcingEventId: Scalars["String"];
};

export type MutationCompletePurchaseRequestArgs = {
  purchaseRequestId: Scalars["String"];
};

export type MutationCompleteSourcingEventArgs = {
  sourcingEventId: Scalars["String"];
};

export type MutationCompleteSourcingRequestArgs = {
  sourcingRequestId: Scalars["String"];
};

export type MutationCreateActivityAttachmentArgs = {
  attachment: Scalars["Upload"];
  organizationId: Scalars["String"];
  parentId: Maybe<Scalars["String"]>;
  relativeId: Scalars["String"];
  relativeType: ActivityRelativeTypeEnum;
};

export type MutationCreateApiKeyArgs = {
  description: Maybe<Scalars["String"]>;
  organizationId: Scalars["String"];
};

export type MutationCreateApprovalRequestArgs = {
  approvalWorkflowId: Scalars["String"];
  approverId: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  dueDate: Maybe<Scalars["Date"]>;
  organizationId: Scalars["String"];
  sequenceNumber: Maybe<Scalars["Int"]>;
};

export type MutationCreateApprovalWorkflowArgs = {
  purchaseOrderId: Maybe<Scalars["String"]>;
  purchaseRequestId: Maybe<Scalars["String"]>;
};

export type MutationCreateApproverArgs = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  organizationId: Scalars["String"];
};

export type MutationCreateCategoryArgs = {
  name: Scalars["String"];
  organizationId: Maybe<Scalars["String"]>;
  parentId: Maybe<Scalars["String"]>;
};

export type MutationCreateCommentArgs = {
  attachments: Maybe<Array<Scalars["Upload"]>>;
  comment: Scalars["String"];
  mentions: Maybe<Array<MentionInput>>;
  organizationId: Scalars["String"];
  parentId: Maybe<Scalars["String"]>;
  relativeId: Scalars["String"];
  relativeType: ActivityRelativeTypeEnum;
  type: ActivityTypeEnum;
};

export type MutationCreateContactPersonArgs = {
  email: Scalars["String"];
  firstName: Maybe<Scalars["String"]>;
  isDefault: Scalars["Boolean"];
  lastName: Maybe<Scalars["String"]>;
  notes: Maybe<Scalars["String"]>;
  phoneNumber: Maybe<Scalars["String"]>;
  position: Maybe<Scalars["String"]>;
  supplierId: Scalars["String"];
};

export type MutationCreateCostCentreArgs = {
  name: Scalars["String"];
  organizationId: Scalars["String"];
};

export type MutationCreateDepartmentArgs = {
  code: Maybe<Scalars["String"]>;
  domain: Maybe<Scalars["String"]>;
  invoiceAddressId: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  organizationId: Scalars["String"];
  shippingAddressId: Maybe<Scalars["String"]>;
};

export type MutationCreateOrderFromPurchaseRequestArgs = {
  method: Maybe<CardPositionMethodEnum>;
  purchaseRequestId: Scalars["String"];
  relativeOrderId: Maybe<Scalars["String"]>;
};

export type MutationCreateOrderFromPurchaseRequestItemsArgs = {
  purchaseRequestItems: Array<Scalars["String"]>;
};

export type MutationCreateOrderFromRfxArgs = {
  hideRfx: Maybe<Scalars["Boolean"]>;
  isCreatedFromSourcingEvent: Maybe<Scalars["Boolean"]>;
  method: Maybe<CardPositionMethodEnum>;
  relativeOrderId: Maybe<Scalars["String"]>;
  rfxId: Scalars["String"];
  rfxItemIds: Maybe<Array<Scalars["String"]>>;
  supplierId: Maybe<Scalars["String"]>;
};

export type MutationCreateOrderInvoiceFromOrderArgs = {
  orderId: Scalars["String"];
};

export type MutationCreateOrganizationArgs = {
  address: Scalars["String"];
  areaCode: Scalars["String"];
  baseCurrency: CurrencyEnum;
  city: Scalars["String"];
  companyName: Scalars["String"];
  countryCode: CountryCodeEnum;
  urlName: Scalars["String"];
};

export type MutationCreateOrganizationAddressArgs = {
  address: Scalars["String"];
  addressType?: Maybe<AddressTypeEnum>;
  areaCode: Scalars["String"];
  city: Scalars["String"];
  countryCode: CountryCodeEnum;
  locationName: Maybe<Scalars["String"]>;
  organizationId: Scalars["String"];
  recipientName: Maybe<Scalars["String"]>;
};

export type MutationCreateOrganizationUserArgs = {
  countryCode: Maybe<CountryCodeEnum>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  organizationId: Scalars["String"];
  phoneNumber: Maybe<Scalars["String"]>;
  roles: Array<UserRoleEnum>;
};

export type MutationCreateOrUpdatePurchaseRequestFromPortalArgs = {
  attachments: Maybe<Array<Scalars["Upload"]>>;
  costCentreId: Maybe<Scalars["String"]>;
  departmentId: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  notes: Maybe<Scalars["String"]>;
  organizationId: Scalars["String"];
  projectId: Maybe<Scalars["String"]>;
  purchaseRequestId: Maybe<Scalars["String"]>;
  status: PurchaseRequestStatusEnum;
};

export type MutationCreateOrUpdateSourcingEventArgs = {
  additionalInfo: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  organizationId: Scalars["String"];
  questionnaire: Maybe<Scalars["String"]>;
  responseDeadline: Maybe<Scalars["Date"]>;
  rfxId: Scalars["String"];
  selectedAttachmentIds: Maybe<Array<Scalars["String"]>>;
  sourcingEventId: Maybe<Scalars["String"]>;
  uploadedAttachments: Maybe<Array<Scalars["Upload"]>>;
};

export type MutationCreateProjectArgs = {
  name: Scalars["String"];
  organizationId: Scalars["String"];
};

export type MutationCreatePurchaseRequestArgs = {
  organizationId: Scalars["String"];
};

export type MutationCreatePurchaseRequestAttachmentsArgs = {
  attachments: Array<Scalars["Upload"]>;
  organizationId: Scalars["String"];
  purchaseRequestId: Scalars["String"];
};

export type MutationCreatePurchaseRequestFromPortalArgs = {
  attachments: Maybe<Array<Scalars["Upload"]>>;
  costCentreId: Maybe<Scalars["String"]>;
  departmentId: Maybe<Scalars["String"]>;
  items: Maybe<Array<PurchaseRequestItemInput>>;
  name: Scalars["String"];
  notes: Maybe<Scalars["String"]>;
  organizationId: Scalars["String"];
  projectId: Maybe<Scalars["String"]>;
  requestorId: Maybe<Scalars["String"]>;
  status: PurchaseRequestStatusEnum;
};

export type MutationCreatePurchaseRequestItemAttachmentsArgs = {
  attachments: Array<Scalars["Upload"]>;
  organizationId: Scalars["String"];
  purchaseRequestItemId: Scalars["String"];
};

export type MutationCreatePurchaseRequestProductArgs = {
  additionalInfo: Maybe<Scalars["String"]>;
  attachments: Maybe<Array<Scalars["Upload"]>>;
  code: Maybe<Scalars["String"]>;
  currency: Maybe<CurrencyEnum>;
  customFields: Maybe<Scalars["String"]>;
  esourcingFields: Maybe<Scalars["String"]>;
  expectedDeliveryDate: Maybe<Scalars["Date"]>;
  name: Scalars["String"];
  productUrl: Maybe<Scalars["String"]>;
  purchaseRequestId: Scalars["String"];
  quantity: Scalars["Float"];
  suggestedSupplier: Maybe<Scalars["String"]>;
  supplierCode: Maybe<Scalars["String"]>;
  unit: Scalars["String"];
  unitPrice: Maybe<Scalars["Float"]>;
};

export type MutationCreatePurchaseRequestServiceArgs = {
  additionalInfo: Maybe<Scalars["String"]>;
  attachments: Maybe<Array<Scalars["Upload"]>>;
  code: Maybe<Scalars["String"]>;
  currency: Maybe<CurrencyEnum>;
  customFields: Maybe<Scalars["String"]>;
  esourcingFields: Maybe<Scalars["String"]>;
  expectedDeliveryDate: Maybe<Scalars["Date"]>;
  name: Scalars["String"];
  productUrl: Maybe<Scalars["String"]>;
  purchaseRequestId: Scalars["String"];
  suggestedSupplier: Maybe<Scalars["String"]>;
  supplierCode: Maybe<Scalars["String"]>;
  unitPrice: Maybe<Scalars["Float"]>;
};

export type MutationCreateQuantityUnitArgs = {
  name: Scalars["String"];
  organizationId: Scalars["String"];
};

export type MutationCreateRequestorArgs = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  organizationId: Scalars["String"];
};

export type MutationCreateRfxFromPurchaseRequestArgs = {
  method: Maybe<CardPositionMethodEnum>;
  purchaseRequestId: Scalars["String"];
  relativeRfxId: Maybe<Scalars["String"]>;
};

export type MutationCreateRfxFromPurchaseRequestItemsArgs = {
  purchaseRequestItems: Array<Scalars["String"]>;
};

export type MutationCreateSupplierArgs = {
  countryCode: Maybe<CountryCodeEnum>;
  email: Scalars["String"];
  name: Maybe<Scalars["String"]>;
  organizationId: Scalars["String"];
};

export type MutationCreateSupplierAttachmentArgs = {
  attachments: Array<Scalars["Upload"]>;
  documentDate: Maybe<Scalars["Date"]>;
  notes: Scalars["String"];
  organizationId: Scalars["String"];
  supplierId: Scalars["String"];
  validityDate: Maybe<Scalars["Date"]>;
};

export type MutationCreateUserArgs = {
  countryCode: Maybe<CountryCodeEnum>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  organizationId: Scalars["String"];
  phoneNumber: Maybe<Scalars["String"]>;
  roles: Array<UserRoleEnum>;
};

export type MutationDeactivateOrganizationArgs = {
  organizationId: Scalars["String"];
};

export type MutationDeactivateUserArgs = {
  userId: Scalars["String"];
};

export type MutationDeleteApiKeyArgs = {
  apiKeyId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type MutationDeleteApprovalRequestArgs = {
  approvalRequestId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type MutationDeleteCostCentreArgs = {
  costCentreId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type MutationDeleteDepartmentArgs = {
  departmentId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type MutationDeleteProjectArgs = {
  organizationId: Scalars["String"];
  projectId: Scalars["String"];
};

export type MutationDeletePurchaseRequestItemArgs = {
  purchaseRequestItemId: Scalars["String"];
};

export type MutationDiscardPurchaseRequestArgs = {
  purchaseRequestId: Scalars["String"];
};

export type MutationFullTextSearchArgs = {
  filter: Maybe<KanbanFilterInput>;
  organizationId: Scalars["String"];
  searchInput: Scalars["String"];
};

export type MutationInviteToOrganizationArgs = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  organizationId: Scalars["String"];
};

export type MutationLoginAsArgs = {
  email: Scalars["String"];
};

export type MutationLoginSupplierUserWithTokenArgs = {
  token: Scalars["String"];
};

export type MutationLoginWithPasswordArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type MutationLoginWithTokenArgs = {
  token: Scalars["String"];
};

export type MutationMarkAllNotificationsSeenArgs = {
  organizationId: Scalars["String"];
};

export type MutationMarkInboundEmailReadStatusArgs = {
  hasBeenRead?: Scalars["Boolean"];
  inboundEmailId: Scalars["String"];
};

export type MutationMarkNotificationsSeenByIdsArgs = {
  notificationIds: Array<Scalars["String"]>;
};

export type MutationRemoveOrganizationFromUserArgs = {
  organizationId: Scalars["String"];
  userId: Scalars["String"];
};

export type MutationRemovePurchaseRequestAttachmentArgs = {
  attachmentId: Scalars["String"];
  purchaseRequestId: Scalars["String"];
};

export type MutationRemovePurchaseRequestItemArgs = {
  purchaseRequestItemId: Scalars["String"];
};

export type MutationRemovePurchaseRequestItemAttachmentArgs = {
  attachmentId: Scalars["String"];
  purchaseRequestItemId: Scalars["String"];
};

export type MutationRemoveSupplierCategoryArgs = {
  categoryId: Scalars["String"];
  organizationId: Scalars["String"];
  supplierId: Scalars["String"];
};

export type MutationRemoveSupplierFromRfxArgs = {
  rfxId: Scalars["String"];
  supplierId: Scalars["String"];
};

export type MutationSendEmailToRequesterArgs = {
  attachments: Maybe<Array<Scalars["Upload"]>>;
  cardId: Scalars["String"];
  cardType: EmailCardTypeEnum;
  ccEmail: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  parentId: Maybe<Scalars["String"]>;
  requesterId: Scalars["String"];
  subject: Scalars["String"];
  toEmail: Maybe<Scalars["String"]>;
};

export type MutationSendEmailToSuppliersArgs = {
  attachments: Maybe<Array<Scalars["Upload"]>>;
  cardId: Scalars["String"];
  cardType: EmailCardTypeEnum;
  ccEmail: Maybe<Scalars["String"]>;
  existingAttachmentIds: Maybe<Array<Scalars["String"]>>;
  includePurchaseOrderPdf?: Maybe<Scalars["Boolean"]>;
  message: Scalars["String"];
  parentId: Maybe<Scalars["String"]>;
  subject: Scalars["String"];
  supplierIds: Array<Scalars["String"]>;
  toEmail: Maybe<Scalars["String"]>;
};

export type MutationSendLoginTokenArgs = {
  email: Scalars["String"];
};

export type MutationSendSourcingRequestArgs = {
  attachments: Maybe<Array<Scalars["Upload"]>>;
  existingAttachmentIds: Maybe<Array<Scalars["String"]>>;
  message: Scalars["String"];
  sourcingEventId: Scalars["String"];
  subject: Scalars["String"];
  supplierIds: Array<Scalars["String"]>;
};

export type MutationSetDefaultContactPersonArgs = {
  contactPersonId: Scalars["String"];
};

export type MutationSupplierFullTextSearchArgs = {
  organizationId: Scalars["String"];
  rfxId: Scalars["String"];
  searchInput: Scalars["String"];
};

export type MutationSupplierLookupArgs = {
  email: Scalars["String"];
  organizationId: Scalars["String"];
  sourcingEventLinkToken: Scalars["String"];
};

export type MutationSupplierSelfRegistrationArgs = {
  city: Maybe<Scalars["String"]>;
  companyName: Scalars["String"];
  contactName: Scalars["String"];
  countryCode: Maybe<CountryCodeEnum>;
  duns: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  euVatNumber: Maybe<Scalars["String"]>;
  organizationId: Scalars["String"];
  position: Maybe<Scalars["String"]>;
  sourcingEventContactPersonId: Scalars["String"];
  sourcingEventLinkToken: Scalars["String"];
  url: Maybe<Scalars["String"]>;
  zip: Maybe<Scalars["String"]>;
};

export type MutationTestApiArgs = {
  testApiKey: Scalars["String"];
};

export type MutationUpdateApprovalRequestArgs = {
  approvalRequestId: Scalars["String"];
  comment: Maybe<Scalars["String"]>;
  decision: ApprovalRequestDecisionEnum;
};

export type MutationUpdateApprovalWorkflowDataArgs = {
  approvalAmount: Maybe<Scalars["Float"]>;
  approvalWorkflowId: Scalars["String"];
  comment: Maybe<Scalars["String"]>;
  keepRequesterUpdated: Scalars["Boolean"];
  requestName: Maybe<Scalars["String"]>;
  singleApprovalRequired: Scalars["Boolean"];
};

export type MutationUpdateApprovalWorkflowStatusArgs = {
  approvalWorkflowId: Scalars["String"];
  cancellingComment: Maybe<Scalars["String"]>;
  status: ApprovalWorkflowStatusEnum;
};

export type MutationUpdateCardPositionArgs = {
  itemId: Scalars["String"];
  method: CardPositionMethodEnum;
  relativeId: Scalars["String"];
  type: CardTypeEnum;
};

export type MutationUpdateContactPersonArgs = {
  contactPersonId: Scalars["String"];
  email: Scalars["String"];
  firstName: Maybe<Scalars["String"]>;
  isDefault: Scalars["Boolean"];
  lastName: Maybe<Scalars["String"]>;
  notes: Maybe<Scalars["String"]>;
  phoneNumber: Maybe<Scalars["String"]>;
  position: Maybe<Scalars["String"]>;
};

export type MutationUpdateCostCentreArgs = {
  costCentreId: Scalars["String"];
  name: Scalars["String"];
  organizationId: Scalars["String"];
};

export type MutationUpdateDepartmentArgs = {
  code: Maybe<Scalars["String"]>;
  departmentId: Scalars["String"];
  domain: Maybe<Scalars["String"]>;
  invoiceAddressId: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  organizationId: Scalars["String"];
  shippingAddressId: Maybe<Scalars["String"]>;
};

export type MutationUpdateOrderArgs = {
  expectedConfirmationDate: Maybe<Scalars["Date"]>;
  expectedDeliveryDate: Scalars["Date"];
  externalIdentifier: Maybe<Scalars["String"]>;
  hasBeenSent: Scalars["Boolean"];
  invoiceAddressId: Maybe<Scalars["String"]>;
  invoiceApproved: Maybe<Scalars["Boolean"]>;
  invoiceNumber: Maybe<Scalars["String"]>;
  invoicePaid: Maybe<Scalars["Boolean"]>;
  invoiceReceived: Maybe<Scalars["Boolean"]>;
  invoiceThreeWayMatch: Maybe<Scalars["Boolean"]>;
  isConfirmationRequired: Scalars["Boolean"];
  name: Maybe<Scalars["String"]>;
  orderId: Scalars["String"];
  paymentTerm: Maybe<Scalars["String"]>;
  purchaseOrderBillingNotes: Maybe<Scalars["String"]>;
  purchaseOrderCurrency: Maybe<CurrencyEnum>;
  purchaseOrderDeliveryNotes: Maybe<Scalars["String"]>;
  purchaseOrderIssueDate: Maybe<Scalars["Date"]>;
  purchaseOrderRenewalDate: Maybe<Scalars["Date"]>;
  purchaseOrderSupplierNotes: Maybe<Scalars["String"]>;
  receivingGoodsReceived: Maybe<Scalars["Boolean"]>;
  receivingGoodsReceivedPartially: Maybe<Scalars["Boolean"]>;
  receivingIsAddedToStock: Maybe<Scalars["Boolean"]>;
  receivingIsConfirmationReceived: Maybe<Scalars["Boolean"]>;
  receivingIsPoCancelled: Maybe<Scalars["Boolean"]>;
  receivingNotes: Maybe<Scalars["String"]>;
  receivingWarehouseCode: Maybe<Scalars["String"]>;
  shippingAddressId: Maybe<Scalars["String"]>;
  supplierId: Maybe<Scalars["String"]>;
  vatPercent: Maybe<Scalars["Float"]>;
};

export type MutationUpdateOrderConfirmationReceivedArgs = {
  isConfirmationReceived: Scalars["Boolean"];
  orderId: Scalars["String"];
};

export type MutationUpdateOrderHasBeenSentArgs = {
  hasBeenSent: Scalars["Boolean"];
  orderId: Scalars["String"];
};

export type MutationUpdateOrderIsAddedToStockArgs = {
  isAddedToStock: Scalars["Boolean"];
  orderId: Scalars["String"];
};

export type MutationUpdateOrderReleaseMethodArgs = {
  orderId: Scalars["String"];
  poReleaseMethod: PurchaseOrderReleaseMethodEnum;
};

export type MutationUpdateOrderStatusArgs = {
  orderId: Scalars["String"];
  status: OrderStatusEnum;
};

export type MutationUpdateOrderTypeArgs = {
  method: Maybe<CardPositionMethodEnum>;
  orderId: Scalars["String"];
  positionWithoutRelativeOrder: Maybe<Scalars["Boolean"]>;
  relativeOrderId: Maybe<Scalars["String"]>;
  type: OrderTypeEnum;
};

export type MutationUpdateOrganizationArgs = {
  address: Scalars["String"];
  areaCode: Scalars["String"];
  baseCurrency: CurrencyEnum;
  businessRegistry: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  companyName: Scalars["String"];
  countryCode: CountryCodeEnum;
  domainWhitelist: Maybe<Array<Scalars["String"]>>;
  dunsCode: Maybe<Scalars["String"]>;
  embeddedReport: Maybe<Scalars["String"]>;
  leiCode: Maybe<Scalars["String"]>;
  organizationId: Scalars["String"];
  poStatusWebhookUrl: Maybe<Scalars["String"]>;
  vatIdentification: Maybe<Scalars["String"]>;
};

export type MutationUpdateOrganizationPoTemplateArgs = {
  note: Maybe<Scalars["String"]>;
  organizationId: Scalars["String"];
  primaryColor: Maybe<Scalars["String"]>;
  secondaryColor: Maybe<Scalars["String"]>;
};

export type MutationUpdateOrganizationUserArgs = {
  costCentreId: Maybe<Scalars["String"]>;
  countryCode: Maybe<CountryCodeEnum>;
  departmentId: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  organizationId: Scalars["String"];
  phoneNumber: Maybe<Scalars["String"]>;
  projectId: Maybe<Scalars["String"]>;
  roles: Array<UserRoleEnum>;
  status: UserStatusEnum;
  userId: Scalars["String"];
};

export type MutationUpdateProfileArgs = {
  address: Maybe<Scalars["String"]>;
  areaCode: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  countryCode: Maybe<CountryCodeEnum>;
  firstName: Scalars["String"];
  id: Scalars["String"];
  lastName: Scalars["String"];
  phoneNumber: Maybe<Scalars["String"]>;
};

export type MutationUpdateProfilePictureArgs = {
  profilePicture: Scalars["Upload"];
};

export type MutationUpdateProjectArgs = {
  name: Scalars["String"];
  organizationId: Scalars["String"];
  projectId: Scalars["String"];
};

export type MutationUpdatePurchaseRequestArgs = {
  assigneeId: Maybe<Scalars["String"]>;
  costCentreId: Maybe<Scalars["String"]>;
  departmentId: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  notes: Maybe<Scalars["String"]>;
  projectId: Maybe<Scalars["String"]>;
  purchaseRequestId: Scalars["String"];
  requestorId: Scalars["String"];
  status: PurchaseRequestStatusEnum;
};

export type MutationUpdatePurchaseRequestFromPortalArgs = {
  attachments: Maybe<Array<Scalars["Upload"]>>;
  costCentreId: Maybe<Scalars["String"]>;
  departmentId: Maybe<Scalars["String"]>;
  items: Maybe<Array<PurchaseRequestItemInput>>;
  name: Scalars["String"];
  notes: Maybe<Scalars["String"]>;
  projectId: Maybe<Scalars["String"]>;
  purchaseRequestId: Scalars["String"];
  requestorId: Maybe<Scalars["String"]>;
  status: PurchaseRequestStatusEnum;
};

export type MutationUpdatePurchaseRequestItemReceivedQuantitiesArgs = {
  areReceivingGoodsReceived: Scalars["Boolean"];
  orderId: Scalars["String"];
  organizationId: Scalars["String"];
  purchaseRequestReceivedItems: Maybe<Array<PurchaseRequestItemReceivedInput>>;
};

export type MutationUpdatePurchaseRequestProductArgs = {
  additionalInfo: Maybe<Scalars["String"]>;
  attachments: Maybe<Array<Scalars["Upload"]>>;
  code: Maybe<Scalars["String"]>;
  currency: Maybe<CurrencyEnum>;
  customFields: Maybe<Scalars["String"]>;
  esourcingFields: Maybe<Scalars["String"]>;
  expectedDeliveryDate: Maybe<Scalars["Date"]>;
  name: Scalars["String"];
  productUrl: Maybe<Scalars["String"]>;
  purchaseRequestItemId: Scalars["String"];
  quantity: Scalars["Float"];
  receivedQuantity: Maybe<Scalars["Float"]>;
  suggestedSupplier: Maybe<Scalars["String"]>;
  supplierCode: Maybe<Scalars["String"]>;
  unit: Maybe<Scalars["String"]>;
  unitPrice: Maybe<Scalars["Float"]>;
};

export type MutationUpdatePurchaseRequestServiceArgs = {
  additionalInfo: Maybe<Scalars["String"]>;
  attachments: Maybe<Array<Scalars["Upload"]>>;
  code: Maybe<Scalars["String"]>;
  currency: Maybe<CurrencyEnum>;
  customFields: Maybe<Scalars["String"]>;
  esourcingFields: Maybe<Scalars["String"]>;
  expectedDeliveryDate: Maybe<Scalars["Date"]>;
  name: Scalars["String"];
  productUrl: Maybe<Scalars["String"]>;
  purchaseRequestItemId: Scalars["String"];
  suggestedSupplier: Maybe<Scalars["String"]>;
  supplierCode: Maybe<Scalars["String"]>;
  unitPrice: Maybe<Scalars["Float"]>;
};

export type MutationUpdatePurchaseRequestStatusArgs = {
  purchaseRequestId: Scalars["String"];
  status: PurchaseRequestStatusEnum;
};

export type MutationUpdateRfxArgs = {
  isRfiComplete: Scalars["Boolean"];
  isRfpComplete: Scalars["Boolean"];
  isRfqComplete: Scalars["Boolean"];
  name: Scalars["String"];
  rfxId: Scalars["String"];
  type: RfxTypeEnum;
};

export type MutationUpdateRfxNameArgs = {
  name: Scalars["String"];
  rfxId: Scalars["String"];
};

export type MutationUpdateRfxTypeArgs = {
  rfxId: Scalars["String"];
  type: RfxTypeEnum;
};

export type MutationUpdateSourcingEventItemWinnersArgs = {
  itemIds: Array<Scalars["String"]>;
  markRfxAsComplete: Maybe<Scalars["Boolean"]>;
  rfxId: Scalars["String"];
  sourcingEventId: Scalars["String"];
  supplierId: Scalars["String"];
};

export type MutationUpdateSourcingRequestArgs = {
  attachments: Maybe<Array<Scalars["Upload"]>>;
  comment: Maybe<Scalars["String"]>;
  quotation: Scalars["String"];
  sourcingRequestId: Scalars["String"];
};

export type MutationUpdateSupplierArgs = {
  address: Maybe<Scalars["String"]>;
  areaCode: Maybe<Scalars["String"]>;
  businessRegistry: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  countryCode: Maybe<CountryCodeEnum>;
  currency: Maybe<CurrencyEnum>;
  customField1: Maybe<Scalars["String"]>;
  customField2: Maybe<Scalars["String"]>;
  customField3: Maybe<Scalars["String"]>;
  dunsCode: Maybe<Scalars["String"]>;
  erpSupplierCode: Maybe<Scalars["String"]>;
  leiCode: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  notes: Maybe<Scalars["String"]>;
  paymentTerms: Maybe<Scalars["String"]>;
  status: Maybe<SupplierStatusEnum>;
  supplierId: Scalars["String"];
  vatIdentification: Maybe<Scalars["String"]>;
  webAddress: Maybe<Scalars["String"]>;
};

export type MutationUpdateUserArgs = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  organizationIds: Array<Scalars["String"]>;
  roles: Array<UserRoleEnum>;
  status: UserStatusEnum;
  userId: Scalars["String"];
};

export type MutationUpdateWhitelistedDomainsArgs = {
  domainWhitelist: Maybe<Array<Scalars["String"]>>;
  organizationId: Scalars["String"];
};

export type MutationUploadOrganizationLogoArgs = {
  companyLogo: Scalars["Upload"];
  organizationId: Scalars["String"];
};

export type MutationUploadProductExcelArgs = {
  excel: Scalars["Upload"];
  name: Scalars["String"];
  organizationId: Scalars["String"];
  purchaseRequestId: Maybe<Scalars["String"]>;
};

export type MutationUploadQuotationPricesExcelArgs = {
  excel: Scalars["Upload"];
  organizationId: Scalars["String"];
  sourcingRequestId: Scalars["String"];
};

export type MutationUploadSupplierExcelArgs = {
  excel: Scalars["Upload"];
  organizationId: Scalars["String"];
};

export type Notification = {
  __typename?: "Notification";
  /** Notification activity */
  activity: ActivityFeed;
  /** Activity id */
  activityId: Scalars["ID"];
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Has seen */
  hasSeen: Scalars["Boolean"];
  /** Notification id */
  id: Scalars["ID"];
  /** Date of update */
  updatedDate: Scalars["Date"];
  /** User id */
  userId: Scalars["ID"];
};

export type Order = {
  __typename?: "Order";
  /** Order related activities */
  activities: Array<ActivityFeed>;
  /** Order assignee */
  assignee: Maybe<User>;
  /** Order assignee id */
  assigneeId: Maybe<Scalars["ID"]>;
  /** Order attachments */
  attachments: Maybe<Array<Attachment>>;
  cardStatus: CardStatus;
  /** Purchase order code */
  code: Scalars["String"];
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Order creator */
  creator: User;
  /** Order creator id */
  creatorId: Scalars["ID"];
  /** Date of deletion */
  deletedDate: Maybe<Scalars["Date"]>;
  /** Order department */
  department: Maybe<Department>;
  /** Department id */
  departmentId: Maybe<Scalars["ID"]>;
  /** Purchase order expected confirmation date */
  expectedConfirmationDate: Maybe<Scalars["Date"]>;
  /** Purchase order expected delivery date */
  expectedDeliveryDate: Maybe<Scalars["Date"]>;
  /** External ERP identifier */
  externalIdentifier: Maybe<Scalars["String"]>;
  /** Purchase order has been sent */
  hasBeenSent: Scalars["Boolean"];
  /** Order unique id */
  id: Scalars["ID"];
  /** Order invoice address */
  invoiceAddress: Maybe<Address>;
  /** Order invoice address id */
  invoiceAddressId: Maybe<Scalars["ID"]>;
  /** Invoice approved */
  invoiceApproved: Scalars["Boolean"];
  /** Invoice sent to billing */
  invoiceIsSentToBilling: Scalars["Boolean"];
  /** Invoice number */
  invoiceNumber: Maybe<Scalars["String"]>;
  /** Invoice paid */
  invoicePaid: Scalars["Boolean"];
  /** Invoice received */
  invoiceReceived: Scalars["Boolean"];
  /** Invoice 3-way match */
  invoiceThreeWayMatch: Scalars["Boolean"];
  /** Purchase order confirmation required */
  isConfirmationRequired: Scalars["Boolean"];
  /** Order items */
  items: Array<PurchaseRequestItem>;
  /** Purchase Order's latest Approval Workflow */
  latestApprovalWorkflow: Maybe<ApprovalWorkflow>;
  /** Order name */
  name: Scalars["String"];
  /** Order organization id */
  organizationId: Scalars["ID"];
  /** Purchase order payment term */
  paymentTerm: Maybe<Scalars["String"]>;
  /** Method how was PO released */
  poReleaseMethod: Maybe<PurchaseOrderReleaseMethodEnum>;
  /** Order projects */
  projects: Array<Project>;
  /** Confirmation notes */
  purchaseConfirmationNotes: Maybe<Scalars["String"]>;
  /** Purchase order delivery notes */
  purchaseOrderBillingNotes: Maybe<Scalars["String"]>;
  /** Purchase order currency code */
  purchaseOrderCurrency: Maybe<CurrencyEnum>;
  /** Purchase order delivery notes */
  purchaseOrderDeliveryNotes: Maybe<Scalars["String"]>;
  /** Purchase order expected date */
  purchaseOrderExpectedDate: Maybe<Scalars["Date"]>;
  /** Purchase order issuing date */
  purchaseOrderIssueDate: Maybe<Scalars["Date"]>;
  /** Purchase order renewal date */
  purchaseOrderRenewalDate: Maybe<Scalars["Date"]>;
  /** Purchase order supplier notes */
  purchaseOrderSupplierNotes: Maybe<Scalars["String"]>;
  /** Goods received */
  receivingGoodsReceived: Scalars["Boolean"];
  /** Goods received partially */
  receivingGoodsReceivedPartially: Scalars["Boolean"];
  /** Receive is added to stock */
  receivingIsAddedToStock: Scalars["Boolean"];
  /** Confirmation received */
  receivingIsConfirmationReceived: Scalars["Boolean"];
  /** Receive is po cancelled */
  receivingIsPoCancelled: Scalars["Boolean"];
  /** Receiving notes */
  receivingNotes: Maybe<Scalars["String"]>;
  /** Warehouse code */
  receivingWarehouseCode: Maybe<Scalars["String"]>;
  /** Order requestors */
  requestors: Array<User>;
  /** Order's backtrack to Rfx */
  rfx: Maybe<Rfx>;
  /** Order shipping address */
  shippingAddress: Maybe<Address>;
  /** Order shipping address id */
  shippingAddressId: Maybe<Scalars["ID"]>;
  /** Order status */
  status: Maybe<OrderStatusEnum>;
  /** Purchase order status updated date */
  statusUpdatedDate: Maybe<Scalars["Date"]>;
  /** Order supplier */
  supplier: Maybe<Supplier>;
  /** Order supplier id */
  supplierId: Maybe<Scalars["ID"]>;
  /** Order type */
  type: OrderTypeEnum;
  /** Date of update */
  updatedDate: Scalars["Date"];
  /** VAT percent (in fractional representation) */
  vatPercent: Maybe<Scalars["Float"]>;
};

export type OrderItemsArgs = {
  withDeleted: Maybe<Scalars["Boolean"]>;
};

export enum OrderStatusEnum {
  INVOICE_APPROVED = "INVOICE_APPROVED",
  INVOICE_PAID = "INVOICE_PAID",
  INVOICE_RECEIVED = "INVOICE_RECEIVED",
  INVOICE_THREE_WAY_MATCH = "INVOICE_THREE_WAY_MATCH",
  PO_CANCELLED = "PO_CANCELLED",
  PO_DRAFT = "PO_DRAFT",
  PO_NOT_RECEIVED = "PO_NOT_RECEIVED",
  PO_READY = "PO_READY",
  PO_RECEIVED = "PO_RECEIVED",
  PO_RECEIVED_PARTIALLY = "PO_RECEIVED_PARTIALLY",
}

export enum OrderTypeEnum {
  INVOICE = "INVOICE",
  PURCHASE_ORDER = "PURCHASE_ORDER",
  RECEIVING = "RECEIVING",
}

export type Organization = {
  __typename?: "Organization";
  /** Returns list of activities related to organization */
  activities: Array<ActivityFeed>;
  /** Returns list of activities by relative id */
  activitiesByRelativeId: Array<ActivityFeed>;
  /** Returns activity by id */
  activityById: ActivityFeed;
  /** Organization address */
  address: Scalars["String"];
  /** Organization address book */
  addressBook: Array<AddressBook>;
  /** Organization addresses */
  addresses: Array<Address>;
  /** Organization api keys */
  apiKeys: Array<ApiKey>;
  /** List of user (approver) Approval Workflows by status */
  approvalWorkflowsByStatus: Array<ApprovalWorkflow>;
  /** Organization approver groups */
  approverGroups: Array<ApproverGroup>;
  /** Organization area code */
  areaCode: Scalars["String"];
  /** Organization base currency */
  baseCurrency: CurrencyEnum;
  /** Organization business registry code */
  businessRegistry: Maybe<Scalars["String"]>;
  /** Organization buyers */
  buyers: Array<User>;
  /** Organization categories */
  categories: Array<Category>;
  /** Organization city */
  city: Scalars["String"];
  /** Organization logo url */
  companyLogoUrl: Maybe<Scalars["String"]>;
  /** Organization company name */
  companyName: Scalars["String"];
  /** Returns cost centre by id */
  costCentreById: CostCentre;
  /** Organization costCentres */
  costCentres: Array<CostCentre>;
  /** Organization country */
  country: Country;
  /** Organization country code */
  countryCode: CountryCodeEnum;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Returns department by id */
  departmentById: Department;
  /** Organization departments */
  departments: Array<Department>;
  /** Organization inbound email domain whitelist */
  domainWhitelist: Maybe<Array<Scalars["String"]>>;
  /** Organization D-U-N-S code */
  dunsCode: Maybe<Scalars["String"]>;
  /** Organization embedded report url */
  embeddedReport: Maybe<Scalars["String"]>;
  /** Organization unique id */
  id: Scalars["ID"];
  /** Organization invoices */
  invoices: Array<Order>;
  /** Organization active status */
  isActive: Scalars["Boolean"];
  /** Organization labels */
  labels: Array<Label>;
  /** Organization LEI code */
  leiCode: Maybe<Scalars["String"]>;
  /** Organization modules */
  modules: Maybe<Scalars["JSON"]>;
  /** Returns list users notifications */
  notifications: Array<Notification>;
  /** Organization orders */
  orders: Array<Order>;
  /** Returns supplier by id */
  organizationSupplierById: Supplier;
  /** Organization paginated invoice orders */
  paginatedInvoices: PaginatedOrderResponse;
  /** Organization paginated orders */
  paginatedOrders: PaginatedOrderResponse;
  /** Organization paginated purchase requests */
  paginatedPurchaseRequests: PaginatedPurchaseRequestsResponse;
  /** Organization paginated receiving orders */
  paginatedReceiving: PaginatedOrderResponse;
  /** Organization paginated rfx */
  paginatedRfx: PaginatedRfxResponse;
  /** Organization po status webhook url */
  poStatusWebhookUrl: Maybe<Scalars["String"]>;
  /** Organization purchase request email address */
  prEmailAddress: Scalars["String"];
  /** Returns project by id */
  projectById: Project;
  /** Organization projects */
  projects: Array<Project>;
  /** Organization purchase requests */
  purchaseRequests: Array<PurchaseRequest>;
  /** Organization quantity units */
  quantityUnits: Array<QuantityUnit>;
  /** Organization receiving orders */
  receiving: Array<Order>;
  /** Organization rfx */
  rfx: Array<Rfx>;
  /** Organization sourcing events */
  sourcingEvents: Array<SourcingEvent>;
  /** Organization subordination hierarchy */
  subordinations: Array<Subordination>;
  /** Organization suppliers */
  suppliers: Array<Supplier>;
  /** Date of update */
  updatedDate: Scalars["Date"];
  /** Organization url name */
  urlName: Scalars["String"];
  /** Returns user by id */
  userById: User;
  /** Organization users */
  users: Array<User>;
  /** Organization VAT identification */
  vatIdentification: Maybe<Scalars["String"]>;
};

export type OrganizationActivitiesArgs = {
  skip: Maybe<Scalars["Int"]>;
  take: Maybe<Scalars["Int"]>;
};

export type OrganizationActivitiesByRelativeIdArgs = {
  relativeId: Scalars["String"];
};

export type OrganizationActivityByIdArgs = {
  activityId: Scalars["String"];
};

export type OrganizationApprovalWorkflowsByStatusArgs = {
  approvalWorkflowStatuses: Array<ApprovalWorkflowStatusEnum>;
};

export type OrganizationCostCentreByIdArgs = {
  costCentreId: Scalars["String"];
};

export type OrganizationDepartmentByIdArgs = {
  departmentId: Scalars["String"];
};

export type OrganizationInvoicesArgs = {
  filter: Maybe<KanbanFilterInput>;
};

export type OrganizationNotificationsArgs = {
  skip: Maybe<Scalars["Int"]>;
  skipSeen: Maybe<Scalars["Boolean"]>;
  take: Maybe<Scalars["Int"]>;
};

export type OrganizationOrdersArgs = {
  filter: Maybe<KanbanFilterInput>;
};

export type OrganizationOrganizationSupplierByIdArgs = {
  supplierId: Scalars["String"];
};

export type OrganizationPaginatedInvoicesArgs = {
  cursor: Maybe<Scalars["String"]>;
  filter: Maybe<KanbanFilterInput>;
  take: Maybe<Scalars["Int"]>;
};

export type OrganizationPaginatedOrdersArgs = {
  cursor: Maybe<Scalars["String"]>;
  filter: Maybe<KanbanFilterInput>;
  take: Maybe<Scalars["Int"]>;
};

export type OrganizationPaginatedPurchaseRequestsArgs = {
  cursor: Maybe<Scalars["String"]>;
  filter: Maybe<KanbanFilterInput>;
  keyword: Maybe<SearchInput>;
  take: Maybe<Scalars["Int"]>;
};

export type OrganizationPaginatedReceivingArgs = {
  cursor: Maybe<Scalars["String"]>;
  filter: Maybe<KanbanFilterInput>;
  take: Maybe<Scalars["Int"]>;
};

export type OrganizationPaginatedRfxArgs = {
  cursor: Maybe<Scalars["String"]>;
  filter: Maybe<KanbanFilterInput>;
  keyword: Maybe<SearchInput>;
  take: Maybe<Scalars["Int"]>;
};

export type OrganizationProjectByIdArgs = {
  projectId: Scalars["String"];
};

export type OrganizationPurchaseRequestsArgs = {
  cursor: Maybe<Scalars["String"]>;
  filter: Maybe<KanbanFilterInput>;
  keyword: Maybe<SearchInput>;
  skip: Maybe<Scalars["Int"]>;
  take: Maybe<Scalars["Int"]>;
};

export type OrganizationReceivingArgs = {
  filter: Maybe<KanbanFilterInput>;
};

export type OrganizationRfxArgs = {
  filter: Maybe<KanbanFilterInput>;
  keyword: Maybe<SearchInput>;
};

export type OrganizationSuppliersArgs = {
  withArchived: Maybe<Scalars["Boolean"]>;
};

export type OrganizationUserByIdArgs = {
  userId: Scalars["String"];
};

export type OrganizationUsersArgs = {
  email: Maybe<Scalars["String"]>;
};

export type OrganizationPublic = {
  __typename?: "OrganizationPublic";
  /** Organization logo url */
  companyLogoUrl: Maybe<Scalars["String"]>;
  /** Organization company name */
  companyName: Scalars["String"];
  /** Organization ID */
  id: Scalars["ID"];
};

export type OutboundEmail = {
  __typename?: "OutboundEmail";
  /** Related activity feed id */
  activityId: Maybe<Scalars["ID"]>;
  /** Outbound email cc address */
  ccEmail: Maybe<Scalars["String"]>;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Creator id */
  creatorId: Maybe<Scalars["ID"]>;
  /** Outbound email raw message data */
  data: Maybe<Scalars["String"]>;
  /** Outbound email from address */
  fromEmail: Maybe<Scalars["String"]>;
  /** Outbound email has attachments */
  hasAttachments: Maybe<Scalars["Boolean"]>;
  /** Outbound email html body */
  htmlBody: Maybe<Scalars["String"]>;
  /** Outbound email unique id */
  id: Scalars["ID"];
  /** Outbound emails parent (outbound) email id */
  parentId: Maybe<Scalars["ID"]>;
  /** Related card id */
  relativeId: Scalars["ID"];
  /** Card's relative type */
  relativeType: ActivityRelativeTypeEnum;
  /** Related requester id */
  requesterId: Maybe<Scalars["ID"]>;
  /** Outbound email sent rejected reason */
  sendRejectedReason: Maybe<Scalars["String"]>;
  /** Outbound email sent status */
  sendStatus: Maybe<EmailStatusEnum>;
  /** Outbound email subject */
  subject: Maybe<Scalars["String"]>;
  /** Related supplier id */
  supplierId: Maybe<Scalars["ID"]>;
  /** Outbound email text body */
  textBody: Maybe<Scalars["String"]>;
  /** Outbound email to address */
  toEmail: Maybe<Scalars["String"]>;
  /** Outbound email type */
  type: OutboundEmailTypeEnum;
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export enum OutboundEmailTypeEnum {
  ACKNOWLEDGEMENT_REPLY = "ACKNOWLEDGEMENT_REPLY",
  MENTION = "MENTION",
  REQUESTER = "REQUESTER",
  SUPPLIER = "SUPPLIER",
}

export type PageInfo = {
  __typename?: "PageInfo";
  /** Pagination next cursor */
  endCursor: Maybe<Scalars["String"]>;
  /** Pagination has next page */
  hasNextPage: Scalars["Boolean"];
};

export type PaginatedOrderResponse = {
  __typename?: "PaginatedOrderResponse";
  /** List of orders */
  edges: Array<Order>;
  /** Order pagination page info */
  pageInfo: PageInfo;
};

export type PaginatedPurchaseRequestsResponse = {
  __typename?: "PaginatedPurchaseRequestsResponse";
  /** List of purchase requests */
  edges: Array<PurchaseRequest>;
  /** Purchase request pagination page info */
  pageInfo: PageInfo;
};

export type PaginatedRfxResponse = {
  __typename?: "PaginatedRfxResponse";
  /** List of rfx */
  edges: Array<Rfx>;
  /** Rfx pagination page info */
  pageInfo: PageInfo;
};

export type Project = {
  __typename?: "Project";
  /** Project costCentre */
  costCentre: Maybe<CostCentre>;
  /** Project cost centre id */
  costCentreId: Maybe<Scalars["ID"]>;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Project creator */
  creator: User;
  /** Project creator id */
  creatorId: Scalars["ID"];
  /** Project unique id */
  id: Scalars["ID"];
  /** Project name */
  name: Scalars["String"];
  /** Organization id */
  organizationId: Scalars["ID"];
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export enum PurchaseOrderReleaseMethodEnum {
  RELEASE_THROUGH_ERP = "RELEASE_THROUGH_ERP",
  SEND_EMAIL_EXTERNALLY = "SEND_EMAIL_EXTERNALLY",
  SEND_EMAIL_INTERNALLY = "SEND_EMAIL_INTERNALLY",
}

export enum PurchaseOrderTypeEnum {
  PRODUCT = "PRODUCT",
  SERVICE = "SERVICE",
}

export type PurchaseRequest = {
  __typename?: "PurchaseRequest";
  /** Purchase request activities */
  activities: Maybe<Array<ActivityFeed>>;
  /** Purchase request assignee */
  assignee: Maybe<User>;
  /** Purchase request assignee id */
  assigneeId: Maybe<Scalars["ID"]>;
  /** Purchase request attachments */
  attachments: Maybe<Array<Attachment>>;
  cardStatus: CardStatus;
  /** Purchase request code */
  code: Scalars["String"];
  /** Purchase request cost centre */
  costCentre: Maybe<CostCentre>;
  /** Purchase request cost centre id */
  costCentreId: Maybe<Scalars["ID"]>;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Purchase request creator */
  creator: User;
  /** Purchase request creator id */
  creatorId: Scalars["ID"];
  /** Purchase request department */
  department: Maybe<Department>;
  /** Purchase request department id */
  departmentId: Maybe<Scalars["ID"]>;
  /** Purchase request inbound and outbound emails */
  emails: Array<Email>;
  /** Purchase request unique id */
  id: Scalars["ID"];
  /** Purchase request is order created */
  isOrderCreated: Scalars["Boolean"];
  /** Purchase request is rfx created */
  isRfxCreated: Scalars["Boolean"];
  /** Purchase request is vat included */
  isVatIncluded: Scalars["Boolean"];
  /** Purchase request items */
  items: Array<PurchaseRequestItem>;
  /** Purchase Request's latest Approval Workflow */
  latestApprovalWorkflow: Maybe<ApprovalWorkflow>;
  /** Purchase request name */
  name: Scalars["String"];
  /** Purchase request notes */
  notes: Scalars["String"];
  /** Purchase request organization id */
  organizationId: Scalars["ID"];
  /** Purchase request project */
  project: Maybe<Project>;
  /** Purchase request project id */
  projectId: Maybe<Scalars["ID"]>;
  /** Purchase request requestor */
  requestor: User;
  /** Purchase request requestor id */
  requestorId: Scalars["ID"];
  /** Purchase request status */
  status: PurchaseRequestStatusEnum;
  /** Purchase request suppliers */
  suppliers: Array<Supplier>;
  /** Date of update */
  updatedDate: Scalars["Date"];
  /** Purchase request vat */
  vat: Scalars["Float"];
};

export type PurchaseRequestItem = {
  __typename?: "PurchaseRequestItem";
  /** Purchase request item additional info */
  additionalInfo: Maybe<Scalars["String"]>;
  /** Purchase request item assignee */
  assignee: Maybe<User>;
  /** Purchase request item attachments */
  attachments: Maybe<Array<Attachment>>;
  /** Purchase request item code */
  code: Maybe<Scalars["String"]>;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Purchase request item currency */
  currency: Maybe<CurrencyEnum>;
  customFields: Maybe<Scalars["JSON"]>;
  esourcingFields: Maybe<Scalars["JSON"]>;
  /** Purchase request item expected delivery date */
  expectedDeliveryDate: Maybe<Scalars["Date"]>;
  /** Purchase request item external identifier */
  externalIdentifier: Maybe<Scalars["String"]>;
  /** Purchase request item history */
  history: Maybe<Array<PurchaseRequestItem>>;
  /** Purchase request item unique id */
  id: Scalars["ID"];
  /** Purchase request item is deleted */
  isDeleted: Scalars["Boolean"];
  /** Purchase request item name */
  name: Scalars["String"];
  /** Purchase request item order id */
  orderId: Maybe<Scalars["ID"]>;
  /** Organization id */
  organizationId: Scalars["ID"];
  /** Origin id */
  originId: Maybe<Scalars["ID"]>;
  /** Purchase request item code */
  parentCode: Scalars["String"];
  /** Purchase request item product url */
  productUrl: Maybe<Scalars["String"]>;
  /** Purchase request progress */
  progress: PurchaseRequestItemProgressTypeEnum;
  /** Purchase request item origin parent code */
  purchaseRequestCode: Scalars["String"];
  /** Purchase request id */
  purchaseRequestId: Scalars["ID"];
  /** Purchase request item quantity */
  quantity: Maybe<Scalars["Float"]>;
  /** Purchase request item received quantity */
  receivedQuantity: Maybe<Scalars["Float"]>;
  /** Purchase request item requestor */
  requestor: User;
  /** Purchase request item rfx id */
  rfxId: Maybe<Scalars["ID"]>;
  /** Purchase request item status */
  status: CardStatus;
  /** Suggested supplier's name by the requester */
  suggestedSupplier: Maybe<Scalars["String"]>;
  /** Purchase request item supplier code */
  supplierCode: Maybe<Scalars["String"]>;
  /** Purchase request item swimlane progress */
  swimlaneProgress: PurchaseRequestItemProgressTypeEnum;
  /** Purchase request item total received quantity */
  totalReceivedQuantity: Maybe<Scalars["Float"]>;
  /** Purchase request type */
  type: PurchaseRequestItemTypeEnum;
  /** Purchase request item unit */
  unit: Maybe<Scalars["String"]>;
  /** Purchase request item unit price */
  unitPrice: Maybe<Scalars["Float"]>;
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export type PurchaseRequestItemAssigneeArgs = {
  withDeleted: Maybe<Scalars["Boolean"]>;
};

export type PurchaseRequestItemParentCodeArgs = {
  withDeleted: Maybe<Scalars["Boolean"]>;
};

export type PurchaseRequestItemInput = {
  /** Purchase request item additional info */
  additionalInfo: Maybe<Scalars["String"]>;
  /** Purchase request attachment upload */
  attachments: Maybe<Array<Scalars["Upload"]>>;
  /** Purchase request item code */
  code: Maybe<Scalars["String"]>;
  /** Purchase request item currency */
  currency: Maybe<Scalars["String"]>;
  /** Purchase request item custom field */
  customFields: Maybe<Scalars["JSON"]>;
  /** Purchase request item esourcing field */
  esourcingFields: Maybe<Scalars["JSON"]>;
  /** Purchase request item expected delivery date */
  expectedDeliveryDate: Maybe<Scalars["Date"]>;
  /** Purchase request item id */
  id: Maybe<Scalars["String"]>;
  /** Purchase request item name */
  name: Scalars["String"];
  /** Purchase request item product url */
  productUrl: Maybe<Scalars["String"]>;
  /** Purchase request item quantity */
  quantity: Maybe<Scalars["Float"]>;
  /** Suggested supplier's name by requester */
  suggestedSupplier: Maybe<Scalars["String"]>;
  /** Purchase request item supplier code */
  supplierCode: Maybe<Scalars["String"]>;
  /** Purchase request item type */
  type: Scalars["String"];
  /** Purchase request item unit */
  unit: Maybe<Scalars["String"]>;
  /** Purchase request item unit price */
  unitPrice: Maybe<Scalars["Float"]>;
};

export enum PurchaseRequestItemProgressTypeEnum {
  ORDER = "ORDER",
  PURCHASE_REQUEST = "PURCHASE_REQUEST",
  RFX = "RFX",
}

export type PurchaseRequestItemReceivedInput = {
  /** Purchase request item id */
  id: Scalars["ID"];
  /** Purchase request item received quantity */
  receivedQuantity: Scalars["Int"];
};

export enum PurchaseRequestItemTypeEnum {
  PRODUCT = "PRODUCT",
  SERVICE = "SERVICE",
}

export enum PurchaseRequestStatusEnum {
  ARCHIVED = "ARCHIVED",
  COMPLETE = "COMPLETE",
  CREATED_FROM_EMAIL = "CREATED_FROM_EMAIL",
  DISCARDED = "DISCARDED",
  DRAFT = "DRAFT",
  IN_PROGRESS = "IN_PROGRESS",
  READY = "READY",
}

export type QuantityUnit = {
  __typename?: "QuantityUnit";
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Quantity unit id */
  id: Scalars["ID"];
  /** Quantity unit name */
  name: Scalars["String"];
  /** Organization id */
  organizationId: Scalars["String"];
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export type Query = {
  __typename?: "Query";
  /** Admin functionality (ADMIN) */
  admin: Admin;
  /** List of countries */
  countries: Array<Country>;
  /** Experiments (ADMIN) */
  experiment: Experiment;
  /** SourcingEvent public info */
  sourcingEventPublic: SourcingEventPublic;
  /** Client and server versions */
  version: Version;
  /** Logged in user */
  viewer: Maybe<Viewer>;
};

export type QuerySourcingEventPublicArgs = {
  sourcingEventLinkToken: Scalars["String"];
};

export type Reply = {
  __typename?: "REPLY";
  /** Comment text */
  comment: Scalars["String"];
  /** Relative info */
  relativeInfo: ActivityRelativeInfo;
  /** Comment author */
  user: ActivityUserInfo;
};

export type Rfx = {
  __typename?: "Rfx";
  /** RFX suppliers that are not (soft) deleted */
  activeSuppliers: Array<Supplier>;
  /** RFX related activities */
  activities: Array<ActivityFeed>;
  /** Rfx assignee */
  assignee: Maybe<User>;
  /** Purchase request assignee id */
  assigneeId: Maybe<Scalars["ID"]>;
  /** Rfx attachments */
  attachments: Maybe<Array<Attachment>>;
  cardStatus: CardStatus;
  /** Rfx code */
  code: Scalars["String"];
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Rfx creator */
  creator: User;
  /** Purchase request creator id */
  creatorId: Scalars["ID"];
  /** Date of soft-deletion */
  deletedDate: Maybe<Scalars["Date"]>;
  /** Rfx department */
  department: Maybe<Department>;
  /** Department id */
  departmentId: Maybe<Scalars["ID"]>;
  /** Rfx inbound and outbound emails */
  emails: Array<Email>;
  /** Rfx expected response date */
  expectedResponseDate: Maybe<Scalars["Date"]>;
  /** Rfx unique id */
  id: Scalars["ID"];
  /** Rfi status */
  isRfiComplete: Scalars["Boolean"];
  /** Rfp status */
  isRfpComplete: Scalars["Boolean"];
  /** Rfq status */
  isRfqComplete: Scalars["Boolean"];
  /** Rfx items */
  items: Array<PurchaseRequestItem>;
  /** Rfx name */
  name: Scalars["String"];
  /** Next RFX step */
  nextStep: Maybe<RfxNextStepEnum>;
  /** Organization id */
  organizationId: Scalars["ID"];
  /** Rfx projects */
  projects: Array<Project>;
  /** Rfx requestors */
  requestors: Array<User>;
  /** RFX sourcing event */
  sourcingEvent: Maybe<SourcingEvent>;
  /** Rfx status */
  status: RfxStatusEnum;
  /** Resolutions/decisions about the supplier in the context of RFX */
  supplierResolutions: Maybe<Array<RfxSupplierResolution>>;
  /** RFX suppliers */
  suppliers: Array<Supplier>;
  /** Rfx type */
  type: Maybe<RfxTypeEnum>;
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export type RfxItemsArgs = {
  withDeleted: Maybe<Scalars["Boolean"]>;
};

export type RfxSourcingEventArgs = {
  withDeleted: Maybe<Scalars["Boolean"]>;
};

export enum RfxNextStepEnum {
  PO = "PO",
  RFP = "RFP",
  RFQ = "RFQ",
}

export enum RfxStatusEnum {
  COMPLETE = "COMPLETE",
  DRAFT = "DRAFT",
  ONGOING = "ONGOING",
}

export type RfxSupplierResolution = {
  __typename?: "RfxSupplierResolution";
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Date of (soft) delete */
  deletedDate: Maybe<Scalars["Date"]>;
  /** Unique id */
  id: Scalars["ID"];
  /** Resolution/decision about the supplier in the context of RFX */
  resolution: Maybe<RfxSupplierResolutionEnum>;
  /** RFX supplier resolution's RFX info */
  rfx: Rfx;
  /** RFX id */
  rfxId: Scalars["ID"];
  /** RFX supplier resolution's supplier info */
  supplier: Supplier;
  /** Supplier id */
  supplierId: Scalars["ID"];
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export enum RfxSupplierResolutionEnum {
  NOMINATE_TO_PO = "NOMINATE_TO_PO",
  NOMINATE_TO_RFI = "NOMINATE_TO_RFI",
  NOMINATE_TO_RFP = "NOMINATE_TO_RFP",
  NOMINATE_TO_RFQ = "NOMINATE_TO_RFQ",
}

export enum RfxTypeEnum {
  RFI = "RFI",
  RFP = "RFP",
  RFQ = "RFQ",
}

export type SearchInput = {
  /** Search input */
  keyword: Maybe<Scalars["String"]>;
};

export type SourcingEvent = {
  __typename?: "SourcingEvent";
  /** Sourcing event additional info */
  additionalInfo: Maybe<Scalars["String"]>;
  /** Sourcing event attachments */
  attachments: Array<Attachment>;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Sourcing event creator */
  creator: User;
  /** Sourcing event creator id */
  creatorId: Scalars["ID"];
  /** Sourcing event unique id */
  id: Scalars["ID"];
  /** Sourcing event link token */
  linkToken: Scalars["String"];
  /** Sourcing event name */
  name: Scalars["String"];
  /** Sourcing event organization */
  organization: Organization;
  /** Sourcing event organization id */
  organizationId: Scalars["ID"];
  /** Sourcing event questionnaire */
  questionnaire: Scalars["JSON"];
  /** Expiry date of sourcing event */
  responseDeadline: Scalars["Date"];
  /** Sourcing event RFX */
  rfx: Rfx;
  /** Sourcing event rfx id */
  rfxId: Scalars["ID"];
  /** Date of sourcing request sent */
  sentDate: Maybe<Scalars["Date"]>;
  /** Sourcing event requests */
  sourcingRequests: Array<SourcingRequest>;
  /** Sourcing event status */
  status: SourcingEventStatusEnum;
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export type SourcingEventSourcingRequestsArgs = {
  withDeleted: Maybe<Scalars["Boolean"]>;
};

export type SourcingEventPublic = {
  __typename?: "SourcingEventPublic";
  /** Sourcing event additional info */
  additionalInfo: Maybe<Scalars["String"]>;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** SourcingEvent creator info */
  creator: UserPublic;
  /** Sourcing event creator id */
  creatorId: Scalars["ID"];
  /** Sourcing event unique id */
  id: Scalars["ID"];
  /** Sourcing event link token */
  linkToken: Scalars["String"];
  /** Sourcing event name */
  name: Scalars["String"];
  /** Sourcing event public data for organization */
  organization: OrganizationPublic;
  /** Sourcing event organization id */
  organizationId: Scalars["ID"];
  /** Expiry date of sourcing event */
  responseDeadline: Scalars["Date"];
  /** Sourcing event status */
  status: SourcingEventStatusEnum;
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export enum SourcingEventStatusEnum {
  CANCELLED = "CANCELLED",
  COMPLETE = "COMPLETE",
  DRAFT = "DRAFT",
  IN_PROGRESS = "IN_PROGRESS",
}

export type SourcingRequest = {
  __typename?: "SourcingRequest";
  /** Sourcing request attachments */
  attachments: Maybe<Array<Attachment>>;
  /** Sourcing request comment by supplier */
  comment: Maybe<Scalars["String"]>;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Sourcing request unique id */
  id: Scalars["ID"];
  /** Sourcing request quotation */
  quotation: Maybe<Scalars["JSON"]>;
  /** Expiry date of sourcing request */
  responseDeadline: Scalars["Date"];
  /** Sourcing request's event */
  sourcingEvent: SourcingEvent;
  /** Sourcing event id */
  sourcingEventId: Scalars["ID"];
  /** Sourcing request status */
  status: SourcingRequestStatusEnum;
  /** Sourcing request supplier */
  supplier: Maybe<Supplier>;
  /** Sourcing request supplier id */
  supplierId: Scalars["ID"];
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export enum SourcingRequestStatusEnum {
  COMPLETE = "COMPLETE",
  DRAFT = "DRAFT",
  IN_PROGRESS = "IN_PROGRESS",
  OPENED = "OPENED",
}

export enum SpfResultEnum {
  FAIL = "fail",
  NEUTRAL = "neutral",
  NONE = "none",
  PASS = "pass",
  PERMERROR = "permerror",
  SOFTFAIL = "softfail",
  TEMPERROR = "temperror",
}

export enum StatusFilterEnum {
  ARCHIVED = "ARCHIVED",
  COMPLETE = "COMPLETE",
  CREATED_FROM_EMAIL = "CREATED_FROM_EMAIL",
  DISCARDED = "DISCARDED",
  DRAFT = "DRAFT",
  IN_PROGRESS = "IN_PROGRESS",
  INVOICE_APPROVED = "INVOICE_APPROVED",
  INVOICE_PAID = "INVOICE_PAID",
  INVOICE_RECEIVED = "INVOICE_RECEIVED",
  INVOICE_THREE_WAY_MATCH = "INVOICE_THREE_WAY_MATCH",
  ONGOING = "ONGOING",
  PO_CANCELLED = "PO_CANCELLED",
  PO_DRAFT = "PO_DRAFT",
  PO_NOT_RECEIVED = "PO_NOT_RECEIVED",
  PO_READY = "PO_READY",
  PO_RECEIVED = "PO_RECEIVED",
  PO_RECEIVED_PARTIALLY = "PO_RECEIVED_PARTIALLY",
  READY = "READY",
}

export type Subordination = {
  __typename?: "Subordination";
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Subordination ID */
  id: Scalars["ID"];
  /** (Line)Manager's ID */
  managerId: Scalars["ID"];
  /** Related Organization's ID */
  organizationId: Scalars["ID"];
  /** Subordinate's ID */
  subordinateId: Scalars["ID"];
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export type Subscription = {
  __typename?: "Subscription";
  /** New Activity subscription */
  activity: ActivityFeed;
  /** New notification subscription */
  notification: Notification;
  /** Example timer subscription */
  timer: Scalars["String"];
};

export type SubscriptionActivityArgs = {
  organizationId: Scalars["String"];
  relativeId: Maybe<Scalars["String"]>;
};

export type SubscriptionNotificationArgs = {
  organizationId: Scalars["String"];
};

export type Supplier = {
  __typename?: "Supplier";
  /** Supplier activities */
  activities: Array<ActivityFeed>;
  /** Supplier address */
  address: Maybe<Scalars["String"]>;
  /** Supplier area code */
  areaCode: Maybe<Scalars["String"]>;
  /** Supplier attachments */
  attachments: Array<Attachment>;
  /** Base supplier ID */
  baseSupplierId: Maybe<Scalars["ID"]>;
  /** Supplier business registry code */
  businessRegistry: Maybe<Scalars["String"]>;
  /** Supplier categories */
  categories: Array<Category>;
  /** Supplier city */
  city: Maybe<Scalars["String"]>;
  /** Supplier contact persons */
  contactPersons: Array<ContactPerson>;
  /** Supplier country */
  country: Maybe<Country>;
  /** Supplier country code */
  countryCode: Maybe<CountryCodeEnum>;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Supplier currency */
  currency: Maybe<CurrencyEnum>;
  customFields: Maybe<Scalars["JSON"]>;
  /** Supplier default contact person */
  defaultContactPerson: ContactPerson;
  /** Date of deletion */
  deletedDate: Maybe<Scalars["Date"]>;
  /** Supplier D-U-N-S code */
  dunsCode: Maybe<Scalars["String"]>;
  /** Supplier inbound and outbound emails */
  emails: Array<Email>;
  /** ERP supplier code */
  erpSupplierCode: Maybe<Scalars["String"]>;
  /** Supplier unique ID */
  id: Scalars["ID"];
  /** Date of last activity */
  lastActivityDate: Scalars["Date"];
  /** Last person who had contact with supplier */
  lastContact: Maybe<User>;
  /** Last contact user ID */
  lastContactUserId: Maybe<Scalars["ID"]>;
  /** Supplier LEI code */
  leiCode: Maybe<Scalars["String"]>;
  /** Supplier mentions */
  mentions: Array<Mention>;
  /** Supplier name */
  name: Maybe<Scalars["String"]>;
  /** Supplier notes */
  notes: Maybe<Scalars["String"]>;
  /** Organization ID */
  organizationId: Scalars["ID"];
  /** Supplier payment terms */
  paymentTerms: Maybe<Scalars["String"]>;
  /** Supplier sourcing requests */
  sourcingRequests: Array<SourcingRequest>;
  /** Supplier status */
  status: Maybe<SupplierStatusEnum>;
  /** Date of update */
  updatedDate: Scalars["Date"];
  /** Supplier VAT identification */
  vatIdentification: Maybe<Scalars["String"]>;
  /** Supplier web address */
  webAddress: Maybe<Scalars["String"]>;
};

export type SupplierFullTextSearchResult = {
  __typename?: "SupplierFullTextSearchResult";
  /** Contact person email */
  email: Maybe<Scalars["String"]>;
  /** Result record unique ID */
  id: Scalars["ID"];
  /** Is supplier already added to RFX */
  isUsed: Scalars["Boolean"];
  /** Name value */
  name: Scalars["String"];
  /** Supplier status */
  status: Maybe<SupplierStatusEnum>;
  /** Result type */
  type: SupplierFullTextSearchResultTypeEnum;
};

export enum SupplierFullTextSearchResultTypeEnum {
  CATEGORY = "CATEGORY",
  CONTACT_PERSON = "CONTACT_PERSON",
  SUPPLIER = "SUPPLIER",
}

export type SupplierLookupResponse = {
  __typename?: "SupplierLookupResponse";
  /** email */
  email: Scalars["String"];
  /** Supplier SRM lookup result */
  result: SupplierLookupResultEnum;
};

export enum SupplierLookupResultEnum {
  FOUND_MANY = "FOUND_MANY",
  FOUND_ONE = "FOUND_ONE",
  NOT_FOUND = "NOT_FOUND",
}

export enum SupplierStatusEnum {
  APPROVED = "APPROVED",
  ARCHIVED = "ARCHIVED",
  INACTIVE = "INACTIVE",
  NEW = "NEW",
}

export type SupplierWithDefaultContact = {
  __typename?: "SupplierWithDefaultContact";
  /** Default contact person email */
  email: Maybe<Scalars["String"]>;
  /** Supplier ID */
  id: Scalars["ID"];
  /** Supplier name value */
  name: Maybe<Scalars["String"]>;
};

export enum SwimlaneIdEnum {
  INVOICE = "INVOICE",
  PURCHASE_ORDER = "PURCHASE_ORDER",
  PURCHASE_REQUEST = "PURCHASE_REQUEST",
  RECEIVING = "RECEIVING",
  RFX = "RFX",
}

export type TestApi = {
  __typename?: "TestApi";
  /** Clears data and adds sample users and organizations */
  addSampleData: Scalars["Boolean"];
  /** Playground for category tree testing */
  categoryTreeTest: Scalars["Boolean"];
  /** Clears data */
  clearData: Scalars["Boolean"];
  /** Create dummy category hierarchy */
  createDummyCategories: Scalars["Boolean"];
  /** Clears all current viewer sessions on server side and logs in as user with email */
  loginAsUser: Scalars["Boolean"];
  /** Gets magic link token by user email */
  magicLinkToken: Scalars["String"];
  /** Changes schema search path back to only public */
  usePublicSchema: Scalars["Boolean"];
  /** Duplicates current public schema (with all data) and sets it to search path at first position */
  useTestSchema: Scalars["Boolean"];
};

export type TestApiCategoryTreeTestArgs = {
  categoryId: Scalars["String"];
};

export type TestApiLoginAsUserArgs = {
  email: Scalars["String"];
};

export type TestApiMagicLinkTokenArgs = {
  email: Scalars["String"];
};

export type Update_Approval_Request = {
  __typename?: "UPDATE_APPROVAL_REQUEST";
  /** Approval Request info at creation time */
  approvalRequestInfo: ApprovalRequestInfo;
  /** Approval Request related workflow info at update time */
  approvalWorkflowInfo: ApprovalWorkflowInfo;
  /** Approval Workflow related approval requests stats */
  approvalWorkflowStats: ApprovalWorkflowStats;
  /** Workflow approver info */
  approverInfo: ActivityUserInfo;
};

export type Update_Approval_Workflow = {
  __typename?: "UPDATE_APPROVAL_WORKFLOW";
  /** Updated approval workflow info */
  approvalWorkflowInfo: ApprovalWorkflowInfo;
  /** Approval Workflow related approval requests stats */
  approvalWorkflowStats: ApprovalWorkflowStats;
  /** Workflow creator info */
  creatorInfo: ActivityUserInfo;
  /** Relative card info */
  relativeCardInfo: ActivityRelativeCardInfo;
};

export type Update_Order_Received_Quantities = {
  __typename?: "UPDATE_ORDER_RECEIVED_QUANTITIES";
  /** Order info */
  orderInfo: Maybe<ActivityRelativeOrderInfo>;
  /** User info */
  user: ActivityUserInfo;
};

export type Update_Supplier = {
  __typename?: "UPDATE_SUPPLIER";
  /** List of changed fields info */
  changedFields: Array<ChangedFieldInfo>;
  /** Updater info */
  creator: ActivityUserInfo;
  /** Supplier name */
  name: Maybe<Scalars["String"]>;
};

export type User = UserInterface & {
  __typename?: "User";
  /** User address */
  address: Maybe<Scalars["String"]>;
  /** User area code */
  areaCode: Maybe<Scalars["String"]>;
  /** User avatar url */
  avatarUrl: Maybe<Scalars["String"]>;
  /** User city */
  city: Maybe<Scalars["String"]>;
  /** User's Cost Centre */
  costCentre: Maybe<CostCentre>;
  /** Order costCentre id */
  costCentreId: Maybe<Scalars["ID"]>;
  /** User country code */
  countryCode: Maybe<Scalars["String"]>;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** User's department */
  department: Maybe<Department>;
  /** Order department id */
  departmentId: Maybe<Scalars["ID"]>;
  /** User email address */
  email: Scalars["String"];
  /** User first name */
  firstName: Scalars["String"];
  /** User unique id */
  id: Scalars["ID"];
  /** User last name */
  lastName: Scalars["String"];
  /** User organizations */
  organizations: Array<Organization>;
  /** User phone number */
  phoneNumber: Maybe<Scalars["String"]>;
  /** User's project */
  project: Maybe<Project>;
  /** Order project id */
  projectId: Maybe<Scalars["ID"]>;
  /** User roles */
  roles: Array<UserRoleEnum>;
  /** User status */
  status: UserStatusEnum;
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export type UserInterface = {
  /** User address */
  address: Maybe<Scalars["String"]>;
  /** User area code */
  areaCode: Maybe<Scalars["String"]>;
  /** User avatar url */
  avatarUrl: Maybe<Scalars["String"]>;
  /** User city */
  city: Maybe<Scalars["String"]>;
  /** Order costCentre id */
  costCentreId: Maybe<Scalars["ID"]>;
  /** User country code */
  countryCode: Maybe<Scalars["String"]>;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Order department id */
  departmentId: Maybe<Scalars["ID"]>;
  /** User email address */
  email: Scalars["String"];
  /** User first name */
  firstName: Scalars["String"];
  /** User unique id */
  id: Scalars["ID"];
  /** User last name */
  lastName: Scalars["String"];
  /** User phone number */
  phoneNumber: Maybe<Scalars["String"]>;
  /** Order project id */
  projectId: Maybe<Scalars["ID"]>;
  /** User roles */
  roles: Array<UserRoleEnum>;
  /** User status */
  status: UserStatusEnum;
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export type UserPublic = {
  __typename?: "UserPublic";
  /** User avatar url */
  avatarUrl: Maybe<Scalars["String"]>;
  /** User email address */
  email: Scalars["String"];
  /** User first name */
  firstName: Scalars["String"];
  /** User unique id */
  id: Scalars["ID"];
  /** User last name */
  lastName: Scalars["String"];
};

export enum UserRoleEnum {
  ADMIN = "ADMIN",
  APPROVER = "APPROVER",
  BUYER = "BUYER",
  KEY_USER = "KEY_USER",
  REQUESTER = "REQUESTER",
  SUPPLIER = "SUPPLIER",
}

export enum UserStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export type Version = {
  __typename?: "Version";
  /** Client version */
  client: Scalars["String"];
  /** Server version */
  server: Scalars["String"];
};

export type Viewer = UserInterface & {
  __typename?: "Viewer";
  /** User address */
  address: Maybe<Scalars["String"]>;
  /** List of user (approver) Approval Workflows by status */
  approvalWorkflowsByStatus: Array<ApprovalWorkflow>;
  /** User area code */
  areaCode: Maybe<Scalars["String"]>;
  /** User avatar url */
  avatarUrl: Maybe<Scalars["String"]>;
  /** User city */
  city: Maybe<Scalars["String"]>;
  /** Returns organization contact person by id */
  contactPersonById: ContactPerson;
  /** Viewer's Cost Centre */
  costCentre: Maybe<CostCentre>;
  /** Order costCentre id */
  costCentreId: Maybe<Scalars["ID"]>;
  /** List of supported countries */
  countries: Array<Country>;
  /** User country code */
  countryCode: Maybe<Scalars["String"]>;
  /** Date of creation */
  createdDate: Scalars["Date"];
  /** Currency symbol and name */
  currencies: Array<Currency>;
  /** Viewer's department */
  department: Maybe<Department>;
  /** Order department id */
  departmentId: Maybe<Scalars["ID"]>;
  /** User email address */
  email: Scalars["String"];
  /** User first name */
  firstName: Scalars["String"];
  /** User unique id */
  id: Scalars["ID"];
  /** User last name */
  lastName: Scalars["String"];
  /** List of user mentions */
  mentions: Array<Mention>;
  /** Returns order by code */
  orderByCode: Order;
  /** Returns organization by id */
  organization: Organization;
  /** Logged in user organization */
  organizations: Array<Organization>;
  /** User phone number */
  phoneNumber: Maybe<Scalars["String"]>;
  /** Viewer's project */
  project: Maybe<Project>;
  /** Order project id */
  projectId: Maybe<Scalars["ID"]>;
  /** Returns purchase request by id */
  purchaseRequest: PurchaseRequest;
  /** Returns purchase request by code */
  purchaseRequestByCode: PurchaseRequest;
  /** Returns purchase request item by id */
  purchaseRequestItem: PurchaseRequestItem;
  /** Returns purchase request item by id */
  purchaseRequestItemById: PurchaseRequestItem;
  /** Returns rfx by code */
  rfxByCode: Rfx;
  /** User roles */
  roles: Array<UserRoleEnum>;
  /** Returns sourcing request by id */
  sourcingRequestById: SourcingRequest;
  /** User status */
  status: UserStatusEnum;
  /** Returns supplier by contact person ID */
  supplierByContactPerson: SupplierWithDefaultContact;
  /** Returns organization supplier by id */
  supplierById: Supplier;
  /** Logged in user suppliers */
  suppliers: Array<Supplier>;
  /** Returns list of suppliers by category ID */
  suppliersByCategoryId: Array<SupplierWithDefaultContact>;
  /** Date of update */
  updatedDate: Scalars["Date"];
};

export type ViewerApprovalWorkflowsByStatusArgs = {
  approvalWorkflowStatuses: Array<ApprovalWorkflowStatusEnum>;
};

export type ViewerContactPersonByIdArgs = {
  contactPersonId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type ViewerOrderByCodeArgs = {
  code: Scalars["String"];
  organizationId: Scalars["String"];
  withDeleted: Maybe<Scalars["Boolean"]>;
};

export type ViewerOrganizationArgs = {
  id: Scalars["String"];
};

export type ViewerPurchaseRequestArgs = {
  id: Scalars["String"];
};

export type ViewerPurchaseRequestByCodeArgs = {
  code: Scalars["String"];
  organizationId: Scalars["String"];
};

export type ViewerPurchaseRequestItemArgs = {
  id: Scalars["String"];
};

export type ViewerPurchaseRequestItemByIdArgs = {
  purchaseRequestItemId: Scalars["String"];
};

export type ViewerRfxByCodeArgs = {
  code: Scalars["String"];
  organizationId: Scalars["String"];
  withDeleted: Maybe<Scalars["Boolean"]>;
};

export type ViewerSourcingRequestByIdArgs = {
  sourcingRequestId: Scalars["String"];
};

export type ViewerSupplierByContactPersonArgs = {
  contactPersonId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type ViewerSupplierByIdArgs = {
  organizationId: Scalars["String"];
  supplierId: Scalars["String"];
};

export type ViewerSuppliersByCategoryIdArgs = {
  categoryId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type KanbanCollaborationSidebarQueryVariables = {
  organizationId: Scalars["String"];
  take: Maybe<Scalars["Int"]>;
};

export type KanbanCollaborationSidebarQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      activities: Array<
        { __typename?: "ActivityFeed" } & ActivityFeedItemFragment
      >;
    };
  }>;
};

export type GetProjectsSuppliersDepartmentsQueryVariables = {
  id: Scalars["String"];
};

export type GetProjectsSuppliersDepartmentsQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      projects: Array<{ __typename?: "Project"; id: string; name: string }>;
      suppliers: Array<{
        __typename?: "Supplier";
        id: string;
        name: Maybe<string>;
        defaultContactPerson: {
          __typename?: "ContactPerson";
          id: string;
          email: string;
        };
      }>;
      departments: Array<{
        __typename?: "Department";
        id: string;
        name: string;
        code: Maybe<string>;
      }>;
    };
  }>;
};

export type CreateOrUpdateSourcingEventMutationVariables = {
  sourcingEventId: Maybe<Scalars["String"]>;
  organizationId: Scalars["String"];
  rfxId: Scalars["String"];
  name: Scalars["String"];
  additionalInfo: Maybe<Scalars["String"]>;
  questionnaire: Maybe<Scalars["String"]>;
  responseDeadline: Maybe<Scalars["Date"]>;
  selectedAttachmentIds: Maybe<Array<Scalars["String"]>>;
  uploadedAttachments: Maybe<Array<Scalars["Upload"]>>;
};

export type CreateOrUpdateSourcingEventMutation = {
  __typename?: "Mutation";
  createOrUpdateSourcingEvent: { __typename?: "SourcingEvent"; id: string };
};

export type SelectDefaultContactMutationVariables = {
  contactPersonId: Scalars["String"];
};

export type SelectDefaultContactMutation = {
  __typename?: "Mutation";
  setDefaultContactPerson: { __typename?: "ContactPerson"; id: string };
};

export type VersionQueryVariables = {};

export type VersionQuery = {
  __typename?: "Query";
  version: { __typename?: "Version"; client: string; server: string };
};

export type ActivitySubscriptionSubscriptionVariables = {
  organizationId: Scalars["String"];
  relativeId: Maybe<Scalars["String"]>;
};

export type ActivitySubscriptionSubscription = {
  __typename?: "Subscription";
  activity: { __typename?: "ActivityFeed" } & ActivityFeedItemFragment;
};

export type NotificationSubscriptionSubscriptionVariables = {
  organizationId: Scalars["String"];
};

export type NotificationSubscriptionSubscription = {
  __typename?: "Subscription";
  notification: { __typename?: "Notification"; id: string; userId: string };
};

export type SendEmailToRequesterMutationVariables = {
  parentId: Maybe<Scalars["String"]>;
  cardId: Scalars["String"];
  cardType: EmailCardTypeEnum;
  requesterId: Scalars["String"];
  toEmail: Maybe<Scalars["String"]>;
  ccEmail: Maybe<Scalars["String"]>;
  subject: Scalars["String"];
  message: Scalars["String"];
  attachments: Maybe<Array<Scalars["Upload"]>>;
};

export type SendEmailToRequesterMutation = {
  __typename?: "Mutation";
  sendEmailToRequester: boolean;
};

export type SendEmailToSuppliersMutationVariables = {
  parentId: Maybe<Scalars["String"]>;
  cardId: Scalars["String"];
  cardType: EmailCardTypeEnum;
  supplierIds: Array<Scalars["String"]>;
  toEmail: Maybe<Scalars["String"]>;
  ccEmail: Maybe<Scalars["String"]>;
  subject: Scalars["String"];
  message: Scalars["String"];
  attachments: Maybe<Array<Scalars["Upload"]>>;
  existingAttachmentIds: Maybe<Array<Scalars["String"]>>;
  includePurchaseOrderPdf: Maybe<Scalars["Boolean"]>;
};

export type SendEmailToSuppliersMutation = {
  __typename?: "Mutation";
  sendEmailToSuppliers: boolean;
};

export type SendSourcingRequestMutationVariables = {
  sourcingEventId: Scalars["String"];
  supplierIds: Array<Scalars["String"]>;
  subject: Scalars["String"];
  message: Scalars["String"];
  attachments: Maybe<Array<Scalars["Upload"]>>;
  existingAttachmentIds: Maybe<Array<Scalars["String"]>>;
};

export type SendSourcingRequestMutation = {
  __typename?: "Mutation";
  sendSourcingRequest: boolean;
};

export type ViewerQueryVariables = {};

export type ViewerQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    firstName: string;
    lastName: string;
    avatarUrl: Maybe<string>;
    email: string;
    roles: Array<UserRoleEnum>;
    phoneNumber: Maybe<string>;
    address: Maybe<string>;
    city: Maybe<string>;
    areaCode: Maybe<string>;
    countryCode: Maybe<string>;
    createdDate: any;
    organizations: Array<{
      __typename?: "Organization";
      id: string;
      companyName: string;
      address: string;
      city: string;
      areaCode: string;
      baseCurrency: CurrencyEnum;
      urlName: string;
      companyLogoUrl: Maybe<string>;
      prEmailAddress: string;
      isActive: boolean;
      domainWhitelist: Maybe<Array<string>>;
      modules: Maybe<{ [key: string]: any }>;
      vatIdentification: Maybe<string>;
      businessRegistry: Maybe<string>;
      leiCode: Maybe<string>;
      dunsCode: Maybe<string>;
      embeddedReport: Maybe<string>;
      poStatusWebhookUrl: Maybe<string>;
      country: { __typename?: "Country"; name: string; code: string };
      quantityUnits: Array<{
        __typename?: "QuantityUnit";
        id: string;
        name: string;
      }>;
      users: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        avatarUrl: Maybe<string>;
        email: string;
        roles: Array<UserRoleEnum>;
      }>;
      costCentres: Array<{
        __typename?: "CostCentre";
        id: string;
        name: string;
      }>;
      departments: Array<{
        __typename?: "Department";
        id: string;
        name: string;
        code: Maybe<string>;
      }>;
      projects: Array<{ __typename?: "Project"; id: string; name: string }>;
    }>;
    countries: Array<{ __typename?: "Country"; code: string; name: string }>;
  }>;
};

export type GetPoExternalIdentifierQueryVariables = {
  code: Scalars["String"];
  organizationId: Scalars["String"];
};

export type GetPoExternalIdentifierQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    orderByCode: {
      __typename?: "Order";
      id: string;
      externalIdentifier: Maybe<string>;
    };
  }>;
};

export type AddOrganizationToUserMutationVariables = {
  userId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type AddOrganizationToUserMutation = {
  __typename?: "Mutation";
  addOrganizationToUser: { __typename?: "User"; id: string };
};

export type AdminCreateUserMutationVariables = {
  organizationId: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  roles: Array<UserRoleEnum>;
  phoneNumber: Maybe<Scalars["String"]>;
  countryCode: Maybe<CountryCodeEnum>;
};

export type AdminCreateUserMutation = {
  __typename?: "Mutation";
  createUser: {
    __typename?: "User";
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    roles: Array<UserRoleEnum>;
    status: UserStatusEnum;
    createdDate: any;
    updatedDate: any;
  };
};

export type AdminUpdateCompanyLogoMutationVariables = {
  organizationId: Scalars["String"];
  companyLogo: Scalars["Upload"];
};

export type AdminUpdateCompanyLogoMutation = {
  __typename?: "Mutation";
  adminUpdateOrganizationLogo: { __typename?: "Organization"; id: string };
};

export type AdminUpdateProfilePictureMutationVariables = {
  userId: Scalars["String"];
  profilePicture: Scalars["Upload"];
};

export type AdminUpdateProfilePictureMutation = {
  __typename?: "Mutation";
  adminUpdateProfilePicture: { __typename?: "Viewer"; id: string };
};

export type AdminUpdateUserMutationVariables = {
  userId: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  email: Scalars["String"];
  organizationIds: Array<Scalars["String"]>;
  roles: Array<UserRoleEnum>;
  status: UserStatusEnum;
};

export type AdminUpdateUserMutation = {
  __typename?: "Mutation";
  updateUser: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    organizations: Array<{
      __typename?: "Organization";
      id: string;
      companyName: string;
    }>;
  };
};

export type CreateOrganizationMutationVariables = {
  companyName: Scalars["String"];
  address: Scalars["String"];
  city: Scalars["String"];
  areaCode: Scalars["String"];
  countryCode: CountryCodeEnum;
  baseCurrency: CurrencyEnum;
  urlName: Scalars["String"];
};

export type CreateOrganizationMutation = {
  __typename?: "Mutation";
  createOrganization: {
    __typename?: "Organization";
    id: string;
    companyName: string;
    address: string;
    city: string;
    areaCode: string;
    countryCode: CountryCodeEnum;
    baseCurrency: CurrencyEnum;
    urlName: string;
    isActive: boolean;
    createdDate: any;
    updatedDate: any;
  };
};

export type OrganizationByIdQueryVariables = {
  organizationId: Scalars["String"];
};

export type OrganizationByIdQuery = {
  __typename?: "Query";
  admin: {
    __typename?: "Admin";
    organizationById: {
      __typename?: "Organization";
      id: string;
      companyName: string;
      baseCurrency: CurrencyEnum;
      urlName: string;
      countryCode: CountryCodeEnum;
      city: string;
      address: string;
      areaCode: string;
      isActive: boolean;
      companyLogoUrl: Maybe<string>;
    };
    countries: Array<{ __typename?: "Country"; code: string; name: string }>;
    currencies: Array<{
      __typename?: "Currency";
      symbol: string;
      name: string;
    }>;
  };
};

export type OrganizationsManagementQueryVariables = {};

export type OrganizationsManagementQuery = {
  __typename?: "Query";
  admin: {
    __typename?: "Admin";
    organizations: Array<{
      __typename?: "Organization";
      id: string;
      companyName: string;
      urlName: string;
      address: string;
      city: string;
      areaCode: string;
      countryCode: CountryCodeEnum;
      createdDate: any;
      updatedDate: any;
      isActive: boolean;
      companyLogoUrl: Maybe<string>;
    }>;
    countries: Array<{ __typename?: "Country"; code: string; name: string }>;
    currencies: Array<{
      __typename?: "Currency";
      symbol: string;
      name: string;
    }>;
  };
};

export type RemoveOrganizationFromUserMutationVariables = {
  userId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type RemoveOrganizationFromUserMutation = {
  __typename?: "Mutation";
  removeOrganizationFromUser: { __typename?: "User"; id: string };
};

export type AdminUpdateOrganizationMutationVariables = {
  organizationId: Scalars["String"];
  companyName: Scalars["String"];
  address: Scalars["String"];
  city: Scalars["String"];
  areaCode: Scalars["String"];
  countryCode: CountryCodeEnum;
  baseCurrency: CurrencyEnum;
  urlName: Scalars["String"];
  isActive: Scalars["Boolean"];
};

export type AdminUpdateOrganizationMutation = {
  __typename?: "Mutation";
  adminUpdateOrganization: {
    __typename?: "Organization";
    id: string;
    companyName: string;
    baseCurrency: CurrencyEnum;
    urlName: string;
    countryCode: CountryCodeEnum;
    city: string;
    address: string;
    areaCode: string;
  };
};

export type UserByIdQueryVariables = {
  userId: Scalars["String"];
};

export type UserByIdQuery = {
  __typename?: "Query";
  admin: {
    __typename?: "Admin";
    userById: {
      __typename?: "User";
      id: string;
      email: string;
      firstName: string;
      lastName: string;
      roles: Array<UserRoleEnum>;
      status: UserStatusEnum;
      createdDate: any;
      updatedDate: any;
      avatarUrl: Maybe<string>;
      organizations: Array<{
        __typename?: "Organization";
        id: string;
        companyName: string;
      }>;
    };
  };
};

export type UsersManagementQueryVariables = {};

export type UsersManagementQuery = {
  __typename?: "Query";
  admin: {
    __typename?: "Admin";
    users: Array<{
      __typename?: "User";
      id: string;
      email: string;
      firstName: string;
      lastName: string;
      countryCode: Maybe<string>;
      phoneNumber: Maybe<string>;
      roles: Array<UserRoleEnum>;
      createdDate: any;
      updatedDate: any;
      status: UserStatusEnum;
      avatarUrl: Maybe<string>;
      organizations: Array<{
        __typename?: "Organization";
        id: string;
        companyName: string;
      }>;
    }>;
    organizations: Array<{
      __typename?: "Organization";
      id: string;
      companyName: string;
    }>;
    countries: Array<{ __typename?: "Country"; code: string; name: string }>;
  };
};

export type ApprovalPortalViewQueryVariables = {
  approvalWorkflowStatuses: Array<ApprovalWorkflowStatusEnum>;
  organizationId: Scalars["String"];
  filter: Maybe<KanbanFilterInput>;
};

export type ApprovalPortalViewQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    avatarUrl: Maybe<string>;
    roles: Array<UserRoleEnum>;
    approvalWorkflowsByStatus: Array<{
      __typename?: "ApprovalWorkflow";
      id: string;
      code: string;
      status: ApprovalWorkflowStatusEnum;
    }>;
    organization: {
      __typename?: "Organization";
      id: string;
      urlName: string;
      costCentres: Array<{
        __typename?: "CostCentre";
        id: string;
        name: string;
      }>;
      departments: Array<{
        __typename?: "Department";
        id: string;
        name: string;
        code: Maybe<string>;
      }>;
      projects: Array<{ __typename?: "Project"; id: string; name: string }>;
      purchaseRequests: Array<{
        __typename?: "PurchaseRequest";
        id: string;
        code: string;
        status: PurchaseRequestStatusEnum;
        name: string;
        isRfxCreated: boolean;
        isOrderCreated: boolean;
        createdDate: any;
        cardStatus: {
          __typename?: "CardStatus";
          level: CardStatusLevelEnum;
          text: string;
        };
        requestor: { __typename?: "User" } & UserInfoFragment;
        assignee: Maybe<{ __typename?: "User" } & UserInfoFragment>;
        project: Maybe<{ __typename?: "Project" } & ProjectInfoFragment>;
        suppliers: Array<{ __typename?: "Supplier" } & SupplierInfoFragment>;
        department: Maybe<
          { __typename?: "Department" } & DepartmentInfoFragment
        >;
        items: Array<
          {
            __typename?: "PurchaseRequestItem";
            status: {
              __typename?: "CardStatus";
              level: CardStatusLevelEnum;
              text: string;
            };
          } & PurchaseRequestItemInfoFragment
        >;
        latestApprovalWorkflow: Maybe<{
          __typename?: "ApprovalWorkflow";
          id: string;
          code: string;
          status: ApprovalWorkflowStatusEnum;
        }>;
      }>;
    };
  }>;
};

export type UpdateApprovalRequestMutationVariables = {
  approvalRequestId: Scalars["String"];
  comment: Scalars["String"];
  decision: ApprovalRequestDecisionEnum;
};

export type UpdateApprovalRequestMutation = {
  __typename?: "Mutation";
  updateApprovalRequest: { __typename?: "ApprovalRequest"; id: string };
};

export type ViewerApprovalWorkflowsQueryVariables = {
  approvalWorkflowStatuses: Array<ApprovalWorkflowStatusEnum>;
};

export type ViewerApprovalWorkflowsQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    firstName: string;
    lastName: string;
    approvalWorkflowsByStatus: Array<{
      __typename?: "ApprovalWorkflow";
      id: string;
      code: string;
      requestName: Maybe<string>;
      approvalAmount: Maybe<number>;
      comment: Maybe<string>;
      status: ApprovalWorkflowStatusEnum;
      createdDate: any;
      updatedDate: any;
      requestor: Maybe<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        avatarUrl: Maybe<string>;
      }>;
      purchaseRequest: Maybe<{
        __typename?: "PurchaseRequest";
        id: string;
        code: string;
        notes: string;
        createdDate: any;
        attachments: Maybe<
          Array<{
            __typename?: "Attachment";
            id: string;
            filename: string;
            url: Maybe<string>;
          }>
        >;
        department: Maybe<{
          __typename?: "Department";
          id: string;
          name: string;
          code: Maybe<string>;
        }>;
        project: Maybe<{ __typename?: "Project"; id: string; name: string }>;
        costCentre: Maybe<{
          __typename?: "CostCentre";
          id: string;
          name: string;
        }>;
        items: Array<{
          __typename?: "PurchaseRequestItem";
          id: string;
          name: string;
          code: Maybe<string>;
          type: PurchaseRequestItemTypeEnum;
          quantity: Maybe<number>;
          unit: Maybe<string>;
          unitPrice: Maybe<number>;
          currency: Maybe<CurrencyEnum>;
          expectedDeliveryDate: Maybe<any>;
          productUrl: Maybe<string>;
          suggestedSupplier: Maybe<string>;
          additionalInfo: Maybe<string>;
          isDeleted: boolean;
          attachments: Maybe<
            Array<{
              __typename?: "Attachment";
              id: string;
              filename: string;
              url: Maybe<string>;
            }>
          >;
        }>;
      }>;
      purchaseOrder: Maybe<{ __typename?: "Order"; id: string }>;
      approvalRequests: Array<{
        __typename?: "ApprovalRequest";
        id: string;
        creatorComment: Maybe<string>;
        approverComment: Maybe<string>;
        decision: Maybe<ApprovalRequestDecisionEnum>;
        decisionDate: Maybe<any>;
        dueDate: Maybe<any>;
        sequenceNumber: number;
        sequenceGroup: number;
        createdDate: any;
        updatedDate: any;
        approver: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
      }>;
    }>;
  }>;
};

export type OrganizationApprovalWorkflowsByStatusQueryVariables = {
  id: Scalars["String"];
  approvalWorkflowStatuses: Array<ApprovalWorkflowStatusEnum>;
};

export type OrganizationApprovalWorkflowsByStatusQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      approvalWorkflowsByStatus: Array<{
        __typename?: "ApprovalWorkflow";
        id: string;
        code: string;
        requestName: Maybe<string>;
        approvalAmount: Maybe<number>;
        creatorId: string;
        status: ApprovalWorkflowStatusEnum;
        createdDate: any;
        updatedDate: any;
        diffWithDeadline: Maybe<number>;
        approvalWorkflowStats: {
          __typename?: "ApprovalWorkflowStats";
          approvedRequestCount: number;
          totalRequestCount: number;
        };
        approvalRequests: Array<{
          __typename?: "ApprovalRequest";
          id: string;
          dueDate: Maybe<any>;
          decision: Maybe<ApprovalRequestDecisionEnum>;
        }>;
        purchaseRequest: Maybe<{
          __typename?: "PurchaseRequest";
          id: string;
          name: string;
          code: string;
        }>;
        creator: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
      }>;
    };
  }>;
};

export type CreateApiKeyMutationVariables = {
  organizationId: Scalars["String"];
  description: Maybe<Scalars["String"]>;
};

export type CreateApiKeyMutation = {
  __typename?: "Mutation";
  createApiKey: {
    __typename?: "ApiKey";
    id: string;
    creatorId: string;
    token: string;
    description: Maybe<string>;
  };
};

export type CreateOrganizationUserMutationVariables = {
  organizationId: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  roles: Array<UserRoleEnum>;
  countryCode: Maybe<CountryCodeEnum>;
  phoneNumber: Maybe<Scalars["String"]>;
};

export type CreateOrganizationUserMutation = {
  __typename?: "Mutation";
  createOrganizationUser: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<UserRoleEnum>;
    countryCode: Maybe<string>;
    phoneNumber: Maybe<string>;
  };
};

export type CreateUserMutationVariables = {
  organizationId: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  roles: Array<UserRoleEnum>;
  phoneNumber: Maybe<Scalars["String"]>;
  countryCode: Maybe<CountryCodeEnum>;
};

export type CreateUserMutation = {
  __typename?: "Mutation";
  createUser: {
    __typename?: "User";
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    roles: Array<UserRoleEnum>;
    status: UserStatusEnum;
    createdDate: any;
    updatedDate: any;
  };
};

export type DeleteApiKeyMutationVariables = {
  apiKeyId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type DeleteApiKeyMutation = {
  __typename?: "Mutation";
  deleteApiKey: {
    __typename?: "ApiKey";
    id: string;
    token: string;
    description: Maybe<string>;
  };
};

export type DeleteCostCentreMutationVariables = {
  costCentreId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type DeleteCostCentreMutation = {
  __typename?: "Mutation";
  deleteCostCentre: { __typename?: "CostCentre"; id: string; name: string };
};

export type DeleteDepartmentMutationVariables = {
  departmentId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type DeleteDepartmentMutation = {
  __typename?: "Mutation";
  deleteDepartment: {
    __typename?: "Department";
    id: string;
    name: string;
    code: Maybe<string>;
  };
};

export type DeleteProjectMutationVariables = {
  projectId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type DeleteProjectMutation = {
  __typename?: "Mutation";
  deleteProject: { __typename?: "Project"; id: string; name: string };
};

export type OrganizationAddressesQueryVariables = {
  organizationId: Scalars["String"];
};

export type OrganizationAddressesQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      addresses: Array<{ __typename?: "Address" } & AddressInfoFragment>;
    };
  }>;
};

export type AddressInfoFragment = {
  __typename?: "Address";
  id: string;
  organizationId: string;
  locationName: Maybe<string>;
  recipientName: Maybe<string>;
  address: string;
  city: string;
  areaCode: string;
  countryCode: CountryCodeEnum;
  addressType: AddressTypeEnum;
  createdDate: any;
  updatedDate: any;
  country: { __typename?: "Country"; code: string; name: string };
};

export type OrganizationApiKeysQueryVariables = {
  organizationId: Scalars["String"];
};

export type OrganizationApiKeysQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      apiKeys: Array<{
        __typename?: "ApiKey";
        id: string;
        token: string;
        description: Maybe<string>;
        createdDate: any;
      }>;
    };
  }>;
};

export type OrganizationCostCentreByIdQueryVariables = {
  organizationId: Scalars["String"];
  costCentreId: Scalars["String"];
};

export type OrganizationCostCentreByIdQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      costCentreById: { __typename?: "CostCentre"; id: string; name: string };
    };
  }>;
};

export type OrganizationDepartmentByIdQueryVariables = {
  organizationId: Scalars["String"];
  departmentId: Scalars["String"];
};

export type OrganizationDepartmentByIdQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      departmentById: {
        __typename?: "Department";
        id: string;
        name: string;
        code: Maybe<string>;
        domain: Maybe<string>;
        shippingAddress: Maybe<
          { __typename?: "Address" } & AddressInfoFragment
        >;
        invoiceAddress: Maybe<{ __typename?: "Address" } & AddressInfoFragment>;
      };
    };
  }>;
};

export type OrganizationProjectByIdQueryVariables = {
  organizationId: Scalars["String"];
  projectId: Scalars["String"];
};

export type OrganizationProjectByIdQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      projectById: { __typename?: "Project"; id: string; name: string };
    };
  }>;
};

export type OrganizationUserByIdQueryVariables = {
  organizationId: Scalars["String"];
  userId: Scalars["String"];
};

export type OrganizationUserByIdQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      companyName: string;
      departments: Array<{
        __typename?: "Department";
        id: string;
        name: string;
        code: Maybe<string>;
      }>;
      projects: Array<{ __typename?: "Project"; id: string; name: string }>;
      costCentres: Array<{
        __typename?: "CostCentre";
        id: string;
        name: string;
      }>;
      userById: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: Maybe<string>;
        roles: Array<UserRoleEnum>;
        status: UserStatusEnum;
        countryCode: Maybe<string>;
        department: Maybe<{
          __typename?: "Department";
          id: string;
          name: string;
          code: Maybe<string>;
        }>;
        project: Maybe<{ __typename?: "Project"; id: string; name: string }>;
        costCentre: Maybe<{
          __typename?: "CostCentre";
          id: string;
          name: string;
        }>;
      };
    };
  }>;
};

export type UpdateCostCentreMutationVariables = {
  costCentreId: Scalars["String"];
  organizationId: Scalars["String"];
  name: Scalars["String"];
};

export type UpdateCostCentreMutation = {
  __typename?: "Mutation";
  updateCostCentre: { __typename?: "CostCentre"; id: string; name: string };
};

export type UpdateDepartmentMutationVariables = {
  departmentId: Scalars["String"];
  organizationId: Scalars["String"];
  name: Scalars["String"];
  code: Maybe<Scalars["String"]>;
  domain: Maybe<Scalars["String"]>;
  shippingAddressId: Maybe<Scalars["String"]>;
  invoiceAddressId: Maybe<Scalars["String"]>;
};

export type UpdateDepartmentMutation = {
  __typename?: "Mutation";
  updateDepartment: {
    __typename?: "Department";
    id: string;
    name: string;
    code: Maybe<string>;
    domain: Maybe<string>;
  };
};

export type UpdateOrganizationPoTemplateMutationVariables = {
  organizationId: Scalars["String"];
  primaryColor: Maybe<Scalars["String"]>;
  secondaryColor: Maybe<Scalars["String"]>;
  note: Maybe<Scalars["String"]>;
};

export type UpdateOrganizationPoTemplateMutation = {
  __typename?: "Mutation";
  updateOrganizationPoTemplate: { __typename?: "Organization"; id: string };
};

export type UpdateOrganizationUserMutationVariables = {
  organizationId: Scalars["String"];
  userId: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  roles: Array<UserRoleEnum>;
  status: UserStatusEnum;
  countryCode: Maybe<CountryCodeEnum>;
  phoneNumber: Maybe<Scalars["String"]>;
  departmentId: Maybe<Scalars["String"]>;
  projectId: Maybe<Scalars["String"]>;
  costCentreId: Maybe<Scalars["String"]>;
};

export type UpdateOrganizationUserMutation = {
  __typename?: "Mutation";
  updateOrganizationUser: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<UserRoleEnum>;
    status: UserStatusEnum;
    countryCode: Maybe<string>;
    phoneNumber: Maybe<string>;
    departmentId: Maybe<string>;
    projectId: Maybe<string>;
    costCentreId: Maybe<string>;
  };
};

export type UpdateProjectMutationVariables = {
  projectId: Scalars["String"];
  organizationId: Scalars["String"];
  name: Scalars["String"];
};

export type UpdateProjectMutation = {
  __typename?: "Mutation";
  updateProject: { __typename?: "Project"; id: string; name: string };
};

export type UpdateWhitelistedDomainsMutationVariables = {
  organizationId: Scalars["String"];
  domainWhitelist: Maybe<Array<Scalars["String"]>>;
};

export type UpdateWhitelistedDomainsMutation = {
  __typename?: "Mutation";
  updateWhitelistedDomains: { __typename?: "Organization"; id: string };
};

export type UploadOrganizationLogoMutationVariables = {
  organizationId: Scalars["String"];
  companyLogo: Scalars["Upload"];
};

export type UploadOrganizationLogoMutation = {
  __typename?: "Mutation";
  uploadOrganizationLogo: { __typename?: "Organization"; id: string };
};

export type UserUpdateOrganizationMutationVariables = {
  organizationId: Scalars["String"];
  companyName: Scalars["String"];
  address: Scalars["String"];
  city: Scalars["String"];
  areaCode: Scalars["String"];
  countryCode: CountryCodeEnum;
  baseCurrency: CurrencyEnum;
  vatIdentification: Maybe<Scalars["String"]>;
  businessRegistry: Maybe<Scalars["String"]>;
  leiCode: Maybe<Scalars["String"]>;
  dunsCode: Maybe<Scalars["String"]>;
  embeddedReport: Maybe<Scalars["String"]>;
  poStatusWebhookUrl: Maybe<Scalars["String"]>;
};

export type UserUpdateOrganizationMutation = {
  __typename?: "Mutation";
  updateOrganization: {
    __typename?: "Organization";
    id: string;
    address: string;
    city: string;
    areaCode: string;
    baseCurrency: CurrencyEnum;
    vatIdentification: Maybe<string>;
    businessRegistry: Maybe<string>;
    leiCode: Maybe<string>;
    dunsCode: Maybe<string>;
    embeddedReport: Maybe<string>;
    poStatusWebhookUrl: Maybe<string>;
    country: { __typename?: "Country"; name: string };
  };
};

export type CreatePurchaseRequestMutationVariables = {
  organizationId: Scalars["String"];
};

export type CreatePurchaseRequestMutation = {
  __typename?: "Mutation";
  createPurchaseRequest: {
    __typename?: "PurchaseRequest";
    id: string;
    code: string;
  };
};

export type CreatePurchaseRequestProductMutationVariables = {
  purchaseRequestId: Scalars["String"];
  name: Scalars["String"];
  code: Maybe<Scalars["String"]>;
  suggestedSupplier: Maybe<Scalars["String"]>;
  supplierCode: Maybe<Scalars["String"]>;
  quantity: Scalars["Float"];
  unit: Scalars["String"];
  expectedDeliveryDate: Maybe<Scalars["Date"]>;
  additionalInfo: Maybe<Scalars["String"]>;
  productUrl: Maybe<Scalars["String"]>;
  unitPrice: Maybe<Scalars["Float"]>;
  currency: Maybe<CurrencyEnum>;
  customFields: Maybe<Scalars["String"]>;
  esourcingFields: Maybe<Scalars["String"]>;
  attachments: Maybe<Array<Scalars["Upload"]>>;
};

export type CreatePurchaseRequestProductMutation = {
  __typename?: "Mutation";
  createPurchaseRequestProduct: {
    __typename?: "PurchaseRequestItem";
    id: string;
    code: Maybe<string>;
  };
};

export type CreatePurchaseRequestServiceMutationVariables = {
  purchaseRequestId: Scalars["String"];
  name: Scalars["String"];
  code: Maybe<Scalars["String"]>;
  suggestedSupplier: Maybe<Scalars["String"]>;
  supplierCode: Maybe<Scalars["String"]>;
  expectedDeliveryDate: Maybe<Scalars["Date"]>;
  additionalInfo: Maybe<Scalars["String"]>;
  productUrl: Maybe<Scalars["String"]>;
  unitPrice: Maybe<Scalars["Float"]>;
  currency: Maybe<CurrencyEnum>;
  customFields: Maybe<Scalars["String"]>;
  esourcingFields: Maybe<Scalars["String"]>;
  attachments: Maybe<Array<Scalars["Upload"]>>;
};

export type CreatePurchaseRequestServiceMutation = {
  __typename?: "Mutation";
  createPurchaseRequestService: {
    __typename?: "PurchaseRequestItem";
    id: string;
    code: Maybe<string>;
  };
};

export type CreateQuantityUnitMutationVariables = {
  organizationId: Scalars["String"];
  name: Scalars["String"];
};

export type CreateQuantityUnitMutation = {
  __typename?: "Mutation";
  createQuantityUnit: { __typename?: "QuantityUnit"; id: string; name: string };
};

export type UploadProductExcelMutationVariables = {
  organizationId: Scalars["String"];
  name: Scalars["String"];
  purchaseRequestId: Maybe<Scalars["String"]>;
  excel: Scalars["Upload"];
};

export type UploadProductExcelMutation = {
  __typename?: "Mutation";
  uploadProductExcel: {
    __typename?: "ExcelImportItemResult";
    success: boolean;
    rows: Array<{
      __typename?: "ExcelImportItemRow";
      row: number;
      success: boolean;
      errors: {
        __typename?: "ExcelImportItemValidationErrors";
        name: Maybe<Array<string>>;
        code: Maybe<Array<string>>;
        suggestedSupplier: Maybe<Array<string>>;
        supplierCode: Maybe<Array<string>>;
        quantity: Maybe<Array<string>>;
        unit: Maybe<Array<string>>;
        unitPrice: Maybe<Array<string>>;
        currency: Maybe<Array<string>>;
        expectedDeliveryDate: Maybe<Array<string>>;
        productUrl: Maybe<Array<string>>;
        additionalInfo: Maybe<Array<string>>;
        customFields: Maybe<Array<string>>;
      };
    }>;
    purchaseRequest: Maybe<{
      __typename?: "PurchaseRequest";
      id: string;
      code: string;
      name: string;
    }>;
  };
};

export type ViewerOrganizationQueryVariables = {
  id: Scalars["String"];
};

export type ViewerOrganizationQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      baseCurrency: CurrencyEnum;
      quantityUnits: Array<{
        __typename?: "QuantityUnit";
        id: string;
        name: string;
      }>;
      projects: Array<{ __typename?: "Project"; id: string; name: string }>;
      departments: Array<{
        __typename?: "Department";
        id: string;
        name: string;
        code: Maybe<string>;
      }>;
      users: Array<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        email: string;
      }>;
    };
  }>;
};

export type CreatePurchaseRequestViewQueryVariables = {
  organizationId: Scalars["String"];
};

export type CreatePurchaseRequestViewQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    avatarUrl: Maybe<string>;
    roles: Array<UserRoleEnum>;
    organization: {
      __typename?: "Organization";
      id: string;
      urlName: string;
      baseCurrency: CurrencyEnum;
      users: Array<{ __typename?: "User"; id: string } & UserInfoFragment>;
      suppliers: Array<{
        __typename?: "Supplier";
        id: string;
        name: Maybe<string>;
        defaultContactPerson: {
          __typename?: "ContactPerson";
          id: string;
          email: string;
        };
      }>;
      departments: Array<{
        __typename?: "Department";
        id: string;
        name: string;
        code: Maybe<string>;
      }>;
      costCentres: Array<{
        __typename?: "CostCentre";
        id: string;
        name: string;
      }>;
      projects: Array<{ __typename?: "Project"; id: string; name: string }>;
      quantityUnits: Array<{
        __typename?: "QuantityUnit";
        id: string;
        name: string;
      }>;
    };
  }>;
};

export type GetItemByIdQueryVariables = {
  purchaseRequestItemId: Scalars["String"];
};

export type GetItemByIdQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    purchaseRequestItemById: {
      __typename?: "PurchaseRequestItem";
      id: string;
      name: string;
      quantity: Maybe<number>;
      unit: Maybe<string>;
      expectedDeliveryDate: Maybe<any>;
      productUrl: Maybe<string>;
      additionalInfo: Maybe<string>;
      currency: Maybe<CurrencyEnum>;
      unitPrice: Maybe<number>;
      code: Maybe<string>;
      suggestedSupplier: Maybe<string>;
      supplierCode: Maybe<string>;
      customFields: Maybe<{ [key: string]: any }>;
      esourcingFields: Maybe<{ [key: string]: any }>;
      attachments: Maybe<
        Array<{
          __typename?: "Attachment";
          id: string;
          filename: string;
          url: Maybe<string>;
        }>
      >;
    };
  }>;
};

export type RemovePurchaseRequestItemAttachmentMutationVariables = {
  attachmentId: Scalars["String"];
  purchaseRequestItemId: Scalars["String"];
};

export type RemovePurchaseRequestItemAttachmentMutation = {
  __typename?: "Mutation";
  removePurchaseRequestItemAttachment: {
    __typename?: "PurchaseRequestItem";
    id: string;
  };
};

export type UpdatePurchaseRequestProductMutationVariables = {
  purchaseRequestItemId: Scalars["String"];
  name: Scalars["String"];
  code: Maybe<Scalars["String"]>;
  suggestedSupplier: Maybe<Scalars["String"]>;
  supplierCode: Maybe<Scalars["String"]>;
  quantity: Scalars["Float"];
  unit: Maybe<Scalars["String"]>;
  expectedDeliveryDate: Maybe<Scalars["Date"]>;
  additionalInfo: Maybe<Scalars["String"]>;
  productUrl: Maybe<Scalars["String"]>;
  unitPrice: Maybe<Scalars["Float"]>;
  currency: Maybe<CurrencyEnum>;
  customFields: Maybe<Scalars["String"]>;
  esourcingFields: Maybe<Scalars["String"]>;
  attachments: Maybe<Array<Scalars["Upload"]>>;
};

export type UpdatePurchaseRequestProductMutation = {
  __typename?: "Mutation";
  updatePurchaseRequestProduct: {
    __typename?: "PurchaseRequestItem";
    id: string;
  };
};

export type UpdatePurchaseRequestServiceMutationVariables = {
  purchaseRequestItemId: Scalars["String"];
  name: Scalars["String"];
  code: Maybe<Scalars["String"]>;
  suggestedSupplier: Maybe<Scalars["String"]>;
  supplierCode: Maybe<Scalars["String"]>;
  expectedDeliveryDate: Maybe<Scalars["Date"]>;
  additionalInfo: Maybe<Scalars["String"]>;
  productUrl: Maybe<Scalars["String"]>;
  unitPrice: Maybe<Scalars["Float"]>;
  currency: Maybe<CurrencyEnum>;
  customFields: Maybe<Scalars["String"]>;
  esourcingFields: Maybe<Scalars["String"]>;
  attachments: Maybe<Array<Scalars["Upload"]>>;
};

export type UpdatePurchaseRequestServiceMutation = {
  __typename?: "Mutation";
  updatePurchaseRequestService: {
    __typename?: "PurchaseRequestItem";
    id: string;
  };
};

export type EmailViewQueryVariables = {
  organizationId: Scalars["String"];
  activityId: Scalars["String"];
};

export type EmailViewQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      urlName: string;
      activityById: { __typename?: "ActivityFeed" } & ActivityFeedItemFragment;
    };
  }>;
};

export type TimerSubscriptionSubscriptionVariables = {};

export type TimerSubscriptionSubscription = {
  __typename?: "Subscription";
  timer: string;
};

export type UpdateProfilePictureMutationVariables = {
  profilePicture: Scalars["Upload"];
};

export type UpdateProfilePictureMutation = {
  __typename?: "Mutation";
  updateProfilePicture: { __typename?: "Viewer"; id: string };
};

export type ActivityFeedItemFragment = {
  __typename?: "ActivityFeed";
  id: string;
  organizationId: string;
  userId: Maybe<string>;
  parentId: Maybe<string>;
  relativeId: string;
  relativeType: ActivityRelativeTypeEnum;
  type: ActivityTypeEnum;
  createdDate: any;
  updatedDate: any;
  attachments: Maybe<
    Array<{
      __typename?: "Attachment";
      id: string;
      filename: string;
      mimetype: string;
      url: Maybe<string>;
    }>
  >;
  data:
    | {
        __typename: "ADD_ATTACHMENT";
        filename: string;
        encoding: string;
        mimetype: string;
        size: number;
        url: Maybe<string>;
      }
    | {
        __typename: "ADD_ITEM";
        name: string;
        addItemQuantity: Maybe<number>;
        addItemUnit: Maybe<string>;
        creator: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
      }
    | {
        __typename: "ADD_ITEMS";
        itemCount: number;
        creator: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
      }
    | {
        __typename: "ASSIGN";
        itemInfo: {
          __typename?: "ItemInfo";
          id: string;
          type: CardTypeEnum;
          code: string;
          name: string;
        };
        assignerInfo: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
        assigneeInfo: Maybe<{
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        }>;
      }
    | {
        __typename: "COMMENT";
        comment: string;
        user: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
        relativeInfo:
          | {
              __typename: "ActivityRelativeCardInfo";
              code: string;
              url: string;
              relativeCardInfoName: string;
            }
          | {
              __typename: "ActivityRelativeItemInfo";
              quantity: Maybe<number>;
              unit: Maybe<string>;
              relativeItemInfoName: string;
            }
          | {
              __typename: "ActivityRelativeSupplierInfo";
              email: Maybe<string>;
              relativeSupplierInfoName: Maybe<string>;
            };
      }
    | {
        __typename: "CREATE_APPROVAL_REQUEST";
        approverInfo: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
        approvalRequestInfo: {
          __typename?: "ApprovalRequestInfo";
          id: string;
          dueDate: Maybe<any>;
        };
        approvalWorkflowInfo: {
          __typename?: "ApprovalWorkflowInfo";
          code: string;
          requestName: Maybe<string>;
          approvalAmount: Maybe<number>;
          status: ApprovalWorkflowStatusEnum;
        };
        approvalWorkflowStats: {
          __typename?: "ApprovalWorkflowStats";
          totalRequestCount: number;
          approvedRequestCount: number;
        };
      }
    | {
        __typename: "CREATE_APPROVAL_WORKFLOW";
        creatorInfo: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
        approvalWorkflowInfo: {
          __typename?: "ApprovalWorkflowInfo";
          code: string;
          requestName: Maybe<string>;
          comment: Maybe<string>;
          approvalAmount: Maybe<number>;
          status: ApprovalWorkflowStatusEnum;
        };
        approvalWorkflowStats: {
          __typename?: "ApprovalWorkflowStats";
          totalRequestCount: number;
          approvedRequestCount: number;
        };
      }
    | {
        __typename: "CREATE_INVOICE_FROM_ORDER";
        user: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
        orderInfo: Maybe<{
          __typename?: "ActivityRelativeOrderInfo";
          id: string;
          code: string;
          type: Maybe<OrderTypeEnum>;
        }>;
      }
    | {
        __typename: "CREATE_PURCHASE_REQUEST";
        code: string;
        creator: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
      }
    | {
        __typename: "CREATE_PURCHASE_REQUEST_PRODUCT";
        name: string;
        quantity: number;
        unit: string;
        purchaseRequestCode: string;
        creator: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
      }
    | {
        __typename: "CREATE_PURCHASE_REQUEST_SERVICE";
        name: string;
        purchaseRequestCode: string;
        creator: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
      }
    | {
        __typename: "CREATE_SUPPLIER";
        email: string;
        supplierName: Maybe<string>;
        creator: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
      }
    | {
        __typename: "DRAG_CARD";
        cardName: string;
        fromType: SwimlaneIdEnum;
        toType: SwimlaneIdEnum;
        fromCode: string;
        toCode: string;
        requestorInfo: { __typename?: "ActivityUserInfo"; firstName: string };
        assigneeInfo: Maybe<{
          __typename?: "ActivityUserInfo";
          firstName: string;
        }>;
      }
    | { __typename: "DRAG_ITEM"; cardName: string }
    | {
        __typename: "EMAIL_INBOUND_MENTION";
        comment: string;
        user: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
        relativeInfo:
          | {
              __typename: "ActivityRelativeCardInfo";
              code: string;
              url: string;
              relativeCardInfoName: string;
            }
          | {
              __typename: "ActivityRelativeItemInfo";
              quantity: Maybe<number>;
              unit: Maybe<string>;
              relativeItemInfoName: string;
            }
          | {
              __typename: "ActivityRelativeSupplierInfo";
              email: Maybe<string>;
              relativeSupplierInfoName: Maybe<string>;
            };
        emailInfo: {
          __typename?: "ActivityEmailInfo";
          subject: string;
          email: string;
          message: string;
        };
      }
    | {
        __typename: "EMAIL_INBOUND_REQUESTER";
        emailInboundRequesterInfo: string;
      }
    | {
        __typename: "EMAIL_INBOUND_SUPPLIER";
        supplierInfo: {
          __typename?: "ActivityRelativeSupplierInfo";
          id: Maybe<string>;
          name: Maybe<string>;
          email: Maybe<string>;
        };
        emailInfo: {
          __typename?: "ActivityEmailInfo";
          subject: string;
          email: string;
          message: string;
        };
      }
    | { __typename: "EMAIL_OUTBOUND_MENTION"; emailOutboundMentionInfo: string }
    | {
        __typename: "EMAIL_OUTBOUND_SUPPLIER";
        subject: string;
        message: string;
        rfxInfo: Maybe<{
          __typename?: "ActivityRelativeRfxInfo";
          id: string;
          type: Maybe<RfxTypeEnum>;
          code: string;
        }>;
        orderInfo: Maybe<{
          __typename?: "ActivityRelativeOrderInfo";
          id: string;
          type: Maybe<OrderTypeEnum>;
          code: string;
        }>;
        supplierInfo: {
          __typename?: "ActivityRelativeSupplierInfo";
          id: Maybe<string>;
          name: Maybe<string>;
          email: Maybe<string>;
        };
        creator: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
      }
    | {
        __typename: "REPLY";
        comment: string;
        user: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
        relativeInfo:
          | {
              __typename: "ActivityRelativeCardInfo";
              code: string;
              url: string;
              relativeCardInfoName: string;
            }
          | {
              __typename: "ActivityRelativeItemInfo";
              quantity: Maybe<number>;
              unit: Maybe<string>;
              relativeItemInfoName: string;
            }
          | {
              __typename: "ActivityRelativeSupplierInfo";
              email: Maybe<string>;
              relativeSupplierInfoName: Maybe<string>;
            };
      }
    | {
        __typename: "UPDATE_APPROVAL_REQUEST";
        approverInfo: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
        approvalRequestInfo: {
          __typename?: "ApprovalRequestInfo";
          id: string;
          sequenceGroup: number;
          sequenceNumber: number;
          creatorComment: Maybe<string>;
          approverComment: Maybe<string>;
          dueDate: Maybe<any>;
          decisionDate: Maybe<any>;
          decision: Maybe<ApprovalRequestDecisionEnum>;
        };
        approvalWorkflowInfo: {
          __typename?: "ApprovalWorkflowInfo";
          code: string;
          requestName: Maybe<string>;
          approvalAmount: Maybe<number>;
          status: ApprovalWorkflowStatusEnum;
        };
        approvalWorkflowStats: {
          __typename?: "ApprovalWorkflowStats";
          totalRequestCount: number;
          approvedRequestCount: number;
        };
      }
    | {
        __typename: "UPDATE_APPROVAL_WORKFLOW";
        creatorInfo: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
        relativeCardInfo: {
          __typename?: "ActivityRelativeCardInfo";
          code: string;
          name: string;
          url: string;
        };
        approvalWorkflowInfo: {
          __typename?: "ApprovalWorkflowInfo";
          code: string;
          requestName: Maybe<string>;
          comment: Maybe<string>;
          approvalAmount: Maybe<number>;
          status: ApprovalWorkflowStatusEnum;
        };
        approvalWorkflowStats: {
          __typename?: "ApprovalWorkflowStats";
          totalRequestCount: number;
          approvedRequestCount: number;
        };
      }
    | {
        __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES";
        user: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
        orderInfo: Maybe<{
          __typename?: "ActivityRelativeOrderInfo";
          id: string;
          code: string;
          type: Maybe<OrderTypeEnum>;
        }>;
      }
    | {
        __typename: "UPDATE_SUPPLIER";
        supplierName: Maybe<string>;
        creator: {
          __typename?: "ActivityUserInfo";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
        changedFields: Array<{
          __typename?: "ChangedFieldInfo";
          fieldName: string;
          oldValue: string;
          newValue: string;
        }>;
      };
  author: Maybe<{
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    avatarUrl: Maybe<string>;
  }>;
  replies: Array<{
    __typename?: "ActivityFeed";
    id: string;
    organizationId: string;
    userId: Maybe<string>;
    parentId: Maybe<string>;
    relativeId: string;
    relativeType: ActivityRelativeTypeEnum;
    type: ActivityTypeEnum;
    createdDate: any;
    updatedDate: any;
    attachments: Maybe<
      Array<{
        __typename?: "Attachment";
        id: string;
        filename: string;
        mimetype: string;
        url: Maybe<string>;
      }>
    >;
    data:
      | {
          __typename: "ADD_ATTACHMENT";
          filename: string;
          encoding: string;
          mimetype: string;
          size: number;
          url: Maybe<string>;
        }
      | {
          __typename: "ADD_ITEM";
          name: string;
          addItemQuantity: Maybe<number>;
          addItemUnit: Maybe<string>;
          creator: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
        }
      | {
          __typename: "ADD_ITEMS";
          itemCount: number;
          creator: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
        }
      | {
          __typename: "ASSIGN";
          itemInfo: {
            __typename?: "ItemInfo";
            id: string;
            type: CardTypeEnum;
            code: string;
            name: string;
          };
          assignerInfo: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          assigneeInfo: Maybe<{
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          }>;
        }
      | {
          __typename: "COMMENT";
          comment: string;
          user: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          relativeInfo:
            | {
                __typename: "ActivityRelativeCardInfo";
                code: string;
                url: string;
                relativeCardInfoName: string;
              }
            | {
                __typename: "ActivityRelativeItemInfo";
                quantity: Maybe<number>;
                unit: Maybe<string>;
                relativeItemInfoName: string;
              }
            | {
                __typename: "ActivityRelativeSupplierInfo";
                email: Maybe<string>;
                relativeSupplierInfoName: Maybe<string>;
              };
        }
      | { __typename?: "CREATE_APPROVAL_REQUEST" }
      | {
          __typename: "CREATE_APPROVAL_WORKFLOW";
          creatorInfo: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          approvalWorkflowInfo: {
            __typename?: "ApprovalWorkflowInfo";
            code: string;
            requestName: Maybe<string>;
            comment: Maybe<string>;
            approvalAmount: Maybe<number>;
            status: ApprovalWorkflowStatusEnum;
          };
        }
      | {
          __typename: "CREATE_INVOICE_FROM_ORDER";
          user: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          orderInfo: Maybe<{
            __typename?: "ActivityRelativeOrderInfo";
            id: string;
            code: string;
            type: Maybe<OrderTypeEnum>;
          }>;
        }
      | {
          __typename: "CREATE_PURCHASE_REQUEST";
          code: string;
          creator: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
        }
      | {
          __typename: "CREATE_PURCHASE_REQUEST_PRODUCT";
          name: string;
          quantity: number;
          unit: string;
          purchaseRequestCode: string;
          creator: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
        }
      | {
          __typename: "CREATE_PURCHASE_REQUEST_SERVICE";
          name: string;
          purchaseRequestCode: string;
          creator: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
        }
      | {
          __typename: "CREATE_SUPPLIER";
          email: string;
          supplierName: Maybe<string>;
          creator: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
        }
      | {
          __typename: "DRAG_CARD";
          cardName: string;
          fromType: SwimlaneIdEnum;
          toType: SwimlaneIdEnum;
          fromCode: string;
          toCode: string;
          requestorInfo: { __typename?: "ActivityUserInfo"; firstName: string };
          assigneeInfo: Maybe<{
            __typename?: "ActivityUserInfo";
            firstName: string;
          }>;
        }
      | { __typename: "DRAG_ITEM"; cardName: string }
      | {
          __typename: "EMAIL_INBOUND_MENTION";
          comment: string;
          user: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          relativeInfo:
            | {
                __typename: "ActivityRelativeCardInfo";
                code: string;
                url: string;
                relativeCardInfoName: string;
              }
            | {
                __typename: "ActivityRelativeItemInfo";
                quantity: Maybe<number>;
                unit: Maybe<string>;
                relativeItemInfoName: string;
              }
            | {
                __typename: "ActivityRelativeSupplierInfo";
                email: Maybe<string>;
                relativeSupplierInfoName: Maybe<string>;
              };
          emailInfo: {
            __typename?: "ActivityEmailInfo";
            subject: string;
            email: string;
            message: string;
          };
        }
      | {
          __typename: "EMAIL_INBOUND_REQUESTER";
          emailInboundRequesterInfo: string;
        }
      | {
          __typename: "EMAIL_INBOUND_SUPPLIER";
          supplierInfo: {
            __typename?: "ActivityRelativeSupplierInfo";
            id: Maybe<string>;
            name: Maybe<string>;
            email: Maybe<string>;
          };
          emailInfo: {
            __typename?: "ActivityEmailInfo";
            subject: string;
            email: string;
            message: string;
          };
        }
      | {
          __typename: "EMAIL_OUTBOUND_MENTION";
          emailOutboundMentionInfo: string;
        }
      | {
          __typename: "EMAIL_OUTBOUND_SUPPLIER";
          subject: string;
          message: string;
          rfxInfo: Maybe<{
            __typename?: "ActivityRelativeRfxInfo";
            id: string;
            type: Maybe<RfxTypeEnum>;
            code: string;
          }>;
          orderInfo: Maybe<{
            __typename?: "ActivityRelativeOrderInfo";
            id: string;
            type: Maybe<OrderTypeEnum>;
            code: string;
          }>;
          supplierInfo: {
            __typename?: "ActivityRelativeSupplierInfo";
            id: Maybe<string>;
            name: Maybe<string>;
            email: Maybe<string>;
          };
          creator: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
        }
      | {
          __typename: "REPLY";
          comment: string;
          user: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          relativeInfo:
            | {
                __typename: "ActivityRelativeCardInfo";
                code: string;
                url: string;
                relativeCardInfoName: string;
              }
            | {
                __typename: "ActivityRelativeItemInfo";
                quantity: Maybe<number>;
                unit: Maybe<string>;
                relativeItemInfoName: string;
              }
            | {
                __typename: "ActivityRelativeSupplierInfo";
                email: Maybe<string>;
                relativeSupplierInfoName: Maybe<string>;
              };
        }
      | { __typename?: "UPDATE_APPROVAL_REQUEST" }
      | { __typename?: "UPDATE_APPROVAL_WORKFLOW" }
      | {
          __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES";
          user: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          orderInfo: Maybe<{
            __typename?: "ActivityRelativeOrderInfo";
            id: string;
            code: string;
            type: Maybe<OrderTypeEnum>;
          }>;
        }
      | {
          __typename: "UPDATE_SUPPLIER";
          supplierName: Maybe<string>;
          creator: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          changedFields: Array<{
            __typename?: "ChangedFieldInfo";
            fieldName: string;
            oldValue: string;
            newValue: string;
          }>;
        };
    author: Maybe<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      avatarUrl: Maybe<string>;
    }>;
    replies: Array<{
      __typename?: "ActivityFeed";
      id: string;
      type: ActivityTypeEnum;
    }>;
  }>;
};

export type AddPurchaseRequestItemsToOrderMutationVariables = {
  orderId: Scalars["String"];
  purchaseRequestItems: Array<Scalars["String"]>;
};

export type AddPurchaseRequestItemsToOrderMutation = {
  __typename?: "Mutation";
  addPurchaseRequestItemsToOrder: {
    __typename?: "Order";
    id: string;
    code: string;
  };
};

export type AddPurchaseRequestItemsToRfxMutationVariables = {
  rfxId: Scalars["String"];
  purchaseRequestItems: Array<Scalars["String"]>;
};

export type AddPurchaseRequestItemsToRfxMutation = {
  __typename?: "Mutation";
  addPurchaseRequestItemsToRfx: {
    __typename?: "Rfx";
    id: string;
    code: string;
  };
};

export type AssignCardMutationVariables = {
  itemId: Scalars["String"];
  assigneeId: Maybe<Scalars["String"]>;
  type: CardTypeEnum;
};

export type AssignCardMutation = {
  __typename?: "Mutation";
  assignCard: boolean;
};

export type CreateOrderFromPurchaseRequestMutationVariables = {
  purchaseRequestId: Scalars["String"];
  relativeOrderId: Maybe<Scalars["String"]>;
  method: Maybe<CardPositionMethodEnum>;
};

export type CreateOrderFromPurchaseRequestMutation = {
  __typename?: "Mutation";
  createOrderFromPurchaseRequest: {
    __typename?: "Order";
    id: string;
    code: string;
  };
};

export type CreateOrderFromPurchaseRequestItemsMutationVariables = {
  purchaseRequestItems: Array<Scalars["String"]>;
};

export type CreateOrderFromPurchaseRequestItemsMutation = {
  __typename?: "Mutation";
  createOrderFromPurchaseRequestItems: {
    __typename?: "Order";
    id: string;
    code: string;
  };
};

export type CreateOrderFromRfxMutationVariables = {
  rfxId: Scalars["String"];
  relativeOrderId: Maybe<Scalars["String"]>;
  method: Maybe<CardPositionMethodEnum>;
  rfxItemIds: Maybe<Array<Scalars["String"]>>;
  supplierId: Maybe<Scalars["String"]>;
  hideRfx: Maybe<Scalars["Boolean"]>;
  isCreatedFromSourcingEvent: Maybe<Scalars["Boolean"]>;
};

export type CreateOrderFromRfxMutation = {
  __typename?: "Mutation";
  createOrderFromRfx: { __typename?: "Order"; id: string; code: string };
};

export type CreateRfxFromPurchaseRequestMutationVariables = {
  purchaseRequestId: Scalars["String"];
  relativeRfxId: Maybe<Scalars["String"]>;
  method: Maybe<CardPositionMethodEnum>;
};

export type CreateRfxFromPurchaseRequestMutation = {
  __typename?: "Mutation";
  createRfxFromPurchaseRequest: {
    __typename?: "Rfx";
    id: string;
    code: string;
  };
};

export type CreateRfxFromPurchaseRequestItemsMutationVariables = {
  purchaseRequestItems: Array<Scalars["String"]>;
};

export type CreateRfxFromPurchaseRequestItemsMutation = {
  __typename?: "Mutation";
  createRfxFromPurchaseRequestItems: {
    __typename?: "Rfx";
    id: string;
    code: string;
  };
};

export type FullTextSearchMutationVariables = {
  searchInput: Scalars["String"];
  organizationId: Scalars["String"];
  filter: Maybe<KanbanFilterInput>;
};

export type FullTextSearchMutation = {
  __typename?: "Mutation";
  fullTextSearch: Array<{
    __typename?: "fullTextSearchResult";
    id: string;
    name: string;
    code: Maybe<string>;
    prefix: string;
    type: FullTextSearchResultTypeEnum;
    isArchived: boolean;
    parent: Maybe<{
      __typename?: "fullTextSearchItemParentInfo";
      code: string;
      prefix: string;
      type: FullTextSearchResultTypeEnum;
      status: {
        __typename?: "CardStatus";
        text: string;
        level: CardStatusLevelEnum;
      };
    }>;
    status: Maybe<{
      __typename?: "CardStatus";
      text: string;
      level: CardStatusLevelEnum;
    }>;
  }>;
};

export type PurchaseRequestItemInfoFragment = {
  __typename?: "PurchaseRequestItem";
  id: string;
  name: string;
  parentCode: string;
  type: PurchaseRequestItemTypeEnum;
  quantity: Maybe<number>;
  receivedQuantity: Maybe<number>;
  totalReceivedQuantity: Maybe<number>;
  unit: Maybe<string>;
  expectedDeliveryDate: Maybe<any>;
  productUrl: Maybe<string>;
  unitPrice: Maybe<number>;
  currency: Maybe<CurrencyEnum>;
  createdDate: any;
  updatedDate: any;
  assignee: Maybe<{
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    avatarUrl: Maybe<string>;
  }>;
};

export type UserInfoFragment = {
  __typename?: "User";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl: Maybe<string>;
  roles: Array<UserRoleEnum>;
  status: UserStatusEnum;
  department: Maybe<{
    __typename?: "Department";
    id: string;
    name: string;
    code: Maybe<string>;
  }>;
  project: Maybe<{ __typename?: "Project"; id: string; name: string }>;
  costCentre: Maybe<{ __typename?: "CostCentre"; id: string; name: string }>;
};

export type SupplierInfoFragment = {
  __typename?: "Supplier";
  id: string;
  name: Maybe<string>;
  paymentTerms: Maybe<string>;
  defaultContactPerson: {
    __typename?: "ContactPerson";
    id: string;
    email: string;
  };
};

export type ProjectInfoFragment = {
  __typename?: "Project";
  id: string;
  name: string;
};

export type DepartmentInfoFragment = {
  __typename?: "Department";
  id: string;
  name: string;
  code: Maybe<string>;
};

export type KanbanViewQueryVariables = {
  organizationId: Scalars["String"];
};

export type KanbanViewQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    avatarUrl: Maybe<string>;
    roles: Array<UserRoleEnum>;
    organization: {
      __typename?: "Organization";
      id: string;
      urlName: string;
      users: Array<{ __typename?: "User" } & UserInfoFragment>;
    };
  }>;
};

export type PaginatedInvoicesQueryVariables = {
  organizationId: Scalars["String"];
  take: Scalars["Int"];
  cursor: Maybe<Scalars["String"]>;
  filter: Maybe<KanbanFilterInput>;
};

export type PaginatedInvoicesQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      paginatedInvoices: {
        __typename?: "PaginatedOrderResponse";
        edges: Array<{
          __typename?: "Order";
          id: string;
          code: string;
          name: string;
          type: OrderTypeEnum;
          status: Maybe<OrderStatusEnum>;
          createdDate: any;
          cardStatus: {
            __typename?: "CardStatus";
            level: CardStatusLevelEnum;
            text: string;
          };
          assignee: Maybe<{ __typename?: "User" } & UserInfoFragment>;
          supplier: Maybe<{ __typename?: "Supplier" } & SupplierInfoFragment>;
          items: Array<
            {
              __typename?: "PurchaseRequestItem";
            } & PurchaseRequestItemInfoFragment
          >;
          projects: Array<{ __typename?: "Project" } & ProjectInfoFragment>;
          requestors: Array<{ __typename?: "User" } & UserInfoFragment>;
        }>;
        pageInfo: {
          __typename?: "PageInfo";
          endCursor: Maybe<string>;
          hasNextPage: boolean;
        };
      };
    };
  }>;
};

export type PaginatedOrdersQueryVariables = {
  organizationId: Scalars["String"];
  take: Scalars["Int"];
  cursor: Maybe<Scalars["String"]>;
  filter: Maybe<KanbanFilterInput>;
};

export type PaginatedOrdersQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      paginatedOrders: {
        __typename?: "PaginatedOrderResponse";
        edges: Array<{
          __typename?: "Order";
          id: string;
          code: string;
          name: string;
          type: OrderTypeEnum;
          status: Maybe<OrderStatusEnum>;
          createdDate: any;
          department: Maybe<{
            __typename?: "Department";
            id: string;
            name: string;
            code: Maybe<string>;
          }>;
          cardStatus: {
            __typename?: "CardStatus";
            level: CardStatusLevelEnum;
            text: string;
          };
          assignee: Maybe<{ __typename?: "User" } & UserInfoFragment>;
          supplier: Maybe<{ __typename?: "Supplier" } & SupplierInfoFragment>;
          items: Array<
            {
              __typename?: "PurchaseRequestItem";
            } & PurchaseRequestItemInfoFragment
          >;
          projects: Array<{ __typename?: "Project" } & ProjectInfoFragment>;
          requestors: Array<{ __typename?: "User" } & UserInfoFragment>;
        }>;
        pageInfo: {
          __typename?: "PageInfo";
          endCursor: Maybe<string>;
          hasNextPage: boolean;
        };
      };
    };
  }>;
};

export type PaginatedPurchaseRequestsQueryVariables = {
  organizationId: Scalars["String"];
  take: Scalars["Int"];
  cursor: Maybe<Scalars["String"]>;
  filter: Maybe<KanbanFilterInput>;
};

export type PaginatedPurchaseRequestsQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      paginatedPurchaseRequests: {
        __typename?: "PaginatedPurchaseRequestsResponse";
        edges: Array<{
          __typename?: "PurchaseRequest";
          id: string;
          code: string;
          status: PurchaseRequestStatusEnum;
          name: string;
          isRfxCreated: boolean;
          isOrderCreated: boolean;
          createdDate: any;
          cardStatus: {
            __typename?: "CardStatus";
            level: CardStatusLevelEnum;
            text: string;
          };
          requestor: { __typename?: "User" } & UserInfoFragment;
          assignee: Maybe<{ __typename?: "User" } & UserInfoFragment>;
          project: Maybe<{ __typename?: "Project" } & ProjectInfoFragment>;
          suppliers: Array<{ __typename?: "Supplier" } & SupplierInfoFragment>;
          department: Maybe<
            { __typename?: "Department" } & DepartmentInfoFragment
          >;
          items: Array<
            {
              __typename?: "PurchaseRequestItem";
              status: {
                __typename?: "CardStatus";
                level: CardStatusLevelEnum;
                text: string;
              };
            } & PurchaseRequestItemInfoFragment
          >;
          latestApprovalWorkflow: Maybe<{
            __typename?: "ApprovalWorkflow";
            id: string;
            code: string;
            status: ApprovalWorkflowStatusEnum;
          }>;
        }>;
        pageInfo: {
          __typename?: "PageInfo";
          endCursor: Maybe<string>;
          hasNextPage: boolean;
        };
      };
    };
  }>;
};

export type PaginatedReceivingQueryVariables = {
  organizationId: Scalars["String"];
  take: Scalars["Int"];
  cursor: Maybe<Scalars["String"]>;
  filter: Maybe<KanbanFilterInput>;
};

export type PaginatedReceivingQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      paginatedReceiving: {
        __typename?: "PaginatedOrderResponse";
        edges: Array<{
          __typename?: "Order";
          id: string;
          code: string;
          name: string;
          type: OrderTypeEnum;
          status: Maybe<OrderStatusEnum>;
          isConfirmationRequired: boolean;
          receivingIsConfirmationReceived: boolean;
          expectedDeliveryDate: Maybe<any>;
          createdDate: any;
          cardStatus: {
            __typename?: "CardStatus";
            level: CardStatusLevelEnum;
            text: string;
          };
          assignee: Maybe<{ __typename?: "User" } & UserInfoFragment>;
          supplier: Maybe<{ __typename?: "Supplier" } & SupplierInfoFragment>;
          items: Array<
            {
              __typename?: "PurchaseRequestItem";
            } & PurchaseRequestItemInfoFragment
          >;
          projects: Array<{ __typename?: "Project" } & ProjectInfoFragment>;
          requestors: Array<{ __typename?: "User" } & UserInfoFragment>;
        }>;
        pageInfo: {
          __typename?: "PageInfo";
          endCursor: Maybe<string>;
          hasNextPage: boolean;
        };
      };
    };
  }>;
};

export type PaginatedRfxQueryVariables = {
  organizationId: Scalars["String"];
  take: Scalars["Int"];
  cursor: Maybe<Scalars["String"]>;
  filter: Maybe<KanbanFilterInput>;
};

export type PaginatedRfxQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      paginatedRfx: {
        __typename?: "PaginatedRfxResponse";
        edges: Array<{
          __typename?: "Rfx";
          id: string;
          code: string;
          name: string;
          type: Maybe<RfxTypeEnum>;
          status: RfxStatusEnum;
          createdDate: any;
          cardStatus: {
            __typename?: "CardStatus";
            level: CardStatusLevelEnum;
            text: string;
          };
          assignee: Maybe<{ __typename?: "User" } & UserInfoFragment>;
          suppliers: Array<{ __typename?: "Supplier" } & SupplierInfoFragment>;
          items: Array<
            {
              __typename?: "PurchaseRequestItem";
            } & PurchaseRequestItemInfoFragment
          >;
          projects: Array<{ __typename?: "Project" } & ProjectInfoFragment>;
          requestors: Array<{ __typename?: "User" } & UserInfoFragment>;
        }>;
        pageInfo: {
          __typename?: "PageInfo";
          endCursor: Maybe<string>;
          hasNextPage: boolean;
        };
      };
    };
  }>;
};

export type UpdateCardPositionMutationVariables = {
  itemId: Scalars["String"];
  relativeId: Scalars["String"];
  method: CardPositionMethodEnum;
  type: CardTypeEnum;
};

export type UpdateCardPositionMutation = {
  __typename?: "Mutation";
  updateCardPosition: boolean;
};

export type UpdateOrderTypeMutationVariables = {
  orderId: Scalars["String"];
  type: OrderTypeEnum;
  relativeOrderId: Maybe<Scalars["String"]>;
  method: Maybe<CardPositionMethodEnum>;
  positionWithoutRelativeOrder: Maybe<Scalars["Boolean"]>;
};

export type UpdateOrderTypeMutation = {
  __typename?: "Mutation";
  updateOrderType: { __typename?: "Order"; id: string; code: string };
};

export type LoginSupplierUserWithTokenMutationVariables = {
  token: Scalars["String"];
};

export type LoginSupplierUserWithTokenMutation = {
  __typename?: "Mutation";
  loginSupplierUserWithToken: {
    __typename?: "Viewer";
    id: string;
    firstName: string;
    lastName: string;
    roles: Array<UserRoleEnum>;
  };
};

export type LoginWithTokenMutationVariables = {
  token: Scalars["String"];
};

export type LoginWithTokenMutation = {
  __typename?: "Mutation";
  loginWithToken: {
    __typename?: "Viewer";
    id: string;
    firstName: string;
    lastName: string;
    roles: Array<UserRoleEnum>;
  };
};

export type SendLoginTokenMutationVariables = {
  email: Scalars["String"];
};

export type SendLoginTokenMutation = {
  __typename?: "Mutation";
  sendLoginToken: boolean;
};

export type LogoutMutationVariables = {};

export type LogoutMutation = { __typename?: "Mutation"; logout: boolean };

export type MarkAllNotificationsSeenMutationVariables = {
  organizationId: Scalars["String"];
};

export type MarkAllNotificationsSeenMutation = {
  __typename?: "Mutation";
  markAllNotificationsSeen: boolean;
};

export type MarkNotificationsSeenByIdsMutationVariables = {
  notificationIds: Array<Scalars["String"]>;
};

export type MarkNotificationsSeenByIdsMutation = {
  __typename?: "Mutation";
  markNotificationsSeenByIds: boolean;
};

export type NotificationsQueryVariables = {
  organizatonId: Scalars["String"];
  skipSeen: Maybe<Scalars["Boolean"]>;
};

export type NotificationsQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      notifications: Array<{
        __typename?: "Notification";
        id: string;
        hasSeen: boolean;
        createdDate: any;
        activity: {
          __typename?: "ActivityFeed";
          id: string;
          organizationId: string;
          relativeId: string;
          type: ActivityTypeEnum;
          relativeType: ActivityRelativeTypeEnum;
          parentId: Maybe<string>;
          userId: Maybe<string>;
          updatedDate: any;
          createdDate: any;
          relativeInfo: Maybe<
            | {
                __typename?: "ActivityRelativeCardInfo";
                code: string;
                assigneeId: Maybe<string>;
                requestorId: Maybe<string>;
              }
            | { __typename?: "ActivityRelativeItemInfo"; name: string }
            | { __typename?: "ActivityRelativeSupplierInfo" }
          >;
          data:
            | {
                __typename: "ADD_ATTACHMENT";
                filename: string;
                encoding: string;
                mimetype: string;
                size: number;
                url: Maybe<string>;
              }
            | {
                __typename: "ADD_ITEM";
                name: string;
                addItemQuantity: Maybe<number>;
                addItemUnit: Maybe<string>;
                creator: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
              }
            | {
                __typename: "ADD_ITEMS";
                itemCount: number;
                creator: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
              }
            | {
                __typename: "ASSIGN";
                itemInfo: {
                  __typename?: "ItemInfo";
                  id: string;
                  type: CardTypeEnum;
                  code: string;
                  name: string;
                };
                assignerInfo: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
                assigneeInfo: Maybe<{
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                }>;
              }
            | {
                __typename: "COMMENT";
                comment: string;
                user: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
                relativeInfo:
                  | {
                      __typename: "ActivityRelativeCardInfo";
                      code: string;
                      url: string;
                      relativeCardInfoName: string;
                    }
                  | {
                      __typename: "ActivityRelativeItemInfo";
                      quantity: Maybe<number>;
                      unit: Maybe<string>;
                      relativeItemInfoName: string;
                    }
                  | {
                      __typename: "ActivityRelativeSupplierInfo";
                      email: Maybe<string>;
                      relativeSupplierInfoName: Maybe<string>;
                    };
              }
            | {
                __typename: "CREATE_APPROVAL_REQUEST";
                approverInfo: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
                approvalRequestInfo: {
                  __typename?: "ApprovalRequestInfo";
                  id: string;
                  sequenceGroup: number;
                  sequenceNumber: number;
                  creatorComment: Maybe<string>;
                  approverComment: Maybe<string>;
                  dueDate: Maybe<any>;
                  decisionDate: Maybe<any>;
                  decision: Maybe<ApprovalRequestDecisionEnum>;
                };
                approvalWorkflowInfo: {
                  __typename?: "ApprovalWorkflowInfo";
                  code: string;
                  requestName: Maybe<string>;
                  approvalAmount: Maybe<number>;
                  status: ApprovalWorkflowStatusEnum;
                };
                approvalWorkflowStats: {
                  __typename?: "ApprovalWorkflowStats";
                  totalRequestCount: number;
                  approvedRequestCount: number;
                };
              }
            | {
                __typename: "CREATE_APPROVAL_WORKFLOW";
                creatorInfo: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
                approvalWorkflowInfo: {
                  __typename?: "ApprovalWorkflowInfo";
                  code: string;
                  requestName: Maybe<string>;
                  comment: Maybe<string>;
                  approvalAmount: Maybe<number>;
                  status: ApprovalWorkflowStatusEnum;
                };
                approvalWorkflowStats: {
                  __typename?: "ApprovalWorkflowStats";
                  totalRequestCount: number;
                  approvedRequestCount: number;
                };
              }
            | {
                __typename: "CREATE_INVOICE_FROM_ORDER";
                user: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
                orderInfo: Maybe<{
                  __typename?: "ActivityRelativeOrderInfo";
                  id: string;
                  code: string;
                  type: Maybe<OrderTypeEnum>;
                }>;
              }
            | {
                __typename: "CREATE_PURCHASE_REQUEST";
                code: string;
                creator: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
              }
            | {
                __typename: "CREATE_PURCHASE_REQUEST_PRODUCT";
                name: string;
                quantity: number;
                unit: string;
                purchaseRequestCode: string;
                creator: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
              }
            | {
                __typename: "CREATE_PURCHASE_REQUEST_SERVICE";
                name: string;
                purchaseRequestCode: string;
                creator: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
              }
            | {
                __typename: "CREATE_SUPPLIER";
                email: string;
                supplierName: Maybe<string>;
                creator: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
              }
            | {
                __typename: "DRAG_CARD";
                cardName: string;
                fromType: SwimlaneIdEnum;
                toType: SwimlaneIdEnum;
                fromCode: string;
                toCode: string;
                requestorInfo: {
                  __typename?: "ActivityUserInfo";
                  firstName: string;
                };
                assigneeInfo: Maybe<{
                  __typename?: "ActivityUserInfo";
                  firstName: string;
                }>;
              }
            | { __typename: "DRAG_ITEM"; cardName: string }
            | {
                __typename: "EMAIL_INBOUND_MENTION";
                comment: string;
                user: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
                relativeInfo:
                  | {
                      __typename: "ActivityRelativeCardInfo";
                      code: string;
                      url: string;
                      relativeCardInfoName: string;
                    }
                  | {
                      __typename: "ActivityRelativeItemInfo";
                      quantity: Maybe<number>;
                      unit: Maybe<string>;
                      relativeItemInfoName: string;
                    }
                  | {
                      __typename: "ActivityRelativeSupplierInfo";
                      email: Maybe<string>;
                      relativeSupplierInfoName: Maybe<string>;
                    };
                emailInfo: {
                  __typename?: "ActivityEmailInfo";
                  subject: string;
                  email: string;
                  message: string;
                };
              }
            | {
                __typename: "EMAIL_INBOUND_REQUESTER";
                emailInboundRequesterInfo: string;
              }
            | {
                __typename: "EMAIL_INBOUND_SUPPLIER";
                supplierInfo: {
                  __typename?: "ActivityRelativeSupplierInfo";
                  id: Maybe<string>;
                  name: Maybe<string>;
                  email: Maybe<string>;
                };
                emailInfo: {
                  __typename?: "ActivityEmailInfo";
                  subject: string;
                  email: string;
                  message: string;
                };
              }
            | {
                __typename: "EMAIL_OUTBOUND_MENTION";
                emailOutboundMentionInfo: string;
                user: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
              }
            | {
                __typename: "EMAIL_OUTBOUND_SUPPLIER";
                subject: string;
                message: string;
                rfxInfo: Maybe<{
                  __typename?: "ActivityRelativeRfxInfo";
                  id: string;
                  type: Maybe<RfxTypeEnum>;
                  code: string;
                }>;
                orderInfo: Maybe<{
                  __typename?: "ActivityRelativeOrderInfo";
                  id: string;
                  type: Maybe<OrderTypeEnum>;
                  code: string;
                }>;
                supplierInfo: {
                  __typename?: "ActivityRelativeSupplierInfo";
                  id: Maybe<string>;
                  name: Maybe<string>;
                  email: Maybe<string>;
                };
                creator: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
              }
            | {
                __typename: "REPLY";
                comment: string;
                user: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
                relativeInfo:
                  | {
                      __typename: "ActivityRelativeCardInfo";
                      code: string;
                      url: string;
                      relativeCardInfoName: string;
                    }
                  | {
                      __typename: "ActivityRelativeItemInfo";
                      quantity: Maybe<number>;
                      unit: Maybe<string>;
                      relativeItemInfoName: string;
                    }
                  | {
                      __typename: "ActivityRelativeSupplierInfo";
                      email: Maybe<string>;
                      relativeSupplierInfoName: Maybe<string>;
                    };
              }
            | {
                __typename: "UPDATE_APPROVAL_REQUEST";
                approverInfo: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
                approvalRequestInfo: {
                  __typename?: "ApprovalRequestInfo";
                  id: string;
                  sequenceGroup: number;
                  sequenceNumber: number;
                  creatorComment: Maybe<string>;
                  approverComment: Maybe<string>;
                  dueDate: Maybe<any>;
                  decisionDate: Maybe<any>;
                  decision: Maybe<ApprovalRequestDecisionEnum>;
                };
                approvalWorkflowInfo: {
                  __typename?: "ApprovalWorkflowInfo";
                  code: string;
                  requestName: Maybe<string>;
                  approvalAmount: Maybe<number>;
                  status: ApprovalWorkflowStatusEnum;
                };
                approvalWorkflowStats: {
                  __typename?: "ApprovalWorkflowStats";
                  totalRequestCount: number;
                  approvedRequestCount: number;
                };
              }
            | {
                __typename: "UPDATE_APPROVAL_WORKFLOW";
                creatorInfo: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
                approvalWorkflowInfo: {
                  __typename?: "ApprovalWorkflowInfo";
                  code: string;
                  requestName: Maybe<string>;
                  comment: Maybe<string>;
                  approvalAmount: Maybe<number>;
                  status: ApprovalWorkflowStatusEnum;
                };
                relativeCardInfo: {
                  __typename?: "ActivityRelativeCardInfo";
                  code: string;
                  name: string;
                  url: string;
                };
                approvalWorkflowStats: {
                  __typename?: "ApprovalWorkflowStats";
                  totalRequestCount: number;
                  approvedRequestCount: number;
                };
              }
            | {
                __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES";
                user: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
                orderInfo: Maybe<{
                  __typename?: "ActivityRelativeOrderInfo";
                  id: string;
                  code: string;
                  type: Maybe<OrderTypeEnum>;
                }>;
              }
            | {
                __typename: "UPDATE_SUPPLIER";
                supplierName: Maybe<string>;
                creator: {
                  __typename?: "ActivityUserInfo";
                  id: string;
                  firstName: string;
                  lastName: string;
                  avatarUrl: Maybe<string>;
                };
                changedFields: Array<{
                  __typename?: "ChangedFieldInfo";
                  fieldName: string;
                  oldValue: string;
                  newValue: string;
                }>;
              };
          author: Maybe<{
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            email: string;
            avatarUrl: Maybe<string>;
          }>;
          replies: Array<{
            __typename?: "ActivityFeed";
            id: string;
            userId: Maybe<string>;
            organizationId: string;
            relativeId: string;
            type: ActivityTypeEnum;
            relativeType: ActivityRelativeTypeEnum;
            parentId: Maybe<string>;
            createdDate: any;
            updatedDate: any;
            data:
              | {
                  __typename: "ADD_ATTACHMENT";
                  filename: string;
                  encoding: string;
                  mimetype: string;
                  size: number;
                  url: Maybe<string>;
                }
              | {
                  __typename: "ADD_ITEM";
                  name: string;
                  addItemQuantity: Maybe<number>;
                  addItemUnit: Maybe<string>;
                  creator: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                }
              | {
                  __typename: "ADD_ITEMS";
                  itemCount: number;
                  creator: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                }
              | {
                  __typename: "ASSIGN";
                  itemInfo: {
                    __typename?: "ItemInfo";
                    id: string;
                    type: CardTypeEnum;
                    code: string;
                    name: string;
                  };
                  assignerInfo: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                  assigneeInfo: Maybe<{
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  }>;
                }
              | {
                  __typename: "COMMENT";
                  comment: string;
                  user: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                  relativeInfo:
                    | {
                        __typename: "ActivityRelativeCardInfo";
                        code: string;
                        url: string;
                        relativeCardInfoName: string;
                      }
                    | {
                        __typename: "ActivityRelativeItemInfo";
                        quantity: Maybe<number>;
                        unit: Maybe<string>;
                        relativeItemInfoName: string;
                      }
                    | {
                        __typename: "ActivityRelativeSupplierInfo";
                        email: Maybe<string>;
                        relativeSupplierInfoName: Maybe<string>;
                      };
                }
              | {
                  __typename: "CREATE_APPROVAL_REQUEST";
                  approverInfo: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                  approvalRequestInfo: {
                    __typename?: "ApprovalRequestInfo";
                    id: string;
                    sequenceGroup: number;
                    sequenceNumber: number;
                    creatorComment: Maybe<string>;
                    approverComment: Maybe<string>;
                    dueDate: Maybe<any>;
                    decisionDate: Maybe<any>;
                    decision: Maybe<ApprovalRequestDecisionEnum>;
                  };
                  approvalWorkflowInfo: {
                    __typename?: "ApprovalWorkflowInfo";
                    code: string;
                    requestName: Maybe<string>;
                    approvalAmount: Maybe<number>;
                    status: ApprovalWorkflowStatusEnum;
                  };
                  approvalWorkflowStats: {
                    __typename?: "ApprovalWorkflowStats";
                    totalRequestCount: number;
                    approvedRequestCount: number;
                  };
                }
              | {
                  __typename: "CREATE_APPROVAL_WORKFLOW";
                  creatorInfo: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                  approvalWorkflowInfo: {
                    __typename?: "ApprovalWorkflowInfo";
                    code: string;
                    requestName: Maybe<string>;
                    comment: Maybe<string>;
                    approvalAmount: Maybe<number>;
                    status: ApprovalWorkflowStatusEnum;
                  };
                  approvalWorkflowStats: {
                    __typename?: "ApprovalWorkflowStats";
                    totalRequestCount: number;
                    approvedRequestCount: number;
                  };
                }
              | {
                  __typename: "CREATE_INVOICE_FROM_ORDER";
                  user: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                  orderInfo: Maybe<{
                    __typename?: "ActivityRelativeOrderInfo";
                    id: string;
                    code: string;
                    type: Maybe<OrderTypeEnum>;
                  }>;
                }
              | {
                  __typename: "CREATE_PURCHASE_REQUEST";
                  code: string;
                  creator: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                }
              | {
                  __typename: "CREATE_PURCHASE_REQUEST_PRODUCT";
                  name: string;
                  quantity: number;
                  unit: string;
                  purchaseRequestCode: string;
                  creator: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                }
              | {
                  __typename: "CREATE_PURCHASE_REQUEST_SERVICE";
                  name: string;
                  purchaseRequestCode: string;
                  creator: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                }
              | {
                  __typename: "CREATE_SUPPLIER";
                  email: string;
                  supplierName: Maybe<string>;
                  creator: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                }
              | {
                  __typename: "DRAG_CARD";
                  cardName: string;
                  fromType: SwimlaneIdEnum;
                  toType: SwimlaneIdEnum;
                  fromCode: string;
                  toCode: string;
                  requestorInfo: {
                    __typename?: "ActivityUserInfo";
                    firstName: string;
                  };
                  assigneeInfo: Maybe<{
                    __typename?: "ActivityUserInfo";
                    firstName: string;
                  }>;
                }
              | { __typename: "DRAG_ITEM"; cardName: string }
              | {
                  __typename: "EMAIL_INBOUND_MENTION";
                  comment: string;
                  user: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                  relativeInfo:
                    | {
                        __typename: "ActivityRelativeCardInfo";
                        code: string;
                        url: string;
                        relativeCardInfoName: string;
                      }
                    | {
                        __typename: "ActivityRelativeItemInfo";
                        quantity: Maybe<number>;
                        unit: Maybe<string>;
                        relativeItemInfoName: string;
                      }
                    | {
                        __typename: "ActivityRelativeSupplierInfo";
                        email: Maybe<string>;
                        relativeSupplierInfoName: Maybe<string>;
                      };
                  emailInfo: {
                    __typename?: "ActivityEmailInfo";
                    subject: string;
                    email: string;
                    message: string;
                  };
                }
              | {
                  __typename: "EMAIL_INBOUND_REQUESTER";
                  emailInboundRequesterInfo: string;
                }
              | {
                  __typename: "EMAIL_INBOUND_SUPPLIER";
                  supplierInfo: {
                    __typename?: "ActivityRelativeSupplierInfo";
                    id: Maybe<string>;
                    name: Maybe<string>;
                    email: Maybe<string>;
                  };
                  emailInfo: {
                    __typename?: "ActivityEmailInfo";
                    subject: string;
                    email: string;
                    message: string;
                  };
                }
              | {
                  __typename: "EMAIL_OUTBOUND_MENTION";
                  emailOutboundMentionInfo: string;
                }
              | {
                  __typename: "EMAIL_OUTBOUND_SUPPLIER";
                  subject: string;
                  message: string;
                  rfxInfo: Maybe<{
                    __typename?: "ActivityRelativeRfxInfo";
                    id: string;
                    type: Maybe<RfxTypeEnum>;
                    code: string;
                  }>;
                  orderInfo: Maybe<{
                    __typename?: "ActivityRelativeOrderInfo";
                    id: string;
                    type: Maybe<OrderTypeEnum>;
                    code: string;
                  }>;
                  supplierInfo: {
                    __typename?: "ActivityRelativeSupplierInfo";
                    id: Maybe<string>;
                    name: Maybe<string>;
                    email: Maybe<string>;
                  };
                  creator: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                }
              | {
                  __typename: "REPLY";
                  comment: string;
                  user: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                  relativeInfo:
                    | {
                        __typename: "ActivityRelativeCardInfo";
                        code: string;
                        url: string;
                        relativeCardInfoName: string;
                      }
                    | {
                        __typename: "ActivityRelativeItemInfo";
                        quantity: Maybe<number>;
                        unit: Maybe<string>;
                        relativeItemInfoName: string;
                      }
                    | {
                        __typename: "ActivityRelativeSupplierInfo";
                        email: Maybe<string>;
                        relativeSupplierInfoName: Maybe<string>;
                      };
                }
              | {
                  __typename: "UPDATE_APPROVAL_REQUEST";
                  approverInfo: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                  approvalRequestInfo: {
                    __typename?: "ApprovalRequestInfo";
                    id: string;
                    sequenceGroup: number;
                    sequenceNumber: number;
                    creatorComment: Maybe<string>;
                    approverComment: Maybe<string>;
                    dueDate: Maybe<any>;
                    decisionDate: Maybe<any>;
                    decision: Maybe<ApprovalRequestDecisionEnum>;
                  };
                  approvalWorkflowInfo: {
                    __typename?: "ApprovalWorkflowInfo";
                    code: string;
                    requestName: Maybe<string>;
                    approvalAmount: Maybe<number>;
                    status: ApprovalWorkflowStatusEnum;
                  };
                  approvalWorkflowStats: {
                    __typename?: "ApprovalWorkflowStats";
                    totalRequestCount: number;
                    approvedRequestCount: number;
                  };
                }
              | {
                  __typename: "UPDATE_APPROVAL_WORKFLOW";
                  creatorInfo: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                  approvalWorkflowInfo: {
                    __typename?: "ApprovalWorkflowInfo";
                    code: string;
                    requestName: Maybe<string>;
                    comment: Maybe<string>;
                    approvalAmount: Maybe<number>;
                    status: ApprovalWorkflowStatusEnum;
                  };
                  relativeCardInfo: {
                    __typename?: "ActivityRelativeCardInfo";
                    code: string;
                    name: string;
                    url: string;
                  };
                  approvalWorkflowStats: {
                    __typename?: "ApprovalWorkflowStats";
                    totalRequestCount: number;
                    approvedRequestCount: number;
                  };
                }
              | {
                  __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES";
                  user: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                  orderInfo: Maybe<{
                    __typename?: "ActivityRelativeOrderInfo";
                    id: string;
                    code: string;
                    type: Maybe<OrderTypeEnum>;
                  }>;
                }
              | {
                  __typename: "UPDATE_SUPPLIER";
                  supplierName: Maybe<string>;
                  creator: {
                    __typename?: "ActivityUserInfo";
                    id: string;
                    firstName: string;
                    lastName: string;
                    avatarUrl: Maybe<string>;
                  };
                  changedFields: Array<{
                    __typename?: "ChangedFieldInfo";
                    fieldName: string;
                    oldValue: string;
                    newValue: string;
                  }>;
                };
            author: Maybe<{
              __typename?: "User";
              id: string;
              firstName: string;
              lastName: string;
              email: string;
              avatarUrl: Maybe<string>;
            }>;
          }>;
        };
      }>;
    };
  }>;
};

export type ArchiveOrderMutationVariables = {
  orderId: Scalars["String"];
};

export type ArchiveOrderMutation = {
  __typename?: "Mutation";
  archiveOrder: { __typename?: "Order"; id: string };
};

export type CreateOrderInvoiceFromOrderMutationVariables = {
  orderId: Scalars["String"];
};

export type CreateOrderInvoiceFromOrderMutation = {
  __typename?: "Mutation";
  createOrderInvoiceFromOrder: {
    __typename?: "Order";
    id: string;
    code: string;
  };
};

export type CreateOrganizationAddressMutationVariables = {
  organizationId: Scalars["String"];
  locationName: Maybe<Scalars["String"]>;
  recipientName: Maybe<Scalars["String"]>;
  address: Scalars["String"];
  city: Scalars["String"];
  areaCode: Scalars["String"];
  countryCode: CountryCodeEnum;
  addressType: AddressTypeEnum;
};

export type CreateOrganizationAddressMutation = {
  __typename?: "Mutation";
  createOrganizationAddress: { __typename?: "Address" } & AddressInfoFragment;
};

export type OrderByCodeQueryVariables = {
  organizationId: Scalars["String"];
  code: Scalars["String"];
  withDeleted: Maybe<Scalars["Boolean"]>;
};

export type OrderByCodeQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    orderByCode: {
      __typename?: "Order";
      id: string;
      organizationId: string;
      supplierId: Maybe<string>;
      creatorId: string;
      assigneeId: Maybe<string>;
      shippingAddressId: Maybe<string>;
      invoiceAddressId: Maybe<string>;
      externalIdentifier: Maybe<string>;
      hasBeenSent: boolean;
      poReleaseMethod: Maybe<PurchaseOrderReleaseMethodEnum>;
      name: string;
      code: string;
      type: OrderTypeEnum;
      status: Maybe<OrderStatusEnum>;
      isConfirmationRequired: boolean;
      expectedConfirmationDate: Maybe<any>;
      purchaseOrderExpectedDate: Maybe<any>;
      expectedDeliveryDate: Maybe<any>;
      purchaseOrderRenewalDate: Maybe<any>;
      purchaseOrderIssueDate: Maybe<any>;
      purchaseOrderCurrency: Maybe<CurrencyEnum>;
      paymentTerm: Maybe<string>;
      purchaseOrderDeliveryNotes: Maybe<string>;
      purchaseOrderBillingNotes: Maybe<string>;
      purchaseOrderSupplierNotes: Maybe<string>;
      purchaseConfirmationNotes: Maybe<string>;
      receivingIsConfirmationReceived: boolean;
      receivingGoodsReceived: boolean;
      receivingGoodsReceivedPartially: boolean;
      receivingIsAddedToStock: boolean;
      receivingIsPoCancelled: boolean;
      receivingWarehouseCode: Maybe<string>;
      receivingNotes: Maybe<string>;
      invoiceNumber: Maybe<string>;
      vatPercent: Maybe<number>;
      invoiceReceived: boolean;
      invoiceThreeWayMatch: boolean;
      invoiceApproved: boolean;
      invoicePaid: boolean;
      invoiceIsSentToBilling: boolean;
      createdDate: any;
      updatedDate: any;
      deletedDate: Maybe<any>;
      department: Maybe<{
        __typename?: "Department";
        id: string;
        name: string;
        code: Maybe<string>;
      }>;
      shippingAddress: Maybe<{ __typename?: "Address" } & AddressInfoFragment>;
      invoiceAddress: Maybe<{ __typename?: "Address" } & AddressInfoFragment>;
      assignee: Maybe<{
        __typename?: "User";
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        countryCode: Maybe<string>;
        phoneNumber: Maybe<string>;
        avatarUrl: Maybe<string>;
        roles: Array<UserRoleEnum>;
        status: UserStatusEnum;
        createdDate: any;
        updatedDate: any;
      }>;
      attachments: Maybe<
        Array<{
          __typename?: "Attachment";
          id: string;
          filename: string;
          url: Maybe<string>;
          size: number;
        }>
      >;
      cardStatus: {
        __typename?: "CardStatus";
        level: CardStatusLevelEnum;
        text: string;
      };
      creator: {
        __typename?: "User";
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        countryCode: Maybe<string>;
        phoneNumber: Maybe<string>;
        avatarUrl: Maybe<string>;
        roles: Array<UserRoleEnum>;
        status: UserStatusEnum;
        createdDate: any;
        updatedDate: any;
      };
      items: Array<{
        __typename?: "PurchaseRequestItem";
        id: string;
        purchaseRequestId: string;
        rfxId: Maybe<string>;
        orderId: Maybe<string>;
        name: string;
        code: Maybe<string>;
        suggestedSupplier: Maybe<string>;
        supplierCode: Maybe<string>;
        type: PurchaseRequestItemTypeEnum;
        quantity: Maybe<number>;
        receivedQuantity: Maybe<number>;
        totalReceivedQuantity: Maybe<number>;
        unit: Maybe<string>;
        expectedDeliveryDate: Maybe<any>;
        additionalInfo: Maybe<string>;
        productUrl: Maybe<string>;
        unitPrice: Maybe<number>;
        currency: Maybe<CurrencyEnum>;
        isDeleted: boolean;
        createdDate: any;
        updatedDate: any;
        customFields: Maybe<{ [key: string]: any }>;
        esourcingFields: Maybe<{ [key: string]: any }>;
        purchaseRequestCode: string;
        parentCode: string;
        swimlaneProgress: PurchaseRequestItemProgressTypeEnum;
        assignee: Maybe<{
          __typename?: "User";
          id: string;
          email: string;
          firstName: string;
          lastName: string;
          countryCode: Maybe<string>;
          phoneNumber: Maybe<string>;
          avatarUrl: Maybe<string>;
          roles: Array<UserRoleEnum>;
          status: UserStatusEnum;
          createdDate: any;
          updatedDate: any;
        }>;
        status: {
          __typename?: "CardStatus";
          level: CardStatusLevelEnum;
          text: string;
        };
        attachments: Maybe<
          Array<{
            __typename?: "Attachment";
            id: string;
            filename: string;
            url: Maybe<string>;
            size: number;
          }>
        >;
        requestor: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
        history: Maybe<
          Array<{
            __typename?: "PurchaseRequestItem";
            parentCode: string;
            progress: PurchaseRequestItemProgressTypeEnum;
            isDeleted: boolean;
            updatedDate: any;
          }>
        >;
      }>;
      projects: Array<{
        __typename?: "Project";
        id: string;
        organizationId: string;
        costCentreId: Maybe<string>;
        creatorId: string;
        name: string;
        createdDate: any;
        updatedDate: any;
        costCentre: Maybe<{
          __typename?: "CostCentre";
          id: string;
          organizationId: string;
          creatorId: string;
          name: string;
          createdDate: any;
          updatedDate: any;
        }>;
        creator: {
          __typename?: "User";
          id: string;
          email: string;
          firstName: string;
          lastName: string;
          countryCode: Maybe<string>;
          phoneNumber: Maybe<string>;
          avatarUrl: Maybe<string>;
          roles: Array<UserRoleEnum>;
          status: UserStatusEnum;
          createdDate: any;
          updatedDate: any;
        };
      }>;
      requestors: Array<{
        __typename?: "User";
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        countryCode: Maybe<string>;
        phoneNumber: Maybe<string>;
        avatarUrl: Maybe<string>;
        roles: Array<UserRoleEnum>;
        status: UserStatusEnum;
        createdDate: any;
        updatedDate: any;
      }>;
      supplier: Maybe<{
        __typename?: "Supplier";
        id: string;
        organizationId: string;
        name: Maybe<string>;
        paymentTerms: Maybe<string>;
        webAddress: Maybe<string>;
        address: Maybe<string>;
        city: Maybe<string>;
        areaCode: Maybe<string>;
        countryCode: Maybe<CountryCodeEnum>;
        createdDate: any;
        updatedDate: any;
        defaultContactPerson: {
          __typename?: "ContactPerson";
          id: string;
          email: string;
        };
        contactPersons: Array<{
          __typename?: "ContactPerson";
          id: string;
          organizationId: string;
          firstName: Maybe<string>;
          lastName: Maybe<string>;
          phoneNumber: Maybe<string>;
          email: string;
          notes: Maybe<string>;
          createdDate: any;
          updatedDate: any;
        }>;
      }>;
      activities: Array<
        { __typename?: "ActivityFeed" } & ActivityFeedItemFragment
      >;
      rfx: Maybe<{
        __typename?: "Rfx";
        id: string;
        name: string;
        code: string;
        department: Maybe<{
          __typename?: "Department";
          id: string;
          name: string;
          code: Maybe<string>;
        }>;
        suppliers: Array<{
          __typename?: "Supplier";
          id: string;
          name: Maybe<string>;
          createdDate: any;
          updatedDate: any;
          defaultContactPerson: {
            __typename?: "ContactPerson";
            id: string;
            email: string;
          };
        }>;
        emails: Array<
          | {
              __typename: "InboundEmail";
              id: string;
              parentId: Maybe<string>;
              relativeId: string;
              relativeType: ActivityRelativeTypeEnum;
              activityId: Maybe<string>;
              supplierId: Maybe<string>;
              supplierName: Maybe<string>;
              requesterId: Maybe<string>;
              requesterName: Maybe<string>;
              fromEmail: Maybe<string>;
              ccEmail: Maybe<string>;
              toEmail: Maybe<string>;
              subject: Maybe<string>;
              textBody: Maybe<string>;
              htmlBody: Maybe<string>;
              hasAttachments: Maybe<boolean>;
              spfResult: Maybe<SpfResultEnum>;
              dkimValid: Maybe<boolean>;
              securityData: Maybe<{ [key: string]: any }>;
              hasBeenRead: boolean;
              createdDate: any;
              inboundData: Maybe<string>;
            }
          | {
              __typename: "OutboundEmail";
              id: string;
              parentId: Maybe<string>;
              relativeId: string;
              relativeType: ActivityRelativeTypeEnum;
              activityId: Maybe<string>;
              supplierId: Maybe<string>;
              requesterId: Maybe<string>;
              creatorId: Maybe<string>;
              fromEmail: Maybe<string>;
              ccEmail: Maybe<string>;
              toEmail: Maybe<string>;
              subject: Maybe<string>;
              textBody: Maybe<string>;
              htmlBody: Maybe<string>;
              sendStatus: Maybe<EmailStatusEnum>;
              sendRejectedReason: Maybe<string>;
              hasAttachments: Maybe<boolean>;
              createdDate: any;
              outboundData: Maybe<string>;
            }
        >;
      }>;
    };
    organization: {
      __typename?: "Organization";
      id: string;
      users: Array<{ __typename?: "User"; id: string } & UserInfoFragment>;
      suppliers: Array<{
        __typename?: "Supplier";
        id: string;
        name: Maybe<string>;
        paymentTerms: Maybe<string>;
        defaultContactPerson: {
          __typename?: "ContactPerson";
          id: string;
          email: string;
        };
      }>;
      addresses: Array<{
        __typename?: "Address";
        id: string;
        locationName: Maybe<string>;
        recipientName: Maybe<string>;
        address: string;
        city: string;
        areaCode: string;
        countryCode: CountryCodeEnum;
        addressType: AddressTypeEnum;
        createdDate: any;
      }>;
    };
    countries: Array<{ __typename?: "Country"; code: string; name: string }>;
  }>;
};

export type OrderModalInfoQueryVariables = {
  organizationId: Scalars["String"];
  filter: Maybe<KanbanFilterInput>;
};

export type OrderModalInfoQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      orders: Array<{
        __typename?: "Order";
        id: string;
        name: string;
        code: string;
        type: OrderTypeEnum;
      }>;
    };
  }>;
};

export type UpdateOrderMutationVariables = {
  orderId: Scalars["String"];
  supplierId: Maybe<Scalars["String"]>;
  shippingAddressId: Maybe<Scalars["String"]>;
  invoiceAddressId: Maybe<Scalars["String"]>;
  externalIdentifier: Maybe<Scalars["String"]>;
  hasBeenSent: Scalars["Boolean"];
  name: Maybe<Scalars["String"]>;
  isConfirmationRequired: Scalars["Boolean"];
  expectedConfirmationDate: Maybe<Scalars["Date"]>;
  expectedDeliveryDate: Scalars["Date"];
  purchaseOrderRenewalDate: Maybe<Scalars["Date"]>;
  purchaseOrderIssueDate: Maybe<Scalars["Date"]>;
  purchaseOrderCurrency: Maybe<CurrencyEnum>;
  paymentTerm: Maybe<Scalars["String"]>;
  purchaseOrderDeliveryNotes: Maybe<Scalars["String"]>;
  purchaseOrderBillingNotes: Maybe<Scalars["String"]>;
  purchaseOrderSupplierNotes: Maybe<Scalars["String"]>;
  receivingIsConfirmationReceived: Maybe<Scalars["Boolean"]>;
  receivingGoodsReceived: Maybe<Scalars["Boolean"]>;
  receivingGoodsReceivedPartially: Maybe<Scalars["Boolean"]>;
  receivingIsAddedToStock: Maybe<Scalars["Boolean"]>;
  receivingIsPoCancelled: Maybe<Scalars["Boolean"]>;
  receivingWarehouseCode: Maybe<Scalars["String"]>;
  receivingNotes: Maybe<Scalars["String"]>;
  invoiceNumber: Maybe<Scalars["String"]>;
  vatPercent: Maybe<Scalars["Float"]>;
  invoiceReceived: Maybe<Scalars["Boolean"]>;
  invoiceThreeWayMatch: Maybe<Scalars["Boolean"]>;
  invoiceApproved: Maybe<Scalars["Boolean"]>;
  invoicePaid: Maybe<Scalars["Boolean"]>;
};

export type UpdateOrderMutation = {
  __typename?: "Mutation";
  updateOrder: { __typename?: "Order"; id: string };
};

export type UpdateOrderHasBeenSentMutationVariables = {
  orderId: Scalars["String"];
  hasBeenSent: Scalars["Boolean"];
};

export type UpdateOrderHasBeenSentMutation = {
  __typename?: "Mutation";
  updateOrderHasBeenSent: {
    __typename?: "Order";
    id: string;
    hasBeenSent: boolean;
  };
};

export type UpdateOrderReleaseMethodMutationVariables = {
  orderId: Scalars["String"];
  poReleaseMethod: PurchaseOrderReleaseMethodEnum;
};

export type UpdateOrderReleaseMethodMutation = {
  __typename?: "Mutation";
  updateOrderReleaseMethod: {
    __typename?: "Order";
    id: string;
    poReleaseMethod: Maybe<PurchaseOrderReleaseMethodEnum>;
  };
};

export type UpdateOrderStatusMutationVariables = {
  orderId: Scalars["String"];
  status: OrderStatusEnum;
};

export type UpdateOrderStatusMutation = {
  __typename?: "Mutation";
  updateOrderStatus: {
    __typename?: "Order";
    id: string;
    status: Maybe<OrderStatusEnum>;
  };
};

export type UpdatePurchaseRequestItemReceivedQuantitesMutationVariables = {
  organizationId: Scalars["String"];
  orderId: Scalars["String"];
  areReceivingGoodsReceived: Scalars["Boolean"];
  purchaseRequestReceivedItems: Maybe<Array<PurchaseRequestItemReceivedInput>>;
};

export type UpdatePurchaseRequestItemReceivedQuantitesMutation = {
  __typename?: "Mutation";
  updatePurchaseRequestItemReceivedQuantities: boolean;
};

export type AddRolesToUserMutationVariables = {
  userId: Scalars["String"];
  roles: Array<UserRoleEnum>;
};

export type AddRolesToUserMutation = {
  __typename?: "Mutation";
  addRolesToUser: { __typename?: "User"; id: string };
};

export type ArchivePurchaseRequestMutationVariables = {
  purchaseRequestId: Scalars["String"];
};

export type ArchivePurchaseRequestMutation = {
  __typename?: "Mutation";
  archivePurchaseRequest: { __typename?: "PurchaseRequest"; id: string };
};

export type CompletePurchaseRequestMutationVariables = {
  purchaseRequestId: Scalars["String"];
};

export type CompletePurchaseRequestMutation = {
  __typename?: "Mutation";
  completePurchaseRequest: { __typename?: "PurchaseRequest"; id: string };
};

export type CreateApprovalRequestMutationVariables = {
  approvalWorkflowId: Scalars["String"];
  organizationId: Scalars["String"];
  approverId: Scalars["String"];
  comment: Maybe<Scalars["String"]>;
  sequenceNumber: Scalars["Int"];
  dueDate: Maybe<Scalars["Date"]>;
};

export type CreateApprovalRequestMutation = {
  __typename?: "Mutation";
  createApprovalRequest: { __typename?: "ApprovalRequest"; id: string };
};

export type CreateApprovalWorkflowMutationVariables = {
  purchaseRequestId: Scalars["String"];
};

export type CreateApprovalWorkflowMutation = {
  __typename?: "Mutation";
  createApprovalWorkflow: { __typename?: "ApprovalWorkflow"; id: string };
};

export type CreateApproverMutationVariables = {
  organizationId: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  email: Scalars["String"];
};

export type CreateApproverMutation = {
  __typename?: "Mutation";
  createApprover: { __typename?: "User" } & UserInfoFragment;
};

export type CreateCostCentreMutationVariables = {
  organizationId: Scalars["String"];
  name: Scalars["String"];
};

export type CreateCostCentreMutation = {
  __typename?: "Mutation";
  createCostCentre: { __typename?: "CostCentre"; id: string; name: string };
};

export type CreateDepartmentMutationVariables = {
  organizationId: Scalars["String"];
  shippingAddressId: Maybe<Scalars["String"]>;
  invoiceAddressId: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  code: Maybe<Scalars["String"]>;
  domain: Maybe<Scalars["String"]>;
};

export type CreateDepartmentMutation = {
  __typename?: "Mutation";
  createDepartment: {
    __typename?: "Department";
    id: string;
    name: string;
    code: Maybe<string>;
    domain: Maybe<string>;
  };
};

export type CreateProjectMutationVariables = {
  organizationId: Scalars["String"];
  name: Scalars["String"];
};

export type CreateProjectMutation = {
  __typename?: "Mutation";
  createProject: { __typename?: "Project"; id: string; name: string };
};

export type CreatePurchaseRequestAttachmentsMutationVariables = {
  organizationId: Scalars["String"];
  purchaseRequestId: Scalars["String"];
  attachments: Array<Scalars["Upload"]>;
};

export type CreatePurchaseRequestAttachmentsMutation = {
  __typename?: "Mutation";
  createPurchaseRequestAttachments: Array<{
    __typename?: "Attachment";
    id: string;
  }>;
};

export type CreatePurchaseRequestItemAttachmentsMutationVariables = {
  organizationId: Scalars["String"];
  purchaseRequestItemId: Scalars["String"];
  attachments: Array<Scalars["Upload"]>;
};

export type CreatePurchaseRequestItemAttachmentsMutation = {
  __typename?: "Mutation";
  createPurchaseRequestItemAttachments: Array<{
    __typename?: "Attachment";
    id: string;
  }>;
};

export type CreateRequestorMutationVariables = {
  organizationId: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  email: Scalars["String"];
};

export type CreateRequestorMutation = {
  __typename?: "Mutation";
  createRequestor: { __typename?: "User" } & UserInfoFragment;
};

export type DeleteApprovalRequestMutationVariables = {
  approvalRequestId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type DeleteApprovalRequestMutation = {
  __typename?: "Mutation";
  deleteApprovalRequest: { __typename?: "ApprovalRequest"; id: string };
};

export type DeletePurchaseRequestItemMutationVariables = {
  purchaseRequestItemId: Scalars["String"];
};

export type DeletePurchaseRequestItemMutation = {
  __typename?: "Mutation";
  deletePurchaseRequestItem: { __typename?: "PurchaseRequestItem"; id: string };
};

export type PurchaseRequestByCodeQueryVariables = {
  organizationId: Scalars["String"];
  code: Scalars["String"];
};

export type PurchaseRequestByCodeQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    purchaseRequestByCode: {
      __typename?: "PurchaseRequest";
      id: string;
      organizationId: string;
      departmentId: Maybe<string>;
      projectId: Maybe<string>;
      costCentreId: Maybe<string>;
      creatorId: string;
      requestorId: string;
      assigneeId: Maybe<string>;
      name: string;
      notes: string;
      code: string;
      status: PurchaseRequestStatusEnum;
      vat: number;
      isVatIncluded: boolean;
      isRfxCreated: boolean;
      createdDate: any;
      updatedDate: any;
      department: Maybe<{ __typename?: "Department" } & DepartmentInfoFragment>;
      project: Maybe<{ __typename?: "Project" } & ProjectInfoFragment>;
      costCentre: Maybe<{
        __typename?: "CostCentre";
        id: string;
        name: string;
      }>;
      requestor: { __typename?: "User" } & UserInfoFragment;
      assignee: Maybe<{ __typename?: "User" } & UserInfoFragment>;
      attachments: Maybe<
        Array<{
          __typename?: "Attachment";
          id: string;
          creatorId: string;
          url: Maybe<string>;
          filename: string;
          mimetype: string;
          encoding: string;
          size: number;
          notes: string;
          createdDate: any;
          updatedDate: any;
          creator: {
            __typename?: "User";
            id: string;
            email: string;
            firstName: string;
            lastName: string;
            countryCode: Maybe<string>;
            phoneNumber: Maybe<string>;
            avatarUrl: Maybe<string>;
            roles: Array<UserRoleEnum>;
            status: UserStatusEnum;
            createdDate: any;
            updatedDate: any;
          };
        }>
      >;
      cardStatus: {
        __typename?: "CardStatus";
        level: CardStatusLevelEnum;
        text: string;
      };
      items: Array<{
        __typename?: "PurchaseRequestItem";
        id: string;
        purchaseRequestId: string;
        name: string;
        type: PurchaseRequestItemTypeEnum;
        quantity: Maybe<number>;
        receivedQuantity: Maybe<number>;
        totalReceivedQuantity: Maybe<number>;
        unit: Maybe<string>;
        expectedDeliveryDate: Maybe<any>;
        productUrl: Maybe<string>;
        additionalInfo: Maybe<string>;
        unitPrice: Maybe<number>;
        currency: Maybe<CurrencyEnum>;
        createdDate: any;
        updatedDate: any;
        isDeleted: boolean;
        orderId: Maybe<string>;
        organizationId: string;
        originId: Maybe<string>;
        progress: PurchaseRequestItemProgressTypeEnum;
        rfxId: Maybe<string>;
        code: Maybe<string>;
        suggestedSupplier: Maybe<string>;
        supplierCode: Maybe<string>;
        customFields: Maybe<{ [key: string]: any }>;
        esourcingFields: Maybe<{ [key: string]: any }>;
        purchaseRequestCode: string;
        parentCode: string;
        swimlaneProgress: PurchaseRequestItemProgressTypeEnum;
        externalIdentifier: Maybe<string>;
        assignee: Maybe<{
          __typename?: "User";
          id: string;
          email: string;
          firstName: string;
          lastName: string;
          countryCode: Maybe<string>;
          phoneNumber: Maybe<string>;
          avatarUrl: Maybe<string>;
          roles: Array<UserRoleEnum>;
          status: UserStatusEnum;
          createdDate: any;
          updatedDate: any;
        }>;
        status: {
          __typename?: "CardStatus";
          level: CardStatusLevelEnum;
          text: string;
        };
        attachments: Maybe<
          Array<{
            __typename?: "Attachment";
            id: string;
            filename: string;
            url: Maybe<string>;
          }>
        >;
        requestor: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
        history: Maybe<
          Array<{
            __typename?: "PurchaseRequestItem";
            parentCode: string;
            progress: PurchaseRequestItemProgressTypeEnum;
            isDeleted: boolean;
            updatedDate: any;
          }>
        >;
      }>;
      activities: Maybe<
        Array<{ __typename?: "ActivityFeed" } & ActivityFeedItemFragment>
      >;
      latestApprovalWorkflow: Maybe<{
        __typename?: "ApprovalWorkflow";
        id: string;
        code: string;
        requestName: Maybe<string>;
        approvalAmount: Maybe<number>;
        comment: Maybe<string>;
        status: ApprovalWorkflowStatusEnum;
        keepRequesterUpdated: boolean;
        singleApprovalRequired: boolean;
        approvalRequests: Array<{
          __typename?: "ApprovalRequest";
          id: string;
          creatorComment: Maybe<string>;
          approverComment: Maybe<string>;
          decision: Maybe<ApprovalRequestDecisionEnum>;
          decisionDate: Maybe<any>;
          dueDate: Maybe<any>;
          sequenceNumber: number;
          sequenceGroup: number;
          createdDate: any;
          updatedDate: any;
          approver: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
        }>;
      }>;
      emails: Array<
        | {
            __typename: "InboundEmail";
            id: string;
            parentId: Maybe<string>;
            relativeId: string;
            relativeType: ActivityRelativeTypeEnum;
            activityId: Maybe<string>;
            supplierId: Maybe<string>;
            supplierName: Maybe<string>;
            requesterId: Maybe<string>;
            requesterName: Maybe<string>;
            fromEmail: Maybe<string>;
            ccEmail: Maybe<string>;
            toEmail: Maybe<string>;
            subject: Maybe<string>;
            textBody: Maybe<string>;
            htmlBody: Maybe<string>;
            hasAttachments: Maybe<boolean>;
            hasBeenRead: boolean;
            spfResult: Maybe<SpfResultEnum>;
            dkimValid: Maybe<boolean>;
            securityData: Maybe<{ [key: string]: any }>;
            createdDate: any;
            inboundData: Maybe<string>;
          }
        | {
            __typename: "OutboundEmail";
            id: string;
            parentId: Maybe<string>;
            relativeId: string;
            relativeType: ActivityRelativeTypeEnum;
            activityId: Maybe<string>;
            supplierId: Maybe<string>;
            requesterId: Maybe<string>;
            creatorId: Maybe<string>;
            fromEmail: Maybe<string>;
            ccEmail: Maybe<string>;
            toEmail: Maybe<string>;
            subject: Maybe<string>;
            textBody: Maybe<string>;
            htmlBody: Maybe<string>;
            sendStatus: Maybe<EmailStatusEnum>;
            sendRejectedReason: Maybe<string>;
            hasAttachments: Maybe<boolean>;
            createdDate: any;
            outboundData: Maybe<string>;
          }
      >;
    };
    organization: {
      __typename?: "Organization";
      id: string;
      baseCurrency: CurrencyEnum;
      urlName: string;
      users: Array<{ __typename?: "User"; id: string } & UserInfoFragment>;
      suppliers: Array<{
        __typename?: "Supplier";
        id: string;
        name: Maybe<string>;
        defaultContactPerson: {
          __typename?: "ContactPerson";
          id: string;
          email: string;
        };
      }>;
      departments: Array<{
        __typename?: "Department";
        id: string;
        name: string;
        code: Maybe<string>;
      }>;
      costCentres: Array<{
        __typename?: "CostCentre";
        id: string;
        name: string;
      }>;
      projects: Array<{ __typename?: "Project"; id: string; name: string }>;
      quantityUnits: Array<{
        __typename?: "QuantityUnit";
        id: string;
        name: string;
      }>;
      addressBook: Array<{
        __typename?: "AddressBook";
        id: string;
        email: string;
      }>;
    };
  }>;
};

export type RemovePurchaseRequestAttachmentMutationVariables = {
  attachmentId: Scalars["String"];
  purchaseRequestId: Scalars["String"];
};

export type RemovePurchaseRequestAttachmentMutation = {
  __typename?: "Mutation";
  removePurchaseRequestAttachment: {
    __typename?: "PurchaseRequest";
    id: string;
  };
};

export type UpdateApprovalWorkflowDataMutationVariables = {
  approvalWorkflowId: Scalars["String"];
  requestName: Maybe<Scalars["String"]>;
  comment: Maybe<Scalars["String"]>;
  keepRequesterUpdated: Scalars["Boolean"];
  singleApprovalRequired: Scalars["Boolean"];
  approvalAmount: Maybe<Scalars["Float"]>;
};

export type UpdateApprovalWorkflowDataMutation = {
  __typename?: "Mutation";
  updateApprovalWorkflowData: { __typename?: "ApprovalWorkflow"; id: string };
};

export type UpdateApprovalWorkflowStatusMutationVariables = {
  approvalWorkflowId: Scalars["String"];
  status: ApprovalWorkflowStatusEnum;
  cancellingComment: Maybe<Scalars["String"]>;
};

export type UpdateApprovalWorkflowStatusMutation = {
  __typename?: "Mutation";
  updateApprovalWorkflowStatus: { __typename?: "ApprovalWorkflow"; id: string };
};

export type UpdatePurchaseRequestMutationVariables = {
  purchaseRequestId: Scalars["String"];
  departmentId: Maybe<Scalars["String"]>;
  projectId: Maybe<Scalars["String"]>;
  costCentreId: Maybe<Scalars["String"]>;
  requestorId: Scalars["String"];
  assigneeId: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  notes: Maybe<Scalars["String"]>;
  status: PurchaseRequestStatusEnum;
};

export type UpdatePurchaseRequestMutation = {
  __typename?: "Mutation";
  updatePurchaseRequest: { __typename?: "PurchaseRequest"; id: string };
};

export type UpdatePurchaseRequestStatusMutationVariables = {
  purchaseRequestId: Scalars["String"];
  status: PurchaseRequestStatusEnum;
};

export type UpdatePurchaseRequestStatusMutation = {
  __typename?: "Mutation";
  updatePurchaseRequestStatus: {
    __typename?: "PurchaseRequest";
    id: string;
    status: PurchaseRequestStatusEnum;
  };
};

export type CreatePurchaseRequestFromPortalMutationVariables = {
  organizationId: Scalars["String"];
  status: PurchaseRequestStatusEnum;
  name: Scalars["String"];
  requestorId: Maybe<Scalars["String"]>;
  departmentId: Maybe<Scalars["String"]>;
  projectId: Maybe<Scalars["String"]>;
  costCentreId: Maybe<Scalars["String"]>;
  notes: Maybe<Scalars["String"]>;
  attachments: Maybe<Array<Scalars["Upload"]>>;
  items: Maybe<Array<PurchaseRequestItemInput>>;
};

export type CreatePurchaseRequestFromPortalMutation = {
  __typename?: "Mutation";
  createPurchaseRequestFromPortal: {
    __typename?: "PurchaseRequest";
    id: string;
    code: string;
    name: string;
    assigneeId: Maybe<string>;
  };
};

export type PurchaseRequestsDetailsByCodeQueryVariables = {
  organizationId: Scalars["String"];
  code: Scalars["String"];
};

export type PurchaseRequestsDetailsByCodeQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    purchaseRequestByCode: {
      __typename?: "PurchaseRequest";
      id: string;
      organizationId: string;
      departmentId: Maybe<string>;
      projectId: Maybe<string>;
      costCentreId: Maybe<string>;
      creatorId: string;
      requestorId: string;
      assigneeId: Maybe<string>;
      name: string;
      notes: string;
      code: string;
      status: PurchaseRequestStatusEnum;
      vat: number;
      isVatIncluded: boolean;
      isRfxCreated: boolean;
      createdDate: any;
      updatedDate: any;
      costCentre: Maybe<{
        __typename?: "CostCentre";
        id: string;
        name: string;
      }>;
      requestor: { __typename?: "User" } & UserInfoFragment;
      assignee: Maybe<{ __typename?: "User" } & UserInfoFragment>;
      attachments: Maybe<
        Array<{
          __typename?: "Attachment";
          id: string;
          url: Maybe<string>;
          filename: string;
        }>
      >;
      department: Maybe<{ __typename?: "Department" } & DepartmentInfoFragment>;
      project: Maybe<{ __typename?: "Project" } & ProjectInfoFragment>;
      cardStatus: {
        __typename?: "CardStatus";
        level: CardStatusLevelEnum;
        text: string;
      };
      items: Array<{
        __typename?: "PurchaseRequestItem";
        id: string;
        purchaseRequestId: string;
        name: string;
        type: PurchaseRequestItemTypeEnum;
        quantity: Maybe<number>;
        receivedQuantity: Maybe<number>;
        totalReceivedQuantity: Maybe<number>;
        unit: Maybe<string>;
        expectedDeliveryDate: Maybe<any>;
        productUrl: Maybe<string>;
        parentCode: string;
        isDeleted: boolean;
        orderId: Maybe<string>;
        organizationId: string;
        originId: Maybe<string>;
        additionalInfo: Maybe<string>;
        progress: PurchaseRequestItemProgressTypeEnum;
        rfxId: Maybe<string>;
        unitPrice: Maybe<number>;
        currency: Maybe<CurrencyEnum>;
        createdDate: any;
        updatedDate: any;
        code: Maybe<string>;
        suggestedSupplier: Maybe<string>;
        supplierCode: Maybe<string>;
        customFields: Maybe<{ [key: string]: any }>;
        esourcingFields: Maybe<{ [key: string]: any }>;
        purchaseRequestCode: string;
        swimlaneProgress: PurchaseRequestItemProgressTypeEnum;
        externalIdentifier: Maybe<string>;
        assignee: Maybe<{
          __typename?: "User";
          id: string;
          email: string;
          firstName: string;
          lastName: string;
          countryCode: Maybe<string>;
          phoneNumber: Maybe<string>;
          avatarUrl: Maybe<string>;
          roles: Array<UserRoleEnum>;
          status: UserStatusEnum;
          createdDate: any;
          updatedDate: any;
        }>;
        status: {
          __typename?: "CardStatus";
          level: CardStatusLevelEnum;
          text: string;
        };
        attachments: Maybe<
          Array<{
            __typename?: "Attachment";
            id: string;
            filename: string;
            url: Maybe<string>;
          }>
        >;
        requestor: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
        history: Maybe<
          Array<{
            __typename?: "PurchaseRequestItem";
            parentCode: string;
            progress: PurchaseRequestItemProgressTypeEnum;
            isDeleted: boolean;
            updatedDate: any;
          }>
        >;
      }>;
      activities: Maybe<
        Array<{ __typename?: "ActivityFeed" } & ActivityFeedItemFragment>
      >;
      latestApprovalWorkflow: Maybe<{
        __typename?: "ApprovalWorkflow";
        id: string;
        code: string;
        status: ApprovalWorkflowStatusEnum;
        comment: Maybe<string>;
        requestName: Maybe<string>;
        approvalRequests: Array<{
          __typename?: "ApprovalRequest";
          id: string;
          creatorComment: Maybe<string>;
          approverComment: Maybe<string>;
          decision: Maybe<ApprovalRequestDecisionEnum>;
          decisionDate: Maybe<any>;
          dueDate: Maybe<any>;
          sequenceNumber: number;
          sequenceGroup: number;
          createdDate: any;
          updatedDate: any;
          approver: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
        }>;
      }>;
      emails: Array<
        | {
            __typename?: "InboundEmail";
            id: string;
            parentId: Maybe<string>;
            relativeId: string;
            relativeType: ActivityRelativeTypeEnum;
            activityId: Maybe<string>;
            supplierId: Maybe<string>;
            supplierName: Maybe<string>;
            requesterId: Maybe<string>;
            requesterName: Maybe<string>;
            fromEmail: Maybe<string>;
            toEmail: Maybe<string>;
            ccEmail: Maybe<string>;
            subject: Maybe<string>;
            textBody: Maybe<string>;
            htmlBody: Maybe<string>;
            hasAttachments: Maybe<boolean>;
            hasBeenRead: boolean;
            spfResult: Maybe<SpfResultEnum>;
            dkimValid: Maybe<boolean>;
            securityData: Maybe<{ [key: string]: any }>;
            createdDate: any;
            inboundData: Maybe<string>;
          }
        | {
            __typename?: "OutboundEmail";
            id: string;
            parentId: Maybe<string>;
            relativeId: string;
            relativeType: ActivityRelativeTypeEnum;
            activityId: Maybe<string>;
            supplierId: Maybe<string>;
            requesterId: Maybe<string>;
            creatorId: Maybe<string>;
            fromEmail: Maybe<string>;
            ccEmail: Maybe<string>;
            toEmail: Maybe<string>;
            subject: Maybe<string>;
            textBody: Maybe<string>;
            htmlBody: Maybe<string>;
            sendStatus: Maybe<EmailStatusEnum>;
            sendRejectedReason: Maybe<string>;
            hasAttachments: Maybe<boolean>;
            createdDate: any;
            outboundData: Maybe<string>;
          }
      >;
    };
    organization: {
      __typename?: "Organization";
      id: string;
      baseCurrency: CurrencyEnum;
      users: Array<{ __typename?: "User"; id: string } & UserInfoFragment>;
      suppliers: Array<{
        __typename?: "Supplier";
        id: string;
        name: Maybe<string>;
        defaultContactPerson: {
          __typename?: "ContactPerson";
          id: string;
          email: string;
        };
      }>;
      departments: Array<{
        __typename?: "Department";
        id: string;
        name: string;
        code: Maybe<string>;
      }>;
      costCentres: Array<{
        __typename?: "CostCentre";
        id: string;
        name: string;
      }>;
      projects: Array<{ __typename?: "Project"; id: string; name: string }>;
    };
  }>;
};

export type RequesterPortalViewQueryVariables = {
  approvalWorkflowStatuses: Array<ApprovalWorkflowStatusEnum>;
  organizationId: Scalars["String"];
  filter: Maybe<KanbanFilterInput>;
};

export type RequesterPortalViewQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    avatarUrl: Maybe<string>;
    roles: Array<UserRoleEnum>;
    department: Maybe<{
      __typename?: "Department";
      id: string;
      name: string;
      code: Maybe<string>;
    }>;
    project: Maybe<{ __typename?: "Project"; id: string; name: string }>;
    costCentre: Maybe<{ __typename?: "CostCentre"; id: string; name: string }>;
    approvalWorkflowsByStatus: Array<{
      __typename?: "ApprovalWorkflow";
      id: string;
      code: string;
      status: ApprovalWorkflowStatusEnum;
    }>;
    organization: {
      __typename?: "Organization";
      id: string;
      baseCurrency: CurrencyEnum;
      urlName: string;
      companyName: string;
      address: string;
      city: string;
      areaCode: string;
      companyLogoUrl: Maybe<string>;
      prEmailAddress: string;
      isActive: boolean;
      domainWhitelist: Maybe<Array<string>>;
      modules: Maybe<{ [key: string]: any }>;
      vatIdentification: Maybe<string>;
      businessRegistry: Maybe<string>;
      leiCode: Maybe<string>;
      dunsCode: Maybe<string>;
      embeddedReport: Maybe<string>;
      poStatusWebhookUrl: Maybe<string>;
      country: { __typename?: "Country"; name: string; code: string };
      users: Array<{ __typename?: "User"; id: string } & UserInfoFragment>;
      suppliers: Array<{
        __typename?: "Supplier";
        id: string;
        name: Maybe<string>;
        defaultContactPerson: {
          __typename?: "ContactPerson";
          id: string;
          email: string;
        };
      }>;
      departments: Array<{
        __typename?: "Department";
        id: string;
        name: string;
        code: Maybe<string>;
      }>;
      costCentres: Array<{
        __typename?: "CostCentre";
        id: string;
        name: string;
      }>;
      projects: Array<{ __typename?: "Project"; id: string; name: string }>;
      quantityUnits: Array<{
        __typename?: "QuantityUnit";
        id: string;
        name: string;
      }>;
      purchaseRequests: Array<{
        __typename?: "PurchaseRequest";
        id: string;
        code: string;
        status: PurchaseRequestStatusEnum;
        name: string;
        isRfxCreated: boolean;
        isOrderCreated: boolean;
        createdDate: any;
        cardStatus: {
          __typename?: "CardStatus";
          level: CardStatusLevelEnum;
          text: string;
        };
        requestor: { __typename?: "User" } & UserInfoFragment;
        assignee: Maybe<{ __typename?: "User" } & UserInfoFragment>;
        project: Maybe<{ __typename?: "Project" } & ProjectInfoFragment>;
        suppliers: Array<{ __typename?: "Supplier" } & SupplierInfoFragment>;
        department: Maybe<
          { __typename?: "Department" } & DepartmentInfoFragment
        >;
        items: Array<
          {
            __typename?: "PurchaseRequestItem";
            status: {
              __typename?: "CardStatus";
              level: CardStatusLevelEnum;
              text: string;
            };
          } & PurchaseRequestItemInfoFragment
        >;
        latestApprovalWorkflow: Maybe<{
          __typename?: "ApprovalWorkflow";
          id: string;
          code: string;
          status: ApprovalWorkflowStatusEnum;
        }>;
      }>;
    };
  }>;
};

export type UpdatePurchaseRequestFromPortalMutationVariables = {
  status: PurchaseRequestStatusEnum;
  purchaseRequestId: Scalars["String"];
  name: Scalars["String"];
  requestorId: Maybe<Scalars["String"]>;
  departmentId: Maybe<Scalars["String"]>;
  projectId: Maybe<Scalars["String"]>;
  costCentreId: Maybe<Scalars["String"]>;
  notes: Maybe<Scalars["String"]>;
  attachments: Maybe<Array<Scalars["Upload"]>>;
  items: Maybe<Array<PurchaseRequestItemInput>>;
};

export type UpdatePurchaseRequestFromPortalMutation = {
  __typename?: "Mutation";
  updatePurchaseRequestFromPortal: {
    __typename?: "PurchaseRequest";
    id: string;
    code: string;
    name: string;
    assigneeId: Maybe<string>;
  };
};

export type AddSupplierResolutionMutationVariables = {
  rfxId: Scalars["String"];
  supplierId: Scalars["String"];
  resolution: Maybe<RfxSupplierResolutionEnum>;
};

export type AddSupplierResolutionMutation = {
  __typename?: "Mutation";
  addSupplierResolution: { __typename?: "Rfx"; id: string };
};

export type AddSupplierToRfxMutationVariables = {
  rfxId: Scalars["String"];
  supplierId: Scalars["String"];
};

export type AddSupplierToRfxMutation = {
  __typename?: "Mutation";
  addSupplierToRfx: { __typename?: "Rfx"; id: string };
};

export type ArchiveRfxMutationVariables = {
  rfxId: Scalars["String"];
};

export type ArchiveRfxMutation = {
  __typename?: "Mutation";
  archiveRfx: { __typename?: "Rfx"; id: string };
};

export type CompleteSourcingEventMutationVariables = {
  sourcingEventId: Scalars["String"];
};

export type CompleteSourcingEventMutation = {
  __typename?: "Mutation";
  completeSourcingEvent: { __typename?: "SourcingEvent"; id: string };
};

export type CreateCommentMutationVariables = {
  type: ActivityTypeEnum;
  comment: Scalars["String"];
  attachments: Maybe<Array<Scalars["Upload"]>>;
  mentions: Maybe<Array<MentionInput>>;
  organizationId: Scalars["String"];
  parentId: Maybe<Scalars["String"]>;
  relativeId: Scalars["String"];
  relativeType: ActivityRelativeTypeEnum;
};

export type CreateCommentMutation = {
  __typename?: "Mutation";
  createComment: { __typename?: "ActivityFeed"; id: string };
};

export type CreateSupplierMutationVariables = {
  organizationId: Scalars["String"];
  name: Maybe<Scalars["String"]>;
  email: Scalars["String"];
};

export type CreateSupplierMutation = {
  __typename?: "Mutation";
  createSupplier: {
    __typename?: "Supplier";
    id: string;
    name: Maybe<string>;
    defaultContactPerson: {
      __typename?: "ContactPerson";
      id: string;
      email: string;
    };
  };
};

export type MarkInboundEmailReadStatusMutationVariables = {
  inboundEmailId: Scalars["String"];
  hasBeenRead: Scalars["Boolean"];
};

export type MarkInboundEmailReadStatusMutation = {
  __typename?: "Mutation";
  markInboundEmailReadStatus: boolean;
};

export type RemovePurchaseRequestItemMutationVariables = {
  purchaseRequestItemId: Scalars["String"];
};

export type RemovePurchaseRequestItemMutation = {
  __typename?: "Mutation";
  removePurchaseRequestItem: { __typename?: "PurchaseRequestItem"; id: string };
};

export type RemoveSupplierFromRfxMutationVariables = {
  rfxId: Scalars["String"];
  supplierId: Scalars["String"];
};

export type RemoveSupplierFromRfxMutation = {
  __typename?: "Mutation";
  removeSupplierFromRfx: { __typename?: "Rfx"; id: string };
};

export type RfxByCodeQueryVariables = {
  organizationId: Scalars["String"];
  code: Scalars["String"];
  withDeleted: Maybe<Scalars["Boolean"]>;
};

export type RfxByCodeQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    rfxByCode: {
      __typename?: "Rfx";
      id: string;
      organizationId: string;
      creatorId: string;
      assigneeId: Maybe<string>;
      name: string;
      code: string;
      type: Maybe<RfxTypeEnum>;
      status: RfxStatusEnum;
      isRfiComplete: boolean;
      isRfpComplete: boolean;
      isRfqComplete: boolean;
      expectedResponseDate: Maybe<any>;
      createdDate: any;
      updatedDate: any;
      deletedDate: Maybe<any>;
      nextStep: Maybe<RfxNextStepEnum>;
      department: Maybe<{
        __typename?: "Department";
        id: string;
        name: string;
        code: Maybe<string>;
      }>;
      assignee: Maybe<{ __typename?: "User"; id: string } & UserInfoFragment>;
      attachments: Maybe<
        Array<{
          __typename?: "Attachment";
          id: string;
          filename: string;
          url: Maybe<string>;
          size: number;
        }>
      >;
      emails: Array<
        | {
            __typename: "InboundEmail";
            id: string;
            parentId: Maybe<string>;
            relativeId: string;
            relativeType: ActivityRelativeTypeEnum;
            activityId: Maybe<string>;
            supplierId: Maybe<string>;
            supplierName: Maybe<string>;
            requesterId: Maybe<string>;
            requesterName: Maybe<string>;
            fromEmail: Maybe<string>;
            toEmail: Maybe<string>;
            ccEmail: Maybe<string>;
            subject: Maybe<string>;
            textBody: Maybe<string>;
            htmlBody: Maybe<string>;
            hasAttachments: Maybe<boolean>;
            hasBeenRead: boolean;
            spfResult: Maybe<SpfResultEnum>;
            dkimValid: Maybe<boolean>;
            securityData: Maybe<{ [key: string]: any }>;
            createdDate: any;
            inboundData: Maybe<string>;
          }
        | {
            __typename: "OutboundEmail";
            id: string;
            parentId: Maybe<string>;
            relativeId: string;
            relativeType: ActivityRelativeTypeEnum;
            activityId: Maybe<string>;
            supplierId: Maybe<string>;
            requesterId: Maybe<string>;
            creatorId: Maybe<string>;
            fromEmail: Maybe<string>;
            ccEmail: Maybe<string>;
            toEmail: Maybe<string>;
            subject: Maybe<string>;
            textBody: Maybe<string>;
            htmlBody: Maybe<string>;
            sendStatus: Maybe<EmailStatusEnum>;
            sendRejectedReason: Maybe<string>;
            hasAttachments: Maybe<boolean>;
            createdDate: any;
            outboundData: Maybe<string>;
          }
      >;
      supplierResolutions: Maybe<
        Array<{
          __typename?: "RfxSupplierResolution";
          id: string;
          rfxId: string;
          supplierId: string;
          resolution: Maybe<RfxSupplierResolutionEnum>;
          deletedDate: Maybe<any>;
        }>
      >;
      items: Array<{
        __typename?: "PurchaseRequestItem";
        id: string;
        purchaseRequestId: string;
        name: string;
        code: Maybe<string>;
        suggestedSupplier: Maybe<string>;
        supplierCode: Maybe<string>;
        type: PurchaseRequestItemTypeEnum;
        quantity: Maybe<number>;
        receivedQuantity: Maybe<number>;
        totalReceivedQuantity: Maybe<number>;
        unit: Maybe<string>;
        expectedDeliveryDate: Maybe<any>;
        additionalInfo: Maybe<string>;
        productUrl: Maybe<string>;
        unitPrice: Maybe<number>;
        currency: Maybe<CurrencyEnum>;
        isDeleted: boolean;
        createdDate: any;
        updatedDate: any;
        customFields: Maybe<{ [key: string]: any }>;
        esourcingFields: Maybe<{ [key: string]: any }>;
        purchaseRequestCode: string;
        parentCode: string;
        swimlaneProgress: PurchaseRequestItemProgressTypeEnum;
        assignee: Maybe<{
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        }>;
        status: {
          __typename?: "CardStatus";
          level: CardStatusLevelEnum;
          text: string;
        };
        attachments: Maybe<
          Array<{
            __typename?: "Attachment";
            id: string;
            filename: string;
            url: Maybe<string>;
            size: number;
          }>
        >;
        requestor: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
        };
        history: Maybe<
          Array<{
            __typename?: "PurchaseRequestItem";
            parentCode: string;
            progress: PurchaseRequestItemProgressTypeEnum;
            isDeleted: boolean;
            updatedDate: any;
          }>
        >;
      }>;
      sourcingEvent: Maybe<{
        __typename?: "SourcingEvent";
        id: string;
        name: string;
        additionalInfo: Maybe<string>;
        questionnaire: { [key: string]: any };
        rfxId: string;
        status: SourcingEventStatusEnum;
        responseDeadline: any;
        sentDate: Maybe<any>;
        linkToken: string;
        creator: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
          email: string;
        };
        attachments: Array<{
          __typename?: "Attachment";
          id: string;
          filename: string;
          url: Maybe<string>;
          size: number;
        }>;
        sourcingRequests: Array<{
          __typename?: "SourcingRequest";
          id: string;
          supplierId: string;
          quotation: Maybe<{ [key: string]: any }>;
          status: SourcingRequestStatusEnum;
          comment: Maybe<string>;
          createdDate: any;
          updatedDate: any;
          supplier: Maybe<{
            __typename?: "Supplier";
            id: string;
            name: Maybe<string>;
          }>;
          attachments: Maybe<
            Array<{
              __typename?: "Attachment";
              id: string;
              filename: string;
              mimetype: string;
              url: Maybe<string>;
            }>
          >;
        }>;
      }>;
      suppliers: Array<{
        __typename?: "Supplier";
        id: string;
        name: Maybe<string>;
        createdDate: any;
        updatedDate: any;
        defaultContactPerson: {
          __typename?: "ContactPerson";
          id: string;
          email: string;
        };
      }>;
      activeSuppliers: Array<{
        __typename?: "Supplier";
        id: string;
        name: Maybe<string>;
        createdDate: any;
        updatedDate: any;
        defaultContactPerson: {
          __typename?: "ContactPerson";
          id: string;
          email: string;
        };
      }>;
      cardStatus: {
        __typename?: "CardStatus";
        level: CardStatusLevelEnum;
        text: string;
      };
      activities: Array<
        { __typename?: "ActivityFeed" } & ActivityFeedItemFragment
      >;
    };
    organization: {
      __typename?: "Organization";
      id: string;
      companyName: string;
      users: Array<{ __typename?: "User"; id: string } & UserInfoFragment>;
      suppliers: Array<{
        __typename?: "Supplier";
        id: string;
        name: Maybe<string>;
        defaultContactPerson: {
          __typename?: "ContactPerson";
          id: string;
          email: string;
        };
      }>;
    };
  }>;
};

export type RfxModalInfoQueryVariables = {
  organizationId: Scalars["String"];
  filter: Maybe<KanbanFilterInput>;
};

export type RfxModalInfoQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      rfx: Array<{
        __typename?: "Rfx";
        id: string;
        name: string;
        code: string;
        type: Maybe<RfxTypeEnum>;
      }>;
    };
  }>;
};

export type SupplierByContactPersonQueryVariables = {
  contactPersonId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type SupplierByContactPersonQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    supplierByContactPerson: {
      __typename?: "SupplierWithDefaultContact";
      id: string;
      name: Maybe<string>;
      email: Maybe<string>;
    };
  }>;
};

export type SupplierFullTextSearchMutationVariables = {
  searchInput: Scalars["String"];
  organizationId: Scalars["String"];
  rfxId: Scalars["String"];
};

export type SupplierFullTextSearchMutation = {
  __typename?: "Mutation";
  supplierFullTextSearch: Array<{
    __typename?: "SupplierFullTextSearchResult";
    id: string;
    name: string;
    email: Maybe<string>;
    status: Maybe<SupplierStatusEnum>;
    type: SupplierFullTextSearchResultTypeEnum;
    isUsed: boolean;
  }>;
};

export type SuppliersByCategoryQueryVariables = {
  categoryId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type SuppliersByCategoryQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    suppliersByCategoryId: Array<{
      __typename?: "SupplierWithDefaultContact";
      id: string;
      name: Maybe<string>;
      email: Maybe<string>;
    }>;
  }>;
};

export type UpdateRfxMutationVariables = {
  rfxId: Scalars["String"];
  name: Scalars["String"];
  type: RfxTypeEnum;
  isRfiComplete: Scalars["Boolean"];
  isRfpComplete: Scalars["Boolean"];
  isRfqComplete: Scalars["Boolean"];
};

export type UpdateRfxMutation = {
  __typename?: "Mutation";
  updateRfx: { __typename?: "Rfx"; id: string; code: string };
};

export type UpdateRfxNameMutationVariables = {
  rfxId: Scalars["String"];
  name: Scalars["String"];
};

export type UpdateRfxNameMutation = {
  __typename?: "Mutation";
  updateRfxName: { __typename?: "Rfx"; id: string; code: string };
};

export type UpdateRfxTypeMutationVariables = {
  rfxId: Scalars["String"];
  type: RfxTypeEnum;
};

export type UpdateRfxTypeMutation = {
  __typename?: "Mutation";
  updateRfxType: {
    __typename?: "Rfx";
    id: string;
    code: string;
    type: Maybe<RfxTypeEnum>;
  };
};

export type UpdateSourcingEventItemWinnersMutationVariables = {
  sourcingEventId: Scalars["String"];
  rfxId: Scalars["String"];
  supplierId: Scalars["String"];
  itemIds: Array<Scalars["String"]>;
  markRfxAsComplete: Maybe<Scalars["Boolean"]>;
};

export type UpdateSourcingEventItemWinnersMutation = {
  __typename?: "Mutation";
  updateSourcingEventItemWinners: { __typename?: "SourcingEvent"; id: string };
};

export type UpdateSupplierMutationVariables = {
  supplierId: Scalars["String"];
  name: Scalars["String"];
  webAddress: Maybe<Scalars["String"]>;
  address: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  areaCode: Maybe<Scalars["String"]>;
  countryCode: Maybe<CountryCodeEnum>;
  paymentTerms: Maybe<Scalars["String"]>;
  currency: Maybe<CurrencyEnum>;
  vatIdentification: Maybe<Scalars["String"]>;
  businessRegistry: Maybe<Scalars["String"]>;
  leiCode: Maybe<Scalars["String"]>;
  dunsCode: Maybe<Scalars["String"]>;
  erpSupplierCode: Maybe<Scalars["String"]>;
  customField1: Maybe<Scalars["String"]>;
  customField2: Maybe<Scalars["String"]>;
  customField3: Maybe<Scalars["String"]>;
  status: Maybe<SupplierStatusEnum>;
  notes: Maybe<Scalars["String"]>;
};

export type UpdateSupplierMutation = {
  __typename?: "Mutation";
  updateSupplier: { __typename?: "Supplier" } & SupplierDetailInfoFragment;
};

export type OrganizationSourcingEventsQueryVariables = {
  organizationId: Scalars["String"];
};

export type OrganizationSourcingEventsQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      sourcingEvents: Array<{
        __typename?: "SourcingEvent";
        id: string;
        name: string;
        status: SourcingEventStatusEnum;
        createdDate: any;
        sentDate: Maybe<any>;
        updatedDate: any;
        responseDeadline: any;
        creator: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
        rfx: {
          __typename?: "Rfx";
          type: Maybe<RfxTypeEnum>;
          code: string;
          status: RfxStatusEnum;
          deletedDate: Maybe<any>;
          department: Maybe<{
            __typename?: "Department";
            id: string;
            name: string;
            code: Maybe<string>;
          }>;
          supplierResolutions: Maybe<
            Array<{
              __typename?: "RfxSupplierResolution";
              id: string;
              resolution: Maybe<RfxSupplierResolutionEnum>;
              deletedDate: Maybe<any>;
              supplier: {
                __typename?: "Supplier";
                id: string;
                name: Maybe<string>;
              };
            }>
          >;
        };
        sourcingRequests: Array<{
          __typename?: "SourcingRequest";
          id: string;
          status: SourcingRequestStatusEnum;
        }>;
      }>;
    };
  }>;
};

export type CompleteSourcingRequestMutationVariables = {
  sourcingRequestId: Scalars["String"];
};

export type CompleteSourcingRequestMutation = {
  __typename?: "Mutation";
  completeSourcingRequest: { __typename?: "SourcingRequest"; id: string };
};

export type SourcingRequestByIdQueryVariables = {
  sourcingRequestId: Scalars["String"];
};

export type SourcingRequestByIdQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    sourcingRequestById: {
      __typename?: "SourcingRequest";
      id: string;
      sourcingEventId: string;
      createdDate: any;
      updatedDate: any;
      supplierId: string;
      quotation: Maybe<{ [key: string]: any }>;
      status: SourcingRequestStatusEnum;
      comment: Maybe<string>;
      responseDeadline: any;
      sourcingEvent: {
        __typename?: "SourcingEvent";
        id: string;
        name: string;
        additionalInfo: Maybe<string>;
        questionnaire: { [key: string]: any };
        status: SourcingEventStatusEnum;
        sentDate: Maybe<any>;
        linkToken: string;
        responseDeadline: any;
        attachments: Array<{
          __typename?: "Attachment";
          id: string;
          filename: string;
          url: Maybe<string>;
          size: number;
        }>;
        organization: {
          __typename?: "Organization";
          id: string;
          companyName: string;
          baseCurrency: CurrencyEnum;
          urlName: string;
          companyLogoUrl: Maybe<string>;
          address: string;
          areaCode: string;
          city: string;
          country: { __typename?: "Country"; code: string; name: string };
        };
        rfx: {
          __typename?: "Rfx";
          id: string;
          code: string;
          type: Maybe<RfxTypeEnum>;
          department: Maybe<{
            __typename?: "Department";
            id: string;
            name: string;
            code: Maybe<string>;
          }>;
          items: Array<{
            __typename?: "PurchaseRequestItem";
            id: string;
            name: string;
            type: PurchaseRequestItemTypeEnum;
            code: Maybe<string>;
            quantity: Maybe<number>;
            unit: Maybe<string>;
            expectedDeliveryDate: Maybe<any>;
            additionalInfo: Maybe<string>;
            isDeleted: boolean;
            attachments: Maybe<
              Array<{
                __typename?: "Attachment";
                id: string;
                url: Maybe<string>;
                filename: string;
              }>
            >;
          }>;
          emails: Array<
            | {
                __typename: "InboundEmail";
                id: string;
                parentId: Maybe<string>;
                relativeId: string;
                relativeType: ActivityRelativeTypeEnum;
                activityId: Maybe<string>;
                supplierId: Maybe<string>;
                supplierName: Maybe<string>;
                requesterId: Maybe<string>;
                requesterName: Maybe<string>;
                fromEmail: Maybe<string>;
                toEmail: Maybe<string>;
                ccEmail: Maybe<string>;
                subject: Maybe<string>;
                textBody: Maybe<string>;
                htmlBody: Maybe<string>;
                hasAttachments: Maybe<boolean>;
                spfResult: Maybe<SpfResultEnum>;
                dkimValid: Maybe<boolean>;
                securityData: Maybe<{ [key: string]: any }>;
                hasBeenRead: boolean;
                createdDate: any;
                inboundData: Maybe<string>;
              }
            | {
                __typename: "OutboundEmail";
                id: string;
                parentId: Maybe<string>;
                relativeId: string;
                relativeType: ActivityRelativeTypeEnum;
                activityId: Maybe<string>;
                supplierId: Maybe<string>;
                requesterId: Maybe<string>;
                creatorId: Maybe<string>;
                fromEmail: Maybe<string>;
                ccEmail: Maybe<string>;
                toEmail: Maybe<string>;
                subject: Maybe<string>;
                textBody: Maybe<string>;
                htmlBody: Maybe<string>;
                sendStatus: Maybe<EmailStatusEnum>;
                sendRejectedReason: Maybe<string>;
                hasAttachments: Maybe<boolean>;
                createdDate: any;
                outboundData: Maybe<string>;
              }
          >;
        };
        creator: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
          email: string;
        };
      };
      attachments: Maybe<
        Array<{
          __typename?: "Attachment";
          id: string;
          filename: string;
          url: Maybe<string>;
        }>
      >;
    };
  }>;
};

export type UpdateSourcingRequestMutationVariables = {
  sourcingRequestId: Scalars["String"];
  quotation: Scalars["String"];
  comment: Maybe<Scalars["String"]>;
  attachments: Maybe<Array<Scalars["Upload"]>>;
};

export type UpdateSourcingRequestMutation = {
  __typename?: "Mutation";
  updateSourcingRequest: { __typename?: "SourcingRequest"; id: string };
};

export type UploadQuotationPricesExcelMutationVariables = {
  organizationId: Scalars["String"];
  sourcingRequestId: Scalars["String"];
  excel: Scalars["Upload"];
};

export type UploadQuotationPricesExcelMutation = {
  __typename?: "Mutation";
  uploadQuotationPricesExcel: {
    __typename?: "ExcelImportQuotationPricesResult";
    success: boolean;
    rows: Array<{
      __typename?: "ExcelImportItemRow";
      row: number;
      success: boolean;
      errors: {
        __typename?: "ExcelImportItemValidationErrors";
        quantity: Maybe<Array<string>>;
        unitPrice: Maybe<Array<string>>;
      };
    }>;
    sourcingRequest: Maybe<{
      __typename?: "SourcingRequest";
      id: string;
      quotation: Maybe<{ [key: string]: any }>;
    }>;
  };
};

export type SupplierSourcingRequestsQueryVariables = {
  supplierId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type SupplierSourcingRequestsQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    supplierById: { __typename?: "Supplier"; id: string };
  }>;
};

export type CountriesQueryVariables = {};

export type CountriesQuery = {
  __typename?: "Query";
  countries: Array<{ __typename?: "Country"; code: string; name: string }>;
};

export type SourcingEventPublicQueryVariables = {
  sourcingEventLinkToken: Scalars["String"];
};

export type SourcingEventPublicQuery = {
  __typename?: "Query";
  sourcingEventPublic: {
    __typename?: "SourcingEventPublic";
    linkToken: string;
    name: string;
    responseDeadline: any;
    additionalInfo: Maybe<string>;
    updatedDate: any;
    organization: {
      __typename?: "OrganizationPublic";
      id: string;
      companyName: string;
      companyLogoUrl: Maybe<string>;
    };
    creator: {
      __typename?: "UserPublic";
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      avatarUrl: Maybe<string>;
    };
  };
};

export type SupplierLookupMutationVariables = {
  email: Scalars["String"];
  organizationId: Scalars["String"];
  sourcingEventLinkToken: Scalars["String"];
};

export type SupplierLookupMutation = {
  __typename?: "Mutation";
  supplierLookup: {
    __typename?: "SupplierLookupResponse";
    email: string;
    result: SupplierLookupResultEnum;
  };
};

export type SupplierSelfRegistrationMutationVariables = {
  sourcingEventContactPersonId: Scalars["String"];
  sourcingEventLinkToken: Scalars["String"];
  companyName: Scalars["String"];
  contactName: Scalars["String"];
  email: Scalars["String"];
  organizationId: Scalars["String"];
  url: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  zip: Maybe<Scalars["String"]>;
  countryCode: Maybe<CountryCodeEnum>;
  duns: Maybe<Scalars["String"]>;
  euVatNumber: Maybe<Scalars["String"]>;
  position: Maybe<Scalars["String"]>;
};

export type SupplierSelfRegistrationMutation = {
  __typename?: "Mutation";
  supplierSelfRegistration: boolean;
};

export type AddSupplierCategoryMutationVariables = {
  organizationId: Scalars["String"];
  supplierId: Scalars["String"];
  categoryId: Scalars["String"];
};

export type AddSupplierCategoryMutation = {
  __typename?: "Mutation";
  addSupplierCategory: { __typename?: "Category"; id: string; name: string };
};

export type ContactPersonInfoFragment = {
  __typename?: "ContactPerson";
  id: string;
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  position: Maybe<string>;
  phoneNumber: Maybe<string>;
  email: string;
  notes: Maybe<string>;
};

export type ContactPersonByIdQueryVariables = {
  contactPersonId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type ContactPersonByIdQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    contactPersonById: {
      __typename?: "ContactPerson";
    } & ContactPersonInfoFragment;
  }>;
};

export type CreateCategoryMutationVariables = {
  organizationId: Maybe<Scalars["String"]>;
  parentId: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type CreateCategoryMutation = {
  __typename?: "Mutation";
  createCategory: { __typename?: "Category"; id: string; name: string };
};

export type CreateContactPersonMutationVariables = {
  supplierId: Scalars["String"];
  firstName: Maybe<Scalars["String"]>;
  lastName: Maybe<Scalars["String"]>;
  position: Maybe<Scalars["String"]>;
  phoneNumber: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  notes: Maybe<Scalars["String"]>;
  isDefault: Scalars["Boolean"];
};

export type CreateContactPersonMutation = {
  __typename?: "Mutation";
  createContactPerson: { __typename?: "ContactPerson"; id: string };
};

export type CreateSupplierAttachmentMutationVariables = {
  organizationId: Scalars["String"];
  supplierId: Scalars["String"];
  notes: Scalars["String"];
  documentDate: Maybe<Scalars["Date"]>;
  validityDate: Maybe<Scalars["Date"]>;
  attachments: Array<Scalars["Upload"]>;
};

export type CreateSupplierAttachmentMutation = {
  __typename?: "Mutation";
  createSupplierAttachment: Array<{
    __typename?: "Attachment";
    id: string;
    creatorId: string;
    url: Maybe<string>;
    filename: string;
    notes: string;
    createdDate: any;
    documentDate: Maybe<any>;
    validityDate: Maybe<any>;
    creator: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      avatarUrl: Maybe<string>;
    };
  }>;
};

export type RemoveSupplierCategoryMutationVariables = {
  organizationId: Scalars["String"];
  supplierId: Scalars["String"];
  categoryId: Scalars["String"];
};

export type RemoveSupplierCategoryMutation = {
  __typename?: "Mutation";
  removeSupplierCategory: { __typename?: "Category"; id: string; name: string };
};

export type SrmViewQueryVariables = {
  organizationId: Scalars["String"];
  withArchived: Maybe<Scalars["Boolean"]>;
};

export type SrmViewQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      suppliers: Array<{
        __typename?: "Supplier";
        id: string;
        name: Maybe<string>;
        status: Maybe<SupplierStatusEnum>;
        webAddress: Maybe<string>;
        address: Maybe<string>;
        city: Maybe<string>;
        areaCode: Maybe<string>;
        countryCode: Maybe<CountryCodeEnum>;
        createdDate: any;
        updatedDate: any;
        lastActivityDate: any;
        defaultContactPerson: {
          __typename?: "ContactPerson";
          id: string;
          email: string;
        };
        country: Maybe<{ __typename?: "Country"; code: string; name: string }>;
        attachments: Array<{ __typename?: "Attachment"; id: string }>;
        lastContact: Maybe<{
          __typename?: "User";
          avatarUrl: Maybe<string>;
          id: string;
          firstName: string;
          lastName: string;
        }>;
        contactPersons: Array<{
          __typename?: "ContactPerson";
          id: string;
          firstName: Maybe<string>;
          lastName: Maybe<string>;
          email: string;
        }>;
        categories: Array<{
          __typename?: "Category";
          id: string;
          name: string;
        }>;
      }>;
    };
  }>;
};

export type SupplierAttachmentQueryVariables = {
  supplierId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type SupplierAttachmentQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    supplierById: {
      __typename?: "Supplier";
      id: string;
      attachments: Array<{
        __typename?: "Attachment";
        id: string;
        creatorId: string;
        url: Maybe<string>;
        filename: string;
        notes: string;
        createdDate: any;
        documentDate: Maybe<any>;
        validityDate: Maybe<any>;
        creator: {
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl: Maybe<string>;
        };
      }>;
    };
  }>;
};

export type SupplierDetailInfoFragment = {
  __typename?: "Supplier";
  id: string;
  name: Maybe<string>;
  webAddress: Maybe<string>;
  address: Maybe<string>;
  city: Maybe<string>;
  areaCode: Maybe<string>;
  countryCode: Maybe<CountryCodeEnum>;
  paymentTerms: Maybe<string>;
  currency: Maybe<CurrencyEnum>;
  vatIdentification: Maybe<string>;
  businessRegistry: Maybe<string>;
  leiCode: Maybe<string>;
  dunsCode: Maybe<string>;
  erpSupplierCode: Maybe<string>;
  customFields: Maybe<{ [key: string]: any }>;
  status: Maybe<SupplierStatusEnum>;
  notes: Maybe<string>;
  createdDate: any;
  updatedDate: any;
  defaultContactPerson: {
    __typename?: "ContactPerson";
    id: string;
    email: string;
  };
  categories: Array<{ __typename?: "Category"; id: string; name: string }>;
  contactPersons: Array<{
    __typename?: "ContactPerson";
    id: string;
    firstName: Maybe<string>;
    lastName: Maybe<string>;
    position: Maybe<string>;
    phoneNumber: Maybe<string>;
    email: string;
    notes: Maybe<string>;
    createdDate: any;
    updatedDate: any;
  }>;
  mentions: Array<{
    __typename?: "Mention";
    id: string;
    activityId: string;
    mentionedUserId: Maybe<string>;
    taggedSupplierId: Maybe<string>;
    createdDate: any;
    updatedDate: any;
    activity: {
      __typename?: "ActivityFeed";
      id: string;
      organizationId: string;
      userId: Maybe<string>;
      parentId: Maybe<string>;
      relativeId: string;
      relativeType: ActivityRelativeTypeEnum;
      type: ActivityTypeEnum;
      createdDate: any;
      updatedDate: any;
      relativeInfo: Maybe<
        | {
            __typename?: "ActivityRelativeCardInfo";
            code: string;
            assigneeId: Maybe<string>;
            requestorId: Maybe<string>;
          }
        | { __typename?: "ActivityRelativeItemInfo" }
        | { __typename?: "ActivityRelativeSupplierInfo" }
      >;
      data:
        | {
            __typename: "ADD_ATTACHMENT";
            filename: string;
            encoding: string;
            mimetype: string;
            size: number;
            url: Maybe<string>;
          }
        | {
            __typename: "ADD_ITEM";
            name: string;
            addItemQuantity: Maybe<number>;
            addItemUnit: Maybe<string>;
            creator: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
          }
        | {
            __typename: "ADD_ITEMS";
            itemCount: number;
            creator: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
          }
        | {
            __typename: "ASSIGN";
            itemInfo: {
              __typename?: "ItemInfo";
              id: string;
              type: CardTypeEnum;
              code: string;
              name: string;
            };
            assignerInfo: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            assigneeInfo: Maybe<{
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            }>;
          }
        | {
            __typename: "COMMENT";
            comment: string;
            user: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            relativeInfo:
              | {
                  __typename: "ActivityRelativeCardInfo";
                  code: string;
                  url: string;
                  relativeCardInfoName: string;
                }
              | {
                  __typename: "ActivityRelativeItemInfo";
                  quantity: Maybe<number>;
                  unit: Maybe<string>;
                  relativeItemInfoName: string;
                }
              | {
                  __typename: "ActivityRelativeSupplierInfo";
                  email: Maybe<string>;
                  relativeSupplierInfoName: Maybe<string>;
                };
          }
        | {
            __typename: "CREATE_APPROVAL_REQUEST";
            approverInfo: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            approvalRequestInfo: {
              __typename?: "ApprovalRequestInfo";
              id: string;
              sequenceGroup: number;
              sequenceNumber: number;
              creatorComment: Maybe<string>;
              approverComment: Maybe<string>;
              dueDate: Maybe<any>;
              decisionDate: Maybe<any>;
              decision: Maybe<ApprovalRequestDecisionEnum>;
            };
            approvalWorkflowInfo: {
              __typename?: "ApprovalWorkflowInfo";
              code: string;
              requestName: Maybe<string>;
              approvalAmount: Maybe<number>;
              status: ApprovalWorkflowStatusEnum;
            };
            approvalWorkflowStats: {
              __typename?: "ApprovalWorkflowStats";
              totalRequestCount: number;
              approvedRequestCount: number;
            };
          }
        | {
            __typename: "CREATE_APPROVAL_WORKFLOW";
            creatorInfo: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            approvalWorkflowInfo: {
              __typename?: "ApprovalWorkflowInfo";
              code: string;
              requestName: Maybe<string>;
              comment: Maybe<string>;
              approvalAmount: Maybe<number>;
              status: ApprovalWorkflowStatusEnum;
            };
            approvalWorkflowStats: {
              __typename?: "ApprovalWorkflowStats";
              totalRequestCount: number;
              approvedRequestCount: number;
            };
          }
        | {
            __typename: "CREATE_INVOICE_FROM_ORDER";
            user: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            orderInfo: Maybe<{
              __typename?: "ActivityRelativeOrderInfo";
              id: string;
              code: string;
              type: Maybe<OrderTypeEnum>;
            }>;
          }
        | {
            __typename: "CREATE_PURCHASE_REQUEST";
            code: string;
            creator: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
          }
        | {
            __typename: "CREATE_PURCHASE_REQUEST_PRODUCT";
            name: string;
            quantity: number;
            unit: string;
            purchaseRequestCode: string;
            creator: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
          }
        | {
            __typename: "CREATE_PURCHASE_REQUEST_SERVICE";
            name: string;
            purchaseRequestCode: string;
            creator: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
          }
        | {
            __typename: "CREATE_SUPPLIER";
            email: string;
            supplierName: Maybe<string>;
            creator: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
          }
        | {
            __typename: "DRAG_CARD";
            cardName: string;
            fromType: SwimlaneIdEnum;
            toType: SwimlaneIdEnum;
            fromCode: string;
            toCode: string;
            requestorInfo: {
              __typename?: "ActivityUserInfo";
              firstName: string;
            };
            assigneeInfo: Maybe<{
              __typename?: "ActivityUserInfo";
              firstName: string;
            }>;
          }
        | { __typename: "DRAG_ITEM"; cardName: string }
        | {
            __typename: "EMAIL_INBOUND_MENTION";
            comment: string;
            user: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            relativeInfo:
              | {
                  __typename: "ActivityRelativeCardInfo";
                  code: string;
                  url: string;
                  relativeCardInfoName: string;
                }
              | {
                  __typename: "ActivityRelativeItemInfo";
                  quantity: Maybe<number>;
                  unit: Maybe<string>;
                  relativeItemInfoName: string;
                }
              | {
                  __typename: "ActivityRelativeSupplierInfo";
                  email: Maybe<string>;
                  relativeSupplierInfoName: Maybe<string>;
                };
            emailInfo: {
              __typename?: "ActivityEmailInfo";
              subject: string;
              email: string;
              message: string;
            };
          }
        | {
            __typename: "EMAIL_INBOUND_REQUESTER";
            emailInboundRequesterInfo: string;
          }
        | {
            __typename: "EMAIL_INBOUND_SUPPLIER";
            supplierInfo: {
              __typename?: "ActivityRelativeSupplierInfo";
              id: Maybe<string>;
              name: Maybe<string>;
              email: Maybe<string>;
            };
            emailInfo: {
              __typename?: "ActivityEmailInfo";
              subject: string;
              email: string;
              message: string;
            };
          }
        | {
            __typename: "EMAIL_OUTBOUND_MENTION";
            emailOutboundMentionInfo: string;
          }
        | {
            __typename: "EMAIL_OUTBOUND_SUPPLIER";
            subject: string;
            message: string;
            rfxInfo: Maybe<{
              __typename?: "ActivityRelativeRfxInfo";
              id: string;
              type: Maybe<RfxTypeEnum>;
              code: string;
            }>;
            orderInfo: Maybe<{
              __typename?: "ActivityRelativeOrderInfo";
              id: string;
              type: Maybe<OrderTypeEnum>;
              code: string;
            }>;
            supplierInfo: {
              __typename?: "ActivityRelativeSupplierInfo";
              id: Maybe<string>;
              name: Maybe<string>;
              email: Maybe<string>;
            };
            creator: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
          }
        | {
            __typename: "REPLY";
            comment: string;
            user: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            relativeInfo:
              | {
                  __typename: "ActivityRelativeCardInfo";
                  code: string;
                  url: string;
                  relativeCardInfoName: string;
                }
              | {
                  __typename: "ActivityRelativeItemInfo";
                  quantity: Maybe<number>;
                  unit: Maybe<string>;
                  relativeItemInfoName: string;
                }
              | {
                  __typename: "ActivityRelativeSupplierInfo";
                  email: Maybe<string>;
                  relativeSupplierInfoName: Maybe<string>;
                };
          }
        | {
            __typename: "UPDATE_APPROVAL_REQUEST";
            approverInfo: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            approvalRequestInfo: {
              __typename?: "ApprovalRequestInfo";
              id: string;
              sequenceGroup: number;
              sequenceNumber: number;
              creatorComment: Maybe<string>;
              approverComment: Maybe<string>;
              dueDate: Maybe<any>;
              decisionDate: Maybe<any>;
              decision: Maybe<ApprovalRequestDecisionEnum>;
            };
            approvalWorkflowInfo: {
              __typename?: "ApprovalWorkflowInfo";
              code: string;
              requestName: Maybe<string>;
              approvalAmount: Maybe<number>;
              status: ApprovalWorkflowStatusEnum;
            };
            approvalWorkflowStats: {
              __typename?: "ApprovalWorkflowStats";
              totalRequestCount: number;
              approvedRequestCount: number;
            };
          }
        | {
            __typename: "UPDATE_APPROVAL_WORKFLOW";
            creatorInfo: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            approvalWorkflowInfo: {
              __typename?: "ApprovalWorkflowInfo";
              code: string;
              requestName: Maybe<string>;
              comment: Maybe<string>;
              approvalAmount: Maybe<number>;
              status: ApprovalWorkflowStatusEnum;
            };
            relativeCardInfo: {
              __typename?: "ActivityRelativeCardInfo";
              code: string;
              name: string;
              url: string;
            };
            approvalWorkflowStats: {
              __typename?: "ApprovalWorkflowStats";
              totalRequestCount: number;
              approvedRequestCount: number;
            };
          }
        | {
            __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES";
            user: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            orderInfo: Maybe<{
              __typename?: "ActivityRelativeOrderInfo";
              id: string;
              code: string;
              type: Maybe<OrderTypeEnum>;
            }>;
          }
        | {
            __typename: "UPDATE_SUPPLIER";
            supplierName: Maybe<string>;
            creator: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            changedFields: Array<{
              __typename?: "ChangedFieldInfo";
              fieldName: string;
              oldValue: string;
              newValue: string;
            }>;
          };
      author: Maybe<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        avatarUrl: Maybe<string>;
      }>;
      attachments: Maybe<
        Array<{
          __typename?: "Attachment";
          id: string;
          filename: string;
          mimetype: string;
          url: Maybe<string>;
        }>
      >;
      replies: Array<{
        __typename?: "ActivityFeed";
        id: string;
        organizationId: string;
        userId: Maybe<string>;
        parentId: Maybe<string>;
        relativeId: string;
        relativeType: ActivityRelativeTypeEnum;
        type: ActivityTypeEnum;
        createdDate: any;
        updatedDate: any;
        attachments: Maybe<
          Array<{
            __typename?: "Attachment";
            id: string;
            filename: string;
            mimetype: string;
            url: Maybe<string>;
          }>
        >;
        data:
          | {
              __typename: "ADD_ATTACHMENT";
              filename: string;
              encoding: string;
              mimetype: string;
              size: number;
              url: Maybe<string>;
            }
          | {
              __typename: "ADD_ITEM";
              name: string;
              addItemQuantity: Maybe<number>;
              addItemUnit: Maybe<string>;
              creator: {
                __typename?: "ActivityUserInfo";
                id: string;
                firstName: string;
                lastName: string;
                avatarUrl: Maybe<string>;
              };
            }
          | {
              __typename: "ADD_ITEMS";
              itemCount: number;
              creator: {
                __typename?: "ActivityUserInfo";
                id: string;
                firstName: string;
                lastName: string;
                avatarUrl: Maybe<string>;
              };
            }
          | {
              __typename: "ASSIGN";
              itemInfo: {
                __typename?: "ItemInfo";
                id: string;
                type: CardTypeEnum;
                code: string;
                name: string;
              };
              assignerInfo: {
                __typename?: "ActivityUserInfo";
                id: string;
                firstName: string;
                lastName: string;
                avatarUrl: Maybe<string>;
              };
              assigneeInfo: Maybe<{
                __typename?: "ActivityUserInfo";
                id: string;
                firstName: string;
                lastName: string;
                avatarUrl: Maybe<string>;
              }>;
            }
          | {
              __typename: "COMMENT";
              comment: string;
              user: {
                __typename?: "ActivityUserInfo";
                id: string;
                firstName: string;
                lastName: string;
                avatarUrl: Maybe<string>;
              };
              relativeInfo:
                | {
                    __typename: "ActivityRelativeCardInfo";
                    code: string;
                    url: string;
                    relativeCardInfoName: string;
                  }
                | {
                    __typename: "ActivityRelativeItemInfo";
                    quantity: Maybe<number>;
                    unit: Maybe<string>;
                    relativeItemInfoName: string;
                  }
                | {
                    __typename: "ActivityRelativeSupplierInfo";
                    email: Maybe<string>;
                    relativeSupplierInfoName: Maybe<string>;
                  };
            }
          | { __typename?: "CREATE_APPROVAL_REQUEST" }
          | {
              __typename: "CREATE_APPROVAL_WORKFLOW";
              creatorInfo: {
                __typename?: "ActivityUserInfo";
                id: string;
                firstName: string;
                lastName: string;
                avatarUrl: Maybe<string>;
              };
              approvalWorkflowInfo: {
                __typename?: "ApprovalWorkflowInfo";
                code: string;
                requestName: Maybe<string>;
                comment: Maybe<string>;
                approvalAmount: Maybe<number>;
                status: ApprovalWorkflowStatusEnum;
              };
            }
          | {
              __typename: "CREATE_INVOICE_FROM_ORDER";
              user: {
                __typename?: "ActivityUserInfo";
                id: string;
                firstName: string;
                lastName: string;
                avatarUrl: Maybe<string>;
              };
              orderInfo: Maybe<{
                __typename?: "ActivityRelativeOrderInfo";
                id: string;
                code: string;
                type: Maybe<OrderTypeEnum>;
              }>;
            }
          | {
              __typename: "CREATE_PURCHASE_REQUEST";
              code: string;
              creator: {
                __typename?: "ActivityUserInfo";
                id: string;
                firstName: string;
                lastName: string;
                avatarUrl: Maybe<string>;
              };
            }
          | {
              __typename: "CREATE_PURCHASE_REQUEST_PRODUCT";
              name: string;
              quantity: number;
              unit: string;
              purchaseRequestCode: string;
              creator: {
                __typename?: "ActivityUserInfo";
                id: string;
                firstName: string;
                lastName: string;
                avatarUrl: Maybe<string>;
              };
            }
          | {
              __typename: "CREATE_PURCHASE_REQUEST_SERVICE";
              name: string;
              purchaseRequestCode: string;
              creator: {
                __typename?: "ActivityUserInfo";
                id: string;
                firstName: string;
                lastName: string;
                avatarUrl: Maybe<string>;
              };
            }
          | {
              __typename: "CREATE_SUPPLIER";
              email: string;
              supplierName: Maybe<string>;
              creator: {
                __typename?: "ActivityUserInfo";
                id: string;
                firstName: string;
                lastName: string;
                avatarUrl: Maybe<string>;
              };
            }
          | {
              __typename: "DRAG_CARD";
              cardName: string;
              fromType: SwimlaneIdEnum;
              toType: SwimlaneIdEnum;
              fromCode: string;
              toCode: string;
              requestorInfo: {
                __typename?: "ActivityUserInfo";
                firstName: string;
              };
              assigneeInfo: Maybe<{
                __typename?: "ActivityUserInfo";
                firstName: string;
              }>;
            }
          | { __typename: "DRAG_ITEM"; cardName: string }
          | {
              __typename: "EMAIL_INBOUND_MENTION";
              comment: string;
              user: {
                __typename?: "ActivityUserInfo";
                id: string;
                firstName: string;
                lastName: string;
                avatarUrl: Maybe<string>;
              };
              relativeInfo:
                | {
                    __typename: "ActivityRelativeCardInfo";
                    code: string;
                    url: string;
                    relativeCardInfoName: string;
                  }
                | {
                    __typename: "ActivityRelativeItemInfo";
                    quantity: Maybe<number>;
                    unit: Maybe<string>;
                    relativeItemInfoName: string;
                  }
                | {
                    __typename: "ActivityRelativeSupplierInfo";
                    email: Maybe<string>;
                    relativeSupplierInfoName: Maybe<string>;
                  };
              emailInfo: {
                __typename?: "ActivityEmailInfo";
                subject: string;
                email: string;
                message: string;
              };
            }
          | {
              __typename: "EMAIL_INBOUND_REQUESTER";
              emailInboundRequesterInfo: string;
            }
          | {
              __typename: "EMAIL_INBOUND_SUPPLIER";
              supplierInfo: {
                __typename?: "ActivityRelativeSupplierInfo";
                id: Maybe<string>;
                name: Maybe<string>;
                email: Maybe<string>;
              };
              emailInfo: {
                __typename?: "ActivityEmailInfo";
                subject: string;
                email: string;
                message: string;
              };
            }
          | {
              __typename: "EMAIL_OUTBOUND_MENTION";
              emailOutboundMentionInfo: string;
            }
          | {
              __typename: "EMAIL_OUTBOUND_SUPPLIER";
              subject: string;
              message: string;
              rfxInfo: Maybe<{
                __typename?: "ActivityRelativeRfxInfo";
                id: string;
                type: Maybe<RfxTypeEnum>;
                code: string;
              }>;
              orderInfo: Maybe<{
                __typename?: "ActivityRelativeOrderInfo";
                id: string;
                type: Maybe<OrderTypeEnum>;
                code: string;
              }>;
              supplierInfo: {
                __typename?: "ActivityRelativeSupplierInfo";
                id: Maybe<string>;
                name: Maybe<string>;
                email: Maybe<string>;
              };
              creator: {
                __typename?: "ActivityUserInfo";
                id: string;
                firstName: string;
                lastName: string;
                avatarUrl: Maybe<string>;
              };
            }
          | {
              __typename: "REPLY";
              comment: string;
              user: {
                __typename?: "ActivityUserInfo";
                id: string;
                firstName: string;
                lastName: string;
                avatarUrl: Maybe<string>;
              };
              relativeInfo:
                | {
                    __typename: "ActivityRelativeCardInfo";
                    code: string;
                    url: string;
                    relativeCardInfoName: string;
                  }
                | {
                    __typename: "ActivityRelativeItemInfo";
                    quantity: Maybe<number>;
                    unit: Maybe<string>;
                    relativeItemInfoName: string;
                  }
                | {
                    __typename: "ActivityRelativeSupplierInfo";
                    email: Maybe<string>;
                    relativeSupplierInfoName: Maybe<string>;
                  };
            }
          | { __typename?: "UPDATE_APPROVAL_REQUEST" }
          | { __typename?: "UPDATE_APPROVAL_WORKFLOW" }
          | {
              __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES";
              user: {
                __typename?: "ActivityUserInfo";
                id: string;
                firstName: string;
                lastName: string;
                avatarUrl: Maybe<string>;
              };
              orderInfo: Maybe<{
                __typename?: "ActivityRelativeOrderInfo";
                id: string;
                code: string;
                type: Maybe<OrderTypeEnum>;
              }>;
            }
          | {
              __typename: "UPDATE_SUPPLIER";
              supplierName: Maybe<string>;
              creator: {
                __typename?: "ActivityUserInfo";
                id: string;
                firstName: string;
                lastName: string;
                avatarUrl: Maybe<string>;
              };
              changedFields: Array<{
                __typename?: "ChangedFieldInfo";
                fieldName: string;
                oldValue: string;
                newValue: string;
              }>;
            };
        author: Maybe<{
          __typename?: "User";
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          avatarUrl: Maybe<string>;
        }>;
        replies: Array<{
          __typename?: "ActivityFeed";
          id: string;
          type: ActivityTypeEnum;
        }>;
      }>;
    };
  }>;
  activities: Array<{
    __typename?: "ActivityFeed";
    id: string;
    organizationId: string;
    userId: Maybe<string>;
    parentId: Maybe<string>;
    relativeId: string;
    relativeType: ActivityRelativeTypeEnum;
    type: ActivityTypeEnum;
    createdDate: any;
    updatedDate: any;
    author: Maybe<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      avatarUrl: Maybe<string>;
    }>;
    attachments: Maybe<
      Array<{
        __typename?: "Attachment";
        id: string;
        filename: string;
        mimetype: string;
        url: Maybe<string>;
      }>
    >;
    replies: Array<{
      __typename?: "ActivityFeed";
      id: string;
      organizationId: string;
      userId: Maybe<string>;
      parentId: Maybe<string>;
      relativeId: string;
      relativeType: ActivityRelativeTypeEnum;
      type: ActivityTypeEnum;
      createdDate: any;
      updatedDate: any;
      attachments: Maybe<
        Array<{
          __typename?: "Attachment";
          id: string;
          filename: string;
          mimetype: string;
          url: Maybe<string>;
        }>
      >;
      data:
        | {
            __typename: "ADD_ATTACHMENT";
            filename: string;
            encoding: string;
            mimetype: string;
            size: number;
            url: Maybe<string>;
          }
        | {
            __typename: "ADD_ITEM";
            name: string;
            addItemQuantity: Maybe<number>;
            addItemUnit: Maybe<string>;
            creator: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
          }
        | {
            __typename: "ADD_ITEMS";
            itemCount: number;
            creator: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
          }
        | {
            __typename: "ASSIGN";
            itemInfo: {
              __typename?: "ItemInfo";
              id: string;
              type: CardTypeEnum;
              code: string;
              name: string;
            };
            assignerInfo: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            assigneeInfo: Maybe<{
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            }>;
          }
        | {
            __typename: "COMMENT";
            comment: string;
            user: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            relativeInfo:
              | {
                  __typename: "ActivityRelativeCardInfo";
                  code: string;
                  url: string;
                  relativeCardInfoName: string;
                }
              | {
                  __typename: "ActivityRelativeItemInfo";
                  quantity: Maybe<number>;
                  unit: Maybe<string>;
                  relativeItemInfoName: string;
                }
              | {
                  __typename: "ActivityRelativeSupplierInfo";
                  email: Maybe<string>;
                  relativeSupplierInfoName: Maybe<string>;
                };
          }
        | { __typename?: "CREATE_APPROVAL_REQUEST" }
        | {
            __typename: "CREATE_APPROVAL_WORKFLOW";
            creatorInfo: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            approvalWorkflowInfo: {
              __typename?: "ApprovalWorkflowInfo";
              code: string;
              requestName: Maybe<string>;
              comment: Maybe<string>;
              approvalAmount: Maybe<number>;
              status: ApprovalWorkflowStatusEnum;
            };
          }
        | {
            __typename: "CREATE_INVOICE_FROM_ORDER";
            user: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            orderInfo: Maybe<{
              __typename?: "ActivityRelativeOrderInfo";
              id: string;
              code: string;
              type: Maybe<OrderTypeEnum>;
            }>;
          }
        | {
            __typename: "CREATE_PURCHASE_REQUEST";
            code: string;
            creator: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
          }
        | {
            __typename: "CREATE_PURCHASE_REQUEST_PRODUCT";
            name: string;
            quantity: number;
            unit: string;
            purchaseRequestCode: string;
            creator: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
          }
        | {
            __typename: "CREATE_PURCHASE_REQUEST_SERVICE";
            name: string;
            purchaseRequestCode: string;
            creator: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
          }
        | {
            __typename: "CREATE_SUPPLIER";
            email: string;
            supplierName: Maybe<string>;
            creator: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
          }
        | {
            __typename: "DRAG_CARD";
            cardName: string;
            fromType: SwimlaneIdEnum;
            toType: SwimlaneIdEnum;
            fromCode: string;
            toCode: string;
            requestorInfo: {
              __typename?: "ActivityUserInfo";
              firstName: string;
            };
            assigneeInfo: Maybe<{
              __typename?: "ActivityUserInfo";
              firstName: string;
            }>;
          }
        | { __typename: "DRAG_ITEM"; cardName: string }
        | {
            __typename: "EMAIL_INBOUND_MENTION";
            comment: string;
            user: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            relativeInfo:
              | {
                  __typename: "ActivityRelativeCardInfo";
                  code: string;
                  url: string;
                  relativeCardInfoName: string;
                }
              | {
                  __typename: "ActivityRelativeItemInfo";
                  quantity: Maybe<number>;
                  unit: Maybe<string>;
                  relativeItemInfoName: string;
                }
              | {
                  __typename: "ActivityRelativeSupplierInfo";
                  email: Maybe<string>;
                  relativeSupplierInfoName: Maybe<string>;
                };
            emailInfo: {
              __typename?: "ActivityEmailInfo";
              subject: string;
              email: string;
              message: string;
            };
          }
        | {
            __typename: "EMAIL_INBOUND_REQUESTER";
            emailInboundRequesterInfo: string;
          }
        | {
            __typename: "EMAIL_INBOUND_SUPPLIER";
            supplierInfo: {
              __typename?: "ActivityRelativeSupplierInfo";
              id: Maybe<string>;
              name: Maybe<string>;
              email: Maybe<string>;
            };
            emailInfo: {
              __typename?: "ActivityEmailInfo";
              subject: string;
              email: string;
              message: string;
            };
          }
        | {
            __typename: "EMAIL_OUTBOUND_MENTION";
            emailOutboundMentionInfo: string;
          }
        | {
            __typename: "EMAIL_OUTBOUND_SUPPLIER";
            subject: string;
            message: string;
            rfxInfo: Maybe<{
              __typename?: "ActivityRelativeRfxInfo";
              id: string;
              type: Maybe<RfxTypeEnum>;
              code: string;
            }>;
            orderInfo: Maybe<{
              __typename?: "ActivityRelativeOrderInfo";
              id: string;
              type: Maybe<OrderTypeEnum>;
              code: string;
            }>;
            supplierInfo: {
              __typename?: "ActivityRelativeSupplierInfo";
              id: Maybe<string>;
              name: Maybe<string>;
              email: Maybe<string>;
            };
            creator: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
          }
        | {
            __typename: "REPLY";
            comment: string;
            user: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            relativeInfo:
              | {
                  __typename: "ActivityRelativeCardInfo";
                  code: string;
                  url: string;
                  relativeCardInfoName: string;
                }
              | {
                  __typename: "ActivityRelativeItemInfo";
                  quantity: Maybe<number>;
                  unit: Maybe<string>;
                  relativeItemInfoName: string;
                }
              | {
                  __typename: "ActivityRelativeSupplierInfo";
                  email: Maybe<string>;
                  relativeSupplierInfoName: Maybe<string>;
                };
          }
        | { __typename?: "UPDATE_APPROVAL_REQUEST" }
        | { __typename?: "UPDATE_APPROVAL_WORKFLOW" }
        | {
            __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES";
            user: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            orderInfo: Maybe<{
              __typename?: "ActivityRelativeOrderInfo";
              id: string;
              code: string;
              type: Maybe<OrderTypeEnum>;
            }>;
          }
        | {
            __typename: "UPDATE_SUPPLIER";
            supplierName: Maybe<string>;
            creator: {
              __typename?: "ActivityUserInfo";
              id: string;
              firstName: string;
              lastName: string;
              avatarUrl: Maybe<string>;
            };
            changedFields: Array<{
              __typename?: "ChangedFieldInfo";
              fieldName: string;
              oldValue: string;
              newValue: string;
            }>;
          };
      author: Maybe<{
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        avatarUrl: Maybe<string>;
      }>;
      replies: Array<{
        __typename?: "ActivityFeed";
        id: string;
        type: ActivityTypeEnum;
      }>;
    }>;
    data:
      | {
          __typename: "ADD_ATTACHMENT";
          filename: string;
          encoding: string;
          mimetype: string;
          size: number;
          url: Maybe<string>;
        }
      | {
          __typename: "ADD_ITEM";
          name: string;
          addItemQuantity: Maybe<number>;
          addItemUnit: Maybe<string>;
          creator: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
        }
      | {
          __typename: "ADD_ITEMS";
          itemCount: number;
          creator: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
        }
      | {
          __typename: "ASSIGN";
          itemInfo: {
            __typename?: "ItemInfo";
            id: string;
            type: CardTypeEnum;
            code: string;
            name: string;
          };
          assignerInfo: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          assigneeInfo: Maybe<{
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          }>;
        }
      | {
          __typename: "COMMENT";
          comment: string;
          user: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          relativeInfo:
            | {
                __typename: "ActivityRelativeCardInfo";
                code: string;
                url: string;
                relativeCardInfoName: string;
              }
            | {
                __typename: "ActivityRelativeItemInfo";
                quantity: Maybe<number>;
                unit: Maybe<string>;
                relativeItemInfoName: string;
              }
            | {
                __typename: "ActivityRelativeSupplierInfo";
                email: Maybe<string>;
                relativeSupplierInfoName: Maybe<string>;
              };
        }
      | {
          __typename: "CREATE_APPROVAL_REQUEST";
          approverInfo: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          approvalRequestInfo: {
            __typename?: "ApprovalRequestInfo";
            id: string;
            sequenceGroup: number;
            sequenceNumber: number;
            creatorComment: Maybe<string>;
            approverComment: Maybe<string>;
            dueDate: Maybe<any>;
            decisionDate: Maybe<any>;
            decision: Maybe<ApprovalRequestDecisionEnum>;
          };
          approvalWorkflowInfo: {
            __typename?: "ApprovalWorkflowInfo";
            code: string;
            requestName: Maybe<string>;
            approvalAmount: Maybe<number>;
            status: ApprovalWorkflowStatusEnum;
          };
          approvalWorkflowStats: {
            __typename?: "ApprovalWorkflowStats";
            totalRequestCount: number;
            approvedRequestCount: number;
          };
        }
      | {
          __typename: "CREATE_APPROVAL_WORKFLOW";
          creatorInfo: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          approvalWorkflowInfo: {
            __typename?: "ApprovalWorkflowInfo";
            code: string;
            requestName: Maybe<string>;
            comment: Maybe<string>;
            approvalAmount: Maybe<number>;
            status: ApprovalWorkflowStatusEnum;
          };
          approvalWorkflowStats: {
            __typename?: "ApprovalWorkflowStats";
            totalRequestCount: number;
            approvedRequestCount: number;
          };
        }
      | {
          __typename: "CREATE_INVOICE_FROM_ORDER";
          user: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          orderInfo: Maybe<{
            __typename?: "ActivityRelativeOrderInfo";
            id: string;
            code: string;
            type: Maybe<OrderTypeEnum>;
          }>;
        }
      | {
          __typename: "CREATE_PURCHASE_REQUEST";
          code: string;
          creator: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
        }
      | {
          __typename: "CREATE_PURCHASE_REQUEST_PRODUCT";
          name: string;
          quantity: number;
          unit: string;
          purchaseRequestCode: string;
          creator: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
        }
      | {
          __typename: "CREATE_PURCHASE_REQUEST_SERVICE";
          name: string;
          purchaseRequestCode: string;
          creator: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
        }
      | {
          __typename: "CREATE_SUPPLIER";
          email: string;
          supplierName: Maybe<string>;
          creator: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
        }
      | {
          __typename: "DRAG_CARD";
          cardName: string;
          fromType: SwimlaneIdEnum;
          toType: SwimlaneIdEnum;
          fromCode: string;
          toCode: string;
          requestorInfo: { __typename?: "ActivityUserInfo"; firstName: string };
          assigneeInfo: Maybe<{
            __typename?: "ActivityUserInfo";
            firstName: string;
          }>;
        }
      | { __typename: "DRAG_ITEM"; cardName: string }
      | {
          __typename: "EMAIL_INBOUND_MENTION";
          comment: string;
          user: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          relativeInfo:
            | {
                __typename: "ActivityRelativeCardInfo";
                code: string;
                url: string;
                relativeCardInfoName: string;
              }
            | {
                __typename: "ActivityRelativeItemInfo";
                quantity: Maybe<number>;
                unit: Maybe<string>;
                relativeItemInfoName: string;
              }
            | {
                __typename: "ActivityRelativeSupplierInfo";
                email: Maybe<string>;
                relativeSupplierInfoName: Maybe<string>;
              };
          emailInfo: {
            __typename?: "ActivityEmailInfo";
            subject: string;
            email: string;
            message: string;
          };
        }
      | {
          __typename: "EMAIL_INBOUND_REQUESTER";
          emailInboundRequesterInfo: string;
        }
      | {
          __typename: "EMAIL_INBOUND_SUPPLIER";
          supplierInfo: {
            __typename?: "ActivityRelativeSupplierInfo";
            id: Maybe<string>;
            name: Maybe<string>;
            email: Maybe<string>;
          };
          emailInfo: {
            __typename?: "ActivityEmailInfo";
            subject: string;
            email: string;
            message: string;
          };
        }
      | {
          __typename: "EMAIL_OUTBOUND_MENTION";
          emailOutboundMentionInfo: string;
        }
      | {
          __typename: "EMAIL_OUTBOUND_SUPPLIER";
          subject: string;
          message: string;
          rfxInfo: Maybe<{
            __typename?: "ActivityRelativeRfxInfo";
            id: string;
            type: Maybe<RfxTypeEnum>;
            code: string;
          }>;
          orderInfo: Maybe<{
            __typename?: "ActivityRelativeOrderInfo";
            id: string;
            type: Maybe<OrderTypeEnum>;
            code: string;
          }>;
          supplierInfo: {
            __typename?: "ActivityRelativeSupplierInfo";
            id: Maybe<string>;
            name: Maybe<string>;
            email: Maybe<string>;
          };
          creator: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
        }
      | {
          __typename: "REPLY";
          comment: string;
          user: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          relativeInfo:
            | {
                __typename: "ActivityRelativeCardInfo";
                code: string;
                url: string;
                relativeCardInfoName: string;
              }
            | {
                __typename: "ActivityRelativeItemInfo";
                quantity: Maybe<number>;
                unit: Maybe<string>;
                relativeItemInfoName: string;
              }
            | {
                __typename: "ActivityRelativeSupplierInfo";
                email: Maybe<string>;
                relativeSupplierInfoName: Maybe<string>;
              };
        }
      | {
          __typename: "UPDATE_APPROVAL_REQUEST";
          approverInfo: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          approvalRequestInfo: {
            __typename?: "ApprovalRequestInfo";
            id: string;
            sequenceGroup: number;
            sequenceNumber: number;
            creatorComment: Maybe<string>;
            approverComment: Maybe<string>;
            dueDate: Maybe<any>;
            decisionDate: Maybe<any>;
            decision: Maybe<ApprovalRequestDecisionEnum>;
          };
          approvalWorkflowInfo: {
            __typename?: "ApprovalWorkflowInfo";
            code: string;
            requestName: Maybe<string>;
            approvalAmount: Maybe<number>;
            status: ApprovalWorkflowStatusEnum;
          };
          approvalWorkflowStats: {
            __typename?: "ApprovalWorkflowStats";
            totalRequestCount: number;
            approvedRequestCount: number;
          };
        }
      | {
          __typename: "UPDATE_APPROVAL_WORKFLOW";
          creatorInfo: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          approvalWorkflowInfo: {
            __typename?: "ApprovalWorkflowInfo";
            code: string;
            requestName: Maybe<string>;
            comment: Maybe<string>;
            approvalAmount: Maybe<number>;
            status: ApprovalWorkflowStatusEnum;
          };
          relativeCardInfo: {
            __typename?: "ActivityRelativeCardInfo";
            code: string;
            name: string;
            url: string;
          };
          approvalWorkflowStats: {
            __typename?: "ApprovalWorkflowStats";
            totalRequestCount: number;
            approvedRequestCount: number;
          };
        }
      | {
          __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES";
          user: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          orderInfo: Maybe<{
            __typename?: "ActivityRelativeOrderInfo";
            id: string;
            code: string;
            type: Maybe<OrderTypeEnum>;
          }>;
        }
      | {
          __typename: "UPDATE_SUPPLIER";
          supplierName: Maybe<string>;
          creator: {
            __typename?: "ActivityUserInfo";
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl: Maybe<string>;
          };
          changedFields: Array<{
            __typename?: "ChangedFieldInfo";
            fieldName: string;
            oldValue: string;
            newValue: string;
          }>;
        };
  }>;
};

export type SupplierByIdQueryVariables = {
  supplierId: Scalars["String"];
  organizationId: Scalars["String"];
};

export type SupplierByIdQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    id: string;
    organization: {
      __typename?: "Organization";
      id: string;
      categories: Array<{ __typename?: "Category"; id: string; name: string }>;
    };
    countries: Array<{ __typename?: "Country"; code: string; name: string }>;
    supplierById: { __typename?: "Supplier" } & SupplierDetailInfoFragment;
  }>;
};

export type SupplierCategoriesQueryVariables = {
  organizationId: Scalars["String"];
  supplierId: Scalars["String"];
};

export type SupplierCategoriesQuery = {
  __typename?: "Query";
  viewer: Maybe<{
    __typename?: "Viewer";
    supplierById: {
      __typename?: "Supplier";
      categories: Array<{ __typename?: "Category"; id: string; name: string }>;
    };
  }>;
};

export type UpdateContactPersonMutationVariables = {
  contactPersonId: Scalars["String"];
  firstName: Maybe<Scalars["String"]>;
  lastName: Maybe<Scalars["String"]>;
  position: Maybe<Scalars["String"]>;
  phoneNumber: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  notes: Maybe<Scalars["String"]>;
  isDefault: Scalars["Boolean"];
};

export type UpdateContactPersonMutation = {
  __typename?: "Mutation";
  updateContactPerson: {
    __typename?: "ContactPerson";
  } & ContactPersonInfoFragment;
};

export type UploadSuppliersExcelMutationVariables = {
  organizationId: Scalars["String"];
  excel: Scalars["Upload"];
};

export type UploadSuppliersExcelMutation = {
  __typename?: "Mutation";
  uploadSupplierExcel: {
    __typename?: "ExcelImportSupplierResult";
    success: boolean;
    rows: Array<{
      __typename?: "ExcelImportSupplierRow";
      row: number;
      success: boolean;
      errors: {
        __typename?: "ExcelImportSupplierValidationErrors";
        name: Maybe<Array<string>>;
        email: Maybe<Array<string>>;
        webAddress: Maybe<Array<string>>;
        address: Maybe<Array<string>>;
        city: Maybe<Array<string>>;
        areaCode: Maybe<Array<string>>;
        countryCode: Maybe<Array<string>>;
        paymentTerms: Maybe<Array<string>>;
        currency: Maybe<Array<string>>;
        vatIdentification: Maybe<Array<string>>;
        businessRegistry: Maybe<Array<string>>;
        leiCode: Maybe<Array<string>>;
        dunsCode: Maybe<Array<string>>;
        erpSupplierCode: Maybe<Array<string>>;
        customField1: Maybe<Array<string>>;
        customField2: Maybe<Array<string>>;
        customField3: Maybe<Array<string>>;
        status: Maybe<Array<string>>;
        notes: Maybe<Array<string>>;
      };
    }>;
  };
};

export type UpdateUserProfileMutationVariables = {
  id: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phoneNumber: Maybe<Scalars["String"]>;
  address: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  areaCode: Maybe<Scalars["String"]>;
  countryCode: Maybe<CountryCodeEnum>;
};

export type UpdateUserProfileMutation = {
  __typename?: "Mutation";
  updateProfile: {
    __typename?: "Viewer";
    phoneNumber: Maybe<string>;
    address: Maybe<string>;
    city: Maybe<string>;
    areaCode: Maybe<string>;
    countryCode: Maybe<string>;
  };
};

export type UserUpdateProfilePictureMutationVariables = {
  profilePicture: Scalars["Upload"];
};

export type UserUpdateProfilePictureMutation = {
  __typename?: "Mutation";
  updateProfilePicture: { __typename?: "Viewer"; id: string };
};

type DiscriminateUnion<T, U> = T extends U ? T : never;

type RequireField<T, TNames extends string> = T &
  { [P in TNames]: (T & { [name: string]: never })[P] };

export type KanbanCollaborationSidebarVariables = KanbanCollaborationSidebarQueryVariables;
export type KanbanCollaborationSidebarViewer = NonNullable<
  KanbanCollaborationSidebarQuery["viewer"]
>;
export type KanbanCollaborationSidebarOrganization = (NonNullable<
  KanbanCollaborationSidebarQuery["viewer"]
>)["organization"];
export type KanbanCollaborationSidebarActivities = ActivityFeedItemFragment;
export const useKanbanCollaborationSidebar = useKanbanCollaborationSidebarQuery;
export type GetProjectsSuppliersDepartmentsVariables = GetProjectsSuppliersDepartmentsQueryVariables;
export type GetProjectsSuppliersDepartmentsViewer = NonNullable<
  GetProjectsSuppliersDepartmentsQuery["viewer"]
>;
export type GetProjectsSuppliersDepartmentsOrganization = (NonNullable<
  GetProjectsSuppliersDepartmentsQuery["viewer"]
>)["organization"];
export type GetProjectsSuppliersDepartmentsProjects = NonNullable<
  (NonNullable<
    GetProjectsSuppliersDepartmentsQuery["viewer"]
  >)["organization"]["projects"][0]
>;
export type GetProjectsSuppliersDepartmentsSuppliers = NonNullable<
  (NonNullable<
    GetProjectsSuppliersDepartmentsQuery["viewer"]
  >)["organization"]["suppliers"][0]
>;
export type GetProjectsSuppliersDepartmentsDefaultContactPerson = (NonNullable<
  (NonNullable<
    GetProjectsSuppliersDepartmentsQuery["viewer"]
  >)["organization"]["suppliers"][0]
>)["defaultContactPerson"];
export type GetProjectsSuppliersDepartmentsDepartments = NonNullable<
  (NonNullable<
    GetProjectsSuppliersDepartmentsQuery["viewer"]
  >)["organization"]["departments"][0]
>;
export const useGetProjectsSuppliersDepartments = useGetProjectsSuppliersDepartmentsQuery;
export type CreateOrUpdateSourcingEventVariables = CreateOrUpdateSourcingEventMutationVariables;
export type CreateOrUpdateSourcingEventCreateOrUpdateSourcingEvent = CreateOrUpdateSourcingEventMutation["createOrUpdateSourcingEvent"];
export const useCreateOrUpdateSourcingEvent = useCreateOrUpdateSourcingEventMutation;
export type SelectDefaultContactVariables = SelectDefaultContactMutationVariables;
export type SelectDefaultContactSetDefaultContactPerson = SelectDefaultContactMutation["setDefaultContactPerson"];
export const useSelectDefaultContact = useSelectDefaultContactMutation;
export type VersionVariables = VersionQueryVariables;
export type VersionVersion = VersionQuery["version"];
export const useVersion = useVersionQuery;
export type ActivitySubscriptionVariables = ActivitySubscriptionSubscriptionVariables;
export type ActivitySubscriptionActivity = ActivityFeedItemFragment;
export const useActivitySubscription = useActivitySubscriptionSubscription;
export type NotificationSubscriptionVariables = NotificationSubscriptionSubscriptionVariables;
export type NotificationSubscriptionNotification = NotificationSubscriptionSubscription["notification"];
export const useNotificationSubscription = useNotificationSubscriptionSubscription;
export type SendEmailToRequesterVariables = SendEmailToRequesterMutationVariables;
export const useSendEmailToRequester = useSendEmailToRequesterMutation;
export type SendEmailToSuppliersVariables = SendEmailToSuppliersMutationVariables;
export const useSendEmailToSuppliers = useSendEmailToSuppliersMutation;
export type SendSourcingRequestVariables = SendSourcingRequestMutationVariables;
export const useSendSourcingRequest = useSendSourcingRequestMutation;
export type ViewerVariables = ViewerQueryVariables;
export type ViewerViewer = NonNullable<ViewerQuery["viewer"]>;
export type ViewerOrganizations = NonNullable<
  (NonNullable<ViewerQuery["viewer"]>)["organizations"][0]
>;
export type ViewerCountry = (NonNullable<
  (NonNullable<ViewerQuery["viewer"]>)["organizations"][0]
>)["country"];
export type ViewerQuantityUnits = NonNullable<
  (NonNullable<
    (NonNullable<ViewerQuery["viewer"]>)["organizations"][0]
  >)["quantityUnits"][0]
>;
export type ViewerUsers = NonNullable<
  (NonNullable<
    (NonNullable<ViewerQuery["viewer"]>)["organizations"][0]
  >)["users"][0]
>;
export type ViewerCostCentres = NonNullable<
  (NonNullable<
    (NonNullable<ViewerQuery["viewer"]>)["organizations"][0]
  >)["costCentres"][0]
>;
export type ViewerDepartments = NonNullable<
  (NonNullable<
    (NonNullable<ViewerQuery["viewer"]>)["organizations"][0]
  >)["departments"][0]
>;
export type ViewerProjects = NonNullable<
  (NonNullable<
    (NonNullable<ViewerQuery["viewer"]>)["organizations"][0]
  >)["projects"][0]
>;
export type ViewerCountries = NonNullable<
  (NonNullable<ViewerQuery["viewer"]>)["countries"][0]
>;
export const useViewer = useViewerQuery;
export type GetPoExternalIdentifierVariables = GetPoExternalIdentifierQueryVariables;
export type GetPoExternalIdentifierViewer = NonNullable<
  GetPoExternalIdentifierQuery["viewer"]
>;
export type GetPoExternalIdentifierOrderByCode = (NonNullable<
  GetPoExternalIdentifierQuery["viewer"]
>)["orderByCode"];
export const useGetPoExternalIdentifier = useGetPoExternalIdentifierQuery;
export type AddOrganizationToUserVariables = AddOrganizationToUserMutationVariables;
export type AddOrganizationToUserAddOrganizationToUser = AddOrganizationToUserMutation["addOrganizationToUser"];
export const useAddOrganizationToUser = useAddOrganizationToUserMutation;
export type AdminCreateUserVariables = AdminCreateUserMutationVariables;
export type AdminCreateUserCreateUser = AdminCreateUserMutation["createUser"];
export const useAdminCreateUser = useAdminCreateUserMutation;
export type AdminUpdateCompanyLogoVariables = AdminUpdateCompanyLogoMutationVariables;
export type AdminUpdateCompanyLogoAdminUpdateOrganizationLogo = AdminUpdateCompanyLogoMutation["adminUpdateOrganizationLogo"];
export const useAdminUpdateCompanyLogo = useAdminUpdateCompanyLogoMutation;
export type AdminUpdateProfilePictureVariables = AdminUpdateProfilePictureMutationVariables;
export type AdminUpdateProfilePictureAdminUpdateProfilePicture = AdminUpdateProfilePictureMutation["adminUpdateProfilePicture"];
export const useAdminUpdateProfilePicture = useAdminUpdateProfilePictureMutation;
export type AdminUpdateUserVariables = AdminUpdateUserMutationVariables;
export type AdminUpdateUserUpdateUser = AdminUpdateUserMutation["updateUser"];
export type AdminUpdateUserOrganizations = NonNullable<
  AdminUpdateUserMutation["updateUser"]["organizations"][0]
>;
export const useAdminUpdateUser = useAdminUpdateUserMutation;
export type CreateOrganizationVariables = CreateOrganizationMutationVariables;
export type CreateOrganizationCreateOrganization = CreateOrganizationMutation["createOrganization"];
export const useCreateOrganization = useCreateOrganizationMutation;
export type OrganizationByIdVariables = OrganizationByIdQueryVariables;
export type OrganizationByIdAdmin = OrganizationByIdQuery["admin"];
export type OrganizationByIdOrganizationById = OrganizationByIdQuery["admin"]["organizationById"];
export type OrganizationByIdCountries = NonNullable<
  OrganizationByIdQuery["admin"]["countries"][0]
>;
export type OrganizationByIdCurrencies = NonNullable<
  OrganizationByIdQuery["admin"]["currencies"][0]
>;
export const useOrganizationById = useOrganizationByIdQuery;
export type OrganizationsManagementVariables = OrganizationsManagementQueryVariables;
export type OrganizationsManagementAdmin = OrganizationsManagementQuery["admin"];
export type OrganizationsManagementOrganizations = NonNullable<
  OrganizationsManagementQuery["admin"]["organizations"][0]
>;
export type OrganizationsManagementCountries = NonNullable<
  OrganizationsManagementQuery["admin"]["countries"][0]
>;
export type OrganizationsManagementCurrencies = NonNullable<
  OrganizationsManagementQuery["admin"]["currencies"][0]
>;
export const useOrganizationsManagement = useOrganizationsManagementQuery;
export type RemoveOrganizationFromUserVariables = RemoveOrganizationFromUserMutationVariables;
export type RemoveOrganizationFromUserRemoveOrganizationFromUser = RemoveOrganizationFromUserMutation["removeOrganizationFromUser"];
export const useRemoveOrganizationFromUser = useRemoveOrganizationFromUserMutation;
export type AdminUpdateOrganizationVariables = AdminUpdateOrganizationMutationVariables;
export type AdminUpdateOrganizationAdminUpdateOrganization = AdminUpdateOrganizationMutation["adminUpdateOrganization"];
export const useAdminUpdateOrganization = useAdminUpdateOrganizationMutation;
export type UserByIdVariables = UserByIdQueryVariables;
export type UserByIdAdmin = UserByIdQuery["admin"];
export type UserByIdUserById = UserByIdQuery["admin"]["userById"];
export type UserByIdOrganizations = NonNullable<
  UserByIdQuery["admin"]["userById"]["organizations"][0]
>;
export const useUserById = useUserByIdQuery;
export type UsersManagementVariables = UsersManagementQueryVariables;
export type UsersManagementAdmin = UsersManagementQuery["admin"];
export type UsersManagementUsers = NonNullable<
  UsersManagementQuery["admin"]["users"][0]
>;
export type UsersManagementOrganizations = NonNullable<
  (NonNullable<UsersManagementQuery["admin"]["users"][0]>)["organizations"][0]
>;
export type UsersManagement_Organizations = NonNullable<
  UsersManagementQuery["admin"]["organizations"][0]
>;
export type UsersManagementCountries = NonNullable<
  UsersManagementQuery["admin"]["countries"][0]
>;
export const useUsersManagement = useUsersManagementQuery;
export type ApprovalPortalViewVariables = ApprovalPortalViewQueryVariables;
export type ApprovalPortalViewViewer = NonNullable<
  ApprovalPortalViewQuery["viewer"]
>;
export type ApprovalPortalViewApprovalWorkflowsByStatus = NonNullable<
  (NonNullable<
    ApprovalPortalViewQuery["viewer"]
  >)["approvalWorkflowsByStatus"][0]
>;
export type ApprovalPortalViewOrganization = (NonNullable<
  ApprovalPortalViewQuery["viewer"]
>)["organization"];
export type ApprovalPortalViewCostCentres = NonNullable<
  (NonNullable<
    ApprovalPortalViewQuery["viewer"]
  >)["organization"]["costCentres"][0]
>;
export type ApprovalPortalViewDepartments = NonNullable<
  (NonNullable<
    ApprovalPortalViewQuery["viewer"]
  >)["organization"]["departments"][0]
>;
export type ApprovalPortalViewProjects = NonNullable<
  (NonNullable<
    ApprovalPortalViewQuery["viewer"]
  >)["organization"]["projects"][0]
>;
export type ApprovalPortalViewPurchaseRequests = NonNullable<
  (NonNullable<
    ApprovalPortalViewQuery["viewer"]
  >)["organization"]["purchaseRequests"][0]
>;
export type ApprovalPortalViewCardStatus = (NonNullable<
  (NonNullable<
    ApprovalPortalViewQuery["viewer"]
  >)["organization"]["purchaseRequests"][0]
>)["cardStatus"];
export type ApprovalPortalViewRequestor = UserInfoFragment;
export type ApprovalPortalViewAssignee = UserInfoFragment;
export type ApprovalPortalViewProject = ProjectInfoFragment;
export type ApprovalPortalViewSuppliers = SupplierInfoFragment;
export type ApprovalPortalViewDepartment = DepartmentInfoFragment;
export type ApprovalPortalViewItems = PurchaseRequestItemInfoFragment;
export type ApprovalPortalViewStatus = (NonNullable<
  (NonNullable<
    (NonNullable<
      ApprovalPortalViewQuery["viewer"]
    >)["organization"]["purchaseRequests"][0]
  >)["items"][0]
>)["status"];
export type ApprovalPortalViewLatestApprovalWorkflow = NonNullable<
  (NonNullable<
    (NonNullable<
      ApprovalPortalViewQuery["viewer"]
    >)["organization"]["purchaseRequests"][0]
  >)["latestApprovalWorkflow"]
>;
export const useApprovalPortalView = useApprovalPortalViewQuery;
export type UpdateApprovalRequestVariables = UpdateApprovalRequestMutationVariables;
export type UpdateApprovalRequestUpdateApprovalRequest = UpdateApprovalRequestMutation["updateApprovalRequest"];
export const useUpdateApprovalRequest = useUpdateApprovalRequestMutation;
export type ViewerApprovalWorkflowsVariables = ViewerApprovalWorkflowsQueryVariables;
export type ViewerApprovalWorkflowsViewer = NonNullable<
  ViewerApprovalWorkflowsQuery["viewer"]
>;
export type ViewerApprovalWorkflowsApprovalWorkflowsByStatus = NonNullable<
  (NonNullable<
    ViewerApprovalWorkflowsQuery["viewer"]
  >)["approvalWorkflowsByStatus"][0]
>;
export type ViewerApprovalWorkflowsRequestor = NonNullable<
  (NonNullable<
    (NonNullable<
      ViewerApprovalWorkflowsQuery["viewer"]
    >)["approvalWorkflowsByStatus"][0]
  >)["requestor"]
>;
export type ViewerApprovalWorkflowsPurchaseRequest = NonNullable<
  (NonNullable<
    (NonNullable<
      ViewerApprovalWorkflowsQuery["viewer"]
    >)["approvalWorkflowsByStatus"][0]
  >)["purchaseRequest"]
>;
export type ViewerApprovalWorkflowsAttachments = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          ViewerApprovalWorkflowsQuery["viewer"]
        >)["approvalWorkflowsByStatus"][0]
      >)["purchaseRequest"]
    >)["attachments"]
  >)[0]
>;
export type ViewerApprovalWorkflowsDepartment = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<
        ViewerApprovalWorkflowsQuery["viewer"]
      >)["approvalWorkflowsByStatus"][0]
    >)["purchaseRequest"]
  >)["department"]
>;
export type ViewerApprovalWorkflowsProject = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<
        ViewerApprovalWorkflowsQuery["viewer"]
      >)["approvalWorkflowsByStatus"][0]
    >)["purchaseRequest"]
  >)["project"]
>;
export type ViewerApprovalWorkflowsCostCentre = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<
        ViewerApprovalWorkflowsQuery["viewer"]
      >)["approvalWorkflowsByStatus"][0]
    >)["purchaseRequest"]
  >)["costCentre"]
>;
export type ViewerApprovalWorkflowsItems = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<
        ViewerApprovalWorkflowsQuery["viewer"]
      >)["approvalWorkflowsByStatus"][0]
    >)["purchaseRequest"]
  >)["items"][0]
>;
export type ViewerApprovalWorkflows_Attachments = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          (NonNullable<
            ViewerApprovalWorkflowsQuery["viewer"]
          >)["approvalWorkflowsByStatus"][0]
        >)["purchaseRequest"]
      >)["items"][0]
    >)["attachments"]
  >)[0]
>;
export type ViewerApprovalWorkflowsPurchaseOrder = NonNullable<
  (NonNullable<
    (NonNullable<
      ViewerApprovalWorkflowsQuery["viewer"]
    >)["approvalWorkflowsByStatus"][0]
  >)["purchaseOrder"]
>;
export type ViewerApprovalWorkflowsApprovalRequests = NonNullable<
  (NonNullable<
    (NonNullable<
      ViewerApprovalWorkflowsQuery["viewer"]
    >)["approvalWorkflowsByStatus"][0]
  >)["approvalRequests"][0]
>;
export type ViewerApprovalWorkflowsApprover = (NonNullable<
  (NonNullable<
    (NonNullable<
      ViewerApprovalWorkflowsQuery["viewer"]
    >)["approvalWorkflowsByStatus"][0]
  >)["approvalRequests"][0]
>)["approver"];
export const useViewerApprovalWorkflows = useViewerApprovalWorkflowsQuery;
export type OrganizationApprovalWorkflowsByStatusVariables = OrganizationApprovalWorkflowsByStatusQueryVariables;
export type OrganizationApprovalWorkflowsByStatusViewer = NonNullable<
  OrganizationApprovalWorkflowsByStatusQuery["viewer"]
>;
export type OrganizationApprovalWorkflowsByStatusOrganization = (NonNullable<
  OrganizationApprovalWorkflowsByStatusQuery["viewer"]
>)["organization"];
export type OrganizationApprovalWorkflowsByStatusApprovalWorkflowsByStatus = NonNullable<
  (NonNullable<
    OrganizationApprovalWorkflowsByStatusQuery["viewer"]
  >)["organization"]["approvalWorkflowsByStatus"][0]
>;
export type OrganizationApprovalWorkflowsByStatusApprovalWorkflowStats = (NonNullable<
  (NonNullable<
    OrganizationApprovalWorkflowsByStatusQuery["viewer"]
  >)["organization"]["approvalWorkflowsByStatus"][0]
>)["approvalWorkflowStats"];
export type OrganizationApprovalWorkflowsByStatusApprovalRequests = NonNullable<
  (NonNullable<
    (NonNullable<
      OrganizationApprovalWorkflowsByStatusQuery["viewer"]
    >)["organization"]["approvalWorkflowsByStatus"][0]
  >)["approvalRequests"][0]
>;
export type OrganizationApprovalWorkflowsByStatusPurchaseRequest = NonNullable<
  (NonNullable<
    (NonNullable<
      OrganizationApprovalWorkflowsByStatusQuery["viewer"]
    >)["organization"]["approvalWorkflowsByStatus"][0]
  >)["purchaseRequest"]
>;
export type OrganizationApprovalWorkflowsByStatusCreator = (NonNullable<
  (NonNullable<
    OrganizationApprovalWorkflowsByStatusQuery["viewer"]
  >)["organization"]["approvalWorkflowsByStatus"][0]
>)["creator"];
export const useOrganizationApprovalWorkflowsByStatus = useOrganizationApprovalWorkflowsByStatusQuery;
export type CreateApiKeyVariables = CreateApiKeyMutationVariables;
export type CreateApiKeyCreateApiKey = CreateApiKeyMutation["createApiKey"];
export const useCreateApiKey = useCreateApiKeyMutation;
export type CreateOrganizationUserVariables = CreateOrganizationUserMutationVariables;
export type CreateOrganizationUserCreateOrganizationUser = CreateOrganizationUserMutation["createOrganizationUser"];
export const useCreateOrganizationUser = useCreateOrganizationUserMutation;
export type CreateUserVariables = CreateUserMutationVariables;
export type CreateUserCreateUser = CreateUserMutation["createUser"];
export const useCreateUser = useCreateUserMutation;
export type DeleteApiKeyVariables = DeleteApiKeyMutationVariables;
export type DeleteApiKeyDeleteApiKey = DeleteApiKeyMutation["deleteApiKey"];
export const useDeleteApiKey = useDeleteApiKeyMutation;
export type DeleteCostCentreVariables = DeleteCostCentreMutationVariables;
export type DeleteCostCentreDeleteCostCentre = DeleteCostCentreMutation["deleteCostCentre"];
export const useDeleteCostCentre = useDeleteCostCentreMutation;
export type DeleteDepartmentVariables = DeleteDepartmentMutationVariables;
export type DeleteDepartmentDeleteDepartment = DeleteDepartmentMutation["deleteDepartment"];
export const useDeleteDepartment = useDeleteDepartmentMutation;
export type DeleteProjectVariables = DeleteProjectMutationVariables;
export type DeleteProjectDeleteProject = DeleteProjectMutation["deleteProject"];
export const useDeleteProject = useDeleteProjectMutation;
export type OrganizationAddressesVariables = OrganizationAddressesQueryVariables;
export type OrganizationAddressesViewer = NonNullable<
  OrganizationAddressesQuery["viewer"]
>;
export type OrganizationAddressesOrganization = (NonNullable<
  OrganizationAddressesQuery["viewer"]
>)["organization"];
export type OrganizationAddressesAddresses = AddressInfoFragment;
export const useOrganizationAddresses = useOrganizationAddressesQuery;
export type AddressInfoCountry = AddressInfoFragment["country"];
export type OrganizationApiKeysVariables = OrganizationApiKeysQueryVariables;
export type OrganizationApiKeysViewer = NonNullable<
  OrganizationApiKeysQuery["viewer"]
>;
export type OrganizationApiKeysOrganization = (NonNullable<
  OrganizationApiKeysQuery["viewer"]
>)["organization"];
export type OrganizationApiKeysApiKeys = NonNullable<
  (NonNullable<
    OrganizationApiKeysQuery["viewer"]
  >)["organization"]["apiKeys"][0]
>;
export const useOrganizationApiKeys = useOrganizationApiKeysQuery;
export type OrganizationCostCentreByIdVariables = OrganizationCostCentreByIdQueryVariables;
export type OrganizationCostCentreByIdViewer = NonNullable<
  OrganizationCostCentreByIdQuery["viewer"]
>;
export type OrganizationCostCentreByIdOrganization = (NonNullable<
  OrganizationCostCentreByIdQuery["viewer"]
>)["organization"];
export type OrganizationCostCentreByIdCostCentreById = (NonNullable<
  OrganizationCostCentreByIdQuery["viewer"]
>)["organization"]["costCentreById"];
export const useOrganizationCostCentreById = useOrganizationCostCentreByIdQuery;
export type OrganizationDepartmentByIdVariables = OrganizationDepartmentByIdQueryVariables;
export type OrganizationDepartmentByIdViewer = NonNullable<
  OrganizationDepartmentByIdQuery["viewer"]
>;
export type OrganizationDepartmentByIdOrganization = (NonNullable<
  OrganizationDepartmentByIdQuery["viewer"]
>)["organization"];
export type OrganizationDepartmentByIdDepartmentById = (NonNullable<
  OrganizationDepartmentByIdQuery["viewer"]
>)["organization"]["departmentById"];
export type OrganizationDepartmentByIdShippingAddress = AddressInfoFragment;
export type OrganizationDepartmentByIdInvoiceAddress = AddressInfoFragment;
export const useOrganizationDepartmentById = useOrganizationDepartmentByIdQuery;
export type OrganizationProjectByIdVariables = OrganizationProjectByIdQueryVariables;
export type OrganizationProjectByIdViewer = NonNullable<
  OrganizationProjectByIdQuery["viewer"]
>;
export type OrganizationProjectByIdOrganization = (NonNullable<
  OrganizationProjectByIdQuery["viewer"]
>)["organization"];
export type OrganizationProjectByIdProjectById = (NonNullable<
  OrganizationProjectByIdQuery["viewer"]
>)["organization"]["projectById"];
export const useOrganizationProjectById = useOrganizationProjectByIdQuery;
export type OrganizationUserByIdVariables = OrganizationUserByIdQueryVariables;
export type OrganizationUserByIdViewer = NonNullable<
  OrganizationUserByIdQuery["viewer"]
>;
export type OrganizationUserByIdOrganization = (NonNullable<
  OrganizationUserByIdQuery["viewer"]
>)["organization"];
export type OrganizationUserByIdDepartments = NonNullable<
  (NonNullable<
    OrganizationUserByIdQuery["viewer"]
  >)["organization"]["departments"][0]
>;
export type OrganizationUserByIdProjects = NonNullable<
  (NonNullable<
    OrganizationUserByIdQuery["viewer"]
  >)["organization"]["projects"][0]
>;
export type OrganizationUserByIdCostCentres = NonNullable<
  (NonNullable<
    OrganizationUserByIdQuery["viewer"]
  >)["organization"]["costCentres"][0]
>;
export type OrganizationUserByIdUserById = (NonNullable<
  OrganizationUserByIdQuery["viewer"]
>)["organization"]["userById"];
export type OrganizationUserByIdDepartment = NonNullable<
  (NonNullable<
    OrganizationUserByIdQuery["viewer"]
  >)["organization"]["userById"]["department"]
>;
export type OrganizationUserByIdProject = NonNullable<
  (NonNullable<
    OrganizationUserByIdQuery["viewer"]
  >)["organization"]["userById"]["project"]
>;
export type OrganizationUserByIdCostCentre = NonNullable<
  (NonNullable<
    OrganizationUserByIdQuery["viewer"]
  >)["organization"]["userById"]["costCentre"]
>;
export const useOrganizationUserById = useOrganizationUserByIdQuery;
export type UpdateCostCentreVariables = UpdateCostCentreMutationVariables;
export type UpdateCostCentreUpdateCostCentre = UpdateCostCentreMutation["updateCostCentre"];
export const useUpdateCostCentre = useUpdateCostCentreMutation;
export type UpdateDepartmentVariables = UpdateDepartmentMutationVariables;
export type UpdateDepartmentUpdateDepartment = UpdateDepartmentMutation["updateDepartment"];
export const useUpdateDepartment = useUpdateDepartmentMutation;
export type UpdateOrganizationPoTemplateVariables = UpdateOrganizationPoTemplateMutationVariables;
export type UpdateOrganizationPoTemplateUpdateOrganizationPoTemplate = UpdateOrganizationPoTemplateMutation["updateOrganizationPoTemplate"];
export const useUpdateOrganizationPoTemplate = useUpdateOrganizationPoTemplateMutation;
export type UpdateOrganizationUserVariables = UpdateOrganizationUserMutationVariables;
export type UpdateOrganizationUserUpdateOrganizationUser = UpdateOrganizationUserMutation["updateOrganizationUser"];
export const useUpdateOrganizationUser = useUpdateOrganizationUserMutation;
export type UpdateProjectVariables = UpdateProjectMutationVariables;
export type UpdateProjectUpdateProject = UpdateProjectMutation["updateProject"];
export const useUpdateProject = useUpdateProjectMutation;
export type UpdateWhitelistedDomainsVariables = UpdateWhitelistedDomainsMutationVariables;
export type UpdateWhitelistedDomainsUpdateWhitelistedDomains = UpdateWhitelistedDomainsMutation["updateWhitelistedDomains"];
export const useUpdateWhitelistedDomains = useUpdateWhitelistedDomainsMutation;
export type UploadOrganizationLogoVariables = UploadOrganizationLogoMutationVariables;
export type UploadOrganizationLogoUploadOrganizationLogo = UploadOrganizationLogoMutation["uploadOrganizationLogo"];
export const useUploadOrganizationLogo = useUploadOrganizationLogoMutation;
export type UserUpdateOrganizationVariables = UserUpdateOrganizationMutationVariables;
export type UserUpdateOrganizationUpdateOrganization = UserUpdateOrganizationMutation["updateOrganization"];
export type UserUpdateOrganizationCountry = UserUpdateOrganizationMutation["updateOrganization"]["country"];
export const useUserUpdateOrganization = useUserUpdateOrganizationMutation;
export type CreatePurchaseRequestVariables = CreatePurchaseRequestMutationVariables;
export type CreatePurchaseRequestCreatePurchaseRequest = CreatePurchaseRequestMutation["createPurchaseRequest"];
export const useCreatePurchaseRequest = useCreatePurchaseRequestMutation;
export type CreatePurchaseRequestProductVariables = CreatePurchaseRequestProductMutationVariables;
export type CreatePurchaseRequestProductCreatePurchaseRequestProduct = CreatePurchaseRequestProductMutation["createPurchaseRequestProduct"];
export const useCreatePurchaseRequestProduct = useCreatePurchaseRequestProductMutation;
export type CreatePurchaseRequestServiceVariables = CreatePurchaseRequestServiceMutationVariables;
export type CreatePurchaseRequestServiceCreatePurchaseRequestService = CreatePurchaseRequestServiceMutation["createPurchaseRequestService"];
export const useCreatePurchaseRequestService = useCreatePurchaseRequestServiceMutation;
export type CreateQuantityUnitVariables = CreateQuantityUnitMutationVariables;
export type CreateQuantityUnitCreateQuantityUnit = CreateQuantityUnitMutation["createQuantityUnit"];
export const useCreateQuantityUnit = useCreateQuantityUnitMutation;
export type UploadProductExcelVariables = UploadProductExcelMutationVariables;
export type UploadProductExcelUploadProductExcel = UploadProductExcelMutation["uploadProductExcel"];
export type UploadProductExcelRows = NonNullable<
  UploadProductExcelMutation["uploadProductExcel"]["rows"][0]
>;
export type UploadProductExcelErrors = (NonNullable<
  UploadProductExcelMutation["uploadProductExcel"]["rows"][0]
>)["errors"];
export type UploadProductExcelPurchaseRequest = NonNullable<
  UploadProductExcelMutation["uploadProductExcel"]["purchaseRequest"]
>;
export const useUploadProductExcel = useUploadProductExcelMutation;
export type ViewerOrganizationVariables = ViewerOrganizationQueryVariables;
export type ViewerOrganizationViewer = NonNullable<
  ViewerOrganizationQuery["viewer"]
>;
export type ViewerOrganizationOrganization = (NonNullable<
  ViewerOrganizationQuery["viewer"]
>)["organization"];
export type ViewerOrganizationQuantityUnits = NonNullable<
  (NonNullable<
    ViewerOrganizationQuery["viewer"]
  >)["organization"]["quantityUnits"][0]
>;
export type ViewerOrganizationProjects = NonNullable<
  (NonNullable<
    ViewerOrganizationQuery["viewer"]
  >)["organization"]["projects"][0]
>;
export type ViewerOrganizationDepartments = NonNullable<
  (NonNullable<
    ViewerOrganizationQuery["viewer"]
  >)["organization"]["departments"][0]
>;
export type ViewerOrganizationUsers = NonNullable<
  (NonNullable<ViewerOrganizationQuery["viewer"]>)["organization"]["users"][0]
>;
export const useViewerOrganization = useViewerOrganizationQuery;
export type CreatePurchaseRequestViewVariables = CreatePurchaseRequestViewQueryVariables;
export type CreatePurchaseRequestViewViewer = NonNullable<
  CreatePurchaseRequestViewQuery["viewer"]
>;
export type CreatePurchaseRequestViewOrganization = (NonNullable<
  CreatePurchaseRequestViewQuery["viewer"]
>)["organization"];
export type CreatePurchaseRequestViewUsers = UserInfoFragment;
export type CreatePurchaseRequestViewSuppliers = NonNullable<
  (NonNullable<
    CreatePurchaseRequestViewQuery["viewer"]
  >)["organization"]["suppliers"][0]
>;
export type CreatePurchaseRequestViewDefaultContactPerson = (NonNullable<
  (NonNullable<
    CreatePurchaseRequestViewQuery["viewer"]
  >)["organization"]["suppliers"][0]
>)["defaultContactPerson"];
export type CreatePurchaseRequestViewDepartments = NonNullable<
  (NonNullable<
    CreatePurchaseRequestViewQuery["viewer"]
  >)["organization"]["departments"][0]
>;
export type CreatePurchaseRequestViewCostCentres = NonNullable<
  (NonNullable<
    CreatePurchaseRequestViewQuery["viewer"]
  >)["organization"]["costCentres"][0]
>;
export type CreatePurchaseRequestViewProjects = NonNullable<
  (NonNullable<
    CreatePurchaseRequestViewQuery["viewer"]
  >)["organization"]["projects"][0]
>;
export type CreatePurchaseRequestViewQuantityUnits = NonNullable<
  (NonNullable<
    CreatePurchaseRequestViewQuery["viewer"]
  >)["organization"]["quantityUnits"][0]
>;
export const useCreatePurchaseRequestView = useCreatePurchaseRequestViewQuery;
export type GetItemByIdVariables = GetItemByIdQueryVariables;
export type GetItemByIdViewer = NonNullable<GetItemByIdQuery["viewer"]>;
export type GetItemByIdPurchaseRequestItemById = (NonNullable<
  GetItemByIdQuery["viewer"]
>)["purchaseRequestItemById"];
export type GetItemByIdAttachments = NonNullable<
  (NonNullable<
    (NonNullable<
      GetItemByIdQuery["viewer"]
    >)["purchaseRequestItemById"]["attachments"]
  >)[0]
>;
export const useGetItemById = useGetItemByIdQuery;
export type RemovePurchaseRequestItemAttachmentVariables = RemovePurchaseRequestItemAttachmentMutationVariables;
export type RemovePurchaseRequestItemAttachmentRemovePurchaseRequestItemAttachment = RemovePurchaseRequestItemAttachmentMutation["removePurchaseRequestItemAttachment"];
export const useRemovePurchaseRequestItemAttachment = useRemovePurchaseRequestItemAttachmentMutation;
export type UpdatePurchaseRequestProductVariables = UpdatePurchaseRequestProductMutationVariables;
export type UpdatePurchaseRequestProductUpdatePurchaseRequestProduct = UpdatePurchaseRequestProductMutation["updatePurchaseRequestProduct"];
export const useUpdatePurchaseRequestProduct = useUpdatePurchaseRequestProductMutation;
export type UpdatePurchaseRequestServiceVariables = UpdatePurchaseRequestServiceMutationVariables;
export type UpdatePurchaseRequestServiceUpdatePurchaseRequestService = UpdatePurchaseRequestServiceMutation["updatePurchaseRequestService"];
export const useUpdatePurchaseRequestService = useUpdatePurchaseRequestServiceMutation;
export type EmailViewVariables = EmailViewQueryVariables;
export type EmailViewViewer = NonNullable<EmailViewQuery["viewer"]>;
export type EmailViewOrganization = (NonNullable<
  EmailViewQuery["viewer"]
>)["organization"];
export type EmailViewActivityById = ActivityFeedItemFragment;
export const useEmailView = useEmailViewQuery;
export type TimerSubscriptionVariables = TimerSubscriptionSubscriptionVariables;
export const useTimerSubscription = useTimerSubscriptionSubscription;
export type UpdateProfilePictureVariables = UpdateProfilePictureMutationVariables;
export type UpdateProfilePictureUpdateProfilePicture = UpdateProfilePictureMutation["updateProfilePicture"];
export const useUpdateProfilePicture = useUpdateProfilePictureMutation;
export type ActivityFeedItemAttachments = NonNullable<
  (NonNullable<ActivityFeedItemFragment["attachments"]>)[0]
>;
export type ActivityFeedItemData = ActivityFeedItemFragment["data"];
export type ActivityFeedItemAssignInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "ASSIGN" }
>;
export type ActivityFeedItemItemInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "ASSIGN" }
>)["itemInfo"];
export type ActivityFeedItemAssignerInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "ASSIGN" }
>)["assignerInfo"];
export type ActivityFeedItemAssigneeInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<ActivityFeedItemFragment["data"], "__typename">,
    { __typename: "ASSIGN" }
  >)["assigneeInfo"]
>;
export type ActivityFeedItemDrag_CardInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "DRAG_CARD" }
>;
export type ActivityFeedItemRequestorInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "DRAG_CARD" }
>)["requestorInfo"];
export type ActivityFeedItem_AssigneeInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<ActivityFeedItemFragment["data"], "__typename">,
    { __typename: "DRAG_CARD" }
  >)["assigneeInfo"]
>;
export type ActivityFeedItemCommentInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "COMMENT" }
>;
export type ActivityFeedItemUser = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "COMMENT" }
>)["user"];
export type ActivityFeedItemRelativeInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "COMMENT" }
>)["relativeInfo"];
export type ActivityFeedItemActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<ActivityFeedItemFragment["data"], "__typename">,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type ActivityFeedItemActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<ActivityFeedItemFragment["data"], "__typename">,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type ActivityFeedItemActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<ActivityFeedItemFragment["data"], "__typename">,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type ActivityFeedItemAdd_ItemInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "ADD_ITEM" }
>;
export type ActivityFeedItemCreator = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "ADD_ITEM" }
>)["creator"];
export type ActivityFeedItemAdd_AttachmentInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "ADD_ATTACHMENT" }
>;
export type ActivityFeedItemEmail_Inbound_MentionInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "EMAIL_INBOUND_MENTION" }
>;
export type ActivityFeedItem_User = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["user"];
export type ActivityFeedItem_RelativeInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["relativeInfo"];
export type ActivityFeedItem_ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<ActivityFeedItemFragment["data"], "__typename">,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type ActivityFeedItem_ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<ActivityFeedItemFragment["data"], "__typename">,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type ActivityFeedItem_ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<ActivityFeedItemFragment["data"], "__typename">,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type ActivityFeedItemEmailInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["emailInfo"];
export type ActivityFeedItemEmail_Inbound_SupplierInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>;
export type ActivityFeedItemSupplierInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>)["supplierInfo"];
export type ActivityFeedItem_EmailInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>)["emailInfo"];
export type ActivityFeedItemEmail_Inbound_RequesterInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "EMAIL_INBOUND_REQUESTER" }
>;
export type ActivityFeedItemEmail_Outbound_MentionInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "EMAIL_OUTBOUND_MENTION" }
>;
export type ActivityFeedItemEmail_Outbound_SupplierInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>;
export type ActivityFeedItemRfxInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<ActivityFeedItemFragment["data"], "__typename">,
    { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
  >)["rfxInfo"]
>;
export type ActivityFeedItemOrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<ActivityFeedItemFragment["data"], "__typename">,
    { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
  >)["orderInfo"]
>;
export type ActivityFeedItem_SupplierInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>)["supplierInfo"];
export type ActivityFeedItem_Creator = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>)["creator"];
export type ActivityFeedItemCreate_Approval_WorkflowInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>;
export type ActivityFeedItemCreatorInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["creatorInfo"];
export type ActivityFeedItemApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowInfo"];
export type ActivityFeedItemApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowStats"];
export type ActivityFeedItemUpdate_Approval_WorkflowInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>;
export type ActivityFeedItem_CreatorInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["creatorInfo"];
export type ActivityFeedItemRelativeCardInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["relativeCardInfo"];
export type ActivityFeedItem_ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowInfo"];
export type ActivityFeedItem_ApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowStats"];
export type ActivityFeedItemCreate_Approval_RequestInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>;
export type ActivityFeedItemApproverInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approverInfo"];
export type ActivityFeedItemApprovalRequestInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approvalRequestInfo"];
export type ActivityFeedItem__ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approvalWorkflowInfo"];
export type ActivityFeedItem__ApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approvalWorkflowStats"];
export type ActivityFeedItemUpdate_Approval_RequestInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>;
export type ActivityFeedItem_ApproverInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approverInfo"];
export type ActivityFeedItem_ApprovalRequestInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approvalRequestInfo"];
export type ActivityFeedItem___ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approvalWorkflowInfo"];
export type ActivityFeedItem___ApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approvalWorkflowStats"];
export type ActivityFeedItemReplyInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "REPLY" }
>;
export type ActivityFeedItem__User = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "REPLY" }
>)["user"];
export type ActivityFeedItem__RelativeInfo = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "REPLY" }
>)["relativeInfo"];
export type ActivityFeedItem__ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<ActivityFeedItemFragment["data"], "__typename">,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type ActivityFeedItem__ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<ActivityFeedItemFragment["data"], "__typename">,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type ActivityFeedItem__ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<ActivityFeedItemFragment["data"], "__typename">,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type ActivityFeedItemAdd_ItemsInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "ADD_ITEMS" }
>;
export type ActivityFeedItem__Creator = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "ADD_ITEMS" }
>)["creator"];
export type ActivityFeedItemCreate_Purchase_RequestInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_PURCHASE_REQUEST" }
>;
export type ActivityFeedItem___Creator = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_PURCHASE_REQUEST" }
>)["creator"];
export type ActivityFeedItemCreate_Purchase_Request_ProductInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_PURCHASE_REQUEST_PRODUCT" }
>;
export type ActivityFeedItem____Creator = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_PURCHASE_REQUEST_PRODUCT" }
>)["creator"];
export type ActivityFeedItemCreate_Purchase_Request_ServiceInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_PURCHASE_REQUEST_SERVICE" }
>;
export type ActivityFeedItem_____Creator = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_PURCHASE_REQUEST_SERVICE" }
>)["creator"];
export type ActivityFeedItemDrag_ItemInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "DRAG_ITEM" }
>;
export type ActivityFeedItemCreate_SupplierInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_SUPPLIER" }
>;
export type ActivityFeedItem______Creator = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_SUPPLIER" }
>)["creator"];
export type ActivityFeedItemUpdate_SupplierInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "UPDATE_SUPPLIER" }
>;
export type ActivityFeedItem_______Creator = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "UPDATE_SUPPLIER" }
>)["creator"];
export type ActivityFeedItemChangedFields = NonNullable<
  (DiscriminateUnion<
    RequireField<ActivityFeedItemFragment["data"], "__typename">,
    { __typename: "UPDATE_SUPPLIER" }
  >)["changedFields"][0]
>;
export type ActivityFeedItemUpdate_Order_Received_QuantitiesInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
>;
export type ActivityFeedItem___User = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
>)["user"];
export type ActivityFeedItem_OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<ActivityFeedItemFragment["data"], "__typename">,
    { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
  >)["orderInfo"]
>;
export type ActivityFeedItemCreate_Invoice_From_OrderInlineFragment = DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_INVOICE_FROM_ORDER" }
>;
export type ActivityFeedItem____User = (DiscriminateUnion<
  RequireField<ActivityFeedItemFragment["data"], "__typename">,
  { __typename: "CREATE_INVOICE_FROM_ORDER" }
>)["user"];
export type ActivityFeedItem__OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<ActivityFeedItemFragment["data"], "__typename">,
    { __typename: "CREATE_INVOICE_FROM_ORDER" }
  >)["orderInfo"]
>;
export type ActivityFeedItemAuthor = NonNullable<
  ActivityFeedItemFragment["author"]
>;
export type ActivityFeedItemReplies = NonNullable<
  ActivityFeedItemFragment["replies"][0]
>;
export type ActivityFeedItem_Attachments = NonNullable<
  (NonNullable<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["attachments"]
  >)[0]
>;
export type ActivityFeedItem_Data = (NonNullable<
  ActivityFeedItemFragment["replies"][0]
>)["data"];
export type ActivityFeedItem_AssignInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>;
export type ActivityFeedItem_ItemInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>)["itemInfo"];
export type ActivityFeedItem_AssignerInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>)["assignerInfo"];
export type ActivityFeedItem__AssigneeInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
      "__typename"
    >,
    { __typename: "ASSIGN" }
  >)["assigneeInfo"]
>;
export type ActivityFeedItem_Drag_CardInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "DRAG_CARD" }
>;
export type ActivityFeedItem_RequestorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "DRAG_CARD" }
>)["requestorInfo"];
export type ActivityFeedItem___AssigneeInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
      "__typename"
    >,
    { __typename: "DRAG_CARD" }
  >)["assigneeInfo"]
>;
export type ActivityFeedItem_CommentInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>;
export type ActivityFeedItem_____User = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>)["user"];
export type ActivityFeedItem___RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>)["relativeInfo"];
export type ActivityFeedItem___ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type ActivityFeedItem___ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type ActivityFeedItem___ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type ActivityFeedItem_Add_ItemInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEM" }
>;
export type ActivityFeedItem________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEM" }
>)["creator"];
export type ActivityFeedItem_Add_AttachmentInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "ADD_ATTACHMENT" }
>;
export type ActivityFeedItem_Email_Inbound_MentionInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>;
export type ActivityFeedItem______User = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["user"];
export type ActivityFeedItem____RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["relativeInfo"];
export type ActivityFeedItem____ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type ActivityFeedItem____ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type ActivityFeedItem____ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type ActivityFeedItem__EmailInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["emailInfo"];
export type ActivityFeedItem_Email_Inbound_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>;
export type ActivityFeedItem__SupplierInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>)["supplierInfo"];
export type ActivityFeedItem___EmailInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>)["emailInfo"];
export type ActivityFeedItem_Email_Inbound_RequesterInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_REQUESTER" }
>;
export type ActivityFeedItem_Email_Outbound_MentionInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_MENTION" }
>;
export type ActivityFeedItem_Email_Outbound_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>;
export type ActivityFeedItem_RfxInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
      "__typename"
    >,
    { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
  >)["rfxInfo"]
>;
export type ActivityFeedItem___OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
      "__typename"
    >,
    { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
  >)["orderInfo"]
>;
export type ActivityFeedItem___SupplierInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>)["supplierInfo"];
export type ActivityFeedItem_________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>)["creator"];
export type ActivityFeedItem_ReplyInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>;
export type ActivityFeedItem_______User = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>)["user"];
export type ActivityFeedItem_____RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>)["relativeInfo"];
export type ActivityFeedItem_____ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type ActivityFeedItem_____ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type ActivityFeedItem_____ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type ActivityFeedItem_Add_ItemsInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEMS" }
>;
export type ActivityFeedItem__________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEMS" }
>)["creator"];
export type ActivityFeedItem_Create_Purchase_RequestInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST" }
>;
export type ActivityFeedItem___________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST" }
>)["creator"];
export type ActivityFeedItem_Create_Purchase_Request_ProductInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_PRODUCT" }
>;
export type ActivityFeedItem____________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_PRODUCT" }
>)["creator"];
export type ActivityFeedItem_Create_Purchase_Request_ServiceInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_SERVICE" }
>;
export type ActivityFeedItem_____________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_SERVICE" }
>)["creator"];
export type ActivityFeedItem_Drag_ItemInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "DRAG_ITEM" }
>;
export type ActivityFeedItem_Create_Approval_WorkflowInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>;
export type ActivityFeedItem__CreatorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["creatorInfo"];
export type ActivityFeedItem____ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowInfo"];
export type ActivityFeedItem_Create_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_SUPPLIER" }
>;
export type ActivityFeedItem______________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_SUPPLIER" }
>)["creator"];
export type ActivityFeedItem_Update_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_SUPPLIER" }
>;
export type ActivityFeedItem_______________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_SUPPLIER" }
>)["creator"];
export type ActivityFeedItem_ChangedFields = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
      "__typename"
    >,
    { __typename: "UPDATE_SUPPLIER" }
  >)["changedFields"][0]
>;
export type ActivityFeedItem_Update_Order_Received_QuantitiesInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
>;
export type ActivityFeedItem________User = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
>)["user"];
export type ActivityFeedItem____OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
      "__typename"
    >,
    { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
  >)["orderInfo"]
>;
export type ActivityFeedItem_Create_Invoice_From_OrderInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_INVOICE_FROM_ORDER" }
>;
export type ActivityFeedItem_________User = (DiscriminateUnion<
  RequireField<
    (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_INVOICE_FROM_ORDER" }
>)["user"];
export type ActivityFeedItem_____OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<ActivityFeedItemFragment["replies"][0]>)["data"],
      "__typename"
    >,
    { __typename: "CREATE_INVOICE_FROM_ORDER" }
  >)["orderInfo"]
>;
export type ActivityFeedItem_Author = NonNullable<
  (NonNullable<ActivityFeedItemFragment["replies"][0]>)["author"]
>;
export type ActivityFeedItem_Replies = NonNullable<
  (NonNullable<ActivityFeedItemFragment["replies"][0]>)["replies"][0]
>;
export type AddPurchaseRequestItemsToOrderVariables = AddPurchaseRequestItemsToOrderMutationVariables;
export type AddPurchaseRequestItemsToOrderAddPurchaseRequestItemsToOrder = AddPurchaseRequestItemsToOrderMutation["addPurchaseRequestItemsToOrder"];
export const useAddPurchaseRequestItemsToOrder = useAddPurchaseRequestItemsToOrderMutation;
export type AddPurchaseRequestItemsToRfxVariables = AddPurchaseRequestItemsToRfxMutationVariables;
export type AddPurchaseRequestItemsToRfxAddPurchaseRequestItemsToRfx = AddPurchaseRequestItemsToRfxMutation["addPurchaseRequestItemsToRfx"];
export const useAddPurchaseRequestItemsToRfx = useAddPurchaseRequestItemsToRfxMutation;
export type AssignCardVariables = AssignCardMutationVariables;
export const useAssignCard = useAssignCardMutation;
export type CreateOrderFromPurchaseRequestVariables = CreateOrderFromPurchaseRequestMutationVariables;
export type CreateOrderFromPurchaseRequestCreateOrderFromPurchaseRequest = CreateOrderFromPurchaseRequestMutation["createOrderFromPurchaseRequest"];
export const useCreateOrderFromPurchaseRequest = useCreateOrderFromPurchaseRequestMutation;
export type CreateOrderFromPurchaseRequestItemsVariables = CreateOrderFromPurchaseRequestItemsMutationVariables;
export type CreateOrderFromPurchaseRequestItemsCreateOrderFromPurchaseRequestItems = CreateOrderFromPurchaseRequestItemsMutation["createOrderFromPurchaseRequestItems"];
export const useCreateOrderFromPurchaseRequestItems = useCreateOrderFromPurchaseRequestItemsMutation;
export type CreateOrderFromRfxVariables = CreateOrderFromRfxMutationVariables;
export type CreateOrderFromRfxCreateOrderFromRfx = CreateOrderFromRfxMutation["createOrderFromRfx"];
export const useCreateOrderFromRfx = useCreateOrderFromRfxMutation;
export type CreateRfxFromPurchaseRequestVariables = CreateRfxFromPurchaseRequestMutationVariables;
export type CreateRfxFromPurchaseRequestCreateRfxFromPurchaseRequest = CreateRfxFromPurchaseRequestMutation["createRfxFromPurchaseRequest"];
export const useCreateRfxFromPurchaseRequest = useCreateRfxFromPurchaseRequestMutation;
export type CreateRfxFromPurchaseRequestItemsVariables = CreateRfxFromPurchaseRequestItemsMutationVariables;
export type CreateRfxFromPurchaseRequestItemsCreateRfxFromPurchaseRequestItems = CreateRfxFromPurchaseRequestItemsMutation["createRfxFromPurchaseRequestItems"];
export const useCreateRfxFromPurchaseRequestItems = useCreateRfxFromPurchaseRequestItemsMutation;
export type FullTextSearchVariables = FullTextSearchMutationVariables;
export type FullTextSearchFullTextSearch = NonNullable<
  FullTextSearchMutation["fullTextSearch"][0]
>;
export type FullTextSearchParent = NonNullable<
  (NonNullable<FullTextSearchMutation["fullTextSearch"][0]>)["parent"]
>;
export type FullTextSearchStatus = (NonNullable<
  (NonNullable<FullTextSearchMutation["fullTextSearch"][0]>)["parent"]
>)["status"];
export type FullTextSearch_Status = NonNullable<
  (NonNullable<FullTextSearchMutation["fullTextSearch"][0]>)["status"]
>;
export const useFullTextSearch = useFullTextSearchMutation;
export type PurchaseRequestItemInfoAssignee = NonNullable<
  PurchaseRequestItemInfoFragment["assignee"]
>;
export type UserInfoDepartment = NonNullable<UserInfoFragment["department"]>;
export type UserInfoProject = NonNullable<UserInfoFragment["project"]>;
export type UserInfoCostCentre = NonNullable<UserInfoFragment["costCentre"]>;
export type SupplierInfoDefaultContactPerson = SupplierInfoFragment["defaultContactPerson"];
export type KanbanViewVariables = KanbanViewQueryVariables;
export type KanbanViewViewer = NonNullable<KanbanViewQuery["viewer"]>;
export type KanbanViewOrganization = (NonNullable<
  KanbanViewQuery["viewer"]
>)["organization"];
export type KanbanViewUsers = UserInfoFragment;
export const useKanbanView = useKanbanViewQuery;
export type PaginatedInvoicesVariables = PaginatedInvoicesQueryVariables;
export type PaginatedInvoicesViewer = NonNullable<
  PaginatedInvoicesQuery["viewer"]
>;
export type PaginatedInvoicesOrganization = (NonNullable<
  PaginatedInvoicesQuery["viewer"]
>)["organization"];
export type PaginatedInvoicesPaginatedInvoices = (NonNullable<
  PaginatedInvoicesQuery["viewer"]
>)["organization"]["paginatedInvoices"];
export type PaginatedInvoicesEdges = NonNullable<
  (NonNullable<
    PaginatedInvoicesQuery["viewer"]
  >)["organization"]["paginatedInvoices"]["edges"][0]
>;
export type PaginatedInvoicesCardStatus = (NonNullable<
  (NonNullable<
    PaginatedInvoicesQuery["viewer"]
  >)["organization"]["paginatedInvoices"]["edges"][0]
>)["cardStatus"];
export type PaginatedInvoicesAssignee = UserInfoFragment;
export type PaginatedInvoicesSupplier = SupplierInfoFragment;
export type PaginatedInvoicesItems = PurchaseRequestItemInfoFragment;
export type PaginatedInvoicesProjects = ProjectInfoFragment;
export type PaginatedInvoicesRequestors = UserInfoFragment;
export type PaginatedInvoicesPageInfo = (NonNullable<
  PaginatedInvoicesQuery["viewer"]
>)["organization"]["paginatedInvoices"]["pageInfo"];
export const usePaginatedInvoices = usePaginatedInvoicesQuery;
export type PaginatedOrdersVariables = PaginatedOrdersQueryVariables;
export type PaginatedOrdersViewer = NonNullable<PaginatedOrdersQuery["viewer"]>;
export type PaginatedOrdersOrganization = (NonNullable<
  PaginatedOrdersQuery["viewer"]
>)["organization"];
export type PaginatedOrdersPaginatedOrders = (NonNullable<
  PaginatedOrdersQuery["viewer"]
>)["organization"]["paginatedOrders"];
export type PaginatedOrdersEdges = NonNullable<
  (NonNullable<
    PaginatedOrdersQuery["viewer"]
  >)["organization"]["paginatedOrders"]["edges"][0]
>;
export type PaginatedOrdersDepartment = NonNullable<
  (NonNullable<
    (NonNullable<
      PaginatedOrdersQuery["viewer"]
    >)["organization"]["paginatedOrders"]["edges"][0]
  >)["department"]
>;
export type PaginatedOrdersCardStatus = (NonNullable<
  (NonNullable<
    PaginatedOrdersQuery["viewer"]
  >)["organization"]["paginatedOrders"]["edges"][0]
>)["cardStatus"];
export type PaginatedOrdersAssignee = UserInfoFragment;
export type PaginatedOrdersSupplier = SupplierInfoFragment;
export type PaginatedOrdersItems = PurchaseRequestItemInfoFragment;
export type PaginatedOrdersProjects = ProjectInfoFragment;
export type PaginatedOrdersRequestors = UserInfoFragment;
export type PaginatedOrdersPageInfo = (NonNullable<
  PaginatedOrdersQuery["viewer"]
>)["organization"]["paginatedOrders"]["pageInfo"];
export const usePaginatedOrders = usePaginatedOrdersQuery;
export type PaginatedPurchaseRequestsVariables = PaginatedPurchaseRequestsQueryVariables;
export type PaginatedPurchaseRequestsViewer = NonNullable<
  PaginatedPurchaseRequestsQuery["viewer"]
>;
export type PaginatedPurchaseRequestsOrganization = (NonNullable<
  PaginatedPurchaseRequestsQuery["viewer"]
>)["organization"];
export type PaginatedPurchaseRequestsPaginatedPurchaseRequests = (NonNullable<
  PaginatedPurchaseRequestsQuery["viewer"]
>)["organization"]["paginatedPurchaseRequests"];
export type PaginatedPurchaseRequestsEdges = NonNullable<
  (NonNullable<
    PaginatedPurchaseRequestsQuery["viewer"]
  >)["organization"]["paginatedPurchaseRequests"]["edges"][0]
>;
export type PaginatedPurchaseRequestsCardStatus = (NonNullable<
  (NonNullable<
    PaginatedPurchaseRequestsQuery["viewer"]
  >)["organization"]["paginatedPurchaseRequests"]["edges"][0]
>)["cardStatus"];
export type PaginatedPurchaseRequestsRequestor = UserInfoFragment;
export type PaginatedPurchaseRequestsAssignee = UserInfoFragment;
export type PaginatedPurchaseRequestsProject = ProjectInfoFragment;
export type PaginatedPurchaseRequestsSuppliers = SupplierInfoFragment;
export type PaginatedPurchaseRequestsDepartment = DepartmentInfoFragment;
export type PaginatedPurchaseRequestsItems = PurchaseRequestItemInfoFragment;
export type PaginatedPurchaseRequestsStatus = (NonNullable<
  (NonNullable<
    (NonNullable<
      PaginatedPurchaseRequestsQuery["viewer"]
    >)["organization"]["paginatedPurchaseRequests"]["edges"][0]
  >)["items"][0]
>)["status"];
export type PaginatedPurchaseRequestsLatestApprovalWorkflow = NonNullable<
  (NonNullable<
    (NonNullable<
      PaginatedPurchaseRequestsQuery["viewer"]
    >)["organization"]["paginatedPurchaseRequests"]["edges"][0]
  >)["latestApprovalWorkflow"]
>;
export type PaginatedPurchaseRequestsPageInfo = (NonNullable<
  PaginatedPurchaseRequestsQuery["viewer"]
>)["organization"]["paginatedPurchaseRequests"]["pageInfo"];
export const usePaginatedPurchaseRequests = usePaginatedPurchaseRequestsQuery;
export type PaginatedReceivingVariables = PaginatedReceivingQueryVariables;
export type PaginatedReceivingViewer = NonNullable<
  PaginatedReceivingQuery["viewer"]
>;
export type PaginatedReceivingOrganization = (NonNullable<
  PaginatedReceivingQuery["viewer"]
>)["organization"];
export type PaginatedReceivingPaginatedReceiving = (NonNullable<
  PaginatedReceivingQuery["viewer"]
>)["organization"]["paginatedReceiving"];
export type PaginatedReceivingEdges = NonNullable<
  (NonNullable<
    PaginatedReceivingQuery["viewer"]
  >)["organization"]["paginatedReceiving"]["edges"][0]
>;
export type PaginatedReceivingCardStatus = (NonNullable<
  (NonNullable<
    PaginatedReceivingQuery["viewer"]
  >)["organization"]["paginatedReceiving"]["edges"][0]
>)["cardStatus"];
export type PaginatedReceivingAssignee = UserInfoFragment;
export type PaginatedReceivingSupplier = SupplierInfoFragment;
export type PaginatedReceivingItems = PurchaseRequestItemInfoFragment;
export type PaginatedReceivingProjects = ProjectInfoFragment;
export type PaginatedReceivingRequestors = UserInfoFragment;
export type PaginatedReceivingPageInfo = (NonNullable<
  PaginatedReceivingQuery["viewer"]
>)["organization"]["paginatedReceiving"]["pageInfo"];
export const usePaginatedReceiving = usePaginatedReceivingQuery;
export type PaginatedRfxVariables = PaginatedRfxQueryVariables;
export type PaginatedRfxViewer = NonNullable<PaginatedRfxQuery["viewer"]>;
export type PaginatedRfxOrganization = (NonNullable<
  PaginatedRfxQuery["viewer"]
>)["organization"];
export type PaginatedRfxPaginatedRfx = (NonNullable<
  PaginatedRfxQuery["viewer"]
>)["organization"]["paginatedRfx"];
export type PaginatedRfxEdges = NonNullable<
  (NonNullable<
    PaginatedRfxQuery["viewer"]
  >)["organization"]["paginatedRfx"]["edges"][0]
>;
export type PaginatedRfxCardStatus = (NonNullable<
  (NonNullable<
    PaginatedRfxQuery["viewer"]
  >)["organization"]["paginatedRfx"]["edges"][0]
>)["cardStatus"];
export type PaginatedRfxAssignee = UserInfoFragment;
export type PaginatedRfxSuppliers = SupplierInfoFragment;
export type PaginatedRfxItems = PurchaseRequestItemInfoFragment;
export type PaginatedRfxProjects = ProjectInfoFragment;
export type PaginatedRfxRequestors = UserInfoFragment;
export type PaginatedRfxPageInfo = (NonNullable<
  PaginatedRfxQuery["viewer"]
>)["organization"]["paginatedRfx"]["pageInfo"];
export const usePaginatedRfx = usePaginatedRfxQuery;
export type UpdateCardPositionVariables = UpdateCardPositionMutationVariables;
export const useUpdateCardPosition = useUpdateCardPositionMutation;
export type UpdateOrderTypeVariables = UpdateOrderTypeMutationVariables;
export type UpdateOrderTypeUpdateOrderType = UpdateOrderTypeMutation["updateOrderType"];
export const useUpdateOrderType = useUpdateOrderTypeMutation;
export type LoginSupplierUserWithTokenVariables = LoginSupplierUserWithTokenMutationVariables;
export type LoginSupplierUserWithTokenLoginSupplierUserWithToken = LoginSupplierUserWithTokenMutation["loginSupplierUserWithToken"];
export const useLoginSupplierUserWithToken = useLoginSupplierUserWithTokenMutation;
export type LoginWithTokenVariables = LoginWithTokenMutationVariables;
export type LoginWithTokenLoginWithToken = LoginWithTokenMutation["loginWithToken"];
export const useLoginWithToken = useLoginWithTokenMutation;
export type SendLoginTokenVariables = SendLoginTokenMutationVariables;
export const useSendLoginToken = useSendLoginTokenMutation;
export type LogoutVariables = LogoutMutationVariables;
export const useLogout = useLogoutMutation;
export type MarkAllNotificationsSeenVariables = MarkAllNotificationsSeenMutationVariables;
export const useMarkAllNotificationsSeen = useMarkAllNotificationsSeenMutation;
export type MarkNotificationsSeenByIdsVariables = MarkNotificationsSeenByIdsMutationVariables;
export const useMarkNotificationsSeenByIds = useMarkNotificationsSeenByIdsMutation;
export type NotificationsVariables = NotificationsQueryVariables;
export type NotificationsViewer = NonNullable<NotificationsQuery["viewer"]>;
export type NotificationsOrganization = (NonNullable<
  NotificationsQuery["viewer"]
>)["organization"];
export type NotificationsNotifications = NonNullable<
  (NonNullable<
    NotificationsQuery["viewer"]
  >)["organization"]["notifications"][0]
>;
export type NotificationsActivity = (NonNullable<
  (NonNullable<
    NotificationsQuery["viewer"]
  >)["organization"]["notifications"][0]
>)["activity"];
export type NotificationsRelativeInfo = NonNullable<
  (NonNullable<
    (NonNullable<
      NotificationsQuery["viewer"]
    >)["organization"]["notifications"][0]
  >)["activity"]["relativeInfo"]
>;
export type NotificationsActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["relativeInfo"]
    >,
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type NotificationsActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["relativeInfo"]
    >,
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type NotificationsData = (NonNullable<
  (NonNullable<
    NotificationsQuery["viewer"]
  >)["organization"]["notifications"][0]
>)["activity"]["data"];
export type NotificationsAssignInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>;
export type NotificationsItemInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>)["itemInfo"];
export type NotificationsAssignerInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>)["assignerInfo"];
export type NotificationsAssigneeInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["data"],
      "__typename"
    >,
    { __typename: "ASSIGN" }
  >)["assigneeInfo"]
>;
export type NotificationsDrag_CardInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "DRAG_CARD" }
>;
export type NotificationsRequestorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "DRAG_CARD" }
>)["requestorInfo"];
export type Notifications_AssigneeInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["data"],
      "__typename"
    >,
    { __typename: "DRAG_CARD" }
  >)["assigneeInfo"]
>;
export type NotificationsCommentInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>;
export type NotificationsUser = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>)["user"];
export type Notifications_RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>)["relativeInfo"];
export type Notifications_ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            NotificationsQuery["viewer"]
          >)["organization"]["notifications"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type NotificationsActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            NotificationsQuery["viewer"]
          >)["organization"]["notifications"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type Notifications_ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            NotificationsQuery["viewer"]
          >)["organization"]["notifications"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type NotificationsAdd_ItemInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEM" }
>;
export type NotificationsCreator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEM" }
>)["creator"];
export type NotificationsAdd_AttachmentInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "ADD_ATTACHMENT" }
>;
export type NotificationsEmail_Inbound_MentionInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>;
export type Notifications_User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["user"];
export type Notifications__RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["relativeInfo"];
export type Notifications__ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            NotificationsQuery["viewer"]
          >)["organization"]["notifications"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type Notifications_ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            NotificationsQuery["viewer"]
          >)["organization"]["notifications"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type Notifications__ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            NotificationsQuery["viewer"]
          >)["organization"]["notifications"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type NotificationsEmailInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["emailInfo"];
export type NotificationsEmail_Inbound_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>;
export type NotificationsSupplierInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>)["supplierInfo"];
export type Notifications_EmailInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>)["emailInfo"];
export type NotificationsEmail_Inbound_RequesterInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_REQUESTER" }
>;
export type NotificationsEmail_Outbound_MentionInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_MENTION" }
>;
export type Notifications__User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_MENTION" }
>)["user"];
export type NotificationsEmail_Outbound_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>;
export type NotificationsRfxInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["data"],
      "__typename"
    >,
    { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
  >)["rfxInfo"]
>;
export type NotificationsOrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["data"],
      "__typename"
    >,
    { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
  >)["orderInfo"]
>;
export type Notifications_SupplierInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>)["supplierInfo"];
export type Notifications_Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>)["creator"];
export type NotificationsReplyInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>;
export type Notifications___User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>)["user"];
export type Notifications___RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>)["relativeInfo"];
export type Notifications___ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            NotificationsQuery["viewer"]
          >)["organization"]["notifications"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type Notifications__ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            NotificationsQuery["viewer"]
          >)["organization"]["notifications"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type Notifications___ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            NotificationsQuery["viewer"]
          >)["organization"]["notifications"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type NotificationsAdd_ItemsInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEMS" }
>;
export type Notifications__Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEMS" }
>)["creator"];
export type NotificationsCreate_Purchase_RequestInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST" }
>;
export type Notifications___Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST" }
>)["creator"];
export type NotificationsCreate_Purchase_Request_ProductInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_PRODUCT" }
>;
export type Notifications____Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_PRODUCT" }
>)["creator"];
export type NotificationsCreate_Purchase_Request_ServiceInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_SERVICE" }
>;
export type Notifications_____Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_SERVICE" }
>)["creator"];
export type NotificationsDrag_ItemInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "DRAG_ITEM" }
>;
export type NotificationsCreate_Approval_WorkflowInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>;
export type NotificationsCreatorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["creatorInfo"];
export type NotificationsApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowInfo"];
export type NotificationsApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowStats"];
export type NotificationsUpdate_Approval_WorkflowInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>;
export type Notifications_CreatorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["creatorInfo"];
export type Notifications_ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowInfo"];
export type NotificationsRelativeCardInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["relativeCardInfo"];
export type Notifications_ApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowStats"];
export type NotificationsCreate_Approval_RequestInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>;
export type NotificationsApproverInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approverInfo"];
export type NotificationsApprovalRequestInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approvalRequestInfo"];
export type Notifications__ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approvalWorkflowInfo"];
export type Notifications__ApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approvalWorkflowStats"];
export type NotificationsUpdate_Approval_RequestInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>;
export type Notifications_ApproverInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approverInfo"];
export type Notifications_ApprovalRequestInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approvalRequestInfo"];
export type Notifications___ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approvalWorkflowInfo"];
export type Notifications___ApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approvalWorkflowStats"];
export type NotificationsCreate_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_SUPPLIER" }
>;
export type Notifications______Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_SUPPLIER" }
>)["creator"];
export type NotificationsUpdate_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_SUPPLIER" }
>;
export type Notifications_______Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_SUPPLIER" }
>)["creator"];
export type NotificationsChangedFields = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["data"],
      "__typename"
    >,
    { __typename: "UPDATE_SUPPLIER" }
  >)["changedFields"][0]
>;
export type NotificationsUpdate_Order_Received_QuantitiesInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
>;
export type Notifications____User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
>)["user"];
export type Notifications_OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["data"],
      "__typename"
    >,
    { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
  >)["orderInfo"]
>;
export type NotificationsCreate_Invoice_From_OrderInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_INVOICE_FROM_ORDER" }
>;
export type Notifications_____User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_INVOICE_FROM_ORDER" }
>)["user"];
export type Notifications__OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["data"],
      "__typename"
    >,
    { __typename: "CREATE_INVOICE_FROM_ORDER" }
  >)["orderInfo"]
>;
export type NotificationsAuthor = NonNullable<
  (NonNullable<
    (NonNullable<
      NotificationsQuery["viewer"]
    >)["organization"]["notifications"][0]
  >)["activity"]["author"]
>;
export type NotificationsReplies = NonNullable<
  (NonNullable<
    (NonNullable<
      NotificationsQuery["viewer"]
    >)["organization"]["notifications"][0]
  >)["activity"]["replies"][0]
>;
export type Notifications_Data = (NonNullable<
  (NonNullable<
    (NonNullable<
      NotificationsQuery["viewer"]
    >)["organization"]["notifications"][0]
  >)["activity"]["replies"][0]
>)["data"];
export type Notifications_AssignInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>;
export type Notifications_ItemInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>)["itemInfo"];
export type Notifications_AssignerInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>)["assignerInfo"];
export type Notifications__AssigneeInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          (NonNullable<
            NotificationsQuery["viewer"]
          >)["organization"]["notifications"][0]
        >)["activity"]["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "ASSIGN" }
  >)["assigneeInfo"]
>;
export type Notifications_Drag_CardInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "DRAG_CARD" }
>;
export type Notifications_RequestorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "DRAG_CARD" }
>)["requestorInfo"];
export type Notifications___AssigneeInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          (NonNullable<
            NotificationsQuery["viewer"]
          >)["organization"]["notifications"][0]
        >)["activity"]["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "DRAG_CARD" }
  >)["assigneeInfo"]
>;
export type Notifications_CommentInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>;
export type Notifications______User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>)["user"];
export type Notifications____RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>)["relativeInfo"];
export type Notifications____ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            (NonNullable<
              NotificationsQuery["viewer"]
            >)["organization"]["notifications"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type Notifications___ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            (NonNullable<
              NotificationsQuery["viewer"]
            >)["organization"]["notifications"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type Notifications____ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            (NonNullable<
              NotificationsQuery["viewer"]
            >)["organization"]["notifications"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type Notifications_Add_ItemInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEM" }
>;
export type Notifications________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEM" }
>)["creator"];
export type Notifications_Add_AttachmentInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ADD_ATTACHMENT" }
>;
export type Notifications_Email_Inbound_MentionInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>;
export type Notifications_______User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["user"];
export type Notifications_____RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["relativeInfo"];
export type Notifications_____ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            (NonNullable<
              NotificationsQuery["viewer"]
            >)["organization"]["notifications"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type Notifications____ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            (NonNullable<
              NotificationsQuery["viewer"]
            >)["organization"]["notifications"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type Notifications_____ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            (NonNullable<
              NotificationsQuery["viewer"]
            >)["organization"]["notifications"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type Notifications__EmailInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["emailInfo"];
export type Notifications_Email_Inbound_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>;
export type Notifications__SupplierInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>)["supplierInfo"];
export type Notifications___EmailInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>)["emailInfo"];
export type Notifications_Email_Inbound_RequesterInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_REQUESTER" }
>;
export type Notifications_Email_Outbound_MentionInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_MENTION" }
>;
export type Notifications_Email_Outbound_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>;
export type Notifications_RfxInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          (NonNullable<
            NotificationsQuery["viewer"]
          >)["organization"]["notifications"][0]
        >)["activity"]["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
  >)["rfxInfo"]
>;
export type Notifications___OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          (NonNullable<
            NotificationsQuery["viewer"]
          >)["organization"]["notifications"][0]
        >)["activity"]["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
  >)["orderInfo"]
>;
export type Notifications___SupplierInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>)["supplierInfo"];
export type Notifications_________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>)["creator"];
export type Notifications_ReplyInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>;
export type Notifications________User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>)["user"];
export type Notifications______RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>)["relativeInfo"];
export type Notifications______ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            (NonNullable<
              NotificationsQuery["viewer"]
            >)["organization"]["notifications"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type Notifications_____ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            (NonNullable<
              NotificationsQuery["viewer"]
            >)["organization"]["notifications"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type Notifications______ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            (NonNullable<
              NotificationsQuery["viewer"]
            >)["organization"]["notifications"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type Notifications_Add_ItemsInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEMS" }
>;
export type Notifications__________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEMS" }
>)["creator"];
export type Notifications_Create_Purchase_RequestInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST" }
>;
export type Notifications___________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST" }
>)["creator"];
export type Notifications_Create_Purchase_Request_ProductInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_PRODUCT" }
>;
export type Notifications____________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_PRODUCT" }
>)["creator"];
export type Notifications_Create_Purchase_Request_ServiceInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_SERVICE" }
>;
export type Notifications_____________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_SERVICE" }
>)["creator"];
export type Notifications_Drag_ItemInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "DRAG_ITEM" }
>;
export type Notifications_Create_Approval_WorkflowInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>;
export type Notifications__CreatorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["creatorInfo"];
export type Notifications____ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowInfo"];
export type Notifications____ApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowStats"];
export type Notifications_Update_Approval_WorkflowInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>;
export type Notifications___CreatorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["creatorInfo"];
export type Notifications_____ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowInfo"];
export type Notifications_RelativeCardInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["relativeCardInfo"];
export type Notifications_____ApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowStats"];
export type Notifications_Create_Approval_RequestInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>;
export type Notifications__ApproverInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approverInfo"];
export type Notifications__ApprovalRequestInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approvalRequestInfo"];
export type Notifications______ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approvalWorkflowInfo"];
export type Notifications______ApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approvalWorkflowStats"];
export type Notifications_Update_Approval_RequestInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>;
export type Notifications___ApproverInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approverInfo"];
export type Notifications___ApprovalRequestInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approvalRequestInfo"];
export type Notifications_______ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approvalWorkflowInfo"];
export type Notifications_______ApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approvalWorkflowStats"];
export type Notifications_Create_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_SUPPLIER" }
>;
export type Notifications______________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_SUPPLIER" }
>)["creator"];
export type Notifications_Update_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_SUPPLIER" }
>;
export type Notifications_______________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_SUPPLIER" }
>)["creator"];
export type Notifications_ChangedFields = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          (NonNullable<
            NotificationsQuery["viewer"]
          >)["organization"]["notifications"][0]
        >)["activity"]["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "UPDATE_SUPPLIER" }
  >)["changedFields"][0]
>;
export type Notifications_Update_Order_Received_QuantitiesInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
>;
export type Notifications_________User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
>)["user"];
export type Notifications____OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          (NonNullable<
            NotificationsQuery["viewer"]
          >)["organization"]["notifications"][0]
        >)["activity"]["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
  >)["orderInfo"]
>;
export type Notifications_Create_Invoice_From_OrderInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_INVOICE_FROM_ORDER" }
>;
export type Notifications__________User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        (NonNullable<
          NotificationsQuery["viewer"]
        >)["organization"]["notifications"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_INVOICE_FROM_ORDER" }
>)["user"];
export type Notifications_____OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          (NonNullable<
            NotificationsQuery["viewer"]
          >)["organization"]["notifications"][0]
        >)["activity"]["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "CREATE_INVOICE_FROM_ORDER" }
  >)["orderInfo"]
>;
export type Notifications_Author = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<
        NotificationsQuery["viewer"]
      >)["organization"]["notifications"][0]
    >)["activity"]["replies"][0]
  >)["author"]
>;
export const useNotifications = useNotificationsQuery;
export type ArchiveOrderVariables = ArchiveOrderMutationVariables;
export type ArchiveOrderArchiveOrder = ArchiveOrderMutation["archiveOrder"];
export const useArchiveOrder = useArchiveOrderMutation;
export type CreateOrderInvoiceFromOrderVariables = CreateOrderInvoiceFromOrderMutationVariables;
export type CreateOrderInvoiceFromOrderCreateOrderInvoiceFromOrder = CreateOrderInvoiceFromOrderMutation["createOrderInvoiceFromOrder"];
export const useCreateOrderInvoiceFromOrder = useCreateOrderInvoiceFromOrderMutation;
export type CreateOrganizationAddressVariables = CreateOrganizationAddressMutationVariables;
export type CreateOrganizationAddressCreateOrganizationAddress = AddressInfoFragment;
export const useCreateOrganizationAddress = useCreateOrganizationAddressMutation;
export type OrderByCodeVariables = OrderByCodeQueryVariables;
export type OrderByCodeViewer = NonNullable<OrderByCodeQuery["viewer"]>;
export type OrderByCodeOrderByCode = (NonNullable<
  OrderByCodeQuery["viewer"]
>)["orderByCode"];
export type OrderByCodeDepartment = NonNullable<
  (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["department"]
>;
export type OrderByCodeShippingAddress = AddressInfoFragment;
export type OrderByCodeInvoiceAddress = AddressInfoFragment;
export type OrderByCodeAssignee = NonNullable<
  (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["assignee"]
>;
export type OrderByCodeAttachments = NonNullable<
  (NonNullable<
    (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["attachments"]
  >)[0]
>;
export type OrderByCodeCardStatus = (NonNullable<
  OrderByCodeQuery["viewer"]
>)["orderByCode"]["cardStatus"];
export type OrderByCodeCreator = (NonNullable<
  OrderByCodeQuery["viewer"]
>)["orderByCode"]["creator"];
export type OrderByCodeItems = NonNullable<
  (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["items"][0]
>;
export type OrderByCode_Assignee = NonNullable<
  (NonNullable<
    (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["items"][0]
  >)["assignee"]
>;
export type OrderByCodeStatus = (NonNullable<
  (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["items"][0]
>)["status"];
export type OrderByCode_Attachments = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["items"][0]
    >)["attachments"]
  >)[0]
>;
export type OrderByCodeRequestor = (NonNullable<
  (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["items"][0]
>)["requestor"];
export type OrderByCodeHistory = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["items"][0]
    >)["history"]
  >)[0]
>;
export type OrderByCodeProjects = NonNullable<
  (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["projects"][0]
>;
export type OrderByCodeCostCentre = NonNullable<
  (NonNullable<
    (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["projects"][0]
  >)["costCentre"]
>;
export type OrderByCode_Creator = (NonNullable<
  (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["projects"][0]
>)["creator"];
export type OrderByCodeRequestors = NonNullable<
  (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["requestors"][0]
>;
export type OrderByCodeSupplier = NonNullable<
  (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["supplier"]
>;
export type OrderByCodeDefaultContactPerson = (NonNullable<
  (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["supplier"]
>)["defaultContactPerson"];
export type OrderByCodeContactPersons = NonNullable<
  (NonNullable<
    (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["supplier"]
  >)["contactPersons"][0]
>;
export type OrderByCodeActivities = ActivityFeedItemFragment;
export type OrderByCodeRfx = NonNullable<
  (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["rfx"]
>;
export type OrderByCode_Department = NonNullable<
  (NonNullable<
    (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["rfx"]
  >)["department"]
>;
export type OrderByCodeSuppliers = NonNullable<
  (NonNullable<
    (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["rfx"]
  >)["suppliers"][0]
>;
export type OrderByCode_DefaultContactPerson = (NonNullable<
  (NonNullable<
    (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["rfx"]
  >)["suppliers"][0]
>)["defaultContactPerson"];
export type OrderByCodeEmails = NonNullable<
  (NonNullable<
    (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["rfx"]
  >)["emails"][0]
>;
export type OrderByCodeOutboundEmailInlineFragment = DiscriminateUnion<
  RequireField<
    NonNullable<
      (NonNullable<
        (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["rfx"]
      >)["emails"][0]
    >,
    "__typename"
  >,
  { __typename: "OutboundEmail" }
>;
export type OrderByCodeInboundEmailInlineFragment = DiscriminateUnion<
  RequireField<
    NonNullable<
      (NonNullable<
        (NonNullable<OrderByCodeQuery["viewer"]>)["orderByCode"]["rfx"]
      >)["emails"][0]
    >,
    "__typename"
  >,
  { __typename: "InboundEmail" }
>;
export type OrderByCodeOrganization = (NonNullable<
  OrderByCodeQuery["viewer"]
>)["organization"];
export type OrderByCodeUsers = UserInfoFragment;
export type OrderByCode_Suppliers = NonNullable<
  (NonNullable<OrderByCodeQuery["viewer"]>)["organization"]["suppliers"][0]
>;
export type OrderByCode__DefaultContactPerson = (NonNullable<
  (NonNullable<OrderByCodeQuery["viewer"]>)["organization"]["suppliers"][0]
>)["defaultContactPerson"];
export type OrderByCodeAddresses = NonNullable<
  (NonNullable<OrderByCodeQuery["viewer"]>)["organization"]["addresses"][0]
>;
export type OrderByCodeCountries = NonNullable<
  (NonNullable<OrderByCodeQuery["viewer"]>)["countries"][0]
>;
export const useOrderByCode = useOrderByCodeQuery;
export type OrderModalInfoVariables = OrderModalInfoQueryVariables;
export type OrderModalInfoViewer = NonNullable<OrderModalInfoQuery["viewer"]>;
export type OrderModalInfoOrganization = (NonNullable<
  OrderModalInfoQuery["viewer"]
>)["organization"];
export type OrderModalInfoOrders = NonNullable<
  (NonNullable<OrderModalInfoQuery["viewer"]>)["organization"]["orders"][0]
>;
export const useOrderModalInfo = useOrderModalInfoQuery;
export type UpdateOrderVariables = UpdateOrderMutationVariables;
export type UpdateOrderUpdateOrder = UpdateOrderMutation["updateOrder"];
export const useUpdateOrder = useUpdateOrderMutation;
export type UpdateOrderHasBeenSentVariables = UpdateOrderHasBeenSentMutationVariables;
export type UpdateOrderHasBeenSentUpdateOrderHasBeenSent = UpdateOrderHasBeenSentMutation["updateOrderHasBeenSent"];
export const useUpdateOrderHasBeenSent = useUpdateOrderHasBeenSentMutation;
export type UpdateOrderReleaseMethodVariables = UpdateOrderReleaseMethodMutationVariables;
export type UpdateOrderReleaseMethodUpdateOrderReleaseMethod = UpdateOrderReleaseMethodMutation["updateOrderReleaseMethod"];
export const useUpdateOrderReleaseMethod = useUpdateOrderReleaseMethodMutation;
export type UpdateOrderStatusVariables = UpdateOrderStatusMutationVariables;
export type UpdateOrderStatusUpdateOrderStatus = UpdateOrderStatusMutation["updateOrderStatus"];
export const useUpdateOrderStatus = useUpdateOrderStatusMutation;
export type UpdatePurchaseRequestItemReceivedQuantitesVariables = UpdatePurchaseRequestItemReceivedQuantitesMutationVariables;
export const useUpdatePurchaseRequestItemReceivedQuantites = useUpdatePurchaseRequestItemReceivedQuantitesMutation;
export type AddRolesToUserVariables = AddRolesToUserMutationVariables;
export type AddRolesToUserAddRolesToUser = AddRolesToUserMutation["addRolesToUser"];
export const useAddRolesToUser = useAddRolesToUserMutation;
export type ArchivePurchaseRequestVariables = ArchivePurchaseRequestMutationVariables;
export type ArchivePurchaseRequestArchivePurchaseRequest = ArchivePurchaseRequestMutation["archivePurchaseRequest"];
export const useArchivePurchaseRequest = useArchivePurchaseRequestMutation;
export type CompletePurchaseRequestVariables = CompletePurchaseRequestMutationVariables;
export type CompletePurchaseRequestCompletePurchaseRequest = CompletePurchaseRequestMutation["completePurchaseRequest"];
export const useCompletePurchaseRequest = useCompletePurchaseRequestMutation;
export type CreateApprovalRequestVariables = CreateApprovalRequestMutationVariables;
export type CreateApprovalRequestCreateApprovalRequest = CreateApprovalRequestMutation["createApprovalRequest"];
export const useCreateApprovalRequest = useCreateApprovalRequestMutation;
export type CreateApprovalWorkflowVariables = CreateApprovalWorkflowMutationVariables;
export type CreateApprovalWorkflowCreateApprovalWorkflow = CreateApprovalWorkflowMutation["createApprovalWorkflow"];
export const useCreateApprovalWorkflow = useCreateApprovalWorkflowMutation;
export type CreateApproverVariables = CreateApproverMutationVariables;
export type CreateApproverCreateApprover = UserInfoFragment;
export const useCreateApprover = useCreateApproverMutation;
export type CreateCostCentreVariables = CreateCostCentreMutationVariables;
export type CreateCostCentreCreateCostCentre = CreateCostCentreMutation["createCostCentre"];
export const useCreateCostCentre = useCreateCostCentreMutation;
export type CreateDepartmentVariables = CreateDepartmentMutationVariables;
export type CreateDepartmentCreateDepartment = CreateDepartmentMutation["createDepartment"];
export const useCreateDepartment = useCreateDepartmentMutation;
export type CreateProjectVariables = CreateProjectMutationVariables;
export type CreateProjectCreateProject = CreateProjectMutation["createProject"];
export const useCreateProject = useCreateProjectMutation;
export type CreatePurchaseRequestAttachmentsVariables = CreatePurchaseRequestAttachmentsMutationVariables;
export type CreatePurchaseRequestAttachmentsCreatePurchaseRequestAttachments = NonNullable<
  CreatePurchaseRequestAttachmentsMutation["createPurchaseRequestAttachments"][0]
>;
export const useCreatePurchaseRequestAttachments = useCreatePurchaseRequestAttachmentsMutation;
export type CreatePurchaseRequestItemAttachmentsVariables = CreatePurchaseRequestItemAttachmentsMutationVariables;
export type CreatePurchaseRequestItemAttachmentsCreatePurchaseRequestItemAttachments = NonNullable<
  CreatePurchaseRequestItemAttachmentsMutation["createPurchaseRequestItemAttachments"][0]
>;
export const useCreatePurchaseRequestItemAttachments = useCreatePurchaseRequestItemAttachmentsMutation;
export type CreateRequestorVariables = CreateRequestorMutationVariables;
export type CreateRequestorCreateRequestor = UserInfoFragment;
export const useCreateRequestor = useCreateRequestorMutation;
export type DeleteApprovalRequestVariables = DeleteApprovalRequestMutationVariables;
export type DeleteApprovalRequestDeleteApprovalRequest = DeleteApprovalRequestMutation["deleteApprovalRequest"];
export const useDeleteApprovalRequest = useDeleteApprovalRequestMutation;
export type DeletePurchaseRequestItemVariables = DeletePurchaseRequestItemMutationVariables;
export type DeletePurchaseRequestItemDeletePurchaseRequestItem = DeletePurchaseRequestItemMutation["deletePurchaseRequestItem"];
export const useDeletePurchaseRequestItem = useDeletePurchaseRequestItemMutation;
export type PurchaseRequestByCodeVariables = PurchaseRequestByCodeQueryVariables;
export type PurchaseRequestByCodeViewer = NonNullable<
  PurchaseRequestByCodeQuery["viewer"]
>;
export type PurchaseRequestByCodePurchaseRequestByCode = (NonNullable<
  PurchaseRequestByCodeQuery["viewer"]
>)["purchaseRequestByCode"];
export type PurchaseRequestByCodeDepartment = DepartmentInfoFragment;
export type PurchaseRequestByCodeProject = ProjectInfoFragment;
export type PurchaseRequestByCodeCostCentre = NonNullable<
  (NonNullable<
    PurchaseRequestByCodeQuery["viewer"]
  >)["purchaseRequestByCode"]["costCentre"]
>;
export type PurchaseRequestByCodeRequestor = UserInfoFragment;
export type PurchaseRequestByCodeAssignee = UserInfoFragment;
export type PurchaseRequestByCodeAttachments = NonNullable<
  (NonNullable<
    (NonNullable<
      PurchaseRequestByCodeQuery["viewer"]
    >)["purchaseRequestByCode"]["attachments"]
  >)[0]
>;
export type PurchaseRequestByCodeCreator = (NonNullable<
  (NonNullable<
    (NonNullable<
      PurchaseRequestByCodeQuery["viewer"]
    >)["purchaseRequestByCode"]["attachments"]
  >)[0]
>)["creator"];
export type PurchaseRequestByCodeCardStatus = (NonNullable<
  PurchaseRequestByCodeQuery["viewer"]
>)["purchaseRequestByCode"]["cardStatus"];
export type PurchaseRequestByCodeItems = NonNullable<
  (NonNullable<
    PurchaseRequestByCodeQuery["viewer"]
  >)["purchaseRequestByCode"]["items"][0]
>;
export type PurchaseRequestByCode_Assignee = NonNullable<
  (NonNullable<
    (NonNullable<
      PurchaseRequestByCodeQuery["viewer"]
    >)["purchaseRequestByCode"]["items"][0]
  >)["assignee"]
>;
export type PurchaseRequestByCodeStatus = (NonNullable<
  (NonNullable<
    PurchaseRequestByCodeQuery["viewer"]
  >)["purchaseRequestByCode"]["items"][0]
>)["status"];
export type PurchaseRequestByCode_Attachments = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<
        PurchaseRequestByCodeQuery["viewer"]
      >)["purchaseRequestByCode"]["items"][0]
    >)["attachments"]
  >)[0]
>;
export type PurchaseRequestByCode_Requestor = (NonNullable<
  (NonNullable<
    PurchaseRequestByCodeQuery["viewer"]
  >)["purchaseRequestByCode"]["items"][0]
>)["requestor"];
export type PurchaseRequestByCodeHistory = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<
        PurchaseRequestByCodeQuery["viewer"]
      >)["purchaseRequestByCode"]["items"][0]
    >)["history"]
  >)[0]
>;
export type PurchaseRequestByCodeActivities = ActivityFeedItemFragment;
export type PurchaseRequestByCodeLatestApprovalWorkflow = NonNullable<
  (NonNullable<
    PurchaseRequestByCodeQuery["viewer"]
  >)["purchaseRequestByCode"]["latestApprovalWorkflow"]
>;
export type PurchaseRequestByCodeApprovalRequests = NonNullable<
  (NonNullable<
    (NonNullable<
      PurchaseRequestByCodeQuery["viewer"]
    >)["purchaseRequestByCode"]["latestApprovalWorkflow"]
  >)["approvalRequests"][0]
>;
export type PurchaseRequestByCodeApprover = (NonNullable<
  (NonNullable<
    (NonNullable<
      PurchaseRequestByCodeQuery["viewer"]
    >)["purchaseRequestByCode"]["latestApprovalWorkflow"]
  >)["approvalRequests"][0]
>)["approver"];
export type PurchaseRequestByCodeEmails = NonNullable<
  (NonNullable<
    PurchaseRequestByCodeQuery["viewer"]
  >)["purchaseRequestByCode"]["emails"][0]
>;
export type PurchaseRequestByCodeOutboundEmailInlineFragment = DiscriminateUnion<
  RequireField<
    NonNullable<
      (NonNullable<
        PurchaseRequestByCodeQuery["viewer"]
      >)["purchaseRequestByCode"]["emails"][0]
    >,
    "__typename"
  >,
  { __typename: "OutboundEmail" }
>;
export type PurchaseRequestByCodeInboundEmailInlineFragment = DiscriminateUnion<
  RequireField<
    NonNullable<
      (NonNullable<
        PurchaseRequestByCodeQuery["viewer"]
      >)["purchaseRequestByCode"]["emails"][0]
    >,
    "__typename"
  >,
  { __typename: "InboundEmail" }
>;
export type PurchaseRequestByCodeOrganization = (NonNullable<
  PurchaseRequestByCodeQuery["viewer"]
>)["organization"];
export type PurchaseRequestByCodeUsers = UserInfoFragment;
export type PurchaseRequestByCodeSuppliers = NonNullable<
  (NonNullable<
    PurchaseRequestByCodeQuery["viewer"]
  >)["organization"]["suppliers"][0]
>;
export type PurchaseRequestByCodeDefaultContactPerson = (NonNullable<
  (NonNullable<
    PurchaseRequestByCodeQuery["viewer"]
  >)["organization"]["suppliers"][0]
>)["defaultContactPerson"];
export type PurchaseRequestByCodeDepartments = NonNullable<
  (NonNullable<
    PurchaseRequestByCodeQuery["viewer"]
  >)["organization"]["departments"][0]
>;
export type PurchaseRequestByCodeCostCentres = NonNullable<
  (NonNullable<
    PurchaseRequestByCodeQuery["viewer"]
  >)["organization"]["costCentres"][0]
>;
export type PurchaseRequestByCodeProjects = NonNullable<
  (NonNullable<
    PurchaseRequestByCodeQuery["viewer"]
  >)["organization"]["projects"][0]
>;
export type PurchaseRequestByCodeQuantityUnits = NonNullable<
  (NonNullable<
    PurchaseRequestByCodeQuery["viewer"]
  >)["organization"]["quantityUnits"][0]
>;
export type PurchaseRequestByCodeAddressBook = NonNullable<
  (NonNullable<
    PurchaseRequestByCodeQuery["viewer"]
  >)["organization"]["addressBook"][0]
>;
export const usePurchaseRequestByCode = usePurchaseRequestByCodeQuery;
export type RemovePurchaseRequestAttachmentVariables = RemovePurchaseRequestAttachmentMutationVariables;
export type RemovePurchaseRequestAttachmentRemovePurchaseRequestAttachment = RemovePurchaseRequestAttachmentMutation["removePurchaseRequestAttachment"];
export const useRemovePurchaseRequestAttachment = useRemovePurchaseRequestAttachmentMutation;
export type UpdateApprovalWorkflowDataVariables = UpdateApprovalWorkflowDataMutationVariables;
export type UpdateApprovalWorkflowDataUpdateApprovalWorkflowData = UpdateApprovalWorkflowDataMutation["updateApprovalWorkflowData"];
export const useUpdateApprovalWorkflowData = useUpdateApprovalWorkflowDataMutation;
export type UpdateApprovalWorkflowStatusVariables = UpdateApprovalWorkflowStatusMutationVariables;
export type UpdateApprovalWorkflowStatusUpdateApprovalWorkflowStatus = UpdateApprovalWorkflowStatusMutation["updateApprovalWorkflowStatus"];
export const useUpdateApprovalWorkflowStatus = useUpdateApprovalWorkflowStatusMutation;
export type UpdatePurchaseRequestVariables = UpdatePurchaseRequestMutationVariables;
export type UpdatePurchaseRequestUpdatePurchaseRequest = UpdatePurchaseRequestMutation["updatePurchaseRequest"];
export const useUpdatePurchaseRequest = useUpdatePurchaseRequestMutation;
export type UpdatePurchaseRequestStatusVariables = UpdatePurchaseRequestStatusMutationVariables;
export type UpdatePurchaseRequestStatusUpdatePurchaseRequestStatus = UpdatePurchaseRequestStatusMutation["updatePurchaseRequestStatus"];
export const useUpdatePurchaseRequestStatus = useUpdatePurchaseRequestStatusMutation;
export type CreatePurchaseRequestFromPortalVariables = CreatePurchaseRequestFromPortalMutationVariables;
export type CreatePurchaseRequestFromPortalCreatePurchaseRequestFromPortal = CreatePurchaseRequestFromPortalMutation["createPurchaseRequestFromPortal"];
export const useCreatePurchaseRequestFromPortal = useCreatePurchaseRequestFromPortalMutation;
export type PurchaseRequestsDetailsByCodeVariables = PurchaseRequestsDetailsByCodeQueryVariables;
export type PurchaseRequestsDetailsByCodeViewer = NonNullable<
  PurchaseRequestsDetailsByCodeQuery["viewer"]
>;
export type PurchaseRequestsDetailsByCodePurchaseRequestByCode = (NonNullable<
  PurchaseRequestsDetailsByCodeQuery["viewer"]
>)["purchaseRequestByCode"];
export type PurchaseRequestsDetailsByCodeCostCentre = NonNullable<
  (NonNullable<
    PurchaseRequestsDetailsByCodeQuery["viewer"]
  >)["purchaseRequestByCode"]["costCentre"]
>;
export type PurchaseRequestsDetailsByCodeRequestor = UserInfoFragment;
export type PurchaseRequestsDetailsByCodeAssignee = UserInfoFragment;
export type PurchaseRequestsDetailsByCodeAttachments = NonNullable<
  (NonNullable<
    (NonNullable<
      PurchaseRequestsDetailsByCodeQuery["viewer"]
    >)["purchaseRequestByCode"]["attachments"]
  >)[0]
>;
export type PurchaseRequestsDetailsByCodeDepartment = DepartmentInfoFragment;
export type PurchaseRequestsDetailsByCodeProject = ProjectInfoFragment;
export type PurchaseRequestsDetailsByCodeCardStatus = (NonNullable<
  PurchaseRequestsDetailsByCodeQuery["viewer"]
>)["purchaseRequestByCode"]["cardStatus"];
export type PurchaseRequestsDetailsByCodeItems = NonNullable<
  (NonNullable<
    PurchaseRequestsDetailsByCodeQuery["viewer"]
  >)["purchaseRequestByCode"]["items"][0]
>;
export type PurchaseRequestsDetailsByCode_Assignee = NonNullable<
  (NonNullable<
    (NonNullable<
      PurchaseRequestsDetailsByCodeQuery["viewer"]
    >)["purchaseRequestByCode"]["items"][0]
  >)["assignee"]
>;
export type PurchaseRequestsDetailsByCodeStatus = (NonNullable<
  (NonNullable<
    PurchaseRequestsDetailsByCodeQuery["viewer"]
  >)["purchaseRequestByCode"]["items"][0]
>)["status"];
export type PurchaseRequestsDetailsByCode_Attachments = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<
        PurchaseRequestsDetailsByCodeQuery["viewer"]
      >)["purchaseRequestByCode"]["items"][0]
    >)["attachments"]
  >)[0]
>;
export type PurchaseRequestsDetailsByCode_Requestor = (NonNullable<
  (NonNullable<
    PurchaseRequestsDetailsByCodeQuery["viewer"]
  >)["purchaseRequestByCode"]["items"][0]
>)["requestor"];
export type PurchaseRequestsDetailsByCodeHistory = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<
        PurchaseRequestsDetailsByCodeQuery["viewer"]
      >)["purchaseRequestByCode"]["items"][0]
    >)["history"]
  >)[0]
>;
export type PurchaseRequestsDetailsByCodeActivities = ActivityFeedItemFragment;
export type PurchaseRequestsDetailsByCodeLatestApprovalWorkflow = NonNullable<
  (NonNullable<
    PurchaseRequestsDetailsByCodeQuery["viewer"]
  >)["purchaseRequestByCode"]["latestApprovalWorkflow"]
>;
export type PurchaseRequestsDetailsByCodeApprovalRequests = NonNullable<
  (NonNullable<
    (NonNullable<
      PurchaseRequestsDetailsByCodeQuery["viewer"]
    >)["purchaseRequestByCode"]["latestApprovalWorkflow"]
  >)["approvalRequests"][0]
>;
export type PurchaseRequestsDetailsByCodeApprover = (NonNullable<
  (NonNullable<
    (NonNullable<
      PurchaseRequestsDetailsByCodeQuery["viewer"]
    >)["purchaseRequestByCode"]["latestApprovalWorkflow"]
  >)["approvalRequests"][0]
>)["approver"];
export type PurchaseRequestsDetailsByCodeEmails = NonNullable<
  (NonNullable<
    PurchaseRequestsDetailsByCodeQuery["viewer"]
  >)["purchaseRequestByCode"]["emails"][0]
>;
export type PurchaseRequestsDetailsByCodeOutboundEmailInlineFragment = DiscriminateUnion<
  RequireField<
    NonNullable<
      (NonNullable<
        PurchaseRequestsDetailsByCodeQuery["viewer"]
      >)["purchaseRequestByCode"]["emails"][0]
    >,
    "__typename"
  >,
  { __typename: "OutboundEmail" }
>;
export type PurchaseRequestsDetailsByCodeInboundEmailInlineFragment = DiscriminateUnion<
  RequireField<
    NonNullable<
      (NonNullable<
        PurchaseRequestsDetailsByCodeQuery["viewer"]
      >)["purchaseRequestByCode"]["emails"][0]
    >,
    "__typename"
  >,
  { __typename: "InboundEmail" }
>;
export type PurchaseRequestsDetailsByCodeOrganization = (NonNullable<
  PurchaseRequestsDetailsByCodeQuery["viewer"]
>)["organization"];
export type PurchaseRequestsDetailsByCodeUsers = UserInfoFragment;
export type PurchaseRequestsDetailsByCodeSuppliers = NonNullable<
  (NonNullable<
    PurchaseRequestsDetailsByCodeQuery["viewer"]
  >)["organization"]["suppliers"][0]
>;
export type PurchaseRequestsDetailsByCodeDefaultContactPerson = (NonNullable<
  (NonNullable<
    PurchaseRequestsDetailsByCodeQuery["viewer"]
  >)["organization"]["suppliers"][0]
>)["defaultContactPerson"];
export type PurchaseRequestsDetailsByCodeDepartments = NonNullable<
  (NonNullable<
    PurchaseRequestsDetailsByCodeQuery["viewer"]
  >)["organization"]["departments"][0]
>;
export type PurchaseRequestsDetailsByCodeCostCentres = NonNullable<
  (NonNullable<
    PurchaseRequestsDetailsByCodeQuery["viewer"]
  >)["organization"]["costCentres"][0]
>;
export type PurchaseRequestsDetailsByCodeProjects = NonNullable<
  (NonNullable<
    PurchaseRequestsDetailsByCodeQuery["viewer"]
  >)["organization"]["projects"][0]
>;
export const usePurchaseRequestsDetailsByCode = usePurchaseRequestsDetailsByCodeQuery;
export type RequesterPortalViewVariables = RequesterPortalViewQueryVariables;
export type RequesterPortalViewViewer = NonNullable<
  RequesterPortalViewQuery["viewer"]
>;
export type RequesterPortalViewDepartment = NonNullable<
  (NonNullable<RequesterPortalViewQuery["viewer"]>)["department"]
>;
export type RequesterPortalViewProject = NonNullable<
  (NonNullable<RequesterPortalViewQuery["viewer"]>)["project"]
>;
export type RequesterPortalViewCostCentre = NonNullable<
  (NonNullable<RequesterPortalViewQuery["viewer"]>)["costCentre"]
>;
export type RequesterPortalViewApprovalWorkflowsByStatus = NonNullable<
  (NonNullable<
    RequesterPortalViewQuery["viewer"]
  >)["approvalWorkflowsByStatus"][0]
>;
export type RequesterPortalViewOrganization = (NonNullable<
  RequesterPortalViewQuery["viewer"]
>)["organization"];
export type RequesterPortalViewCountry = (NonNullable<
  RequesterPortalViewQuery["viewer"]
>)["organization"]["country"];
export type RequesterPortalViewUsers = UserInfoFragment;
export type RequesterPortalViewSuppliers = NonNullable<
  (NonNullable<
    RequesterPortalViewQuery["viewer"]
  >)["organization"]["suppliers"][0]
>;
export type RequesterPortalViewDefaultContactPerson = (NonNullable<
  (NonNullable<
    RequesterPortalViewQuery["viewer"]
  >)["organization"]["suppliers"][0]
>)["defaultContactPerson"];
export type RequesterPortalViewDepartments = NonNullable<
  (NonNullable<
    RequesterPortalViewQuery["viewer"]
  >)["organization"]["departments"][0]
>;
export type RequesterPortalViewCostCentres = NonNullable<
  (NonNullable<
    RequesterPortalViewQuery["viewer"]
  >)["organization"]["costCentres"][0]
>;
export type RequesterPortalViewProjects = NonNullable<
  (NonNullable<
    RequesterPortalViewQuery["viewer"]
  >)["organization"]["projects"][0]
>;
export type RequesterPortalViewQuantityUnits = NonNullable<
  (NonNullable<
    RequesterPortalViewQuery["viewer"]
  >)["organization"]["quantityUnits"][0]
>;
export type RequesterPortalViewPurchaseRequests = NonNullable<
  (NonNullable<
    RequesterPortalViewQuery["viewer"]
  >)["organization"]["purchaseRequests"][0]
>;
export type RequesterPortalViewCardStatus = (NonNullable<
  (NonNullable<
    RequesterPortalViewQuery["viewer"]
  >)["organization"]["purchaseRequests"][0]
>)["cardStatus"];
export type RequesterPortalViewRequestor = UserInfoFragment;
export type RequesterPortalViewAssignee = UserInfoFragment;
export type RequesterPortalView_Project = ProjectInfoFragment;
export type RequesterPortalView_Suppliers = SupplierInfoFragment;
export type RequesterPortalView_Department = DepartmentInfoFragment;
export type RequesterPortalViewItems = PurchaseRequestItemInfoFragment;
export type RequesterPortalViewStatus = (NonNullable<
  (NonNullable<
    (NonNullable<
      RequesterPortalViewQuery["viewer"]
    >)["organization"]["purchaseRequests"][0]
  >)["items"][0]
>)["status"];
export type RequesterPortalViewLatestApprovalWorkflow = NonNullable<
  (NonNullable<
    (NonNullable<
      RequesterPortalViewQuery["viewer"]
    >)["organization"]["purchaseRequests"][0]
  >)["latestApprovalWorkflow"]
>;
export const useRequesterPortalView = useRequesterPortalViewQuery;
export type UpdatePurchaseRequestFromPortalVariables = UpdatePurchaseRequestFromPortalMutationVariables;
export type UpdatePurchaseRequestFromPortalUpdatePurchaseRequestFromPortal = UpdatePurchaseRequestFromPortalMutation["updatePurchaseRequestFromPortal"];
export const useUpdatePurchaseRequestFromPortal = useUpdatePurchaseRequestFromPortalMutation;
export type AddSupplierResolutionVariables = AddSupplierResolutionMutationVariables;
export type AddSupplierResolutionAddSupplierResolution = AddSupplierResolutionMutation["addSupplierResolution"];
export const useAddSupplierResolution = useAddSupplierResolutionMutation;
export type AddSupplierToRfxVariables = AddSupplierToRfxMutationVariables;
export type AddSupplierToRfxAddSupplierToRfx = AddSupplierToRfxMutation["addSupplierToRfx"];
export const useAddSupplierToRfx = useAddSupplierToRfxMutation;
export type ArchiveRfxVariables = ArchiveRfxMutationVariables;
export type ArchiveRfxArchiveRfx = ArchiveRfxMutation["archiveRfx"];
export const useArchiveRfx = useArchiveRfxMutation;
export type CompleteSourcingEventVariables = CompleteSourcingEventMutationVariables;
export type CompleteSourcingEventCompleteSourcingEvent = CompleteSourcingEventMutation["completeSourcingEvent"];
export const useCompleteSourcingEvent = useCompleteSourcingEventMutation;
export type CreateCommentVariables = CreateCommentMutationVariables;
export type CreateCommentCreateComment = CreateCommentMutation["createComment"];
export const useCreateComment = useCreateCommentMutation;
export type CreateSupplierVariables = CreateSupplierMutationVariables;
export type CreateSupplierCreateSupplier = CreateSupplierMutation["createSupplier"];
export type CreateSupplierDefaultContactPerson = CreateSupplierMutation["createSupplier"]["defaultContactPerson"];
export const useCreateSupplier = useCreateSupplierMutation;
export type MarkInboundEmailReadStatusVariables = MarkInboundEmailReadStatusMutationVariables;
export const useMarkInboundEmailReadStatus = useMarkInboundEmailReadStatusMutation;
export type RemovePurchaseRequestItemVariables = RemovePurchaseRequestItemMutationVariables;
export type RemovePurchaseRequestItemRemovePurchaseRequestItem = RemovePurchaseRequestItemMutation["removePurchaseRequestItem"];
export const useRemovePurchaseRequestItem = useRemovePurchaseRequestItemMutation;
export type RemoveSupplierFromRfxVariables = RemoveSupplierFromRfxMutationVariables;
export type RemoveSupplierFromRfxRemoveSupplierFromRfx = RemoveSupplierFromRfxMutation["removeSupplierFromRfx"];
export const useRemoveSupplierFromRfx = useRemoveSupplierFromRfxMutation;
export type RfxByCodeVariables = RfxByCodeQueryVariables;
export type RfxByCodeViewer = NonNullable<RfxByCodeQuery["viewer"]>;
export type RfxByCodeRfxByCode = (NonNullable<
  RfxByCodeQuery["viewer"]
>)["rfxByCode"];
export type RfxByCodeDepartment = NonNullable<
  (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["department"]
>;
export type RfxByCodeAssignee = UserInfoFragment;
export type RfxByCodeAttachments = NonNullable<
  (NonNullable<
    (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["attachments"]
  >)[0]
>;
export type RfxByCodeEmails = NonNullable<
  (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["emails"][0]
>;
export type RfxByCodeOutboundEmailInlineFragment = DiscriminateUnion<
  RequireField<
    NonNullable<
      (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["emails"][0]
    >,
    "__typename"
  >,
  { __typename: "OutboundEmail" }
>;
export type RfxByCodeInboundEmailInlineFragment = DiscriminateUnion<
  RequireField<
    NonNullable<
      (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["emails"][0]
    >,
    "__typename"
  >,
  { __typename: "InboundEmail" }
>;
export type RfxByCodeSupplierResolutions = NonNullable<
  (NonNullable<
    (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["supplierResolutions"]
  >)[0]
>;
export type RfxByCodeItems = NonNullable<
  (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["items"][0]
>;
export type RfxByCode_Assignee = NonNullable<
  (NonNullable<
    (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["items"][0]
  >)["assignee"]
>;
export type RfxByCodeStatus = (NonNullable<
  (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["items"][0]
>)["status"];
export type RfxByCode_Attachments = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["items"][0]
    >)["attachments"]
  >)[0]
>;
export type RfxByCodeRequestor = (NonNullable<
  (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["items"][0]
>)["requestor"];
export type RfxByCodeHistory = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["items"][0]
    >)["history"]
  >)[0]
>;
export type RfxByCodeSourcingEvent = NonNullable<
  (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["sourcingEvent"]
>;
export type RfxByCodeCreator = (NonNullable<
  (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["sourcingEvent"]
>)["creator"];
export type RfxByCode__Attachments = NonNullable<
  (NonNullable<
    (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["sourcingEvent"]
  >)["attachments"][0]
>;
export type RfxByCodeSourcingRequests = NonNullable<
  (NonNullable<
    (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["sourcingEvent"]
  >)["sourcingRequests"][0]
>;
export type RfxByCodeSupplier = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["sourcingEvent"]
    >)["sourcingRequests"][0]
  >)["supplier"]
>;
export type RfxByCode___Attachments = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<
        (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["sourcingEvent"]
      >)["sourcingRequests"][0]
    >)["attachments"]
  >)[0]
>;
export type RfxByCodeSuppliers = NonNullable<
  (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["suppliers"][0]
>;
export type RfxByCodeDefaultContactPerson = (NonNullable<
  (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["suppliers"][0]
>)["defaultContactPerson"];
export type RfxByCodeActiveSuppliers = NonNullable<
  (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["activeSuppliers"][0]
>;
export type RfxByCode_DefaultContactPerson = (NonNullable<
  (NonNullable<RfxByCodeQuery["viewer"]>)["rfxByCode"]["activeSuppliers"][0]
>)["defaultContactPerson"];
export type RfxByCodeCardStatus = (NonNullable<
  RfxByCodeQuery["viewer"]
>)["rfxByCode"]["cardStatus"];
export type RfxByCodeActivities = ActivityFeedItemFragment;
export type RfxByCodeOrganization = (NonNullable<
  RfxByCodeQuery["viewer"]
>)["organization"];
export type RfxByCodeUsers = UserInfoFragment;
export type RfxByCode_Suppliers = NonNullable<
  (NonNullable<RfxByCodeQuery["viewer"]>)["organization"]["suppliers"][0]
>;
export type RfxByCode__DefaultContactPerson = (NonNullable<
  (NonNullable<RfxByCodeQuery["viewer"]>)["organization"]["suppliers"][0]
>)["defaultContactPerson"];
export const useRfxByCode = useRfxByCodeQuery;
export type RfxModalInfoVariables = RfxModalInfoQueryVariables;
export type RfxModalInfoViewer = NonNullable<RfxModalInfoQuery["viewer"]>;
export type RfxModalInfoOrganization = (NonNullable<
  RfxModalInfoQuery["viewer"]
>)["organization"];
export type RfxModalInfoRfx = NonNullable<
  (NonNullable<RfxModalInfoQuery["viewer"]>)["organization"]["rfx"][0]
>;
export const useRfxModalInfo = useRfxModalInfoQuery;
export type SupplierByContactPersonVariables = SupplierByContactPersonQueryVariables;
export type SupplierByContactPersonViewer = NonNullable<
  SupplierByContactPersonQuery["viewer"]
>;
export type SupplierByContactPersonSupplierByContactPerson = (NonNullable<
  SupplierByContactPersonQuery["viewer"]
>)["supplierByContactPerson"];
export const useSupplierByContactPerson = useSupplierByContactPersonQuery;
export type SupplierFullTextSearchVariables = SupplierFullTextSearchMutationVariables;
export type SupplierFullTextSearchSupplierFullTextSearch = NonNullable<
  SupplierFullTextSearchMutation["supplierFullTextSearch"][0]
>;
export const useSupplierFullTextSearch = useSupplierFullTextSearchMutation;
export type SuppliersByCategoryVariables = SuppliersByCategoryQueryVariables;
export type SuppliersByCategoryViewer = NonNullable<
  SuppliersByCategoryQuery["viewer"]
>;
export type SuppliersByCategorySuppliersByCategoryId = NonNullable<
  (NonNullable<SuppliersByCategoryQuery["viewer"]>)["suppliersByCategoryId"][0]
>;
export const useSuppliersByCategory = useSuppliersByCategoryQuery;
export type UpdateRfxVariables = UpdateRfxMutationVariables;
export type UpdateRfxUpdateRfx = UpdateRfxMutation["updateRfx"];
export const useUpdateRfx = useUpdateRfxMutation;
export type UpdateRfxNameVariables = UpdateRfxNameMutationVariables;
export type UpdateRfxNameUpdateRfxName = UpdateRfxNameMutation["updateRfxName"];
export const useUpdateRfxName = useUpdateRfxNameMutation;
export type UpdateRfxTypeVariables = UpdateRfxTypeMutationVariables;
export type UpdateRfxTypeUpdateRfxType = UpdateRfxTypeMutation["updateRfxType"];
export const useUpdateRfxType = useUpdateRfxTypeMutation;
export type UpdateSourcingEventItemWinnersVariables = UpdateSourcingEventItemWinnersMutationVariables;
export type UpdateSourcingEventItemWinnersUpdateSourcingEventItemWinners = UpdateSourcingEventItemWinnersMutation["updateSourcingEventItemWinners"];
export const useUpdateSourcingEventItemWinners = useUpdateSourcingEventItemWinnersMutation;
export type UpdateSupplierVariables = UpdateSupplierMutationVariables;
export type UpdateSupplierUpdateSupplier = SupplierDetailInfoFragment;
export const useUpdateSupplier = useUpdateSupplierMutation;
export type OrganizationSourcingEventsVariables = OrganizationSourcingEventsQueryVariables;
export type OrganizationSourcingEventsViewer = NonNullable<
  OrganizationSourcingEventsQuery["viewer"]
>;
export type OrganizationSourcingEventsOrganization = (NonNullable<
  OrganizationSourcingEventsQuery["viewer"]
>)["organization"];
export type OrganizationSourcingEventsSourcingEvents = NonNullable<
  (NonNullable<
    OrganizationSourcingEventsQuery["viewer"]
  >)["organization"]["sourcingEvents"][0]
>;
export type OrganizationSourcingEventsCreator = (NonNullable<
  (NonNullable<
    OrganizationSourcingEventsQuery["viewer"]
  >)["organization"]["sourcingEvents"][0]
>)["creator"];
export type OrganizationSourcingEventsRfx = (NonNullable<
  (NonNullable<
    OrganizationSourcingEventsQuery["viewer"]
  >)["organization"]["sourcingEvents"][0]
>)["rfx"];
export type OrganizationSourcingEventsDepartment = NonNullable<
  (NonNullable<
    (NonNullable<
      OrganizationSourcingEventsQuery["viewer"]
    >)["organization"]["sourcingEvents"][0]
  >)["rfx"]["department"]
>;
export type OrganizationSourcingEventsSupplierResolutions = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<
        OrganizationSourcingEventsQuery["viewer"]
      >)["organization"]["sourcingEvents"][0]
    >)["rfx"]["supplierResolutions"]
  >)[0]
>;
export type OrganizationSourcingEventsSupplier = (NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<
        OrganizationSourcingEventsQuery["viewer"]
      >)["organization"]["sourcingEvents"][0]
    >)["rfx"]["supplierResolutions"]
  >)[0]
>)["supplier"];
export type OrganizationSourcingEventsSourcingRequests = NonNullable<
  (NonNullable<
    (NonNullable<
      OrganizationSourcingEventsQuery["viewer"]
    >)["organization"]["sourcingEvents"][0]
  >)["sourcingRequests"][0]
>;
export const useOrganizationSourcingEvents = useOrganizationSourcingEventsQuery;
export type CompleteSourcingRequestVariables = CompleteSourcingRequestMutationVariables;
export type CompleteSourcingRequestCompleteSourcingRequest = CompleteSourcingRequestMutation["completeSourcingRequest"];
export const useCompleteSourcingRequest = useCompleteSourcingRequestMutation;
export type SourcingRequestByIdVariables = SourcingRequestByIdQueryVariables;
export type SourcingRequestByIdViewer = NonNullable<
  SourcingRequestByIdQuery["viewer"]
>;
export type SourcingRequestByIdSourcingRequestById = (NonNullable<
  SourcingRequestByIdQuery["viewer"]
>)["sourcingRequestById"];
export type SourcingRequestByIdSourcingEvent = (NonNullable<
  SourcingRequestByIdQuery["viewer"]
>)["sourcingRequestById"]["sourcingEvent"];
export type SourcingRequestByIdAttachments = NonNullable<
  (NonNullable<
    SourcingRequestByIdQuery["viewer"]
  >)["sourcingRequestById"]["sourcingEvent"]["attachments"][0]
>;
export type SourcingRequestByIdOrganization = (NonNullable<
  SourcingRequestByIdQuery["viewer"]
>)["sourcingRequestById"]["sourcingEvent"]["organization"];
export type SourcingRequestByIdCountry = (NonNullable<
  SourcingRequestByIdQuery["viewer"]
>)["sourcingRequestById"]["sourcingEvent"]["organization"]["country"];
export type SourcingRequestByIdRfx = (NonNullable<
  SourcingRequestByIdQuery["viewer"]
>)["sourcingRequestById"]["sourcingEvent"]["rfx"];
export type SourcingRequestByIdDepartment = NonNullable<
  (NonNullable<
    SourcingRequestByIdQuery["viewer"]
  >)["sourcingRequestById"]["sourcingEvent"]["rfx"]["department"]
>;
export type SourcingRequestByIdItems = NonNullable<
  (NonNullable<
    SourcingRequestByIdQuery["viewer"]
  >)["sourcingRequestById"]["sourcingEvent"]["rfx"]["items"][0]
>;
export type SourcingRequestById_Attachments = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<
        SourcingRequestByIdQuery["viewer"]
      >)["sourcingRequestById"]["sourcingEvent"]["rfx"]["items"][0]
    >)["attachments"]
  >)[0]
>;
export type SourcingRequestByIdEmails = NonNullable<
  (NonNullable<
    SourcingRequestByIdQuery["viewer"]
  >)["sourcingRequestById"]["sourcingEvent"]["rfx"]["emails"][0]
>;
export type SourcingRequestByIdOutboundEmailInlineFragment = DiscriminateUnion<
  RequireField<
    NonNullable<
      (NonNullable<
        SourcingRequestByIdQuery["viewer"]
      >)["sourcingRequestById"]["sourcingEvent"]["rfx"]["emails"][0]
    >,
    "__typename"
  >,
  { __typename: "OutboundEmail" }
>;
export type SourcingRequestByIdInboundEmailInlineFragment = DiscriminateUnion<
  RequireField<
    NonNullable<
      (NonNullable<
        SourcingRequestByIdQuery["viewer"]
      >)["sourcingRequestById"]["sourcingEvent"]["rfx"]["emails"][0]
    >,
    "__typename"
  >,
  { __typename: "InboundEmail" }
>;
export type SourcingRequestByIdCreator = (NonNullable<
  SourcingRequestByIdQuery["viewer"]
>)["sourcingRequestById"]["sourcingEvent"]["creator"];
export type SourcingRequestById__Attachments = NonNullable<
  (NonNullable<
    (NonNullable<
      SourcingRequestByIdQuery["viewer"]
    >)["sourcingRequestById"]["attachments"]
  >)[0]
>;
export const useSourcingRequestById = useSourcingRequestByIdQuery;
export type UpdateSourcingRequestVariables = UpdateSourcingRequestMutationVariables;
export type UpdateSourcingRequestUpdateSourcingRequest = UpdateSourcingRequestMutation["updateSourcingRequest"];
export const useUpdateSourcingRequest = useUpdateSourcingRequestMutation;
export type UploadQuotationPricesExcelVariables = UploadQuotationPricesExcelMutationVariables;
export type UploadQuotationPricesExcelUploadQuotationPricesExcel = UploadQuotationPricesExcelMutation["uploadQuotationPricesExcel"];
export type UploadQuotationPricesExcelRows = NonNullable<
  UploadQuotationPricesExcelMutation["uploadQuotationPricesExcel"]["rows"][0]
>;
export type UploadQuotationPricesExcelErrors = (NonNullable<
  UploadQuotationPricesExcelMutation["uploadQuotationPricesExcel"]["rows"][0]
>)["errors"];
export type UploadQuotationPricesExcelSourcingRequest = NonNullable<
  UploadQuotationPricesExcelMutation["uploadQuotationPricesExcel"]["sourcingRequest"]
>;
export const useUploadQuotationPricesExcel = useUploadQuotationPricesExcelMutation;
export type SupplierSourcingRequestsVariables = SupplierSourcingRequestsQueryVariables;
export type SupplierSourcingRequestsViewer = NonNullable<
  SupplierSourcingRequestsQuery["viewer"]
>;
export type SupplierSourcingRequestsSupplierById = (NonNullable<
  SupplierSourcingRequestsQuery["viewer"]
>)["supplierById"];
export const useSupplierSourcingRequests = useSupplierSourcingRequestsQuery;
export type CountriesVariables = CountriesQueryVariables;
export type CountriesCountries = NonNullable<CountriesQuery["countries"][0]>;
export const useCountries = useCountriesQuery;
export type SourcingEventPublicVariables = SourcingEventPublicQueryVariables;
export type SourcingEventPublicSourcingEventPublic = SourcingEventPublicQuery["sourcingEventPublic"];
export type SourcingEventPublicOrganization = SourcingEventPublicQuery["sourcingEventPublic"]["organization"];
export type SourcingEventPublicCreator = SourcingEventPublicQuery["sourcingEventPublic"]["creator"];
export const useSourcingEventPublic = useSourcingEventPublicQuery;
export type SupplierLookupVariables = SupplierLookupMutationVariables;
export type SupplierLookupSupplierLookup = SupplierLookupMutation["supplierLookup"];
export const useSupplierLookup = useSupplierLookupMutation;
export type SupplierSelfRegistrationVariables = SupplierSelfRegistrationMutationVariables;
export const useSupplierSelfRegistration = useSupplierSelfRegistrationMutation;
export type AddSupplierCategoryVariables = AddSupplierCategoryMutationVariables;
export type AddSupplierCategoryAddSupplierCategory = AddSupplierCategoryMutation["addSupplierCategory"];
export const useAddSupplierCategory = useAddSupplierCategoryMutation;
export type ContactPersonByIdVariables = ContactPersonByIdQueryVariables;
export type ContactPersonByIdViewer = NonNullable<
  ContactPersonByIdQuery["viewer"]
>;
export type ContactPersonByIdContactPersonById = ContactPersonInfoFragment;
export const useContactPersonById = useContactPersonByIdQuery;
export type CreateCategoryVariables = CreateCategoryMutationVariables;
export type CreateCategoryCreateCategory = CreateCategoryMutation["createCategory"];
export const useCreateCategory = useCreateCategoryMutation;
export type CreateContactPersonVariables = CreateContactPersonMutationVariables;
export type CreateContactPersonCreateContactPerson = CreateContactPersonMutation["createContactPerson"];
export const useCreateContactPerson = useCreateContactPersonMutation;
export type CreateSupplierAttachmentVariables = CreateSupplierAttachmentMutationVariables;
export type CreateSupplierAttachmentCreateSupplierAttachment = NonNullable<
  CreateSupplierAttachmentMutation["createSupplierAttachment"][0]
>;
export type CreateSupplierAttachmentCreator = (NonNullable<
  CreateSupplierAttachmentMutation["createSupplierAttachment"][0]
>)["creator"];
export const useCreateSupplierAttachment = useCreateSupplierAttachmentMutation;
export type RemoveSupplierCategoryVariables = RemoveSupplierCategoryMutationVariables;
export type RemoveSupplierCategoryRemoveSupplierCategory = RemoveSupplierCategoryMutation["removeSupplierCategory"];
export const useRemoveSupplierCategory = useRemoveSupplierCategoryMutation;
export type SrmViewVariables = SrmViewQueryVariables;
export type SrmViewViewer = NonNullable<SrmViewQuery["viewer"]>;
export type SrmViewOrganization = (NonNullable<
  SrmViewQuery["viewer"]
>)["organization"];
export type SrmViewSuppliers = NonNullable<
  (NonNullable<SrmViewQuery["viewer"]>)["organization"]["suppliers"][0]
>;
export type SrmViewDefaultContactPerson = (NonNullable<
  (NonNullable<SrmViewQuery["viewer"]>)["organization"]["suppliers"][0]
>)["defaultContactPerson"];
export type SrmViewCountry = NonNullable<
  (NonNullable<
    (NonNullable<SrmViewQuery["viewer"]>)["organization"]["suppliers"][0]
  >)["country"]
>;
export type SrmViewAttachments = NonNullable<
  (NonNullable<
    (NonNullable<SrmViewQuery["viewer"]>)["organization"]["suppliers"][0]
  >)["attachments"][0]
>;
export type SrmViewLastContact = NonNullable<
  (NonNullable<
    (NonNullable<SrmViewQuery["viewer"]>)["organization"]["suppliers"][0]
  >)["lastContact"]
>;
export type SrmViewContactPersons = NonNullable<
  (NonNullable<
    (NonNullable<SrmViewQuery["viewer"]>)["organization"]["suppliers"][0]
  >)["contactPersons"][0]
>;
export type SrmViewCategories = NonNullable<
  (NonNullable<
    (NonNullable<SrmViewQuery["viewer"]>)["organization"]["suppliers"][0]
  >)["categories"][0]
>;
export const useSrmView = useSrmViewQuery;
export type SupplierAttachmentVariables = SupplierAttachmentQueryVariables;
export type SupplierAttachmentViewer = NonNullable<
  SupplierAttachmentQuery["viewer"]
>;
export type SupplierAttachmentSupplierById = (NonNullable<
  SupplierAttachmentQuery["viewer"]
>)["supplierById"];
export type SupplierAttachmentAttachments = NonNullable<
  (NonNullable<
    SupplierAttachmentQuery["viewer"]
  >)["supplierById"]["attachments"][0]
>;
export type SupplierAttachmentCreator = (NonNullable<
  (NonNullable<
    SupplierAttachmentQuery["viewer"]
  >)["supplierById"]["attachments"][0]
>)["creator"];
export const useSupplierAttachment = useSupplierAttachmentQuery;
export type SupplierDetailInfoDefaultContactPerson = SupplierDetailInfoFragment["defaultContactPerson"];
export type SupplierDetailInfoCategories = NonNullable<
  SupplierDetailInfoFragment["categories"][0]
>;
export type SupplierDetailInfoContactPersons = NonNullable<
  SupplierDetailInfoFragment["contactPersons"][0]
>;
export type SupplierDetailInfoMentions = NonNullable<
  SupplierDetailInfoFragment["mentions"][0]
>;
export type SupplierDetailInfoActivity = (NonNullable<
  SupplierDetailInfoFragment["mentions"][0]
>)["activity"];
export type SupplierDetailInfoRelativeInfo = NonNullable<
  (NonNullable<
    SupplierDetailInfoFragment["mentions"][0]
  >)["activity"]["relativeInfo"]
>;
export type SupplierDetailInfoActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["relativeInfo"]
    >,
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type SupplierDetailInfoData = (NonNullable<
  SupplierDetailInfoFragment["mentions"][0]
>)["activity"]["data"];
export type SupplierDetailInfoAssignInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>;
export type SupplierDetailInfoItemInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>)["itemInfo"];
export type SupplierDetailInfoAssignerInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>)["assignerInfo"];
export type SupplierDetailInfoAssigneeInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["data"],
      "__typename"
    >,
    { __typename: "ASSIGN" }
  >)["assigneeInfo"]
>;
export type SupplierDetailInfoDrag_CardInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "DRAG_CARD" }
>;
export type SupplierDetailInfoRequestorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "DRAG_CARD" }
>)["requestorInfo"];
export type SupplierDetailInfo_AssigneeInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["data"],
      "__typename"
    >,
    { __typename: "DRAG_CARD" }
  >)["assigneeInfo"]
>;
export type SupplierDetailInfoCommentInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>;
export type SupplierDetailInfoUser = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>)["user"];
export type SupplierDetailInfo_RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>)["relativeInfo"];
export type SupplierDetailInfo_ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          SupplierDetailInfoFragment["mentions"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type SupplierDetailInfoActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          SupplierDetailInfoFragment["mentions"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type SupplierDetailInfoActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          SupplierDetailInfoFragment["mentions"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type SupplierDetailInfoAdd_ItemInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEM" }
>;
export type SupplierDetailInfoCreator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEM" }
>)["creator"];
export type SupplierDetailInfoAdd_AttachmentInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "ADD_ATTACHMENT" }
>;
export type SupplierDetailInfoEmail_Inbound_MentionInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>;
export type SupplierDetailInfo_User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["user"];
export type SupplierDetailInfo__RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["relativeInfo"];
export type SupplierDetailInfo__ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          SupplierDetailInfoFragment["mentions"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type SupplierDetailInfo_ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          SupplierDetailInfoFragment["mentions"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type SupplierDetailInfo_ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          SupplierDetailInfoFragment["mentions"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type SupplierDetailInfoEmailInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["emailInfo"];
export type SupplierDetailInfoEmail_Inbound_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>;
export type SupplierDetailInfoSupplierInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>)["supplierInfo"];
export type SupplierDetailInfo_EmailInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>)["emailInfo"];
export type SupplierDetailInfoEmail_Inbound_RequesterInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_REQUESTER" }
>;
export type SupplierDetailInfoEmail_Outbound_MentionInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_MENTION" }
>;
export type SupplierDetailInfoEmail_Outbound_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>;
export type SupplierDetailInfoRfxInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["data"],
      "__typename"
    >,
    { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
  >)["rfxInfo"]
>;
export type SupplierDetailInfoOrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["data"],
      "__typename"
    >,
    { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
  >)["orderInfo"]
>;
export type SupplierDetailInfo_SupplierInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>)["supplierInfo"];
export type SupplierDetailInfo_Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>)["creator"];
export type SupplierDetailInfoReplyInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>;
export type SupplierDetailInfo__User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>)["user"];
export type SupplierDetailInfo___RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>)["relativeInfo"];
export type SupplierDetailInfo___ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          SupplierDetailInfoFragment["mentions"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type SupplierDetailInfo__ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          SupplierDetailInfoFragment["mentions"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type SupplierDetailInfo__ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          SupplierDetailInfoFragment["mentions"][0]
        >)["activity"]["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type SupplierDetailInfoAdd_ItemsInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEMS" }
>;
export type SupplierDetailInfo__Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEMS" }
>)["creator"];
export type SupplierDetailInfoCreate_Purchase_RequestInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST" }
>;
export type SupplierDetailInfo___Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST" }
>)["creator"];
export type SupplierDetailInfoCreate_Purchase_Request_ProductInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_PRODUCT" }
>;
export type SupplierDetailInfo____Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_PRODUCT" }
>)["creator"];
export type SupplierDetailInfoCreate_Purchase_Request_ServiceInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_SERVICE" }
>;
export type SupplierDetailInfo_____Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_SERVICE" }
>)["creator"];
export type SupplierDetailInfoDrag_ItemInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "DRAG_ITEM" }
>;
export type SupplierDetailInfoCreate_Approval_WorkflowInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>;
export type SupplierDetailInfoCreatorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["creatorInfo"];
export type SupplierDetailInfoApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowInfo"];
export type SupplierDetailInfoApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowStats"];
export type SupplierDetailInfoUpdate_Approval_WorkflowInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>;
export type SupplierDetailInfo_CreatorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["creatorInfo"];
export type SupplierDetailInfo_ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowInfo"];
export type SupplierDetailInfoRelativeCardInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["relativeCardInfo"];
export type SupplierDetailInfo_ApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowStats"];
export type SupplierDetailInfoCreate_Approval_RequestInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>;
export type SupplierDetailInfoApproverInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approverInfo"];
export type SupplierDetailInfoApprovalRequestInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approvalRequestInfo"];
export type SupplierDetailInfo__ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approvalWorkflowInfo"];
export type SupplierDetailInfo__ApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approvalWorkflowStats"];
export type SupplierDetailInfoUpdate_Approval_RequestInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>;
export type SupplierDetailInfo_ApproverInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approverInfo"];
export type SupplierDetailInfo_ApprovalRequestInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approvalRequestInfo"];
export type SupplierDetailInfo___ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approvalWorkflowInfo"];
export type SupplierDetailInfo___ApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approvalWorkflowStats"];
export type SupplierDetailInfoCreate_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_SUPPLIER" }
>;
export type SupplierDetailInfo______Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_SUPPLIER" }
>)["creator"];
export type SupplierDetailInfoUpdate_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_SUPPLIER" }
>;
export type SupplierDetailInfo_______Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_SUPPLIER" }
>)["creator"];
export type SupplierDetailInfoChangedFields = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["data"],
      "__typename"
    >,
    { __typename: "UPDATE_SUPPLIER" }
  >)["changedFields"][0]
>;
export type SupplierDetailInfoUpdate_Order_Received_QuantitiesInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
>;
export type SupplierDetailInfo___User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
>)["user"];
export type SupplierDetailInfo_OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["data"],
      "__typename"
    >,
    { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
  >)["orderInfo"]
>;
export type SupplierDetailInfoCreate_Invoice_From_OrderInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_INVOICE_FROM_ORDER" }
>;
export type SupplierDetailInfo____User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["data"],
    "__typename"
  >,
  { __typename: "CREATE_INVOICE_FROM_ORDER" }
>)["user"];
export type SupplierDetailInfo__OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["data"],
      "__typename"
    >,
    { __typename: "CREATE_INVOICE_FROM_ORDER" }
  >)["orderInfo"]
>;
export type SupplierDetailInfoAuthor = NonNullable<
  (NonNullable<SupplierDetailInfoFragment["mentions"][0]>)["activity"]["author"]
>;
export type SupplierDetailInfoAttachments = NonNullable<
  (NonNullable<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["attachments"]
  >)[0]
>;
export type SupplierDetailInfoReplies = NonNullable<
  (NonNullable<
    SupplierDetailInfoFragment["mentions"][0]
  >)["activity"]["replies"][0]
>;
export type SupplierDetailInfo_Attachments = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["attachments"]
  >)[0]
>;
export type SupplierDetailInfo_Data = (NonNullable<
  (NonNullable<
    SupplierDetailInfoFragment["mentions"][0]
  >)["activity"]["replies"][0]
>)["data"];
export type SupplierDetailInfo_AssignInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>;
export type SupplierDetailInfo_ItemInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>)["itemInfo"];
export type SupplierDetailInfo_AssignerInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>)["assignerInfo"];
export type SupplierDetailInfo__AssigneeInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          SupplierDetailInfoFragment["mentions"][0]
        >)["activity"]["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "ASSIGN" }
  >)["assigneeInfo"]
>;
export type SupplierDetailInfo_Drag_CardInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "DRAG_CARD" }
>;
export type SupplierDetailInfo_RequestorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "DRAG_CARD" }
>)["requestorInfo"];
export type SupplierDetailInfo___AssigneeInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          SupplierDetailInfoFragment["mentions"][0]
        >)["activity"]["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "DRAG_CARD" }
  >)["assigneeInfo"]
>;
export type SupplierDetailInfo_CommentInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>;
export type SupplierDetailInfo_____User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>)["user"];
export type SupplierDetailInfo____RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>)["relativeInfo"];
export type SupplierDetailInfo____ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["mentions"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type SupplierDetailInfo___ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["mentions"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type SupplierDetailInfo___ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["mentions"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type SupplierDetailInfo_Add_ItemInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEM" }
>;
export type SupplierDetailInfo________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEM" }
>)["creator"];
export type SupplierDetailInfo_Add_AttachmentInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ADD_ATTACHMENT" }
>;
export type SupplierDetailInfo_Email_Inbound_MentionInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>;
export type SupplierDetailInfo______User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["user"];
export type SupplierDetailInfo_____RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["relativeInfo"];
export type SupplierDetailInfo_____ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["mentions"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type SupplierDetailInfo____ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["mentions"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type SupplierDetailInfo____ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["mentions"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type SupplierDetailInfo__EmailInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["emailInfo"];
export type SupplierDetailInfo_Email_Inbound_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>;
export type SupplierDetailInfo__SupplierInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>)["supplierInfo"];
export type SupplierDetailInfo___EmailInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>)["emailInfo"];
export type SupplierDetailInfo_Email_Inbound_RequesterInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_REQUESTER" }
>;
export type SupplierDetailInfo_Email_Outbound_MentionInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_MENTION" }
>;
export type SupplierDetailInfo_Email_Outbound_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>;
export type SupplierDetailInfo_RfxInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          SupplierDetailInfoFragment["mentions"][0]
        >)["activity"]["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
  >)["rfxInfo"]
>;
export type SupplierDetailInfo___OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          SupplierDetailInfoFragment["mentions"][0]
        >)["activity"]["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
  >)["orderInfo"]
>;
export type SupplierDetailInfo___SupplierInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>)["supplierInfo"];
export type SupplierDetailInfo_________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>)["creator"];
export type SupplierDetailInfo_ReplyInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>;
export type SupplierDetailInfo_______User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>)["user"];
export type SupplierDetailInfo______RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>)["relativeInfo"];
export type SupplierDetailInfo______ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["mentions"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type SupplierDetailInfo_____ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["mentions"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type SupplierDetailInfo_____ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["mentions"][0]
          >)["activity"]["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type SupplierDetailInfo_Add_ItemsInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEMS" }
>;
export type SupplierDetailInfo__________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEMS" }
>)["creator"];
export type SupplierDetailInfo_Create_Purchase_RequestInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST" }
>;
export type SupplierDetailInfo___________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST" }
>)["creator"];
export type SupplierDetailInfo_Create_Purchase_Request_ProductInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_PRODUCT" }
>;
export type SupplierDetailInfo____________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_PRODUCT" }
>)["creator"];
export type SupplierDetailInfo_Create_Purchase_Request_ServiceInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_SERVICE" }
>;
export type SupplierDetailInfo_____________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_SERVICE" }
>)["creator"];
export type SupplierDetailInfo_Drag_ItemInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "DRAG_ITEM" }
>;
export type SupplierDetailInfo_Create_Approval_WorkflowInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>;
export type SupplierDetailInfo__CreatorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["creatorInfo"];
export type SupplierDetailInfo____ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowInfo"];
export type SupplierDetailInfo_Create_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_SUPPLIER" }
>;
export type SupplierDetailInfo______________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_SUPPLIER" }
>)["creator"];
export type SupplierDetailInfo_Update_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_SUPPLIER" }
>;
export type SupplierDetailInfo_______________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_SUPPLIER" }
>)["creator"];
export type SupplierDetailInfo_ChangedFields = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          SupplierDetailInfoFragment["mentions"][0]
        >)["activity"]["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "UPDATE_SUPPLIER" }
  >)["changedFields"][0]
>;
export type SupplierDetailInfo_Update_Order_Received_QuantitiesInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
>;
export type SupplierDetailInfo________User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
>)["user"];
export type SupplierDetailInfo____OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          SupplierDetailInfoFragment["mentions"][0]
        >)["activity"]["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
  >)["orderInfo"]
>;
export type SupplierDetailInfo_Create_Invoice_From_OrderInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_INVOICE_FROM_ORDER" }
>;
export type SupplierDetailInfo_________User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<
        SupplierDetailInfoFragment["mentions"][0]
      >)["activity"]["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_INVOICE_FROM_ORDER" }
>)["user"];
export type SupplierDetailInfo_____OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<
          SupplierDetailInfoFragment["mentions"][0]
        >)["activity"]["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "CREATE_INVOICE_FROM_ORDER" }
  >)["orderInfo"]
>;
export type SupplierDetailInfo_Author = NonNullable<
  (NonNullable<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["replies"][0]
  >)["author"]
>;
export type SupplierDetailInfo_Replies = NonNullable<
  (NonNullable<
    (NonNullable<
      SupplierDetailInfoFragment["mentions"][0]
    >)["activity"]["replies"][0]
  >)["replies"][0]
>;
export type SupplierDetailInfoActivities = NonNullable<
  SupplierDetailInfoFragment["activities"][0]
>;
export type SupplierDetailInfo__Author = NonNullable<
  (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["author"]
>;
export type SupplierDetailInfo__Attachments = NonNullable<
  (NonNullable<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["attachments"]
  >)[0]
>;
export type SupplierDetailInfo__Replies = NonNullable<
  (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
>;
export type SupplierDetailInfo___Attachments = NonNullable<
  (NonNullable<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["attachments"]
  >)[0]
>;
export type SupplierDetailInfo__Data = (NonNullable<
  (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
>)["data"];
export type SupplierDetailInfo__AssignInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>;
export type SupplierDetailInfo__ItemInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>)["itemInfo"];
export type SupplierDetailInfo__AssignerInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>)["assignerInfo"];
export type SupplierDetailInfo____AssigneeInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "ASSIGN" }
  >)["assigneeInfo"]
>;
export type SupplierDetailInfo__Drag_CardInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "DRAG_CARD" }
>;
export type SupplierDetailInfo__RequestorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "DRAG_CARD" }
>)["requestorInfo"];
export type SupplierDetailInfo_____AssigneeInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "DRAG_CARD" }
  >)["assigneeInfo"]
>;
export type SupplierDetailInfo__CommentInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>;
export type SupplierDetailInfo__________User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>)["user"];
export type SupplierDetailInfo_______RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>)["relativeInfo"];
export type SupplierDetailInfo_______ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["activities"][0]
          >)["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type SupplierDetailInfo______ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["activities"][0]
          >)["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type SupplierDetailInfo______ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["activities"][0]
          >)["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type SupplierDetailInfo__Add_ItemInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEM" }
>;
export type SupplierDetailInfo________________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEM" }
>)["creator"];
export type SupplierDetailInfo__Add_AttachmentInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ADD_ATTACHMENT" }
>;
export type SupplierDetailInfo__Email_Inbound_MentionInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>;
export type SupplierDetailInfo___________User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["user"];
export type SupplierDetailInfo________RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["relativeInfo"];
export type SupplierDetailInfo________ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["activities"][0]
          >)["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type SupplierDetailInfo_______ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["activities"][0]
          >)["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type SupplierDetailInfo_______ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["activities"][0]
          >)["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type SupplierDetailInfo____EmailInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["emailInfo"];
export type SupplierDetailInfo__Email_Inbound_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>;
export type SupplierDetailInfo____SupplierInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>)["supplierInfo"];
export type SupplierDetailInfo_____EmailInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>)["emailInfo"];
export type SupplierDetailInfo__Email_Inbound_RequesterInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_REQUESTER" }
>;
export type SupplierDetailInfo__Email_Outbound_MentionInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_MENTION" }
>;
export type SupplierDetailInfo__Email_Outbound_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>;
export type SupplierDetailInfo__RfxInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
  >)["rfxInfo"]
>;
export type SupplierDetailInfo______OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
  >)["orderInfo"]
>;
export type SupplierDetailInfo_____SupplierInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>)["supplierInfo"];
export type SupplierDetailInfo_________________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>)["creator"];
export type SupplierDetailInfo__ReplyInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>;
export type SupplierDetailInfo____________User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>)["user"];
export type SupplierDetailInfo_________RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>)["relativeInfo"];
export type SupplierDetailInfo_________ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["activities"][0]
          >)["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type SupplierDetailInfo________ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["activities"][0]
          >)["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type SupplierDetailInfo________ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<
          (NonNullable<
            SupplierDetailInfoFragment["activities"][0]
          >)["replies"][0]
        >)["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type SupplierDetailInfo__Add_ItemsInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEMS" }
>;
export type SupplierDetailInfo__________________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEMS" }
>)["creator"];
export type SupplierDetailInfo__Create_Purchase_RequestInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST" }
>;
export type SupplierDetailInfo___________________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST" }
>)["creator"];
export type SupplierDetailInfo__Create_Purchase_Request_ProductInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_PRODUCT" }
>;
export type SupplierDetailInfo____________________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_PRODUCT" }
>)["creator"];
export type SupplierDetailInfo__Create_Purchase_Request_ServiceInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_SERVICE" }
>;
export type SupplierDetailInfo_____________________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_SERVICE" }
>)["creator"];
export type SupplierDetailInfo__Drag_ItemInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "DRAG_ITEM" }
>;
export type SupplierDetailInfo__Create_Approval_WorkflowInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>;
export type SupplierDetailInfo___CreatorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["creatorInfo"];
export type SupplierDetailInfo_____ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowInfo"];
export type SupplierDetailInfo__Create_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_SUPPLIER" }
>;
export type SupplierDetailInfo______________________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_SUPPLIER" }
>)["creator"];
export type SupplierDetailInfo__Update_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_SUPPLIER" }
>;
export type SupplierDetailInfo_______________________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_SUPPLIER" }
>)["creator"];
export type SupplierDetailInfo__ChangedFields = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "UPDATE_SUPPLIER" }
  >)["changedFields"][0]
>;
export type SupplierDetailInfo__Update_Order_Received_QuantitiesInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
>;
export type SupplierDetailInfo_____________User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
>)["user"];
export type SupplierDetailInfo_______OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
  >)["orderInfo"]
>;
export type SupplierDetailInfo__Create_Invoice_From_OrderInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_INVOICE_FROM_ORDER" }
>;
export type SupplierDetailInfo______________User = (DiscriminateUnion<
  RequireField<
    (NonNullable<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
    >)["data"],
    "__typename"
  >,
  { __typename: "CREATE_INVOICE_FROM_ORDER" }
>)["user"];
export type SupplierDetailInfo________OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<
        (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
      >)["data"],
      "__typename"
    >,
    { __typename: "CREATE_INVOICE_FROM_ORDER" }
  >)["orderInfo"]
>;
export type SupplierDetailInfo___Author = NonNullable<
  (NonNullable<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
  >)["author"]
>;
export type SupplierDetailInfo___Replies = NonNullable<
  (NonNullable<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["replies"][0]
  >)["replies"][0]
>;
export type SupplierDetailInfo___Data = (NonNullable<
  SupplierDetailInfoFragment["activities"][0]
>)["data"];
export type SupplierDetailInfo___AssignInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>;
export type SupplierDetailInfo___ItemInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>)["itemInfo"];
export type SupplierDetailInfo___AssignerInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "ASSIGN" }
>)["assignerInfo"];
export type SupplierDetailInfo______AssigneeInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
      "__typename"
    >,
    { __typename: "ASSIGN" }
  >)["assigneeInfo"]
>;
export type SupplierDetailInfo___Drag_CardInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "DRAG_CARD" }
>;
export type SupplierDetailInfo___RequestorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "DRAG_CARD" }
>)["requestorInfo"];
export type SupplierDetailInfo_______AssigneeInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
      "__typename"
    >,
    { __typename: "DRAG_CARD" }
  >)["assigneeInfo"]
>;
export type SupplierDetailInfo___CommentInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>;
export type SupplierDetailInfo_______________User = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>)["user"];
export type SupplierDetailInfo__________RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "COMMENT" }
>)["relativeInfo"];
export type SupplierDetailInfo__________ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type SupplierDetailInfo_________ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type SupplierDetailInfo_________ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
        "__typename"
      >,
      { __typename: "COMMENT" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type SupplierDetailInfo___Add_ItemInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEM" }
>;
export type SupplierDetailInfo________________________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEM" }
>)["creator"];
export type SupplierDetailInfo___Add_AttachmentInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "ADD_ATTACHMENT" }
>;
export type SupplierDetailInfo___Email_Inbound_MentionInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>;
export type SupplierDetailInfo________________User = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["user"];
export type SupplierDetailInfo___________RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["relativeInfo"];
export type SupplierDetailInfo___________ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type SupplierDetailInfo__________ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type SupplierDetailInfo__________ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
        "__typename"
      >,
      { __typename: "EMAIL_INBOUND_MENTION" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type SupplierDetailInfo______EmailInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_MENTION" }
>)["emailInfo"];
export type SupplierDetailInfo___Email_Inbound_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>;
export type SupplierDetailInfo______SupplierInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>)["supplierInfo"];
export type SupplierDetailInfo_______EmailInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_SUPPLIER" }
>)["emailInfo"];
export type SupplierDetailInfo___Email_Inbound_RequesterInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_INBOUND_REQUESTER" }
>;
export type SupplierDetailInfo___Email_Outbound_MentionInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_MENTION" }
>;
export type SupplierDetailInfo___Email_Outbound_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>;
export type SupplierDetailInfo___RfxInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
      "__typename"
    >,
    { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
  >)["rfxInfo"]
>;
export type SupplierDetailInfo_________OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
      "__typename"
    >,
    { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
  >)["orderInfo"]
>;
export type SupplierDetailInfo_______SupplierInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>)["supplierInfo"];
export type SupplierDetailInfo_________________________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "EMAIL_OUTBOUND_SUPPLIER" }
>)["creator"];
export type SupplierDetailInfo___ReplyInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>;
export type SupplierDetailInfo_________________User = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>)["user"];
export type SupplierDetailInfo____________RelativeInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "REPLY" }
>)["relativeInfo"];
export type SupplierDetailInfo____________ActivityRelativeCardInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeCardInfo" }
>;
export type SupplierDetailInfo___________ActivityRelativeSupplierInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeSupplierInfo" }
>;
export type SupplierDetailInfo___________ActivityRelativeItemInfoInlineFragment = DiscriminateUnion<
  RequireField<
    (DiscriminateUnion<
      RequireField<
        (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
        "__typename"
      >,
      { __typename: "REPLY" }
    >)["relativeInfo"],
    "__typename"
  >,
  { __typename: "ActivityRelativeItemInfo" }
>;
export type SupplierDetailInfo___Add_ItemsInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEMS" }
>;
export type SupplierDetailInfo__________________________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "ADD_ITEMS" }
>)["creator"];
export type SupplierDetailInfo___Create_Purchase_RequestInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST" }
>;
export type SupplierDetailInfo___________________________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST" }
>)["creator"];
export type SupplierDetailInfo___Create_Purchase_Request_ProductInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_PRODUCT" }
>;
export type SupplierDetailInfo____________________________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_PRODUCT" }
>)["creator"];
export type SupplierDetailInfo___Create_Purchase_Request_ServiceInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_SERVICE" }
>;
export type SupplierDetailInfo_____________________________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_PURCHASE_REQUEST_SERVICE" }
>)["creator"];
export type SupplierDetailInfo___Drag_ItemInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "DRAG_ITEM" }
>;
export type SupplierDetailInfo___Create_Approval_WorkflowInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>;
export type SupplierDetailInfo____CreatorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["creatorInfo"];
export type SupplierDetailInfo______ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowInfo"];
export type SupplierDetailInfo____ApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowStats"];
export type SupplierDetailInfo_Update_Approval_WorkflowInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>;
export type SupplierDetailInfo_____CreatorInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["creatorInfo"];
export type SupplierDetailInfo_______ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowInfo"];
export type SupplierDetailInfo_RelativeCardInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["relativeCardInfo"];
export type SupplierDetailInfo_____ApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_WORKFLOW" }
>)["approvalWorkflowStats"];
export type SupplierDetailInfo_Create_Approval_RequestInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>;
export type SupplierDetailInfo__ApproverInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approverInfo"];
export type SupplierDetailInfo__ApprovalRequestInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approvalRequestInfo"];
export type SupplierDetailInfo________ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approvalWorkflowInfo"];
export type SupplierDetailInfo______ApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_APPROVAL_REQUEST" }
>)["approvalWorkflowStats"];
export type SupplierDetailInfo_Update_Approval_RequestInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>;
export type SupplierDetailInfo___ApproverInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approverInfo"];
export type SupplierDetailInfo___ApprovalRequestInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approvalRequestInfo"];
export type SupplierDetailInfo_________ApprovalWorkflowInfo = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approvalWorkflowInfo"];
export type SupplierDetailInfo_______ApprovalWorkflowStats = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_APPROVAL_REQUEST" }
>)["approvalWorkflowStats"];
export type SupplierDetailInfo___Create_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_SUPPLIER" }
>;
export type SupplierDetailInfo______________________________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_SUPPLIER" }
>)["creator"];
export type SupplierDetailInfo___Update_SupplierInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_SUPPLIER" }
>;
export type SupplierDetailInfo_______________________________Creator = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_SUPPLIER" }
>)["creator"];
export type SupplierDetailInfo___ChangedFields = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
      "__typename"
    >,
    { __typename: "UPDATE_SUPPLIER" }
  >)["changedFields"][0]
>;
export type SupplierDetailInfo___Update_Order_Received_QuantitiesInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
>;
export type SupplierDetailInfo__________________User = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
>)["user"];
export type SupplierDetailInfo__________OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
      "__typename"
    >,
    { __typename: "UPDATE_ORDER_RECEIVED_QUANTITIES" }
  >)["orderInfo"]
>;
export type SupplierDetailInfo___Create_Invoice_From_OrderInlineFragment = DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_INVOICE_FROM_ORDER" }
>;
export type SupplierDetailInfo___________________User = (DiscriminateUnion<
  RequireField<
    (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
    "__typename"
  >,
  { __typename: "CREATE_INVOICE_FROM_ORDER" }
>)["user"];
export type SupplierDetailInfo___________OrderInfo = NonNullable<
  (DiscriminateUnion<
    RequireField<
      (NonNullable<SupplierDetailInfoFragment["activities"][0]>)["data"],
      "__typename"
    >,
    { __typename: "CREATE_INVOICE_FROM_ORDER" }
  >)["orderInfo"]
>;
export type SupplierByIdVariables = SupplierByIdQueryVariables;
export type SupplierByIdViewer = NonNullable<SupplierByIdQuery["viewer"]>;
export type SupplierByIdOrganization = (NonNullable<
  SupplierByIdQuery["viewer"]
>)["organization"];
export type SupplierByIdCategories = NonNullable<
  (NonNullable<SupplierByIdQuery["viewer"]>)["organization"]["categories"][0]
>;
export type SupplierByIdCountries = NonNullable<
  (NonNullable<SupplierByIdQuery["viewer"]>)["countries"][0]
>;
export type SupplierByIdSupplierById = SupplierDetailInfoFragment;
export const useSupplierById = useSupplierByIdQuery;
export type SupplierCategoriesVariables = SupplierCategoriesQueryVariables;
export type SupplierCategoriesViewer = NonNullable<
  SupplierCategoriesQuery["viewer"]
>;
export type SupplierCategoriesSupplierById = (NonNullable<
  SupplierCategoriesQuery["viewer"]
>)["supplierById"];
export type SupplierCategoriesCategories = NonNullable<
  (NonNullable<
    SupplierCategoriesQuery["viewer"]
  >)["supplierById"]["categories"][0]
>;
export const useSupplierCategories = useSupplierCategoriesQuery;
export type UpdateContactPersonVariables = UpdateContactPersonMutationVariables;
export type UpdateContactPersonUpdateContactPerson = ContactPersonInfoFragment;
export const useUpdateContactPerson = useUpdateContactPersonMutation;
export type UploadSuppliersExcelVariables = UploadSuppliersExcelMutationVariables;
export type UploadSuppliersExcelUploadSupplierExcel = UploadSuppliersExcelMutation["uploadSupplierExcel"];
export type UploadSuppliersExcelRows = NonNullable<
  UploadSuppliersExcelMutation["uploadSupplierExcel"]["rows"][0]
>;
export type UploadSuppliersExcelErrors = (NonNullable<
  UploadSuppliersExcelMutation["uploadSupplierExcel"]["rows"][0]
>)["errors"];
export const useUploadSuppliersExcel = useUploadSuppliersExcelMutation;
export type UpdateUserProfileVariables = UpdateUserProfileMutationVariables;
export type UpdateUserProfileUpdateProfile = UpdateUserProfileMutation["updateProfile"];
export const useUpdateUserProfile = useUpdateUserProfileMutation;
export type UserUpdateProfilePictureVariables = UserUpdateProfilePictureMutationVariables;
export type UserUpdateProfilePictureUpdateProfilePicture = UserUpdateProfilePictureMutation["updateProfilePicture"];
export const useUserUpdateProfilePicture = useUserUpdateProfilePictureMutation;
export const AddressInfoFragmentDoc: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddressInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "locationName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "recipientName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "city" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "areaCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countryCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "addressType" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "country" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ActivityFeedItemFragmentDoc: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ActivityFeedItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ActivityFeed" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "userId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "parentId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "relativeId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "relativeType" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "type" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "attachments" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filename" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mimetype" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "url" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "data" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ASSIGN" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assignerInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "DRAG_CARD" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "COMMENT" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ITEM" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "addItemQuantity" },
                        name: { kind: "Name", value: "quantity" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "addItemUnit" },
                        name: { kind: "Name", value: "unit" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ATTACHMENT" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "encoding" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "size" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_MENTION" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_REQUESTER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "emailInboundRequesterInfo",
                        },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_OUTBOUND_MENTION" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "emailOutboundMentionInfo",
                        },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_OUTBOUND_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subject" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rfxInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_APPROVAL_WORKFLOW" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_APPROVAL_WORKFLOW" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeCardInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_APPROVAL_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approverInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalRequestInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dueDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_APPROVAL_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approverInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalRequestInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceGroup" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceNumber" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approverComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dueDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decisionDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decision" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "REPLY" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ITEMS" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemCount" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_PURCHASE_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unit" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequestCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "CREATE_PURCHASE_REQUEST_SERVICE",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequestCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "DRAG_ITEM" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardName" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "supplierName" },
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "supplierName" },
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "changedFields" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fieldName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "oldValue" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "newValue" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_INVOICE_FROM_ORDER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "replies" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeType" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "type" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attachments" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ASSIGN" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assignerInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_CARD" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "COMMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemQuantity" },
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemUnit" },
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ATTACHMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encoding" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "size" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_REQUESTER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailInboundRequesterInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailOutboundMentionInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfxInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "REPLY" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEMS" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemCount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_SERVICE",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_APPROVAL_WORKFLOW",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "CREATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "changedFields" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fieldName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "oldValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "newValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_INVOICE_FROM_ORDER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "author" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "replies" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const PurchaseRequestItemInfoFragmentDoc: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PurchaseRequestItemInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PurchaseRequestItem" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "parentCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "type" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "quantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "receivedQuantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "totalReceivedQuantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "unit" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "assignee" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedDeliveryDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "productUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "unitPrice" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currency" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};
export const UserInfoFragmentDoc: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatarUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "department" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "costCentre" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const SupplierInfoFragmentDoc: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SupplierInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Supplier" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentTerms" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "defaultContactPerson" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ProjectInfoFragmentDoc: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProjectInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Project" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};
export const DepartmentInfoFragmentDoc: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DepartmentInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Department" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "code" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};
export const ContactPersonInfoFragmentDoc: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ContactPersonInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ContactPerson" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "position" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "phoneNumber" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "notes" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};
export const SupplierDetailInfoFragmentDoc: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SupplierDetailInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Supplier" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "defaultContactPerson" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "webAddress" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "city" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "areaCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countryCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentTerms" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currency" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "vatIdentification" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "businessRegistry" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "leiCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "dunsCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "erpSupplierCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "customFields" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "notes" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "contactPersons" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "position" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "phoneNumber" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notes" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "mentions" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "activityId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mentionedUserId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "taggedSupplierId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "activity" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parentId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "assigneeId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestorId",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "data" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ASSIGN" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "itemInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assignerInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assigneeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "DRAG_CARD" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromType" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toType" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromCode" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toCode" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestorInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assigneeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "COMMENT" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ITEM" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "addItemQuantity",
                                    },
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "addItemUnit",
                                    },
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ATTACHMENT" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "filename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "encoding" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mimetype" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "size" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_MENTION",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "emailInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subject",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "message",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "supplierInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "emailInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subject",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "message",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_REQUESTER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "emailInboundRequesterInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_OUTBOUND_MENTION",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "emailOutboundMentionInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_OUTBOUND_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "rfxInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "supplierInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "REPLY" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ITEMS" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "itemCount" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "purchaseRequestCode",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST_SERVICE",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "purchaseRequestCode",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "DRAG_ITEM" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_APPROVAL_WORKFLOW",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creatorInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalAmount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowStats",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvedRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_APPROVAL_WORKFLOW",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creatorInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalAmount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeCardInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowStats",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvedRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_APPROVAL_REQUEST",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approverInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalRequestInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "sequenceGroup",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "sequenceNumber",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creatorComment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approverComment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dueDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "decisionDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "decision",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalAmount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowStats",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvedRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_APPROVAL_REQUEST",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approverInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalRequestInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "sequenceGroup",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "sequenceNumber",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creatorComment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approverComment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dueDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "decisionDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "decision",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalAmount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowStats",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvedRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "supplierName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "supplierName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "changedFields",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fieldName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "oldValue",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "newValue",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_INVOICE_FROM_ORDER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "author" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attachments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "replies" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "organizationId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "parentId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attachments" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "filename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mimetype" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "data" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "ASSIGN" },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "itemInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "type",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "assignerInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "assigneeInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "DRAG_CARD",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "cardName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fromType",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "toType",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fromCode",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "toCode",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestorInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "assigneeInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "COMMENT" },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "user" },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "relativeInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeCardInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeCardInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeSupplierInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeSupplierInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeItemInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeItemInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "quantity",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unit",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "ADD_ITEM" },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "addItemQuantity",
                                          },
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "addItemUnit",
                                          },
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ADD_ATTACHMENT",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "filename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "encoding",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "mimetype",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "size" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "EMAIL_INBOUND_MENTION",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "user" },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "relativeInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeCardInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeCardInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeSupplierInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeSupplierInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeItemInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeItemInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "quantity",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unit",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "emailInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "subject",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "message",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "EMAIL_INBOUND_SUPPLIER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "supplierInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "emailInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "subject",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "message",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "EMAIL_INBOUND_REQUESTER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "emailInboundRequesterInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "EMAIL_OUTBOUND_MENTION",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "emailOutboundMentionInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "EMAIL_OUTBOUND_SUPPLIER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subject",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "message",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "rfxInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "type",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "orderInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "type",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "supplierInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "REPLY" },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "user" },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "relativeInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeCardInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeCardInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeSupplierInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeSupplierInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeItemInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeItemInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "quantity",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unit",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ADD_ITEMS",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "itemCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "CREATE_PURCHASE_REQUEST",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value:
                                          "CREATE_PURCHASE_REQUEST_PRODUCT",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "purchaseRequestCode",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value:
                                          "CREATE_PURCHASE_REQUEST_SERVICE",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "purchaseRequestCode",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "DRAG_ITEM",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "cardName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "CREATE_APPROVAL_WORKFLOW",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creatorInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalWorkflowInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "requestName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "comment",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "approvalAmount",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "status",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "CREATE_SUPPLIER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "supplierName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "UPDATE_SUPPLIER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "supplierName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "changedFields",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "fieldName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "oldValue",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "newValue",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value:
                                          "UPDATE_ORDER_RECEIVED_QUANTITIES",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "user" },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "orderInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "type",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "CREATE_INVOICE_FROM_ORDER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "user" },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "orderInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "type",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "replies" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "activities" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeType" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "type" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "author" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attachments" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "replies" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parentId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attachments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "data" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ASSIGN" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "itemInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assignerInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assigneeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "DRAG_CARD" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromType" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toType" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromCode" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toCode" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestorInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assigneeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "COMMENT" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ITEM" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "addItemQuantity",
                                    },
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "addItemUnit",
                                    },
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ATTACHMENT" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "filename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "encoding" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mimetype" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "size" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_MENTION",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "emailInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subject",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "message",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "supplierInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "emailInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subject",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "message",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_REQUESTER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "emailInboundRequesterInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_OUTBOUND_MENTION",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "emailOutboundMentionInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_OUTBOUND_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "rfxInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "supplierInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "REPLY" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ITEMS" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "itemCount" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "purchaseRequestCode",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST_SERVICE",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "purchaseRequestCode",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "DRAG_ITEM" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_APPROVAL_WORKFLOW",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creatorInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalAmount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "supplierName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "supplierName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "changedFields",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fieldName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "oldValue",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "newValue",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_INVOICE_FROM_ORDER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "author" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "replies" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ASSIGN" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assignerInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_CARD" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "COMMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemQuantity" },
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemUnit" },
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ATTACHMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encoding" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "size" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_REQUESTER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailInboundRequesterInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailOutboundMentionInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfxInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "REPLY" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEMS" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemCount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_SERVICE",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_APPROVAL_WORKFLOW",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowStats",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "totalRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvedRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "UPDATE_APPROVAL_WORKFLOW",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeCardInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowStats",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "totalRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvedRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_APPROVAL_REQUEST",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approverInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalRequestInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sequenceGroup",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sequenceNumber",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creatorComment",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approverComment",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dueDate" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "decisionDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "decision" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowStats",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "totalRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvedRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "UPDATE_APPROVAL_REQUEST",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approverInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalRequestInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sequenceGroup",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sequenceNumber",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creatorComment",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approverComment",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dueDate" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "decisionDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "decision" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowStats",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "totalRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvedRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "CREATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "changedFields" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fieldName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "oldValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "newValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_INVOICE_FROM_ORDER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const KanbanCollaborationSidebarDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "KanbanCollaborationSidebar" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "take" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "activities" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "take" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "take" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ActivityFeedItem" },
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ActivityFeedItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ActivityFeed" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "userId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "parentId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "relativeId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "relativeType" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "type" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "attachments" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filename" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mimetype" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "url" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "data" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ASSIGN" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assignerInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "DRAG_CARD" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "COMMENT" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ITEM" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "addItemQuantity" },
                        name: { kind: "Name", value: "quantity" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "addItemUnit" },
                        name: { kind: "Name", value: "unit" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ATTACHMENT" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "encoding" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "size" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_MENTION" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_REQUESTER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "emailInboundRequesterInfo",
                        },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_OUTBOUND_MENTION" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "emailOutboundMentionInfo",
                        },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_OUTBOUND_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subject" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rfxInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_APPROVAL_WORKFLOW" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_APPROVAL_WORKFLOW" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeCardInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_APPROVAL_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approverInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalRequestInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dueDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_APPROVAL_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approverInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalRequestInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceGroup" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceNumber" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approverComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dueDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decisionDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decision" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "REPLY" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ITEMS" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemCount" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_PURCHASE_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unit" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequestCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "CREATE_PURCHASE_REQUEST_SERVICE",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequestCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "DRAG_ITEM" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardName" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "supplierName" },
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "supplierName" },
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "changedFields" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fieldName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "oldValue" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "newValue" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_INVOICE_FROM_ORDER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "replies" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeType" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "type" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attachments" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ASSIGN" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assignerInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_CARD" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "COMMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemQuantity" },
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemUnit" },
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ATTACHMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encoding" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "size" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_REQUESTER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailInboundRequesterInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailOutboundMentionInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfxInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "REPLY" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEMS" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemCount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_SERVICE",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_APPROVAL_WORKFLOW",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "CREATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "changedFields" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fieldName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "oldValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "newValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_INVOICE_FROM_ORDER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "author" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "replies" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useKanbanCollaborationSidebarQuery__
 *
 * To run a query within a React component, call `useKanbanCollaborationSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useKanbanCollaborationSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKanbanCollaborationSidebarQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useKanbanCollaborationSidebarQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    KanbanCollaborationSidebarQuery,
    KanbanCollaborationSidebarQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    KanbanCollaborationSidebarQuery,
    KanbanCollaborationSidebarQueryVariables
  >(KanbanCollaborationSidebarDocument, baseOptions);
}
export function useKanbanCollaborationSidebarLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    KanbanCollaborationSidebarQuery,
    KanbanCollaborationSidebarQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    KanbanCollaborationSidebarQuery,
    KanbanCollaborationSidebarQueryVariables
  >(KanbanCollaborationSidebarDocument, baseOptions);
}
export type KanbanCollaborationSidebarQueryHookResult = ReturnType<
  typeof useKanbanCollaborationSidebarQuery
>;
export type KanbanCollaborationSidebarLazyQueryHookResult = ReturnType<
  typeof useKanbanCollaborationSidebarLazyQuery
>;
export type KanbanCollaborationSidebarQueryResult = ApolloReactCommon.QueryResult<
  KanbanCollaborationSidebarQuery,
  KanbanCollaborationSidebarQueryVariables
>;
export const GetProjectsSuppliersDepartmentsDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetProjectsSuppliersDepartments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "projects" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suppliers" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "defaultContactPerson",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "departments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useGetProjectsSuppliersDepartmentsQuery__
 *
 * To run a query within a React component, call `useGetProjectsSuppliersDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsSuppliersDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsSuppliersDepartmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectsSuppliersDepartmentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetProjectsSuppliersDepartmentsQuery,
    GetProjectsSuppliersDepartmentsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetProjectsSuppliersDepartmentsQuery,
    GetProjectsSuppliersDepartmentsQueryVariables
  >(GetProjectsSuppliersDepartmentsDocument, baseOptions);
}
export function useGetProjectsSuppliersDepartmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProjectsSuppliersDepartmentsQuery,
    GetProjectsSuppliersDepartmentsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetProjectsSuppliersDepartmentsQuery,
    GetProjectsSuppliersDepartmentsQueryVariables
  >(GetProjectsSuppliersDepartmentsDocument, baseOptions);
}
export type GetProjectsSuppliersDepartmentsQueryHookResult = ReturnType<
  typeof useGetProjectsSuppliersDepartmentsQuery
>;
export type GetProjectsSuppliersDepartmentsLazyQueryHookResult = ReturnType<
  typeof useGetProjectsSuppliersDepartmentsLazyQuery
>;
export type GetProjectsSuppliersDepartmentsQueryResult = ApolloReactCommon.QueryResult<
  GetProjectsSuppliersDepartmentsQuery,
  GetProjectsSuppliersDepartmentsQueryVariables
>;
export const CreateOrUpdateSourcingEventDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateOrUpdateSourcingEvent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourcingEventId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rfxId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "additionalInfo" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "questionnaire" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "responseDeadline" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "selectedAttachmentIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "String" },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "uploadedAttachments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "Upload" },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createOrUpdateSourcingEvent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "sourcingEventId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sourcingEventId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "rfxId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rfxId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "additionalInfo" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "additionalInfo" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "questionnaire" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "questionnaire" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "responseDeadline" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "responseDeadline" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "selectedAttachmentIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "selectedAttachmentIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "uploadedAttachments" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uploadedAttachments" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateOrUpdateSourcingEventMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateSourcingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateSourcingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateSourcingEventMutation, { data, loading, error }] = useCreateOrUpdateSourcingEventMutation({
 *   variables: {
 *      sourcingEventId: // value for 'sourcingEventId'
 *      organizationId: // value for 'organizationId'
 *      rfxId: // value for 'rfxId'
 *      name: // value for 'name'
 *      additionalInfo: // value for 'additionalInfo'
 *      questionnaire: // value for 'questionnaire'
 *      responseDeadline: // value for 'responseDeadline'
 *      selectedAttachmentIds: // value for 'selectedAttachmentIds'
 *      uploadedAttachments: // value for 'uploadedAttachments'
 *   },
 * });
 */
export function useCreateOrUpdateSourcingEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrUpdateSourcingEventMutation,
    CreateOrUpdateSourcingEventMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateOrUpdateSourcingEventMutation,
    CreateOrUpdateSourcingEventMutationVariables
  >(CreateOrUpdateSourcingEventDocument, baseOptions);
}
export type CreateOrUpdateSourcingEventMutationHookResult = ReturnType<
  typeof useCreateOrUpdateSourcingEventMutation
>;
export type CreateOrUpdateSourcingEventMutationResult = ApolloReactCommon.MutationResult<
  CreateOrUpdateSourcingEventMutation
>;
export type CreateOrUpdateSourcingEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrUpdateSourcingEventMutation,
  CreateOrUpdateSourcingEventMutationVariables
>;
export const SelectDefaultContactDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SelectDefaultContact" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contactPersonId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "setDefaultContactPerson" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "contactPersonId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "contactPersonId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useSelectDefaultContactMutation__
 *
 * To run a mutation, you first call `useSelectDefaultContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectDefaultContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectDefaultContactMutation, { data, loading, error }] = useSelectDefaultContactMutation({
 *   variables: {
 *      contactPersonId: // value for 'contactPersonId'
 *   },
 * });
 */
export function useSelectDefaultContactMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SelectDefaultContactMutation,
    SelectDefaultContactMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SelectDefaultContactMutation,
    SelectDefaultContactMutationVariables
  >(SelectDefaultContactDocument, baseOptions);
}
export type SelectDefaultContactMutationHookResult = ReturnType<
  typeof useSelectDefaultContactMutation
>;
export type SelectDefaultContactMutationResult = ApolloReactCommon.MutationResult<
  SelectDefaultContactMutation
>;
export type SelectDefaultContactMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SelectDefaultContactMutation,
  SelectDefaultContactMutationVariables
>;
export const VersionDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Version" },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "version" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "server" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useVersionQuery__
 *
 * To run a query within a React component, call `useVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useVersionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    VersionQuery,
    VersionQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<VersionQuery, VersionQueryVariables>(
    VersionDocument,
    baseOptions,
  );
}
export function useVersionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VersionQuery,
    VersionQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<VersionQuery, VersionQueryVariables>(
    VersionDocument,
    baseOptions,
  );
}
export type VersionQueryHookResult = ReturnType<typeof useVersionQuery>;
export type VersionLazyQueryHookResult = ReturnType<typeof useVersionLazyQuery>;
export type VersionQueryResult = ApolloReactCommon.QueryResult<
  VersionQuery,
  VersionQueryVariables
>;
export const ActivitySubscriptionDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "subscription",
      name: { kind: "Name", value: "ActivitySubscription" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "relativeId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "activity" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "relativeId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "relativeId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ActivityFeedItem" },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ActivityFeedItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ActivityFeed" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "userId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "parentId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "relativeId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "relativeType" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "type" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "attachments" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filename" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mimetype" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "url" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "data" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ASSIGN" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assignerInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "DRAG_CARD" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "COMMENT" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ITEM" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "addItemQuantity" },
                        name: { kind: "Name", value: "quantity" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "addItemUnit" },
                        name: { kind: "Name", value: "unit" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ATTACHMENT" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "encoding" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "size" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_MENTION" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_REQUESTER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "emailInboundRequesterInfo",
                        },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_OUTBOUND_MENTION" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "emailOutboundMentionInfo",
                        },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_OUTBOUND_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subject" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rfxInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_APPROVAL_WORKFLOW" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_APPROVAL_WORKFLOW" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeCardInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_APPROVAL_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approverInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalRequestInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dueDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_APPROVAL_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approverInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalRequestInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceGroup" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceNumber" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approverComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dueDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decisionDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decision" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "REPLY" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ITEMS" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemCount" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_PURCHASE_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unit" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequestCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "CREATE_PURCHASE_REQUEST_SERVICE",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequestCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "DRAG_ITEM" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardName" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "supplierName" },
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "supplierName" },
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "changedFields" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fieldName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "oldValue" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "newValue" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_INVOICE_FROM_ORDER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "replies" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeType" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "type" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attachments" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ASSIGN" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assignerInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_CARD" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "COMMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemQuantity" },
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemUnit" },
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ATTACHMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encoding" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "size" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_REQUESTER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailInboundRequesterInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailOutboundMentionInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfxInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "REPLY" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEMS" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemCount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_SERVICE",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_APPROVAL_WORKFLOW",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "CREATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "changedFields" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fieldName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "oldValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "newValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_INVOICE_FROM_ORDER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "author" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "replies" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useActivitySubscriptionSubscription__
 *
 * To run a query within a React component, call `useActivitySubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useActivitySubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivitySubscriptionSubscription({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      relativeId: // value for 'relativeId'
 *   },
 * });
 */
export function useActivitySubscriptionSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    ActivitySubscriptionSubscription,
    ActivitySubscriptionSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    ActivitySubscriptionSubscription,
    ActivitySubscriptionSubscriptionVariables
  >(ActivitySubscriptionDocument, baseOptions);
}
export type ActivitySubscriptionSubscriptionHookResult = ReturnType<
  typeof useActivitySubscriptionSubscription
>;
export type ActivitySubscriptionSubscriptionResult = ApolloReactCommon.SubscriptionResult<
  ActivitySubscriptionSubscription
>;
export const NotificationSubscriptionDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "subscription",
      name: { kind: "Name", value: "NotificationSubscription" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "notification" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userId" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useNotificationSubscriptionSubscription__
 *
 * To run a query within a React component, call `useNotificationSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationSubscriptionSubscription({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useNotificationSubscriptionSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    NotificationSubscriptionSubscription,
    NotificationSubscriptionSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    NotificationSubscriptionSubscription,
    NotificationSubscriptionSubscriptionVariables
  >(NotificationSubscriptionDocument, baseOptions);
}
export type NotificationSubscriptionSubscriptionHookResult = ReturnType<
  typeof useNotificationSubscriptionSubscription
>;
export type NotificationSubscriptionSubscriptionResult = ApolloReactCommon.SubscriptionResult<
  NotificationSubscriptionSubscription
>;
export const SendEmailToRequesterDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SendEmailToRequester" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "parentId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardType" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "EmailCardTypeEnum" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "requesterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "toEmail" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "ccEmail" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "subject" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "message" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "Upload" },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendEmailToRequester" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "parentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "parentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardType" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardType" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "requesterId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "requesterId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "toEmail" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "toEmail" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "ccEmail" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "ccEmail" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "subject" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "subject" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "message" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "message" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachments" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachments" },
                },
              },
            ],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __useSendEmailToRequesterMutation__
 *
 * To run a mutation, you first call `useSendEmailToRequesterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailToRequesterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailToRequesterMutation, { data, loading, error }] = useSendEmailToRequesterMutation({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      cardId: // value for 'cardId'
 *      cardType: // value for 'cardType'
 *      requesterId: // value for 'requesterId'
 *      toEmail: // value for 'toEmail'
 *      ccEmail: // value for 'ccEmail'
 *      subject: // value for 'subject'
 *      message: // value for 'message'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useSendEmailToRequesterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendEmailToRequesterMutation,
    SendEmailToRequesterMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SendEmailToRequesterMutation,
    SendEmailToRequesterMutationVariables
  >(SendEmailToRequesterDocument, baseOptions);
}
export type SendEmailToRequesterMutationHookResult = ReturnType<
  typeof useSendEmailToRequesterMutation
>;
export type SendEmailToRequesterMutationResult = ApolloReactCommon.MutationResult<
  SendEmailToRequesterMutation
>;
export type SendEmailToRequesterMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendEmailToRequesterMutation,
  SendEmailToRequesterMutationVariables
>;
export const SendEmailToSuppliersDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SendEmailToSuppliers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "parentId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cardType" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "EmailCardTypeEnum" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "toEmail" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "ccEmail" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "subject" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "message" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "Upload" },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "existingAttachmentIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "String" },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "includePurchaseOrderPdf" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendEmailToSuppliers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "parentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "parentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "cardType" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cardType" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "supplierIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "supplierIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "toEmail" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "toEmail" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "ccEmail" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "ccEmail" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "subject" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "subject" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "message" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "message" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachments" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachments" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "existingAttachmentIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "existingAttachmentIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "includePurchaseOrderPdf" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "includePurchaseOrderPdf" },
                },
              },
            ],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __useSendEmailToSuppliersMutation__
 *
 * To run a mutation, you first call `useSendEmailToSuppliersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailToSuppliersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailToSuppliersMutation, { data, loading, error }] = useSendEmailToSuppliersMutation({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      cardId: // value for 'cardId'
 *      cardType: // value for 'cardType'
 *      supplierIds: // value for 'supplierIds'
 *      toEmail: // value for 'toEmail'
 *      ccEmail: // value for 'ccEmail'
 *      subject: // value for 'subject'
 *      message: // value for 'message'
 *      attachments: // value for 'attachments'
 *      existingAttachmentIds: // value for 'existingAttachmentIds'
 *      includePurchaseOrderPdf: // value for 'includePurchaseOrderPdf'
 *   },
 * });
 */
export function useSendEmailToSuppliersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendEmailToSuppliersMutation,
    SendEmailToSuppliersMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SendEmailToSuppliersMutation,
    SendEmailToSuppliersMutationVariables
  >(SendEmailToSuppliersDocument, baseOptions);
}
export type SendEmailToSuppliersMutationHookResult = ReturnType<
  typeof useSendEmailToSuppliersMutation
>;
export type SendEmailToSuppliersMutationResult = ApolloReactCommon.MutationResult<
  SendEmailToSuppliersMutation
>;
export type SendEmailToSuppliersMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendEmailToSuppliersMutation,
  SendEmailToSuppliersMutationVariables
>;
export const SendSourcingRequestDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SendSourcingRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourcingEventId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "subject" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "message" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "Upload" },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "existingAttachmentIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "String" },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendSourcingRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "sourcingEventId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sourcingEventId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "supplierIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "supplierIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "subject" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "subject" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "message" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "message" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachments" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachments" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "existingAttachmentIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "existingAttachmentIds" },
                },
              },
            ],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __useSendSourcingRequestMutation__
 *
 * To run a mutation, you first call `useSendSourcingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSourcingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSourcingRequestMutation, { data, loading, error }] = useSendSourcingRequestMutation({
 *   variables: {
 *      sourcingEventId: // value for 'sourcingEventId'
 *      supplierIds: // value for 'supplierIds'
 *      subject: // value for 'subject'
 *      message: // value for 'message'
 *      attachments: // value for 'attachments'
 *      existingAttachmentIds: // value for 'existingAttachmentIds'
 *   },
 * });
 */
export function useSendSourcingRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendSourcingRequestMutation,
    SendSourcingRequestMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SendSourcingRequestMutation,
    SendSourcingRequestMutationVariables
  >(SendSourcingRequestDocument, baseOptions);
}
export type SendSourcingRequestMutationHookResult = ReturnType<
  typeof useSendSourcingRequestMutation
>;
export type SendSourcingRequestMutationResult = ApolloReactCommon.MutationResult<
  SendSourcingRequestMutation
>;
export type SendSourcingRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendSourcingRequestMutation,
  SendSourcingRequestMutationVariables
>;
export const ViewerDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Viewer" },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roles" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizations" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companyName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "city" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "areaCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "baseCurrency" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantityUnits" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "urlName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companyLogoUrl" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "prEmailAddress" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "users" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "roles" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "costCentres" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "departments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "projects" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isActive" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "domainWhitelist" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modules" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "vatIdentification" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "businessRegistry" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "leiCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dunsCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "embeddedReport" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "poStatusWebhookUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "phoneNumber" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "city" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "areaCode" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countryCode" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewerQuery,
    ViewerQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<ViewerQuery, ViewerQueryVariables>(
    ViewerDocument,
    baseOptions,
  );
}
export function useViewerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewerQuery,
    ViewerQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<ViewerQuery, ViewerQueryVariables>(
    ViewerDocument,
    baseOptions,
  );
}
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerQueryResult = ApolloReactCommon.QueryResult<
  ViewerQuery,
  ViewerQueryVariables
>;
export const GetPoExternalIdentifierDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetPoExternalIdentifier" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orderByCode" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "code" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "code" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "organizationId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "externalIdentifier" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useGetPoExternalIdentifierQuery__
 *
 * To run a query within a React component, call `useGetPoExternalIdentifierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoExternalIdentifierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoExternalIdentifierQuery({
 *   variables: {
 *      code: // value for 'code'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetPoExternalIdentifierQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetPoExternalIdentifierQuery,
    GetPoExternalIdentifierQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetPoExternalIdentifierQuery,
    GetPoExternalIdentifierQueryVariables
  >(GetPoExternalIdentifierDocument, baseOptions);
}
export function useGetPoExternalIdentifierLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPoExternalIdentifierQuery,
    GetPoExternalIdentifierQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetPoExternalIdentifierQuery,
    GetPoExternalIdentifierQueryVariables
  >(GetPoExternalIdentifierDocument, baseOptions);
}
export type GetPoExternalIdentifierQueryHookResult = ReturnType<
  typeof useGetPoExternalIdentifierQuery
>;
export type GetPoExternalIdentifierLazyQueryHookResult = ReturnType<
  typeof useGetPoExternalIdentifierLazyQuery
>;
export type GetPoExternalIdentifierQueryResult = ApolloReactCommon.QueryResult<
  GetPoExternalIdentifierQuery,
  GetPoExternalIdentifierQueryVariables
>;
export const AddOrganizationToUserDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddOrganizationToUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addOrganizationToUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useAddOrganizationToUserMutation__
 *
 * To run a mutation, you first call `useAddOrganizationToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationToUserMutation, { data, loading, error }] = useAddOrganizationToUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useAddOrganizationToUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddOrganizationToUserMutation,
    AddOrganizationToUserMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AddOrganizationToUserMutation,
    AddOrganizationToUserMutationVariables
  >(AddOrganizationToUserDocument, baseOptions);
}
export type AddOrganizationToUserMutationHookResult = ReturnType<
  typeof useAddOrganizationToUserMutation
>;
export type AddOrganizationToUserMutationResult = ApolloReactCommon.MutationResult<
  AddOrganizationToUserMutation
>;
export type AddOrganizationToUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddOrganizationToUserMutation,
  AddOrganizationToUserMutationVariables
>;
export const AdminCreateUserDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AdminCreateUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firstName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lastName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "roles" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "UserRoleEnum" },
                },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phoneNumber" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryCode" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CountryCodeEnum" },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "firstName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "firstName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "lastName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "lastName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "roles" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "roles" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "phoneNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phoneNumber" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "countryCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "countryCode" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roles" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "status" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useAdminCreateUserMutation__
 *
 * To run a mutation, you first call `useAdminCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateUserMutation, { data, loading, error }] = useAdminCreateUserMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      roles: // value for 'roles'
 *      phoneNumber: // value for 'phoneNumber'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useAdminCreateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminCreateUserMutation,
    AdminCreateUserMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AdminCreateUserMutation,
    AdminCreateUserMutationVariables
  >(AdminCreateUserDocument, baseOptions);
}
export type AdminCreateUserMutationHookResult = ReturnType<
  typeof useAdminCreateUserMutation
>;
export type AdminCreateUserMutationResult = ApolloReactCommon.MutationResult<
  AdminCreateUserMutation
>;
export type AdminCreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminCreateUserMutation,
  AdminCreateUserMutationVariables
>;
export const AdminUpdateCompanyLogoDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AdminUpdateCompanyLogo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "companyLogo" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Upload" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adminUpdateOrganizationLogo" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "companyLogo" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "companyLogo" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useAdminUpdateCompanyLogoMutation__
 *
 * To run a mutation, you first call `useAdminUpdateCompanyLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateCompanyLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateCompanyLogoMutation, { data, loading, error }] = useAdminUpdateCompanyLogoMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      companyLogo: // value for 'companyLogo'
 *   },
 * });
 */
export function useAdminUpdateCompanyLogoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminUpdateCompanyLogoMutation,
    AdminUpdateCompanyLogoMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AdminUpdateCompanyLogoMutation,
    AdminUpdateCompanyLogoMutationVariables
  >(AdminUpdateCompanyLogoDocument, baseOptions);
}
export type AdminUpdateCompanyLogoMutationHookResult = ReturnType<
  typeof useAdminUpdateCompanyLogoMutation
>;
export type AdminUpdateCompanyLogoMutationResult = ApolloReactCommon.MutationResult<
  AdminUpdateCompanyLogoMutation
>;
export type AdminUpdateCompanyLogoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminUpdateCompanyLogoMutation,
  AdminUpdateCompanyLogoMutationVariables
>;
export const AdminUpdateProfilePictureDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AdminUpdateProfilePicture" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "profilePicture" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Upload" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adminUpdateProfilePicture" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "profilePicture" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "profilePicture" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useAdminUpdateProfilePictureMutation__
 *
 * To run a mutation, you first call `useAdminUpdateProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateProfilePictureMutation, { data, loading, error }] = useAdminUpdateProfilePictureMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      profilePicture: // value for 'profilePicture'
 *   },
 * });
 */
export function useAdminUpdateProfilePictureMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminUpdateProfilePictureMutation,
    AdminUpdateProfilePictureMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AdminUpdateProfilePictureMutation,
    AdminUpdateProfilePictureMutationVariables
  >(AdminUpdateProfilePictureDocument, baseOptions);
}
export type AdminUpdateProfilePictureMutationHookResult = ReturnType<
  typeof useAdminUpdateProfilePictureMutation
>;
export type AdminUpdateProfilePictureMutationResult = ApolloReactCommon.MutationResult<
  AdminUpdateProfilePictureMutation
>;
export type AdminUpdateProfilePictureMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminUpdateProfilePictureMutation,
  AdminUpdateProfilePictureMutationVariables
>;
export const AdminUpdateUserDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AdminUpdateUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firstName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lastName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "roles" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "UserRoleEnum" },
                },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UserStatusEnum" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "firstName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "firstName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "lastName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "lastName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "roles" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "roles" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "status" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "status" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizations" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companyName" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useAdminUpdateUserMutation__
 *
 * To run a mutation, you first call `useAdminUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateUserMutation, { data, loading, error }] = useAdminUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      organizationIds: // value for 'organizationIds'
 *      roles: // value for 'roles'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useAdminUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminUpdateUserMutation,
    AdminUpdateUserMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AdminUpdateUserMutation,
    AdminUpdateUserMutationVariables
  >(AdminUpdateUserDocument, baseOptions);
}
export type AdminUpdateUserMutationHookResult = ReturnType<
  typeof useAdminUpdateUserMutation
>;
export type AdminUpdateUserMutationResult = ApolloReactCommon.MutationResult<
  AdminUpdateUserMutation
>;
export type AdminUpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminUpdateUserMutation,
  AdminUpdateUserMutationVariables
>;
export const CreateOrganizationDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateOrganization" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "companyName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "address" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "city" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "areaCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CountryCodeEnum" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "baseCurrency" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CurrencyEnum" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "urlName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createOrganization" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "companyName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "companyName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "address" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "city" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "city" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "areaCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "areaCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "countryCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "countryCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "baseCurrency" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "baseCurrency" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "urlName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "urlName" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "companyName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "city" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "areaCode" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countryCode" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "baseCurrency" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "urlName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "isActive" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      companyName: // value for 'companyName'
 *      address: // value for 'address'
 *      city: // value for 'city'
 *      areaCode: // value for 'areaCode'
 *      countryCode: // value for 'countryCode'
 *      baseCurrency: // value for 'baseCurrency'
 *      urlName: // value for 'urlName'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(CreateOrganizationDocument, baseOptions);
}
export type CreateOrganizationMutationHookResult = ReturnType<
  typeof useCreateOrganizationMutation
>;
export type CreateOrganizationMutationResult = ApolloReactCommon.MutationResult<
  CreateOrganizationMutation
>;
export type CreateOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const OrganizationByIdDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OrganizationById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "admin" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationById" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "organizationId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companyName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "baseCurrency" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "urlName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "city" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "areaCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isActive" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companyLogoUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currencies" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "symbol" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useOrganizationByIdQuery__
 *
 * To run a query within a React component, call `useOrganizationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationByIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationByIdQuery,
    OrganizationByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    OrganizationByIdQuery,
    OrganizationByIdQueryVariables
  >(OrganizationByIdDocument, baseOptions);
}
export function useOrganizationByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationByIdQuery,
    OrganizationByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    OrganizationByIdQuery,
    OrganizationByIdQueryVariables
  >(OrganizationByIdDocument, baseOptions);
}
export type OrganizationByIdQueryHookResult = ReturnType<
  typeof useOrganizationByIdQuery
>;
export type OrganizationByIdLazyQueryHookResult = ReturnType<
  typeof useOrganizationByIdLazyQuery
>;
export type OrganizationByIdQueryResult = ApolloReactCommon.QueryResult<
  OrganizationByIdQuery,
  OrganizationByIdQueryVariables
>;
export const OrganizationsManagementDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OrganizationsManagement" },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "admin" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizations" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companyName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "urlName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "city" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "areaCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isActive" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companyLogoUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currencies" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "symbol" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useOrganizationsManagementQuery__
 *
 * To run a query within a React component, call `useOrganizationsManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsManagementQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationsManagementQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationsManagementQuery,
    OrganizationsManagementQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    OrganizationsManagementQuery,
    OrganizationsManagementQueryVariables
  >(OrganizationsManagementDocument, baseOptions);
}
export function useOrganizationsManagementLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationsManagementQuery,
    OrganizationsManagementQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    OrganizationsManagementQuery,
    OrganizationsManagementQueryVariables
  >(OrganizationsManagementDocument, baseOptions);
}
export type OrganizationsManagementQueryHookResult = ReturnType<
  typeof useOrganizationsManagementQuery
>;
export type OrganizationsManagementLazyQueryHookResult = ReturnType<
  typeof useOrganizationsManagementLazyQuery
>;
export type OrganizationsManagementQueryResult = ApolloReactCommon.QueryResult<
  OrganizationsManagementQuery,
  OrganizationsManagementQueryVariables
>;
export const RemoveOrganizationFromUserDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RemoveOrganizationFromUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removeOrganizationFromUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useRemoveOrganizationFromUserMutation__
 *
 * To run a mutation, you first call `useRemoveOrganizationFromUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganizationFromUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganizationFromUserMutation, { data, loading, error }] = useRemoveOrganizationFromUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useRemoveOrganizationFromUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveOrganizationFromUserMutation,
    RemoveOrganizationFromUserMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    RemoveOrganizationFromUserMutation,
    RemoveOrganizationFromUserMutationVariables
  >(RemoveOrganizationFromUserDocument, baseOptions);
}
export type RemoveOrganizationFromUserMutationHookResult = ReturnType<
  typeof useRemoveOrganizationFromUserMutation
>;
export type RemoveOrganizationFromUserMutationResult = ApolloReactCommon.MutationResult<
  RemoveOrganizationFromUserMutation
>;
export type RemoveOrganizationFromUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveOrganizationFromUserMutation,
  RemoveOrganizationFromUserMutationVariables
>;
export const AdminUpdateOrganizationDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AdminUpdateOrganization" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "companyName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "address" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "city" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "areaCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CountryCodeEnum" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "baseCurrency" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CurrencyEnum" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "urlName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isActive" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adminUpdateOrganization" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "companyName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "companyName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "address" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "city" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "city" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "areaCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "areaCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "countryCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "countryCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "baseCurrency" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "baseCurrency" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "urlName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "urlName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isActive" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isActive" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "companyName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "baseCurrency" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "urlName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countryCode" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "city" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "areaCode" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useAdminUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useAdminUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateOrganizationMutation, { data, loading, error }] = useAdminUpdateOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      companyName: // value for 'companyName'
 *      address: // value for 'address'
 *      city: // value for 'city'
 *      areaCode: // value for 'areaCode'
 *      countryCode: // value for 'countryCode'
 *      baseCurrency: // value for 'baseCurrency'
 *      urlName: // value for 'urlName'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useAdminUpdateOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminUpdateOrganizationMutation,
    AdminUpdateOrganizationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AdminUpdateOrganizationMutation,
    AdminUpdateOrganizationMutationVariables
  >(AdminUpdateOrganizationDocument, baseOptions);
}
export type AdminUpdateOrganizationMutationHookResult = ReturnType<
  typeof useAdminUpdateOrganizationMutation
>;
export type AdminUpdateOrganizationMutationResult = ApolloReactCommon.MutationResult<
  AdminUpdateOrganizationMutation
>;
export type AdminUpdateOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminUpdateOrganizationMutation,
  AdminUpdateOrganizationMutationVariables
>;
export const UserByIdDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "admin" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userById" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "userId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "userId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "roles" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizations" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "companyName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserByIdQuery,
    UserByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<UserByIdQuery, UserByIdQueryVariables>(
    UserByIdDocument,
    baseOptions,
  );
}
export function useUserByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserByIdQuery,
    UserByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(
    UserByIdDocument,
    baseOptions,
  );
}
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<
  typeof useUserByIdLazyQuery
>;
export type UserByIdQueryResult = ApolloReactCommon.QueryResult<
  UserByIdQuery,
  UserByIdQueryVariables
>;
export const UsersManagementDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UsersManagement" },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "admin" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "users" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "phoneNumber" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "roles" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizations" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "companyName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizations" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companyName" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUsersManagementQuery__
 *
 * To run a query within a React component, call `useUsersManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersManagementQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersManagementQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UsersManagementQuery,
    UsersManagementQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    UsersManagementQuery,
    UsersManagementQueryVariables
  >(UsersManagementDocument, baseOptions);
}
export function useUsersManagementLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UsersManagementQuery,
    UsersManagementQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    UsersManagementQuery,
    UsersManagementQueryVariables
  >(UsersManagementDocument, baseOptions);
}
export type UsersManagementQueryHookResult = ReturnType<
  typeof useUsersManagementQuery
>;
export type UsersManagementLazyQueryHookResult = ReturnType<
  typeof useUsersManagementLazyQuery
>;
export type UsersManagementQueryResult = ApolloReactCommon.QueryResult<
  UsersManagementQuery,
  UsersManagementQueryVariables
>;
export const ApprovalPortalViewDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ApprovalPortalView" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "approvalWorkflowStatuses" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ApprovalWorkflowStatusEnum" },
                },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "KanbanFilterInput" },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roles" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "approvalWorkflowsByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "approvalWorkflowStatuses" },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "approvalWorkflowStatuses",
                        },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "urlName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "costCentres" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "departments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "projects" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequests" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "filter" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardStatus" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "level" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "text" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestor" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "UserInfo" },
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assignee" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "UserInfo" },
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "project" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "ProjectInfo",
                                    },
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "suppliers" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "SupplierInfo",
                                    },
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "department" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "DepartmentInfo",
                                    },
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "items" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "PurchaseRequestItemInfo",
                                    },
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "level",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "text" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "latestApprovalWorkflow",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isRfxCreated" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isOrderCreated" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatarUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "department" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "costCentre" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProjectInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Project" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SupplierInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Supplier" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentTerms" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "defaultContactPerson" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DepartmentInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Department" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "code" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PurchaseRequestItemInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PurchaseRequestItem" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "parentCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "type" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "quantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "receivedQuantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "totalReceivedQuantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "unit" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "assignee" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedDeliveryDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "productUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "unitPrice" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currency" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __useApprovalPortalViewQuery__
 *
 * To run a query within a React component, call `useApprovalPortalViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalPortalViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalPortalViewQuery({
 *   variables: {
 *      approvalWorkflowStatuses: // value for 'approvalWorkflowStatuses'
 *      organizationId: // value for 'organizationId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useApprovalPortalViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ApprovalPortalViewQuery,
    ApprovalPortalViewQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    ApprovalPortalViewQuery,
    ApprovalPortalViewQueryVariables
  >(ApprovalPortalViewDocument, baseOptions);
}
export function useApprovalPortalViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ApprovalPortalViewQuery,
    ApprovalPortalViewQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    ApprovalPortalViewQuery,
    ApprovalPortalViewQueryVariables
  >(ApprovalPortalViewDocument, baseOptions);
}
export type ApprovalPortalViewQueryHookResult = ReturnType<
  typeof useApprovalPortalViewQuery
>;
export type ApprovalPortalViewLazyQueryHookResult = ReturnType<
  typeof useApprovalPortalViewLazyQuery
>;
export type ApprovalPortalViewQueryResult = ApolloReactCommon.QueryResult<
  ApprovalPortalViewQuery,
  ApprovalPortalViewQueryVariables
>;
export const UpdateApprovalRequestDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateApprovalRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "approvalRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "comment" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "decision" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ApprovalRequestDecisionEnum" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateApprovalRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "approvalRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "approvalRequestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "comment" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "comment" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "decision" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "decision" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateApprovalRequestMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalRequestMutation, { data, loading, error }] = useUpdateApprovalRequestMutation({
 *   variables: {
 *      approvalRequestId: // value for 'approvalRequestId'
 *      comment: // value for 'comment'
 *      decision: // value for 'decision'
 *   },
 * });
 */
export function useUpdateApprovalRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateApprovalRequestMutation,
    UpdateApprovalRequestMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateApprovalRequestMutation,
    UpdateApprovalRequestMutationVariables
  >(UpdateApprovalRequestDocument, baseOptions);
}
export type UpdateApprovalRequestMutationHookResult = ReturnType<
  typeof useUpdateApprovalRequestMutation
>;
export type UpdateApprovalRequestMutationResult = ApolloReactCommon.MutationResult<
  UpdateApprovalRequestMutation
>;
export type UpdateApprovalRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateApprovalRequestMutation,
  UpdateApprovalRequestMutationVariables
>;
export const ViewerApprovalWorkflowsDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ViewerApprovalWorkflows" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "approvalWorkflowStatuses" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ApprovalWorkflowStatusEnum" },
                },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "approvalWorkflowsByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "approvalWorkflowStatuses" },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "approvalWorkflowStatuses",
                        },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalAmount" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestor" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequest" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "notes" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attachments" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "filename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "department" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "project" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "costCentre" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "items" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unitPrice" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "expectedDeliveryDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "productUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "suggestedSupplier",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "additionalInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isDeleted" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "attachments",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "filename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseOrder" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalRequests" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approverComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decision" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decisionDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dueDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceNumber" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceGroup" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approver" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useViewerApprovalWorkflowsQuery__
 *
 * To run a query within a React component, call `useViewerApprovalWorkflowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerApprovalWorkflowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerApprovalWorkflowsQuery({
 *   variables: {
 *      approvalWorkflowStatuses: // value for 'approvalWorkflowStatuses'
 *   },
 * });
 */
export function useViewerApprovalWorkflowsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewerApprovalWorkflowsQuery,
    ViewerApprovalWorkflowsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    ViewerApprovalWorkflowsQuery,
    ViewerApprovalWorkflowsQueryVariables
  >(ViewerApprovalWorkflowsDocument, baseOptions);
}
export function useViewerApprovalWorkflowsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewerApprovalWorkflowsQuery,
    ViewerApprovalWorkflowsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    ViewerApprovalWorkflowsQuery,
    ViewerApprovalWorkflowsQueryVariables
  >(ViewerApprovalWorkflowsDocument, baseOptions);
}
export type ViewerApprovalWorkflowsQueryHookResult = ReturnType<
  typeof useViewerApprovalWorkflowsQuery
>;
export type ViewerApprovalWorkflowsLazyQueryHookResult = ReturnType<
  typeof useViewerApprovalWorkflowsLazyQuery
>;
export type ViewerApprovalWorkflowsQueryResult = ApolloReactCommon.QueryResult<
  ViewerApprovalWorkflowsQuery,
  ViewerApprovalWorkflowsQueryVariables
>;
export const OrganizationApprovalWorkflowsByStatusDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OrganizationApprovalWorkflowsByStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "approvalWorkflowStatuses" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ApprovalWorkflowStatusEnum" },
                },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "approvalWorkflowsByStatus",
                        },
                        arguments: [
                          {
                            kind: "Argument",
                            name: {
                              kind: "Name",
                              value: "approvalWorkflowStatuses",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowStatuses",
                              },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "diffWithDeadline" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowStats",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvedRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "totalRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalRequests" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dueDate" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "decision" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "purchaseRequest" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useOrganizationApprovalWorkflowsByStatusQuery__
 *
 * To run a query within a React component, call `useOrganizationApprovalWorkflowsByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationApprovalWorkflowsByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationApprovalWorkflowsByStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *      approvalWorkflowStatuses: // value for 'approvalWorkflowStatuses'
 *   },
 * });
 */
export function useOrganizationApprovalWorkflowsByStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationApprovalWorkflowsByStatusQuery,
    OrganizationApprovalWorkflowsByStatusQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    OrganizationApprovalWorkflowsByStatusQuery,
    OrganizationApprovalWorkflowsByStatusQueryVariables
  >(OrganizationApprovalWorkflowsByStatusDocument, baseOptions);
}
export function useOrganizationApprovalWorkflowsByStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationApprovalWorkflowsByStatusQuery,
    OrganizationApprovalWorkflowsByStatusQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    OrganizationApprovalWorkflowsByStatusQuery,
    OrganizationApprovalWorkflowsByStatusQueryVariables
  >(OrganizationApprovalWorkflowsByStatusDocument, baseOptions);
}
export type OrganizationApprovalWorkflowsByStatusQueryHookResult = ReturnType<
  typeof useOrganizationApprovalWorkflowsByStatusQuery
>;
export type OrganizationApprovalWorkflowsByStatusLazyQueryHookResult = ReturnType<
  typeof useOrganizationApprovalWorkflowsByStatusLazyQuery
>;
export type OrganizationApprovalWorkflowsByStatusQueryResult = ApolloReactCommon.QueryResult<
  OrganizationApprovalWorkflowsByStatusQuery,
  OrganizationApprovalWorkflowsByStatusQueryVariables
>;
export const CreateApiKeyDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createApiKey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "description" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createApiKey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "description" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "description" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creatorId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "token" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "description" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyMutation, { data, loading, error }] = useCreateApiKeyMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateApiKeyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateApiKeyMutation,
    CreateApiKeyMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateApiKeyMutation,
    CreateApiKeyMutationVariables
  >(CreateApiKeyDocument, baseOptions);
}
export type CreateApiKeyMutationHookResult = ReturnType<
  typeof useCreateApiKeyMutation
>;
export type CreateApiKeyMutationResult = ApolloReactCommon.MutationResult<
  CreateApiKeyMutation
>;
export type CreateApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateApiKeyMutation,
  CreateApiKeyMutationVariables
>;
export const CreateOrganizationUserDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateOrganizationUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firstName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lastName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "roles" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "UserRoleEnum" },
                },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryCode" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CountryCodeEnum" },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phoneNumber" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createOrganizationUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "firstName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "firstName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "lastName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "lastName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "roles" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "roles" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "countryCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "countryCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "phoneNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phoneNumber" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roles" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countryCode" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "phoneNumber" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateOrganizationUserMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationUserMutation, { data, loading, error }] = useCreateOrganizationUserMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      roles: // value for 'roles'
 *      countryCode: // value for 'countryCode'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useCreateOrganizationUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrganizationUserMutation,
    CreateOrganizationUserMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateOrganizationUserMutation,
    CreateOrganizationUserMutationVariables
  >(CreateOrganizationUserDocument, baseOptions);
}
export type CreateOrganizationUserMutationHookResult = ReturnType<
  typeof useCreateOrganizationUserMutation
>;
export type CreateOrganizationUserMutationResult = ApolloReactCommon.MutationResult<
  CreateOrganizationUserMutation
>;
export type CreateOrganizationUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrganizationUserMutation,
  CreateOrganizationUserMutationVariables
>;
export const CreateUserDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firstName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lastName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "roles" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "UserRoleEnum" },
                },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phoneNumber" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryCode" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CountryCodeEnum" },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "firstName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "firstName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "lastName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "lastName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "roles" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "roles" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "phoneNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phoneNumber" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "countryCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "countryCode" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roles" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "status" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      roles: // value for 'roles'
 *      phoneNumber: // value for 'phoneNumber'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateUserMutation,
    CreateUserMutationVariables
  >(CreateUserDocument, baseOptions);
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<
  CreateUserMutation
>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const DeleteApiKeyDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteApiKey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "apiKeyId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteApiKey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "apiKeyId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "apiKeyId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "token" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "description" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useDeleteApiKeyMutation__
 *
 * To run a mutation, you first call `useDeleteApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiKeyMutation, { data, loading, error }] = useDeleteApiKeyMutation({
 *   variables: {
 *      apiKeyId: // value for 'apiKeyId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteApiKeyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteApiKeyMutation,
    DeleteApiKeyMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteApiKeyMutation,
    DeleteApiKeyMutationVariables
  >(DeleteApiKeyDocument, baseOptions);
}
export type DeleteApiKeyMutationHookResult = ReturnType<
  typeof useDeleteApiKeyMutation
>;
export type DeleteApiKeyMutationResult = ApolloReactCommon.MutationResult<
  DeleteApiKeyMutation
>;
export type DeleteApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteApiKeyMutation,
  DeleteApiKeyMutationVariables
>;
export const DeleteCostCentreDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteCostCentre" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "costCentreId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteCostCentre" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "costCentreId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "costCentreId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useDeleteCostCentreMutation__
 *
 * To run a mutation, you first call `useDeleteCostCentreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCostCentreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCostCentreMutation, { data, loading, error }] = useDeleteCostCentreMutation({
 *   variables: {
 *      costCentreId: // value for 'costCentreId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteCostCentreMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCostCentreMutation,
    DeleteCostCentreMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteCostCentreMutation,
    DeleteCostCentreMutationVariables
  >(DeleteCostCentreDocument, baseOptions);
}
export type DeleteCostCentreMutationHookResult = ReturnType<
  typeof useDeleteCostCentreMutation
>;
export type DeleteCostCentreMutationResult = ApolloReactCommon.MutationResult<
  DeleteCostCentreMutation
>;
export type DeleteCostCentreMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCostCentreMutation,
  DeleteCostCentreMutationVariables
>;
export const DeleteDepartmentDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteDepartment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "departmentId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteDepartment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "departmentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "departmentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useDeleteDepartmentMutation__
 *
 * To run a mutation, you first call `useDeleteDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDepartmentMutation, { data, loading, error }] = useDeleteDepartmentMutation({
 *   variables: {
 *      departmentId: // value for 'departmentId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteDepartmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteDepartmentMutation,
    DeleteDepartmentMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteDepartmentMutation,
    DeleteDepartmentMutationVariables
  >(DeleteDepartmentDocument, baseOptions);
}
export type DeleteDepartmentMutationHookResult = ReturnType<
  typeof useDeleteDepartmentMutation
>;
export type DeleteDepartmentMutationResult = ApolloReactCommon.MutationResult<
  DeleteDepartmentMutation
>;
export type DeleteDepartmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteDepartmentMutation,
  DeleteDepartmentMutationVariables
>;
export const DeleteProjectDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteProject" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "projectId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >(DeleteProjectDocument, baseOptions);
}
export type DeleteProjectMutationHookResult = ReturnType<
  typeof useDeleteProjectMutation
>;
export type DeleteProjectMutationResult = ApolloReactCommon.MutationResult<
  DeleteProjectMutation
>;
export type DeleteProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const OrganizationAddressesDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OrganizationAddresses" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "addresses" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "AddressInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddressInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "locationName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "recipientName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "city" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "areaCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countryCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "addressType" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "country" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useOrganizationAddressesQuery__
 *
 * To run a query within a React component, call `useOrganizationAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationAddressesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationAddressesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationAddressesQuery,
    OrganizationAddressesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    OrganizationAddressesQuery,
    OrganizationAddressesQueryVariables
  >(OrganizationAddressesDocument, baseOptions);
}
export function useOrganizationAddressesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationAddressesQuery,
    OrganizationAddressesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    OrganizationAddressesQuery,
    OrganizationAddressesQueryVariables
  >(OrganizationAddressesDocument, baseOptions);
}
export type OrganizationAddressesQueryHookResult = ReturnType<
  typeof useOrganizationAddressesQuery
>;
export type OrganizationAddressesLazyQueryHookResult = ReturnType<
  typeof useOrganizationAddressesLazyQuery
>;
export type OrganizationAddressesQueryResult = ApolloReactCommon.QueryResult<
  OrganizationAddressesQuery,
  OrganizationAddressesQueryVariables
>;
export const OrganizationApiKeysDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OrganizationApiKeys" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "apiKeys" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "token" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useOrganizationApiKeysQuery__
 *
 * To run a query within a React component, call `useOrganizationApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationApiKeysQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationApiKeysQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationApiKeysQuery,
    OrganizationApiKeysQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    OrganizationApiKeysQuery,
    OrganizationApiKeysQueryVariables
  >(OrganizationApiKeysDocument, baseOptions);
}
export function useOrganizationApiKeysLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationApiKeysQuery,
    OrganizationApiKeysQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    OrganizationApiKeysQuery,
    OrganizationApiKeysQueryVariables
  >(OrganizationApiKeysDocument, baseOptions);
}
export type OrganizationApiKeysQueryHookResult = ReturnType<
  typeof useOrganizationApiKeysQuery
>;
export type OrganizationApiKeysLazyQueryHookResult = ReturnType<
  typeof useOrganizationApiKeysLazyQuery
>;
export type OrganizationApiKeysQueryResult = ApolloReactCommon.QueryResult<
  OrganizationApiKeysQuery,
  OrganizationApiKeysQueryVariables
>;
export const OrganizationCostCentreByIdDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OrganizationCostCentreById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "costCentreId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "costCentreById" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "costCentreId" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "costCentreId" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useOrganizationCostCentreByIdQuery__
 *
 * To run a query within a React component, call `useOrganizationCostCentreByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCostCentreByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationCostCentreByIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      costCentreId: // value for 'costCentreId'
 *   },
 * });
 */
export function useOrganizationCostCentreByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationCostCentreByIdQuery,
    OrganizationCostCentreByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    OrganizationCostCentreByIdQuery,
    OrganizationCostCentreByIdQueryVariables
  >(OrganizationCostCentreByIdDocument, baseOptions);
}
export function useOrganizationCostCentreByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationCostCentreByIdQuery,
    OrganizationCostCentreByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    OrganizationCostCentreByIdQuery,
    OrganizationCostCentreByIdQueryVariables
  >(OrganizationCostCentreByIdDocument, baseOptions);
}
export type OrganizationCostCentreByIdQueryHookResult = ReturnType<
  typeof useOrganizationCostCentreByIdQuery
>;
export type OrganizationCostCentreByIdLazyQueryHookResult = ReturnType<
  typeof useOrganizationCostCentreByIdLazyQuery
>;
export type OrganizationCostCentreByIdQueryResult = ApolloReactCommon.QueryResult<
  OrganizationCostCentreByIdQuery,
  OrganizationCostCentreByIdQueryVariables
>;
export const OrganizationDepartmentByIdDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OrganizationDepartmentById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "departmentId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "departmentById" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "departmentId" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "departmentId" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "domain" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shippingAddress" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "AddressInfo",
                                    },
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "invoiceAddress" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "AddressInfo",
                                    },
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddressInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "locationName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "recipientName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "city" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "areaCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countryCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "addressType" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "country" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useOrganizationDepartmentByIdQuery__
 *
 * To run a query within a React component, call `useOrganizationDepartmentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDepartmentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDepartmentByIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useOrganizationDepartmentByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationDepartmentByIdQuery,
    OrganizationDepartmentByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    OrganizationDepartmentByIdQuery,
    OrganizationDepartmentByIdQueryVariables
  >(OrganizationDepartmentByIdDocument, baseOptions);
}
export function useOrganizationDepartmentByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationDepartmentByIdQuery,
    OrganizationDepartmentByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    OrganizationDepartmentByIdQuery,
    OrganizationDepartmentByIdQueryVariables
  >(OrganizationDepartmentByIdDocument, baseOptions);
}
export type OrganizationDepartmentByIdQueryHookResult = ReturnType<
  typeof useOrganizationDepartmentByIdQuery
>;
export type OrganizationDepartmentByIdLazyQueryHookResult = ReturnType<
  typeof useOrganizationDepartmentByIdLazyQuery
>;
export type OrganizationDepartmentByIdQueryResult = ApolloReactCommon.QueryResult<
  OrganizationDepartmentByIdQuery,
  OrganizationDepartmentByIdQueryVariables
>;
export const OrganizationProjectByIdDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OrganizationProjectById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "projectById" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "projectId" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "projectId" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useOrganizationProjectByIdQuery__
 *
 * To run a query within a React component, call `useOrganizationProjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationProjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationProjectByIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useOrganizationProjectByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationProjectByIdQuery,
    OrganizationProjectByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    OrganizationProjectByIdQuery,
    OrganizationProjectByIdQueryVariables
  >(OrganizationProjectByIdDocument, baseOptions);
}
export function useOrganizationProjectByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationProjectByIdQuery,
    OrganizationProjectByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    OrganizationProjectByIdQuery,
    OrganizationProjectByIdQueryVariables
  >(OrganizationProjectByIdDocument, baseOptions);
}
export type OrganizationProjectByIdQueryHookResult = ReturnType<
  typeof useOrganizationProjectByIdQuery
>;
export type OrganizationProjectByIdLazyQueryHookResult = ReturnType<
  typeof useOrganizationProjectByIdLazyQuery
>;
export type OrganizationProjectByIdQueryResult = ApolloReactCommon.QueryResult<
  OrganizationProjectByIdQuery,
  OrganizationProjectByIdQueryVariables
>;
export const OrganizationUserByIdDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OrganizationUserById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companyName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "departments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "projects" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "costCentres" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userById" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "userId" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "userId" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phoneNumber" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "roles" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "department" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "project" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "costCentre" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useOrganizationUserByIdQuery__
 *
 * To run a query within a React component, call `useOrganizationUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationUserByIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOrganizationUserByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationUserByIdQuery,
    OrganizationUserByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    OrganizationUserByIdQuery,
    OrganizationUserByIdQueryVariables
  >(OrganizationUserByIdDocument, baseOptions);
}
export function useOrganizationUserByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationUserByIdQuery,
    OrganizationUserByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    OrganizationUserByIdQuery,
    OrganizationUserByIdQueryVariables
  >(OrganizationUserByIdDocument, baseOptions);
}
export type OrganizationUserByIdQueryHookResult = ReturnType<
  typeof useOrganizationUserByIdQuery
>;
export type OrganizationUserByIdLazyQueryHookResult = ReturnType<
  typeof useOrganizationUserByIdLazyQuery
>;
export type OrganizationUserByIdQueryResult = ApolloReactCommon.QueryResult<
  OrganizationUserByIdQuery,
  OrganizationUserByIdQueryVariables
>;
export const UpdateCostCentreDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateCostCentre" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "costCentreId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateCostCentre" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "costCentreId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "costCentreId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateCostCentreMutation__
 *
 * To run a mutation, you first call `useUpdateCostCentreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostCentreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostCentreMutation, { data, loading, error }] = useUpdateCostCentreMutation({
 *   variables: {
 *      costCentreId: // value for 'costCentreId'
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateCostCentreMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCostCentreMutation,
    UpdateCostCentreMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateCostCentreMutation,
    UpdateCostCentreMutationVariables
  >(UpdateCostCentreDocument, baseOptions);
}
export type UpdateCostCentreMutationHookResult = ReturnType<
  typeof useUpdateCostCentreMutation
>;
export type UpdateCostCentreMutationResult = ApolloReactCommon.MutationResult<
  UpdateCostCentreMutation
>;
export type UpdateCostCentreMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCostCentreMutation,
  UpdateCostCentreMutationVariables
>;
export const UpdateDepartmentDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDepartment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "departmentId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "domain" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingAddressId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "invoiceAddressId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateDepartment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "departmentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "departmentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "code" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "code" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "domain" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "domain" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "shippingAddressId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "shippingAddressId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "invoiceAddressId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "invoiceAddressId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "domain" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateDepartmentMutation__
 *
 * To run a mutation, you first call `useUpdateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepartmentMutation, { data, loading, error }] = useUpdateDepartmentMutation({
 *   variables: {
 *      departmentId: // value for 'departmentId'
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      domain: // value for 'domain'
 *      shippingAddressId: // value for 'shippingAddressId'
 *      invoiceAddressId: // value for 'invoiceAddressId'
 *   },
 * });
 */
export function useUpdateDepartmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateDepartmentMutation,
    UpdateDepartmentMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateDepartmentMutation,
    UpdateDepartmentMutationVariables
  >(UpdateDepartmentDocument, baseOptions);
}
export type UpdateDepartmentMutationHookResult = ReturnType<
  typeof useUpdateDepartmentMutation
>;
export type UpdateDepartmentMutationResult = ApolloReactCommon.MutationResult<
  UpdateDepartmentMutation
>;
export type UpdateDepartmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDepartmentMutation,
  UpdateDepartmentMutationVariables
>;
export const UpdateOrganizationPoTemplateDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateOrganizationPoTemplate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "primaryColor" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "secondaryColor" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "note" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateOrganizationPoTemplate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "primaryColor" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "primaryColor" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "secondaryColor" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "secondaryColor" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "note" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "note" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateOrganizationPoTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationPoTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationPoTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationPoTemplateMutation, { data, loading, error }] = useUpdateOrganizationPoTemplateMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      primaryColor: // value for 'primaryColor'
 *      secondaryColor: // value for 'secondaryColor'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdateOrganizationPoTemplateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrganizationPoTemplateMutation,
    UpdateOrganizationPoTemplateMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateOrganizationPoTemplateMutation,
    UpdateOrganizationPoTemplateMutationVariables
  >(UpdateOrganizationPoTemplateDocument, baseOptions);
}
export type UpdateOrganizationPoTemplateMutationHookResult = ReturnType<
  typeof useUpdateOrganizationPoTemplateMutation
>;
export type UpdateOrganizationPoTemplateMutationResult = ApolloReactCommon.MutationResult<
  UpdateOrganizationPoTemplateMutation
>;
export type UpdateOrganizationPoTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateOrganizationPoTemplateMutation,
  UpdateOrganizationPoTemplateMutationVariables
>;
export const UpdateOrganizationUserDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateOrganizationUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firstName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lastName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "roles" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "UserRoleEnum" },
                },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UserStatusEnum" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryCode" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CountryCodeEnum" },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phoneNumber" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "departmentId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "costCentreId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateOrganizationUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "firstName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "firstName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "lastName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "lastName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "roles" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "roles" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "status" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "status" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "countryCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "countryCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "phoneNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phoneNumber" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "departmentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "departmentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "projectId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "costCentreId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "costCentreId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roles" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "status" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countryCode" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "phoneNumber" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "departmentId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "projectId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "costCentreId" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateOrganizationUserMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationUserMutation, { data, loading, error }] = useUpdateOrganizationUserMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      roles: // value for 'roles'
 *      status: // value for 'status'
 *      countryCode: // value for 'countryCode'
 *      phoneNumber: // value for 'phoneNumber'
 *      departmentId: // value for 'departmentId'
 *      projectId: // value for 'projectId'
 *      costCentreId: // value for 'costCentreId'
 *   },
 * });
 */
export function useUpdateOrganizationUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrganizationUserMutation,
    UpdateOrganizationUserMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateOrganizationUserMutation,
    UpdateOrganizationUserMutationVariables
  >(UpdateOrganizationUserDocument, baseOptions);
}
export type UpdateOrganizationUserMutationHookResult = ReturnType<
  typeof useUpdateOrganizationUserMutation
>;
export type UpdateOrganizationUserMutationResult = ApolloReactCommon.MutationResult<
  UpdateOrganizationUserMutation
>;
export type UpdateOrganizationUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateOrganizationUserMutation,
  UpdateOrganizationUserMutationVariables
>;
export const UpdateProjectDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateProject" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "projectId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >(UpdateProjectDocument, baseOptions);
}
export type UpdateProjectMutationHookResult = ReturnType<
  typeof useUpdateProjectMutation
>;
export type UpdateProjectMutationResult = ApolloReactCommon.MutationResult<
  UpdateProjectMutation
>;
export type UpdateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const UpdateWhitelistedDomainsDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateWhitelistedDomains" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "domainWhitelist" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "String" },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateWhitelistedDomains" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "domainWhitelist" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "domainWhitelist" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateWhitelistedDomainsMutation__
 *
 * To run a mutation, you first call `useUpdateWhitelistedDomainsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWhitelistedDomainsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWhitelistedDomainsMutation, { data, loading, error }] = useUpdateWhitelistedDomainsMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      domainWhitelist: // value for 'domainWhitelist'
 *   },
 * });
 */
export function useUpdateWhitelistedDomainsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateWhitelistedDomainsMutation,
    UpdateWhitelistedDomainsMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateWhitelistedDomainsMutation,
    UpdateWhitelistedDomainsMutationVariables
  >(UpdateWhitelistedDomainsDocument, baseOptions);
}
export type UpdateWhitelistedDomainsMutationHookResult = ReturnType<
  typeof useUpdateWhitelistedDomainsMutation
>;
export type UpdateWhitelistedDomainsMutationResult = ApolloReactCommon.MutationResult<
  UpdateWhitelistedDomainsMutation
>;
export type UpdateWhitelistedDomainsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateWhitelistedDomainsMutation,
  UpdateWhitelistedDomainsMutationVariables
>;
export const UploadOrganizationLogoDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UploadOrganizationLogo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "companyLogo" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Upload" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "uploadOrganizationLogo" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "companyLogo" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "companyLogo" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUploadOrganizationLogoMutation__
 *
 * To run a mutation, you first call `useUploadOrganizationLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadOrganizationLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadOrganizationLogoMutation, { data, loading, error }] = useUploadOrganizationLogoMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      companyLogo: // value for 'companyLogo'
 *   },
 * });
 */
export function useUploadOrganizationLogoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadOrganizationLogoMutation,
    UploadOrganizationLogoMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UploadOrganizationLogoMutation,
    UploadOrganizationLogoMutationVariables
  >(UploadOrganizationLogoDocument, baseOptions);
}
export type UploadOrganizationLogoMutationHookResult = ReturnType<
  typeof useUploadOrganizationLogoMutation
>;
export type UploadOrganizationLogoMutationResult = ApolloReactCommon.MutationResult<
  UploadOrganizationLogoMutation
>;
export type UploadOrganizationLogoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadOrganizationLogoMutation,
  UploadOrganizationLogoMutationVariables
>;
export const UserUpdateOrganizationDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UserUpdateOrganization" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "companyName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "address" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "city" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "areaCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CountryCodeEnum" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "baseCurrency" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CurrencyEnum" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "vatIdentification" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "businessRegistry" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "leiCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dunsCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "embeddedReport" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "poStatusWebhookUrl" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateOrganization" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "companyName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "companyName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "address" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "city" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "city" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "areaCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "areaCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "countryCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "countryCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "baseCurrency" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "baseCurrency" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "vatIdentification" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "vatIdentification" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "businessRegistry" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "businessRegistry" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "leiCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "leiCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dunsCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dunsCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "embeddedReport" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "embeddedReport" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "poStatusWebhookUrl" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "poStatusWebhookUrl" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "city" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "areaCode" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "country" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "baseCurrency" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "vatIdentification" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "businessRegistry" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "leiCode" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dunsCode" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "embeddedReport" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "poStatusWebhookUrl" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUserUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUserUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateOrganizationMutation, { data, loading, error }] = useUserUpdateOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      companyName: // value for 'companyName'
 *      address: // value for 'address'
 *      city: // value for 'city'
 *      areaCode: // value for 'areaCode'
 *      countryCode: // value for 'countryCode'
 *      baseCurrency: // value for 'baseCurrency'
 *      vatIdentification: // value for 'vatIdentification'
 *      businessRegistry: // value for 'businessRegistry'
 *      leiCode: // value for 'leiCode'
 *      dunsCode: // value for 'dunsCode'
 *      embeddedReport: // value for 'embeddedReport'
 *      poStatusWebhookUrl: // value for 'poStatusWebhookUrl'
 *   },
 * });
 */
export function useUserUpdateOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserUpdateOrganizationMutation,
    UserUpdateOrganizationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UserUpdateOrganizationMutation,
    UserUpdateOrganizationMutationVariables
  >(UserUpdateOrganizationDocument, baseOptions);
}
export type UserUpdateOrganizationMutationHookResult = ReturnType<
  typeof useUserUpdateOrganizationMutation
>;
export type UserUpdateOrganizationMutationResult = ApolloReactCommon.MutationResult<
  UserUpdateOrganizationMutation
>;
export type UserUpdateOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserUpdateOrganizationMutation,
  UserUpdateOrganizationMutationVariables
>;
export const CreatePurchaseRequestDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreatePurchaseRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createPurchaseRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreatePurchaseRequestMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseRequestMutation, { data, loading, error }] = useCreatePurchaseRequestMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreatePurchaseRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePurchaseRequestMutation,
    CreatePurchaseRequestMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreatePurchaseRequestMutation,
    CreatePurchaseRequestMutationVariables
  >(CreatePurchaseRequestDocument, baseOptions);
}
export type CreatePurchaseRequestMutationHookResult = ReturnType<
  typeof useCreatePurchaseRequestMutation
>;
export type CreatePurchaseRequestMutationResult = ApolloReactCommon.MutationResult<
  CreatePurchaseRequestMutation
>;
export type CreatePurchaseRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePurchaseRequestMutation,
  CreatePurchaseRequestMutationVariables
>;
export const CreatePurchaseRequestProductDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createPurchaseRequestProduct" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "suggestedSupplier" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "quantity" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "unit" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "expectedDeliveryDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "additionalInfo" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "productUrl" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "unitPrice" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currency" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CurrencyEnum" },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customFields" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "esourcingFields" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "Upload" },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createPurchaseRequestProduct" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "code" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "code" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "suggestedSupplier" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "suggestedSupplier" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "supplierCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "supplierCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "quantity" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "quantity" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "unit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "unit" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "expectedDeliveryDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "expectedDeliveryDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "additionalInfo" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "additionalInfo" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "productUrl" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "productUrl" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "unitPrice" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "unitPrice" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "currency" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "currency" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customFields" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customFields" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "esourcingFields" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "esourcingFields" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachments" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachments" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreatePurchaseRequestProductMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseRequestProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseRequestProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseRequestProductMutation, { data, loading, error }] = useCreatePurchaseRequestProductMutation({
 *   variables: {
 *      purchaseRequestId: // value for 'purchaseRequestId'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      suggestedSupplier: // value for 'suggestedSupplier'
 *      supplierCode: // value for 'supplierCode'
 *      quantity: // value for 'quantity'
 *      unit: // value for 'unit'
 *      expectedDeliveryDate: // value for 'expectedDeliveryDate'
 *      additionalInfo: // value for 'additionalInfo'
 *      productUrl: // value for 'productUrl'
 *      unitPrice: // value for 'unitPrice'
 *      currency: // value for 'currency'
 *      customFields: // value for 'customFields'
 *      esourcingFields: // value for 'esourcingFields'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useCreatePurchaseRequestProductMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePurchaseRequestProductMutation,
    CreatePurchaseRequestProductMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreatePurchaseRequestProductMutation,
    CreatePurchaseRequestProductMutationVariables
  >(CreatePurchaseRequestProductDocument, baseOptions);
}
export type CreatePurchaseRequestProductMutationHookResult = ReturnType<
  typeof useCreatePurchaseRequestProductMutation
>;
export type CreatePurchaseRequestProductMutationResult = ApolloReactCommon.MutationResult<
  CreatePurchaseRequestProductMutation
>;
export type CreatePurchaseRequestProductMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePurchaseRequestProductMutation,
  CreatePurchaseRequestProductMutationVariables
>;
export const CreatePurchaseRequestServiceDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreatePurchaseRequestService" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "suggestedSupplier" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "expectedDeliveryDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "additionalInfo" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "productUrl" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "unitPrice" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currency" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CurrencyEnum" },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customFields" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "esourcingFields" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "Upload" },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createPurchaseRequestService" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "code" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "code" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "suggestedSupplier" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "suggestedSupplier" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "supplierCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "supplierCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "expectedDeliveryDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "expectedDeliveryDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "additionalInfo" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "additionalInfo" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "productUrl" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "productUrl" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "unitPrice" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "unitPrice" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "currency" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "currency" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customFields" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customFields" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "esourcingFields" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "esourcingFields" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachments" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachments" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreatePurchaseRequestServiceMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseRequestServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseRequestServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseRequestServiceMutation, { data, loading, error }] = useCreatePurchaseRequestServiceMutation({
 *   variables: {
 *      purchaseRequestId: // value for 'purchaseRequestId'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      suggestedSupplier: // value for 'suggestedSupplier'
 *      supplierCode: // value for 'supplierCode'
 *      expectedDeliveryDate: // value for 'expectedDeliveryDate'
 *      additionalInfo: // value for 'additionalInfo'
 *      productUrl: // value for 'productUrl'
 *      unitPrice: // value for 'unitPrice'
 *      currency: // value for 'currency'
 *      customFields: // value for 'customFields'
 *      esourcingFields: // value for 'esourcingFields'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useCreatePurchaseRequestServiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePurchaseRequestServiceMutation,
    CreatePurchaseRequestServiceMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreatePurchaseRequestServiceMutation,
    CreatePurchaseRequestServiceMutationVariables
  >(CreatePurchaseRequestServiceDocument, baseOptions);
}
export type CreatePurchaseRequestServiceMutationHookResult = ReturnType<
  typeof useCreatePurchaseRequestServiceMutation
>;
export type CreatePurchaseRequestServiceMutationResult = ApolloReactCommon.MutationResult<
  CreatePurchaseRequestServiceMutation
>;
export type CreatePurchaseRequestServiceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePurchaseRequestServiceMutation,
  CreatePurchaseRequestServiceMutationVariables
>;
export const CreateQuantityUnitDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateQuantityUnit" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createQuantityUnit" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateQuantityUnitMutation__
 *
 * To run a mutation, you first call `useCreateQuantityUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuantityUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuantityUnitMutation, { data, loading, error }] = useCreateQuantityUnitMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateQuantityUnitMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateQuantityUnitMutation,
    CreateQuantityUnitMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateQuantityUnitMutation,
    CreateQuantityUnitMutationVariables
  >(CreateQuantityUnitDocument, baseOptions);
}
export type CreateQuantityUnitMutationHookResult = ReturnType<
  typeof useCreateQuantityUnitMutation
>;
export type CreateQuantityUnitMutationResult = ApolloReactCommon.MutationResult<
  CreateQuantityUnitMutation
>;
export type CreateQuantityUnitMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateQuantityUnitMutation,
  CreateQuantityUnitMutationVariables
>;
export const UploadProductExcelDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UploadProductExcel" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "excel" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Upload" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "uploadProductExcel" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "excel" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "excel" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "success" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rows" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "row" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "errors" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "suggestedSupplier",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unitPrice" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "expectedDeliveryDate",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "productUrl" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "additionalInfo" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "customFields" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "purchaseRequest" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUploadProductExcelMutation__
 *
 * To run a mutation, you first call `useUploadProductExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProductExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProductExcelMutation, { data, loading, error }] = useUploadProductExcelMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *      purchaseRequestId: // value for 'purchaseRequestId'
 *      excel: // value for 'excel'
 *   },
 * });
 */
export function useUploadProductExcelMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadProductExcelMutation,
    UploadProductExcelMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UploadProductExcelMutation,
    UploadProductExcelMutationVariables
  >(UploadProductExcelDocument, baseOptions);
}
export type UploadProductExcelMutationHookResult = ReturnType<
  typeof useUploadProductExcelMutation
>;
export type UploadProductExcelMutationResult = ApolloReactCommon.MutationResult<
  UploadProductExcelMutation
>;
export type UploadProductExcelMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadProductExcelMutation,
  UploadProductExcelMutationVariables
>;
export const ViewerOrganizationDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ViewerOrganization" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "baseCurrency" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantityUnits" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "projects" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "departments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "users" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useViewerOrganizationQuery__
 *
 * To run a query within a React component, call `useViewerOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewerOrganizationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewerOrganizationQuery,
    ViewerOrganizationQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    ViewerOrganizationQuery,
    ViewerOrganizationQueryVariables
  >(ViewerOrganizationDocument, baseOptions);
}
export function useViewerOrganizationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewerOrganizationQuery,
    ViewerOrganizationQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    ViewerOrganizationQuery,
    ViewerOrganizationQueryVariables
  >(ViewerOrganizationDocument, baseOptions);
}
export type ViewerOrganizationQueryHookResult = ReturnType<
  typeof useViewerOrganizationQuery
>;
export type ViewerOrganizationLazyQueryHookResult = ReturnType<
  typeof useViewerOrganizationLazyQuery
>;
export type ViewerOrganizationQueryResult = ApolloReactCommon.QueryResult<
  ViewerOrganizationQuery,
  ViewerOrganizationQueryVariables
>;
export const CreatePurchaseRequestViewDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CreatePurchaseRequestView" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roles" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "urlName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "baseCurrency" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "users" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "UserInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suppliers" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "defaultContactPerson",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "departments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "costCentres" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "projects" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantityUnits" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatarUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "department" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "costCentre" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreatePurchaseRequestViewQuery__
 *
 * To run a query within a React component, call `useCreatePurchaseRequestViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseRequestViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatePurchaseRequestViewQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreatePurchaseRequestViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CreatePurchaseRequestViewQuery,
    CreatePurchaseRequestViewQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    CreatePurchaseRequestViewQuery,
    CreatePurchaseRequestViewQueryVariables
  >(CreatePurchaseRequestViewDocument, baseOptions);
}
export function useCreatePurchaseRequestViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CreatePurchaseRequestViewQuery,
    CreatePurchaseRequestViewQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    CreatePurchaseRequestViewQuery,
    CreatePurchaseRequestViewQueryVariables
  >(CreatePurchaseRequestViewDocument, baseOptions);
}
export type CreatePurchaseRequestViewQueryHookResult = ReturnType<
  typeof useCreatePurchaseRequestViewQuery
>;
export type CreatePurchaseRequestViewLazyQueryHookResult = ReturnType<
  typeof useCreatePurchaseRequestViewLazyQuery
>;
export type CreatePurchaseRequestViewQueryResult = ApolloReactCommon.QueryResult<
  CreatePurchaseRequestViewQuery,
  CreatePurchaseRequestViewQueryVariables
>;
export const GetItemByIdDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetItemById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestItemId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "purchaseRequestItemById" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "purchaseRequestItemId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "purchaseRequestItemId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unit" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expectedDeliveryDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "productUrl" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "additionalInfo" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unitPrice" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suggestedSupplier" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customFields" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "esourcingFields" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attachments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useGetItemByIdQuery__
 *
 * To run a query within a React component, call `useGetItemByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemByIdQuery({
 *   variables: {
 *      purchaseRequestItemId: // value for 'purchaseRequestItemId'
 *   },
 * });
 */
export function useGetItemByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetItemByIdQuery,
    GetItemByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<GetItemByIdQuery, GetItemByIdQueryVariables>(
    GetItemByIdDocument,
    baseOptions,
  );
}
export function useGetItemByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetItemByIdQuery,
    GetItemByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetItemByIdQuery,
    GetItemByIdQueryVariables
  >(GetItemByIdDocument, baseOptions);
}
export type GetItemByIdQueryHookResult = ReturnType<typeof useGetItemByIdQuery>;
export type GetItemByIdLazyQueryHookResult = ReturnType<
  typeof useGetItemByIdLazyQuery
>;
export type GetItemByIdQueryResult = ApolloReactCommon.QueryResult<
  GetItemByIdQuery,
  GetItemByIdQueryVariables
>;
export const RemovePurchaseRequestItemAttachmentDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RemovePurchaseRequestItemAttachment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachmentId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestItemId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "removePurchaseRequestItemAttachment",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachmentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachmentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestItemId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestItemId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useRemovePurchaseRequestItemAttachmentMutation__
 *
 * To run a mutation, you first call `useRemovePurchaseRequestItemAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePurchaseRequestItemAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePurchaseRequestItemAttachmentMutation, { data, loading, error }] = useRemovePurchaseRequestItemAttachmentMutation({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *      purchaseRequestItemId: // value for 'purchaseRequestItemId'
 *   },
 * });
 */
export function useRemovePurchaseRequestItemAttachmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemovePurchaseRequestItemAttachmentMutation,
    RemovePurchaseRequestItemAttachmentMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    RemovePurchaseRequestItemAttachmentMutation,
    RemovePurchaseRequestItemAttachmentMutationVariables
  >(RemovePurchaseRequestItemAttachmentDocument, baseOptions);
}
export type RemovePurchaseRequestItemAttachmentMutationHookResult = ReturnType<
  typeof useRemovePurchaseRequestItemAttachmentMutation
>;
export type RemovePurchaseRequestItemAttachmentMutationResult = ApolloReactCommon.MutationResult<
  RemovePurchaseRequestItemAttachmentMutation
>;
export type RemovePurchaseRequestItemAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemovePurchaseRequestItemAttachmentMutation,
  RemovePurchaseRequestItemAttachmentMutationVariables
>;
export const UpdatePurchaseRequestProductDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdatePurchaseRequestProduct" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestItemId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "suggestedSupplier" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "quantity" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "unit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "expectedDeliveryDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "additionalInfo" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "productUrl" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "unitPrice" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currency" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CurrencyEnum" },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customFields" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "esourcingFields" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "Upload" },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updatePurchaseRequestProduct" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestItemId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestItemId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "code" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "code" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "suggestedSupplier" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "suggestedSupplier" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "supplierCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "supplierCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "quantity" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "quantity" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "unit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "unit" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "expectedDeliveryDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "expectedDeliveryDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "additionalInfo" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "additionalInfo" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "productUrl" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "productUrl" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "unitPrice" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "unitPrice" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "currency" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "currency" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customFields" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customFields" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "esourcingFields" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "esourcingFields" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachments" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachments" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdatePurchaseRequestProductMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseRequestProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseRequestProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseRequestProductMutation, { data, loading, error }] = useUpdatePurchaseRequestProductMutation({
 *   variables: {
 *      purchaseRequestItemId: // value for 'purchaseRequestItemId'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      suggestedSupplier: // value for 'suggestedSupplier'
 *      supplierCode: // value for 'supplierCode'
 *      quantity: // value for 'quantity'
 *      unit: // value for 'unit'
 *      expectedDeliveryDate: // value for 'expectedDeliveryDate'
 *      additionalInfo: // value for 'additionalInfo'
 *      productUrl: // value for 'productUrl'
 *      unitPrice: // value for 'unitPrice'
 *      currency: // value for 'currency'
 *      customFields: // value for 'customFields'
 *      esourcingFields: // value for 'esourcingFields'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useUpdatePurchaseRequestProductMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePurchaseRequestProductMutation,
    UpdatePurchaseRequestProductMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdatePurchaseRequestProductMutation,
    UpdatePurchaseRequestProductMutationVariables
  >(UpdatePurchaseRequestProductDocument, baseOptions);
}
export type UpdatePurchaseRequestProductMutationHookResult = ReturnType<
  typeof useUpdatePurchaseRequestProductMutation
>;
export type UpdatePurchaseRequestProductMutationResult = ApolloReactCommon.MutationResult<
  UpdatePurchaseRequestProductMutation
>;
export type UpdatePurchaseRequestProductMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePurchaseRequestProductMutation,
  UpdatePurchaseRequestProductMutationVariables
>;
export const UpdatePurchaseRequestServiceDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdatePurchaseRequestService" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestItemId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "suggestedSupplier" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "expectedDeliveryDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "additionalInfo" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "productUrl" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "unitPrice" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currency" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CurrencyEnum" },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customFields" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "esourcingFields" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "Upload" },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updatePurchaseRequestService" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestItemId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestItemId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "code" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "code" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "suggestedSupplier" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "suggestedSupplier" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "supplierCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "supplierCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "expectedDeliveryDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "expectedDeliveryDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "additionalInfo" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "additionalInfo" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "productUrl" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "productUrl" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "unitPrice" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "unitPrice" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "currency" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "currency" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customFields" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customFields" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "esourcingFields" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "esourcingFields" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachments" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachments" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdatePurchaseRequestServiceMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseRequestServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseRequestServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseRequestServiceMutation, { data, loading, error }] = useUpdatePurchaseRequestServiceMutation({
 *   variables: {
 *      purchaseRequestItemId: // value for 'purchaseRequestItemId'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      suggestedSupplier: // value for 'suggestedSupplier'
 *      supplierCode: // value for 'supplierCode'
 *      expectedDeliveryDate: // value for 'expectedDeliveryDate'
 *      additionalInfo: // value for 'additionalInfo'
 *      productUrl: // value for 'productUrl'
 *      unitPrice: // value for 'unitPrice'
 *      currency: // value for 'currency'
 *      customFields: // value for 'customFields'
 *      esourcingFields: // value for 'esourcingFields'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useUpdatePurchaseRequestServiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePurchaseRequestServiceMutation,
    UpdatePurchaseRequestServiceMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdatePurchaseRequestServiceMutation,
    UpdatePurchaseRequestServiceMutationVariables
  >(UpdatePurchaseRequestServiceDocument, baseOptions);
}
export type UpdatePurchaseRequestServiceMutationHookResult = ReturnType<
  typeof useUpdatePurchaseRequestServiceMutation
>;
export type UpdatePurchaseRequestServiceMutationResult = ApolloReactCommon.MutationResult<
  UpdatePurchaseRequestServiceMutation
>;
export type UpdatePurchaseRequestServiceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePurchaseRequestServiceMutation,
  UpdatePurchaseRequestServiceMutationVariables
>;
export const EmailViewDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "EmailView" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "activityId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "urlName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "activityById" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "activityId" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "activityId" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ActivityFeedItem" },
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ActivityFeedItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ActivityFeed" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "userId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "parentId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "relativeId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "relativeType" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "type" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "attachments" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filename" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mimetype" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "url" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "data" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ASSIGN" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assignerInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "DRAG_CARD" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "COMMENT" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ITEM" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "addItemQuantity" },
                        name: { kind: "Name", value: "quantity" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "addItemUnit" },
                        name: { kind: "Name", value: "unit" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ATTACHMENT" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "encoding" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "size" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_MENTION" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_REQUESTER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "emailInboundRequesterInfo",
                        },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_OUTBOUND_MENTION" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "emailOutboundMentionInfo",
                        },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_OUTBOUND_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subject" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rfxInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_APPROVAL_WORKFLOW" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_APPROVAL_WORKFLOW" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeCardInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_APPROVAL_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approverInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalRequestInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dueDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_APPROVAL_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approverInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalRequestInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceGroup" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceNumber" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approverComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dueDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decisionDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decision" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "REPLY" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ITEMS" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemCount" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_PURCHASE_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unit" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequestCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "CREATE_PURCHASE_REQUEST_SERVICE",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequestCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "DRAG_ITEM" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardName" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "supplierName" },
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "supplierName" },
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "changedFields" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fieldName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "oldValue" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "newValue" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_INVOICE_FROM_ORDER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "replies" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeType" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "type" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attachments" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ASSIGN" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assignerInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_CARD" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "COMMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemQuantity" },
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemUnit" },
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ATTACHMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encoding" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "size" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_REQUESTER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailInboundRequesterInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailOutboundMentionInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfxInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "REPLY" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEMS" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemCount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_SERVICE",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_APPROVAL_WORKFLOW",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "CREATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "changedFields" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fieldName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "oldValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "newValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_INVOICE_FROM_ORDER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "author" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "replies" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useEmailViewQuery__
 *
 * To run a query within a React component, call `useEmailViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailViewQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useEmailViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    EmailViewQuery,
    EmailViewQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<EmailViewQuery, EmailViewQueryVariables>(
    EmailViewDocument,
    baseOptions,
  );
}
export function useEmailViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EmailViewQuery,
    EmailViewQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<EmailViewQuery, EmailViewQueryVariables>(
    EmailViewDocument,
    baseOptions,
  );
}
export type EmailViewQueryHookResult = ReturnType<typeof useEmailViewQuery>;
export type EmailViewLazyQueryHookResult = ReturnType<
  typeof useEmailViewLazyQuery
>;
export type EmailViewQueryResult = ApolloReactCommon.QueryResult<
  EmailViewQuery,
  EmailViewQueryVariables
>;
export const TimerSubscriptionDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "subscription",
      name: { kind: "Name", value: "TimerSubscription" },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "timer" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __useTimerSubscriptionSubscription__
 *
 * To run a query within a React component, call `useTimerSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTimerSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimerSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTimerSubscriptionSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    TimerSubscriptionSubscription,
    TimerSubscriptionSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    TimerSubscriptionSubscription,
    TimerSubscriptionSubscriptionVariables
  >(TimerSubscriptionDocument, baseOptions);
}
export type TimerSubscriptionSubscriptionHookResult = ReturnType<
  typeof useTimerSubscriptionSubscription
>;
export type TimerSubscriptionSubscriptionResult = ApolloReactCommon.SubscriptionResult<
  TimerSubscriptionSubscription
>;
export const UpdateProfilePictureDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateProfilePicture" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "profilePicture" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Upload" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateProfilePicture" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "profilePicture" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "profilePicture" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateProfilePictureMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePictureMutation, { data, loading, error }] = useUpdateProfilePictureMutation({
 *   variables: {
 *      profilePicture: // value for 'profilePicture'
 *   },
 * });
 */
export function useUpdateProfilePictureMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProfilePictureMutation,
    UpdateProfilePictureMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateProfilePictureMutation,
    UpdateProfilePictureMutationVariables
  >(UpdateProfilePictureDocument, baseOptions);
}
export type UpdateProfilePictureMutationHookResult = ReturnType<
  typeof useUpdateProfilePictureMutation
>;
export type UpdateProfilePictureMutationResult = ApolloReactCommon.MutationResult<
  UpdateProfilePictureMutation
>;
export type UpdateProfilePictureMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProfilePictureMutation,
  UpdateProfilePictureMutationVariables
>;
export const AddPurchaseRequestItemsToOrderDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddPurchaseRequestItemsToOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestItems" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addPurchaseRequestItemsToOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestItems" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestItems" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useAddPurchaseRequestItemsToOrderMutation__
 *
 * To run a mutation, you first call `useAddPurchaseRequestItemsToOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPurchaseRequestItemsToOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPurchaseRequestItemsToOrderMutation, { data, loading, error }] = useAddPurchaseRequestItemsToOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      purchaseRequestItems: // value for 'purchaseRequestItems'
 *   },
 * });
 */
export function useAddPurchaseRequestItemsToOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddPurchaseRequestItemsToOrderMutation,
    AddPurchaseRequestItemsToOrderMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AddPurchaseRequestItemsToOrderMutation,
    AddPurchaseRequestItemsToOrderMutationVariables
  >(AddPurchaseRequestItemsToOrderDocument, baseOptions);
}
export type AddPurchaseRequestItemsToOrderMutationHookResult = ReturnType<
  typeof useAddPurchaseRequestItemsToOrderMutation
>;
export type AddPurchaseRequestItemsToOrderMutationResult = ApolloReactCommon.MutationResult<
  AddPurchaseRequestItemsToOrderMutation
>;
export type AddPurchaseRequestItemsToOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPurchaseRequestItemsToOrderMutation,
  AddPurchaseRequestItemsToOrderMutationVariables
>;
export const AddPurchaseRequestItemsToRfxDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddPurchaseRequestItemsToRfx" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rfxId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestItems" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addPurchaseRequestItemsToRfx" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "rfxId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rfxId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestItems" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestItems" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useAddPurchaseRequestItemsToRfxMutation__
 *
 * To run a mutation, you first call `useAddPurchaseRequestItemsToRfxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPurchaseRequestItemsToRfxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPurchaseRequestItemsToRfxMutation, { data, loading, error }] = useAddPurchaseRequestItemsToRfxMutation({
 *   variables: {
 *      rfxId: // value for 'rfxId'
 *      purchaseRequestItems: // value for 'purchaseRequestItems'
 *   },
 * });
 */
export function useAddPurchaseRequestItemsToRfxMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddPurchaseRequestItemsToRfxMutation,
    AddPurchaseRequestItemsToRfxMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AddPurchaseRequestItemsToRfxMutation,
    AddPurchaseRequestItemsToRfxMutationVariables
  >(AddPurchaseRequestItemsToRfxDocument, baseOptions);
}
export type AddPurchaseRequestItemsToRfxMutationHookResult = ReturnType<
  typeof useAddPurchaseRequestItemsToRfxMutation
>;
export type AddPurchaseRequestItemsToRfxMutationResult = ApolloReactCommon.MutationResult<
  AddPurchaseRequestItemsToRfxMutation
>;
export type AddPurchaseRequestItemsToRfxMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPurchaseRequestItemsToRfxMutation,
  AddPurchaseRequestItemsToRfxMutationVariables
>;
export const AssignCardDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AssignCard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "assigneeId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CardTypeEnum" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "assignCard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "itemId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "itemId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "assigneeId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "assigneeId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "type" },
                },
              },
            ],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __useAssignCardMutation__
 *
 * To run a mutation, you first call `useAssignCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCardMutation, { data, loading, error }] = useAssignCardMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      assigneeId: // value for 'assigneeId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAssignCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssignCardMutation,
    AssignCardMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AssignCardMutation,
    AssignCardMutationVariables
  >(AssignCardDocument, baseOptions);
}
export type AssignCardMutationHookResult = ReturnType<
  typeof useAssignCardMutation
>;
export type AssignCardMutationResult = ApolloReactCommon.MutationResult<
  AssignCardMutation
>;
export type AssignCardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AssignCardMutation,
  AssignCardMutationVariables
>;
export const CreateOrderFromPurchaseRequestDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateOrderFromPurchaseRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "relativeOrderId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "method" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CardPositionMethodEnum" },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createOrderFromPurchaseRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "relativeOrderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "relativeOrderId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "method" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "method" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateOrderFromPurchaseRequestMutation__
 *
 * To run a mutation, you first call `useCreateOrderFromPurchaseRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderFromPurchaseRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderFromPurchaseRequestMutation, { data, loading, error }] = useCreateOrderFromPurchaseRequestMutation({
 *   variables: {
 *      purchaseRequestId: // value for 'purchaseRequestId'
 *      relativeOrderId: // value for 'relativeOrderId'
 *      method: // value for 'method'
 *   },
 * });
 */
export function useCreateOrderFromPurchaseRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrderFromPurchaseRequestMutation,
    CreateOrderFromPurchaseRequestMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateOrderFromPurchaseRequestMutation,
    CreateOrderFromPurchaseRequestMutationVariables
  >(CreateOrderFromPurchaseRequestDocument, baseOptions);
}
export type CreateOrderFromPurchaseRequestMutationHookResult = ReturnType<
  typeof useCreateOrderFromPurchaseRequestMutation
>;
export type CreateOrderFromPurchaseRequestMutationResult = ApolloReactCommon.MutationResult<
  CreateOrderFromPurchaseRequestMutation
>;
export type CreateOrderFromPurchaseRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrderFromPurchaseRequestMutation,
  CreateOrderFromPurchaseRequestMutationVariables
>;
export const CreateOrderFromPurchaseRequestItemsDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateOrderFromPurchaseRequestItems" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestItems" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "createOrderFromPurchaseRequestItems",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestItems" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestItems" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateOrderFromPurchaseRequestItemsMutation__
 *
 * To run a mutation, you first call `useCreateOrderFromPurchaseRequestItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderFromPurchaseRequestItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderFromPurchaseRequestItemsMutation, { data, loading, error }] = useCreateOrderFromPurchaseRequestItemsMutation({
 *   variables: {
 *      purchaseRequestItems: // value for 'purchaseRequestItems'
 *   },
 * });
 */
export function useCreateOrderFromPurchaseRequestItemsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrderFromPurchaseRequestItemsMutation,
    CreateOrderFromPurchaseRequestItemsMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateOrderFromPurchaseRequestItemsMutation,
    CreateOrderFromPurchaseRequestItemsMutationVariables
  >(CreateOrderFromPurchaseRequestItemsDocument, baseOptions);
}
export type CreateOrderFromPurchaseRequestItemsMutationHookResult = ReturnType<
  typeof useCreateOrderFromPurchaseRequestItemsMutation
>;
export type CreateOrderFromPurchaseRequestItemsMutationResult = ApolloReactCommon.MutationResult<
  CreateOrderFromPurchaseRequestItemsMutation
>;
export type CreateOrderFromPurchaseRequestItemsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrderFromPurchaseRequestItemsMutation,
  CreateOrderFromPurchaseRequestItemsMutationVariables
>;
export const CreateOrderFromRfxDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateOrderFromRfx" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rfxId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "relativeOrderId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "method" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CardPositionMethodEnum" },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rfxItemIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "String" },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "hideRfx" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isCreatedFromSourcingEvent" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createOrderFromRfx" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "rfxId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rfxId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "relativeOrderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "relativeOrderId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "method" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "method" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "rfxItemIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rfxItemIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "supplierId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "supplierId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideRfx" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "hideRfx" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isCreatedFromSourcingEvent" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isCreatedFromSourcingEvent" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateOrderFromRfxMutation__
 *
 * To run a mutation, you first call `useCreateOrderFromRfxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderFromRfxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderFromRfxMutation, { data, loading, error }] = useCreateOrderFromRfxMutation({
 *   variables: {
 *      rfxId: // value for 'rfxId'
 *      relativeOrderId: // value for 'relativeOrderId'
 *      method: // value for 'method'
 *      rfxItemIds: // value for 'rfxItemIds'
 *      supplierId: // value for 'supplierId'
 *      hideRfx: // value for 'hideRfx'
 *      isCreatedFromSourcingEvent: // value for 'isCreatedFromSourcingEvent'
 *   },
 * });
 */
export function useCreateOrderFromRfxMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrderFromRfxMutation,
    CreateOrderFromRfxMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateOrderFromRfxMutation,
    CreateOrderFromRfxMutationVariables
  >(CreateOrderFromRfxDocument, baseOptions);
}
export type CreateOrderFromRfxMutationHookResult = ReturnType<
  typeof useCreateOrderFromRfxMutation
>;
export type CreateOrderFromRfxMutationResult = ApolloReactCommon.MutationResult<
  CreateOrderFromRfxMutation
>;
export type CreateOrderFromRfxMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrderFromRfxMutation,
  CreateOrderFromRfxMutationVariables
>;
export const CreateRfxFromPurchaseRequestDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateRfxFromPurchaseRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "relativeRfxId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "method" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CardPositionMethodEnum" },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createRfxFromPurchaseRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "relativeRfxId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "relativeRfxId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "method" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "method" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateRfxFromPurchaseRequestMutation__
 *
 * To run a mutation, you first call `useCreateRfxFromPurchaseRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRfxFromPurchaseRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRfxFromPurchaseRequestMutation, { data, loading, error }] = useCreateRfxFromPurchaseRequestMutation({
 *   variables: {
 *      purchaseRequestId: // value for 'purchaseRequestId'
 *      relativeRfxId: // value for 'relativeRfxId'
 *      method: // value for 'method'
 *   },
 * });
 */
export function useCreateRfxFromPurchaseRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateRfxFromPurchaseRequestMutation,
    CreateRfxFromPurchaseRequestMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateRfxFromPurchaseRequestMutation,
    CreateRfxFromPurchaseRequestMutationVariables
  >(CreateRfxFromPurchaseRequestDocument, baseOptions);
}
export type CreateRfxFromPurchaseRequestMutationHookResult = ReturnType<
  typeof useCreateRfxFromPurchaseRequestMutation
>;
export type CreateRfxFromPurchaseRequestMutationResult = ApolloReactCommon.MutationResult<
  CreateRfxFromPurchaseRequestMutation
>;
export type CreateRfxFromPurchaseRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateRfxFromPurchaseRequestMutation,
  CreateRfxFromPurchaseRequestMutationVariables
>;
export const CreateRfxFromPurchaseRequestItemsDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateRfxFromPurchaseRequestItems" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestItems" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createRfxFromPurchaseRequestItems" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestItems" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestItems" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateRfxFromPurchaseRequestItemsMutation__
 *
 * To run a mutation, you first call `useCreateRfxFromPurchaseRequestItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRfxFromPurchaseRequestItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRfxFromPurchaseRequestItemsMutation, { data, loading, error }] = useCreateRfxFromPurchaseRequestItemsMutation({
 *   variables: {
 *      purchaseRequestItems: // value for 'purchaseRequestItems'
 *   },
 * });
 */
export function useCreateRfxFromPurchaseRequestItemsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateRfxFromPurchaseRequestItemsMutation,
    CreateRfxFromPurchaseRequestItemsMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateRfxFromPurchaseRequestItemsMutation,
    CreateRfxFromPurchaseRequestItemsMutationVariables
  >(CreateRfxFromPurchaseRequestItemsDocument, baseOptions);
}
export type CreateRfxFromPurchaseRequestItemsMutationHookResult = ReturnType<
  typeof useCreateRfxFromPurchaseRequestItemsMutation
>;
export type CreateRfxFromPurchaseRequestItemsMutationResult = ApolloReactCommon.MutationResult<
  CreateRfxFromPurchaseRequestItemsMutation
>;
export type CreateRfxFromPurchaseRequestItemsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateRfxFromPurchaseRequestItemsMutation,
  CreateRfxFromPurchaseRequestItemsMutationVariables
>;
export const FullTextSearchDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FullTextSearch" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchInput" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "KanbanFilterInput" },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "fullTextSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "searchInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "searchInput" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "prefix" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "prefix" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "text" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "level" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "status" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "text" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "level" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "type" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "isArchived" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useFullTextSearchMutation__
 *
 * To run a mutation, you first call `useFullTextSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFullTextSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fullTextSearchMutation, { data, loading, error }] = useFullTextSearchMutation({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      organizationId: // value for 'organizationId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFullTextSearchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FullTextSearchMutation,
    FullTextSearchMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    FullTextSearchMutation,
    FullTextSearchMutationVariables
  >(FullTextSearchDocument, baseOptions);
}
export type FullTextSearchMutationHookResult = ReturnType<
  typeof useFullTextSearchMutation
>;
export type FullTextSearchMutationResult = ApolloReactCommon.MutationResult<
  FullTextSearchMutation
>;
export type FullTextSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FullTextSearchMutation,
  FullTextSearchMutationVariables
>;
export const KanbanViewDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "KanbanView" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roles" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "urlName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "users" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "UserInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatarUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "department" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "costCentre" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useKanbanViewQuery__
 *
 * To run a query within a React component, call `useKanbanViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useKanbanViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKanbanViewQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useKanbanViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    KanbanViewQuery,
    KanbanViewQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<KanbanViewQuery, KanbanViewQueryVariables>(
    KanbanViewDocument,
    baseOptions,
  );
}
export function useKanbanViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    KanbanViewQuery,
    KanbanViewQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    KanbanViewQuery,
    KanbanViewQueryVariables
  >(KanbanViewDocument, baseOptions);
}
export type KanbanViewQueryHookResult = ReturnType<typeof useKanbanViewQuery>;
export type KanbanViewLazyQueryHookResult = ReturnType<
  typeof useKanbanViewLazyQuery
>;
export type KanbanViewQueryResult = ApolloReactCommon.QueryResult<
  KanbanViewQuery,
  KanbanViewQueryVariables
>;
export const PaginatedInvoicesDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PaginatedInvoices" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "take" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cursor" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "KanbanFilterInput" },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paginatedInvoices" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "take" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "take" },
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "cursor" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "cursor" },
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "filter" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "edges" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardStatus" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "level",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "text" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "assignee" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "UserInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "supplier" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "SupplierInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "PurchaseRequestItemInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "projects" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "ProjectInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "requestors" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "UserInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endCursor" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "hasNextPage",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatarUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "department" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "costCentre" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SupplierInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Supplier" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentTerms" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "defaultContactPerson" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PurchaseRequestItemInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PurchaseRequestItem" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "parentCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "type" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "quantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "receivedQuantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "totalReceivedQuantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "unit" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "assignee" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedDeliveryDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "productUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "unitPrice" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currency" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProjectInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Project" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __usePaginatedInvoicesQuery__
 *
 * To run a query within a React component, call `usePaginatedInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedInvoicesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      take: // value for 'take'
 *      cursor: // value for 'cursor'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePaginatedInvoicesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PaginatedInvoicesQuery,
    PaginatedInvoicesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    PaginatedInvoicesQuery,
    PaginatedInvoicesQueryVariables
  >(PaginatedInvoicesDocument, baseOptions);
}
export function usePaginatedInvoicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaginatedInvoicesQuery,
    PaginatedInvoicesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    PaginatedInvoicesQuery,
    PaginatedInvoicesQueryVariables
  >(PaginatedInvoicesDocument, baseOptions);
}
export type PaginatedInvoicesQueryHookResult = ReturnType<
  typeof usePaginatedInvoicesQuery
>;
export type PaginatedInvoicesLazyQueryHookResult = ReturnType<
  typeof usePaginatedInvoicesLazyQuery
>;
export type PaginatedInvoicesQueryResult = ApolloReactCommon.QueryResult<
  PaginatedInvoicesQuery,
  PaginatedInvoicesQueryVariables
>;
export const PaginatedOrdersDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PaginatedOrders" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "take" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cursor" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "KanbanFilterInput" },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paginatedOrders" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "take" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "take" },
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "cursor" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "cursor" },
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "filter" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "edges" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "department" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardStatus" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "level",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "text" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "assignee" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "UserInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "supplier" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "SupplierInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "PurchaseRequestItemInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "projects" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "ProjectInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "requestors" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "UserInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endCursor" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "hasNextPage",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatarUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "department" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "costCentre" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SupplierInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Supplier" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentTerms" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "defaultContactPerson" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PurchaseRequestItemInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PurchaseRequestItem" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "parentCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "type" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "quantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "receivedQuantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "totalReceivedQuantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "unit" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "assignee" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedDeliveryDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "productUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "unitPrice" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currency" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProjectInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Project" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __usePaginatedOrdersQuery__
 *
 * To run a query within a React component, call `usePaginatedOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedOrdersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      take: // value for 'take'
 *      cursor: // value for 'cursor'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePaginatedOrdersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PaginatedOrdersQuery,
    PaginatedOrdersQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    PaginatedOrdersQuery,
    PaginatedOrdersQueryVariables
  >(PaginatedOrdersDocument, baseOptions);
}
export function usePaginatedOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaginatedOrdersQuery,
    PaginatedOrdersQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    PaginatedOrdersQuery,
    PaginatedOrdersQueryVariables
  >(PaginatedOrdersDocument, baseOptions);
}
export type PaginatedOrdersQueryHookResult = ReturnType<
  typeof usePaginatedOrdersQuery
>;
export type PaginatedOrdersLazyQueryHookResult = ReturnType<
  typeof usePaginatedOrdersLazyQuery
>;
export type PaginatedOrdersQueryResult = ApolloReactCommon.QueryResult<
  PaginatedOrdersQuery,
  PaginatedOrdersQueryVariables
>;
export const PaginatedPurchaseRequestsDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PaginatedPurchaseRequests" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "take" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cursor" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "KanbanFilterInput" },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "paginatedPurchaseRequests",
                        },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "take" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "take" },
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "cursor" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "cursor" },
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "filter" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "edges" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardStatus" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "level",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "text" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "requestor" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "UserInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "assignee" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "UserInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "project" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "ProjectInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "suppliers" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "SupplierInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "department" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "DepartmentInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "PurchaseRequestItemInfo",
                                          },
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "level",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "text",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "latestApprovalWorkflow",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "isRfxCreated",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "isOrderCreated",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endCursor" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "hasNextPage",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatarUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "department" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "costCentre" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProjectInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Project" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SupplierInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Supplier" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentTerms" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "defaultContactPerson" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DepartmentInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Department" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "code" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PurchaseRequestItemInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PurchaseRequestItem" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "parentCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "type" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "quantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "receivedQuantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "totalReceivedQuantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "unit" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "assignee" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedDeliveryDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "productUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "unitPrice" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currency" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __usePaginatedPurchaseRequestsQuery__
 *
 * To run a query within a React component, call `usePaginatedPurchaseRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedPurchaseRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedPurchaseRequestsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      take: // value for 'take'
 *      cursor: // value for 'cursor'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePaginatedPurchaseRequestsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PaginatedPurchaseRequestsQuery,
    PaginatedPurchaseRequestsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    PaginatedPurchaseRequestsQuery,
    PaginatedPurchaseRequestsQueryVariables
  >(PaginatedPurchaseRequestsDocument, baseOptions);
}
export function usePaginatedPurchaseRequestsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaginatedPurchaseRequestsQuery,
    PaginatedPurchaseRequestsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    PaginatedPurchaseRequestsQuery,
    PaginatedPurchaseRequestsQueryVariables
  >(PaginatedPurchaseRequestsDocument, baseOptions);
}
export type PaginatedPurchaseRequestsQueryHookResult = ReturnType<
  typeof usePaginatedPurchaseRequestsQuery
>;
export type PaginatedPurchaseRequestsLazyQueryHookResult = ReturnType<
  typeof usePaginatedPurchaseRequestsLazyQuery
>;
export type PaginatedPurchaseRequestsQueryResult = ApolloReactCommon.QueryResult<
  PaginatedPurchaseRequestsQuery,
  PaginatedPurchaseRequestsQueryVariables
>;
export const PaginatedReceivingDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PaginatedReceiving" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "take" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cursor" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "KanbanFilterInput" },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paginatedReceiving" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "take" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "take" },
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "cursor" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "cursor" },
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "filter" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "edges" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardStatus" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "level",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "text" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "isConfirmationRequired",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "receivingIsConfirmationReceived",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "assignee" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "UserInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "supplier" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "SupplierInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "PurchaseRequestItemInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "projects" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "ProjectInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "requestors" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "UserInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "expectedDeliveryDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endCursor" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "hasNextPage",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatarUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "department" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "costCentre" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SupplierInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Supplier" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentTerms" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "defaultContactPerson" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PurchaseRequestItemInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PurchaseRequestItem" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "parentCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "type" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "quantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "receivedQuantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "totalReceivedQuantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "unit" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "assignee" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedDeliveryDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "productUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "unitPrice" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currency" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProjectInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Project" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __usePaginatedReceivingQuery__
 *
 * To run a query within a React component, call `usePaginatedReceivingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedReceivingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedReceivingQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      take: // value for 'take'
 *      cursor: // value for 'cursor'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePaginatedReceivingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PaginatedReceivingQuery,
    PaginatedReceivingQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    PaginatedReceivingQuery,
    PaginatedReceivingQueryVariables
  >(PaginatedReceivingDocument, baseOptions);
}
export function usePaginatedReceivingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaginatedReceivingQuery,
    PaginatedReceivingQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    PaginatedReceivingQuery,
    PaginatedReceivingQueryVariables
  >(PaginatedReceivingDocument, baseOptions);
}
export type PaginatedReceivingQueryHookResult = ReturnType<
  typeof usePaginatedReceivingQuery
>;
export type PaginatedReceivingLazyQueryHookResult = ReturnType<
  typeof usePaginatedReceivingLazyQuery
>;
export type PaginatedReceivingQueryResult = ApolloReactCommon.QueryResult<
  PaginatedReceivingQuery,
  PaginatedReceivingQueryVariables
>;
export const PaginatedRfxDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PaginatedRfx" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "take" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cursor" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "KanbanFilterInput" },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paginatedRfx" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "take" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "take" },
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "cursor" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "cursor" },
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "filter" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "edges" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardStatus" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "level",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "text" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "assignee" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "UserInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "suppliers" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "SupplierInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "PurchaseRequestItemInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "projects" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "ProjectInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "requestors" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "UserInfo",
                                          },
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endCursor" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "hasNextPage",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatarUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "department" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "costCentre" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SupplierInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Supplier" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentTerms" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "defaultContactPerson" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PurchaseRequestItemInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PurchaseRequestItem" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "parentCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "type" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "quantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "receivedQuantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "totalReceivedQuantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "unit" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "assignee" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedDeliveryDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "productUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "unitPrice" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currency" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProjectInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Project" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __usePaginatedRfxQuery__
 *
 * To run a query within a React component, call `usePaginatedRfxQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedRfxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedRfxQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      take: // value for 'take'
 *      cursor: // value for 'cursor'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePaginatedRfxQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PaginatedRfxQuery,
    PaginatedRfxQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    PaginatedRfxQuery,
    PaginatedRfxQueryVariables
  >(PaginatedRfxDocument, baseOptions);
}
export function usePaginatedRfxLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaginatedRfxQuery,
    PaginatedRfxQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    PaginatedRfxQuery,
    PaginatedRfxQueryVariables
  >(PaginatedRfxDocument, baseOptions);
}
export type PaginatedRfxQueryHookResult = ReturnType<
  typeof usePaginatedRfxQuery
>;
export type PaginatedRfxLazyQueryHookResult = ReturnType<
  typeof usePaginatedRfxLazyQuery
>;
export type PaginatedRfxQueryResult = ApolloReactCommon.QueryResult<
  PaginatedRfxQuery,
  PaginatedRfxQueryVariables
>;
export const UpdateCardPositionDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateCardPosition" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "relativeId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "method" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CardPositionMethodEnum" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CardTypeEnum" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateCardPosition" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "itemId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "itemId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "relativeId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "relativeId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "method" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "method" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "type" },
                },
              },
            ],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateCardPositionMutation__
 *
 * To run a mutation, you first call `useUpdateCardPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardPositionMutation, { data, loading, error }] = useUpdateCardPositionMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      relativeId: // value for 'relativeId'
 *      method: // value for 'method'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateCardPositionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCardPositionMutation,
    UpdateCardPositionMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateCardPositionMutation,
    UpdateCardPositionMutationVariables
  >(UpdateCardPositionDocument, baseOptions);
}
export type UpdateCardPositionMutationHookResult = ReturnType<
  typeof useUpdateCardPositionMutation
>;
export type UpdateCardPositionMutationResult = ApolloReactCommon.MutationResult<
  UpdateCardPositionMutation
>;
export type UpdateCardPositionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCardPositionMutation,
  UpdateCardPositionMutationVariables
>;
export const UpdateOrderTypeDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateOrderType" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "OrderTypeEnum" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "relativeOrderId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "method" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CardPositionMethodEnum" },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "positionWithoutRelativeOrder" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateOrderType" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "type" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "relativeOrderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "relativeOrderId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "method" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "method" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "positionWithoutRelativeOrder" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "positionWithoutRelativeOrder" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateOrderTypeMutation__
 *
 * To run a mutation, you first call `useUpdateOrderTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderTypeMutation, { data, loading, error }] = useUpdateOrderTypeMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      type: // value for 'type'
 *      relativeOrderId: // value for 'relativeOrderId'
 *      method: // value for 'method'
 *      positionWithoutRelativeOrder: // value for 'positionWithoutRelativeOrder'
 *   },
 * });
 */
export function useUpdateOrderTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrderTypeMutation,
    UpdateOrderTypeMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateOrderTypeMutation,
    UpdateOrderTypeMutationVariables
  >(UpdateOrderTypeDocument, baseOptions);
}
export type UpdateOrderTypeMutationHookResult = ReturnType<
  typeof useUpdateOrderTypeMutation
>;
export type UpdateOrderTypeMutationResult = ApolloReactCommon.MutationResult<
  UpdateOrderTypeMutation
>;
export type UpdateOrderTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateOrderTypeMutation,
  UpdateOrderTypeMutationVariables
>;
export const LoginSupplierUserWithTokenDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "LoginSupplierUserWithToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "token" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "loginSupplierUserWithToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "token" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "token" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roles" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useLoginSupplierUserWithTokenMutation__
 *
 * To run a mutation, you first call `useLoginSupplierUserWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginSupplierUserWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginSupplierUserWithTokenMutation, { data, loading, error }] = useLoginSupplierUserWithTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoginSupplierUserWithTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginSupplierUserWithTokenMutation,
    LoginSupplierUserWithTokenMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    LoginSupplierUserWithTokenMutation,
    LoginSupplierUserWithTokenMutationVariables
  >(LoginSupplierUserWithTokenDocument, baseOptions);
}
export type LoginSupplierUserWithTokenMutationHookResult = ReturnType<
  typeof useLoginSupplierUserWithTokenMutation
>;
export type LoginSupplierUserWithTokenMutationResult = ApolloReactCommon.MutationResult<
  LoginSupplierUserWithTokenMutation
>;
export type LoginSupplierUserWithTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginSupplierUserWithTokenMutation,
  LoginSupplierUserWithTokenMutationVariables
>;
export const LoginWithTokenDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "LoginWithToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "token" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "loginWithToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "token" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "token" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roles" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useLoginWithTokenMutation__
 *
 * To run a mutation, you first call `useLoginWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithTokenMutation, { data, loading, error }] = useLoginWithTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoginWithTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginWithTokenMutation,
    LoginWithTokenMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    LoginWithTokenMutation,
    LoginWithTokenMutationVariables
  >(LoginWithTokenDocument, baseOptions);
}
export type LoginWithTokenMutationHookResult = ReturnType<
  typeof useLoginWithTokenMutation
>;
export type LoginWithTokenMutationResult = ApolloReactCommon.MutationResult<
  LoginWithTokenMutation
>;
export type LoginWithTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginWithTokenMutation,
  LoginWithTokenMutationVariables
>;
export const SendLoginTokenDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SendLoginToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendLoginToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
            ],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __useSendLoginTokenMutation__
 *
 * To run a mutation, you first call `useSendLoginTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendLoginTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendLoginTokenMutation, { data, loading, error }] = useSendLoginTokenMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendLoginTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendLoginTokenMutation,
    SendLoginTokenMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SendLoginTokenMutation,
    SendLoginTokenMutationVariables
  >(SendLoginTokenDocument, baseOptions);
}
export type SendLoginTokenMutationHookResult = ReturnType<
  typeof useSendLoginTokenMutation
>;
export type SendLoginTokenMutationResult = ApolloReactCommon.MutationResult<
  SendLoginTokenMutation
>;
export type SendLoginTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendLoginTokenMutation,
  SendLoginTokenMutationVariables
>;
export const LogoutDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Logout" },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "logout" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    baseOptions,
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<
  LogoutMutation
>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const MarkAllNotificationsSeenDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "MarkAllNotificationsSeen" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "markAllNotificationsSeen" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __useMarkAllNotificationsSeenMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationsSeenMutation, { data, loading, error }] = useMarkAllNotificationsSeenMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useMarkAllNotificationsSeenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkAllNotificationsSeenMutation,
    MarkAllNotificationsSeenMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    MarkAllNotificationsSeenMutation,
    MarkAllNotificationsSeenMutationVariables
  >(MarkAllNotificationsSeenDocument, baseOptions);
}
export type MarkAllNotificationsSeenMutationHookResult = ReturnType<
  typeof useMarkAllNotificationsSeenMutation
>;
export type MarkAllNotificationsSeenMutationResult = ApolloReactCommon.MutationResult<
  MarkAllNotificationsSeenMutation
>;
export type MarkAllNotificationsSeenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MarkAllNotificationsSeenMutation,
  MarkAllNotificationsSeenMutationVariables
>;
export const MarkNotificationsSeenByIdsDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "MarkNotificationsSeenByIds" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "notificationIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "markNotificationsSeenByIds" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "notificationIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "notificationIds" },
                },
              },
            ],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __useMarkNotificationsSeenByIdsMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsSeenByIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsSeenByIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsSeenByIdsMutation, { data, loading, error }] = useMarkNotificationsSeenByIdsMutation({
 *   variables: {
 *      notificationIds: // value for 'notificationIds'
 *   },
 * });
 */
export function useMarkNotificationsSeenByIdsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkNotificationsSeenByIdsMutation,
    MarkNotificationsSeenByIdsMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    MarkNotificationsSeenByIdsMutation,
    MarkNotificationsSeenByIdsMutationVariables
  >(MarkNotificationsSeenByIdsDocument, baseOptions);
}
export type MarkNotificationsSeenByIdsMutationHookResult = ReturnType<
  typeof useMarkNotificationsSeenByIdsMutation
>;
export type MarkNotificationsSeenByIdsMutationResult = ApolloReactCommon.MutationResult<
  MarkNotificationsSeenByIdsMutation
>;
export type MarkNotificationsSeenByIdsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MarkNotificationsSeenByIdsMutation,
  MarkNotificationsSeenByIdsMutationVariables
>;
export const NotificationsDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Notifications" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizatonId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "skipSeen" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizatonId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "notifications" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "skipSeen" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "skipSeen" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hasSeen" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "activity" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "organizationId",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "relativeId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeType",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "assigneeId",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "requestorId",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "parentId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "userId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ASSIGN",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "itemInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "type",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "assignerInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "assigneeInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "DRAG_CARD",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "cardName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "fromType",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "toType",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "fromCode",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "toCode",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "requestorInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "assigneeInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "COMMENT",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "user",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "relativeInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "InlineFragment",
                                                      typeCondition: {
                                                        kind: "NamedType",
                                                        name: {
                                                          kind: "Name",
                                                          value:
                                                            "ActivityRelativeCardInfo",
                                                        },
                                                      },
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            alias: {
                                                              kind: "Name",
                                                              value:
                                                                "relativeCardInfoName",
                                                            },
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "code",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "url",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "InlineFragment",
                                                      typeCondition: {
                                                        kind: "NamedType",
                                                        name: {
                                                          kind: "Name",
                                                          value:
                                                            "ActivityRelativeSupplierInfo",
                                                        },
                                                      },
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            alias: {
                                                              kind: "Name",
                                                              value:
                                                                "relativeSupplierInfoName",
                                                            },
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "InlineFragment",
                                                      typeCondition: {
                                                        kind: "NamedType",
                                                        name: {
                                                          kind: "Name",
                                                          value:
                                                            "ActivityRelativeItemInfo",
                                                        },
                                                      },
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            alias: {
                                                              kind: "Name",
                                                              value:
                                                                "relativeItemInfoName",
                                                            },
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "quantity",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "unit",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "comment",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ADD_ITEM",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "addItemQuantity",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "addItemUnit",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "creator",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ADD_ATTACHMENT",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "filename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "encoding",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "mimetype",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "size",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "EMAIL_INBOUND_MENTION",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "user",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "relativeInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "InlineFragment",
                                                      typeCondition: {
                                                        kind: "NamedType",
                                                        name: {
                                                          kind: "Name",
                                                          value:
                                                            "ActivityRelativeCardInfo",
                                                        },
                                                      },
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            alias: {
                                                              kind: "Name",
                                                              value:
                                                                "relativeCardInfoName",
                                                            },
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "code",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "url",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "InlineFragment",
                                                      typeCondition: {
                                                        kind: "NamedType",
                                                        name: {
                                                          kind: "Name",
                                                          value:
                                                            "ActivityRelativeSupplierInfo",
                                                        },
                                                      },
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            alias: {
                                                              kind: "Name",
                                                              value:
                                                                "relativeSupplierInfoName",
                                                            },
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "InlineFragment",
                                                      typeCondition: {
                                                        kind: "NamedType",
                                                        name: {
                                                          kind: "Name",
                                                          value:
                                                            "ActivityRelativeItemInfo",
                                                        },
                                                      },
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            alias: {
                                                              kind: "Name",
                                                              value:
                                                                "relativeItemInfoName",
                                                            },
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "quantity",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "unit",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "emailInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "subject",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "message",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "comment",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "EMAIL_INBOUND_SUPPLIER",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "supplierInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "emailInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "subject",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "message",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "EMAIL_INBOUND_REQUESTER",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "emailInboundRequesterInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "EMAIL_OUTBOUND_MENTION",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "emailOutboundMentionInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "user",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "EMAIL_OUTBOUND_SUPPLIER",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "subject",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "message",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "rfxInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "type",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "orderInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "type",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "supplierInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "creator",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "REPLY",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "user",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "relativeInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "InlineFragment",
                                                      typeCondition: {
                                                        kind: "NamedType",
                                                        name: {
                                                          kind: "Name",
                                                          value:
                                                            "ActivityRelativeCardInfo",
                                                        },
                                                      },
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            alias: {
                                                              kind: "Name",
                                                              value:
                                                                "relativeCardInfoName",
                                                            },
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "code",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "url",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "InlineFragment",
                                                      typeCondition: {
                                                        kind: "NamedType",
                                                        name: {
                                                          kind: "Name",
                                                          value:
                                                            "ActivityRelativeSupplierInfo",
                                                        },
                                                      },
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            alias: {
                                                              kind: "Name",
                                                              value:
                                                                "relativeSupplierInfoName",
                                                            },
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "InlineFragment",
                                                      typeCondition: {
                                                        kind: "NamedType",
                                                        name: {
                                                          kind: "Name",
                                                          value:
                                                            "ActivityRelativeItemInfo",
                                                        },
                                                      },
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            alias: {
                                                              kind: "Name",
                                                              value:
                                                                "relativeItemInfoName",
                                                            },
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "quantity",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "unit",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "comment",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ADD_ITEMS",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "itemCount",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "creator",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "CREATE_PURCHASE_REQUEST",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "creator",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "CREATE_PURCHASE_REQUEST_PRODUCT",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "purchaseRequestCode",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "creator",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "CREATE_PURCHASE_REQUEST_SERVICE",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "purchaseRequestCode",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "creator",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "DRAG_ITEM",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "cardName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "CREATE_APPROVAL_WORKFLOW",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "creatorInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "approvalWorkflowInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "requestName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "comment",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "approvalAmount",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "status",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "approvalWorkflowStats",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "totalRequestCount",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "approvedRequestCount",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "UPDATE_APPROVAL_WORKFLOW",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "creatorInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "approvalWorkflowInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "requestName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "comment",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "approvalAmount",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "status",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "relativeCardInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "approvalWorkflowStats",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "totalRequestCount",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "approvedRequestCount",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "CREATE_APPROVAL_REQUEST",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "approverInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "approvalRequestInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "sequenceGroup",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "sequenceNumber",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "creatorComment",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "approverComment",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dueDate",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "decisionDate",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "decision",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "approvalWorkflowInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "requestName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "approvalAmount",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "status",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "approvalWorkflowStats",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "totalRequestCount",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "approvedRequestCount",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "UPDATE_APPROVAL_REQUEST",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "approverInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "approvalRequestInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "sequenceGroup",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "sequenceNumber",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "creatorComment",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "approverComment",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dueDate",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "decisionDate",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "decision",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "approvalWorkflowInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "requestName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "approvalAmount",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "status",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "approvalWorkflowStats",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "totalRequestCount",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "approvedRequestCount",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "CREATE_SUPPLIER",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "supplierName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "creator",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "UPDATE_SUPPLIER",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "supplierName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "creator",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "changedFields",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "fieldName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "oldValue",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "newValue",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "UPDATE_ORDER_RECEIVED_QUANTITIES",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "user",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "orderInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "type",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "CREATE_INVOICE_FROM_ORDER",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "user",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "firstName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "lastName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "avatarUrl",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "orderInfo",
                                                },
                                                arguments: [],
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "type",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "author" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "replies" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "userId",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "organizationId",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "relativeId",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "relativeType",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "parentId",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "data" },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value: "ASSIGN",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "itemInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "type",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "code",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "assignerInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "assigneeInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value: "DRAG_CARD",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "cardName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "fromType",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "toType",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "fromCode",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "toCode",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "requestorInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "assigneeInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value: "COMMENT",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "user",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "relativeInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "__typename",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind:
                                                              "InlineFragment",
                                                            typeCondition: {
                                                              kind: "NamedType",
                                                              name: {
                                                                kind: "Name",
                                                                value:
                                                                  "ActivityRelativeCardInfo",
                                                              },
                                                            },
                                                            directives: [],
                                                            selectionSet: {
                                                              kind:
                                                                "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  alias: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "relativeCardInfoName",
                                                                  },
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "name",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "code",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "url",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind:
                                                              "InlineFragment",
                                                            typeCondition: {
                                                              kind: "NamedType",
                                                              name: {
                                                                kind: "Name",
                                                                value:
                                                                  "ActivityRelativeSupplierInfo",
                                                              },
                                                            },
                                                            directives: [],
                                                            selectionSet: {
                                                              kind:
                                                                "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  alias: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "relativeSupplierInfoName",
                                                                  },
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "name",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "email",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind:
                                                              "InlineFragment",
                                                            typeCondition: {
                                                              kind: "NamedType",
                                                              name: {
                                                                kind: "Name",
                                                                value:
                                                                  "ActivityRelativeItemInfo",
                                                              },
                                                            },
                                                            directives: [],
                                                            selectionSet: {
                                                              kind:
                                                                "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  alias: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "relativeItemInfoName",
                                                                  },
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "name",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "quantity",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "unit",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "comment",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value: "ADD_ITEM",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "addItemQuantity",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "quantity",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value: "addItemUnit",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "unit",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "creator",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value: "ADD_ATTACHMENT",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "filename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "encoding",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "mimetype",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "size",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "EMAIL_INBOUND_MENTION",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "user",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "relativeInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "__typename",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind:
                                                              "InlineFragment",
                                                            typeCondition: {
                                                              kind: "NamedType",
                                                              name: {
                                                                kind: "Name",
                                                                value:
                                                                  "ActivityRelativeCardInfo",
                                                              },
                                                            },
                                                            directives: [],
                                                            selectionSet: {
                                                              kind:
                                                                "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  alias: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "relativeCardInfoName",
                                                                  },
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "name",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "code",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "url",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind:
                                                              "InlineFragment",
                                                            typeCondition: {
                                                              kind: "NamedType",
                                                              name: {
                                                                kind: "Name",
                                                                value:
                                                                  "ActivityRelativeSupplierInfo",
                                                              },
                                                            },
                                                            directives: [],
                                                            selectionSet: {
                                                              kind:
                                                                "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  alias: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "relativeSupplierInfoName",
                                                                  },
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "name",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "email",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind:
                                                              "InlineFragment",
                                                            typeCondition: {
                                                              kind: "NamedType",
                                                              name: {
                                                                kind: "Name",
                                                                value:
                                                                  "ActivityRelativeItemInfo",
                                                              },
                                                            },
                                                            directives: [],
                                                            selectionSet: {
                                                              kind:
                                                                "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  alias: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "relativeItemInfoName",
                                                                  },
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "name",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "quantity",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "unit",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "emailInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "subject",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "message",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "comment",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "EMAIL_INBOUND_SUPPLIER",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "supplierInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "emailInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "subject",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "message",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "EMAIL_INBOUND_REQUESTER",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "emailInboundRequesterInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "EMAIL_OUTBOUND_MENTION",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "emailOutboundMentionInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "EMAIL_OUTBOUND_SUPPLIER",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "subject",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "message",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "rfxInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "type",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "code",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "orderInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "type",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "code",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "supplierInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "creator",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value: "REPLY",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "user",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "relativeInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "__typename",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind:
                                                              "InlineFragment",
                                                            typeCondition: {
                                                              kind: "NamedType",
                                                              name: {
                                                                kind: "Name",
                                                                value:
                                                                  "ActivityRelativeCardInfo",
                                                              },
                                                            },
                                                            directives: [],
                                                            selectionSet: {
                                                              kind:
                                                                "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  alias: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "relativeCardInfoName",
                                                                  },
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "name",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "code",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "url",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind:
                                                              "InlineFragment",
                                                            typeCondition: {
                                                              kind: "NamedType",
                                                              name: {
                                                                kind: "Name",
                                                                value:
                                                                  "ActivityRelativeSupplierInfo",
                                                              },
                                                            },
                                                            directives: [],
                                                            selectionSet: {
                                                              kind:
                                                                "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  alias: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "relativeSupplierInfoName",
                                                                  },
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "name",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "email",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind:
                                                              "InlineFragment",
                                                            typeCondition: {
                                                              kind: "NamedType",
                                                              name: {
                                                                kind: "Name",
                                                                value:
                                                                  "ActivityRelativeItemInfo",
                                                              },
                                                            },
                                                            directives: [],
                                                            selectionSet: {
                                                              kind:
                                                                "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  alias: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "relativeItemInfoName",
                                                                  },
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "name",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "quantity",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind:
                                                                      "Name",
                                                                    value:
                                                                      "unit",
                                                                  },
                                                                  arguments: [],
                                                                  directives: [],
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "comment",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value: "ADD_ITEMS",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "itemCount",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "creator",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "CREATE_PURCHASE_REQUEST",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "creator",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "CREATE_PURCHASE_REQUEST_PRODUCT",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "quantity",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unit",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "purchaseRequestCode",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "creator",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "CREATE_PURCHASE_REQUEST_SERVICE",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "purchaseRequestCode",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "creator",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value: "DRAG_ITEM",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "cardName",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "CREATE_APPROVAL_WORKFLOW",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "creatorInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "approvalWorkflowInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "code",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "requestName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "comment",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "approvalAmount",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "status",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "approvalWorkflowStats",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "totalRequestCount",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "approvedRequestCount",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "UPDATE_APPROVAL_WORKFLOW",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "creatorInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "approvalWorkflowInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "code",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "requestName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "comment",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "approvalAmount",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "status",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeCardInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "code",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "url",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "approvalWorkflowStats",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "totalRequestCount",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "approvedRequestCount",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "CREATE_APPROVAL_REQUEST",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "approverInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "approvalRequestInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "sequenceGroup",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "sequenceNumber",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "creatorComment",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "approverComment",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "dueDate",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "decisionDate",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "decision",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "approvalWorkflowInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "code",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "requestName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "approvalAmount",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "status",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "approvalWorkflowStats",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "totalRequestCount",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "approvedRequestCount",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "UPDATE_APPROVAL_REQUEST",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "approverInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "approvalRequestInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "sequenceGroup",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "sequenceNumber",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "creatorComment",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "approverComment",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "dueDate",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "decisionDate",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "decision",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "approvalWorkflowInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "code",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "requestName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "approvalAmount",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "status",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "approvalWorkflowStats",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "totalRequestCount",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "approvedRequestCount",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value: "CREATE_SUPPLIER",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value: "supplierName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "creator",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value: "UPDATE_SUPPLIER",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value: "supplierName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "creator",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "changedFields",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "fieldName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "oldValue",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "newValue",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "UPDATE_ORDER_RECEIVED_QUANTITIES",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "user",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "orderInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "code",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "type",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "CREATE_INVOICE_FROM_ORDER",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "__typename",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "user",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "firstName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "lastName",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "avatarUrl",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "orderInfo",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "code",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "type",
                                                            },
                                                            arguments: [],
                                                            directives: [],
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "author",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      organizatonId: // value for 'organizatonId'
 *      skipSeen: // value for 'skipSeen'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    NotificationsQuery,
    NotificationsQueryVariables
  >(NotificationsDocument, baseOptions);
}
export function useNotificationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    NotificationsQuery,
    NotificationsQueryVariables
  >(NotificationsDocument, baseOptions);
}
export type NotificationsQueryHookResult = ReturnType<
  typeof useNotificationsQuery
>;
export type NotificationsLazyQueryHookResult = ReturnType<
  typeof useNotificationsLazyQuery
>;
export type NotificationsQueryResult = ApolloReactCommon.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>;
export const ArchiveOrderDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ArchiveOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "archiveOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useArchiveOrderMutation__
 *
 * To run a mutation, you first call `useArchiveOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveOrderMutation, { data, loading, error }] = useArchiveOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useArchiveOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveOrderMutation,
    ArchiveOrderMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    ArchiveOrderMutation,
    ArchiveOrderMutationVariables
  >(ArchiveOrderDocument, baseOptions);
}
export type ArchiveOrderMutationHookResult = ReturnType<
  typeof useArchiveOrderMutation
>;
export type ArchiveOrderMutationResult = ApolloReactCommon.MutationResult<
  ArchiveOrderMutation
>;
export type ArchiveOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveOrderMutation,
  ArchiveOrderMutationVariables
>;
export const CreateOrderInvoiceFromOrderDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateOrderInvoiceFromOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createOrderInvoiceFromOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateOrderInvoiceFromOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderInvoiceFromOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderInvoiceFromOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderInvoiceFromOrderMutation, { data, loading, error }] = useCreateOrderInvoiceFromOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCreateOrderInvoiceFromOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrderInvoiceFromOrderMutation,
    CreateOrderInvoiceFromOrderMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateOrderInvoiceFromOrderMutation,
    CreateOrderInvoiceFromOrderMutationVariables
  >(CreateOrderInvoiceFromOrderDocument, baseOptions);
}
export type CreateOrderInvoiceFromOrderMutationHookResult = ReturnType<
  typeof useCreateOrderInvoiceFromOrderMutation
>;
export type CreateOrderInvoiceFromOrderMutationResult = ApolloReactCommon.MutationResult<
  CreateOrderInvoiceFromOrderMutation
>;
export type CreateOrderInvoiceFromOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrderInvoiceFromOrderMutation,
  CreateOrderInvoiceFromOrderMutationVariables
>;
export const CreateOrganizationAddressDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateOrganizationAddress" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "locationName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "recipientName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "address" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "city" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "areaCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CountryCodeEnum" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "addressType" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AddressTypeEnum" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createOrganizationAddress" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "locationName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "locationName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "recipientName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "recipientName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "address" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "city" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "city" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "areaCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "areaCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "countryCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "countryCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "addressType" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "addressType" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AddressInfo" },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddressInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "locationName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "recipientName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "city" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "areaCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countryCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "addressType" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "country" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateOrganizationAddressMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationAddressMutation, { data, loading, error }] = useCreateOrganizationAddressMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      locationName: // value for 'locationName'
 *      recipientName: // value for 'recipientName'
 *      address: // value for 'address'
 *      city: // value for 'city'
 *      areaCode: // value for 'areaCode'
 *      countryCode: // value for 'countryCode'
 *      addressType: // value for 'addressType'
 *   },
 * });
 */
export function useCreateOrganizationAddressMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrganizationAddressMutation,
    CreateOrganizationAddressMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateOrganizationAddressMutation,
    CreateOrganizationAddressMutationVariables
  >(CreateOrganizationAddressDocument, baseOptions);
}
export type CreateOrganizationAddressMutationHookResult = ReturnType<
  typeof useCreateOrganizationAddressMutation
>;
export type CreateOrganizationAddressMutationResult = ApolloReactCommon.MutationResult<
  CreateOrganizationAddressMutation
>;
export type CreateOrganizationAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrganizationAddressMutation,
  CreateOrganizationAddressMutationVariables
>;
export const OrderByCodeDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OrderByCode" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "withDeleted" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orderByCode" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "organizationId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "code" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "code" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "withDeleted" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "withDeleted" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingAddressId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceAddressId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "externalIdentifier" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasBeenSent" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "poReleaseMethod" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isConfirmationRequired" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "expectedConfirmationDate",
                        },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "purchaseOrderExpectedDate",
                        },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expectedDeliveryDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "purchaseOrderRenewalDate",
                        },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseOrderIssueDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseOrderCurrency" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paymentTerm" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "purchaseOrderDeliveryNotes",
                        },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "purchaseOrderBillingNotes",
                        },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "purchaseOrderSupplierNotes",
                        },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "purchaseConfirmationNotes",
                        },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "receivingIsConfirmationReceived",
                        },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivingGoodsReceived" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "receivingGoodsReceivedPartially",
                        },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "receivingIsAddedToStock",
                        },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivingIsPoCancelled" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivingWarehouseCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivingNotes" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceNumber" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "vatPercent" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceReceived" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceThreeWayMatch" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceApproved" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "invoicePaid" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceIsSentToBilling" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deletedDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "department" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingAddress" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "AddressInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceAddress" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "AddressInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assignee" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phoneNumber" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "roles" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attachments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "size" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardStatus" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "level" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "text" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phoneNumber" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "roles" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "withDeleted" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "withDeleted" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestId",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfxId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "suggestedSupplier",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "receivedQuantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalReceivedQuantity",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "expectedDeliveryDate",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "additionalInfo" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "productUrl" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unitPrice" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isDeleted" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assignee" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "withDeleted" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "withDeleted",
                                    },
                                  },
                                },
                              ],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryCode",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "phoneNumber",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "roles" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "level" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "text" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "customFields" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "esourcingFields" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attachments" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "filename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "size" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestor" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "parentCode" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "withDeleted" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "withDeleted",
                                    },
                                  },
                                },
                              ],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "swimlaneProgress" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "history" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "parentCode" },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: {
                                          kind: "Name",
                                          value: "withDeleted",
                                        },
                                        value: {
                                          kind: "BooleanValue",
                                          value: true,
                                        },
                                      },
                                    ],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "progress" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isDeleted" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "projects" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "organizationId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "costCentreId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "costCentre" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "organizationId",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creatorId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryCode",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "phoneNumber",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "roles" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestors" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phoneNumber" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "roles" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplier" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "organizationId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "defaultContactPerson",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paymentTerms" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "webAddress" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "city" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "areaCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "contactPersons" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "organizationId",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "phoneNumber",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "notes" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "activities" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ActivityFeedItem" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rfx" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "department" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "suppliers" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "defaultContactPerson",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emails" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "OutboundEmail",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "parentId",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "relativeId",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "relativeType",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "activityId",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "supplierId",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requesterId",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creatorId",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fromEmail",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ccEmail",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "toEmail",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subject",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "textBody",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "htmlBody",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "outboundData",
                                          },
                                          name: { kind: "Name", value: "data" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "sendStatus",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "sendRejectedReason",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "hasAttachments",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "InboundEmail",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "parentId",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "relativeId",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "relativeType",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "activityId",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "supplierId",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "supplierName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requesterId",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requesterName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fromEmail",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ccEmail",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "toEmail",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subject",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "textBody",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "htmlBody",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "inboundData",
                                          },
                                          name: { kind: "Name", value: "data" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "hasAttachments",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "spfResult",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dkimValid",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "securityData",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "hasBeenRead",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "users" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "UserInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suppliers" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paymentTerms" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "defaultContactPerson",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "addresses" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locationName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "recipientName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "city" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "areaCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "addressType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddressInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "locationName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "recipientName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "city" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "areaCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countryCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "addressType" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "country" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ActivityFeedItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ActivityFeed" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "userId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "parentId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "relativeId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "relativeType" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "type" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "attachments" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filename" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mimetype" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "url" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "data" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ASSIGN" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assignerInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "DRAG_CARD" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "COMMENT" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ITEM" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "addItemQuantity" },
                        name: { kind: "Name", value: "quantity" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "addItemUnit" },
                        name: { kind: "Name", value: "unit" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ATTACHMENT" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "encoding" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "size" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_MENTION" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_REQUESTER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "emailInboundRequesterInfo",
                        },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_OUTBOUND_MENTION" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "emailOutboundMentionInfo",
                        },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_OUTBOUND_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subject" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rfxInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_APPROVAL_WORKFLOW" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_APPROVAL_WORKFLOW" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeCardInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_APPROVAL_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approverInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalRequestInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dueDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_APPROVAL_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approverInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalRequestInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceGroup" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceNumber" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approverComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dueDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decisionDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decision" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "REPLY" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ITEMS" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemCount" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_PURCHASE_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unit" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequestCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "CREATE_PURCHASE_REQUEST_SERVICE",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequestCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "DRAG_ITEM" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardName" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "supplierName" },
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "supplierName" },
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "changedFields" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fieldName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "oldValue" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "newValue" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_INVOICE_FROM_ORDER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "replies" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeType" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "type" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attachments" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ASSIGN" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assignerInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_CARD" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "COMMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemQuantity" },
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemUnit" },
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ATTACHMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encoding" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "size" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_REQUESTER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailInboundRequesterInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailOutboundMentionInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfxInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "REPLY" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEMS" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemCount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_SERVICE",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_APPROVAL_WORKFLOW",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "CREATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "changedFields" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fieldName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "oldValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "newValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_INVOICE_FROM_ORDER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "author" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "replies" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatarUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "department" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "costCentre" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useOrderByCodeQuery__
 *
 * To run a query within a React component, call `useOrderByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderByCodeQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      code: // value for 'code'
 *      withDeleted: // value for 'withDeleted'
 *   },
 * });
 */
export function useOrderByCodeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrderByCodeQuery,
    OrderByCodeQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<OrderByCodeQuery, OrderByCodeQueryVariables>(
    OrderByCodeDocument,
    baseOptions,
  );
}
export function useOrderByCodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrderByCodeQuery,
    OrderByCodeQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    OrderByCodeQuery,
    OrderByCodeQueryVariables
  >(OrderByCodeDocument, baseOptions);
}
export type OrderByCodeQueryHookResult = ReturnType<typeof useOrderByCodeQuery>;
export type OrderByCodeLazyQueryHookResult = ReturnType<
  typeof useOrderByCodeLazyQuery
>;
export type OrderByCodeQueryResult = ApolloReactCommon.QueryResult<
  OrderByCodeQuery,
  OrderByCodeQueryVariables
>;
export const OrderModalInfoDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OrderModalInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "KanbanFilterInput" },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orders" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "filter" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useOrderModalInfoQuery__
 *
 * To run a query within a React component, call `useOrderModalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderModalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderModalInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrderModalInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrderModalInfoQuery,
    OrderModalInfoQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    OrderModalInfoQuery,
    OrderModalInfoQueryVariables
  >(OrderModalInfoDocument, baseOptions);
}
export function useOrderModalInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrderModalInfoQuery,
    OrderModalInfoQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    OrderModalInfoQuery,
    OrderModalInfoQueryVariables
  >(OrderModalInfoDocument, baseOptions);
}
export type OrderModalInfoQueryHookResult = ReturnType<
  typeof useOrderModalInfoQuery
>;
export type OrderModalInfoLazyQueryHookResult = ReturnType<
  typeof useOrderModalInfoLazyQuery
>;
export type OrderModalInfoQueryResult = ApolloReactCommon.QueryResult<
  OrderModalInfoQuery,
  OrderModalInfoQueryVariables
>;
export const UpdateOrderDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingAddressId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "invoiceAddressId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "externalIdentifier" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "hasBeenSent" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isConfirmationRequired" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "expectedConfirmationDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "expectedDeliveryDate" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseOrderRenewalDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseOrderIssueDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseOrderCurrency" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CurrencyEnum" },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "paymentTerm" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseOrderDeliveryNotes" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseOrderBillingNotes" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseOrderSupplierNotes" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "receivingIsConfirmationReceived" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "receivingGoodsReceived" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "receivingGoodsReceivedPartially" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "receivingIsAddedToStock" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "receivingIsPoCancelled" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "receivingWarehouseCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "receivingNotes" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "invoiceNumber" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "vatPercent" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "invoiceReceived" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "invoiceThreeWayMatch" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "invoiceApproved" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "invoicePaid" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "supplierId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "supplierId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "shippingAddressId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "shippingAddressId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "invoiceAddressId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "invoiceAddressId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "externalIdentifier" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "externalIdentifier" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hasBeenSent" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "hasBeenSent" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isConfirmationRequired" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isConfirmationRequired" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "expectedConfirmationDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "expectedConfirmationDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "expectedDeliveryDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "expectedDeliveryDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseOrderRenewalDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseOrderRenewalDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseOrderIssueDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseOrderIssueDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseOrderCurrency" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseOrderCurrency" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "paymentTerm" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "paymentTerm" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseOrderDeliveryNotes" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseOrderDeliveryNotes" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseOrderBillingNotes" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseOrderBillingNotes" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseOrderSupplierNotes" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseOrderSupplierNotes" },
                },
              },
              {
                kind: "Argument",
                name: {
                  kind: "Name",
                  value: "receivingIsConfirmationReceived",
                },
                value: {
                  kind: "Variable",
                  name: {
                    kind: "Name",
                    value: "receivingIsConfirmationReceived",
                  },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "receivingGoodsReceived" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "receivingGoodsReceived" },
                },
              },
              {
                kind: "Argument",
                name: {
                  kind: "Name",
                  value: "receivingGoodsReceivedPartially",
                },
                value: {
                  kind: "Variable",
                  name: {
                    kind: "Name",
                    value: "receivingGoodsReceivedPartially",
                  },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "receivingIsAddedToStock" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "receivingIsAddedToStock" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "receivingIsPoCancelled" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "receivingIsPoCancelled" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "receivingWarehouseCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "receivingWarehouseCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "receivingNotes" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "receivingNotes" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "invoiceNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "invoiceNumber" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "vatPercent" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "vatPercent" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "invoiceReceived" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "invoiceReceived" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "invoiceThreeWayMatch" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "invoiceThreeWayMatch" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "invoiceApproved" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "invoiceApproved" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "invoicePaid" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "invoicePaid" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      supplierId: // value for 'supplierId'
 *      shippingAddressId: // value for 'shippingAddressId'
 *      invoiceAddressId: // value for 'invoiceAddressId'
 *      externalIdentifier: // value for 'externalIdentifier'
 *      hasBeenSent: // value for 'hasBeenSent'
 *      name: // value for 'name'
 *      isConfirmationRequired: // value for 'isConfirmationRequired'
 *      expectedConfirmationDate: // value for 'expectedConfirmationDate'
 *      expectedDeliveryDate: // value for 'expectedDeliveryDate'
 *      purchaseOrderRenewalDate: // value for 'purchaseOrderRenewalDate'
 *      purchaseOrderIssueDate: // value for 'purchaseOrderIssueDate'
 *      purchaseOrderCurrency: // value for 'purchaseOrderCurrency'
 *      paymentTerm: // value for 'paymentTerm'
 *      purchaseOrderDeliveryNotes: // value for 'purchaseOrderDeliveryNotes'
 *      purchaseOrderBillingNotes: // value for 'purchaseOrderBillingNotes'
 *      purchaseOrderSupplierNotes: // value for 'purchaseOrderSupplierNotes'
 *      receivingIsConfirmationReceived: // value for 'receivingIsConfirmationReceived'
 *      receivingGoodsReceived: // value for 'receivingGoodsReceived'
 *      receivingGoodsReceivedPartially: // value for 'receivingGoodsReceivedPartially'
 *      receivingIsAddedToStock: // value for 'receivingIsAddedToStock'
 *      receivingIsPoCancelled: // value for 'receivingIsPoCancelled'
 *      receivingWarehouseCode: // value for 'receivingWarehouseCode'
 *      receivingNotes: // value for 'receivingNotes'
 *      invoiceNumber: // value for 'invoiceNumber'
 *      vatPercent: // value for 'vatPercent'
 *      invoiceReceived: // value for 'invoiceReceived'
 *      invoiceThreeWayMatch: // value for 'invoiceThreeWayMatch'
 *      invoiceApproved: // value for 'invoiceApproved'
 *      invoicePaid: // value for 'invoicePaid'
 *   },
 * });
 */
export function useUpdateOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrderMutation,
    UpdateOrderMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateOrderMutation,
    UpdateOrderMutationVariables
  >(UpdateOrderDocument, baseOptions);
}
export type UpdateOrderMutationHookResult = ReturnType<
  typeof useUpdateOrderMutation
>;
export type UpdateOrderMutationResult = ApolloReactCommon.MutationResult<
  UpdateOrderMutation
>;
export type UpdateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateOrderMutation,
  UpdateOrderMutationVariables
>;
export const UpdateOrderHasBeenSentDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateOrderHasBeenSent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "hasBeenSent" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateOrderHasBeenSent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hasBeenSent" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "hasBeenSent" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hasBeenSent" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateOrderHasBeenSentMutation__
 *
 * To run a mutation, you first call `useUpdateOrderHasBeenSentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderHasBeenSentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderHasBeenSentMutation, { data, loading, error }] = useUpdateOrderHasBeenSentMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      hasBeenSent: // value for 'hasBeenSent'
 *   },
 * });
 */
export function useUpdateOrderHasBeenSentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrderHasBeenSentMutation,
    UpdateOrderHasBeenSentMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateOrderHasBeenSentMutation,
    UpdateOrderHasBeenSentMutationVariables
  >(UpdateOrderHasBeenSentDocument, baseOptions);
}
export type UpdateOrderHasBeenSentMutationHookResult = ReturnType<
  typeof useUpdateOrderHasBeenSentMutation
>;
export type UpdateOrderHasBeenSentMutationResult = ApolloReactCommon.MutationResult<
  UpdateOrderHasBeenSentMutation
>;
export type UpdateOrderHasBeenSentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateOrderHasBeenSentMutation,
  UpdateOrderHasBeenSentMutationVariables
>;
export const UpdateOrderReleaseMethodDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateOrderReleaseMethod" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "poReleaseMethod" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PurchaseOrderReleaseMethodEnum" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateOrderReleaseMethod" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "poReleaseMethod" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "poReleaseMethod" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "poReleaseMethod" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateOrderReleaseMethodMutation__
 *
 * To run a mutation, you first call `useUpdateOrderReleaseMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderReleaseMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderReleaseMethodMutation, { data, loading, error }] = useUpdateOrderReleaseMethodMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      poReleaseMethod: // value for 'poReleaseMethod'
 *   },
 * });
 */
export function useUpdateOrderReleaseMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrderReleaseMethodMutation,
    UpdateOrderReleaseMethodMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateOrderReleaseMethodMutation,
    UpdateOrderReleaseMethodMutationVariables
  >(UpdateOrderReleaseMethodDocument, baseOptions);
}
export type UpdateOrderReleaseMethodMutationHookResult = ReturnType<
  typeof useUpdateOrderReleaseMethodMutation
>;
export type UpdateOrderReleaseMethodMutationResult = ApolloReactCommon.MutationResult<
  UpdateOrderReleaseMethodMutation
>;
export type UpdateOrderReleaseMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateOrderReleaseMethodMutation,
  UpdateOrderReleaseMethodMutationVariables
>;
export const UpdateOrderStatusDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateOrderStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "OrderStatusEnum" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateOrderStatus" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "status" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "status" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "status" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderStatusMutation, { data, loading, error }] = useUpdateOrderStatusMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateOrderStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrderStatusMutation,
    UpdateOrderStatusMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateOrderStatusMutation,
    UpdateOrderStatusMutationVariables
  >(UpdateOrderStatusDocument, baseOptions);
}
export type UpdateOrderStatusMutationHookResult = ReturnType<
  typeof useUpdateOrderStatusMutation
>;
export type UpdateOrderStatusMutationResult = ApolloReactCommon.MutationResult<
  UpdateOrderStatusMutation
>;
export type UpdateOrderStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateOrderStatusMutation,
  UpdateOrderStatusMutationVariables
>;
export const UpdatePurchaseRequestItemReceivedQuantitesDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "UpdatePurchaseRequestItemReceivedQuantites",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "areReceivingGoodsReceived" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestReceivedItems" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: {
                  kind: "Name",
                  value: "PurchaseRequestItemReceivedInput",
                },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updatePurchaseRequestItemReceivedQuantities",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "areReceivingGoodsReceived" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "areReceivingGoodsReceived" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestReceivedItems" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestReceivedItems" },
                },
              },
            ],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdatePurchaseRequestItemReceivedQuantitesMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseRequestItemReceivedQuantitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseRequestItemReceivedQuantitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseRequestItemReceivedQuantitesMutation, { data, loading, error }] = useUpdatePurchaseRequestItemReceivedQuantitesMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      orderId: // value for 'orderId'
 *      areReceivingGoodsReceived: // value for 'areReceivingGoodsReceived'
 *      purchaseRequestReceivedItems: // value for 'purchaseRequestReceivedItems'
 *   },
 * });
 */
export function useUpdatePurchaseRequestItemReceivedQuantitesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePurchaseRequestItemReceivedQuantitesMutation,
    UpdatePurchaseRequestItemReceivedQuantitesMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdatePurchaseRequestItemReceivedQuantitesMutation,
    UpdatePurchaseRequestItemReceivedQuantitesMutationVariables
  >(UpdatePurchaseRequestItemReceivedQuantitesDocument, baseOptions);
}
export type UpdatePurchaseRequestItemReceivedQuantitesMutationHookResult = ReturnType<
  typeof useUpdatePurchaseRequestItemReceivedQuantitesMutation
>;
export type UpdatePurchaseRequestItemReceivedQuantitesMutationResult = ApolloReactCommon.MutationResult<
  UpdatePurchaseRequestItemReceivedQuantitesMutation
>;
export type UpdatePurchaseRequestItemReceivedQuantitesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePurchaseRequestItemReceivedQuantitesMutation,
  UpdatePurchaseRequestItemReceivedQuantitesMutationVariables
>;
export const AddRolesToUserDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddRolesToUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "roles" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "UserRoleEnum" },
                },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addRolesToUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "roles" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "roles" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useAddRolesToUserMutation__
 *
 * To run a mutation, you first call `useAddRolesToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRolesToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRolesToUserMutation, { data, loading, error }] = useAddRolesToUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useAddRolesToUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddRolesToUserMutation,
    AddRolesToUserMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AddRolesToUserMutation,
    AddRolesToUserMutationVariables
  >(AddRolesToUserDocument, baseOptions);
}
export type AddRolesToUserMutationHookResult = ReturnType<
  typeof useAddRolesToUserMutation
>;
export type AddRolesToUserMutationResult = ApolloReactCommon.MutationResult<
  AddRolesToUserMutation
>;
export type AddRolesToUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddRolesToUserMutation,
  AddRolesToUserMutationVariables
>;
export const ArchivePurchaseRequestDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ArchivePurchaseRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "archivePurchaseRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useArchivePurchaseRequestMutation__
 *
 * To run a mutation, you first call `useArchivePurchaseRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchivePurchaseRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archivePurchaseRequestMutation, { data, loading, error }] = useArchivePurchaseRequestMutation({
 *   variables: {
 *      purchaseRequestId: // value for 'purchaseRequestId'
 *   },
 * });
 */
export function useArchivePurchaseRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchivePurchaseRequestMutation,
    ArchivePurchaseRequestMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    ArchivePurchaseRequestMutation,
    ArchivePurchaseRequestMutationVariables
  >(ArchivePurchaseRequestDocument, baseOptions);
}
export type ArchivePurchaseRequestMutationHookResult = ReturnType<
  typeof useArchivePurchaseRequestMutation
>;
export type ArchivePurchaseRequestMutationResult = ApolloReactCommon.MutationResult<
  ArchivePurchaseRequestMutation
>;
export type ArchivePurchaseRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchivePurchaseRequestMutation,
  ArchivePurchaseRequestMutationVariables
>;
export const CompletePurchaseRequestDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CompletePurchaseRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "completePurchaseRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCompletePurchaseRequestMutation__
 *
 * To run a mutation, you first call `useCompletePurchaseRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePurchaseRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePurchaseRequestMutation, { data, loading, error }] = useCompletePurchaseRequestMutation({
 *   variables: {
 *      purchaseRequestId: // value for 'purchaseRequestId'
 *   },
 * });
 */
export function useCompletePurchaseRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CompletePurchaseRequestMutation,
    CompletePurchaseRequestMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CompletePurchaseRequestMutation,
    CompletePurchaseRequestMutationVariables
  >(CompletePurchaseRequestDocument, baseOptions);
}
export type CompletePurchaseRequestMutationHookResult = ReturnType<
  typeof useCompletePurchaseRequestMutation
>;
export type CompletePurchaseRequestMutationResult = ApolloReactCommon.MutationResult<
  CompletePurchaseRequestMutation
>;
export type CompletePurchaseRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CompletePurchaseRequestMutation,
  CompletePurchaseRequestMutationVariables
>;
export const CreateApprovalRequestDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateApprovalRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "approvalWorkflowId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "approverId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "comment" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sequenceNumber" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dueDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createApprovalRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "approvalWorkflowId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "approvalWorkflowId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "approverId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "approverId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "comment" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "comment" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sequenceNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sequenceNumber" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dueDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dueDate" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateApprovalRequestMutation__
 *
 * To run a mutation, you first call `useCreateApprovalRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApprovalRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApprovalRequestMutation, { data, loading, error }] = useCreateApprovalRequestMutation({
 *   variables: {
 *      approvalWorkflowId: // value for 'approvalWorkflowId'
 *      organizationId: // value for 'organizationId'
 *      approverId: // value for 'approverId'
 *      comment: // value for 'comment'
 *      sequenceNumber: // value for 'sequenceNumber'
 *      dueDate: // value for 'dueDate'
 *   },
 * });
 */
export function useCreateApprovalRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateApprovalRequestMutation,
    CreateApprovalRequestMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateApprovalRequestMutation,
    CreateApprovalRequestMutationVariables
  >(CreateApprovalRequestDocument, baseOptions);
}
export type CreateApprovalRequestMutationHookResult = ReturnType<
  typeof useCreateApprovalRequestMutation
>;
export type CreateApprovalRequestMutationResult = ApolloReactCommon.MutationResult<
  CreateApprovalRequestMutation
>;
export type CreateApprovalRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateApprovalRequestMutation,
  CreateApprovalRequestMutationVariables
>;
export const CreateApprovalWorkflowDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateApprovalWorkflow" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createApprovalWorkflow" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateApprovalWorkflowMutation__
 *
 * To run a mutation, you first call `useCreateApprovalWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApprovalWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApprovalWorkflowMutation, { data, loading, error }] = useCreateApprovalWorkflowMutation({
 *   variables: {
 *      purchaseRequestId: // value for 'purchaseRequestId'
 *   },
 * });
 */
export function useCreateApprovalWorkflowMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateApprovalWorkflowMutation,
    CreateApprovalWorkflowMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateApprovalWorkflowMutation,
    CreateApprovalWorkflowMutationVariables
  >(CreateApprovalWorkflowDocument, baseOptions);
}
export type CreateApprovalWorkflowMutationHookResult = ReturnType<
  typeof useCreateApprovalWorkflowMutation
>;
export type CreateApprovalWorkflowMutationResult = ApolloReactCommon.MutationResult<
  CreateApprovalWorkflowMutation
>;
export type CreateApprovalWorkflowMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateApprovalWorkflowMutation,
  CreateApprovalWorkflowMutationVariables
>;
export const CreateApproverDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateApprover" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firstName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lastName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createApprover" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "firstName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "firstName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "lastName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "lastName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserInfo" },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatarUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "department" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "costCentre" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateApproverMutation__
 *
 * To run a mutation, you first call `useCreateApproverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApproverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApproverMutation, { data, loading, error }] = useCreateApproverMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateApproverMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateApproverMutation,
    CreateApproverMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateApproverMutation,
    CreateApproverMutationVariables
  >(CreateApproverDocument, baseOptions);
}
export type CreateApproverMutationHookResult = ReturnType<
  typeof useCreateApproverMutation
>;
export type CreateApproverMutationResult = ApolloReactCommon.MutationResult<
  CreateApproverMutation
>;
export type CreateApproverMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateApproverMutation,
  CreateApproverMutationVariables
>;
export const CreateCostCentreDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateCostCentre" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createCostCentre" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateCostCentreMutation__
 *
 * To run a mutation, you first call `useCreateCostCentreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostCentreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostCentreMutation, { data, loading, error }] = useCreateCostCentreMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCostCentreMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCostCentreMutation,
    CreateCostCentreMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateCostCentreMutation,
    CreateCostCentreMutationVariables
  >(CreateCostCentreDocument, baseOptions);
}
export type CreateCostCentreMutationHookResult = ReturnType<
  typeof useCreateCostCentreMutation
>;
export type CreateCostCentreMutationResult = ApolloReactCommon.MutationResult<
  CreateCostCentreMutation
>;
export type CreateCostCentreMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCostCentreMutation,
  CreateCostCentreMutationVariables
>;
export const CreateDepartmentDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateDepartment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingAddressId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "invoiceAddressId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "domain" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createDepartment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "shippingAddressId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "shippingAddressId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "invoiceAddressId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "invoiceAddressId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "code" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "code" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "domain" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "domain" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "domain" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateDepartmentMutation__
 *
 * To run a mutation, you first call `useCreateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDepartmentMutation, { data, loading, error }] = useCreateDepartmentMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      shippingAddressId: // value for 'shippingAddressId'
 *      invoiceAddressId: // value for 'invoiceAddressId'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useCreateDepartmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateDepartmentMutation,
    CreateDepartmentMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateDepartmentMutation,
    CreateDepartmentMutationVariables
  >(CreateDepartmentDocument, baseOptions);
}
export type CreateDepartmentMutationHookResult = ReturnType<
  typeof useCreateDepartmentMutation
>;
export type CreateDepartmentMutationResult = ApolloReactCommon.MutationResult<
  CreateDepartmentMutation
>;
export type CreateDepartmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateDepartmentMutation,
  CreateDepartmentMutationVariables
>;
export const CreateProjectDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createProject" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >(CreateProjectDocument, baseOptions);
}
export type CreateProjectMutationHookResult = ReturnType<
  typeof useCreateProjectMutation
>;
export type CreateProjectMutationResult = ApolloReactCommon.MutationResult<
  CreateProjectMutation
>;
export type CreateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const CreatePurchaseRequestAttachmentsDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreatePurchaseRequestAttachments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachments" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "Upload" },
                },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createPurchaseRequestAttachments" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachments" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachments" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreatePurchaseRequestAttachmentsMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseRequestAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseRequestAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseRequestAttachmentsMutation, { data, loading, error }] = useCreatePurchaseRequestAttachmentsMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      purchaseRequestId: // value for 'purchaseRequestId'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useCreatePurchaseRequestAttachmentsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePurchaseRequestAttachmentsMutation,
    CreatePurchaseRequestAttachmentsMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreatePurchaseRequestAttachmentsMutation,
    CreatePurchaseRequestAttachmentsMutationVariables
  >(CreatePurchaseRequestAttachmentsDocument, baseOptions);
}
export type CreatePurchaseRequestAttachmentsMutationHookResult = ReturnType<
  typeof useCreatePurchaseRequestAttachmentsMutation
>;
export type CreatePurchaseRequestAttachmentsMutationResult = ApolloReactCommon.MutationResult<
  CreatePurchaseRequestAttachmentsMutation
>;
export type CreatePurchaseRequestAttachmentsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePurchaseRequestAttachmentsMutation,
  CreatePurchaseRequestAttachmentsMutationVariables
>;
export const CreatePurchaseRequestItemAttachmentsDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreatePurchaseRequestItemAttachments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestItemId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachments" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "Upload" },
                },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "createPurchaseRequestItemAttachments",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestItemId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestItemId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachments" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachments" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreatePurchaseRequestItemAttachmentsMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseRequestItemAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseRequestItemAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseRequestItemAttachmentsMutation, { data, loading, error }] = useCreatePurchaseRequestItemAttachmentsMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      purchaseRequestItemId: // value for 'purchaseRequestItemId'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useCreatePurchaseRequestItemAttachmentsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePurchaseRequestItemAttachmentsMutation,
    CreatePurchaseRequestItemAttachmentsMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreatePurchaseRequestItemAttachmentsMutation,
    CreatePurchaseRequestItemAttachmentsMutationVariables
  >(CreatePurchaseRequestItemAttachmentsDocument, baseOptions);
}
export type CreatePurchaseRequestItemAttachmentsMutationHookResult = ReturnType<
  typeof useCreatePurchaseRequestItemAttachmentsMutation
>;
export type CreatePurchaseRequestItemAttachmentsMutationResult = ApolloReactCommon.MutationResult<
  CreatePurchaseRequestItemAttachmentsMutation
>;
export type CreatePurchaseRequestItemAttachmentsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePurchaseRequestItemAttachmentsMutation,
  CreatePurchaseRequestItemAttachmentsMutationVariables
>;
export const CreateRequestorDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateRequestor" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firstName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lastName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createRequestor" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "firstName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "firstName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "lastName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "lastName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserInfo" },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatarUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "department" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "costCentre" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateRequestorMutation__
 *
 * To run a mutation, you first call `useCreateRequestorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestorMutation, { data, loading, error }] = useCreateRequestorMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateRequestorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateRequestorMutation,
    CreateRequestorMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateRequestorMutation,
    CreateRequestorMutationVariables
  >(CreateRequestorDocument, baseOptions);
}
export type CreateRequestorMutationHookResult = ReturnType<
  typeof useCreateRequestorMutation
>;
export type CreateRequestorMutationResult = ApolloReactCommon.MutationResult<
  CreateRequestorMutation
>;
export type CreateRequestorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateRequestorMutation,
  CreateRequestorMutationVariables
>;
export const DeleteApprovalRequestDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteApprovalRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "approvalRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteApprovalRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "approvalRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "approvalRequestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useDeleteApprovalRequestMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalRequestMutation, { data, loading, error }] = useDeleteApprovalRequestMutation({
 *   variables: {
 *      approvalRequestId: // value for 'approvalRequestId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteApprovalRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteApprovalRequestMutation,
    DeleteApprovalRequestMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteApprovalRequestMutation,
    DeleteApprovalRequestMutationVariables
  >(DeleteApprovalRequestDocument, baseOptions);
}
export type DeleteApprovalRequestMutationHookResult = ReturnType<
  typeof useDeleteApprovalRequestMutation
>;
export type DeleteApprovalRequestMutationResult = ApolloReactCommon.MutationResult<
  DeleteApprovalRequestMutation
>;
export type DeleteApprovalRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteApprovalRequestMutation,
  DeleteApprovalRequestMutationVariables
>;
export const DeletePurchaseRequestItemDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeletePurchaseRequestItem" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestItemId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deletePurchaseRequestItem" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestItemId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestItemId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useDeletePurchaseRequestItemMutation__
 *
 * To run a mutation, you first call `useDeletePurchaseRequestItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePurchaseRequestItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePurchaseRequestItemMutation, { data, loading, error }] = useDeletePurchaseRequestItemMutation({
 *   variables: {
 *      purchaseRequestItemId: // value for 'purchaseRequestItemId'
 *   },
 * });
 */
export function useDeletePurchaseRequestItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePurchaseRequestItemMutation,
    DeletePurchaseRequestItemMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeletePurchaseRequestItemMutation,
    DeletePurchaseRequestItemMutationVariables
  >(DeletePurchaseRequestItemDocument, baseOptions);
}
export type DeletePurchaseRequestItemMutationHookResult = ReturnType<
  typeof useDeletePurchaseRequestItemMutation
>;
export type DeletePurchaseRequestItemMutationResult = ApolloReactCommon.MutationResult<
  DeletePurchaseRequestItemMutation
>;
export type DeletePurchaseRequestItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePurchaseRequestItemMutation,
  DeletePurchaseRequestItemMutationVariables
>;
export const PurchaseRequestByCodeDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PurchaseRequestByCode" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "purchaseRequestByCode" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "organizationId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "code" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "code" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "departmentId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "department" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "DepartmentInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "projectId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "project" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ProjectInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "costCentreId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "costCentre" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestorId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "notes" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "vat" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isVatIncluded" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isRfxCreated" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestor" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "UserInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assignee" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "UserInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attachments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encoding" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "size" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "notes" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryCode",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "phoneNumber",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "roles" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardStatus" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "level" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "text" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestId",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "receivedQuantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalReceivedQuantity",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "expectedDeliveryDate",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "productUrl" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "additionalInfo" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unitPrice" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isDeleted" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "organizationId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "originId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "progress" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfxId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assignee" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryCode",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "phoneNumber",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "roles" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "suggestedSupplier",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "level" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "text" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "customFields" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "esourcingFields" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attachments" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "filename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestor" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "parentCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "swimlaneProgress" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "externalIdentifier",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "history" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "parentCode" },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: {
                                          kind: "Name",
                                          value: "withDeleted",
                                        },
                                        value: {
                                          kind: "BooleanValue",
                                          value: true,
                                        },
                                      },
                                    ],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "progress" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isDeleted" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "activities" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ActivityFeedItem" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "latestApprovalWorkflow" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "keepRequesterUpdated",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "singleApprovalRequired",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalRequests" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creatorComment",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approverComment",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "decision" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "decisionDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dueDate" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sequenceNumber",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sequenceGroup",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "approver" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emails" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "OutboundEmail" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "parentId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "relativeId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeType",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "activityId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "supplierId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requesterId",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creatorId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ccEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "textBody" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "htmlBody" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "sendStatus" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sendRejectedReason",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "outboundData",
                                    },
                                    name: { kind: "Name", value: "data" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "hasAttachments",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "InboundEmail" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "parentId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "relativeId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeType",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "activityId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "supplierId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "supplierName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requesterId",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requesterName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ccEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "textBody" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "htmlBody" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "inboundData",
                                    },
                                    name: { kind: "Name", value: "data" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "hasAttachments",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "hasBeenRead",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "spfResult" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dkimValid" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "securityData",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "baseCurrency" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "urlName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "users" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "UserInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suppliers" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "defaultContactPerson",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "departments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "costCentres" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "projects" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantityUnits" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "addressBook" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DepartmentInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Department" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "code" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProjectInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Project" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatarUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "department" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "costCentre" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ActivityFeedItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ActivityFeed" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "userId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "parentId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "relativeId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "relativeType" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "type" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "attachments" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filename" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mimetype" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "url" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "data" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ASSIGN" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assignerInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "DRAG_CARD" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "COMMENT" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ITEM" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "addItemQuantity" },
                        name: { kind: "Name", value: "quantity" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "addItemUnit" },
                        name: { kind: "Name", value: "unit" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ATTACHMENT" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "encoding" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "size" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_MENTION" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_REQUESTER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "emailInboundRequesterInfo",
                        },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_OUTBOUND_MENTION" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "emailOutboundMentionInfo",
                        },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_OUTBOUND_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subject" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rfxInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_APPROVAL_WORKFLOW" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_APPROVAL_WORKFLOW" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeCardInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_APPROVAL_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approverInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalRequestInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dueDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_APPROVAL_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approverInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalRequestInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceGroup" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceNumber" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approverComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dueDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decisionDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decision" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "REPLY" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ITEMS" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemCount" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_PURCHASE_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unit" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequestCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "CREATE_PURCHASE_REQUEST_SERVICE",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequestCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "DRAG_ITEM" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardName" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "supplierName" },
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "supplierName" },
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "changedFields" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fieldName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "oldValue" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "newValue" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_INVOICE_FROM_ORDER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "replies" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeType" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "type" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attachments" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ASSIGN" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assignerInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_CARD" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "COMMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemQuantity" },
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemUnit" },
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ATTACHMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encoding" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "size" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_REQUESTER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailInboundRequesterInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailOutboundMentionInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfxInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "REPLY" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEMS" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemCount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_SERVICE",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_APPROVAL_WORKFLOW",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "CREATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "changedFields" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fieldName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "oldValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "newValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_INVOICE_FROM_ORDER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "author" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "replies" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __usePurchaseRequestByCodeQuery__
 *
 * To run a query within a React component, call `usePurchaseRequestByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseRequestByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseRequestByCodeQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      code: // value for 'code'
 *   },
 * });
 */
export function usePurchaseRequestByCodeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PurchaseRequestByCodeQuery,
    PurchaseRequestByCodeQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    PurchaseRequestByCodeQuery,
    PurchaseRequestByCodeQueryVariables
  >(PurchaseRequestByCodeDocument, baseOptions);
}
export function usePurchaseRequestByCodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PurchaseRequestByCodeQuery,
    PurchaseRequestByCodeQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    PurchaseRequestByCodeQuery,
    PurchaseRequestByCodeQueryVariables
  >(PurchaseRequestByCodeDocument, baseOptions);
}
export type PurchaseRequestByCodeQueryHookResult = ReturnType<
  typeof usePurchaseRequestByCodeQuery
>;
export type PurchaseRequestByCodeLazyQueryHookResult = ReturnType<
  typeof usePurchaseRequestByCodeLazyQuery
>;
export type PurchaseRequestByCodeQueryResult = ApolloReactCommon.QueryResult<
  PurchaseRequestByCodeQuery,
  PurchaseRequestByCodeQueryVariables
>;
export const RemovePurchaseRequestAttachmentDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RemovePurchaseRequestAttachment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachmentId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removePurchaseRequestAttachment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachmentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachmentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useRemovePurchaseRequestAttachmentMutation__
 *
 * To run a mutation, you first call `useRemovePurchaseRequestAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePurchaseRequestAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePurchaseRequestAttachmentMutation, { data, loading, error }] = useRemovePurchaseRequestAttachmentMutation({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *      purchaseRequestId: // value for 'purchaseRequestId'
 *   },
 * });
 */
export function useRemovePurchaseRequestAttachmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemovePurchaseRequestAttachmentMutation,
    RemovePurchaseRequestAttachmentMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    RemovePurchaseRequestAttachmentMutation,
    RemovePurchaseRequestAttachmentMutationVariables
  >(RemovePurchaseRequestAttachmentDocument, baseOptions);
}
export type RemovePurchaseRequestAttachmentMutationHookResult = ReturnType<
  typeof useRemovePurchaseRequestAttachmentMutation
>;
export type RemovePurchaseRequestAttachmentMutationResult = ApolloReactCommon.MutationResult<
  RemovePurchaseRequestAttachmentMutation
>;
export type RemovePurchaseRequestAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemovePurchaseRequestAttachmentMutation,
  RemovePurchaseRequestAttachmentMutationVariables
>;
export const UpdateApprovalWorkflowDataDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateApprovalWorkflowData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "approvalWorkflowId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "requestName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "comment" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "keepRequesterUpdated" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "singleApprovalRequired" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "approvalAmount" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateApprovalWorkflowData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "approvalWorkflowId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "approvalWorkflowId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "requestName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "requestName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "comment" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "comment" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "keepRequesterUpdated" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "keepRequesterUpdated" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "singleApprovalRequired" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "singleApprovalRequired" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "approvalAmount" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "approvalAmount" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateApprovalWorkflowDataMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalWorkflowDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalWorkflowDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalWorkflowDataMutation, { data, loading, error }] = useUpdateApprovalWorkflowDataMutation({
 *   variables: {
 *      approvalWorkflowId: // value for 'approvalWorkflowId'
 *      requestName: // value for 'requestName'
 *      comment: // value for 'comment'
 *      keepRequesterUpdated: // value for 'keepRequesterUpdated'
 *      singleApprovalRequired: // value for 'singleApprovalRequired'
 *      approvalAmount: // value for 'approvalAmount'
 *   },
 * });
 */
export function useUpdateApprovalWorkflowDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateApprovalWorkflowDataMutation,
    UpdateApprovalWorkflowDataMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateApprovalWorkflowDataMutation,
    UpdateApprovalWorkflowDataMutationVariables
  >(UpdateApprovalWorkflowDataDocument, baseOptions);
}
export type UpdateApprovalWorkflowDataMutationHookResult = ReturnType<
  typeof useUpdateApprovalWorkflowDataMutation
>;
export type UpdateApprovalWorkflowDataMutationResult = ApolloReactCommon.MutationResult<
  UpdateApprovalWorkflowDataMutation
>;
export type UpdateApprovalWorkflowDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateApprovalWorkflowDataMutation,
  UpdateApprovalWorkflowDataMutationVariables
>;
export const UpdateApprovalWorkflowStatusDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateApprovalWorkflowStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "approvalWorkflowId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ApprovalWorkflowStatusEnum" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cancellingComment" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateApprovalWorkflowStatus" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "approvalWorkflowId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "approvalWorkflowId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "status" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "status" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "cancellingComment" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cancellingComment" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateApprovalWorkflowStatusMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalWorkflowStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalWorkflowStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalWorkflowStatusMutation, { data, loading, error }] = useUpdateApprovalWorkflowStatusMutation({
 *   variables: {
 *      approvalWorkflowId: // value for 'approvalWorkflowId'
 *      status: // value for 'status'
 *      cancellingComment: // value for 'cancellingComment'
 *   },
 * });
 */
export function useUpdateApprovalWorkflowStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateApprovalWorkflowStatusMutation,
    UpdateApprovalWorkflowStatusMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateApprovalWorkflowStatusMutation,
    UpdateApprovalWorkflowStatusMutationVariables
  >(UpdateApprovalWorkflowStatusDocument, baseOptions);
}
export type UpdateApprovalWorkflowStatusMutationHookResult = ReturnType<
  typeof useUpdateApprovalWorkflowStatusMutation
>;
export type UpdateApprovalWorkflowStatusMutationResult = ApolloReactCommon.MutationResult<
  UpdateApprovalWorkflowStatusMutation
>;
export type UpdateApprovalWorkflowStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateApprovalWorkflowStatusMutation,
  UpdateApprovalWorkflowStatusMutationVariables
>;
export const UpdatePurchaseRequestDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdatePurchaseRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "departmentId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "costCentreId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "requestorId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "assigneeId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "notes" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PurchaseRequestStatusEnum" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updatePurchaseRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "departmentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "departmentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "projectId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "costCentreId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "costCentreId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "requestorId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "requestorId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "assigneeId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "assigneeId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "notes" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "notes" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "status" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "status" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdatePurchaseRequestMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseRequestMutation, { data, loading, error }] = useUpdatePurchaseRequestMutation({
 *   variables: {
 *      purchaseRequestId: // value for 'purchaseRequestId'
 *      departmentId: // value for 'departmentId'
 *      projectId: // value for 'projectId'
 *      costCentreId: // value for 'costCentreId'
 *      requestorId: // value for 'requestorId'
 *      assigneeId: // value for 'assigneeId'
 *      name: // value for 'name'
 *      notes: // value for 'notes'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdatePurchaseRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePurchaseRequestMutation,
    UpdatePurchaseRequestMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdatePurchaseRequestMutation,
    UpdatePurchaseRequestMutationVariables
  >(UpdatePurchaseRequestDocument, baseOptions);
}
export type UpdatePurchaseRequestMutationHookResult = ReturnType<
  typeof useUpdatePurchaseRequestMutation
>;
export type UpdatePurchaseRequestMutationResult = ApolloReactCommon.MutationResult<
  UpdatePurchaseRequestMutation
>;
export type UpdatePurchaseRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePurchaseRequestMutation,
  UpdatePurchaseRequestMutationVariables
>;
export const UpdatePurchaseRequestStatusDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdatePurchaseRequestStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PurchaseRequestStatusEnum" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updatePurchaseRequestStatus" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "status" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "status" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "status" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdatePurchaseRequestStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseRequestStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseRequestStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseRequestStatusMutation, { data, loading, error }] = useUpdatePurchaseRequestStatusMutation({
 *   variables: {
 *      purchaseRequestId: // value for 'purchaseRequestId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdatePurchaseRequestStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePurchaseRequestStatusMutation,
    UpdatePurchaseRequestStatusMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdatePurchaseRequestStatusMutation,
    UpdatePurchaseRequestStatusMutationVariables
  >(UpdatePurchaseRequestStatusDocument, baseOptions);
}
export type UpdatePurchaseRequestStatusMutationHookResult = ReturnType<
  typeof useUpdatePurchaseRequestStatusMutation
>;
export type UpdatePurchaseRequestStatusMutationResult = ApolloReactCommon.MutationResult<
  UpdatePurchaseRequestStatusMutation
>;
export type UpdatePurchaseRequestStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePurchaseRequestStatusMutation,
  UpdatePurchaseRequestStatusMutationVariables
>;
export const CreatePurchaseRequestFromPortalDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreatePurchaseRequestFromPortal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PurchaseRequestStatusEnum" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "requestorId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "departmentId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "costCentreId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "notes" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "Upload" },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "items" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "PurchaseRequestItemInput" },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createPurchaseRequestFromPortal" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "status" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "status" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "requestorId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "requestorId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "departmentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "departmentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "projectId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "costCentreId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "costCentreId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "notes" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "notes" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachments" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachments" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "items" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "items" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "assigneeId" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreatePurchaseRequestFromPortalMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseRequestFromPortalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseRequestFromPortalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseRequestFromPortalMutation, { data, loading, error }] = useCreatePurchaseRequestFromPortalMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      status: // value for 'status'
 *      name: // value for 'name'
 *      requestorId: // value for 'requestorId'
 *      departmentId: // value for 'departmentId'
 *      projectId: // value for 'projectId'
 *      costCentreId: // value for 'costCentreId'
 *      notes: // value for 'notes'
 *      attachments: // value for 'attachments'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useCreatePurchaseRequestFromPortalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePurchaseRequestFromPortalMutation,
    CreatePurchaseRequestFromPortalMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreatePurchaseRequestFromPortalMutation,
    CreatePurchaseRequestFromPortalMutationVariables
  >(CreatePurchaseRequestFromPortalDocument, baseOptions);
}
export type CreatePurchaseRequestFromPortalMutationHookResult = ReturnType<
  typeof useCreatePurchaseRequestFromPortalMutation
>;
export type CreatePurchaseRequestFromPortalMutationResult = ApolloReactCommon.MutationResult<
  CreatePurchaseRequestFromPortalMutation
>;
export type CreatePurchaseRequestFromPortalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePurchaseRequestFromPortalMutation,
  CreatePurchaseRequestFromPortalMutationVariables
>;
export const PurchaseRequestsDetailsByCodeDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PurchaseRequestsDetailsByCode" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "purchaseRequestByCode" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "organizationId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "code" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "code" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "departmentId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "projectId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "costCentreId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "costCentre" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestorId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "notes" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "vat" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isVatIncluded" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isRfxCreated" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestor" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "UserInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assignee" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "UserInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attachments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "department" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "DepartmentInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "project" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ProjectInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardStatus" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "level" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "text" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestId",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "receivedQuantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalReceivedQuantity",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "expectedDeliveryDate",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "productUrl" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "parentCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isDeleted" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "organizationId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "originId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "additionalInfo" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "progress" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfxId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unitPrice" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assignee" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryCode",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "phoneNumber",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "roles" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "suggestedSupplier",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "level" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "text" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "customFields" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "esourcingFields" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attachments" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "filename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestor" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "swimlaneProgress" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "externalIdentifier",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "history" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "parentCode" },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: {
                                          kind: "Name",
                                          value: "withDeleted",
                                        },
                                        value: {
                                          kind: "BooleanValue",
                                          value: true,
                                        },
                                      },
                                    ],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "progress" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isDeleted" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "activities" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ActivityFeedItem" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "latestApprovalWorkflow" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalRequests" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creatorComment",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approverComment",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "decision" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "decisionDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dueDate" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sequenceNumber",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sequenceGroup",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "approver" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emails" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "OutboundEmail" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "parentId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "relativeId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeType",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "activityId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "supplierId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requesterId",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creatorId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ccEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "textBody" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "htmlBody" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "sendStatus" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sendRejectedReason",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "outboundData",
                                    },
                                    name: { kind: "Name", value: "data" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "hasAttachments",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "InboundEmail" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "parentId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "relativeId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeType",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "activityId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "supplierId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "supplierName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requesterId",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requesterName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ccEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "textBody" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "htmlBody" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "inboundData",
                                    },
                                    name: { kind: "Name", value: "data" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "hasAttachments",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "hasBeenRead",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "spfResult" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dkimValid" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "securityData",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "baseCurrency" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "users" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "UserInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suppliers" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "defaultContactPerson",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "departments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "costCentres" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "projects" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatarUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "department" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "costCentre" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DepartmentInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Department" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "code" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProjectInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Project" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ActivityFeedItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ActivityFeed" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "userId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "parentId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "relativeId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "relativeType" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "type" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "attachments" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filename" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mimetype" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "url" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "data" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ASSIGN" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assignerInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "DRAG_CARD" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "COMMENT" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ITEM" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "addItemQuantity" },
                        name: { kind: "Name", value: "quantity" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "addItemUnit" },
                        name: { kind: "Name", value: "unit" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ATTACHMENT" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "encoding" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "size" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_MENTION" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_REQUESTER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "emailInboundRequesterInfo",
                        },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_OUTBOUND_MENTION" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "emailOutboundMentionInfo",
                        },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_OUTBOUND_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subject" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rfxInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_APPROVAL_WORKFLOW" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_APPROVAL_WORKFLOW" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeCardInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_APPROVAL_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approverInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalRequestInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dueDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_APPROVAL_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approverInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalRequestInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceGroup" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceNumber" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approverComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dueDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decisionDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decision" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "REPLY" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ITEMS" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemCount" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_PURCHASE_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unit" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequestCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "CREATE_PURCHASE_REQUEST_SERVICE",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequestCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "DRAG_ITEM" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardName" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "supplierName" },
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "supplierName" },
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "changedFields" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fieldName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "oldValue" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "newValue" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_INVOICE_FROM_ORDER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "replies" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeType" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "type" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attachments" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ASSIGN" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assignerInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_CARD" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "COMMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemQuantity" },
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemUnit" },
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ATTACHMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encoding" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "size" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_REQUESTER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailInboundRequesterInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailOutboundMentionInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfxInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "REPLY" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEMS" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemCount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_SERVICE",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_APPROVAL_WORKFLOW",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "CREATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "changedFields" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fieldName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "oldValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "newValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_INVOICE_FROM_ORDER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "author" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "replies" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __usePurchaseRequestsDetailsByCodeQuery__
 *
 * To run a query within a React component, call `usePurchaseRequestsDetailsByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseRequestsDetailsByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseRequestsDetailsByCodeQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      code: // value for 'code'
 *   },
 * });
 */
export function usePurchaseRequestsDetailsByCodeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PurchaseRequestsDetailsByCodeQuery,
    PurchaseRequestsDetailsByCodeQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    PurchaseRequestsDetailsByCodeQuery,
    PurchaseRequestsDetailsByCodeQueryVariables
  >(PurchaseRequestsDetailsByCodeDocument, baseOptions);
}
export function usePurchaseRequestsDetailsByCodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PurchaseRequestsDetailsByCodeQuery,
    PurchaseRequestsDetailsByCodeQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    PurchaseRequestsDetailsByCodeQuery,
    PurchaseRequestsDetailsByCodeQueryVariables
  >(PurchaseRequestsDetailsByCodeDocument, baseOptions);
}
export type PurchaseRequestsDetailsByCodeQueryHookResult = ReturnType<
  typeof usePurchaseRequestsDetailsByCodeQuery
>;
export type PurchaseRequestsDetailsByCodeLazyQueryHookResult = ReturnType<
  typeof usePurchaseRequestsDetailsByCodeLazyQuery
>;
export type PurchaseRequestsDetailsByCodeQueryResult = ApolloReactCommon.QueryResult<
  PurchaseRequestsDetailsByCodeQuery,
  PurchaseRequestsDetailsByCodeQueryVariables
>;
export const RequesterPortalViewDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "RequesterPortalView" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "approvalWorkflowStatuses" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ApprovalWorkflowStatusEnum" },
                },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "KanbanFilterInput" },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "roles" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "department" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "project" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "costCentre" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "approvalWorkflowsByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "approvalWorkflowStatuses" },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "approvalWorkflowStatuses",
                        },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "baseCurrency" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "urlName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companyName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "city" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "areaCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companyLogoUrl" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "prEmailAddress" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isActive" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "domainWhitelist" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modules" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "vatIdentification" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "businessRegistry" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "leiCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dunsCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "embeddedReport" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "poStatusWebhookUrl" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "users" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "UserInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suppliers" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "defaultContactPerson",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "departments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "costCentres" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "projects" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantityUnits" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequests" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "filter" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardStatus" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "level" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "text" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestor" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "UserInfo" },
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assignee" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "UserInfo" },
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "project" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "ProjectInfo",
                                    },
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "suppliers" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "SupplierInfo",
                                    },
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "department" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "DepartmentInfo",
                                    },
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "items" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "PurchaseRequestItemInfo",
                                    },
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "level",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "text" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "latestApprovalWorkflow",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isRfxCreated" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isOrderCreated" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatarUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "department" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "costCentre" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProjectInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Project" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SupplierInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Supplier" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentTerms" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "defaultContactPerson" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DepartmentInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Department" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "code" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PurchaseRequestItemInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PurchaseRequestItem" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "parentCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "type" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "quantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "receivedQuantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "totalReceivedQuantity" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "unit" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "assignee" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedDeliveryDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "productUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "unitPrice" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currency" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __useRequesterPortalViewQuery__
 *
 * To run a query within a React component, call `useRequesterPortalViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequesterPortalViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequesterPortalViewQuery({
 *   variables: {
 *      approvalWorkflowStatuses: // value for 'approvalWorkflowStatuses'
 *      organizationId: // value for 'organizationId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRequesterPortalViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RequesterPortalViewQuery,
    RequesterPortalViewQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    RequesterPortalViewQuery,
    RequesterPortalViewQueryVariables
  >(RequesterPortalViewDocument, baseOptions);
}
export function useRequesterPortalViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RequesterPortalViewQuery,
    RequesterPortalViewQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    RequesterPortalViewQuery,
    RequesterPortalViewQueryVariables
  >(RequesterPortalViewDocument, baseOptions);
}
export type RequesterPortalViewQueryHookResult = ReturnType<
  typeof useRequesterPortalViewQuery
>;
export type RequesterPortalViewLazyQueryHookResult = ReturnType<
  typeof useRequesterPortalViewLazyQuery
>;
export type RequesterPortalViewQueryResult = ApolloReactCommon.QueryResult<
  RequesterPortalViewQuery,
  RequesterPortalViewQueryVariables
>;
export const UpdatePurchaseRequestFromPortalDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdatePurchaseRequestFromPortal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PurchaseRequestStatusEnum" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "requestorId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "departmentId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "costCentreId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "notes" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "Upload" },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "items" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "PurchaseRequestItemInput" },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updatePurchaseRequestFromPortal" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "status" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "status" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "requestorId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "requestorId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "departmentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "departmentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "projectId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "costCentreId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "costCentreId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "notes" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "notes" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachments" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachments" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "items" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "items" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "assigneeId" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdatePurchaseRequestFromPortalMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseRequestFromPortalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseRequestFromPortalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseRequestFromPortalMutation, { data, loading, error }] = useUpdatePurchaseRequestFromPortalMutation({
 *   variables: {
 *      status: // value for 'status'
 *      purchaseRequestId: // value for 'purchaseRequestId'
 *      name: // value for 'name'
 *      requestorId: // value for 'requestorId'
 *      departmentId: // value for 'departmentId'
 *      projectId: // value for 'projectId'
 *      costCentreId: // value for 'costCentreId'
 *      notes: // value for 'notes'
 *      attachments: // value for 'attachments'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useUpdatePurchaseRequestFromPortalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePurchaseRequestFromPortalMutation,
    UpdatePurchaseRequestFromPortalMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdatePurchaseRequestFromPortalMutation,
    UpdatePurchaseRequestFromPortalMutationVariables
  >(UpdatePurchaseRequestFromPortalDocument, baseOptions);
}
export type UpdatePurchaseRequestFromPortalMutationHookResult = ReturnType<
  typeof useUpdatePurchaseRequestFromPortalMutation
>;
export type UpdatePurchaseRequestFromPortalMutationResult = ApolloReactCommon.MutationResult<
  UpdatePurchaseRequestFromPortalMutation
>;
export type UpdatePurchaseRequestFromPortalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePurchaseRequestFromPortalMutation,
  UpdatePurchaseRequestFromPortalMutationVariables
>;
export const AddSupplierResolutionDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddSupplierResolution" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rfxId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "resolution" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "RfxSupplierResolutionEnum" },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addSupplierResolution" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "rfxId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rfxId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "supplierId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "supplierId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "resolution" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "resolution" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useAddSupplierResolutionMutation__
 *
 * To run a mutation, you first call `useAddSupplierResolutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplierResolutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplierResolutionMutation, { data, loading, error }] = useAddSupplierResolutionMutation({
 *   variables: {
 *      rfxId: // value for 'rfxId'
 *      supplierId: // value for 'supplierId'
 *      resolution: // value for 'resolution'
 *   },
 * });
 */
export function useAddSupplierResolutionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddSupplierResolutionMutation,
    AddSupplierResolutionMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AddSupplierResolutionMutation,
    AddSupplierResolutionMutationVariables
  >(AddSupplierResolutionDocument, baseOptions);
}
export type AddSupplierResolutionMutationHookResult = ReturnType<
  typeof useAddSupplierResolutionMutation
>;
export type AddSupplierResolutionMutationResult = ApolloReactCommon.MutationResult<
  AddSupplierResolutionMutation
>;
export type AddSupplierResolutionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddSupplierResolutionMutation,
  AddSupplierResolutionMutationVariables
>;
export const AddSupplierToRfxDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddSupplierToRfx" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rfxId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addSupplierToRfx" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "rfxId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rfxId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "supplierId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "supplierId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useAddSupplierToRfxMutation__
 *
 * To run a mutation, you first call `useAddSupplierToRfxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplierToRfxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplierToRfxMutation, { data, loading, error }] = useAddSupplierToRfxMutation({
 *   variables: {
 *      rfxId: // value for 'rfxId'
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useAddSupplierToRfxMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddSupplierToRfxMutation,
    AddSupplierToRfxMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AddSupplierToRfxMutation,
    AddSupplierToRfxMutationVariables
  >(AddSupplierToRfxDocument, baseOptions);
}
export type AddSupplierToRfxMutationHookResult = ReturnType<
  typeof useAddSupplierToRfxMutation
>;
export type AddSupplierToRfxMutationResult = ApolloReactCommon.MutationResult<
  AddSupplierToRfxMutation
>;
export type AddSupplierToRfxMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddSupplierToRfxMutation,
  AddSupplierToRfxMutationVariables
>;
export const ArchiveRfxDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ArchiveRfx" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rfxId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "archiveRfx" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "rfxId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rfxId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useArchiveRfxMutation__
 *
 * To run a mutation, you first call `useArchiveRfxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveRfxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveRfxMutation, { data, loading, error }] = useArchiveRfxMutation({
 *   variables: {
 *      rfxId: // value for 'rfxId'
 *   },
 * });
 */
export function useArchiveRfxMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveRfxMutation,
    ArchiveRfxMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    ArchiveRfxMutation,
    ArchiveRfxMutationVariables
  >(ArchiveRfxDocument, baseOptions);
}
export type ArchiveRfxMutationHookResult = ReturnType<
  typeof useArchiveRfxMutation
>;
export type ArchiveRfxMutationResult = ApolloReactCommon.MutationResult<
  ArchiveRfxMutation
>;
export type ArchiveRfxMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveRfxMutation,
  ArchiveRfxMutationVariables
>;
export const CompleteSourcingEventDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CompleteSourcingEvent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourcingEventId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "completeSourcingEvent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "sourcingEventId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sourcingEventId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCompleteSourcingEventMutation__
 *
 * To run a mutation, you first call `useCompleteSourcingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSourcingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSourcingEventMutation, { data, loading, error }] = useCompleteSourcingEventMutation({
 *   variables: {
 *      sourcingEventId: // value for 'sourcingEventId'
 *   },
 * });
 */
export function useCompleteSourcingEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CompleteSourcingEventMutation,
    CompleteSourcingEventMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CompleteSourcingEventMutation,
    CompleteSourcingEventMutationVariables
  >(CompleteSourcingEventDocument, baseOptions);
}
export type CompleteSourcingEventMutationHookResult = ReturnType<
  typeof useCompleteSourcingEventMutation
>;
export type CompleteSourcingEventMutationResult = ApolloReactCommon.MutationResult<
  CompleteSourcingEventMutation
>;
export type CompleteSourcingEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CompleteSourcingEventMutation,
  CompleteSourcingEventMutationVariables
>;
export const CreateCommentDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ActivityTypeEnum" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "comment" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "Upload" },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "mentions" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "MentionInput" },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "parentId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "relativeId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "relativeType" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ActivityRelativeTypeEnum" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createComment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "type" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "comment" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "comment" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachments" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachments" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "mentions" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "mentions" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "parentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "parentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "relativeId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "relativeId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "relativeType" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "relativeType" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      type: // value for 'type'
 *      comment: // value for 'comment'
 *      attachments: // value for 'attachments'
 *      mentions: // value for 'mentions'
 *      organizationId: // value for 'organizationId'
 *      parentId: // value for 'parentId'
 *      relativeId: // value for 'relativeId'
 *      relativeType: // value for 'relativeType'
 *   },
 * });
 */
export function useCreateCommentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >(CreateCommentDocument, baseOptions);
}
export type CreateCommentMutationHookResult = ReturnType<
  typeof useCreateCommentMutation
>;
export type CreateCommentMutationResult = ApolloReactCommon.MutationResult<
  CreateCommentMutation
>;
export type CreateCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCommentMutation,
  CreateCommentMutationVariables
>;
export const CreateSupplierDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateSupplier" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createSupplier" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "defaultContactPerson" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateSupplierMutation__
 *
 * To run a mutation, you first call `useCreateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierMutation, { data, loading, error }] = useCreateSupplierMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateSupplierMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSupplierMutation,
    CreateSupplierMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateSupplierMutation,
    CreateSupplierMutationVariables
  >(CreateSupplierDocument, baseOptions);
}
export type CreateSupplierMutationHookResult = ReturnType<
  typeof useCreateSupplierMutation
>;
export type CreateSupplierMutationResult = ApolloReactCommon.MutationResult<
  CreateSupplierMutation
>;
export type CreateSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSupplierMutation,
  CreateSupplierMutationVariables
>;
export const MarkInboundEmailReadStatusDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "MarkInboundEmailReadStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "inboundEmailId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "hasBeenRead" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "markInboundEmailReadStatus" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "inboundEmailId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "inboundEmailId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hasBeenRead" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "hasBeenRead" },
                },
              },
            ],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __useMarkInboundEmailReadStatusMutation__
 *
 * To run a mutation, you first call `useMarkInboundEmailReadStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkInboundEmailReadStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markInboundEmailReadStatusMutation, { data, loading, error }] = useMarkInboundEmailReadStatusMutation({
 *   variables: {
 *      inboundEmailId: // value for 'inboundEmailId'
 *      hasBeenRead: // value for 'hasBeenRead'
 *   },
 * });
 */
export function useMarkInboundEmailReadStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkInboundEmailReadStatusMutation,
    MarkInboundEmailReadStatusMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    MarkInboundEmailReadStatusMutation,
    MarkInboundEmailReadStatusMutationVariables
  >(MarkInboundEmailReadStatusDocument, baseOptions);
}
export type MarkInboundEmailReadStatusMutationHookResult = ReturnType<
  typeof useMarkInboundEmailReadStatusMutation
>;
export type MarkInboundEmailReadStatusMutationResult = ApolloReactCommon.MutationResult<
  MarkInboundEmailReadStatusMutation
>;
export type MarkInboundEmailReadStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MarkInboundEmailReadStatusMutation,
  MarkInboundEmailReadStatusMutationVariables
>;
export const RemovePurchaseRequestItemDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RemovePurchaseRequestItem" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "purchaseRequestItemId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removePurchaseRequestItem" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "purchaseRequestItemId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "purchaseRequestItemId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useRemovePurchaseRequestItemMutation__
 *
 * To run a mutation, you first call `useRemovePurchaseRequestItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePurchaseRequestItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePurchaseRequestItemMutation, { data, loading, error }] = useRemovePurchaseRequestItemMutation({
 *   variables: {
 *      purchaseRequestItemId: // value for 'purchaseRequestItemId'
 *   },
 * });
 */
export function useRemovePurchaseRequestItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemovePurchaseRequestItemMutation,
    RemovePurchaseRequestItemMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    RemovePurchaseRequestItemMutation,
    RemovePurchaseRequestItemMutationVariables
  >(RemovePurchaseRequestItemDocument, baseOptions);
}
export type RemovePurchaseRequestItemMutationHookResult = ReturnType<
  typeof useRemovePurchaseRequestItemMutation
>;
export type RemovePurchaseRequestItemMutationResult = ApolloReactCommon.MutationResult<
  RemovePurchaseRequestItemMutation
>;
export type RemovePurchaseRequestItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemovePurchaseRequestItemMutation,
  RemovePurchaseRequestItemMutationVariables
>;
export const RemoveSupplierFromRfxDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RemoveSupplierFromRfx" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rfxId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removeSupplierFromRfx" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "rfxId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rfxId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "supplierId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "supplierId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useRemoveSupplierFromRfxMutation__
 *
 * To run a mutation, you first call `useRemoveSupplierFromRfxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSupplierFromRfxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSupplierFromRfxMutation, { data, loading, error }] = useRemoveSupplierFromRfxMutation({
 *   variables: {
 *      rfxId: // value for 'rfxId'
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useRemoveSupplierFromRfxMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveSupplierFromRfxMutation,
    RemoveSupplierFromRfxMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    RemoveSupplierFromRfxMutation,
    RemoveSupplierFromRfxMutationVariables
  >(RemoveSupplierFromRfxDocument, baseOptions);
}
export type RemoveSupplierFromRfxMutationHookResult = ReturnType<
  typeof useRemoveSupplierFromRfxMutation
>;
export type RemoveSupplierFromRfxMutationResult = ApolloReactCommon.MutationResult<
  RemoveSupplierFromRfxMutation
>;
export type RemoveSupplierFromRfxMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveSupplierFromRfxMutation,
  RemoveSupplierFromRfxMutationVariables
>;
export const RfxByCodeDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "RfxByCode" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "withDeleted" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rfxByCode" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "organizationId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "code" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "code" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "withDeleted" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "withDeleted" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isRfiComplete" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isRfpComplete" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isRfqComplete" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expectedResponseDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deletedDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nextStep" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "department" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assignee" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "UserInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attachments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "size" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emails" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "OutboundEmail" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "parentId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "relativeId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeType",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "activityId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "supplierId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requesterId",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creatorId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ccEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "textBody" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "htmlBody" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "sendStatus" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sendRejectedReason",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "outboundData",
                                    },
                                    name: { kind: "Name", value: "data" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "hasAttachments",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "InboundEmail" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "parentId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "relativeId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeType",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "activityId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "supplierId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "supplierName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requesterId",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requesterName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ccEmail" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "textBody" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "htmlBody" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "inboundData",
                                    },
                                    name: { kind: "Name", value: "data" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "hasAttachments",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "hasBeenRead",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "spfResult" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dkimValid" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "securityData",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierResolutions" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfxId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "resolution" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "deletedDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "withDeleted" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "withDeleted" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestId",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "suggestedSupplier",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "receivedQuantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalReceivedQuantity",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "expectedDeliveryDate",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "additionalInfo" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "productUrl" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unitPrice" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isDeleted" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assignee" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "level" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "text" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "customFields" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "esourcingFields" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attachments" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "filename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "size" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestor" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "parentCode" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "withDeleted" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "withDeleted",
                                    },
                                  },
                                },
                              ],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "swimlaneProgress" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "history" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "parentCode" },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: {
                                          kind: "Name",
                                          value: "withDeleted",
                                        },
                                        value: {
                                          kind: "Variable",
                                          name: {
                                            kind: "Name",
                                            value: "withDeleted",
                                          },
                                        },
                                      },
                                    ],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "progress" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isDeleted" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sourcingEvent" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "withDeleted" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "withDeleted" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "additionalInfo" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "questionnaire" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfxId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "responseDeadline" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sentDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "linkToken" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attachments" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "filename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "size" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sourcingRequests" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "withDeleted" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "withDeleted",
                                    },
                                  },
                                },
                              ],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "supplierId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quotation" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "supplier" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "attachments",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "filename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "mimetype",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suppliers" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "defaultContactPerson",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "activeSuppliers" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "defaultContactPerson",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardStatus" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "level" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "text" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "activities" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ActivityFeedItem" },
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companyName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "users" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "UserInfo" },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suppliers" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "defaultContactPerson",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatarUrl" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "department" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "costCentre" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ActivityFeedItem" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ActivityFeed" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "userId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "parentId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "relativeId" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "relativeType" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "type" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "attachments" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filename" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mimetype" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "url" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "data" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ASSIGN" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assignerInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "DRAG_CARD" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assigneeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "COMMENT" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ITEM" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "addItemQuantity" },
                        name: { kind: "Name", value: "quantity" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "addItemUnit" },
                        name: { kind: "Name", value: "unit" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ATTACHMENT" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "encoding" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "size" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_MENTION" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_INBOUND_REQUESTER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "emailInboundRequesterInfo",
                        },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_OUTBOUND_MENTION" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "emailOutboundMentionInfo",
                        },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "EMAIL_OUTBOUND_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subject" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rfxInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_APPROVAL_WORKFLOW" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_APPROVAL_WORKFLOW" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creatorInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeCardInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_APPROVAL_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approverInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalRequestInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dueDate" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_APPROVAL_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approverInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalRequestInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceGroup" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sequenceNumber" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approverComment" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dueDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decisionDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "decision" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approvalAmount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "approvalWorkflowStats" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvedRequestCount",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "REPLY" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeCardInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeSupplierInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeSupplierInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeItemInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "relativeItemInfoName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "ADD_ITEMS" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemCount" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_PURCHASE_REQUEST" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unit" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequestCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "CREATE_PURCHASE_REQUEST_SERVICE",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "purchaseRequestCode" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "DRAG_ITEM" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardName" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "supplierName" },
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "supplierName" },
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creator" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "changedFields" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fieldName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "oldValue" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "newValue" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: {
                      kind: "Name",
                      value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                    },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "CREATE_INVOICE_FROM_ORDER" },
                  },
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatarUrl" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "replies" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeType" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "type" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attachments" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ASSIGN" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assignerInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_CARD" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "COMMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemQuantity" },
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemUnit" },
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ATTACHMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encoding" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "size" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_REQUESTER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailInboundRequesterInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailOutboundMentionInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfxInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "REPLY" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEMS" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemCount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_SERVICE",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_APPROVAL_WORKFLOW",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "CREATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "changedFields" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fieldName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "oldValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "newValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_INVOICE_FROM_ORDER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "author" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "replies" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useRfxByCodeQuery__
 *
 * To run a query within a React component, call `useRfxByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRfxByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRfxByCodeQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      code: // value for 'code'
 *      withDeleted: // value for 'withDeleted'
 *   },
 * });
 */
export function useRfxByCodeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RfxByCodeQuery,
    RfxByCodeQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<RfxByCodeQuery, RfxByCodeQueryVariables>(
    RfxByCodeDocument,
    baseOptions,
  );
}
export function useRfxByCodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RfxByCodeQuery,
    RfxByCodeQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<RfxByCodeQuery, RfxByCodeQueryVariables>(
    RfxByCodeDocument,
    baseOptions,
  );
}
export type RfxByCodeQueryHookResult = ReturnType<typeof useRfxByCodeQuery>;
export type RfxByCodeLazyQueryHookResult = ReturnType<
  typeof useRfxByCodeLazyQuery
>;
export type RfxByCodeQueryResult = ApolloReactCommon.QueryResult<
  RfxByCodeQuery,
  RfxByCodeQueryVariables
>;
export const RfxModalInfoDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "RfxModalInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "KanbanFilterInput" },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rfx" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "filter" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useRfxModalInfoQuery__
 *
 * To run a query within a React component, call `useRfxModalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRfxModalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRfxModalInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRfxModalInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RfxModalInfoQuery,
    RfxModalInfoQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    RfxModalInfoQuery,
    RfxModalInfoQueryVariables
  >(RfxModalInfoDocument, baseOptions);
}
export function useRfxModalInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RfxModalInfoQuery,
    RfxModalInfoQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    RfxModalInfoQuery,
    RfxModalInfoQueryVariables
  >(RfxModalInfoDocument, baseOptions);
}
export type RfxModalInfoQueryHookResult = ReturnType<
  typeof useRfxModalInfoQuery
>;
export type RfxModalInfoLazyQueryHookResult = ReturnType<
  typeof useRfxModalInfoLazyQuery
>;
export type RfxModalInfoQueryResult = ApolloReactCommon.QueryResult<
  RfxModalInfoQuery,
  RfxModalInfoQueryVariables
>;
export const SupplierByContactPersonDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SupplierByContactPerson" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contactPersonId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supplierByContactPerson" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "contactPersonId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "contactPersonId" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "organizationId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useSupplierByContactPersonQuery__
 *
 * To run a query within a React component, call `useSupplierByContactPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierByContactPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierByContactPersonQuery({
 *   variables: {
 *      contactPersonId: // value for 'contactPersonId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSupplierByContactPersonQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SupplierByContactPersonQuery,
    SupplierByContactPersonQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    SupplierByContactPersonQuery,
    SupplierByContactPersonQueryVariables
  >(SupplierByContactPersonDocument, baseOptions);
}
export function useSupplierByContactPersonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SupplierByContactPersonQuery,
    SupplierByContactPersonQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    SupplierByContactPersonQuery,
    SupplierByContactPersonQueryVariables
  >(SupplierByContactPersonDocument, baseOptions);
}
export type SupplierByContactPersonQueryHookResult = ReturnType<
  typeof useSupplierByContactPersonQuery
>;
export type SupplierByContactPersonLazyQueryHookResult = ReturnType<
  typeof useSupplierByContactPersonLazyQuery
>;
export type SupplierByContactPersonQueryResult = ApolloReactCommon.QueryResult<
  SupplierByContactPersonQuery,
  SupplierByContactPersonQueryVariables
>;
export const SupplierFullTextSearchDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SupplierFullTextSearch" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchInput" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rfxId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "supplierFullTextSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "searchInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "searchInput" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "rfxId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rfxId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "status" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "type" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "isUsed" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useSupplierFullTextSearchMutation__
 *
 * To run a mutation, you first call `useSupplierFullTextSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupplierFullTextSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supplierFullTextSearchMutation, { data, loading, error }] = useSupplierFullTextSearchMutation({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      organizationId: // value for 'organizationId'
 *      rfxId: // value for 'rfxId'
 *   },
 * });
 */
export function useSupplierFullTextSearchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SupplierFullTextSearchMutation,
    SupplierFullTextSearchMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SupplierFullTextSearchMutation,
    SupplierFullTextSearchMutationVariables
  >(SupplierFullTextSearchDocument, baseOptions);
}
export type SupplierFullTextSearchMutationHookResult = ReturnType<
  typeof useSupplierFullTextSearchMutation
>;
export type SupplierFullTextSearchMutationResult = ApolloReactCommon.MutationResult<
  SupplierFullTextSearchMutation
>;
export type SupplierFullTextSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SupplierFullTextSearchMutation,
  SupplierFullTextSearchMutationVariables
>;
export const SuppliersByCategoryDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SuppliersByCategory" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categoryId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "suppliersByCategoryId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "categoryId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryId" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "organizationId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useSuppliersByCategoryQuery__
 *
 * To run a query within a React component, call `useSuppliersByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersByCategoryQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSuppliersByCategoryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SuppliersByCategoryQuery,
    SuppliersByCategoryQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    SuppliersByCategoryQuery,
    SuppliersByCategoryQueryVariables
  >(SuppliersByCategoryDocument, baseOptions);
}
export function useSuppliersByCategoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SuppliersByCategoryQuery,
    SuppliersByCategoryQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    SuppliersByCategoryQuery,
    SuppliersByCategoryQueryVariables
  >(SuppliersByCategoryDocument, baseOptions);
}
export type SuppliersByCategoryQueryHookResult = ReturnType<
  typeof useSuppliersByCategoryQuery
>;
export type SuppliersByCategoryLazyQueryHookResult = ReturnType<
  typeof useSuppliersByCategoryLazyQuery
>;
export type SuppliersByCategoryQueryResult = ApolloReactCommon.QueryResult<
  SuppliersByCategoryQuery,
  SuppliersByCategoryQueryVariables
>;
export const UpdateRfxDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateRfx" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rfxId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "RfxTypeEnum" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isRfiComplete" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isRfpComplete" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isRfqComplete" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateRfx" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "rfxId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rfxId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "type" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isRfiComplete" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isRfiComplete" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isRfpComplete" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isRfpComplete" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isRfqComplete" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isRfqComplete" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateRfxMutation__
 *
 * To run a mutation, you first call `useUpdateRfxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRfxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRfxMutation, { data, loading, error }] = useUpdateRfxMutation({
 *   variables: {
 *      rfxId: // value for 'rfxId'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      isRfiComplete: // value for 'isRfiComplete'
 *      isRfpComplete: // value for 'isRfpComplete'
 *      isRfqComplete: // value for 'isRfqComplete'
 *   },
 * });
 */
export function useUpdateRfxMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateRfxMutation,
    UpdateRfxMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateRfxMutation,
    UpdateRfxMutationVariables
  >(UpdateRfxDocument, baseOptions);
}
export type UpdateRfxMutationHookResult = ReturnType<
  typeof useUpdateRfxMutation
>;
export type UpdateRfxMutationResult = ApolloReactCommon.MutationResult<
  UpdateRfxMutation
>;
export type UpdateRfxMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateRfxMutation,
  UpdateRfxMutationVariables
>;
export const UpdateRfxNameDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateRfxName" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rfxId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateRfxName" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "rfxId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rfxId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateRfxNameMutation__
 *
 * To run a mutation, you first call `useUpdateRfxNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRfxNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRfxNameMutation, { data, loading, error }] = useUpdateRfxNameMutation({
 *   variables: {
 *      rfxId: // value for 'rfxId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateRfxNameMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateRfxNameMutation,
    UpdateRfxNameMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateRfxNameMutation,
    UpdateRfxNameMutationVariables
  >(UpdateRfxNameDocument, baseOptions);
}
export type UpdateRfxNameMutationHookResult = ReturnType<
  typeof useUpdateRfxNameMutation
>;
export type UpdateRfxNameMutationResult = ApolloReactCommon.MutationResult<
  UpdateRfxNameMutation
>;
export type UpdateRfxNameMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateRfxNameMutation,
  UpdateRfxNameMutationVariables
>;
export const UpdateRfxTypeDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateRfxType" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rfxId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "RfxTypeEnum" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateRfxType" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "rfxId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rfxId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "type" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "type" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateRfxTypeMutation__
 *
 * To run a mutation, you first call `useUpdateRfxTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRfxTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRfxTypeMutation, { data, loading, error }] = useUpdateRfxTypeMutation({
 *   variables: {
 *      rfxId: // value for 'rfxId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateRfxTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateRfxTypeMutation,
    UpdateRfxTypeMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateRfxTypeMutation,
    UpdateRfxTypeMutationVariables
  >(UpdateRfxTypeDocument, baseOptions);
}
export type UpdateRfxTypeMutationHookResult = ReturnType<
  typeof useUpdateRfxTypeMutation
>;
export type UpdateRfxTypeMutationResult = ApolloReactCommon.MutationResult<
  UpdateRfxTypeMutation
>;
export type UpdateRfxTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateRfxTypeMutation,
  UpdateRfxTypeMutationVariables
>;
export const UpdateSourcingEventItemWinnersDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateSourcingEventItemWinners" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourcingEventId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rfxId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "markRfxAsComplete" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateSourcingEventItemWinners" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "sourcingEventId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sourcingEventId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "rfxId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rfxId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "supplierId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "supplierId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "itemIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "itemIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "markRfxAsComplete" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "markRfxAsComplete" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateSourcingEventItemWinnersMutation__
 *
 * To run a mutation, you first call `useUpdateSourcingEventItemWinnersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSourcingEventItemWinnersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSourcingEventItemWinnersMutation, { data, loading, error }] = useUpdateSourcingEventItemWinnersMutation({
 *   variables: {
 *      sourcingEventId: // value for 'sourcingEventId'
 *      rfxId: // value for 'rfxId'
 *      supplierId: // value for 'supplierId'
 *      itemIds: // value for 'itemIds'
 *      markRfxAsComplete: // value for 'markRfxAsComplete'
 *   },
 * });
 */
export function useUpdateSourcingEventItemWinnersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSourcingEventItemWinnersMutation,
    UpdateSourcingEventItemWinnersMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateSourcingEventItemWinnersMutation,
    UpdateSourcingEventItemWinnersMutationVariables
  >(UpdateSourcingEventItemWinnersDocument, baseOptions);
}
export type UpdateSourcingEventItemWinnersMutationHookResult = ReturnType<
  typeof useUpdateSourcingEventItemWinnersMutation
>;
export type UpdateSourcingEventItemWinnersMutationResult = ApolloReactCommon.MutationResult<
  UpdateSourcingEventItemWinnersMutation
>;
export type UpdateSourcingEventItemWinnersMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSourcingEventItemWinnersMutation,
  UpdateSourcingEventItemWinnersMutationVariables
>;
export const UpdateSupplierDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateSupplier" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "webAddress" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "address" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "city" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "areaCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryCode" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CountryCodeEnum" },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "paymentTerms" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currency" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CurrencyEnum" },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "vatIdentification" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "businessRegistry" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "leiCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dunsCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "erpSupplierCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customField1" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customField2" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "customField3" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "SupplierStatusEnum" },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "notes" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateSupplier" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "supplierId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "supplierId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "webAddress" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "webAddress" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "address" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "city" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "city" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "areaCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "areaCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "countryCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "countryCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "paymentTerms" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "paymentTerms" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "currency" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "currency" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "vatIdentification" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "vatIdentification" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "businessRegistry" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "businessRegistry" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "leiCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "leiCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dunsCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dunsCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "erpSupplierCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "erpSupplierCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customField1" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customField1" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customField2" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customField2" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "customField3" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "customField3" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "status" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "status" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "notes" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "notes" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SupplierDetailInfo" },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SupplierDetailInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Supplier" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "defaultContactPerson" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "webAddress" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "city" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "areaCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countryCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentTerms" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currency" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "vatIdentification" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "businessRegistry" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "leiCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "dunsCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "erpSupplierCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "customFields" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "notes" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "contactPersons" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "position" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "phoneNumber" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notes" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "mentions" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "activityId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mentionedUserId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "taggedSupplierId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "activity" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parentId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "assigneeId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestorId",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "data" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ASSIGN" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "itemInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assignerInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assigneeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "DRAG_CARD" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromType" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toType" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromCode" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toCode" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestorInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assigneeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "COMMENT" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ITEM" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "addItemQuantity",
                                    },
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "addItemUnit",
                                    },
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ATTACHMENT" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "filename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "encoding" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mimetype" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "size" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_MENTION",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "emailInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subject",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "message",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "supplierInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "emailInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subject",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "message",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_REQUESTER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "emailInboundRequesterInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_OUTBOUND_MENTION",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "emailOutboundMentionInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_OUTBOUND_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "rfxInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "supplierInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "REPLY" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ITEMS" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "itemCount" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "purchaseRequestCode",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST_SERVICE",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "purchaseRequestCode",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "DRAG_ITEM" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_APPROVAL_WORKFLOW",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creatorInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalAmount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowStats",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvedRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_APPROVAL_WORKFLOW",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creatorInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalAmount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeCardInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowStats",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvedRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_APPROVAL_REQUEST",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approverInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalRequestInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "sequenceGroup",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "sequenceNumber",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creatorComment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approverComment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dueDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "decisionDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "decision",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalAmount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowStats",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvedRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_APPROVAL_REQUEST",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approverInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalRequestInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "sequenceGroup",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "sequenceNumber",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creatorComment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approverComment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dueDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "decisionDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "decision",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalAmount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowStats",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvedRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "supplierName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "supplierName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "changedFields",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fieldName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "oldValue",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "newValue",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_INVOICE_FROM_ORDER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "author" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attachments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "replies" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "organizationId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "parentId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attachments" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "filename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mimetype" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "data" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "ASSIGN" },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "itemInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "type",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "assignerInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "assigneeInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "DRAG_CARD",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "cardName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fromType",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "toType",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fromCode",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "toCode",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestorInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "assigneeInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "COMMENT" },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "user" },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "relativeInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeCardInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeCardInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeSupplierInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeSupplierInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeItemInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeItemInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "quantity",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unit",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "ADD_ITEM" },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "addItemQuantity",
                                          },
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "addItemUnit",
                                          },
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ADD_ATTACHMENT",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "filename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "encoding",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "mimetype",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "size" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "EMAIL_INBOUND_MENTION",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "user" },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "relativeInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeCardInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeCardInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeSupplierInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeSupplierInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeItemInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeItemInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "quantity",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unit",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "emailInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "subject",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "message",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "EMAIL_INBOUND_SUPPLIER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "supplierInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "emailInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "subject",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "message",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "EMAIL_INBOUND_REQUESTER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "emailInboundRequesterInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "EMAIL_OUTBOUND_MENTION",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "emailOutboundMentionInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "EMAIL_OUTBOUND_SUPPLIER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subject",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "message",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "rfxInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "type",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "orderInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "type",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "supplierInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "REPLY" },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "user" },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "relativeInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeCardInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeCardInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeSupplierInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeSupplierInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeItemInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeItemInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "quantity",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unit",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ADD_ITEMS",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "itemCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "CREATE_PURCHASE_REQUEST",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value:
                                          "CREATE_PURCHASE_REQUEST_PRODUCT",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "purchaseRequestCode",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value:
                                          "CREATE_PURCHASE_REQUEST_SERVICE",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "purchaseRequestCode",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "DRAG_ITEM",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "cardName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "CREATE_APPROVAL_WORKFLOW",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creatorInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalWorkflowInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "requestName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "comment",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "approvalAmount",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "status",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "CREATE_SUPPLIER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "supplierName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "UPDATE_SUPPLIER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "supplierName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "changedFields",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "fieldName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "oldValue",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "newValue",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value:
                                          "UPDATE_ORDER_RECEIVED_QUANTITIES",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "user" },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "orderInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "type",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "CREATE_INVOICE_FROM_ORDER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "user" },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "orderInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "type",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "replies" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "activities" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeType" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "type" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "author" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attachments" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "replies" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parentId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attachments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "data" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ASSIGN" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "itemInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assignerInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assigneeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "DRAG_CARD" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromType" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toType" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromCode" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toCode" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestorInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assigneeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "COMMENT" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ITEM" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "addItemQuantity",
                                    },
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "addItemUnit",
                                    },
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ATTACHMENT" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "filename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "encoding" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mimetype" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "size" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_MENTION",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "emailInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subject",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "message",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "supplierInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "emailInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subject",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "message",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_REQUESTER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "emailInboundRequesterInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_OUTBOUND_MENTION",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "emailOutboundMentionInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_OUTBOUND_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "rfxInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "supplierInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "REPLY" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ITEMS" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "itemCount" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "purchaseRequestCode",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST_SERVICE",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "purchaseRequestCode",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "DRAG_ITEM" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_APPROVAL_WORKFLOW",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creatorInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalAmount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "supplierName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "supplierName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "changedFields",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fieldName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "oldValue",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "newValue",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_INVOICE_FROM_ORDER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "author" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "replies" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ASSIGN" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assignerInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_CARD" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "COMMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemQuantity" },
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemUnit" },
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ATTACHMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encoding" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "size" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_REQUESTER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailInboundRequesterInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailOutboundMentionInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfxInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "REPLY" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEMS" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemCount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_SERVICE",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_APPROVAL_WORKFLOW",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowStats",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "totalRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvedRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "UPDATE_APPROVAL_WORKFLOW",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeCardInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowStats",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "totalRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvedRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_APPROVAL_REQUEST",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approverInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalRequestInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sequenceGroup",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sequenceNumber",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creatorComment",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approverComment",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dueDate" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "decisionDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "decision" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowStats",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "totalRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvedRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "UPDATE_APPROVAL_REQUEST",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approverInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalRequestInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sequenceGroup",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sequenceNumber",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creatorComment",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approverComment",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dueDate" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "decisionDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "decision" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowStats",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "totalRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvedRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "CREATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "changedFields" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fieldName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "oldValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "newValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_INVOICE_FROM_ORDER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierMutation, { data, loading, error }] = useUpdateSupplierMutation({
 *   variables: {
 *      supplierId: // value for 'supplierId'
 *      name: // value for 'name'
 *      webAddress: // value for 'webAddress'
 *      address: // value for 'address'
 *      city: // value for 'city'
 *      areaCode: // value for 'areaCode'
 *      countryCode: // value for 'countryCode'
 *      paymentTerms: // value for 'paymentTerms'
 *      currency: // value for 'currency'
 *      vatIdentification: // value for 'vatIdentification'
 *      businessRegistry: // value for 'businessRegistry'
 *      leiCode: // value for 'leiCode'
 *      dunsCode: // value for 'dunsCode'
 *      erpSupplierCode: // value for 'erpSupplierCode'
 *      customField1: // value for 'customField1'
 *      customField2: // value for 'customField2'
 *      customField3: // value for 'customField3'
 *      status: // value for 'status'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateSupplierMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSupplierMutation,
    UpdateSupplierMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateSupplierMutation,
    UpdateSupplierMutationVariables
  >(UpdateSupplierDocument, baseOptions);
}
export type UpdateSupplierMutationHookResult = ReturnType<
  typeof useUpdateSupplierMutation
>;
export type UpdateSupplierMutationResult = ApolloReactCommon.MutationResult<
  UpdateSupplierMutation
>;
export type UpdateSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSupplierMutation,
  UpdateSupplierMutationVariables
>;
export const OrganizationSourcingEventsDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OrganizationSourcingEvents" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sourcingEvents" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sentDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "responseDeadline" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfx" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "department" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "supplierResolutions",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "resolution",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "supplier",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "deletedDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "deletedDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sourcingRequests" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useOrganizationSourcingEventsQuery__
 *
 * To run a query within a React component, call `useOrganizationSourcingEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSourcingEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSourcingEventsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationSourcingEventsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationSourcingEventsQuery,
    OrganizationSourcingEventsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    OrganizationSourcingEventsQuery,
    OrganizationSourcingEventsQueryVariables
  >(OrganizationSourcingEventsDocument, baseOptions);
}
export function useOrganizationSourcingEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationSourcingEventsQuery,
    OrganizationSourcingEventsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    OrganizationSourcingEventsQuery,
    OrganizationSourcingEventsQueryVariables
  >(OrganizationSourcingEventsDocument, baseOptions);
}
export type OrganizationSourcingEventsQueryHookResult = ReturnType<
  typeof useOrganizationSourcingEventsQuery
>;
export type OrganizationSourcingEventsLazyQueryHookResult = ReturnType<
  typeof useOrganizationSourcingEventsLazyQuery
>;
export type OrganizationSourcingEventsQueryResult = ApolloReactCommon.QueryResult<
  OrganizationSourcingEventsQuery,
  OrganizationSourcingEventsQueryVariables
>;
export const CompleteSourcingRequestDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CompleteSourcingRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourcingRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "completeSourcingRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "sourcingRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sourcingRequestId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCompleteSourcingRequestMutation__
 *
 * To run a mutation, you first call `useCompleteSourcingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSourcingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSourcingRequestMutation, { data, loading, error }] = useCompleteSourcingRequestMutation({
 *   variables: {
 *      sourcingRequestId: // value for 'sourcingRequestId'
 *   },
 * });
 */
export function useCompleteSourcingRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CompleteSourcingRequestMutation,
    CompleteSourcingRequestMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CompleteSourcingRequestMutation,
    CompleteSourcingRequestMutationVariables
  >(CompleteSourcingRequestDocument, baseOptions);
}
export type CompleteSourcingRequestMutationHookResult = ReturnType<
  typeof useCompleteSourcingRequestMutation
>;
export type CompleteSourcingRequestMutationResult = ApolloReactCommon.MutationResult<
  CompleteSourcingRequestMutation
>;
export type CompleteSourcingRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CompleteSourcingRequestMutation,
  CompleteSourcingRequestMutationVariables
>;
export const SourcingRequestByIdDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SourcingRequestById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourcingRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sourcingRequestById" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "sourcingRequestId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sourcingRequestId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sourcingEventId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sourcingEvent" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "additionalInfo" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "questionnaire" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sentDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "linkToken" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "responseDeadline" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attachments" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "filename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "size" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "organization" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "companyName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "baseCurrency",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "urlName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "companyLogoUrl",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "address" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "areaCode" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "city" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "country" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfx" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "department" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "expectedDeliveryDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "additionalInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "isDeleted",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "attachments",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "filename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "emails" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "OutboundEmail",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "parentId",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "relativeId",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "relativeType",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "activityId",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "supplierId",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "requesterId",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "creatorId",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "fromEmail",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "ccEmail",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "toEmail",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "subject",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "textBody",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "htmlBody",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "outboundData",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "data",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "sendStatus",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "sendRejectedReason",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "hasAttachments",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdDate",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "InboundEmail",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "parentId",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "relativeId",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "relativeType",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "activityId",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "supplierId",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "supplierName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "requesterId",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "requesterName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "fromEmail",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "toEmail",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "ccEmail",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "subject",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "textBody",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "htmlBody",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "inboundData",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "data",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "hasAttachments",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "spfResult",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dkimValid",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "securityData",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "hasBeenRead",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdDate",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supplierId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quotation" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "responseDeadline" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attachments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useSourcingRequestByIdQuery__
 *
 * To run a query within a React component, call `useSourcingRequestByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourcingRequestByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourcingRequestByIdQuery({
 *   variables: {
 *      sourcingRequestId: // value for 'sourcingRequestId'
 *   },
 * });
 */
export function useSourcingRequestByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SourcingRequestByIdQuery,
    SourcingRequestByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    SourcingRequestByIdQuery,
    SourcingRequestByIdQueryVariables
  >(SourcingRequestByIdDocument, baseOptions);
}
export function useSourcingRequestByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SourcingRequestByIdQuery,
    SourcingRequestByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    SourcingRequestByIdQuery,
    SourcingRequestByIdQueryVariables
  >(SourcingRequestByIdDocument, baseOptions);
}
export type SourcingRequestByIdQueryHookResult = ReturnType<
  typeof useSourcingRequestByIdQuery
>;
export type SourcingRequestByIdLazyQueryHookResult = ReturnType<
  typeof useSourcingRequestByIdLazyQuery
>;
export type SourcingRequestByIdQueryResult = ApolloReactCommon.QueryResult<
  SourcingRequestByIdQuery,
  SourcingRequestByIdQueryVariables
>;
export const UpdateSourcingRequestDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateSourcingRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourcingRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "quotation" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "comment" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "Upload" },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateSourcingRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "sourcingRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sourcingRequestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "quotation" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "quotation" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "comment" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "comment" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachments" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachments" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateSourcingRequestMutation__
 *
 * To run a mutation, you first call `useUpdateSourcingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSourcingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSourcingRequestMutation, { data, loading, error }] = useUpdateSourcingRequestMutation({
 *   variables: {
 *      sourcingRequestId: // value for 'sourcingRequestId'
 *      quotation: // value for 'quotation'
 *      comment: // value for 'comment'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useUpdateSourcingRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSourcingRequestMutation,
    UpdateSourcingRequestMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateSourcingRequestMutation,
    UpdateSourcingRequestMutationVariables
  >(UpdateSourcingRequestDocument, baseOptions);
}
export type UpdateSourcingRequestMutationHookResult = ReturnType<
  typeof useUpdateSourcingRequestMutation
>;
export type UpdateSourcingRequestMutationResult = ApolloReactCommon.MutationResult<
  UpdateSourcingRequestMutation
>;
export type UpdateSourcingRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSourcingRequestMutation,
  UpdateSourcingRequestMutationVariables
>;
export const UploadQuotationPricesExcelDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UploadQuotationPricesExcel" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourcingRequestId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "excel" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Upload" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "uploadQuotationPricesExcel" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sourcingRequestId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sourcingRequestId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "excel" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "excel" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "success" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rows" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "row" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "errors" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unitPrice" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sourcingRequest" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quotation" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUploadQuotationPricesExcelMutation__
 *
 * To run a mutation, you first call `useUploadQuotationPricesExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadQuotationPricesExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadQuotationPricesExcelMutation, { data, loading, error }] = useUploadQuotationPricesExcelMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      sourcingRequestId: // value for 'sourcingRequestId'
 *      excel: // value for 'excel'
 *   },
 * });
 */
export function useUploadQuotationPricesExcelMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadQuotationPricesExcelMutation,
    UploadQuotationPricesExcelMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UploadQuotationPricesExcelMutation,
    UploadQuotationPricesExcelMutationVariables
  >(UploadQuotationPricesExcelDocument, baseOptions);
}
export type UploadQuotationPricesExcelMutationHookResult = ReturnType<
  typeof useUploadQuotationPricesExcelMutation
>;
export type UploadQuotationPricesExcelMutationResult = ApolloReactCommon.MutationResult<
  UploadQuotationPricesExcelMutation
>;
export type UploadQuotationPricesExcelMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadQuotationPricesExcelMutation,
  UploadQuotationPricesExcelMutationVariables
>;
export const SupplierSourcingRequestsDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SupplierSourcingRequests" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supplierById" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "supplierId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "supplierId" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "organizationId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useSupplierSourcingRequestsQuery__
 *
 * To run a query within a React component, call `useSupplierSourcingRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierSourcingRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierSourcingRequestsQuery({
 *   variables: {
 *      supplierId: // value for 'supplierId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSupplierSourcingRequestsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SupplierSourcingRequestsQuery,
    SupplierSourcingRequestsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    SupplierSourcingRequestsQuery,
    SupplierSourcingRequestsQueryVariables
  >(SupplierSourcingRequestsDocument, baseOptions);
}
export function useSupplierSourcingRequestsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SupplierSourcingRequestsQuery,
    SupplierSourcingRequestsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    SupplierSourcingRequestsQuery,
    SupplierSourcingRequestsQueryVariables
  >(SupplierSourcingRequestsDocument, baseOptions);
}
export type SupplierSourcingRequestsQueryHookResult = ReturnType<
  typeof useSupplierSourcingRequestsQuery
>;
export type SupplierSourcingRequestsLazyQueryHookResult = ReturnType<
  typeof useSupplierSourcingRequestsLazyQuery
>;
export type SupplierSourcingRequestsQueryResult = ApolloReactCommon.QueryResult<
  SupplierSourcingRequestsQuery,
  SupplierSourcingRequestsQueryVariables
>;
export const CountriesDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Countries" },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "code" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountriesQuery,
    CountriesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<CountriesQuery, CountriesQueryVariables>(
    CountriesDocument,
    baseOptions,
  );
}
export function useCountriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountriesQuery,
    CountriesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<CountriesQuery, CountriesQueryVariables>(
    CountriesDocument,
    baseOptions,
  );
}
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<
  typeof useCountriesLazyQuery
>;
export type CountriesQueryResult = ApolloReactCommon.QueryResult<
  CountriesQuery,
  CountriesQueryVariables
>;
export const SourcingEventPublicDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SourcingEventPublic" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourcingEventLinkToken" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sourcingEventPublic" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "sourcingEventLinkToken" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sourcingEventLinkToken" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "linkToken" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "responseDeadline" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "additionalInfo" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companyName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companyLogoUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creator" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useSourcingEventPublicQuery__
 *
 * To run a query within a React component, call `useSourcingEventPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourcingEventPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourcingEventPublicQuery({
 *   variables: {
 *      sourcingEventLinkToken: // value for 'sourcingEventLinkToken'
 *   },
 * });
 */
export function useSourcingEventPublicQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SourcingEventPublicQuery,
    SourcingEventPublicQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    SourcingEventPublicQuery,
    SourcingEventPublicQueryVariables
  >(SourcingEventPublicDocument, baseOptions);
}
export function useSourcingEventPublicLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SourcingEventPublicQuery,
    SourcingEventPublicQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    SourcingEventPublicQuery,
    SourcingEventPublicQueryVariables
  >(SourcingEventPublicDocument, baseOptions);
}
export type SourcingEventPublicQueryHookResult = ReturnType<
  typeof useSourcingEventPublicQuery
>;
export type SourcingEventPublicLazyQueryHookResult = ReturnType<
  typeof useSourcingEventPublicLazyQuery
>;
export type SourcingEventPublicQueryResult = ApolloReactCommon.QueryResult<
  SourcingEventPublicQuery,
  SourcingEventPublicQueryVariables
>;
export const SupplierLookupDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SupplierLookup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourcingEventLinkToken" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "supplierLookup" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sourcingEventLinkToken" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sourcingEventLinkToken" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "result" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useSupplierLookupMutation__
 *
 * To run a mutation, you first call `useSupplierLookupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupplierLookupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supplierLookupMutation, { data, loading, error }] = useSupplierLookupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      organizationId: // value for 'organizationId'
 *      sourcingEventLinkToken: // value for 'sourcingEventLinkToken'
 *   },
 * });
 */
export function useSupplierLookupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SupplierLookupMutation,
    SupplierLookupMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SupplierLookupMutation,
    SupplierLookupMutationVariables
  >(SupplierLookupDocument, baseOptions);
}
export type SupplierLookupMutationHookResult = ReturnType<
  typeof useSupplierLookupMutation
>;
export type SupplierLookupMutationResult = ApolloReactCommon.MutationResult<
  SupplierLookupMutation
>;
export type SupplierLookupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SupplierLookupMutation,
  SupplierLookupMutationVariables
>;
export const SupplierSelfRegistrationDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SupplierSelfRegistration" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourcingEventContactPersonId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourcingEventLinkToken" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "companyName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contactName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "url" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "city" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "zip" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryCode" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CountryCodeEnum" },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "duns" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "euVatNumber" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "position" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "supplierSelfRegistration" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "companyName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "companyName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "contactName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "contactName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sourcingEventContactPersonId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sourcingEventContactPersonId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sourcingEventLinkToken" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sourcingEventLinkToken" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "url" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "url" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "city" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "city" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "zip" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "zip" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "countryCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "countryCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "duns" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "duns" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "euVatNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "euVatNumber" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "position" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "position" },
                },
              },
            ],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __useSupplierSelfRegistrationMutation__
 *
 * To run a mutation, you first call `useSupplierSelfRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupplierSelfRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supplierSelfRegistrationMutation, { data, loading, error }] = useSupplierSelfRegistrationMutation({
 *   variables: {
 *      sourcingEventContactPersonId: // value for 'sourcingEventContactPersonId'
 *      sourcingEventLinkToken: // value for 'sourcingEventLinkToken'
 *      companyName: // value for 'companyName'
 *      contactName: // value for 'contactName'
 *      email: // value for 'email'
 *      organizationId: // value for 'organizationId'
 *      url: // value for 'url'
 *      city: // value for 'city'
 *      zip: // value for 'zip'
 *      countryCode: // value for 'countryCode'
 *      duns: // value for 'duns'
 *      euVatNumber: // value for 'euVatNumber'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useSupplierSelfRegistrationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SupplierSelfRegistrationMutation,
    SupplierSelfRegistrationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SupplierSelfRegistrationMutation,
    SupplierSelfRegistrationMutationVariables
  >(SupplierSelfRegistrationDocument, baseOptions);
}
export type SupplierSelfRegistrationMutationHookResult = ReturnType<
  typeof useSupplierSelfRegistrationMutation
>;
export type SupplierSelfRegistrationMutationResult = ApolloReactCommon.MutationResult<
  SupplierSelfRegistrationMutation
>;
export type SupplierSelfRegistrationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SupplierSelfRegistrationMutation,
  SupplierSelfRegistrationMutationVariables
>;
export const AddSupplierCategoryDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddSupplierCategory" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categoryId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addSupplierCategory" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "supplierId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "supplierId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "categoryId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "categoryId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useAddSupplierCategoryMutation__
 *
 * To run a mutation, you first call `useAddSupplierCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplierCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplierCategoryMutation, { data, loading, error }] = useAddSupplierCategoryMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      supplierId: // value for 'supplierId'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useAddSupplierCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddSupplierCategoryMutation,
    AddSupplierCategoryMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AddSupplierCategoryMutation,
    AddSupplierCategoryMutationVariables
  >(AddSupplierCategoryDocument, baseOptions);
}
export type AddSupplierCategoryMutationHookResult = ReturnType<
  typeof useAddSupplierCategoryMutation
>;
export type AddSupplierCategoryMutationResult = ApolloReactCommon.MutationResult<
  AddSupplierCategoryMutation
>;
export type AddSupplierCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddSupplierCategoryMutation,
  AddSupplierCategoryMutationVariables
>;
export const ContactPersonByIdDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ContactPersonById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contactPersonId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contactPersonById" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "contactPersonId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "contactPersonId" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "organizationId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ContactPersonInfo" },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ContactPersonInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ContactPerson" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "position" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "phoneNumber" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "notes" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __useContactPersonByIdQuery__
 *
 * To run a query within a React component, call `useContactPersonByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactPersonByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactPersonByIdQuery({
 *   variables: {
 *      contactPersonId: // value for 'contactPersonId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useContactPersonByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ContactPersonByIdQuery,
    ContactPersonByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    ContactPersonByIdQuery,
    ContactPersonByIdQueryVariables
  >(ContactPersonByIdDocument, baseOptions);
}
export function useContactPersonByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContactPersonByIdQuery,
    ContactPersonByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    ContactPersonByIdQuery,
    ContactPersonByIdQueryVariables
  >(ContactPersonByIdDocument, baseOptions);
}
export type ContactPersonByIdQueryHookResult = ReturnType<
  typeof useContactPersonByIdQuery
>;
export type ContactPersonByIdLazyQueryHookResult = ReturnType<
  typeof useContactPersonByIdLazyQuery
>;
export type ContactPersonByIdQueryResult = ApolloReactCommon.QueryResult<
  ContactPersonByIdQuery,
  ContactPersonByIdQueryVariables
>;
export const CreateCategoryDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateCategory" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "parentId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createCategory" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "parentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "parentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      parentId: // value for 'parentId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCategoryMutation,
    CreateCategoryMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateCategoryMutation,
    CreateCategoryMutationVariables
  >(CreateCategoryDocument, baseOptions);
}
export type CreateCategoryMutationHookResult = ReturnType<
  typeof useCreateCategoryMutation
>;
export type CreateCategoryMutationResult = ApolloReactCommon.MutationResult<
  CreateCategoryMutation
>;
export type CreateCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCategoryMutation,
  CreateCategoryMutationVariables
>;
export const CreateContactPersonDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateContactPerson" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firstName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lastName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "position" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phoneNumber" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "notes" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isDefault" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createContactPerson" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "supplierId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "supplierId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "firstName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "firstName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "lastName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "lastName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "position" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "position" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "phoneNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phoneNumber" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "notes" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "notes" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isDefault" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isDefault" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateContactPersonMutation__
 *
 * To run a mutation, you first call `useCreateContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactPersonMutation, { data, loading, error }] = useCreateContactPersonMutation({
 *   variables: {
 *      supplierId: // value for 'supplierId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      position: // value for 'position'
 *      phoneNumber: // value for 'phoneNumber'
 *      email: // value for 'email'
 *      notes: // value for 'notes'
 *      isDefault: // value for 'isDefault'
 *   },
 * });
 */
export function useCreateContactPersonMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateContactPersonMutation,
    CreateContactPersonMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateContactPersonMutation,
    CreateContactPersonMutationVariables
  >(CreateContactPersonDocument, baseOptions);
}
export type CreateContactPersonMutationHookResult = ReturnType<
  typeof useCreateContactPersonMutation
>;
export type CreateContactPersonMutationResult = ApolloReactCommon.MutationResult<
  CreateContactPersonMutation
>;
export type CreateContactPersonMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateContactPersonMutation,
  CreateContactPersonMutationVariables
>;
export const CreateSupplierAttachmentDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateSupplierAttachment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "notes" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "documentDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "validityDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachments" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "Upload" },
                },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createSupplierAttachment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "supplierId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "supplierId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "notes" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "notes" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "documentDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "documentDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "validityDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "validityDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachments" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachments" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creatorId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "url" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filename" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notes" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "documentDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "validityDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creator" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useCreateSupplierAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateSupplierAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierAttachmentMutation, { data, loading, error }] = useCreateSupplierAttachmentMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      supplierId: // value for 'supplierId'
 *      notes: // value for 'notes'
 *      documentDate: // value for 'documentDate'
 *      validityDate: // value for 'validityDate'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useCreateSupplierAttachmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSupplierAttachmentMutation,
    CreateSupplierAttachmentMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateSupplierAttachmentMutation,
    CreateSupplierAttachmentMutationVariables
  >(CreateSupplierAttachmentDocument, baseOptions);
}
export type CreateSupplierAttachmentMutationHookResult = ReturnType<
  typeof useCreateSupplierAttachmentMutation
>;
export type CreateSupplierAttachmentMutationResult = ApolloReactCommon.MutationResult<
  CreateSupplierAttachmentMutation
>;
export type CreateSupplierAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSupplierAttachmentMutation,
  CreateSupplierAttachmentMutationVariables
>;
export const RemoveSupplierCategoryDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RemoveSupplierCategory" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categoryId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removeSupplierCategory" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "supplierId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "supplierId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "categoryId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "categoryId" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useRemoveSupplierCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveSupplierCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSupplierCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSupplierCategoryMutation, { data, loading, error }] = useRemoveSupplierCategoryMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      supplierId: // value for 'supplierId'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useRemoveSupplierCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveSupplierCategoryMutation,
    RemoveSupplierCategoryMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    RemoveSupplierCategoryMutation,
    RemoveSupplierCategoryMutationVariables
  >(RemoveSupplierCategoryDocument, baseOptions);
}
export type RemoveSupplierCategoryMutationHookResult = ReturnType<
  typeof useRemoveSupplierCategoryMutation
>;
export type RemoveSupplierCategoryMutationResult = ApolloReactCommon.MutationResult<
  RemoveSupplierCategoryMutation
>;
export type RemoveSupplierCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveSupplierCategoryMutation,
  RemoveSupplierCategoryMutationVariables
>;
export const SrmViewDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SrmView" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "withArchived" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suppliers" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "withArchived" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "withArchived" },
                            },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "defaultContactPerson",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "webAddress" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "city" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "areaCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "country" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastActivityDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attachments" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastContact" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "contactPersons" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "categories" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useSrmViewQuery__
 *
 * To run a query within a React component, call `useSrmViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSrmViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSrmViewQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      withArchived: // value for 'withArchived'
 *   },
 * });
 */
export function useSrmViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SrmViewQuery,
    SrmViewQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<SrmViewQuery, SrmViewQueryVariables>(
    SrmViewDocument,
    baseOptions,
  );
}
export function useSrmViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SrmViewQuery,
    SrmViewQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<SrmViewQuery, SrmViewQueryVariables>(
    SrmViewDocument,
    baseOptions,
  );
}
export type SrmViewQueryHookResult = ReturnType<typeof useSrmViewQuery>;
export type SrmViewLazyQueryHookResult = ReturnType<typeof useSrmViewLazyQuery>;
export type SrmViewQueryResult = ApolloReactCommon.QueryResult<
  SrmViewQuery,
  SrmViewQueryVariables
>;
export const SupplierAttachmentDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SupplierAttachment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supplierById" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "supplierId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "supplierId" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "organizationId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attachments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "notes" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "documentDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "validityDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useSupplierAttachmentQuery__
 *
 * To run a query within a React component, call `useSupplierAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierAttachmentQuery({
 *   variables: {
 *      supplierId: // value for 'supplierId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSupplierAttachmentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SupplierAttachmentQuery,
    SupplierAttachmentQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    SupplierAttachmentQuery,
    SupplierAttachmentQueryVariables
  >(SupplierAttachmentDocument, baseOptions);
}
export function useSupplierAttachmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SupplierAttachmentQuery,
    SupplierAttachmentQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    SupplierAttachmentQuery,
    SupplierAttachmentQueryVariables
  >(SupplierAttachmentDocument, baseOptions);
}
export type SupplierAttachmentQueryHookResult = ReturnType<
  typeof useSupplierAttachmentQuery
>;
export type SupplierAttachmentLazyQueryHookResult = ReturnType<
  typeof useSupplierAttachmentLazyQuery
>;
export type SupplierAttachmentQueryResult = ApolloReactCommon.QueryResult<
  SupplierAttachmentQuery,
  SupplierAttachmentQueryVariables
>;
export const SupplierByIdDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SupplierById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "code" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supplierById" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "supplierId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "supplierId" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "organizationId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "SupplierDetailInfo" },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SupplierDetailInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Supplier" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "defaultContactPerson" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "webAddress" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "city" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "areaCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countryCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentTerms" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currency" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "vatIdentification" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "businessRegistry" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "leiCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "dunsCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "erpSupplierCode" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "customFields" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "notes" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "updatedDate" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "contactPersons" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastName" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "position" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "phoneNumber" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notes" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "mentions" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "activityId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mentionedUserId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "taggedSupplierId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "activity" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parentId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeInfo" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "ActivityRelativeCardInfo",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "assigneeId" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestorId",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "data" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ASSIGN" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "itemInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assignerInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assigneeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "DRAG_CARD" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromType" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toType" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromCode" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toCode" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestorInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assigneeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "COMMENT" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ITEM" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "addItemQuantity",
                                    },
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "addItemUnit",
                                    },
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ATTACHMENT" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "filename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "encoding" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mimetype" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "size" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_MENTION",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "emailInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subject",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "message",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "supplierInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "emailInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subject",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "message",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_REQUESTER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "emailInboundRequesterInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_OUTBOUND_MENTION",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "emailOutboundMentionInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_OUTBOUND_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "rfxInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "supplierInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "REPLY" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ITEMS" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "itemCount" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "purchaseRequestCode",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST_SERVICE",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "purchaseRequestCode",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "DRAG_ITEM" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_APPROVAL_WORKFLOW",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creatorInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalAmount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowStats",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvedRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_APPROVAL_WORKFLOW",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creatorInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalAmount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeCardInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowStats",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvedRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_APPROVAL_REQUEST",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approverInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalRequestInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "sequenceGroup",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "sequenceNumber",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creatorComment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approverComment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dueDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "decisionDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "decision",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalAmount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowStats",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvedRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_APPROVAL_REQUEST",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approverInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalRequestInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "sequenceGroup",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "sequenceNumber",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creatorComment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approverComment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dueDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "decisionDate",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "decision",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalAmount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowStats",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvedRequestCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "supplierName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "supplierName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "changedFields",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fieldName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "oldValue",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "newValue",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_INVOICE_FROM_ORDER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "author" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attachments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "replies" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "organizationId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "parentId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeId" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attachments" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "filename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mimetype" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "data" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "ASSIGN" },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "itemInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "type",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "assignerInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "assigneeInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "DRAG_CARD",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "cardName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fromType",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "toType",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fromCode",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "toCode",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestorInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "assigneeInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "COMMENT" },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "user" },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "relativeInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeCardInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeCardInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeSupplierInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeSupplierInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeItemInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeItemInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "quantity",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unit",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "ADD_ITEM" },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "addItemQuantity",
                                          },
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "addItemUnit",
                                          },
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ADD_ATTACHMENT",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "filename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "encoding",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "mimetype",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "size" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "EMAIL_INBOUND_MENTION",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "user" },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "relativeInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeCardInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeCardInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeSupplierInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeSupplierInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeItemInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeItemInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "quantity",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unit",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "emailInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "subject",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "message",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "EMAIL_INBOUND_SUPPLIER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "supplierInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "emailInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "subject",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "message",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "EMAIL_INBOUND_REQUESTER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "emailInboundRequesterInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "EMAIL_OUTBOUND_MENTION",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "emailOutboundMentionInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "EMAIL_OUTBOUND_SUPPLIER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subject",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "message",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "rfxInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "type",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "orderInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "type",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "supplierInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "REPLY" },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "user" },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "relativeInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "__typename",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeCardInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeCardInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "code",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeSupplierInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeSupplierInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: {
                                                    kind: "Name",
                                                    value:
                                                      "ActivityRelativeItemInfo",
                                                  },
                                                },
                                                directives: [],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      alias: {
                                                        kind: "Name",
                                                        value:
                                                          "relativeItemInfoName",
                                                      },
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "quantity",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unit",
                                                      },
                                                      arguments: [],
                                                      directives: [],
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ADD_ITEMS",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "itemCount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "CREATE_PURCHASE_REQUEST",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value:
                                          "CREATE_PURCHASE_REQUEST_PRODUCT",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "purchaseRequestCode",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value:
                                          "CREATE_PURCHASE_REQUEST_SERVICE",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "purchaseRequestCode",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "DRAG_ITEM",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "cardName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "CREATE_APPROVAL_WORKFLOW",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creatorInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalWorkflowInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "requestName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "comment",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "approvalAmount",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "status",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "CREATE_SUPPLIER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "supplierName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "UPDATE_SUPPLIER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "supplierName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "creator",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "changedFields",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "fieldName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "oldValue",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "newValue",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value:
                                          "UPDATE_ORDER_RECEIVED_QUANTITIES",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "user" },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "orderInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "type",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "CREATE_INVOICE_FROM_ORDER",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "user" },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "avatarUrl",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "orderInfo",
                                          },
                                          arguments: [],
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "type",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedDate" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "replies" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "activities" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeId" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "relativeType" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "type" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedDate" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "author" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attachments" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mimetype" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "url" },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "replies" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "id" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parentId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeId" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "relativeType" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attachments" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "data" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ASSIGN" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "itemInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assignerInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assigneeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "DRAG_CARD" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromType" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toType" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromCode" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toCode" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestorInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "assigneeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "COMMENT" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ITEM" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "addItemQuantity",
                                    },
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "addItemUnit",
                                    },
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ATTACHMENT" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "filename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "encoding" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mimetype" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "size" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_MENTION",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "emailInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subject",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "message",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "supplierInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "emailInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subject",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "message",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_INBOUND_REQUESTER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "emailInboundRequesterInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_OUTBOUND_MENTION",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "emailOutboundMentionInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "EMAIL_OUTBOUND_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "rfxInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "supplierInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "REPLY" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "relativeInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeCardInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeCardInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "code",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value:
                                                "ActivityRelativeSupplierInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value:
                                                    "relativeSupplierInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ActivityRelativeItemInfo",
                                            },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                alias: {
                                                  kind: "Name",
                                                  value: "relativeItemInfoName",
                                                },
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "quantity",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "unit",
                                                },
                                                arguments: [],
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ADD_ITEMS" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "itemCount" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "purchaseRequestCode",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_PURCHASE_REQUEST_SERVICE",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "purchaseRequestCode",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "DRAG_ITEM" },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_APPROVAL_WORKFLOW",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creatorInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalWorkflowInfo",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "comment",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "approvalAmount",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "supplierName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_SUPPLIER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    alias: {
                                      kind: "Name",
                                      value: "supplierName",
                                    },
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "creator" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "changedFields",
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fieldName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "oldValue",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "newValue",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: {
                                  kind: "Name",
                                  value: "CREATE_INVOICE_FROM_ORDER",
                                },
                              },
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "avatarUrl",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderInfo" },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedDate" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "author" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "replies" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ASSIGN" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assignerInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_CARD" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toType" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "toCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "assigneeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "COMMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemQuantity" },
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "addItemUnit" },
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ATTACHMENT" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "encoding" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mimetype" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "size" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "emailInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subject" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_INBOUND_REQUESTER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailInboundRequesterInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_MENTION",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "emailOutboundMentionInfo",
                              },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "EMAIL_OUTBOUND_SUPPLIER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subject" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rfxInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "supplierInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "REPLY" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeCardInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeCardInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeSupplierInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeSupplierInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "ActivityRelativeItemInfo",
                                      },
                                    },
                                    directives: [],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "relativeItemInfoName",
                                          },
                                          name: { kind: "Name", value: "name" },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "unit" },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comment" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ADD_ITEMS" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "itemCount" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_PRODUCT",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_PURCHASE_REQUEST_SERVICE",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "purchaseRequestCode",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "DRAG_ITEM" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cardName" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_APPROVAL_WORKFLOW",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowStats",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "totalRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvedRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "UPDATE_APPROVAL_WORKFLOW",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creatorInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "comment" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "relativeCardInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowStats",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "totalRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvedRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_APPROVAL_REQUEST",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approverInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalRequestInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sequenceGroup",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sequenceNumber",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creatorComment",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approverComment",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dueDate" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "decisionDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "decision" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowStats",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "totalRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvedRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "UPDATE_APPROVAL_REQUEST",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "approverInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalRequestInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sequenceGroup",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sequenceNumber",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creatorComment",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approverComment",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dueDate" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "decisionDate",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "decision" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowInfo",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requestName",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvalAmount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "approvalWorkflowStats",
                              },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "totalRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "approvedRequestCount",
                                    },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "CREATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "UPDATE_SUPPLIER" },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "supplierName" },
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "creator" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "changedFields" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fieldName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "oldValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "newValue" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "UPDATE_ORDER_RECEIVED_QUANTITIES",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "CREATE_INVOICE_FROM_ORDER",
                          },
                        },
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderInfo" },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useSupplierByIdQuery__
 *
 * To run a query within a React component, call `useSupplierByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierByIdQuery({
 *   variables: {
 *      supplierId: // value for 'supplierId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSupplierByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SupplierByIdQuery,
    SupplierByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    SupplierByIdQuery,
    SupplierByIdQueryVariables
  >(SupplierByIdDocument, baseOptions);
}
export function useSupplierByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SupplierByIdQuery,
    SupplierByIdQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    SupplierByIdQuery,
    SupplierByIdQueryVariables
  >(SupplierByIdDocument, baseOptions);
}
export type SupplierByIdQueryHookResult = ReturnType<
  typeof useSupplierByIdQuery
>;
export type SupplierByIdLazyQueryHookResult = ReturnType<
  typeof useSupplierByIdLazyQuery
>;
export type SupplierByIdQueryResult = ApolloReactCommon.QueryResult<
  SupplierByIdQuery,
  SupplierByIdQueryVariables
>;
export const SupplierCategoriesDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SupplierCategories" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supplierId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supplierById" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "organizationId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizationId" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "supplierId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "supplierId" },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useSupplierCategoriesQuery__
 *
 * To run a query within a React component, call `useSupplierCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierCategoriesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useSupplierCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SupplierCategoriesQuery,
    SupplierCategoriesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    SupplierCategoriesQuery,
    SupplierCategoriesQueryVariables
  >(SupplierCategoriesDocument, baseOptions);
}
export function useSupplierCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SupplierCategoriesQuery,
    SupplierCategoriesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    SupplierCategoriesQuery,
    SupplierCategoriesQueryVariables
  >(SupplierCategoriesDocument, baseOptions);
}
export type SupplierCategoriesQueryHookResult = ReturnType<
  typeof useSupplierCategoriesQuery
>;
export type SupplierCategoriesLazyQueryHookResult = ReturnType<
  typeof useSupplierCategoriesLazyQuery
>;
export type SupplierCategoriesQueryResult = ApolloReactCommon.QueryResult<
  SupplierCategoriesQuery,
  SupplierCategoriesQueryVariables
>;
export const UpdateContactPersonDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateContactPerson" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contactPersonId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firstName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lastName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "position" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phoneNumber" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "notes" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isDefault" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateContactPerson" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "contactPersonId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "contactPersonId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "firstName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "firstName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "lastName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "lastName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "position" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "position" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "phoneNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phoneNumber" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "notes" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "notes" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isDefault" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isDefault" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ContactPersonInfo" },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ContactPersonInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ContactPerson" },
      },
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "firstName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastName" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "position" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "phoneNumber" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "email" },
            arguments: [],
            directives: [],
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "notes" },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateContactPersonMutation__
 *
 * To run a mutation, you first call `useUpdateContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactPersonMutation, { data, loading, error }] = useUpdateContactPersonMutation({
 *   variables: {
 *      contactPersonId: // value for 'contactPersonId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      position: // value for 'position'
 *      phoneNumber: // value for 'phoneNumber'
 *      email: // value for 'email'
 *      notes: // value for 'notes'
 *      isDefault: // value for 'isDefault'
 *   },
 * });
 */
export function useUpdateContactPersonMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateContactPersonMutation,
    UpdateContactPersonMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateContactPersonMutation,
    UpdateContactPersonMutationVariables
  >(UpdateContactPersonDocument, baseOptions);
}
export type UpdateContactPersonMutationHookResult = ReturnType<
  typeof useUpdateContactPersonMutation
>;
export type UpdateContactPersonMutationResult = ApolloReactCommon.MutationResult<
  UpdateContactPersonMutation
>;
export type UpdateContactPersonMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateContactPersonMutation,
  UpdateContactPersonMutationVariables
>;
export const UploadSuppliersExcelDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UploadSuppliersExcel" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "excel" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Upload" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "uploadSupplierExcel" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "excel" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "excel" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "success" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rows" },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "row" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "errors" },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "webAddress" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "city" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "areaCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paymentTerms" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "vatIdentification",
                              },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "businessRegistry" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "leiCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dunsCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "erpSupplierCode" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "customField1" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "customField2" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "customField3" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "notes" },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUploadSuppliersExcelMutation__
 *
 * To run a mutation, you first call `useUploadSuppliersExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadSuppliersExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadSuppliersExcelMutation, { data, loading, error }] = useUploadSuppliersExcelMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      excel: // value for 'excel'
 *   },
 * });
 */
export function useUploadSuppliersExcelMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadSuppliersExcelMutation,
    UploadSuppliersExcelMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UploadSuppliersExcelMutation,
    UploadSuppliersExcelMutationVariables
  >(UploadSuppliersExcelDocument, baseOptions);
}
export type UploadSuppliersExcelMutationHookResult = ReturnType<
  typeof useUploadSuppliersExcelMutation
>;
export type UploadSuppliersExcelMutationResult = ApolloReactCommon.MutationResult<
  UploadSuppliersExcelMutation
>;
export type UploadSuppliersExcelMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadSuppliersExcelMutation,
  UploadSuppliersExcelMutationVariables
>;
export const UpdateUserProfileDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateUserProfile" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firstName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lastName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phoneNumber" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "address" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "city" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "areaCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          directives: [],
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryCode" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CountryCodeEnum" },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateProfile" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "firstName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "firstName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "lastName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "lastName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "phoneNumber" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phoneNumber" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "address" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "city" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "city" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "areaCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "areaCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "countryCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "countryCode" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "phoneNumber" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "city" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "areaCode" },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countryCode" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *      address: // value for 'address'
 *      city: // value for 'city'
 *      areaCode: // value for 'areaCode'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >(UpdateUserProfileDocument, baseOptions);
}
export type UpdateUserProfileMutationHookResult = ReturnType<
  typeof useUpdateUserProfileMutation
>;
export type UpdateUserProfileMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserProfileMutation
>;
export type UpdateUserProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;
export const UserUpdateProfilePictureDocument: DocumentNode = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UserUpdateProfilePicture" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "profilePicture" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Upload" },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateProfilePicture" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "profilePicture" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "profilePicture" },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "id" },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useUserUpdateProfilePictureMutation__
 *
 * To run a mutation, you first call `useUserUpdateProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateProfilePictureMutation, { data, loading, error }] = useUserUpdateProfilePictureMutation({
 *   variables: {
 *      profilePicture: // value for 'profilePicture'
 *   },
 * });
 */
export function useUserUpdateProfilePictureMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserUpdateProfilePictureMutation,
    UserUpdateProfilePictureMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UserUpdateProfilePictureMutation,
    UserUpdateProfilePictureMutationVariables
  >(UserUpdateProfilePictureDocument, baseOptions);
}
export type UserUpdateProfilePictureMutationHookResult = ReturnType<
  typeof useUserUpdateProfilePictureMutation
>;
export type UserUpdateProfilePictureMutationResult = ApolloReactCommon.MutationResult<
  UserUpdateProfilePictureMutation
>;
export type UserUpdateProfilePictureMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserUpdateProfilePictureMutation,
  UserUpdateProfilePictureMutationVariables
>;
